import TACTICS_COLORS from '../../constants/tacticsColors'
import uppercaseFirstLetter from '../uppercaseFirstLetter'

const chipsRowPdfHelper = (chips, pageLength, chipRowStyle, chipTextStyle) => {
  if (!chips?.length) return null

  const PAGE_LENGTH = pageLength ?? 400
  const CHIP_MARGIN = 5
  const CHIP_HEIGHT = 20
  const columnsArray = []
  let chipsTotalLength = 0
  let chipsSumLength = 0
  let columnsRow = []

  chips.map((chip) => {
    const { name, type } = chip || {}
    // 5 is the approximate width of a character, and 30 is the padding. Also remove whitespace.
    const chipWidth = name?.length ? name.replace(/\s/g, '').length * 5 + 30 : 100
    chipsSumLength += chipWidth + CHIP_MARGIN
    chipsTotalLength += chipWidth

    // If the chip exceeds page length limits.
    if (chipsSumLength >= PAGE_LENGTH) {
      columnsArray.push(columnsRow)
      columnsRow = []
      chipsSumLength = 0
    }

    // 5 is for right margin of the chip.
    columnsRow.push({
      width: chipWidth + CHIP_MARGIN,
      style: chipRowStyle,
      stack: [
        {
          text: uppercaseFirstLetter(name),
          relativePosition: { x: 0, y: 5 },
          style: chipTextStyle,
        },
        {
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: chipWidth,
              h: CHIP_HEIGHT,
              r: 15,
              color: type ? TACTICS_COLORS[type] : '#00000014',
              relativePosition: { x: 0, y: 5 },
              fillOpacity: 0.4,
            },
          ],
        },
      ],
    })

    return null
  })

  columnsArray.push(columnsRow)

  return {
    chips: columnsArray.map((columnArray) => ({
      columns: columnArray,
    })),
    chipsTotalLength,
  }
}

export default chipsRowPdfHelper
