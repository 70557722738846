export const TRAINING_MODULES_TYPES_URL = {
  DRILLS: 'drills',
  PLAYS: 'plays',
}

const TRAINING_MODULES_TYPES = {
  drill: 'DRILL',
  plays: 'PLAY',
}

export default TRAINING_MODULES_TYPES
