import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'

const FormContainer = ({ children }) => {
  const useStyles = makeStyles({
    container: {
      paddingLeft: 20,
      paddingRight: 20,
      width: '100%!important',
      margin: '0 0 0 0!important',
    },
    gridItemRoot: {
      padding: '15px 8px 8px 8px!important',
    },
    gridItemRootNoPadding: {
      padding: '0px 8px 0 8px!important',
    },
  })
  const classes = useStyles()
  const mapChildren = () =>
    React.Children.map(children, (child) => {
      if (!child || child?.props?.renderValue === false) return null

      const { props } = child
      const { xs, noPadding } = props

      return (
        <Grid
          item
          xs={xs ? xs : true}
          classes={{ root: noPadding ? classes.gridItemRootNoPadding : classes.gridItemRoot }}
        >
          {child}
        </Grid>
      )
    })

  return (
    <Grid container spacing={2} direction='column' classes={{ container: classes.container }}>
      {mapChildren()}
    </Grid>
  )
}

export default FormContainer
