import {
  ListItemText,
  List,
  ListItem,
  makeStyles,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import React, { useRef, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import useQueryParams from '../../hooks/useQueryParams'
import useHistoryPush from '../../hooks/useHistoryPush'
import createMenuItemLinkStyle from '../../utils/helpers/createMenuItemLinkStyle'
import AddButton from '../../components/ui/AddButton/AddButton'
import MyDynamicPopover from '../../components/MyDynamicPopover/MyDynamicPopover'
import PlaysListModal from '../../components/PlaysListModal/PlaysListModal'
import PlaybookMasonry from './PlaybookMasonry/PlaybookMasonry'
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender'
import LoaderDots from '../../components/loaders/LoaderDots/LoaderDots'
import LoaderCircle from '../../components/loaders/LoaderCircle/LoaderCircle'
import PDF_VARIANTS from '../../utils/constants/pdfVariants'
import PdfDownloadButton from '../../components/PdfDownloadButton/PdfDownloadButton'
import localStorageHelper from '../../utils/helpers/localStorageHelper'
import localStorageIds from '../../utils/constants/localStorageIds'
import playbookListStyles from './playbookList.styles'
import usePlaybookQueries from '../../hooks/usePlaybookQueries'
import groupTrainingModulesByTactics from '../../utils/helpers/groupTrainingModulesByTactics'
import useTour from '../../hooks/useTour'
import TOUR_IDS from '../../utils/constants/introIds'
import IsUserRolesAuthorized from '../../components/IsUserRolesAuthorized/IsUserRolesAuthorized'
import USER_ORG_MEMBERS_ROLES from '../../utils/constants/userOrgMembersRoles'
import USER_ORG_ROLES from '../../utils/constants/userOrgRoles'

const PlaybookList = () => {
  const [plays, setPlays] = useState(null)
  const [playsDisplay, setPlaysDisplay] = useState(null)
  const [selectedGroup, setSelectedGroup] = useState('')
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
  const [drillsListModalOpen, setDrillsListModalOpen] = useState(false)
  const queryParams = useQueryParams()
  const { redirect } = useHistoryPush()
  const translate = useTranslate()
  const { localStorageValue } = localStorageHelper(localStorageIds.SELECTED_ORG)
  const useStyles = makeStyles(playbookListStyles)
  const classes = useStyles()
  const elRef = useRef()

  const NO_TACTICS = 'No Tactics'
  const SCROLL_MARGIN_TOP = 25

  const { playbook, createPlaybook, updatePlaybook } = usePlaybookQueries()

  useTour({
    elRef,
    tourName: 'playBook-tour',
    tourSteps: 'playbooks',
  })

  useEffect(() => {
    if (playbook?.data?.length) {
      const plays = playbook?.data[0]?.trainingModules

      setPlays(groupTrainingModulesByTactics(plays))
    }
  }, [playbook?.data])

  // Scroll to tactics category
  useEffect(() => {
    if (queryParams?.tacticGroup) {
      const element = document.getElementById(queryParams?.tacticGroup)
      const elementScroll = element?.offsetTop
      const mainBody = document.getElementById('playlistContainer')

      mainBody.scrollTo({
        top: elementScroll - SCROLL_MARGIN_TOP,
        behavior: 'smooth',
      })
    }
  }, [queryParams])

  // Set selected tactic group on component mount
  useEffect(() => {
    if (selectedGroup !== queryParams?.tacticGroup) {
      return setSelectedGroup(queryParams?.tacticGroup)
    }
  }, [queryParams, setSelectedGroup, selectedGroup, plays])

  useEffect(() => {
    if (plays) {
      setPlaysDisplay(Object.keys(plays).map((key) => JSON.parse(key).join(', ')))
    }
  }, [plays])

  const popoverOptions = [
    {
      label: 'Plays',
      type: '',
      onClick: () => {
        setPopoverAnchorEl(null)
        setDrillsListModalOpen(true)
      },
    },
    {
      label: 'Create new play',
      type: 'add',
      onClick: () => {
        redirect('/playbook/create')
      },
    },
  ]

  // When select an already created play to insert to playbook
  const onChoosePlay = (drill) => {
    if (!playbook?.data[0]?.id) {
      createPlaybook.mutate({ teamId: queryParams?.team, trainingModulesIds: [drill.id] })
    } else {
      updatePlaybook.mutate({
        playBookId: playbook?.data[0]?.id,
        values: { trainingModulesForAssignment: [drill.id] },
        redirectTo: 'playbook',
      })
    }
  }

  return (
    <div ref={elRef} className={classes.body}>
      <ConditionalRender renderValue={playsDisplay && playsDisplay?.length > 0}>
        <Grid className={classes.grid}>
          <div className={classes.titleContainer}>
            <Typography component='div' className={classes.title}>
              {translate('ra.text.tacticsGroups')}
            </Typography>
            <LoaderCircle loading={playbook.isLoading} className={classes.circleLoader} />
          </div>
          <List className={classes.list}>
            {playsDisplay &&
              playsDisplay.map((text) => {
                const newText = text?.length !== 0 ? text : NO_TACTICS

                return (
                  <ListItem
                    button
                    key={newText}
                    onClick={() => {
                      setSelectedGroup(newText)
                      redirect('playbook', [{ tacticGroup: newText }])
                    }}
                    style={createMenuItemLinkStyle(selectedGroup, newText)}
                  >
                    <ListItemText primary={newText} classes={{ primary: classes.listItemText }} />
                  </ListItem>
                )
              })}
          </List>
        </Grid>
      </ConditionalRender>

      <Divider light orientation='vertical' className={classes.divider} />

      <div className={classes.playsList} id='playlistContainer'>
        <div className={classes.appBar}>
          <div id={TOUR_IDS.playbooks.export}>
            <PdfDownloadButton
              pdfVariant={PDF_VARIANTS.PLAYBOOK}
              title={`${localStorageValue?.name}-playbook.pdf`}
              contentProps={{ trainingModules: playbook?.data }}
            />
          </div>
          <IsUserRolesAuthorized
            acceptedRoles={[
              USER_ORG_ROLES.OWNER,
              USER_ORG_ROLES.ADMIN,
              USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
            ]}
          >
            <AddButton
              id={TOUR_IDS.playbooks.add}
              label={translate('ra.buttons.addSmall')}
              onClick={(e) => setPopoverAnchorEl(e.currentTarget)}
              filled
            />
          </IsUserRolesAuthorized>
          <MyDynamicPopover
            open={Boolean(popoverAnchorEl)}
            onClose={() => setPopoverAnchorEl(null)}
            anchorEl={popoverAnchorEl}
            options={popoverOptions}
          />
          {drillsListModalOpen && (
            <PlaysListModal
              open={drillsListModalOpen}
              onClose={() => setDrillsListModalOpen(false)}
              onPlayClick={(element) => {
                setDrillsListModalOpen(false)
                onChoosePlay(element)
              }}
            />
          )}
        </div>
        <ConditionalRender renderValue={Boolean(plays && playsDisplay)}>
          <PlaybookMasonry
            plays={plays}
            playsDisplay={playsDisplay}
            playbookId={playbook?.data ? playbook?.data[0]?.id : ''}
            toggleAccess='private'
          />
        </ConditionalRender>
        <ConditionalRender renderValue={playbook.isFetching}>
          <Grid
            container
            justifyContent='center'
            style={{ marginTop: '100px', marginBottom: '30px' }}
          >
            <LoaderDots className={classes.loaderIcon} />
          </Grid>
        </ConditionalRender>
        <ConditionalRender
          renderValue={!playbook?.isFetching && (!playsDisplay || playsDisplay?.length === 0)}
        >
          <Grid container justifyContent='center'>
            <Typography variant='body1' className={classes.emptyMessage}>
              {translate('ra.text.noPlays')}
            </Typography>
          </Grid>
        </ConditionalRender>
      </div>
    </div>
  )
}

export default PlaybookList
