const parsePostPracticePlan = ({
  practicePlan,
  practice,
  newTrainingModule,
  closeDesignerModal,
}) => {
  const { description, practicePlanComponents } = practicePlan

  const newComponent = {
    duration: 0,
    order: practicePlan?.practicePlanComponents
      ? Math.max(...practicePlan?.practicePlanComponents.map((comp) => comp.order)) + 1
      : 0,
    notes: '',
    trainingModuleId: newTrainingModule?.id,
  }

  return {
    values: {
      eventId: practice?.id,
      description:
        description ??
        `Practice Plan ${new Date(practice.startDateTime).toLocaleDateString('en-GB')}`,
      components: practicePlanComponents
        ? [...practicePlanComponents, newComponent]
        : [newComponent],
    },
    closeDesignerModal,
  }
}

export default parsePostPracticePlan
