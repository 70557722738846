import React from 'react'
import { useSelector } from 'react-redux'
import { Sidebar } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import useAppBarHeight from '../../../../hooks/useAppBarHeight'
import MyProvidedMenu from '../MyMenu/MyMenu'

const MySidebar = (props) => {
  const open = useSelector((state) => state.admin.ui.sidebarOpen)
  const appBarHeight = useAppBarHeight()
  const useStyles = makeStyles({
    root: {
      borderRight: '1px solid #E8E8E8',
      background: '#FFFFFF',
      height: `calc(100dvh - ${appBarHeight}px)`,

      // This is only for firefox
      '@supports (-moz-appearance: none)': {
        height: `calc(100% - ${appBarHeight}px)`,
      },
    },
    drawerPaper: {
      width: open ? '255px' : '60px',
    },
    fixed: {
      background: '#FFFFFF',
      height: `calc(100dvh - ${appBarHeight}px)`,

      // This is only for firefox
      '@supports (-moz-appearance: none)': {
        height: `calc(100% - ${appBarHeight}px)`,
      },
    },
  })
  const classes = useStyles()

  return (
    <Sidebar {...props} classes={classes}>
      <MyProvidedMenu {...props} />
    </Sidebar>
  )
}

export default MySidebar
