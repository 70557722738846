import React from 'react'
import { useRecordContext } from 'react-admin'
import { Chip, makeStyles } from '@material-ui/core'
import teamFieldStyles from './teamField.styles'
import sortByStringValueAscending from '../../../../utils/helpers/sortByStringValueAscending'

const TeamField = ({ source }) => {
  const record = useRecordContext()
  const useStyles = makeStyles(teamFieldStyles)
  const classes = useStyles()

  if (!record || !record[source]) return <span>-</span>

  return sortByStringValueAscending(record[source], 'name').map((team) => (
    <Chip label={team.name} key={team.team} classes={{ root: classes.chip }} />
  ))
}

export default TeamField
