import COLORS from '../../utils/constants/colors'

const liveStreamPlayerStyles = (isLightMode) => ({
  mainContent: {
    height: 'calc(100vh - 60px)!important',
    backgroundColor: !isLightMode && COLORS.menuChoiceGrey,
    transition: 'background-color 0.3s ease',
  },
  liveVideo: {
    position: 'relative',
    color: !isLightMode && COLORS.white,
    transition: 'color 0.3s ease',
    height: 'inherit',
  },
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  loaderText: {
    marginTop: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loaderIcon: {
    fill: COLORS.orange,
    marginRight: '48%',
    marginLeft: '48%',
    marginTop: 20,
    width: 70,
    height: 70,
  },
})

export default liveStreamPlayerStyles
