const GreyLabelsWithComma = ({ values, className }) => {
  if (!values || values.length === 0) return null

  return (
    <div className={className}>
      {values.map((value) => (value.displayName ? value.displayName : value.name)).join(', ')}
    </div>
  )
}

export default GreyLabelsWithComma
