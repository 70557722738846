import COLORS from '../../utils/constants/colors'

const courtLogosPreviewEditBoxStyles = (hovered, isDisabled, iconSize) => ({
  wrapper: {
    cursor: !isDisabled ? 'pointer' : 'default',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgContainer: {
    width: '100%',
    height: '100%',
    opacity: hovered && !isDisabled ? 0.6 : 1,
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'bottom',
  },
  editIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: iconSize ?? '0.65vw',
    height: iconSize ?? '0.65vw',
    padding: 3,
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: COLORS.orange,
    fill: COLORS.white,
  },
  addPhotoIcon: {
    width: '55%',
    height: '55%',
  },
})

export default courtLogosPreviewEditBoxStyles
