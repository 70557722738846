import * as React from 'react'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { makeStyles } from '@material-ui/core'
import COLORS from '../../utils/constants/colors'

const AddIcon = (props) => {
  const { filled, ...restProps } = props
  const useStyles = makeStyles({
    addIcon: {
      width: 21,
      height: 21,
      fill: filled ? COLORS.white : COLORS.orange,
      cursor: 'pointer',
    },
  })
  const classes = useStyles()

  return <AddCircleIcon {...restProps} classes={{ root: classes.addIcon }} />
}

export default AddIcon
