import * as React from 'react'
import { makeStyles, TextField } from '@material-ui/core'
import simpleTextFieldStyles from './simpleTextField.styles'

const SimpleTextField = (props) => {
  const useStyles = makeStyles(simpleTextFieldStyles)
  const classes = useStyles()

  return (
    <TextField
      {...props}
      variant='filled'
      color='secondary'
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.root,
          input: classes.fieldInput,
          multiline: classes.multiline,
        },
      }}
    />
  )
}

export default SimpleTextField
