import * as React from 'react'
import { Dialog, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import deleteConfigurationModalStyles from './deleteConfirmationModal.styles'
import ButtonWithLoader from '../ButtonWithLoader/ButtonWithLoader'
import TextButton from '../TextButton/TextButton'
import USER_ORG_ROLES from '../../../utils/constants/userOrgRoles'
import IsUserRolesAuthorized from '../../IsUserRolesAuthorized/IsUserRolesAuthorized'
import MyTextField from '../../form/fields/MyTextField'

const DeleteConfirmationModal = (props) => {
  const {
    isModalOpen,
    onClose,
    title,
    subtitle,
    comparisonValue,
    buttonLabel,
    textFieldLabel,
    onSubmit,
    isLoading,
    importantMessage,
  } = props
  const translate = useTranslate()
  const useStyles = makeStyles(deleteConfigurationModalStyles)
  const classes = useStyles()

  return (
    <Dialog open={isModalOpen} onClose={onClose} disableScrollLock>
      <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
        <span className={classes.title}>{title}</span>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <Formik initialValues={{ name: '' }} onSubmit={onSubmit}>
          {(formikProps) => (
            <Form>
              <div className={classes.formWrapper}>
                <Typography variant='subtitle1' className={classes.subtitle}>
                  {subtitle}
                </Typography>
                <IsUserRolesAuthorized acceptedRoles={[USER_ORG_ROLES.OWNER]}>
                  <Typography variant='subtitle1' className={classes.ownerMessage}>
                    {importantMessage}
                  </Typography>
                </IsUserRolesAuthorized>
                <MyTextField label={textFieldLabel} name='name' required />
              </div>
              <div className={classes.buttonWrapper}>
                <TextButton
                  className={classes.cancelButton}
                  onClick={onClose}
                  label={translate('ra.buttons.cancel')}
                />
                <ButtonWithLoader
                  className={classes.deleteButton}
                  label={buttonLabel}
                  disabled={!comparisonValue ? false : formikProps.values.name !== comparisonValue}
                  loading={isLoading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

DeleteConfirmationModal.propTypes = {
  isModalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  comparisonValue: PropTypes.string,
  buttonLabel: PropTypes.string,
  textFieldLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  importantMessage: PropTypes.string,
}

export default DeleteConfirmationModal
