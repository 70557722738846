import axiosInstance from '../axiosInterceptor/axiosInstance'

const addOrg = async (data) => {
  const { name, countryId, city, logo, type, scopes } = data
  const url = '/clubs'

  const requestBody = {
    name,
    countryId,
    city,
    logo,
    type,
    scopes,
  }

  return axiosInstance.post(url, requestBody)
}

export default addOrg
