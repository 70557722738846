const RenderEventRange = ({ start, end }) => {
  if (!start || !end) return null
  const startDate = new Date(start).toLocaleDateString('en-GB', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  })
  const endDate = new Date(end).toLocaleDateString('en-GB', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  })
  const startTime = new Date(start).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  })
  const endTime = new Date(end).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })

  return <span>{`${startDate} ${startTime} - ${endDate} ${endTime}`}</span>
}

export default RenderEventRange
