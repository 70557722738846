import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import DATE_TIME_FIELD_STYLES from '../../../utils/constants/CSS/dateTimefieldStyles'
import CalendarIcon from '../../../assets/icons/calendarIcon'

const MyDateTimeField = (props) => {
  const { name, required } = props
  const [field, meta, helpers] = useField(name)
  const { value } = field
  const { error } = meta
  const { setValue } = helpers
  const useStyles = makeStyles(DATE_TIME_FIELD_STYLES)
  const classes = useStyles()

  const isError = error || (!value && required)

  return (
    <KeyboardDateTimePicker
      {...props}
      variant='inline'
      inputVariant='filled'
      format='dd-MM-yyyy hh:mm'
      fullWidth
      ampm={false}
      value={value}
      error={isError}
      onChange={(v) => setValue(v)}
      keyboardIcon={<CalendarIcon />}
      KeyboardButtonProps={{
        classes: {
          root: classes.icon,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          input: classes.input,
        },
        disableUnderline: true,
      }}
      InputLabelProps={{
        classes: {
          formControl: classes.formControl,
          shrink: classes.shrink,
          focused: classes.focused,
        },
      }}
    />
  )
}

MyDateTimeField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
}

export default MyDateTimeField
