export const selectRenderValueStyles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    width: 28,
    height: 28,
    marginRight: 10,
    borderRadius: '50%',
  },
  orgIcon: {
    width: '30px',
    height: '30px',
    marginRight: 10,
  },
}
