import React, { createContext, useContext, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useRouteMatch } from 'react-router'
import { useNotify, useTranslate } from 'react-admin'
import QueryKeys from '../utils/constants/queryKeys'
import getEventTypes from '../Apis/event/getEventTypes'
import createEvent from '../Apis/event/createEvent'
import getEvent from '../Apis/event/getEvent'
import deleteEvent from '../Apis/event/deleteEvent'
import updateEvent from '../Apis/event/updateEvent'
import getTeams from '../Apis/team/getTeams'
import getEvents from '../Apis/event/getEvents'
import parseEvents from '../utils/helpers/eventHelpers/parseEvents'
import useQueryParams from '../hooks/useQueryParams'
import useHistoryPush from '../hooks/useHistoryPush'
import getCourts from '../Apis/courts/getCourts'
import { EVENT_TYPE_FORMS } from '../utils/constants/eventTypeForms'
import filterTeamsByRole from '../utils/helpers/filterTeamsByRole'
import USER_ORG_MEMBERS_ROLES from '../utils/constants/userOrgMembersRoles'
import USER_ORG_ROLES from '../utils/constants/userOrgRoles'
import usePrevious from '../hooks/usePrevious'
import orgIsInSubscriptionState from '../utils/helpers/orgIsInSubscriptionState'
import SUBSCRIPTION_STATES from '../utils/constants/subscriptionStates'
import ConnectionErrorPage from '../pages/ErrorInConnection/ConnectionErrorPage'

const TeamCalendarContext = createContext()

const TeamCalendarProvider = ({ children }) => {
  const [createEventDrawerOpen, setCreateEventDrawerOpen] = useState(false)
  const [selectedEventType, setSelectedEventType] = useState()
  const [dates, setDates] = useState()
  const [dateClick, setDateClick] = useState()
  const [events, setEvents] = useState()
  const [coordinatesModal, setCoordinatesModal] = useState({ x: 0, y: 0 })
  const [formDirty, setFormDirty] = useState()
  const eventId = useRouteMatch('/calendar/:id')?.params?.id
  const eventTeamId = useRouteMatch('/teamCalendar/:id')?.params?.id
  const [clickedEventId, setClickedEventId] = useState(eventId || eventTeamId || '')
  const translate = useTranslate()
  const notify = useNotify()
  const queryClient = useQueryClient()
  const queryParams = useQueryParams()
  const { org, team } = queryParams
  const { redirect, pathname } = useHistoryPush()
  const { OWNER, ADMIN } = USER_ORG_ROLES
  const { TEAM_MANAGER } = USER_ORG_MEMBERS_ROLES

  useEffect(() => {
    if (eventId || eventTeamId) {
      setCreateEventDrawerOpen(true)
    }
  }, [eventId, eventTeamId])

  const getTypeOfClickedEvent = (allEvents, eventId) => {
    if (!allEvents) return null
    const event = allEvents.find((e) => e.id === eventId)
    if (!event?.type) return null
    return event.type
  }

  const getAllEvents = useQuery(
    [QueryKeys.GET_EVENTS, dates, org, team],
    () =>
      getEvents({
        ...(team && { teams: team }),
        ...(!team && { org }),
        startDateTime: dates?.start.toISOString(),
        endDateTime: dates?.end.toISOString(),
      }),
    {
      retry: 4,
      enabled: Boolean(dates?.start),
      onSuccess: (resp) => {
        setEvents(resp)
      },
    },
  )

  const event = useQuery(
    [QueryKeys.GET_EVENT, clickedEventId],
    () =>
      getEvent({
        eventTypeCode: getTypeOfClickedEvent(getAllEvents?.data, clickedEventId).code,
        eventId: clickedEventId,
      }),
    {
      enabled: Boolean(clickedEventId && getAllEvents?.data),
    },
  )

  const eventTypes = useQuery([QueryKeys.GET_EVENT_TYPES], () => org && getEventTypes(org), {
    onSuccess: (response) => {
      if (queryParams?.eventType) {
        return setSelectedEventType(response.find((type) => type.code === queryParams?.eventType))
      }

      return setSelectedEventType(response.find((type) => type.code === EVENT_TYPE_FORMS.GAME))
    },
  })

  const teams = useQuery([QueryKeys.GET_TEAMS, org || null], () => org && getTeams(org))

  const handleCreateError = (error) => {
    if (error.response.status === 413) {
      notify(translate('ra.errorMessages.logosLargePayloadError'), 'error', undefined, false, 3000)
    } else {
      notify(
        `Error ${error?.response?.status}: ${
          error?.response?.data?.Message ?? error?.response?.statusText
        }`,
        'error',
        undefined,
        false,
        3000,
      )
    }
  }

  const createAnEvent = useMutation((data) => createEvent(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_EVENTS)
      setCreateEventDrawerOpen(false)
      redirect(pathname, [], ['breadcrumb', 'eventType'])
    },
    onError: (err) => handleCreateError(err),
  })

  const deleteAnEvent = useMutation((eventId) => deleteEvent(eventId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_EVENTS)
      setCreateEventDrawerOpen(false)
    },
  })

  const updateAnEvent = useMutation((data) => updateEvent(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_EVENTS)
      queryClient.invalidateQueries(QueryKeys.GET_EVENT)
      queryClient.invalidateQueries(QueryKeys.GET_RUNNING_BROADCASTS_EVENTS)
      setCreateEventDrawerOpen(false)
      redirect(`/${pathname.split('/')[1]}`, [], ['eventType'])
    },
    onError: (err) => handleCreateError(err),
  })

  const isUpdatingPrevious = usePrevious(updateAnEvent?.isLoading)

  // After update an event, clear clickedEventId
  useEffect(() => {
    if (isUpdatingPrevious && !updateAnEvent?.isLoading) {
      setClickedEventId('')
    }
  }, [updateAnEvent?.isLoading, isUpdatingPrevious])

  const courts = useQuery([QueryKeys.GET_COURTS, org], () => getCourts(org))

  const currentTeam =
    teams && teams.data && teams.data.length > 0 && teams.data.find((t) => t.id === team)

  const EVENT_TYPES_FILTER = useMemo(
    () => [
      {
        // Event types request is not finished or failed
        condition: () => !eventTypes.data,
        action: () => {
          return []
        },
      },
      {
        // Organidation is in inactive subscription status
        condition: () => orgIsInSubscriptionState([SUBSCRIPTION_STATES.INACTIVE]),
        action: () => {
          return eventTypes.data.slice(0, 2)
        },
      },
      {
        // Otherwise, everything gone when
        condition: () => true,
        action: () => {
          return eventTypes.data
        },
      },
    ],
    [eventTypes.data],
  )

  const value = useMemo(
    () => ({
      // Wrap the value object creation in useMemo
      createEventDrawerOpen,
      setCreateEventDrawerOpen,
      selectedEventType,
      setSelectedEventType,
      clickedEventId,
      setClickedEventId,
      coordinatesModal,
      setCoordinatesModal,
      formDirty,
      setFormDirty,
      translate,
      currentTeam,
      event: event?.data,
      // If organisation is on Inactive subscription state, user with both coach and livestream scope can only see what the livestream scope provides
      eventTypes: EVENT_TYPES_FILTER.find((rule) => rule.condition()).action(),
      createEvent: createAnEvent,
      deleteEvent: deleteAnEvent,
      updateEvent: updateAnEvent,
      // If organisation is on Inactive subscription state, user with both coach and livestream scope can only see what the livestream scope provides
      events: orgIsInSubscriptionState(SUBSCRIPTION_STATES.INACTIVE)
        ? parseEvents(
            events?.filter((ev) => ev?.type.code === 'game' || ev?.type.code === 'pract'),
            team,
          )
        : parseEvents(events, team),
      eventsRaw: events,
      setDates,
      dateClick,
      setDateClick,
      setEvents,
      teams: !teams.isLoading ? filterTeamsByRole(teams?.data, [OWNER, ADMIN, TEAM_MANAGER]) : [],
      eventLoading: getAllEvents.isFetching,
      courts: courts?.data?.courts,
    }),
    [
      createEventDrawerOpen,
      selectedEventType,
      clickedEventId,
      coordinatesModal,
      formDirty,
      translate,
      currentTeam,
      event?.data,
      EVENT_TYPES_FILTER,
      createAnEvent,
      deleteAnEvent,
      updateAnEvent,
      events,
      team,
      dateClick,
      teams.isLoading,
      teams?.data,
      OWNER,
      ADMIN,
      TEAM_MANAGER,
      getAllEvents.isFetching,
      courts?.data?.courts,
    ],
  )

  return (
    <TeamCalendarContext.Provider value={value}>
      {getAllEvents.isError ? <ConnectionErrorPage /> : children}
    </TeamCalendarContext.Provider>
  )
}
TeamCalendarProvider.propTypes = {
  children: PropTypes.node.isRequired, // Add prop validation for 'children'
}

const useTeamCalendarContext = () => {
  const context = useContext(TeamCalendarContext)
  if (context === undefined) {
    throw new Error('useTeamCalendarContext must be used within a TeamCalendarProvider')
  }
  return context
}

export { TeamCalendarProvider, useTeamCalendarContext }
