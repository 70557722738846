import { makeStyles } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useQuery } from 'react-query'
import { v4 } from 'uuid'
import MyAutocomplete from '../../../../components/form/fields/MyAutocomplete'
import MyAdjustableFields from '../../../../components/form/fields/MyAdjustableFields/MyAdjustableFields'
import setRolesMemberFieldsStyles from './setRolesMemberFields.styles'
import QueryKeys from '../../../../utils/constants/queryKeys'
import getOrgRoles from '../../../../Apis/organisation/getOrgRoles'
import getTeams from '../../../../Apis/team/getTeams'
import useQueryParams from '../../../../hooks/useQueryParams'
import reformatRoleNames from '../../../../utils/helpers/reformatRoleNames'
import USER_ORG_ROLES from '../../../../utils/constants/userOrgRoles'
import { USER_SCOPES } from '../../../../utils/constants/userScopes'
import orgIsInSubscriptionState from '../../../../utils/helpers/orgIsInSubscriptionState'
import SUBSCRIPTION_STATES from '../../../../utils/constants/subscriptionStates'

const SetRolesMemberFields = (props) => {
  const { orgScopeOptions, index, adminOnlyOption } = props
  const [showAdjustableFields, setShowAdjustableFields] = useState(false)
  const { values, setFieldValue } = useFormikContext()
  const { scopes, roles } = values?.invitations[index] || {}
  const queryParams = useQueryParams()
  const { org } = queryParams
  const translate = useTranslate()
  const useStyles = makeStyles(setRolesMemberFieldsStyles)
  const classes = useStyles()

  const { data: orgRoles } = useQuery([QueryKeys.GET_ORG_ROLES], () => getOrgRoles())

  const { data: teams } = useQuery([QueryKeys.GET_TEAMS], () => getTeams(org))

  const onRoleChange = (e, setRoleFieldValue) => {
    const selectedValue = e.target.textContent
    setShowAdjustableFields(selectedValue === 'Member')
    setFieldValue(`invitations[${index}].isAdmin`, selectedValue === 'Admin')
    setRoleFieldValue !== 'Member' &&
      setFieldValue(`invitations[${index}].roles`, [{ key: v4(), Team: '', Role: '' }])
  }

  return (
    <>
      <div className={classes.formContainer}>
        {orgScopeOptions && orgScopeOptions?.length > 1 && (
          <Field
            className={classes.formItem}
            name={`invitations[${index}].scopes`}
            id={`invitations[${index}].scopes`}
            label={translate('ra.formFields.userPermissions')}
            multiple
            required
            autoSelect={false}
            component={MyAutocomplete}
            options={
              // If org is on Inactive or grace subscription state, owner cannot invite coach members. In future probably will get co,trolled by be
              orgIsInSubscriptionState([SUBSCRIPTION_STATES.INACTIVE, SUBSCRIPTION_STATES.GRACE])
                ? orgScopeOptions.filter(
                    (option) => !scopes?.includes(option) && option !== USER_SCOPES.COACH,
                  )
                : orgScopeOptions.filter((option) => !scopes?.includes(option))
            }
            fullWidth
          />
        )}
        <Field
          className={classes.formItem}
          name={`invitations[${index}].isAdmin`}
          id={`invitations[${index}].isAdmin`}
          label={translate('ra.formFields.OrgRole')}
          variant='filled'
          options={
            adminOnlyOption ? [USER_ORG_ROLES.ADMIN] : Object.values(USER_ORG_ROLES).slice(2)
          }
          component={MyAutocomplete}
          onChange={(e) => onRoleChange(e, setFieldValue)}
          autoSelect={false}
          required
        />
      </div>

      {showAdjustableFields && (
        <MyAdjustableFields
          ParentListIndex={index}
          ParentList='invitations'
          parentField='roles'
          fieldsNumber={2}
          labels={[translate('ra.formFields.team'), translate('ra.formFields.role')]}
          keys={['Team', 'Role']}
          options={[
            teams?.filter(
              (team) => !roles.map((role) => (role?.Team ? role?.Team.id : '')).includes(team.id),
            ) || [],
            orgRoles ? reformatRoleNames(orgRoles.slice(2)) : [],
          ]}
          isFirstRequired={showAdjustableFields}
        />
      )}
    </>
  )
}

export default SetRolesMemberFields
