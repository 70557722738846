import COLORS from '../../../utils/constants/colors'

const orgTypeChooseModalStyles = {
  card: {
    width: '100%',
    paddingTop: '80%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${COLORS.grey}`,
    cursor: 'pointer',
    transition: 'border-color 0.3s ease-in-out, color 0.3s ease-in-out',
    margin: 'auto',
    overflow: 'hidden',
    '&:hover': {
      borderColor: COLORS.orange,
      color: COLORS.orange,
    },
  },
  cardContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    textAlign: 'center',
    height: '100%',
    padding: '16px',
    boxSizing: 'border-box',
  },
}

export default orgTypeChooseModalStyles
