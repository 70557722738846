import COLORS from '../../../utils/constants/colors'

const createEventModalStyles = {
  dialog: {
    position: 'absolute',
    right: -7,
    top: 67,
    width: 185,
    height: 'fit-content',
    padding: 0,
  },
  dialogContentRoot: {
    '&&&': {
      padding: '0px',
      overflowY: 'hidden',
    },
  },
  listRoot: {
    padding: '0px',
    margin: '8px 0 8px 0',
    height: '100%',
    width: '100%',
  },
  listItemRoot: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.hoverGrey,
    },
  },
}

export default createEventModalStyles
