import React from 'react'
import { Stepper, Step, StepLabel } from '@material-ui/core'
import PropTypes from 'prop-types'

const MyWizard = ({ steps, activeStep }) => {
  return (
    <Stepper activeStep={activeStep}>
      {steps?.length &&
        steps.map((label) => {
          const stepProps = {}
          const labelProps = {}
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
    </Stepper>
  )
}

MyWizard.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string),
  activeStep: PropTypes.number,
}

export default MyWizard
