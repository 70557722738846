import { shape, string, arrayOf } from 'prop-types'
import broadcastType from '../broadcastType'
import enumIdType from '../enumIdType'
import teamSmallType from '../teamSmallType'
import eventVariantSmallType from './eventVariantSmallType'

const eventType = shape({
  broadcast: broadcastType,
  court: enumIdType,
  endDateTime: string,
  id: string,
  notes: string,
  place: string,
  startDateTime: string,
  teams: arrayOf(teamSmallType),
  title: string,
  type: eventVariantSmallType,
})

export default eventType
