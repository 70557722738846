import React from 'react'
import { DrillShowProvider } from '../../../context/useDrillShowContext'
import DrillShowBody from '../DrillShowBody/DrillShowBody'

const DrillShowPage = (props) => (
  <DrillShowProvider>
    <DrillShowBody {...props} />
  </DrillShowProvider>
)

export default DrillShowPage
