import axiosInstance from '../axiosInterceptor/axiosInstance'
import mapPracticePlayers from '../../utils/helpers/practicePlayersMapper/mapPracticePlayers'

const updatePracticePlayers = async (data) => {
  const { practicePlayersId, eventId, players, teams } = data
  const mappedPracticePlayers = mapPracticePlayers(eventId, players, teams)
  const url = `/practice-players/${practicePlayersId}`
  return axiosInstance.patch(url, mappedPracticePlayers)
}

export default updatePracticePlayers
