import axiosInstance from '../axiosInterceptor/axiosInstance'

const getPracticePlan = async (data) => {
  const { practicePlanId } = data
  const url = `/practice-plans/${practicePlanId}`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getPracticePlan
