import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import textButtonStyles from './textButton.styles'

const TextButton = (props) => {
  const { label, secondary, disabled, ...rest } = props
  const useStyles = makeStyles(textButtonStyles)
  const classes = useStyles()

  return (
    <Button
      {...rest}
      color={secondary ? 'secondary' : 'primary'}
      disabled={disabled}
      classes={{ root: classes.button }}
    >
      {label}
    </Button>
  )
}

TextButton.propTypes = {
  label: PropTypes.string,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default TextButton
