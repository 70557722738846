const playbookPdfHeader = () => [
  {
    style: 'playbookHeaderLogo',
    svg:
      '<svg width="95" height="88" viewBox="0 0 95 88" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M0.945587 -6.91308e-06C8.45707 1.63886 15.2755 4.78035 19.7497 10.917C28.5065 22.9135 24.2971 41.5659 34.4796 52.3589C45.0061 63.5188 63.9397 59.3995 77.4131 66.7001C85.6065 71.1358 90.5371 78.9394 95 87.3362V-6.91308e-06H0.945587Z" fill="#7D66FC"/>\n' +
      '</svg>\n',
  },
]

export default playbookPdfHeader
