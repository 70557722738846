import React from 'react'
import PropTypes from 'prop-types'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import notificationListStyles from './notificationListItemStyles'
import mapNotification from '../../utils/constants/NotificationTypesMapper'

const NotificationItem = ({ notification, onClick }) => {
  const translate = useTranslate()
  const useStyles = makeStyles(notificationListStyles)
  const classes = useStyles()
  const { data, isRead, type, timestamp } = notification

  // Parse the information string into a JavaScript object
  const informationObject = JSON.parse(data)
  const date = new Date(timestamp)

  const notificationType = mapNotification(type)

  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = String(date.getFullYear()).slice(-2)
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  const formattedDate = `${day}/${month}/${year} in ${hours}:${minutes}`

  return (
    <ListItem
      button
      onClick={() => onClick(notification)}
      className={`${classes.notificationListItem} ${isRead ? classes.read : classes.unread} ${
        classes.hover
      }`}
    >
      <ListItemText
        primary={
          <div className={classes.notificationHeader}>
            {/* Display bullet if notification is not read */}
            {!isRead && <div className={classes.bullet} />}
            {/* Display notification type */}
            <span className={classes.notificationType}>
              {translate(`ra.notification.${notificationType}`)}
            </span>
            {/* Display date and time */}
            <span className={classes.dateTime}>{formattedDate}</span>
          </div>
        }
        secondary={
          <div className={classes.notificationContent}>
            <span className={classes.secondaryText}>
              <span className={classes.boldText}> {informationObject.Sender}</span>{' '}
              {translate(`ra.invitation.pending`)}{' '}
              <span className={classes.boldText}>
                {notificationType === 'invitation' ? `${informationObject.Club}.` : ' day(s).'}
              </span>
            </span>
          </div>
        }
      />
    </ListItem>
  )
}

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired,
    isRead: PropTypes.bool.isRequired,
    type: PropTypes.number.isRequired,
    timestamp: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default NotificationItem
