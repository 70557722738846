import axiosInstance from '../axiosInterceptor/axiosInstance'

const getRecentLivestreams = async (data) => {
  const { orgId, limit } = data
  const url = `/livestreams/recent?clubId=${orgId}&limit=${limit}`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getRecentLivestreams
