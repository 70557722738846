import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import useTeamsSettings from '../../../hooks/useTeamsSettings'
import AddTeamForm from '../../form/forms/AddTeamForm/AddTeamForm'
import ContainerPadding from '../../containerPadding/containerPadding'
import addTeamModalStyles from './addTeamModal.styles'
import DialogWithCloseIcon from '../../DialogWithCloseIcon/DialogWithCloseIcon'
import mutationType from '../../../types/mutationType'
import DefaultOrgIcon from '../../../assets/icons/DefaultOrgIcon'

const AddTeamModal = (props) => {
  const { showAddTeamModal, handleCloseModal, addTeam } = props
  const useStyles = makeStyles(addTeamModalStyles)
  const classes = useStyles()
  const { singleOrgName, singleOrgLogo, gender, ageRestriction, teamLogo, orgId } =
    useTeamsSettings()

  return (
    <DialogWithCloseIcon
      open={showAddTeamModal}
      dialogWidth='650px'
      onClose={handleCloseModal}
      dialogTitle={
        <Typography component='div' variant='h6' style={{ fontWeight: 'bold' }}>
          Add Team
        </Typography>
      }
    >
      <Grid container alignItems='center' direction='column'>
        <Grid item>
          {singleOrgLogo ? (
            <img src={singleOrgLogo} alt='avatar' className={classes.orgLogo} />
          ) : (
            <DefaultOrgIcon className={classes.emptyOrg} />
          )}
        </Grid>
        <Grid item classes={{ root: classes.orgName }}>
          {singleOrgName}
        </Grid>
      </Grid>
      <ContainerPadding paddingValue='1%'>
        <AddTeamForm
          gender={gender}
          ageRestriction={ageRestriction}
          teamLogo={teamLogo}
          orgId={orgId}
          addTeam={addTeam}
        />
      </ContainerPadding>
    </DialogWithCloseIcon>
  )
}

AddTeamModal.propTypes = {
  showAddTeamModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  addTeam: mutationType,
}

export default AddTeamModal
