import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core'
import PropTypes from 'prop-types'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { selectTeamStyles } from './selectTeam.styles'
import { useMyLayout } from '../../context/useMyLayout'
import AddTeamModal from '../ui/AddTeamModal/AddTeamModal'
import useQueryParams from '../../hooks/useQueryParams'
import AddButton from '../ui/AddButton/AddButton'
import ExpandIcon from '../../assets/icons/expandIcon'
import useHistoryPush from '../../hooks/useHistoryPush'
import orgType from '../../types/orgType'
import localStorageIds from '../../utils/constants/localStorageIds'
import IsUserRolesAuthorized from '../IsUserRolesAuthorized/IsUserRolesAuthorized'
import USER_ORG_ROLES from '../../utils/constants/userOrgRoles'
import isUserOrgRolesValid from '../../utils/helpers/isUserOrgRolesValid'
import useGetLocalStorage from '../../hooks/useGetLocalStorage'
import {
  removeLocalStorageValue,
  setLocalStorageValue,
} from '../../utils/helpers/localStorageHelperMethods'
import SelectRenderValue from '../SelectRenderValue/SelectRenderValue'

const SelectTeam = ({ myOrgs, showCreateOrgTeamModal, error }) => {
  const [teamSelect, setTeamSelect] = useState('')
  const { teams, orgId, addTeam, showAddTeamModal, setShowAddTeamModal } = useMyLayout()
  const queryParams = useQueryParams()
  const translate = useTranslate()
  const localStorageValue = useGetLocalStorage()
  const { [localStorageIds.SELECTED_TEAM]: selectedTeam } = localStorageValue || {}
  const { redirect } = useHistoryPush()
  const useStyles = makeStyles(selectTeamStyles)
  const classes = useStyles()

  // Set team from browser's local storage if exists
  useEffect(() => {
    if (queryParams?.team && teams?.length > 0) {
      const oldUrlTeamId = queryParams?.team || selectedTeam?.id
      const newTeam = teams.find((t) => t.id === oldUrlTeamId)
      setTeamSelect(newTeam)
      setLocalStorageValue([
        { id: localStorageIds.SELECTED_TEAM, value: teams.find((t) => t.id === queryParams.team) },
      ])
    } else {
      setTeamSelect('')
      removeLocalStorageValue([localStorageIds.SELECTED_TEAM])
    }
  }, [queryParams, teams])

  const handleChange = (e) => {
    setTeamSelect(e.target.value)
    if (e.target.value === 'addTeam') {
      setShowAddTeamModal(true)
    } else {
      const changedSelectedTeam = teams.find((t) => t.name === e.target.value)
      setLocalStorageValue([{ id: localStorageIds.SELECTED_TEAM, value: changedSelectedTeam }])
      redirect('/teamCalendar', [{ org: orgId }, { team: changedSelectedTeam?.id }])
    }
  }

  return (
    <>
      {teams && teams.length ? (
        <FormControl variant='filled'>
          <Select
            id='teamSelect'
            classes={{ select: classes.select, filled: classes.selectInput }}
            value={teamSelect}
            displayEmpty
            disableUnderline
            IconComponent={ExpandIcon}
            onChange={handleChange}
            renderValue={(item) =>
              item ? (
                <SelectRenderValue
                  item={item}
                  chosenOption={teamSelect}
                  logoArgName='teamLogo'
                  labelBlack
                />
              ) : (
                <div>{translate('ra.buttons.selectTeam')}</div>
              )
            }
            style={{
              height: '40px',
              width: '170px',
              backgroundColor: '#f5f5f5',
              marginRight: 15,
              borderRadius: '5px',
            }}
            disabled={error}
          >
            {teams?.map((item) => (
              <MenuItem
                key={item.id}
                value={item.name}
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.menuItemSelected,
                }}
              >
                <SelectRenderValue item={item} chosenOption={teamSelect} logoArgName='teamLogo' />
              </MenuItem>
            ))}
            {isUserOrgRolesValid([USER_ORG_ROLES.OWNER]) && (
              <MenuItem value='addTeam' style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AddCircleIcon
                    style={{ width: '30', height: '30', marginRight: 10, fill: '#E0726E' }}
                  />
                  <span style={{ color: '#555555', fontSize: '90%' }}>
                    {translate('ra.buttons.addTeam')}
                  </span>
                </div>
              </MenuItem>
            )}
          </Select>
        </FormControl>
      ) : (
        <IsUserRolesAuthorized acceptedRoles={[USER_ORG_ROLES.OWNER]}>
          <AddButton
            onClick={() => {
              if (myOrgs && myOrgs?.length > 0) {
                return setShowAddTeamModal(true)
              }
              return showCreateOrgTeamModal()
            }}
            style={{ marginRight: 10 }}
            label={translate('ra.buttons.addTeam')}
            disabled={error}
          />
        </IsUserRolesAuthorized>
      )}
      <AddTeamModal
        showAddTeamModal={showAddTeamModal}
        handleCloseModal={() => setShowAddTeamModal(false)}
        addTeam={addTeam}
      />
    </>
  )
}

SelectTeam.propTypes = {
  myOrgs: PropTypes.arrayOf(orgType),
  showCreateOrgTeamModal: PropTypes.func,
}

export default SelectTeam
