import React from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core'
import CalendarIcon from '../../../assets/icons/calendarIcon'
import TEXT_INPUT_STYLES from '../../../utils/constants/CSS/textInputStyles'

const MyDateField = (props) => {
  const { source, label, disabled } = props
  const {
    input: { onChange, value },
    meta: { error },
  } = useField(source)

  const useStyles = makeStyles(TEXT_INPUT_STYLES)
  const classes = useStyles()

  return (
    <KeyboardDatePicker
      variant='inline'
      disabled={disabled}
      label={label}
      inputVariant='filled'
      format='dd/MM/yyyy'
      fullWidth
      value={!value ? null : value}
      error={error}
      helperText={error}
      onChange={(v) => onChange(v)}
      keyboardIcon={<CalendarIcon />}
      KeyboardButtonProps={{
        classes: {
          root: classes.icon,
          label: classes.iconLabel,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          input: classes.input,
        },
        disableUnderline: true,
      }}
      InputLabelProps={{
        classes: {
          formControl: classes.formControl,
          shrink: classes.shrink,
          focused: classes.focused,
        },
      }}
    />
  )
}

MyDateField.propTypes = {
  source: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
}

export default MyDateField
