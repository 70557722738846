import React from 'react'

const ContainerPadding = (props) => {
  const { children, className, style, paddingValue, vertical, padding } = props
  const DEFAULT_PADDING = '2.5%'

  const styles = {
    ...style,
    paddingLeft: paddingValue || DEFAULT_PADDING,
    paddingRight: paddingValue || DEFAULT_PADDING,
    paddingTop: vertical ? paddingValue || DEFAULT_PADDING : '',
    paddingBottom: vertical ? paddingValue || DEFAULT_PADDING : '',
    ...(padding && { padding }),
  }

  return (
    <div className={className} style={styles}>
      {children}
    </div>
  )
}

export default ContainerPadding
