import COLORS from '../../../utils/constants/colors'

const livestreamPlayerToolbarStyles = (isLightMode) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 60,
    backgroundColor: !isLightMode && COLORS.menuChoiceGrey,
  },
  leftButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  rightButtons: {
    display: 'flex',
    marginRight: 20,
    gap: 15,
  },
})

export default livestreamPlayerToolbarStyles
