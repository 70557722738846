import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import NoLayoutContainer from '../../../components/layout/NoLayoutContainer/NoLayoutContainer'
import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm'
import resetPasswordPageStyles from './resetPasswordPage.styles'

const ResetPasswordPage = () => {
  const translate = useTranslate()
  const useStyles = makeStyles(resetPasswordPageStyles)
  const classes = useStyles()

  return (
    <NoLayoutContainer>
      <div className={classes.resetPasswordLabel}>{translate('ra.page.resetPassword')}</div>
      <ResetPasswordForm />
    </NoLayoutContainer>
  )
}

export default ResetPasswordPage
