import React from 'react'
import { Avatar, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import orgAvatarEmptyPreviewStyles from './orgAvatarEmptyPreview.styles'
import DefaultOrgIcon from '../../assets/icons/DefaultOrgIcon'

const OrgAvatarEmptyPreview = ({ croppedImage }) => {
  const useStyles = makeStyles(orgAvatarEmptyPreviewStyles)
  const classes = useStyles()

  return croppedImage ? (
    <Avatar className={classes.preview} src={croppedImage} alt='Org-Avatar' />
  ) : (
    <DefaultOrgIcon className={classes.emptyOrg} />
  )
}

OrgAvatarEmptyPreview.propTypes = {
  croppedImage: PropTypes.string,
}

export default OrgAvatarEmptyPreview
