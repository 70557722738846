import React from 'react'
import { useTranslate } from 'react-admin'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import drillShowBodyStyles from './drillShowBody.styles'
import DrillShowAppbar from '../DrillShowAppbar/DrillShowAppbar'
import Designs from '../../../components/designs/Designs'
import DescriptionField from '../../../react-admin/components/fields/DescriptionField/DescriptionField'
import ChipField from '../../../react-admin/components/fields/ChipField/ChipField'
import ChipsContainer from '../../../components/ChipsContainer/ChipsContainer'
import ContainerPadding from '../../../components/containerPadding/containerPadding'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import setTacticsColor from '../../../utils/helpers/setTacticsColor'
import useShowTrainingModuleQueries from '../../../hooks/useShowTrainingModuleQueries'
import { useDrillShowContext } from '../../../context/useDrillShowContext'
import LoaderDots from '../../../components/loaders/LoaderDots/LoaderDots'
import drillListPageStyles from '../../DrillList/DrillListPage/drillListPage.styles'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const DrillShowPage = (props) => {
  const { clickedComponentId, isModal, openDrillCreateModal, onModalClose } = props
  const {
    trainingModule,
    isFetching,
    drillId,
    pathname,
    trainingModuleType,
    deleteADrill,
    deleteTrainingModule,
  } = useShowTrainingModuleQueries({
    ...props,
  })
  const practicePlanId = window.location.href.match(/\/practice-plan\/([^?]+)/)?.[1]
  const { usersTrainingModules } = useDrillShowContext()
  const translate = useTranslate()
  const useStyles = makeStyles(drillShowBodyStyles)
  const classes = useStyles()

  const { tactics: initialTactics, designs } = trainingModule || {}
  const tactics = setTacticsColor(initialTactics)

  const checkIfLastRevision = (downloadedTrainingModule) => {
    if (!downloadedTrainingModule) return false

    return downloadedTrainingModule?.revisionId === downloadedTrainingModule?.revisionReferenceId
  }

  const uploadedVideo = trainingModule?.uploadedVideo || ''

  return trainingModule && !isFetching ? (
    <div className={classes.cardContentRoot}>
      <ContainerPadding>
        <DrillShowAppbar
          trainingModule={trainingModule}
          pathname={pathname}
          drillId={drillId}
          designs={designs}
          practicePlanId={practicePlanId}
          clickedComponentId={clickedComponentId}
          isModal={isModal}
          openDrillCreateModal={openDrillCreateModal}
          onModalClose={onModalClose}
          deleteADrill={deleteADrill}
          deleteTrainingModule={deleteTrainingModule}
          isLoading={usersTrainingModules.isLoading}
          isDownloaded={checkIfLastRevision(
            usersTrainingModules?.data?.trainingModulesDetailsDto.find(
              (element) =>
                // Show page in coach corner (PUBLIC)
                trainingModule?.id === element.originalTrainingModuleId ||
                // Show page in collection
                trainingModule?.id === element.revisionReferenceId ||
                // Show in collection downloaded TM added from PERSONAL list
                // could also be:
                // trainingModule?.revisionReferenceId
                //   ? trainingModule?.revisionReferenceId === element.revisionReferenceId
                //   : false,
                trainingModule?.originalTrainingModuleId === element.id,
            ),
          )}
        />
      </ContainerPadding>
      <Divider light classes={{ root: classes.fullWidth }} />

      <ContainerPadding>
        <Grid container classes={{ root: classes.fullWidth }}>
          {/* Left Column */}
          <Grid item xs={8}>
            {/* Description for Drills */}
            <ConditionalRender renderValue={trainingModuleType === 'drills'}>
              <DescriptionField
                label={translate('ra.text.description')}
                text={trainingModule?.description}
                titleStyle={classes.description}
                recordStyle={classes.greySpan}
              />
            </ConditionalRender>
            {/* Graphics */}
            <div className={classes.description}>{translate('ra.text.graphics')}</div>
            <Designs designs={designs} noIcons type={trainingModuleType} show />
          </Grid>

          <Grid
            item
            xs={1}
            container
            justifyContent='center'
            classes={{ root: classes.gridDividerRoot }}
          >
            <Divider light orientation='vertical' />
          </Grid>

          {/* Right Column */}
          <Grid item xs={3} classes={{ root: classes.tagsGrid }}>
            {/* Description for Plays */}
            <ConditionalRender renderValue={trainingModuleType === 'plays'}>
              <DescriptionField
                label={translate('ra.text.description')}
                text={trainingModule?.description}
                titleStyle={classes.description}
                recordStyle={classes.greySpan}
              />
            </ConditionalRender>

            {/* Intensities and Positions */}
            <ConditionalRender renderValue={trainingModuleType === 'drills'}>
              <ChipField
                label={translate('ra.formFields.intensity')}
                dataArray={trainingModule?.intensities}
                titleStyle={classes.description}
              />
              <ChipField
                label={translate('ra.formFields.positions')}
                dataArray={trainingModule?.positions}
                titleStyle={classes.description}
              />
            </ConditionalRender>

            {/* Tactics */}
            <ConditionalRender renderValue={tactics && tactics?.length > 0}>
              <div className={classes.span}>{translate('ra.formFields.tactics')}</div>
            </ConditionalRender>

            <ChipsContainer chips={tactics} />

            <ConditionalRender renderValue={uploadedVideo?.length > 0}>
              <div className={classes.videoPlayer}>
                <VideoPlayer
                  video={uploadedVideo}
                  trainingModuleId={trainingModule.originalTrainingModuleId || trainingModule.id}
                />
              </div>
            </ConditionalRender>
          </Grid>
        </Grid>
      </ContainerPadding>
    </div>
  ) : (
    <Grid container justifyContent='center' style={{ marginTop: '100px', marginBottom: '30px' }}>
      <LoaderDots style={drillListPageStyles.loaderIcon} />
    </Grid>
  )
}

DrillShowPage.propTypes = {
  clickedComponentId: PropTypes.string,
  isModal: PropTypes.bool,
  openDrillCreateModal: PropTypes.func,
  onModalClose: PropTypes.func,
}

export default DrillShowPage
