export const selectTeamStyles = {
  select: {
    "&:focus": {
      backgroundColor: "transparent"
    }
  },
  selectInput: {
    padding: "15px 12px 15px"
  },
  menuItemRoot: {
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "20px"
  },
  menuItemSelected: {
    "&&": {
      backgroundColor: "transparent"
    }
  },
  addButton: {
    padding: "4px 8px 4px 7px",
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
};