import COLORS from '../../utils/constants/colors'

const livestreamBannerMediaStyles = {
  mediaContainer: {
    flexDirection: 'column',
    width: '100%',
    display: 'flex',
    marginBottom: '10px',
  },
  videoContainerNotClicked: {
    display: 'flex',
    alignItems: 'center',
    color: COLORS.white,
    justifyContent: 'space-around',
    paddingLeft: '10%',
    paddingRight: '8%',
  },
  videoContainerClicked: {
    display: 'flex',
    alignItems: 'center',
    color: COLORS.white,
    animation: '$backColor 2s',
    animationIterationCount: '1',
    justifyContent: 'space-around',
    paddingLeft: '10%',
    paddingRight: '8%',
  },

  '@keyframes backColor': {
    '20%': {
      backgroundColor: COLORS.orange,
    },
    '100%': {
      backgroundColor: COLORS.lightBlack,
    },
  },
}

export default livestreamBannerMediaStyles
