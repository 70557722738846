import React from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Avatar,
  Chip,
  Typography,
  Tooltip,
} from '@material-ui/core'
import selectedPlayerStyles from './selectedPlayerStyles'
import DragIcon from '../../assets/icons/dragIcon'
import MyCancelIcon from '../../assets/icons/myCancelIcon'

const SelectedPlayer = ({ player, drag, inTeam, removePlayerFromTeam }) => {
  const useStyles = makeStyles(selectedPlayerStyles)
  const classes = useStyles()
  const positionNames = player?.personalData?.positions?.map((position) => position?.name) || []

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text
  }

  const fullName = `${player?.personalData?.firstName} ${player?.personalData?.lastName}`
  const truncatedName = truncateText(fullName, 20)
  const isTruncated = fullName !== truncatedName

  return !inTeam ? (
    <Card className={classes.card} style={{ cursor: drag ? 'pointer' : 'default' }}>
      <CardHeader
        avatar={
          player.logoUrl ? (
            <Avatar alt='Player Avatar' src={player.logoUrl} className={classes.avatar} />
          ) : null
        }
        className={classes.cardHeader}
      />
      <CardContent
        className={classes.content}
        style={!player.logoUrl && drag ? { paddingLeft: '18px' } : {}}
      >
        <Tooltip title={isTruncated ? fullName : ''}>
          <Typography variant='body1' className={classes.playerName}>
            {truncatedName}
          </Typography>
        </Tooltip>
        <div className={classes.chipContainer}>
          {positionNames.map((positionName) => (
            <Chip
              key={positionName}
              label={truncateText(positionName, 10)}
              size='small'
              className={classes.chip}
            />
          ))}
        </div>
      </CardContent>
      <CardActions className={classes.cardActions}>
        {drag && <DragIcon className={classes.dragIcon} style={{ width: '20px' }} />}
      </CardActions>
    </Card>
  ) : (
    <span className={classes.span}>
      {`${player?.personalData?.firstName} ${player?.personalData?.lastName}`}
      <MyCancelIcon onClick={() => removePlayerFromTeam(player)} />
    </span>
  )
}

SelectedPlayer.propTypes = {
  player: PropTypes.shape({
    personalData: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      positions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    logoUrl: PropTypes.string,
  }).isRequired,
  drag: PropTypes.bool,
  inTeam: PropTypes.bool,
  removePlayerFromTeam: PropTypes.func,
}

SelectedPlayer.defaultProps = {
  drag: false,
  inTeam: false,
  removePlayerFromTeam: () => {},
}

export default SelectedPlayer
