import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import drillBoxLiteStyles from './drillBoxLite.styles'
import QueryKeys from '../../utils/constants/queryKeys'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import MyCloudIcon from '../../assets/icons/MyCloudIcon'
import DrillSlider from '../DrillSlider/DrillSlider'
import MyCloudDownloadIcon from '../../assets/icons/myCloudDownloadIcon'
import MyPersonIcon from '../../assets/icons/MyPersonIcon'
import getUserData from '../../Apis/user/getUserData'
import drillBoxLiteAnimationStyles from './drillBoxLiteAnimation.styles'
import { accessDrill } from '../../Apis/drill/accessDrill'
import ContainerPadding from '../containerPadding/containerPadding'
import trainingModuleType from '../../types/trainingModuleType'
import TRAINING_MODULE_ACCESS_OPTIONS from '../../utils/constants/trainingModuleAccessOptions'
import Description from '../Description/Description'

const DrillBoxLite = (props) => {
  const { drill, toggleAccess, handleDrillClick, loading, usersDownloaded } = props
  const {
    title,
    description,
    id,
    creator,
    designs,
    access: drillAccess,
    originalCreatorFirstName,
  } = drill
  const [cloudIconClicked, setCloudIconClicked] = useState(true)
  const queryClient = useQueryClient()
  const useStyles = makeStyles(drillBoxLiteStyles)
  const classes = useStyles()
  const useAnimationStyles = makeStyles(drillBoxLiteAnimationStyles(loading))
  const animationClasses = useAnimationStyles()

  useEffect(() => {
    setCloudIconClicked(
      usersDownloaded && usersDownloaded?.revisionReferenceId === usersDownloaded?.revisionId,
    )
  }, [usersDownloaded])

  const accessADrill = useMutation((drillId) => accessDrill(drillId), {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.GET_DRILLS),
  })
  const me = useQuery([QueryKeys.GET_ACCOUNT_ME], () => getUserData())

  const isCurrentUserDrillCreator = me?.data?.id === creator.id

  return (
    <div className={animationClasses.wrapper} onClick={handleDrillClick}>
      <ContainerPadding paddingValue='5%'>
        <ConditionalRender renderValue={originalCreatorFirstName || toggleAccess === 'public'}>
          <div className={classes.creator}>
            <MyPersonIcon style={{ marginRight: '5px' }} />
            {originalCreatorFirstName ? (
              <span className={classes.greySmall}>{originalCreatorFirstName}</span>
            ) : (
              <span className={classes.greySmall}>{creator.firstName}</span>
            )}
          </div>
        </ConditionalRender>

        <ConditionalRender renderValue={title}>
          <div className={classes.divFlex}>
            {toggleAccess === TRAINING_MODULE_ACCESS_OPTIONS.private &&
              isCurrentUserDrillCreator &&
              drillAccess?.name === TRAINING_MODULE_ACCESS_OPTIONS.public.toUpperCase() && (
                <MyCloudIcon className={classes.cloudIcon} />
              )}
            {toggleAccess === 'public' && !isCurrentUserDrillCreator && (
              <MyCloudDownloadIcon
                onClick={(e) => {
                  e.stopPropagation()
                  if (!cloudIconClicked) {
                    setCloudIconClicked(true)
                    accessADrill.mutate(id)
                  }
                }}
                className={
                  cloudIconClicked ? classes.cloudIconClicked : classes.cloudIconNotClicked
                }
              />
            )}
            <span className={classes.title}>{title}</span>
          </div>
        </ConditionalRender>

        <ConditionalRender renderValue={description}>
          <Description description={description} />
        </ConditionalRender>

        <DrillSlider designs={designs} />
      </ContainerPadding>
    </div>
  )
}

DrillBoxLite.propTypes = {
  drill: trainingModuleType,
  toggleAccess: PropTypes.string,
  handleDrillClick: PropTypes.func,
  loading: PropTypes.bool,
  usersDownloaded: PropTypes.arrayOf(trainingModuleType),
}

export default DrillBoxLite
