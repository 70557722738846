import React, { useEffect, useState } from 'react'
import PropTypes, { arrayOf } from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core'
import Courteditor, { ELEMENT_LOADING_ENUM } from 'courteditor/lib'
import designType from '../../types/designType'
import courtEditorDialogStyles from './courtEditorDialog.styles'

const CourtEditorDialog = (props) => {
  const {
    open,
    onClose,
    onExit,
    designToEdit,
    setDesignToEdit,
    setDesign,
    isLoading,
    designs,
    title,
    editDesign,
    changeDesignOrder,
    deleteDesign,
  } = props
  const [hasSaved, setHasSaved] = useState(false)
  const [previousDesOrder, setPreviousDesOrder] = useState()
  const useStyles = makeStyles(courtEditorDialogStyles)
  const classes = useStyles()

  useEffect(() => {
    if (designToEdit) {
      setPreviousDesOrder(designToEdit?.order)
    }
  }, [designToEdit, designToEdit?.order])
  const onSave = (json, base64, closeModal) => {
    // TODO If backend logic doesn't change, create a mapper.
    let orderValue = null
    if (closeModal) {
      orderValue = designToEdit
        ? previousDesOrder
        : previousDesOrder
        ? previousDesOrder + 1
        : designs.length > 0
        ? designs.sort((a, b) => a.order - b.order).reverse()[0].order + 1
        : 1
    } else {
      orderValue = designToEdit
        ? previousDesOrder
        : hasSaved
        ? (setPreviousDesOrder((prevCount) => prevCount + 1), previousDesOrder + 1)
        : (setPreviousDesOrder(designs.length + 1), designs.length + 1)
    }

    const newDesign = {
      id: designToEdit?.id,
      description: '',
      source: json,
      preview: base64,
      order: orderValue,
      edited: true,
    }

    setDesign(newDesign)
    // TODO: onExit, trigger submit if had "New and Save" before
    if (closeModal) {
      setPreviousDesOrder(undefined)
      setHasSaved(false)
      onClose()
    } else {
      setHasSaved(true)
      setDesignToEdit('')
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      disableEnforceFocus
      classes={{ paper: classes.dialog }}
    >
      <Courteditor
        initialDesign={designToEdit && designToEdit.source && JSON.parse(designToEdit.source)}
        onSaveAndNew={(json, base64) => onSave(json, base64, false)}
        onSaveAndExit={(json, base64) => onSave(json, base64, true)}
        loadingElement={
          isLoading.addLoading || isLoading.updateLoading
            ? ELEMENT_LOADING_ENUM.saveAndExit
            : isLoading.addLoading || isLoading.updateLoading
            ? ELEMENT_LOADING_ENUM.saveAndNew
            : null
        }
        title={title}
        onExit={onExit}
        designs={designs}
        editDesign={editDesign}
        changeDesignOrder={changeDesignOrder}
        deleteDesign={deleteDesign}
      />
    </Dialog>
  )
}

CourtEditorDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExit: PropTypes.func,
  designToEdit: designType,
  setDesignToEdit: PropTypes.func,
  setDesign: PropTypes.func,
  isLoading: PropTypes.shape({
    addLoading: PropTypes.bool,
    updateLoading: PropTypes.bool,
  }),
  designs: arrayOf(designType),
  title: PropTypes.string,
  editDesign: PropTypes.func,
  changeDesignOrder: PropTypes.func,
  deleteDesign: PropTypes.func,
}

export default CourtEditorDialog
