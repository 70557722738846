import COLORS from '../../../utils/constants/colors'

const addButtonStyles = (filled) => ({
  noHoverEffect: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  startIconNoMargin: {
    margin: 0,
  },
  buttonLabel: {
    color: filled ? COLORS.white : COLORS.darkGrey,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
  },
})

export default addButtonStyles
