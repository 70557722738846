import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import QueryKeys from '../utils/constants/queryKeys'
import useQueryParams from './useQueryParams'
import addDrill from '../Apis/drill/addDrill'
import patchDrill from '../Apis/drill/patchDrill'
import getLatestRevision from '../Apis/revisions/getLatestRevision'
import getDrill from '../Apis/drill/getDrill'
import { TRAINING_MODULES_TYPES_URL } from '../utils/constants/trainingModulesTypes'
import getRevision from '../Apis/revisions/getRevision'
import parsePatchTrainingModule from '../utils/helpers/parsePatchTrainingModule'
import parsePostTrainingModule from '../utils/helpers/parsePostTrainingModule'
import addPracticePlan from '../Apis/practice/addPracticePlan'
import updatePracticePlan from '../Apis/practice/updatePracticePlan'
import parsePatchPracticePlan from '../utils/helpers/parsePatchPracticePlan'
import parsePostPracticePlan from '../utils/helpers/parsePostPracticePlan'
import findReplacedObjectBetweenTwoArrays from '../utils/helpers/findReplacedObjectBetweenTwoArrays'
import { usePracticePlanContext } from '../context/usePracticePlanContext'

const usePracticePlanQueries = ({
  clickedComponent,
  closeDrillCreateModal,
  setClickedComponent,
}) => {
  // Based on user option to update or not play globally. Default value is false.
  const [shouldUpdateDrillGlobally, setShouldUpdateDrillGlobally] = useState(true)
  const [trainingModule, setTrainingModule] = useState(null)
  // We use this variable to know if there is initially a clicked component.
  // This way we know if user create new or edit a training-module to insert to practice-plan.
  const [isNewDrillCreated] = useState(Boolean(clickedComponent))
  const queryClient = useQueryClient()
  const { team, org } = useQueryParams()
  const {
    practicePlan,
    setPracticePlan,
    practice,
    formUpdatePracticePlanBody,
    setUpdatedComponents,
    updatedComponents,
    deletedComponents,
  } = usePracticePlanContext()

  const trainingModuleId = clickedComponent?.trainingModule.id || undefined

  const addAPracticePlan = useMutation((data) => addPracticePlan(data), {
    onSuccess: ({ data }, { closeDesignerModal }) => {
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICE_PLANS)
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICE_PLAN)
      window.history.replaceState({}, '', `/practice-plan/${data?.id}?org=${org}&team=${team}`)

      const changedComponentId = findReplacedObjectBetweenTwoArrays(
        practicePlan?.practicePlanComponents
          ?.filter((comp) => !!comp?.trainingModule)
          ?.map((tr) => {
            return { id: tr.id }
          }) || [],
        data.practicePlanComponents
          ?.filter((comp) => !!comp?.trainingModule)
          .map((tr) => {
            return { id: tr.id }
          }),
      )
      const changedComponent = data.practicePlanComponents.find(
        (tr) => tr.id === changedComponentId?.id,
      )

      setPracticePlan(data)
      setClickedComponent(changedComponent)
      queryClient.invalidateQueries(QueryKeys.GET_REVISION)

      if (closeDesignerModal) return closeDesignerModal(false)

      return closeDrillCreateModal(changedComponent)
    },
  })

  const updateAPracticePlan = useMutation((data) => updatePracticePlan(data), {
    onSuccess: (res, { closeDesignerModal }) => {
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICE_PLANS)
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICE_PLAN)
      // We need to find the changed component,through changed training Module
      // Because after every update we get new training module id (revision)
      const changedTrainingModuleId = findReplacedObjectBetweenTwoArrays(
        practicePlan.practicePlanComponents.map((tr) => {
          return { id: tr?.trainingModule?.id }
        }),
        res.data.practicePlanComponents.map((tr) => {
          return { id: tr?.trainingModule?.id }
        }),
      )
      const changedComponent = res.data.practicePlanComponents.find(
        (tr) => tr?.trainingModule?.id === changedTrainingModuleId?.id,
      )

      setClickedComponent(changedComponent)
      queryClient.invalidateQueries(QueryKeys.GET_REVISION)
      setUpdatedComponents([])

      if (closeDesignerModal) return closeDesignerModal(false)

      return closeDrillCreateModal(changedComponent)
    },
  })

  const addADrill = useMutation((data) => addDrill(data), {
    onSuccess: ({ data }, { closeDesignerModal }) => {
      if (!practicePlan?.id) {
        return addAPracticePlan.mutate(
          parsePostPracticePlan({
            practicePlan,
            practice,
            newTrainingModule: data,
            closeDesignerModal,
          }),
        )
      }

      return updateAPracticePlan.mutate(
        parsePatchPracticePlan({
          practicePlan,
          formUpdatePracticePlanBody,
          updatedComponents,
          deletedComponents,
          clickedComponent,
          newTrainingModuleRevisionId: data?.id,
          closeDesignerModal,
        }),
      )
    },
  })

  const updateADrill = useMutation((data) => patchDrill(data), {
    onSuccess: ({ data }, { closeDesignerModal }) => {
      queryClient.invalidateQueries(QueryKeys.GET_DRILLS)

      updateAPracticePlan.mutate(
        formUpdatePracticePlanBody(
          null,
          {
            id: clickedComponent?.id,
            duration: clickedComponent?.duration,
            notes: clickedComponent?.notes,
            order: clickedComponent?.order,
            trainingModuleId: data?.id,
          },
          closeDesignerModal,
        ),
      )
    },
  })

  const trainingModuleFetch = useMutation([QueryKeys.GET_DRILL], (data) => getDrill(data), {
    onSuccess: (data) => {
      setTrainingModule(data)
    },
  })

  // Based on current training-module we ask for latest revision of original current training-module
  const trainingModuleLatestRevision = useMutation(
    [QueryKeys.GET_LATEST_REVISION],
    (data) => getLatestRevision(data),
    {
      enabled: trainingModuleId !== 'create' && trainingModuleId !== null,
      onSuccess: (response, data) => {
        // Check if current training-module revision is the latest training-module revision of the original
        if (response?.latestRevisionId === data?.trainingModuleRevision.id) {
          return trainingModuleFetch.mutate(data?.trainingModuleRevision.originalTrainingModuleId)
        }

        setTrainingModule(data?.trainingModuleRevision)
        return setShouldUpdateDrillGlobally(false)
      },
    },
  )

  // Because we are in playbook we ask for revision training-module.
  const trainingModuleRevision = useQuery(
    [QueryKeys.GET_REVISION, trainingModuleId],
    () => getRevision(trainingModuleId),
    {
      enabled: Boolean(trainingModuleId && trainingModuleId !== 'create'),
      onSuccess: (data) => {
        // setRevisionDrillId(data?.id)

        trainingModuleLatestRevision.mutate({
          trainingModuleId: data?.originalTrainingModuleId,
          trainingModuleRevision: data,
        })
      },
    },
  )

  const createDrillProcess = (data, closeDesignerModal) =>
    addADrill.mutate({
      values: parsePostTrainingModule(data, TRAINING_MODULES_TYPES_URL.DRILLS),
      closeDesignerModal,
    })

  const updateDrillProcess = (data, closeDesignerModal) => {
    const parsedTrainingModule = parsePatchTrainingModule(data, TRAINING_MODULES_TYPES_URL.DRILLS)

    if (shouldUpdateDrillGlobally) {
      return updateADrill.mutate({
        values: parsedTrainingModule,
        // Here we want the initial play id.
        trainingModuleId: trainingModule?.id,
        closeDesignerModal,
      })
    }

    // Initial edit. After this the isHidden is always true.
    if (!shouldUpdateDrillGlobally && !trainingModule.isHidden) {
      return updateAPracticePlan.mutate(
        parsePatchPracticePlan({
          practicePlan,
          formUpdatePracticePlanBody,
          updatedComponents,
          deletedComponents,
          clickedComponent,
          designs: data?.designs,
          newTrainingModuleRevisionId: data?.id,
          newTrainingModule: parsePatchTrainingModule(data, TRAINING_MODULES_TYPES_URL.DRILLS),
          closeDesignerModal,
        }),
      )
    }

    if (!shouldUpdateDrillGlobally && trainingModule.isHidden) {
      return updateADrill.mutate({
        values: parsedTrainingModule,
        // Here we want the initial play id.
        trainingModuleId: trainingModule?.id,
        closeDesignerModal,
      })
    }

    return null
  }

  // Here we check if the initial training-module is updated either in coach corner or previously in playbook.
  // If yes we don't give user the option to update training-module globally.
  const isTrainingModuleUpdatedDirty =
    trainingModuleLatestRevision?.data &&
    trainingModuleRevision?.data &&
    trainingModule &&
    trainingModuleLatestRevision?.data?.latestRevisionId === trainingModuleRevision?.data?.id &&
    !trainingModule.isHidden &&
    isNewDrillCreated

  const isFetching =
    trainingModuleRevision?.isFetching ||
    trainingModuleFetch?.isLoading ||
    trainingModuleLatestRevision?.isLoading

  const isMutating =
    addAPracticePlan.isLoading ||
    updateAPracticePlan.isLoading ||
    addADrill.isLoading ||
    updateADrill.isLoading

  return {
    trainingModule,
    createDrill: createDrillProcess,
    updateDrill: updateDrillProcess,
    isFetching,
    isMutating,
    isTrainingModuleUpdatedDirty,
    shouldUpdateDrillGlobally,
    setShouldUpdateDrillGlobally,
    type: TRAINING_MODULES_TYPES_URL.DRILLS,
  }
}

export default usePracticePlanQueries
