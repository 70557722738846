import React from 'react'
import { makeStyles, MenuItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import { BrowserRouter } from 'react-router-dom'
import { useLogout } from 'react-admin'
import MoreIcon from '@material-ui/icons/MoreVert'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import useHistoryPush from '../../hooks/useHistoryPush'
import { useSignalRConnectionContext } from '../../context/useSignalRConnectionContext' // Import the SignalR connection context
import PROFILE_MENU_OPTIONS from '../../utils/constants/profileMenuOptions'
import moreIconProfileStyles from './moreIconProfile.styles'

const MoreIconProfile = (props) => {
  const { profileMenuModal, setProfileMenuModal, disabled } = props
  const { redirect } = useHistoryPush()
  const logout = useLogout()
  const { connection } = useSignalRConnectionContext() // Access the SignalR connection context
  const useStyles = makeStyles(moreIconProfileStyles)
  const classes = useStyles()

  const handleMenuItemClick = (href) => {
    if (href === 'profile' || href === 'invitationsList') {
      redirect(`/${href}`, [{ profileOption: href }])
    } else {
      window.open(href, '_blank')
    }
  }

  const handleLogout = () => {
    // Disconnect from SignalR when logging out
    if (connection) {
      connection.stop().then(() => {
        // Redirect to remove the paragms from the url
        redirect('/', [], ['org', 'team'])
        logout() // After disconnecting, perform logout and fix url paragms
      })
    } else {
      // Redirect to remove the paragms from the url
      redirect('/', [], ['org', 'team'])
      logout() // If connection is not available, proceed with logout directly
    }
  }

  return (
    <BrowserRouter>
      <IconButton
        aria-haspopup='true'
        color='inherit'
        aria-controls='profile-menu'
        onClick={(e) => setProfileMenuModal(e.currentTarget)}
      >
        <MoreIcon style={{ fill: '#555555' }} />
      </IconButton>
      <Menu
        id='profile-menu'
        open={Boolean(profileMenuModal)}
        anchorEl={profileMenuModal}
        onClose={() => setProfileMenuModal(null)}
        onClick={() => setProfileMenuModal(null)}
      >
        {PROFILE_MENU_OPTIONS.map((item) => (
          <MenuItem
            key={item.href}
            onClick={() => handleMenuItemClick(item.href)}
            classes={{ root: classes.profileMenuItems }}
            disabled={disabled}
          >
            <div className={classes.profileMenuItemsBody}>
              {item.icon}
              {item.text}
            </div>
          </MenuItem>
        ))}
        <MenuItem key='logout' onClick={handleLogout} classes={{ root: classes.profileMenuItems }}>
          <div className={classes.profileMenuItemsBody}>
            <ExitToAppIcon />
            Log Out
          </div>
        </MenuItem>
      </Menu>
    </BrowserRouter>
  )
}

MoreIconProfile.propTypes = {
  profileMenuModal: PropTypes.string,
  setProfileMenuModal: PropTypes.func,
}

export default MoreIconProfile
