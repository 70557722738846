import COLORS from '../../../../utils/constants/colors'
import IMAGE_CROP_DEFAULT_DIMENSIONS from '../../../../utils/constants/imageCropDefaultDimensions'

const imageCropAddPreviewStyles = (width, height) => ({
  wrapper: {
    position: 'relative',
    width: width ?? IMAGE_CROP_DEFAULT_DIMENSIONS.WIDTH,
    height: height ?? IMAGE_CROP_DEFAULT_DIMENSIONS.HEIGHT,
    border: '1px solid black',
    borderStyle: 'dashed',
    borderRadius: 10,
  },
  inputLabel: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  chooseFile: {
    height: '100%',
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center',
    color: COLORS.orange,
  },
  fileInput: {
    display: 'none',
  },
})

export default imageCropAddPreviewStyles
