import axiosInstance from '../axiosInterceptor/axiosInstance'

const getDownloadRecordingUrl = async (data) => {
  const { eventId } = data
  const url = `/events/${eventId}/recording`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getDownloadRecordingUrl
