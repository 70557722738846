import getEvent from '../../../../Apis/event/getEvent'

const teamCalendarIdBreadcrumb = async (data) => {
  const { match, teamName, eventType } = data || {}
  const { eventId } = match.params

  const eventTitle = await getEvent({ eventId, eventTypeCode: eventType }).then(
    (event) => event.title,
  )

  return [teamName ? teamName.toUpperCase() : '', 'CALENDAR', eventTitle.toUpperCase()]
}

export default teamCalendarIdBreadcrumb
