import { Divider, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import Masonry from 'react-masonry-css'
import { v4 } from 'uuid'
import MyChip from '../../../components/MyChip/MyChip'
import DrillBox from '../../../components/DrillBox/DrillBox'
import playbookMasonryStyles from './playbookMasonry.styles'
import MASONRY_BREAKPOINTS from '../../../utils/constants/masonryBreakpoints'
import useHistoryPush from '../../../hooks/useHistoryPush'

const PlaybookMasonry = (props) => {
  const { plays, playsDisplay, toggleAccess, playbookId } = props
  const { redirect } = useHistoryPush()
  const useStyles = makeStyles(playbookMasonryStyles())
  const breakpoint = MASONRY_BREAKPOINTS.PLAYBOOK
  const classes = useStyles()

  const showRedirect = (trainingModuleId) => {
    redirect(`playbook/${trainingModuleId}/show`)
  }

  return (
    plays &&
    Object.values(plays).map((value, index) => (
      <div key={v4()} id={playsDisplay[index] || 'No Tactics'}>
        <Grid container alignItems='center' className={classes.head}>
          <Divider light className={classes.divider} />
          {value[0]?.tactics.length > 0 ? (
            value[0]?.tactics.map((tactic) => {
              return (
                <MyChip
                  key={tactic.name}
                  label={tactic.name}
                  style={{ marginLeft: '5px', marginRight: '5px' }}
                />
              )
            })
          ) : (
            <MyChip label='No Tactics' style={{ marginLeft: '5px', marginRight: '5px' }} />
          )}
          <Divider light className={classes.divider} />
        </Grid>

        <Masonry
          breakpointCols={breakpoint}
          className={classes.myMasonryGrid}
          columnClassName={classes.myMasonryGridColumn}
        >
          {value.map((element) => (
            <div key={element.id} onClick={() => showRedirect(element.id)}>
              <DrillBox
                drill={element}
                toggleAccess={toggleAccess}
                trModuleType='playbook'
                accessedFrom='playbook'
                playbookId={playbookId}
                isDeleteModalHidden
              />
            </div>
          ))}
        </Masonry>
      </div>
    ))
  )
}

export default PlaybookMasonry
