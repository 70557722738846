import React from 'react'
import { SelectInput } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import SELECT_INPUT_STYLES from '../../../utils/constants/CSS/selectInputStyles'

const MySelectInput = (props) => {
  const { loading, ...rest } = props
  const useStyles = makeStyles(SELECT_INPUT_STYLES)
  const classes = useStyles()

  return (
    <SelectInput
      {...rest}
      fullWidth
      margin='none'
      options={{
        SelectProps: {
          IconComponent: () => <KeyboardArrowDownIcon classes={{ root: classes.arrowDown }} />,
        },
        InputProps: {
          classes: {
            root: classes.root,
            input: classes.input,
          },
          disableUnderline: true,
        },
        InputLabelProps: {
          classes: {
            error: classes.error,
            formControl: classes.formControl,
            shrink: classes.shrink,
          },
        },
        FormHelperTextProps: {
          classes: {
            root: classes.helperTextRoot,
          },
        },
      }}
    />
  )
}

export default MySelectInput
