import React from 'react'
import MicIcon from '@material-ui/icons/Mic';
import { makeStyles } from '@material-ui/core'
import COLORS from '../../utils/constants/colors'

const MyMicIcon = (props) => {
  const useStyles = makeStyles({
    leftIcon: {
      width: 24,
      height: 24,
      cursor: props.cursor? props.cursor : 'pointer',
      fill: COLORS.white,
      '&:hover': {
        fill: COLORS.lightGreen,
      }
    },
  })
  const classes = useStyles()

  return <MicIcon {...props} classes={{ root: classes.leftIcon }} />
}

export default MyMicIcon
