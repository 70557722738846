import COLORS from '../../../utils/constants/colors'

const subscriptionAddonCardStyles = (enabled) => ({
  planCard: {
    backgroundColor: enabled && COLORS.opacBlue,
    borderColor: enabled && COLORS.blue,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: '300px',
    marginBottom: '20px',
  },
  typographyBold: { fontSize: '20px', fontWeight: 'bold' },
  button: { fontWeight: 'bold' },
  typographyLight: { color: COLORS.lightGrey },
  deactivateBtn: {
    fontSize: '12px',
    textAlign: 'center',
    color: 'primary',
  },
  buttonDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '12px',
    paddingRight: '8px',
    paddingTop: '2px!important',
    paddingBottom: '2px!important',
  },
  dotText: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    marginLeft: '10%',
  },
  check: {
    width: 10,
    height: 10,
    color: COLORS.black,
    padding: 0,
  },
  priceColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

export default subscriptionAddonCardStyles
