import COLORS from '../../utils/constants/colors'

const durationChipStyles = {
  divFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  durationContainer: {
    width: '33px',
    height: '33px',
    padding: '13px',
    backgroundColor: COLORS.backgroundGrey,
    borderRadius: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  duration: {
    fontSize: '24px',
    fontWeight: 900,
    lineHeight: '21px',
  },
  min: {
    lineHeight: '16px',
  },
}

export default durationChipStyles
