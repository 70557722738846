import React from 'react'
import { makeStyles, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import ExpandIcon from '../../../assets/icons/expandIcon'
import AUTOCOMPLETE_STYLES from '../../../utils/constants/CSS/autocompleteStyles'
import isObjectEmptyHelper from '../../../utils/helpers/isObjectEmptyHelper'

const MyAutocomplete = (props) => {
  const { form, label, field, required, multiple, onChange, autoSelect } = props
  const { setFieldValue } = form
  const useStyles = makeStyles(AUTOCOMPLETE_STYLES)
  const classes = useStyles()

  return (
    <Autocomplete
      {...props}
      popupIcon={<ExpandIcon />}
      multiple={multiple}
      autoHighlight
      autoSelect={autoSelect === undefined ? true : autoSelect}
      onChange={(e, value) => {
        onChange ? onChange(e, value) : setFieldValue(field.name, value)
      }}
      ChipProps={{
        classes: {
          root: classes.chipRoot,
          label: classes.chipLabel,
          deleteIcon: classes.chipDeleteIcon,
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!form?.errors[field?.name]}
          helperText={form?.errors[field?.name]}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          variant='filled'
          label={label}
          required={
            required && (isObjectEmptyHelper(field.value) || !field.value || !field.value?.length)
          }
          InputProps={{
            ...params.InputProps,
            classes: {
              root: classes.root,
              input: classes.input,
            },
            disableUnderline: true,
          }}
          InputLabelProps={{
            classes: {
              formControl: classes.formControl,
              shrink: classes.shrink,
              focused: classes.focused,
            },
          }}
        />
      )}
    />
  )
}

export default MyAutocomplete
