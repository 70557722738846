import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import PracticePlanComponentsTable from '../PracticePlanComponentsTable/PracticePlanComponentsTable'
import { usePracticePlanContext } from '../../context/usePracticePlanContext'

const PracticePlanComponentsList = (props) => {
  const { openDrillShowPageModal } = props
  const {
    practicePlan,
    setPracticePlan,
    triggerPracticePlanRequest,
    setUpdatedComponents,
    setDeletedComponents,
  } = usePracticePlanContext()

  const history = useHistory()
  const components = practicePlan?.practicePlanComponents ?? []

  return (
    components?.length > 0 && (
      <PracticePlanComponentsTable
        edit={history.location.pathname.substring(1) !== 'practice'}
        openDrillShowPageModal={openDrillShowPageModal}
        practicePlan={practicePlan}
        setPracticePlan={setPracticePlan}
        triggerPracticePlanRequest={triggerPracticePlanRequest}
        setUpdatedComponents={setUpdatedComponents}
        setDeletedComponents={setDeletedComponents}
      />
    )
  )
}

PracticePlanComponentsList.propTypes = {
  openDrillShowPageModal: PropTypes.func,
}

export default PracticePlanComponentsList
