import * as React from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core'
import COLORS from '../../utils/constants/colors'

const MyDownArrowIcon = (props) => {
  const useStyles = makeStyles({
    addIcon: {
      width: 21,
      height: 21,
      fill: COLORS.orange,
      cursor: 'pointer',
    },
  })
  const classes = useStyles()

  return <KeyboardArrowDownIcon {...props} classes={{ root: classes.addIcon }} />
}

export default MyDownArrowIcon
