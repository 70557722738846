import COLORS from '../../../utils/constants/colors'

const mainSubscriptionTab = {
  infoContent: {
    width: '66%',
    margin: 'auto',
  },
  mainContent: {
    width: '66%',
    display: 'table',
  },
  columns: {
    padding: '0% 6% 0% 6%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  subtitle: {
    marginBottom: '1%',
  },
  form: {
    width: '100%',
    height: '100%',
  },
  typographyBold: { fontWeight: 'bold' },
  typographyBoldFaq: { fontWeight: 'bold', marginTop: '60px' },
  typographyLight: { color: COLORS.lightGrey },
  summaryCard: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '300px',
    backgroundColor: COLORS.opacBlue,
  },
  table: {
    border: '1px solid #d3d3d3',
  },
}

export default mainSubscriptionTab
