import dateHelper from '../dateHelpers/dateHelper'
import isObjectEmptyHelper from '../isObjectEmptyHelper'


export const setEventStartTime = (event, dateClick) => {

  const { todayPlusHalfHour } = dateHelper()

  if (!isObjectEmptyHelper(event)) {
    return event?.startDateTime
  }

  if (!isObjectEmptyHelper(dateClick)) {
    return dateClick.start
  }

  return todayPlusHalfHour
}

export const setEventEndTime = (event, dateClick) => {
  const { todayPlusOneHour } = dateHelper()

  if (!isObjectEmptyHelper(event)) {
    return event?.endDateTime
  }

  if (!isObjectEmptyHelper(dateClick)) {
    return dateClick.end
  }

  return todayPlusOneHour
}
