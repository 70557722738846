import renderDesignOnCanvas from '../../../utils/helpers/pdfHelpers/renderDesignsOnCanvas'
import chipsRowPdfHelper from '../../../utils/helpers/pdfHelpers/chipsRowPdfHelper'
import arrayWithComma from '../../../utils/helpers/arrayWithComma'
import Stadium from '../../../assets/images/FullCourt.png'
import HalfStadium from '../../../assets/images/HalfCourt.png'

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = src
    image.onload = () => resolve(image)
    image.onerror = reject
  })
}

const drillPdfContent = async (data) => {
  const { trainingModule } = data
  const { title, description, designs, tactics, positions, intensities } = trainingModule || {}
  const content = []

  const CANVAS_WIDTH = 528
  const CANVAS_HEIGHT = 800
  // Load the court images
  const stadiumImage = await loadImage(Stadium)
  const halfStadiumImage = await loadImage(HalfStadium)

  // Set Title
  if (title && title !== 'Untitled') {
    content.push({
      text: title,
      style: 'title',
    })
  }

  const tacticChips = chipsRowPdfHelper(tactics, 350, 'chipRow', 'chipName')
  const positionsChips = chipsRowPdfHelper(positions, 150, 'chipRow', 'chipName')
  const PAGE_LENGTH = 470

  // Set Tactics/Positions
  content.push({
    layout: 'noBorders',
    table: {
      widths: [PAGE_LENGTH - positionsChips.chipsTotalLength, positionsChips.chipsTotalLength],
      body: [[tacticChips ? tacticChips.chips : '', positionsChips ? positionsChips.chips : '']],
    },
  })

  // Set Intensities
  if (intensities?.length) {
    content.push({
      text: arrayWithComma(intensities.map((intensity) => intensity.name)),
      style: 'intensities',
    })
  }

  // Set Description
  if (description) {
    content.push({
      text: description,
      style: 'subtitle',
    })
  }

  // Set Designs
  if (designs && designs?.length > 0) {
    const drillsTable = []

    designs.map((design, index) => {
      if (index % 2 === 0) {
        const jsonData = JSON.parse(designs[index].source)
        const canvas = renderDesignOnCanvas(
          jsonData,
          stadiumImage,
          halfStadiumImage,
          CANVAS_WIDTH,
          CANVAS_HEIGHT,
        )
        // First row
        drillsTable.push([
          {
            stack: [
              {
                image: canvas.toDataURL(),
                width: 200,
                style: 'drillDesign',
              },
              {
                text: `${index + 1}/${designs?.length}`,
                style: 'designCount',
                absolutePosition: { x: 50, y: 42 },
              },
            ],
          },
        ])

        // First row
        if (designs[index + 1]?.preview) {
          const nextJsonData = JSON.parse(designs[index + 1].source)

          const nextCanvas = renderDesignOnCanvas(
            nextJsonData,
            stadiumImage,
            halfStadiumImage,
            CANVAS_WIDTH,
            CANVAS_HEIGHT,
          )
          drillsTable[index].push({
            stack: [
              {
                image: nextCanvas.toDataURL(),
                width: 200,
                style: 'drillDesign',
              },
              {
                text: `${index + 2}/${designs?.length}`,
                style: 'designCount',
                absolutePosition: { x: 313, y: 42 },
              },
            ],
          })
        } else {
          drillsTable[index].push({})
        }

        // Second Row
        drillsTable.push([
          {
            text: design?.description,
            style: 'drillDescription',
          },
        ])

        // Second Row
        if (designs[index + 1]?.description) {
          drillsTable[index + 1].push({
            text: designs[index + 1]?.description,
            style: 'drillDescription',
          })
        } else {
          drillsTable[index + 1].push({})
        }
      }

      return null
    })

    content.push({
      layout: 'noBorders',
      table: {
        dontBreakRows: true,
        widths: ['*', '*'],
        body: drillsTable,
      },
    })
  }

  return content
}

export default drillPdfContent
