import { IconButton, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import MyPlayIcon from '../../assets/icons/myPlayIcon'
import livestreamBannerMediaStyles from './livestreamBannerMedia.styles'
import broadcastSwitch from '../../Apis/livestream/broadcastSwitch'
import useQueryParams from '../../hooks/useQueryParams'
import useTimer from '../../hooks/useTimer'
import COLORS from '../../utils/constants/colors'

const LivestreamBannerMedia = (props) => {
  const { media, broadcast } = props
  const { activeVideo, id: broadcastId } = broadcast
  const [clickedMedia, setClickedMedia] = useState('')
  const { timeElapsed, resetTimer } = useTimer(2000)
  const queryParams = useQueryParams()
  const { org } = queryParams
  const useStyles = makeStyles(livestreamBannerMediaStyles)
  const classes = useStyles()

  // TODO: Animation restarts when running-broadcasts request happens, because component re-renders
  // Reset state to stop animation to happen on every render
  useEffect(() => {
    if (timeElapsed) setClickedMedia('')
  }, [timeElapsed])

  const switchBroadcast = useMutation((data) => broadcastSwitch(data))

  const handleVideoPlayButton = (e, switchToMediaId) => {
    resetTimer()
    setClickedMedia(switchToMediaId)
    switchBroadcast.mutate({ switchToMediaId, orgId: org, broadcastId })
  }

  return (
    <div className={classes.mediaContainer}>
      {media.map((video) => {
        const isMediaPlaying = activeVideo ? activeVideo.includes(video.id) : false
        return (
          <div
            key={video.id}
            className={
              clickedMedia === video.id
                ? classes.videoContainerClicked
                : classes.videoContainerNotClicked
            }
          >
            <Typography style={{ flex: 2 }}>{video.fileName}</Typography>
            <IconButton onClick={(e) => handleVideoPlayButton(e, video.id)}>
              <MyPlayIcon
                style={{ flex: 1, fill: isMediaPlaying ? COLORS.orange : COLORS.white }}
              />
            </IconButton>
          </div>
        )
      })}
    </div>
  )
}

export default LivestreamBannerMedia
