import React from 'react'
import { Admin } from 'react-admin'
import { QueryClient, QueryClientProvider } from 'react-query'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { enGB } from 'date-fns/locale'
import { createBrowserHistory } from 'history'
import authProvider from './react-admin/providers/authProvider'
import i18nProvider from './react-admin/providers/i18nProvider'
import Dashboard from './react-admin/components/Dashboard/Dashboard'
import metargusTheme from './utils/constants/metargusTheme'
import dataProviderIndex from './react-admin/providers'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import MyLayout from './react-admin/components/layout/MyLayout/MyLayout'
import LoginPage from './pages/Login/LoginPage'
import routesWithAuth from './utils/helpers/routesWithAuth'
import resourcesWithAuth from './utils/helpers/resourcesWithAuth'
// App.scss should be imported last in order to not be overwritten by default styles.
import './App.scss'
import localStorageIds from './utils/constants/localStorageIds'
import decodeJwt from 'jwt-decode'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: () => {
        const token = localStorage.getItem(localStorageIds.TOKEN)
        const { orgScopes, orgRoles } = decodeJwt(token)
        if (!orgScopes || !orgRoles) {
          localStorage.removeItem(localStorageIds.TOKEN)
        }
      },
    },
  },
})

const App = () => {
  const customRoutes = routesWithAuth()
  const resources = resourcesWithAuth()
  const history = createBrowserHistory()

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
      <QueryClientProvider client={queryClient}>
        <Admin
          history={history} // Used to remove "#" from url
          theme={metargusTheme}
          loginPage={LoginPage}
          authProvider={authProvider}
          dataProvider={dataProviderIndex}
          dashboard={Dashboard}
          customRoutes={customRoutes}
          i18nProvider={i18nProvider}
          layout={MyLayout}
        >
          {resources}
        </Admin>
      </QueryClientProvider>
    </MuiPickersUtilsProvider>
  )
}

export default App
