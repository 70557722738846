import getDrill from '../../../../Apis/drill/getDrill'

const playBreadcrumbEdit = async (data) => {
  const { match } = data || {}
  const { playId } = match.params

  if (playId === 'create') return

  const playTitle = await getDrill(playId).then((play) => play.title)

  return ['PLAYS', playTitle ? playTitle.toUpperCase() : '']
}

export default playBreadcrumbEdit
