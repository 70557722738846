import { useHistory } from 'react-router-dom'
import useQueryParams from './useQueryParams'

/*
 redirect(pathNameToRedirect, addedParams, removedParams)

 How to use: redirect(pathname, [{ startDate: parseDateUrl(startDate) }, { endDate: parseDateUrl(endDate) }])
*/

const useHistoryPush = () => {
  const queryParams = useQueryParams()
  const history = useHistory()

  const redirect = (pathname, newSearchParams, removeSearchParams) => {
    let queryString = {}
    const queryParamsKeys = Object.keys(queryParams)

    queryParamsKeys.forEach((key) => {
      const removedKey =
        removeSearchParams && removeSearchParams.find((searchParam) => searchParam === key)
      if (!removedKey) {
        return (queryString[key] = queryParams[key])
      }
      return queryString
    })

    if (newSearchParams && newSearchParams.length > 0) {
      newSearchParams.map((newSearchParam) => {
        const objectKey = Object.keys(newSearchParam)[0]
        const objectValue = Object.values(newSearchParam)[0]
        return (queryString[objectKey] = objectValue)
      })
    }

    // Map objectString to string
    const parseQueryString = () => {
      const queryStringEntries = Object.entries(queryString)
      let search = ''
      queryStringEntries.forEach((query) => (search = search + `&${query[0]}=${query[1]}`))

      return search
    }

    if (pathname.charAt(0) !== '/') {
      pathname = `/${pathname}`
    }

    history.push({
      pathname,
      search: `?${parseQueryString().substring(1)}`,
    })
  }

  return {
    redirect,
    goBack: () => history.goBack(),
    pathname: history.location.pathname,
  }
}

export default useHistoryPush
