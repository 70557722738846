import * as React from 'react'
import { useTranslate } from 'react-admin'
import { useMutation } from 'react-query'
import * as yup from 'yup'
import { ErrorMessage, Form, Formik } from 'formik'
import { Link, makeStyles } from '@material-ui/core'
import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import MyTextField from '../../../components/form/fields/MyTextField'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import MyTextFieldPassword from '../../../components/form/fields/MyTextFieldPassword'
import useHistoryPush from '../../../hooks/useHistoryPush'
import signUpFormStyles from './signUpForm.styles'
import SignUpFormAcceptTerms from '../SignUpFormAcceptTerms'
import MyBooleanField from '../../../components/form/fields/MyBooleanField'
import useFormValidation from '../../../hooks/useFormValidation'
import signUp from '../../../Apis/user/signUp'
import MySnackbar from '../../../components/MySnackbar/MySnackbar'

const SignUpForm = () => {
  const reCaptchaRef = useRef()
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const translate = useTranslate()
  const { redirect } = useHistoryPush()
  const { email, password, boolean } = useFormValidation()
  const useStyles = makeStyles((theme) => signUpFormStyles(theme))
  const classes = useStyles()

  const createAccount = useMutation((data) => signUp(data), {
    onSuccess: (data, values) => {
      reCaptchaRef.current.reset()
      redirect('/send-confirmation-email', [{ initialEmail: values.email }])
    },
    onError: (data) => {
      reCaptchaRef.current.reset()
      setErrorMessage(data?.response?.data?.Message)
      setIsSnackbarOpen(true)
    },
  })

  const onCloseSnackbar = () => setIsSnackbarOpen(false)

  const onSubmit = async (data) => {
    const captchaToken = await reCaptchaRef.current.executeAsync()

    const { electronicMail, confirmTerms, ...rest } = data
    createAccount.mutate({
      ...rest,
      email: electronicMail,
      captchaToken,
    })
  }

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        electronicMail: '',
        password: '',
        acceptTerms: false,
      }}
      validationSchema={yup.object().shape({
        electronicMail: email,
        password,
        acceptTerms: boolean(translate('ra.validationErrors.terms')),
      })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(data) => onSubmit(data)}
    >
      {() => (
        <Form>
          <div className={classes.formWrapper}>
            <MyTextField label={translate('ra.formFields.firstName')} name='firstName' required />
            <MyTextField label={translate('ra.formFields.lastName')} name='lastName' required />
            <MyTextField label={translate('ra.formFields.email')} name='electronicMail' required />
            <MyTextFieldPassword
              label={translate('ra.formFields.password')}
              name='password'
              required
            />
            <div>
              <div className={classes.termWrapper}>
                <MyBooleanField name='acceptTerms' />
                <SignUpFormAcceptTerms />
              </div>
              <ErrorMessage component='div' name='acceptTerms' className={classes.errorMessage} />
            </div>
            <ButtonWithLoader
              fullWidth
              label={translate('ra.page.signUp')}
              style={{ marginTop: 10 }}
              loading={createAccount.isLoading}
            />
            <Link href='/login' variant='body2' className={classes.link}>
              {translate('ra.gridLinks.login')}
            </Link>
          </div>
          <ReCAPTCHA
            ref={reCaptchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            size='invisible'
          />
          <MySnackbar
            isOpen={isSnackbarOpen}
            onClose={onCloseSnackbar}
            errorMessage={errorMessage ?? translate('ra.errorMessages.signUpError')}
          />
        </Form>
      )}
    </Formik>
  )
}

export default SignUpForm
