import React from 'react';
import { Typography } from '@material-ui/core'
import ContainerPadding from "../../containerPadding/containerPadding";
import packageJson from '../../../../package.json';

const VersionNumber = () => (
  <ContainerPadding padding='0 2% 0 2%'>
    <Typography variant="subtitle2">
      {`V ${packageJson.version}`}
    </Typography>
  </ContainerPadding>
)

export default VersionNumber;
