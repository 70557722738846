import chipsRowPdfHelper from '../../../utils/helpers/pdfHelpers/chipsRowPdfHelper'
import renderDesignOnCanvas from '../../../utils/helpers/pdfHelpers/renderDesignsOnCanvas'
import Stadium from '../../../assets/images/FullCourt.png'
import HalfStadium from '../../../assets/images/HalfCourt.png'

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = src
    image.onload = () => resolve(image)
    image.onerror = reject
  })
}
const playPdfContent = async (data) => {
  const { trainingModule } = data
  const { title, description, designs, tactics } = trainingModule || {}
  const content = []

  const CANVAS_WIDTH = 528
  const CANVAS_HEIGHT = 800

  const stadiumImage = await loadImage(Stadium)
  const halfStadiumImage = await loadImage(HalfStadium)

  // Set Title
  if (title && title !== 'Untitled') {
    content.push({
      text: title,
      style: 'title',
    })
  }

  const tacticsChips = chipsRowPdfHelper(tactics, null, 'chipRow', 'chipName')

  // Set Tactics
  // Empty text to add top margin
  content.push([{ text: '', style: 'chipsMarginTop' }, tacticsChips ? tacticsChips.chips : []])

  // Set Description
  if (description) {
    content.push({
      text: description,
      style: 'subtitle',
    })
  }

  // Set Designs
  if (designs && designs?.length > 0) {
    const playsTable = []

    designs.map((design, index) => {
      const { isZoomedIn } = JSON.parse(design.source)
      const jsonData = JSON.parse(design.source)

      const canvas = renderDesignOnCanvas(
        jsonData,
        stadiumImage,
        halfStadiumImage,
        CANVAS_WIDTH,
        CANVAS_HEIGHT,
      )

      return playsTable.push([
        {
          stack: [
            {
              image: canvas.toDataURL(),
              width: 200,
              style: 'drillDesign',
            },
            {
              text: `${index + 1}/${designs?.length}`,
              style: 'designCount',
              absolutePosition: { x: 22, y: 42 },
            },
          ],
        },
        {
          text: design.description,
          style: !isZoomedIn ? 'playDescriptionFullDesign' : 'playDescriptionHalfDesign',
        },
      ])
    })

    // Push designsTable to pdf content
    content.push({
      layout: 'noBorders',
      table: {
        dontBreakRows: true,
        body: playsTable,
      },
    })
  }

  return content
}

export default playPdfContent
