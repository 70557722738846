import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

const MyCloudDownloadIcon = (props) => {
  const useStyles = makeStyles({
    addIcon: {
      cursor: 'pointer',
    },
  })
  const classes = useStyles()

  return <CloudDownloadIcon {...props} classes={{ root: classes.addIcon }} fontSize='medium' />
}

export default MyCloudDownloadIcon
