import { Typography } from '@material-ui/core'
import COLORS from '../../../utils/constants/colors'
import MyChip from '../../../components/MyChip/MyChip'
import { useEffect } from 'react'

const AvailableSeatsDisplay = (props) => {
  const { orgAddonSeat, addedScopes, setAreSeatsValid } = props
  const enabledAddons = orgAddonSeat?.filter((addon) => addon.enabled && !addon.isOrgLevel)
  const addedAddons = Array.isArray(addedScopes)
    ? addedScopes.reduce((acc, item) => {
        item.scopes.forEach((scope) => {
          acc[scope] = (acc[scope] || 0) + 1
        })
        return acc
      }, {})
    : addedScopes

  useEffect(() => {
    const areAllAddonsValid = !enabledAddons.every(
      (addon) => addon.seats.free - (addedAddons[addon?.id] || 0) >= 0,
    )
    setAreSeatsValid(areAllAddonsValid)
  }, [addedScopes])

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
      <Typography variant='subtitle1'>Available Seats: </Typography>
      {enabledAddons.map((addon) => (
        <>
          <Typography variant='subtitle1' color='textPrimary'>
            {addon.id}:
          </Typography>
          <MyChip
            label={addon?.seats?.free - (addedAddons[addon?.id] || 0)}
            color={
              addon?.seats?.free - (addedAddons[addon?.id] || 0) >= 0
                ? COLORS.subscriptionBlue
                : COLORS.errorRed
            }
            small
          />
        </>
      ))}
    </div>
  )
}

export default AvailableSeatsDisplay
