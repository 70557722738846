import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import COLORS from '../../utils/constants/colors'

const MyCheckCircleIcon = (props) => {
  const { width, height } = props

  const useStyles = makeStyles({
    checkIcon: {
      cursor: 'pointer',
      padding: 2,
    },
  })
  const classes = useStyles()

  return (
    <svg
      {...props}
      className={classes.checkIcon}
      width={width ?? 24}
      height={height ?? 24}
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='11.5' cy='11.5' r='11.5' fill={COLORS.orange} />
      <path
        d='M9.75012 15.1275L6.62262 12L5.55762 13.0575L9.75012 17.25L18.7501 8.25L17.6926 7.1925L9.75012 15.1275Z'
        fill='white'
      />
    </svg>
  )
}

MyCheckCircleIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default MyCheckCircleIcon
