const parsePatchPracticePlan = ({
  practicePlan,
  formUpdatePracticePlanBody,
  clickedComponent,
  newTrainingModule,
  designs,
  newTrainingModuleRevisionId,
  closeDesignerModal,
}) => {
  let trainingModuleComp
  if (newTrainingModuleRevisionId) {
    trainingModuleComp = {
      duration: 0,
      order: practicePlan?.practicePlanComponents
        ? Math.max(...practicePlan?.practicePlanComponents.map((comp) => comp.order)) + 1
        : 0,
      notes: '',
      trainingModuleId: newTrainingModuleRevisionId,
    }
  } else {
    trainingModuleComp = {
      id: clickedComponent?.id,
      duration: clickedComponent?.duration,
      order: clickedComponent?.order,
      notes: clickedComponent?.notes,
      trainingModuleForCreationDto: {
        ...newTrainingModule,
        design: null,
        designs: designs.map((design) => {
          return {
            source: design.source,
            preview: design.preview,
            description: design.description,
            order: design.order,
          }
        }),
      },
    }
  }

  return formUpdatePracticePlanBody(null, trainingModuleComp, closeDesignerModal)
}

export default parsePatchPracticePlan
