import COLORS from '../../../utils/constants/colors'
import { CARD_STYLES } from '../../../utils/constants/CSS/cardStyles'

const orgUsersPageStyles = {
  card: CARD_STYLES.card,
  cardTitle: CARD_STYLES.cardTitle,
  cardSubtitle: {
    ...CARD_STYLES.cardSubtitle,
  },
  loaderIcon: {
    fill: COLORS.orange,
    marginRight: '48%',
    marginLeft: '48%',
    marginTop: 20,
    width: 70,
    height: 70,
  },
  addButtonWrapper: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: 15,
    gap: 15
  },
}
export default orgUsersPageStyles
