export const noLayoutContainerStyles = {
  wrapper: {
    height: 'calc(100dvh + 14px)',
    overflow: 'auto',
    textAlign: 'center',
  },
  formWrapper: {
    width: 350,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}

export const logoStyles = {
  padding: '3.5% 0 30px 0 ',
}
