import getCurrentDateClassicFormat from '../../../utils/helpers/dateHelpers/getCurrentDateClassicFormat'

const playbookPdfFooter = (currentPage) => {
  const footerTable = []

  footerTable.push({
    svg:
      '<svg width="95" height="88" viewBox="0 0 95 88" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M94.0547 88C86.5429 86.3611 79.7246 83.2197 75.2503 77.083C66.4936 65.0865 70.7032 46.4341 60.5206 35.641C49.9944 24.4812 31.0605 28.6005 17.5871 21.2999C9.39382 16.8641 4.46312 9.06061 0.00019074 0.663757L0.000183105 88H94.0547Z" fill="#FF7B5B"/>\n' +
      '</svg>\n',
    absolutePosition: {
      x: 0,
      y: -48,
    },
    style: 'playbookFooterLogo',
  })

  if (currentPage === 1) {
    footerTable.push({
      text: getCurrentDateClassicFormat(),
      relativePosition: {
        x: -15,
        y: 15,
      },
      style: 'playbookFooterDate',
    })
  }

  return footerTable
}

export default playbookPdfFooter
