import React from 'react'
import PrintIcon from '@material-ui/icons/Print'
import { makeStyles } from '@material-ui/core'
import COLORS from '../../utils/constants/colors'

const MyPdfIcon = (props) => {
  const useStyles = makeStyles({
    pdfIcon: {
      fill: COLORS.orange,
      cursor: 'pointer',
    },
  })
  const classes = useStyles()

  return <PrintIcon {...props} classes={{ root: classes.pdfIcon }} fontSize='medium' />
}

export default MyPdfIcon
