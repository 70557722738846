import localStorageHelper from './localStorageHelper'
import localStorageIds from '../constants/localStorageIds'
import isUserScopesValid from './isUserScopesValid'

const userHasOnlySpecificScopes = (allowedScopes) => {
  const { localStorageValue: userScopes } = localStorageHelper(localStorageIds.SCOPES)
  const { localStorageValue: selectedOrg } = localStorageHelper(localStorageIds.SELECTED_ORG)

  if (!userScopes[selectedOrg?.id]) return false

  return (
    isUserScopesValid(allowedScopes) && userScopes[selectedOrg?.id].length === allowedScopes.length
  )
}

export default userHasOnlySpecificScopes
