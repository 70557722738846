import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const TitleField = (props) => {
  const { title } = props
  const useStyles = makeStyles({
    root: {
      fontSize: '24px',
      flex: 1,
      lineHeight: '32px',
    },
  })
  const classes = useStyles()

  return <div className={classes.root}>{title}</div>
}

TitleField.propTypes = {
  title: PropTypes.string,
}

export default TitleField
