import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { SaveButton, useNotify, useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import { useForm } from 'react-final-form'
import createAndAddButtonStyles from './createAndAddButton.styles'
import loadingDots from '../../../../../assets/images/loading-dots.svg'

const CreateAndAddButton = (props) => {
  const { saving, label, ...rest } = props
  const form = useForm()
  const handleClick = useCallback(() => {
    form.setConfig('keepDirtyOnReinitialize', false)
    form.restart()
  }, [form])
  const notify = useNotify()
  const translate = useTranslate()
  const useStyles = makeStyles(createAndAddButtonStyles)
  const classes = useStyles()

  return (
    <SaveButton
      {...rest}
      label={saving ? <img alt='img' src={loadingDots} className={classes.loadingDots} /> : label}
      onClick={handleClick}
      classes={{ button: classes.button }}
      onSuccess={() =>
        notify(`${translate('ra.notification.created')}`, 'success', undefined, false, 5000)
      }
    />
  )
}

CreateAndAddButton.propTypes = {
  saving: PropTypes.bool,
  label: PropTypes.string,
}

export default CreateAndAddButton
