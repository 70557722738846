const unauthorizedPageStyles = {
  flexCont: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexText: {
    display: 'flex',
    justifyContent: 'center',
  },
}

export default unauthorizedPageStyles
