import COLORS from '../../utils/constants/colors'

const practicePlanInfoRowStyles = (onContainerClick) => {
  return {
    divFlex: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      cursor: onContainerClick && 'pointer',
    },
    icon: {
      marginRight: '5px',
    },
    topic: {
      color: COLORS.darkGrey,
    },
    handballIcon: {
      marginLeft: 'auto',
    },
    componentsLength: {
      padding: '4px 8px 4px 8px',
      backgroundColor: '#7d6bbd',
      borderRadius: '9px',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '12px',
      color: COLORS.white,
    },
  }
}

export default practicePlanInfoRowStyles
