import { Form, Formik } from 'formik'
import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslate } from 'react-admin'
import MyTextField from '../../form/fields/MyTextField'
import supportBubbleFormStyles from './supportBubbleForm.styles'
import ContainerPadding from '../../containerPadding/containerPadding'
import ButtonWithLoader from '../../ui/ButtonWithLoader/ButtonWithLoader'

const SupportBubbleForm = (props) => {
  const { sendSupportEmailMutation, title, message, setCollapseForm } = props
  const translate = useTranslate()
  const useStyles = makeStyles(supportBubbleFormStyles)
  const classes = useStyles()

  const handleOnSubmit = (formikValues) => {
    sendSupportEmailMutation.mutate(formikValues)
  }

  return (
    <ContainerPadding className={classes.container} vertical>
      <Typography variant='h6' hidden={!!title}>
        {translate('ra.text.supportBubbleHeader')}
      </Typography>
      <Typography variant='subtitle1' hidden={!!message}>
        {translate('ra.text.supportBubbleText')}
      </Typography>
      <Formik
        initialValues={{ Subject: title || '', Body: '' }}
        onSubmit={(values) => handleOnSubmit(values)}
      >
        {({ values }) => (
          <Form style={{ width: '95%' }}>
            {!title && (
              <MyTextField
                className={classes.formField}
                name='Subject'
                label='Title'
                disabled={!!title}
              />
            )}

            <MyTextField
              className={classes.formField}
              name='Body'
              label='Message'
              multiline
              minRows={5}
              required
            />
            <div className={classes.buttonContainer}>
              <ButtonWithLoader
                label='SEND MESSAGE'
                disabled={!values.Body}
                loading={sendSupportEmailMutation.isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </ContainerPadding>
  )
}

SupportBubbleForm.defaultProps = {
  setCollapseForm: () => {},
}

export default SupportBubbleForm
