import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import QueryKeys from '../../utils/constants/queryKeys'
import getTactics from '../../Apis/tactics/getTactics'
import TacticsAccordion from '../../components/TacticsAccordion/TacticsAccordion'

const TacticsPage = () => {
  const { data } = useQuery([QueryKeys.GET_TACTICS], getTactics, { staleTime: 0 })

  const [tactics, setTactics] = useState([])

  useEffect(() => {
    if (data) {
      setTactics(data)
    }
  }, [data])

  return <TacticsAccordion onTagClick={() => {}} tactics={tactics} />
}

export default TacticsPage
