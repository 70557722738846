import React from 'react'
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import PropTypes from 'prop-types'

const MySnackbar = (props) => {
  const { isOpen, onClose, isSuccess, successMessage, errorMessage, autoHideDuration } = props

  return (
    <Snackbar open={isOpen} autoHideDuration={autoHideDuration ?? 6000} onClose={onClose}>
      <Alert
        elevation={6}
        variant='filled'
        onClose={onClose}
        severity={isSuccess ? 'success' : 'error'}
      >
        {isSuccess ? successMessage : errorMessage}
      </Alert>
    </Snackbar>
  )
}

MySnackbar.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isSuccess: PropTypes.bool,
  successMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  errorMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  autoHideDuration: PropTypes.number,
}

export default MySnackbar
