const addPracticePlanTypePopoverStyles = {
  popover: {
    padding: '5px 0',
  },
  addButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}

export default addPracticePlanTypePopoverStyles
