import * as React from 'react'
import { Checkbox, makeStyles } from '@material-ui/core'
import { Field } from 'formik'
import PropTypes from 'prop-types'

const MyBooleanField = (props) => {
  const { name, disabled, size } = props
  const useStyles = makeStyles({
    checkboxRoot: {
      padding: 0,
    },
  })
  const classes = useStyles()

  return (
    <Field
      {...props}
      className={classes.checkboxRoot}
      name={name}
      disabled={disabled}
      type='checkbox'
      size={size ?? 'small'}
      as={Checkbox}
      color='primary'
    />
  )
}

MyBooleanField.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
}

export default MyBooleanField
