import axiosInstance from '../axiosInterceptor/axiosInstance'

const disableUserAccount = async ({ password }) => {
  const url = '/users/me'
  const response = await axiosInstance.delete(url, {
    data: {
      Password: password,
    },
  })
  return response?.data
}

export default disableUserAccount
