import COLORS from '../../../utils/constants/colors'

const passOwnershipModalStyles = {
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 300,
    paddingLeft: '2%',
    paddingRight: '2%',
  },
  tableTitle: { color: COLORS.grey },
  cancelButton: {
    '&&': {
      color: COLORS.orange,
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    bottom: '5px',
    gap: 20,
    marginTop: '30px',
    marginBottom: '20px',
    marginLeft: '20px',
  },
  successModal: {
    display: 'flex',
    marginBottom: '20px',
  },
  successIcon: {
    color: '#83B061',
    marginRight: '15px',
  },
  loaderIcon: {
    fill: COLORS.orange,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 20,
    width: 70,
    height: 70,
  },
  emailContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
}

export default passOwnershipModalStyles
