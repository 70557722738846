import { SPACING, TYPOGRAPHY } from './generalPdfStyles'

const playPdfStyles = {
  headerLogo: {
    alignment: 'right',
    marginTop: SPACING.M,
    marginRight: SPACING.M,
  },
  title: {
    marginTop: SPACING.S,
    alignment: 'center',
    fontSize: TYPOGRAPHY.XXL,
    bold: true,
  },
  subtitle: {
    marginTop: SPACING.L,
  },
  drillDesign: {
    marginTop: SPACING.L,
  },
  designCount: {
    fontSize: TYPOGRAPHY.XXS,
  },
  playDescriptionFullDesign: {
    marginTop: 164,
    fontSize: TYPOGRAPHY.S,
  },
  playDescriptionHalfDesign: {
    marginTop: 94,
    fontSize: TYPOGRAPHY.S,
  },
  chipRow: {
    alignment: 'center',
    marginTop: SPACING.XS,
  },
  chipName: {
    fontSize: TYPOGRAPHY.XXS,
  },
  chipsMarginTop: {
    marginTop: SPACING.M,
  },
}

export default playPdfStyles
