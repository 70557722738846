import React from 'react'
import { useQuery } from 'react-query'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import orgLogoStyles from './orgLogo.styles'
import DefaultOrgIcon from '../../assets/icons/DefaultOrgIcon'
import redirectToHomeHelper from '../../utils/helpers/redirectToHomeHelper'
import useHistoryPush from '../../hooks/useHistoryPush'
import useQueryParams from '../../hooks/useQueryParams'
import QueryKeys from '../../utils/constants/queryKeys'
import getTeams from '../../Apis/team/getTeams'

const OrgLogo = ({ singleOrgLogo, myOrgs, showCreateOrgTeamModal }) => {
  const { redirect } = useHistoryPush()
  const queryParams = useQueryParams()
  const { org } = queryParams
  const useStyles = makeStyles(orgLogoStyles)
  const classes = useStyles()

  const { data } = useQuery([QueryKeys.GET_TEAMS, org], () => org && getTeams(org))

  const handleOnClick = () => {
    if (myOrgs && myOrgs.length > 0) {
      redirectToHomeHelper(data, redirect)
    } else {
      showCreateOrgTeamModal()
    }
  }

  return singleOrgLogo ? (
    <img src={singleOrgLogo} alt='avatar' className={classes.logoSmall} onClick={handleOnClick} />
  ) : (
    <DefaultOrgIcon onClick={handleOnClick} className={classes.orgIcon} />
  )
}

OrgLogo.propTypes = {
  singleOrgLogo: PropTypes.string,
}

export default OrgLogo
