import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Create, SimpleForm, ArrayInput, ReferenceArrayInput, required } from 'react-admin'
import { useQuery } from 'react-query'
import stringWithComma from '../../../../utils/helpers/stringWithComma'
import CustomIterator from '../../../components/CustomIterator/CustomIterator'
import playerCreateStyles from './playerCreate.styles'
import MyNumberInput from '../../../components/inputs/MyNumberInput'
import MyTextInput from '../../../components/inputs/MyTextInput'
import MyAutocompleteArrayInput from '../../../components/inputs/MyAutocompleteArrayInput'
import FormGrid from '../../../components/layout/FormGrid/FormGrid'
import PlayerCreateToolbar from '../PlayerCreateToolbar/PlayerCreateToolbar'
import MyAddLogoInput from '../../../components/inputs/MyAddLogoInput'
import MyDateField from '../../../components/inputs/MyDateField'
import useQueryParams from '../../../../hooks/useQueryParams'
import QueryKeys from '../../../../utils/constants/queryKeys'
import getTeams from '../../../../Apis/team/getTeams'

const PlayerCreate = (props) => {
  const { syncWithLocation, ...rest } = props
  const queryParams = useQueryParams()
  const { team, org } = queryParams
  const useStyles = makeStyles(playerCreateStyles)
  const classes = useStyles()

  const teams = useQuery([QueryKeys.GET_TEAMS, org || null], () => org && getTeams(org))
  const currentTeam =
    teams && teams.data && teams.data.length > 0 && teams.data.find((t) => t.id === team)

  return (
    <Create {...rest}>
      <SimpleForm
        toolbar={<PlayerCreateToolbar />}
        initialValues={{ logo: null, teams: [{ team: currentTeam ? currentTeam.id : '' }] }}
        className={classes.root}
      >
        <FormGrid container spacing={2}>
          <MyAddLogoInput source='logo' />
        </FormGrid>

        <FormGrid container spacing={2}>
          <MyTextInput source='firstName' validate={[required()]} sm={12} />
          <MyTextInput source='lastName' validate={[required()]} sm={12} />
        </FormGrid>

        <FormGrid container spacing={2}>
          <MyDateField
            source='dateOfBirth'
            label='Date Of Birth'
            gridItemStyle={classes.dateField}
          />
          <MyNumberInput
            source='height'
            parse={(height) => stringWithComma(height)}
            step={0.1}
            min={0}
          />
        </FormGrid>

        <FormGrid container spacing={2}>
          <ReferenceArrayInput reference='positions' source='positions'>
            <MyAutocompleteArrayInput source='positions' resettable />
          </ReferenceArrayInput>
        </FormGrid>

        <ArrayInput source='teams' label='' classes={{ root: classes.arrayInputRoot }}>
          <CustomIterator org={org} fieldArrayName='teams' />
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
}

export default PlayerCreate
