import React, { useRef, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { useQuery } from 'react-query'
import PropTypes from 'prop-types'
import MyToggleButton from '../../../components/MyToggleButton/MyToggleButton'
import AddButton from '../../../components/ui/AddButton/AddButton'
import MyFilterIcon from '../../../assets/icons/myFilterIcon'
import QueryKeys from '../../../utils/constants/queryKeys'
import getIntensities from '../../../Apis/drill/getIntesities/getIntensities'
import getPositions from '../../../Apis/drill/getPositions/getPositions'
import DrillListAppbarFiltersMenu from '../DrillListAppbarFiltersMenu/DrillListAppbarFiltersMenu'
import DrillListAppbarSelectField from '../DrillListAppbarSelectField/DrillListAppbarSelectField'
import { DRILLS_FILTERS_TYPES } from '../../../utils/constants/drillsFiltersTypes'
import SearchField from '../../../components/ui/SearchField/SearchField'
import useQueryParams from '../../../hooks/useQueryParams'
import TacticsModal from '../../../components/TacticsModal/TacticsModal'
import TacticsSelectField from '../../../components/TacticsSelectField/TacticsSelectField'
import useHistoryPush from '../../../hooks/useHistoryPush'
import tacticClickedType from '../../../types/tactics/tacticClickedType'
import LoaderCircle from '../../../components/loaders/LoaderCircle/LoaderCircle'
import drillListAppbarStyles from './DrillListAppbar.styles'
import useTour from '../../../hooks/useTour'
import TOUR_IDS from '../../../utils/constants/introIds'
import COLORS from '../../../utils/constants/colors'

const DrillListAppbar = (props) => {
  const {
    resource,
    toggleValue,
    setToggleValue,
    filters,
    setFilters,
    tactics,
    setTactics,
    searchValue,
    setSearchValue,
    isLoading,
  } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [showSelectFilter, setShowSelectFilter] = useState([])
  const [tacticsModalOpen, setTacticsModalOpen] = useState(false)
  const translate = useTranslate()
  const { redirect } = useHistoryPush()
  const { intensity, position } = DRILLS_FILTERS_TYPES
  const queryParams = useQueryParams()
  const useStyles = makeStyles(drillListAppbarStyles)
  const classes = useStyles()
  const showDrillsFilters = resource === 'drills'
  const elRef = useRef()

  const pageType = () => {
    if (showDrillsFilters) {
      return 'drills'
    }
    return 'plays'
  }

  useTour({
    elRef,
    tourName: `${pageType()}-tour`,
    tourSteps: pageType(),
  })

  useEffect(() => {
    if (queryParams.position && showSelectFilter.indexOf(position) === -1) {
      setShowSelectFilter([...showSelectFilter, position])
    } else if (queryParams.intensity && showSelectFilter.indexOf(intensity) === -1) {
      setShowSelectFilter([...showSelectFilter, intensity])
    }
  }, [queryParams]) // eslint-disable-line react-hooks/exhaustive-deps

  const intensities = useQuery([QueryKeys.GET_INTENSITIES], () => getIntensities(), {
    staleTime: Infinity,
  })

  const positions = useQuery([QueryKeys.GET_POSITIONS], () => getPositions(), {
    staleTime: Infinity,
  })

  const onMenuItemClick = (name) => {
    if (!showSelectFilter.find((filter) => filter === name)) {
      setShowSelectFilter([...showSelectFilter, name])
    }
  }

  const setFiltersType = (type, values) => {
    if (type === intensity) {
      setFilters({
        ...filters,
        intensity: values,
      })
    } else {
      setFilters({
        ...filters,
        position: values,
      })
    }
  }

  const onCloseField = (name) => {
    setShowSelectFilter(showSelectFilter.filter((filter) => filter !== name))
    name === position ? setFiltersType(position, []) : setFiltersType(intensity, [])
  }

  const handleChangePositions = (values, option) => {
    if (option === 'ALL' && values.indexOf('ALL') === -1) {
      setFiltersType(position, values)
    } else if (option === 'ALL' && values.indexOf('ALL') > -1) {
      setFiltersType(position, ['ALL'])
    } else if (values.length === positions?.data.length - 1) {
      setFiltersType(position, ['ALL'])
    } else {
      const newValues = values.filter((value) => value !== 'ALL')
      setFiltersType(position, newValues)
    }
  }

  const handleIntensitiesDeleteChip = (values, option) => {
    const newValues = values.filter((value) => value !== option)
    setFiltersType(intensity, newValues)
  }

  const handlePositionsDeleteChip = (values, option) => {
    const newValues = values.filter((value) => value !== option)
    handleChangePositions(newValues, option)
  }

  return (
    <div ref={elRef} className={classes.wrapper}>
      <MyToggleButton
        left={{
          value: 'private',
          label: translate('ra.buttons.personal'),
        }}
        right={{
          value: 'public',
          label: translate('ra.buttons.public'),
        }}
        setToggleValue={setToggleValue}
        toggleValue={toggleValue}
      />

      <SearchField
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        reset={() => setSearchValue('')}
        variant='filled'
      />

      <LoaderCircle loading={isLoading} />
      <DrillListAppbarSelectField
        isVisible={showDrillsFilters && showSelectFilter.some((filter) => filter === intensity)}
        options={intensities?.data}
        values={filters[intensity] ? filters[intensity] : []}
        label={translate('ra.formFields.intensity')}
        onCloseField={() => onCloseField(intensity)}
        onChange={(e) => setFiltersType(intensity, e.target.value)}
        onDeleteChip={(values, option) => handleIntensitiesDeleteChip(values, option)}
      />
      <DrillListAppbarSelectField
        isVisible={showDrillsFilters && showSelectFilter.some((filter) => filter === position)}
        options={positions?.data}
        values={filters[position] ? filters[position] : []}
        label={translate('ra.formFields.positions')}
        onCloseField={() => onCloseField(position)}
        onChange={(e, option) => handleChangePositions(e.target.value, option.props.value)}
        onDeleteChip={(values, option) => handlePositionsDeleteChip(values, option)}
      />
      <TacticsSelectField
        tactics={tactics}
        onOpen={() => setTacticsModalOpen(true)}
        onCloseField={() => setTactics([])}
      />
      <TacticsModal
        open={tacticsModalOpen}
        onClose={() => setTacticsModalOpen(false)}
        initialTags={tactics}
        setTags={(tagsClicked) => setTactics(tagsClicked)}
      />
      <div className={classes.buttonsWrapper}>
        <AddButton
          label={translate('ra.action.add_filter')}
          icon={<MyFilterIcon style={{ fill: COLORS.orange }} />}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          filled
        />
        <DrillListAppbarFiltersMenu
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          onMenuItemClick={onMenuItemClick}
          onTacticsItemClick={() => setTacticsModalOpen(true)}
          showSelectFilter={showSelectFilter}
          showDrillsFilters={showDrillsFilters}
          tactics={tactics}
        />
        <AddButton
          label={translate('ra.buttons.createSmall')}
          id={TOUR_IDS.drills.add}
          onClick={() => {
            if (resource === 'drills') {
              redirect('drills/create')
            } else if (resource === 'plays') {
              redirect('plays/create')
            }
          }}
          filled
        />
      </div>
    </div>
  )
}

DrillListAppbar.propTypes = {
  resource: PropTypes.string,
  toggleValue: PropTypes.string,
  setToggleValue: PropTypes.func,
  filters: PropTypes.shape({
    intensity: PropTypes.arrayOf(PropTypes.string),
    position: PropTypes.arrayOf(PropTypes.string),
  }),
  setFilters: PropTypes.func,
  tactics: PropTypes.arrayOf(tacticClickedType),
  setTactics: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default DrillListAppbar
