import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import Popover from '@material-ui/core/Popover'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import { useTranslate } from 'react-admin'
import NotificationItem from '../InviteNotification/NotificationItem'
import useHistoryPush from '../../hooks/useHistoryPush'
import NotificationIcon from '../../assets/icons/NotificationsIcon'
import popoverStyles from '../../utils/constants/popoverStyles'
import './notifications.css'
import INVOKE_FUNCTIONS from '../../utils/constants/invokeFunctions'
import notificationSound from '../../assets/sounds/notification.mp3'
import { useSignalRConnectionContext } from '../../context/useSignalRConnectionContext'
import mapNotification from '../../utils/constants/NotificationTypesMapper'

const Notifications = ({ sideBarOpen }) => {
  const [notifications, setNotifications] = useState([])
  const [animate, setAnimate] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [playSound, setPlaySound] = useState(false)
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0)
  const [prevUnreadNotificationCount, setPrevUnreadNotificationCount] = useState(0)
  const translate = useTranslate()
  const { redirect } = useHistoryPush()
  const { connection, connectionError } = useSignalRConnectionContext()

  useEffect(() => {
    if (connection) {
      connection.on(INVOKE_FUNCTIONS.loadNotifications, (invitations) => {
        const unreadNotifications = invitations.filter((invitation) => !invitation.isRead)
        const readNotifications = invitations.filter((invitation) => invitation.isRead)
        const sortedNotifications = [...unreadNotifications, ...readNotifications]

        setNotifications(sortedNotifications)
      })

      connection.on(INVOKE_FUNCTIONS.invitationNotification, (invitation) => {
        setNotifications((prevNotifications) => [invitation, ...prevNotifications])
      })

      connection.on(INVOKE_FUNCTIONS.billingNotification, (notification) => {
        setNotifications((prevNotifications) => [notification, ...prevNotifications])
      })

      connection.on(INVOKE_FUNCTIONS.markAsRead, (notificationId) => {
        // Update the notifications state to set isRead to true for the notification with the given ID
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification.id === notificationId ? { ...notification, isRead: true } : notification,
          ),
        )
      })
    }
  }, [connection, connectionError])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPlaySound(false)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [playSound, notifications])

  useEffect(() => {
    if (notifications.length > 0) {
      const unreadCount = notifications.filter((notification) => !notification.isRead).length
      if (unreadCount > prevUnreadNotificationCount) {
        setUnreadNotificationCount(unreadCount)
        if (unreadCount > prevUnreadNotificationCount && !playSound) {
          setAnimate(true)
          setPlaySound(true)
          const timer = setTimeout(() => {
            setAnimate(false)
          }, 1000)
          return () => clearTimeout(timer)
        }
      }
    }
    return undefined
  }, [notifications])

  useEffect(() => {
    setPrevUnreadNotificationCount(unreadNotificationCount)
  }, [unreadNotificationCount])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleNotificationClick = (notification) => {
    connection.invoke(INVOKE_FUNCTIONS.markAsRead, notification.id)
    handleClose()

    const notificationType = mapNotification(notification.type)

    if (notificationType === 'billing') {
      // redirect(`/subscription`)
    } else if (notificationType === 'invitation') {
      redirect(`/invitationsList`, [{ profileOption: 'invitationsList' }])
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'notification-popover' : undefined

  return (
    <div className={`notifications-wrapper${sideBarOpen ? '' : '-closed'}`}>
      <IconButton onClick={handleClick} className={animate ? 'notification-icon-animation' : ''}>
        <Badge
          badgeContent={notifications.filter((notification) => !notification.isRead).length}
          color='primary'
        >
          {sideBarOpen && <NotificationIcon />}
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            ...popoverStyles.paper,
            ...(notifications.length === 0 && popoverStyles.centerContent),
          },
        }}
      >
        {connectionError || notifications.length === 0 ? (
          <Typography>{connectionError || translate('ra.notification.noNotifications')}</Typography>
        ) : (
          <List>
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                onClick={handleNotificationClick}
              />
            ))}
          </List>
        )}
      </Popover>
      {playSound && (
        <audio src={notificationSound} autoPlay>
          <track kind='captions' />
        </audio>
      )}
    </div>
  )
}

Notifications.propTypes = {
  sideBarOpen: PropTypes.bool.isRequired,
}

export default Notifications
