import React from 'react'
import PropTypes from 'prop-types'
import { useRecordContext } from 'react-admin'

const JerseyNumberField = ({ recordSource, teamId }) => {
  const record = useRecordContext()
  if (!record || !record[recordSource]) return <span>-</span>

  const obj = record.teams.find((o) => o.team === teamId)

  return (
    <span>
      {obj && obj.jerseyNumber !== null && obj.jerseyNumber !== undefined ? obj.jerseyNumber : '-'}
    </span>
  )
}

JerseyNumberField.propTypes = {
  recordSource: PropTypes.string,
  teamId: PropTypes.string,
}

export default JerseyNumberField
