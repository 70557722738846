import { shape, number, oneOf, string, func } from 'prop-types'

const historyRouterType = shape({
  action: oneOf(['PUSH', 'REPLACE', 'POP']),
  block: func,
  createHref: func,
  go: func,
  goBack: func,
  goForward: func,
  length: number,
  listen: func,
  location: shape({
    hash: string,
    pathname: string,
    search: string,
  }),
  push: func,
  replace: func,
})

export default historyRouterType
