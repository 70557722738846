import React, { useState, Children, cloneElement, useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import MyCloseIcon from '../../assets/icons/MyCloseIcon'
import dialogWithCloseIconStyles from './dialogWithCloseIcon.styles'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import MyWizard from '../MyWizard/MyWizard'

const DialogWithCloseIcon = (props) => {
  const {
    open,
    onClose,
    dialogTitle,
    dialogWidth,
    dialogHeight,
    maxDialogWidth,
    minDialogWidth,
    maxDialogHeight,
    minDialogHeight,
    backgroundColor,
    children,
    fullScreen,
    overflow,
    disableEnforceFocus,
    wizardSteps,
    hideCloseIcon,
  } = props

  const [activeStep, setActiveStep] = useState(0)
  const useStyles = makeStyles(
    dialogWithCloseIconStyles(
      dialogWidth,
      dialogHeight,
      maxDialogWidth,
      minDialogWidth,
      maxDialogHeight,
      minDialogHeight,
      backgroundColor,
      fullScreen,
      overflow,
    ),
  )
  const classes = useStyles(props)

  useEffect(() => {
    setActiveStep(0)
  }, [open])

  const stepForward = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)

  const stepBackwards = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

  const cloneChildrenWithProps = (children) =>
    Children.map(children, (child) =>
      cloneElement(child, { activeStep, setActiveStep, stepForward, stepBackwards }),
    )

  return (
    <Dialog
      open={open}
      onClose={() => {
        setActiveStep(0)
        onClose()
      }}
      fullScreen={fullScreen}
      classes={{ paper: classes.dialog }}
      disableEnforceFocus={disableEnforceFocus}
    >
      <div style={{ marginRight: 20 }}>
        {wizardSteps && (
          <MyWizard steps={wizardSteps} activeStep={activeStep} setActiveStep={setActiveStep} />
        )}
      </div>
      {!hideCloseIcon && <MyCloseIcon onClick={onClose} className={classes.closeIcon} />}
      <ConditionalRender renderValue={!!dialogTitle}>
        <DialogTitle className={classes.title}>{dialogTitle}</DialogTitle>
      </ConditionalRender>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        {cloneChildrenWithProps(children)}
      </DialogContent>
    </Dialog>
  )
}

DialogWithCloseIcon.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  dialogTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  dialogWidth: PropTypes.string,
  dialogHeight: PropTypes.string,
  maxDialogWidth: PropTypes.string,
  minDialogWidth: PropTypes.string,
  maxDialogHeight: PropTypes.string,
  minDialogHeight: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  fullScreen: PropTypes.bool,
  overflow: PropTypes.string,
  disableEnforceFocus: PropTypes.bool,
  wizardSteps: PropTypes.arrayOf(PropTypes.string),
  hideCloseIcon: PropTypes.bool,
}

DialogWithCloseIcon.defaultProps = {
  disableEnforceFocus: false,
}

export default DialogWithCloseIcon
