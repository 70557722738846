import COLORS from '../../../utils/constants/colors'

const drillListPageStyles = {
  loaderIcon: {
    fill: COLORS.orange,
    marginTop: -50,
    width: 50,
    height: 50,
  },
  noDrillsMessage: {
    margin: '50px auto 0px auto',
  },
}
export default drillListPageStyles
