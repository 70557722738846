import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles, Tooltip } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import COLORS from '../../utils/constants/colors'

const MyEditIcon = (props) => {
  const translate = useTranslate()
  const useStyles = makeStyles({
    addIcon: {
      fill: COLORS.grey,
      cursor: 'pointer',
    },
  })
  const classes = useStyles()

  return (
    <Tooltip title={translate('ra.buttons.edit')}>
      <EditIcon {...props} classes={{ root: classes.addIcon }} fontSize='medium' />
    </Tooltip>
  )
}

export default MyEditIcon
