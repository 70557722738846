const updateEmailModalStyles = {
  title: {
    marginTop: '5px',
    fontWeight: 500,
  },
  subtitle: {
    marginBottom: '5px',
  },
  dialogTitleRoot: {
    paddingBottom: '5px',
  },
  formWrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '15px',
    gap: '10px',
  },
}

export default updateEmailModalStyles
