const orgLogoStyles = {
  logoSmall: {
    width: '40px',
    height: '40px',
    borderRadius: '100%',
    marginLeft: 10,
    marginRight: 16,
    cursor: 'pointer',
  },
  orgIcon: {
    width: '40px',
    height: '40px',
    minWidth: '40px',
    maxWidth: '40px',
    marginLeft: '7px',
    marginRight: '19px',
    cursor: 'pointer',
  },
}

export default orgLogoStyles
