import { CARD_STYLES } from '../../../utils/constants/CSS/cardStyles'
import COLORS from '../../../utils/constants/colors'

const appIntegrationStyles = (expandedForm) => ({
  divider: CARD_STYLES.divider,
  containerApp: {
    display: 'grid',
    marginTop: 20,
    marginBottom: 15,
    gap: 15,
  },
  titleContainer: {
    display: 'flex',
    gap: 15,
    alignItems: 'center',
  },
  textContainer: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  info: {
    '& .MuiAlert-icon': {
      fontSize: 25,
      alignItems: 'center',
    },
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 50,
    paddingLeft: 5,
    paddingRight: 5,
  },
  arrow: {
    transform: expandedForm ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.3s ease',
    fill: COLORS.orange,
  },
  integrationFooter: { display: 'flex', alignItems: 'center' },
  button: {
    width: '10%',
    color: COLORS.errorRed,
  },
  configuration: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
  },
})

export default appIntegrationStyles
