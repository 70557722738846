import COLORS from '../../../../utils/constants/colors'

const myUploadVideoFieldStyles = {
  mediaUploadContainer: {
    display: 'flex',
    gap: '12px',
    flexDirection: 'column',
    marginTop: 30,
    marginBottom: 30,
    alignItems: 'start',
  },
  videoPlayer: {
    width: '115%',
    height: 'auto',
  },
  cancel: {
    marginLeft: 10,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  addButton: {
    flex: 1,
  },
  infoIcon: {
    color: COLORS.grey,
  },
  visuallyHiddenInput: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  },
  uploadedFile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cancelButton: { fill: COLORS.grey, cursor: 'pointer' },
  completedText: { display: 'flex', justifyContent: 'flex-end' },
}

export default myUploadVideoFieldStyles
