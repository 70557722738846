const supportBubbleFormStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    alignItems: 'center',
    textAlign: 'center',
  },
  formField: {
    marginTop: 5,
    marginBottom: 5,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}

export default supportBubbleFormStyles
