import React from 'react'
import PropTypes from 'prop-types'
import { useRecordContext } from 'react-admin'
import getAge from '../../../../utils/helpers/getAge'

const AgeField = ({ recordSource }) => {
  const record = useRecordContext()
  if (!record || !record[recordSource] || !record[recordSource].dateOfBirth) return <span>-</span>

  const age = getAge(record[recordSource].dateOfBirth)

  return <span>{age}</span>
}

AgeField.propTypes = {
  recordSource: PropTypes.string,
}

export default AgeField
