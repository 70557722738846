import React, { useEffect, useState } from 'react'
import Menu from '@material-ui/core/Menu'
import { useQuery } from 'react-query'
import { useTranslate } from 'react-admin'
import { Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import QueryKeys from '../../utils/constants/queryKeys'
import getTactics from '../../Apis/tactics/getTactics'
import tacticsPopover from './tacticsSelectPopover.styles'
import TACTICS_COLORS from '../../utils/constants/tacticsColors'
import AccordionParent from '../accordion/AccordionParent'
import AccordionChild from '../accordion/AccordionChild'
import SearchField from '../ui/SearchField/SearchField'
import initialTacticType from '../../types/tactics/initialTacticType'
import MySelectWithCheckmarks from '../MySelectWithCheckmarks/MySelectWithCheckmarks'

const TacticsSelectPopover = (props) => {
  const { initialTags, setTags } = props
  const [tagsClicked, setTagsClicked] = useState(initialTags)
  const [tacticsPopoverAnchorEl, setTacticsPopoverAnchorEl] = useState(null)
  const [searchField, setSearchField] = useState('')
  const [tacticsShown, setTacticsShown] = useState([])
  const translate = useTranslate()
  const useStyles = makeStyles(tacticsPopover)
  const classes = useStyles()

  const { data: allTactics } = useQuery([QueryKeys.GET_TACTICS], () => getTactics(), {
    onSuccess: (data) => {
      setTacticsShown(data)
    },
    staleTime: Infinity,
  })

  useEffect(() => {
    setTagsClicked(initialTags)
  }, [initialTags])

  useEffect(() => {
    const res = []

    if (allTactics?.length > 0) {
      res.push(
        ...allTactics?.filter((ParentTactic) => {
          if (ParentTactic.children) {
            res.push(
              ...ParentTactic.children.filter((tactic) => {
                if (tactic.children) {
                  res.push(
                    ...tactic.children.filter((childTactic) =>
                      childTactic.name.toLocaleLowerCase().match(searchField.toLocaleLowerCase()),
                    ),
                  )
                }

                return tactic.name.toLocaleLowerCase().match(searchField.toLocaleLowerCase())
              }),
            )
          }
          return ParentTactic.name.toLocaleLowerCase().match(searchField.toLocaleLowerCase())
        }),
      )
    }

    setTacticsShown(searchField !== '' ? res : allTactics)
  }, [searchField])

  const onTagClick = (tag) => {
    const tagIndex = tagsClicked.findIndex((t) => t.id === tag.id)
    if (tagIndex !== -1) {
      const newTagsClicked = tagsClicked.filter((t) => t.id !== tag.id)
      setTags(newTagsClicked)
      return setTagsClicked(newTagsClicked)
    }

    setTags([...tagsClicked, tag])
    return setTagsClicked([...tagsClicked, tag])
  }

  const onClose = () => setTacticsPopoverAnchorEl(false)

  return (
    <>
      <MySelectWithCheckmarks
        value={tagsClicked}
        placeholder={translate('ra.formFields.tactics')}
        fullWidth
        open={false}
        onClick={(e) => setTacticsPopoverAnchorEl(e.currentTarget)}
      />

      <Menu
        open={tacticsPopoverAnchorEl}
        onClose={onClose}
        anchorEl={tacticsPopoverAnchorEl}
        anchorOrigin={{
          vertical: 'top',
        }}
        transformOrigin={{
          vertical: 'top',
        }}
        classes={{ paper: classes.popover }}
        disableAutoFocusItem
      >
        {
          <SearchField
            variant='filled'
            // fullWidth
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            reset={() => setSearchField('')}
            style={{ marginLeft: '10px', width: '92%' }}
          />
        }
        {tacticsShown?.map((tactic) => {
          let colorName = ''
          if (tactic?.children) {
            colorName = tactic?.parentId
              ? allTactics?.filter((topTactic) => topTactic.id === tactic?.parentId)[0]?.name
              : tactic.name
          } else {
            colorName = allTactics?.filter(
              (topTactic) =>
                topTactic?.children?.filter(
                  (parentTactic) => parentTactic.id === tactic?.parentId,
                )[0]?.parentId === topTactic.id,
            )[0]?.name
          }

          const color = TACTICS_COLORS[colorName]

          if (tactic.children) {
            return (
              <AccordionParent
                key={tactic.id}
                label={tactic.name}
                color={color}
                backgroundColor='white'
                initiallyOpen={false}
                gridPosition='flex-start'
                style={{ fontWeight: 600, color, marginLeft: '0px' }}
                onClick={() => onTagClick({ ...tactic, color })}
                clicked={tagsClicked.some((tag) => tag.id === tactic.id)}
                items={
                  tactic.children[0].children
                    ? tactic.children.map((child) => (
                        <AccordionParent
                          key={child.id}
                          label={child.name}
                          color={color}
                          backgroundColor='white'
                          initiallyOpen={false}
                          gridPosition='flex-start'
                          style={{ marginLeft: '5%', height: '45px' }}
                          onClick={() => onTagClick({ ...child, color })}
                          clicked={tagsClicked.some((tag) => tag.id === child.id)}
                          items={child.children.map((c) => (
                            <Grid container classes={{ root: classes.wrapper }}>
                              <AccordionChild
                                key={c.id}
                                label={c.name}
                                color={color}
                                backgroundColor='white'
                                style={{
                                  marginLeft: '10%',
                                  height: '45px',
                                }}
                                onClick={() => onTagClick({ ...c, color })}
                                clicked={tagsClicked.some((tag) => tag.id === c.id)}
                              />
                            </Grid>
                          ))}
                        />
                      ))
                    : tactic.children.map((c) => (
                        <Grid container classes={{ root: classes.wrapper }}>
                          <AccordionChild
                            key={c.id}
                            label={c.name}
                            color={color}
                            backgroundColor='white'
                            style={{
                              marginLeft: '10%',
                              height: '45px',
                            }}
                            onClick={() => onTagClick({ ...c, color })}
                            clicked={tagsClicked.some((tag) => tag.id === c.id)}
                          />
                        </Grid>
                      ))
                }
              />
            )
          } else {
            return (
              <AccordionChild
                key={tactic.id}
                label={tactic.name}
                color={color}
                backgroundColor='white'
                style={{
                  marginLeft: '10%',
                  height: '45px',
                }}
                onClick={() => onTagClick({ ...tactic, color })}
                clicked={tagsClicked.some((tag) => tag.id === tactic.id)}
              />
            )
          }
        })}
      </Menu>
    </>
  )
}

TacticsSelectPopover.propTypes = {
  initialTags: PropTypes.arrayOf(initialTacticType),
  setTags: PropTypes.func,
}

export default TacticsSelectPopover
