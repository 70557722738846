import { isEmptyArray } from 'formik'
import localStorageIds from '../constants/localStorageIds'
import USER_ORG_ROLES from '../constants/userOrgRoles'
import localStorageHelper from './localStorageHelper'

const isUserOrgRolesValid = (allowedUserOrgRoles, searchIds) => {
  const searchIdsAvailable = !!searchIds && !isEmptyArray(searchIds)
  const { ALL } = USER_ORG_ROLES
  if (allowedUserOrgRoles === ALL) return true

  const { localStorageValue: userOrgRoles } = localStorageHelper(localStorageIds.USER_ORG_ROLES)

  const { localStorageValue: selectedOrg } = localStorageHelper(localStorageIds.SELECTED_ORG)
  const { localStorageValue: selectedTeam } = localStorageHelper(localStorageIds.SELECTED_TEAM)

  if (!userOrgRoles || (!selectedOrg && !selectedTeam && !searchIdsAvailable)) return false
  if (
    !userOrgRoles[selectedOrg?.id] &&
    !userOrgRoles[selectedTeam?.id] &&
    searchIdsAvailable &&
    !searchIds.map((id) => userOrgRoles[id]).filter((value) => value !== undefined).length > 0
  )
    return false

  return (
    allowedUserOrgRoles?.includes(userOrgRoles[selectedOrg?.id]) ||
    allowedUserOrgRoles?.includes(userOrgRoles[selectedTeam?.id]) ||
    (searchIdsAvailable &&
      searchIds
        .map((id) => userOrgRoles[id])
        .filter((value) => value !== undefined)
        .some((role) => allowedUserOrgRoles.includes(role)))
  )
}

export default isUserOrgRolesValid
