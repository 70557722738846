import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import CloudQueueIcon from '@material-ui/icons/CloudQueue'
import COLORS from '../../utils/constants/colors'

const MyCloudIcon = (props) => {
  const useStyles = makeStyles({
    addIcon: {
      fill: COLORS.grey,
      width: 22,
      height: 22,
    },
  })
  const classes = useStyles()

  return <CloudQueueIcon {...props} classes={{ root: classes.addIcon }} />
}

export default MyCloudIcon
