import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import { useTranslate } from 'react-admin'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import ExpandIcon from '../../../../assets/icons/expandIcon'
import ConditionalRender from '../../../ConditionalRender/ConditionalRender'
import mySelectFieldStyles from './mySelectField.styles'

const MySelectField = (props) => {
  const { label, options, emptyText, fullWidth, required, addOptionsComponent, ...otherProps } =
    props
  const [field, meta, helpers] = useField(props)
  const { value, ...rest } = field
  const { touched, error } = meta
  const [selectedOption, setSelectedOption] = useState(value ?? '')
  const translate = useTranslate()
  const useStyles = makeStyles(mySelectFieldStyles)
  const classes = useStyles()
  const ADD_NEW_OPTION = 'addNewOptions'

  const isError = (touched && error) || (touched && !value && required)

  const getOptionValue = (option) => (typeof option === 'object' ? option.id : option)
  const getOptionName = (option) => (typeof option === 'object' ? option.name : option)

  // Set selectedOption when create new option
  useEffect(() => {
    if (value && value !== selectedOption) {
      setSelectedOption(value)
    }
  }, [value, selectedOption])

  const handleChange = (e) => {
    // Forward onClick to AddOptionsComponent
    if (e.target.value === ADD_NEW_OPTION) {
      return addOptionsComponent.props.onClick()
    }

    helpers.setValue(e.target.value)
    return setSelectedOption(e.target.value)
  }

  return (
    <FormControl
      {...otherProps}
      variant='filled'
      error={isError}
      helperText={touched && error}
      required={required}
      fullWidth={fullWidth}
      classes={{ root: classes.formControlRoot }}
    >
      <InputLabel
        id={label}
        classes={{
          shrink: classes.shrink,
          formControl: classes.formControl,
          focused: classes.focused,
          error: classes.error,
        }}
      >
        {label}
      </InputLabel>
      <Select
        {...rest}
        value={selectedOption}
        onChange={handleChange}
        labelId={label}
        required={required}
        disableUnderline
        IconComponent={ExpandIcon}
        style={{ borderRadius: '5px', height: '42px' }}
        classes={{ select: classes.select, filled: classes.selectInput }}
      >
        {options?.length > 0 &&
          options.map((option) => (
            <MenuItem key={getOptionValue(option)} value={getOptionValue(option)}>
              {getOptionName(option)}
            </MenuItem>
          ))}

        {options?.length > 0 && addOptionsComponent && (
          <MenuItem value={ADD_NEW_OPTION}>{addOptionsComponent}</MenuItem>
        )}

        {!options?.length && (
          <div className={classes.emptyOptionsWrapper}>
            <MenuItem value='' disabled>
              {emptyText ?? translate('ra.text.noOptionsAvailable')}
            </MenuItem>

            <ConditionalRender renderValue={addOptionsComponent}>
              {addOptionsComponent}
            </ConditionalRender>
          </div>
        )}
      </Select>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

MySelectField.propTypes = {
  label: PropTypes.string,
  emptyText: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
}

export default MySelectField
