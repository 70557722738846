import COLORS from '../../utils/constants/colors'

const drillComponentInfoStyles = {
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    margin: '10px 0 0 10px',
  },
  title: {
    fontSize: '22px',
    lineHeight: '29px',
    wordWrap: 'break-word',
    textAlign: 'justify',
    hyphens: 'auto',
  },
  description: {
    lineHeight: '14px',
    wordWrap: 'break-word',
    textAlign: 'justify',
    hyphens: 'auto',
  },
  divFlex: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  semicolon: {
    width: '3px',
    height: '3px',
    backgroundColor: COLORS.lightDarkGrey,
    borderRadius: '100%',
  },
  positions: {
    width: '15px',
    height: '15px',
    borderRadius: '100%',
    backgroundColor: COLORS.backgroundGrey,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    padding: '7.5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  positionsFirstLetter: {
    color: COLORS.lightDarkGrey,
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '15px',
  },
  intensities: {
    color: COLORS.lightDarkGrey,
    fontSize: '12px',
    lineHeight: '17px',
  },
}

export default drillComponentInfoStyles
