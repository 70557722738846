import React, { useState } from 'react'
import { makeStyles, Popper, Paper, ClickAwayListener, Fade } from '@material-ui/core'
import ModeCommentIcon from '@material-ui/icons/ModeComment'
import { useMutation } from 'react-query'
import { useTranslate } from 'react-admin'
import supportBubbleStyles from './supportBubble.styles'
import SupportBubbleForm from './SupportBubbleForm/SupportBubbleForm'
import sendSupportEmail from '../../Apis/support/sendSupportEmail'
import MySnackbar from '../MySnackbar/MySnackbar'

const SupportBubble = () => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const translate = useTranslate()
  const useStyles = makeStyles(supportBubbleStyles)
  const classes = useStyles()

  const sendSupportEmailMutation = useMutation((data) => sendSupportEmail(data), {
    onSettled: () => {
      setIsSnackbarOpen(true)
      setAnchorEl(null)
    },
  })

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClickAway = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div className={classes.circle} onClick={handleClick}>
        <ModeCommentIcon className={classes.chatIcon} fontSize='medium' />
      </div>
      <Popper
        className={classes.popper}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement='bottom-start'
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Fade in={!!anchorEl} timeout={400}>
            <Paper className={classes.paper}>
              <SupportBubbleForm sendSupportEmailMutation={sendSupportEmailMutation} />
            </Paper>
          </Fade>
        </ClickAwayListener>
      </Popper>
      <MySnackbar
        isOpen={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
        isSuccess={sendSupportEmailMutation.isSuccess}
        successMessage={translate('ra.successMessages.supportMessageSuccess')}
        errorMessage={translate('ra.errorMessages.supportMessageFail')}
      />
    </>
  )
}

export default SupportBubble
