import React from 'react'
import { makeStyles } from '@material-ui/core'
import loaderDots from './loaderDots.styles'

const LoaderDots = (props) => {
  const useStyles = makeStyles(loaderDots)
  const classes = useStyles()

  return (
    <svg
      {...props}
      className={classes.loaderIcon}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1920 411'
    >
      <g
        id='svg_3'
        display=' block'
        transform='matrix(-57.817832946777344,0,0,57.69166564941406,-203.88502502441406,182.05294799804688) '
      >
        <g id='svg_4' transform='matrix(1,0,0,1,-7.504000186920166,0.4020000100135803) '>
          <circle r='2.72' id='svg_5'>
            <animate
              id='first'
              href='#svg_5'
              attributeName='fill-opacity'
              values='0.4;1;0.4'
              begin='0s;third.end'
              dur='0.5s'
            />
            <animate
              id='firstR'
              href='#svg_5'
              attributeName='r'
              values='2.72;3.264;2.72'
              begin='0s;thirdR.end'
              dur='0.5s'
            />
          </circle>
        </g>
      </g>
      <g
        id='svg_6'
        display=' block'
        transform='matrix(-56.51422882080078,0,0,56.51422882080078,524.897216796875,182.52627563476562) '
      >
        <g id='svg_7' transform='matrix(1,0,0,1,-7.504000186920166,0.4020000100135803) '>
          <circle r='2.72' id='svg_8'>
            <animate
              id='second'
              href='#svg_8'
              attributeName='fill-opacity'
              values='0.4;1;0.4'
              begin='0.25s;first.end-0.25s'
              dur='0.5s'
            />
            <animate
              id='secondR'
              href='#svg_8'
              attributeName='r'
              values='2.72;3.264;2.72'
              begin='0.25s;firstR.end-0.25s'
              dur='0.5s'
            />
          </circle>
        </g>
      </g>
      <g
        id='svg_9'
        display=' block'
        transform='matrix(-56.51422882080078,0,0,56.51422882080078,1243.897216796875,182.52627563476562) '
      >
        <g id='svg_10' transform='matrix(1,0,0,1,-7.504000186920166,0.4020000100135803) '>
          <circle r='2.72' id='svg_11'>
            <animate
              id='third'
              href='#svg_11'
              attributeName='fill-opacity'
              values='0.4;1;0.4'
              begin='0.5s;second.end-0.25s'
              dur='0.5s'
            />
            <animate
              id='thirdR'
              href='#svg_11'
              attributeName='r'
              values='2.72;3.264;2.72'
              begin='0.5s;secondR.end-0.25s'
              dur='0.5s'
            />
          </circle>
        </g>
      </g>
    </svg>
  )
}

export default LoaderDots
