import COLORS from "../../utils/constants/colors";

const MyGroupIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 17 11"
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill={COLORS.grey}
  >
    <path
      d="M8.5 0C9.15752 0 9.7881 0.261197 10.253 0.726131C10.718 1.19107 10.9792 1.82165 10.9792 2.47917C10.9792 3.13668 10.718 3.76727 10.253 4.2322C9.7881 4.69714 9.15752 4.95833 8.5 4.95833C7.84248 4.95833 7.2119 4.69714 6.74696 4.2322C6.28203 3.76727 6.02083 3.13668 6.02083 2.47917C6.02083 1.82165 6.28203 1.19107 6.74696 0.726131C7.2119 0.261197 7.84248 0 8.5 0ZM3.54167 1.77083C3.93833 1.77083 4.30667 1.87708 4.62542 2.06833C4.51917 3.08125 4.81667 4.08708 5.42583 4.87333C5.07167 5.55333 4.36333 6.02083 3.54167 6.02083C2.97808 6.02083 2.43758 5.79695 2.03906 5.39844C1.64055 4.99992 1.41667 4.45942 1.41667 3.89583C1.41667 3.33225 1.64055 2.79175 2.03906 2.39323C2.43758 1.99472 2.97808 1.77083 3.54167 1.77083ZM13.4583 1.77083C14.0219 1.77083 14.5624 1.99472 14.9609 2.39323C15.3595 2.79175 15.5833 3.33225 15.5833 3.89583C15.5833 4.45942 15.3595 4.99992 14.9609 5.39844C14.5624 5.79695 14.0219 6.02083 13.4583 6.02083C12.6367 6.02083 11.9283 5.55333 11.5742 4.87333C12.1917 4.07605 12.4784 3.07147 12.3746 2.06833C12.6933 1.87708 13.0617 1.77083 13.4583 1.77083ZM3.89583 9.03125C3.89583 7.565 5.95708 6.375 8.5 6.375C11.0429 6.375 13.1042 7.565 13.1042 9.03125V10.2708H3.89583V9.03125ZM0 10.2708V9.20833C0 8.22375 1.33875 7.395 3.15208 7.15417C2.73417 7.63583 2.47917 8.30167 2.47917 9.03125V10.2708H0ZM17 10.2708H14.5208V9.03125C14.5208 8.30167 14.2658 7.63583 13.8479 7.15417C15.6612 7.395 17 8.22375 17 9.20833V10.2708Z"
      fill="#757575"
    />
  </svg>
);

export default MyGroupIcon;


// style={{ fill: COLORS.grey }}
