import React from 'react'
import { makeStyles, Slider, withStyles } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { useTranslate } from 'react-admin'
import switchTripleOptionStyles from './switchTripleOption.styles'
import COLORS from '../../utils/constants/colors'

const switchStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    width: 68,
  },
  thumb: {
    height: 20,
    width: '20px !important',
    marginTop: 1,
    color: COLORS.white,
  },
  track: {},
  mark: {
    height: 13,
    width: 13,
    borderRadius: 240,
    marginTop: 1,
    marginLeft: -4,
    opacity: 0.2,
    color: COLORS.white,
  },
  rail: {
    height: 27,
    width: 98,
    borderRadius: 240,
    opacity: 1,
    marginLeft: 6,
    color: '#e0726e',
    border: `1px solid ${COLORS.orange}`,
  },
  disabled: {
    '& .MuiSlider-thumb ': {
      height: 20,
      width: 20,
      marginTop: 0,
      marginLeft: -6,
    },
    '& .MuiSlider-rail': {
      backgroundColor: '#e8e8e8',
      border: `1px solid #e8e8e8`,
    },
  },
})

const SwitchTripleOption = (props) => {
  // Expects fieldNames to be an array with 2 names. The middle state sets both as null
  // Expects optionLabels to be an array with 2 labels. First the left one and second the right one
  const { fieldNames, optionLabels, disabled, mode } = props
  const { values, setFieldValue } = useFormikContext()
  const translate = useTranslate()
  const useStyles = makeStyles(switchTripleOptionStyles)
  const classes = useStyles()
  const switchClasses = switchStyles()

  const TripleButtonsGroup = withStyles({
    grouped: { borderColor: disabled ? COLORS.borderGrey : COLORS.orange },
  })(ToggleButtonGroup)

  const TripleButtons = withStyles({
    selected: {
      backgroundColor: disabled
        ? `${COLORS.backgroundGrey} !important`
        : `${COLORS.lightOrange} !important`,
    },
    disabled: { color: `${COLORS.lightGrey} !important` },
  })(ToggleButton)

  const onSwitchChange = (value) => {
    if (value === 1) {
      setFieldValue(fieldNames[0], true)
      setFieldValue(fieldNames[1], false)
      return null
    }
    if (value === 3) {
      setFieldValue(fieldNames[0], false)
      setFieldValue(fieldNames[1], true)
      return null
    }

    setFieldValue(fieldNames[0], false)
    setFieldValue(fieldNames[1], false)
    return null
  }

  const setValue = (currentValues, fieldArrayNames) => {
    if (currentValues[fieldArrayNames[0]]) return 1
    if (currentValues[fieldArrayNames[1]]) return 3
    return 2
  }

  return (
    <div className={classes.container}>
      {mode === 'ToggleButtons' ? (
        <TripleButtonsGroup
          color='primary'
          value={setValue(values, fieldNames)}
          exclusive
          onChange={(event, value) => onSwitchChange(value)}
        >
          <TripleButtons value={1} style={{ color: COLORS.orange }} disabled={disabled}>
            {optionLabels?.[0]}
          </TripleButtons>
          <TripleButtons
            value={2}
            style={{
              color: COLORS.orange,
              borderLeft: `1px solid ${disabled ? COLORS.borderGrey : COLORS.orange}`,
            }}
            disabled={disabled}
          >
            {translate('ra.buttons.none')}
          </TripleButtons>
          <TripleButtons value={3} style={{ color: COLORS.orange }} disabled={disabled}>
            {optionLabels?.[1]}
          </TripleButtons>
        </TripleButtonsGroup>
      ) : (
        <>
          <span>{optionLabels?.[0]}</span>
          <div className={classes.holder}>
            <Slider
              classes={{
                root: switchClasses.root,
                thumb: switchClasses.thumb,
                track: switchClasses.track,
                rail: switchClasses.rail,
                disabled: switchClasses.disabled,
                mark: switchClasses.mark,
              }}
              marks
              step={1}
              min={1}
              max={3}
              track={false}
              onChangeCommitted={(event, value) => onSwitchChange(value)}
              defaultValue={setValue(values, fieldNames)}
              disabled={disabled}
            />
          </div>
          <span style={{ marginLeft: 6 }}>{optionLabels?.[1]}</span>
        </>
      )}
    </div>
  )
}

export default SwitchTripleOption
