export const dateRangeLabelStyles = {
  dateLabelWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "5px"
  },
  dateLabel: {
    fontSize: "14px",
    color: "#717171",
    lineHeight: "20px"
  },
};