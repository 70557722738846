import React from 'react'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import { useRouteMatch } from 'react-router'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import MyEditIcon from '../../assets/icons/MyEditIcon'
import MyDeleteIcon from '../../assets/icons/MyDeleteIcon'
import PreviousArrowIcon from '../../assets/icons/previousArrowIcon'
import ContainerPadding from '../../components/containerPadding/containerPadding'
import QueryKeys from '../../utils/constants/queryKeys'
import getPracticePlayers from '../../Apis/practice/getPracticePlayers'
import PdfDownloadButton from '../../components/PdfDownloadButton/PdfDownloadButton'
import PDF_VARIANTS from '../../utils/constants/pdfVariants'
import useHistoryPush from '../../hooks/useHistoryPush'
import PracticePlayersComponentsTable from '../../components/PracticePlayersComponentsTable/PracticePlayersComponentsTable'
import practicePlayersShowStyles from './practicePlayersShow.styles'
import deletePracticePlayers from '../../Apis/practice/deletePracticePlayers'
import useQueryParams from '../../hooks/useQueryParams'
import LoaderDots from '../../components/loaders/LoaderDots/LoaderDots'
import COLORS from '../../utils/constants/colors'
import IsUserRolesAuthorized from '../../components/IsUserRolesAuthorized/IsUserRolesAuthorized'
import USER_ORG_ROLES from '../../utils/constants/userOrgRoles'
import USER_ORG_MEMBERS_ROLES from '../../utils/constants/userOrgMembersRoles'
import getEvent from '../../Apis/event/getEvent'

const PracticePlayersShow = () => {
  const { redirect, goBack } = useHistoryPush()
  const queryParams = useQueryParams()
  const queryClient = useQueryClient()
  const practicePlayersId = useRouteMatch({ path: '/practice-players/:id/show' })?.params?.id
  const useStyles = makeStyles(practicePlayersShowStyles)
  const classes = useStyles()

  const { data: practicePlayers, isFetching } = useQuery(
    [QueryKeys.GET_PRACTICE_PLAYERS],
    () =>
      getPracticePlayers({
        practicePlayersId,
      }),
    {
      enabled: Boolean(practicePlayersId),
    },
  )

  const practice = useQuery(
    [QueryKeys.GET_EVENT],
    () =>
      getEvent({
        eventTypeCode: 'pract',
        eventId: queryParams.eventId,
      }),
    {
      enabled: Boolean(queryParams.eventId),
    },
  )

  const deleteAPracticePlayers = useMutation(
    (practicePlayersIdValue) => deletePracticePlayers(practicePlayersIdValue),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.GET_PRACTICES)
        redirect('/practice', [{ eventId: queryParams.eventId }, { team: queryParams.team }])
      },
    },
  )

  const handleGoBackButton = () => {
    if (queryParams?.goBack) {
      return redirect('practice', [], ['goBack'])
    }

    return goBack()
  }
  const handleEditButton = () => {
    redirect('/practice-players', [
      { eventId: queryParams.eventId },
      { practicePlayersId },
      { goBack: '/practice' },
    ])
  }

  const extractAndFormatDate = (timestamp) => {
    if (!timestamp) return null
    const datePart = timestamp?.split('T')[0]
    const [year, month, day] = datePart.split('-')
    return `${day}/${month}/${year}`
  }
  const formattedDate = extractAndFormatDate(practice?.data?.startDateTime)

  const handleDeleteButton = () => deleteAPracticePlayers.mutate(practicePlayersId)

  return (
    <div>
      <ContainerPadding paddingValue='1.5%' vertical className={classes.appbar}>
        <div className={classes.appBarPracticePlanName}>
          <PreviousArrowIcon onClick={handleGoBackButton} style={{ fill: COLORS.orange }} />
          <div>Players</div>
        </div>

        <div className={classes.appBarButtons}>
          <PdfDownloadButton
            pdfVariant={PDF_VARIANTS.PRACTICE_PLAN_WITH_PLAYERS}
            title='Players.pdf'
            contentProps={{ practicePlayers, formattedDate }}
            className={classes.orangeIcon}
          />

          <IsUserRolesAuthorized
            acceptedRoles={[
              USER_ORG_ROLES.OWNER,
              USER_ORG_ROLES.ADMIN,
              USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
            ]}
          >
            <MyEditIcon className={classes.orangeIcon} onClick={handleEditButton} />
            <MyDeleteIcon className={classes.orangeIcon} onClick={handleDeleteButton} />
          </IsUserRolesAuthorized>
        </div>
      </ContainerPadding>

      <Divider light />

      <ContainerPadding padding='3% 5%'>
        {isFetching ? (
          <Grid
            container
            justifyContent='center'
            style={{ marginTop: '30px', marginBottom: '30px' }}
          >
            <LoaderDots />
          </Grid>
        ) : (
          <PracticePlayersComponentsTable practicePlayers={practicePlayers} />
        )}
      </ContainerPadding>
    </div>
  )
}

export default PracticePlayersShow
