const playerEditToolbarStyles = (theme) => ({
  buttonsWrapper: {
    display: 'flex',
    gap: 5,
    justifyContent: 'end',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'start',
    },
  },
})

export default playerEditToolbarStyles
