import { makeStyles, MenuItem } from '@material-ui/core'
import React from 'react'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import createPracticePlanPopoverStyles from './createPracticePlanPopover.styles'

const CreatePracticePlanPopover = (props) => {
  const {
    open,
    onClose,
    anchorEl,
    handlePopoverCreateNewClick,
    setPracticePlansModal,
    setPracticeAccordionPressed,
    practiceId,
  } = props

  const translate = useTranslate()
  const useStyles = makeStyles(createPracticePlanPopoverStyles)
  const classes = useStyles()

  const handlePracticePlansClick = () => {
    if (practiceId && setPracticeAccordionPressed) {
      setPracticeAccordionPressed(practiceId)
    }
    setPracticePlansModal(true)
    onClose()
  }

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{ paper: classes.popover }}
      disableAutoFocusItem
    >
      <MenuItem onClick={handlePracticePlansClick}>
        <div className={classes.textWrapper}>{translate('ra.text.practicePlans')}</div>
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          e.stopPropagation()
          onClose()
          handlePopoverCreateNewClick()
        }}
      >
        <AddCircleIcon style={{ width: '22', height: '22', marginRight: 5, fill: '#E0726E' }} />
        {translate('ra.text.createNew')}
      </MenuItem>
    </Menu>
  )
}

CreatePracticePlanPopover.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  // anchorEl: PropTypes.string,
  handlePopoverCreateNewClick: PropTypes.func,
  setPracticePlansModal: PropTypes.func,
  setPracticeAccordionPressed: PropTypes.func,
  practiceId: PropTypes.string,
}

export default CreatePracticePlanPopover
