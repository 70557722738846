import React from 'react'
import { makeStyles } from '@material-ui/core'
import { SaveButton, useNotify, useTranslate } from 'react-admin'
import mySaveButtonStyles from './mySaveButton.styles'
import loadingDots from '../../../../../assets/images/loading-dots.svg'
import useHistoryPush from '../../../../../hooks/useHistoryPush'

const MySaveButton = (props) => {
  const { saving, label, ...rest } = props
  const notify = useNotify()
  const translate = useTranslate()
  const { redirect } = useHistoryPush()
  const useStyles = makeStyles(mySaveButtonStyles)
  const classes = useStyles()

  const onSuccessCallback = () => {
    notify(`${translate('ra.notification.created')}`, 'success', undefined, false, 5000)
    redirect(props.basePath)
  }

  return (
    <SaveButton
      {...rest}
      classes={{ button: classes.button }}
      label={saving ? <img alt='img' src={loadingDots} className={classes.loadingDots} /> : label}
      onSuccess={onSuccessCallback}
    />
  )
}

export default MySaveButton
