import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import ButtonWithLoader from '../../ui/ButtonWithLoader/ButtonWithLoader'
import orgAddonSelectStyles from './orgAddonSelect.styles'
import QueryKeys from '../../../utils/constants/queryKeys'
import useQueryParams from '../../../hooks/useQueryParams'
import ContainerPadding from '../../containerPadding/containerPadding'
import LoaderDots from '../../loaders/LoaderDots/LoaderDots'
import SubscriptionAddonCard from '../../../pages/Subscription/SubscriptionAddonCard/SubscriptionAddonCard'
import { USER_SCOPES } from '../../../utils/constants/userScopes'
import ORGANISATION_TYPES from '../../../utils/constants/OrganisationTypes'
import getSubscriptionDetails from '../../../Apis/subscriptions/getSubscriptionDetails'

const OrgAddonSelect = (props) => {
  const { onClose, orgTypeSelected } = props
  const queryParams = useQueryParams()
  const { org: orgId } = queryParams
  const translate = useTranslate()
  const useStyles = makeStyles(orgAddonSelectStyles)
  const classes = useStyles()

  const subscriptionOptions = useQuery(
    [QueryKeys.GET_SUBSCRIPTION_OPTIONS],
    () => getSubscriptionDetails({ orgId }),
    {
      enabled: !!orgId,
    },
  )

  if (subscriptionOptions.isLoading)
    return (
      <ContainerPadding paddingValue='45%'>
        <LoaderDots className={classes.loader} />
      </ContainerPadding>
    )

  return (
    <div className={classes.dialogContent}>
      <div className={classes.cardsContainer}>
        {subscriptionOptions?.data?.addons
          ?.filter((addon) => {
            if (orgTypeSelected === ORGANISATION_TYPES.ORG_CLUB) return true
            return addon.id !== USER_SCOPES.COACH
          })
          .map((addon) => (
            <SubscriptionAddonCard key={addon.id} addon={addon} nonActivatableAddons />
          ))}
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
        <ButtonWithLoader
          className={classes.addButton}
          label={translate('ra.buttons.finish')}
          loading={subscriptionOptions.isLoading}
          onClick={onClose}
        />
      </div>
    </div>
  )
}

OrgAddonSelect.propTypes = {
  onClose: PropTypes.func,
  orgTypeSelected: PropTypes.string,
}

export default OrgAddonSelect
