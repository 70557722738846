const userFieldStyles = (columnAlignment) => ({
  cellContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: columnAlignment === 'right' ? 'flex-end' : columnAlignment,
  },
  typo: { marginLeft: '10px' },
  userNameFieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginTop: 7,
    marginBottom: 7,
  },
  userNameFieldAvatar: {
    width: '40px',
    height: '40px',
  },
})

export default userFieldStyles
