import React, { useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import { Form, Formik } from 'formik'
import { useLocation } from 'react-router-dom'
import {
  Divider,
  Grid,
  makeStyles,
  Popper,
  Tooltip,
  Fade,
  Paper,
  Typography,
} from '@material-ui/core'
import VIDEO_SIZE from '../../../utils/constants/uploadFileSize'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import drillCreateFormStyles from './drillCreateForm.styles'
import AddButton from '../../../components/ui/AddButton/AddButton'
import MyCheckboxGroupInput from '../../../components/form/fields/MyCheckboxGroupInput'
import MyCheckboxOneToMany from '../../../components/form/fields/MyCheckboxOneToMany/MyCheckboxOneToMany'
import ChipsContainer from '../../../components/ChipsContainer/ChipsContainer'
import TacticsModal from '../../../components/TacticsModal/TacticsModal'
import ContainerPadding from '../../../components/containerPadding/containerPadding'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import MyTextField from '../../../components/form/fields/MyTextField'
import MySwitchField from '../../../components/form/fields/MySelectField/MySwitchField'
import Designs from '../../../components/designs/Designs'
import CourtEditorDialog from '../../../components/CourtEditorDialog/CourtEditorDialog'
import setTacticsColor from '../../../utils/helpers/setTacticsColor'
import LoaderDots from '../../../components/loaders/LoaderDots/LoaderDots'
import constructDrillTitle from '../../../utils/helpers/constructDrillTitle'
import DeleteModal from '../../../components/DeleteModal/DeleteModal'
import useGetPositions from '../../../Apis/drill/getPositions/useGetPositions'
import useGetIntensities from '../../../Apis/drill/getIntesities/useGetIntensities'
import addDrill from '../../../Apis/drill/addDrill'
import trainingModuleType from '../../../types/trainingModuleType'
import drillListPageStyles from '../../DrillList/DrillListPage/drillListPage.styles'
import useTour from '../../../hooks/useTour'
import TOUR_IDS from '../../../utils/constants/introIds'
import MyDeleteIcon from '../../../assets/icons/MyDeleteIcon'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import MyUploadVideoField from '../../../components/form/fields/MyUploadVideoField/MyUploadVideoField'
import MyCloseIcon from '../../../assets/icons/MyCloseIcon'
import useHistoryPush from '../../../hooks/useHistoryPush'

const DrillCreateForm = (props) => {
  const {
    trainingModule,
    createDrill,
    updateDrill,
    deleteDrill,
    isFetching,
    isMutating,
    type,
    shouldUpdateGlobally,
  } = props
  const [tacticsModalOpen, setTacticsModalOpen] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [courtEditorModalOpen, setCourtEditorModalOpen] = useState(false)
  const [designToEdit, setDesignToEdit] = useState()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const spanRef = React.useRef()
  const [open, setOpen] = React.useState(false)
  const translate = useTranslate()
  const useStyles = makeStyles((theme) =>
    drillCreateFormStyles(theme, { deleteDisabled: !trainingModule?.id }),
  )
  const elRef = useRef()
  const { redirect } = useHistoryPush()
  const classes = useStyles()
  const location = useLocation()
  const pathname = location?.pathname?.split('/')?.[1]

  const { data: positionsChoices } = useGetPositions(null, { staleTime: Infinity })
  const { data: intensitiesChoices } = useGetIntensities(null, { staleTime: Infinity })
  const SHOULD_CLOSE_DESIGNER_MODAL_FLAG = 'shouldCloseDesignerModalFlag'

  useTour({
    elRef,
    tourName: `create${type}-tour`,
    tourSteps: `create${type}`,
  })

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setOpen(false)
      }, 4000)

      return () => clearTimeout(timer)
    }
  }, [open])

  const editDesign = (designOrder, designs) => {
    const editedDesign = designs.find((design) => design.order === designOrder)
    setDesignToEdit(editedDesign)
    setCourtEditorModalOpen(true)
  }

  const deleteDesign = (designOrder, setFieldValues, designs) => {
    setFieldValues(
      'designs',
      designs
        .filter((design) => design.order !== designOrder)
        .map((obj) => (obj.order > designOrder ? { ...obj, order: obj.order - 1 } : { ...obj })),
    )
  }

  const onRemoveVideo = (setFieldValues) => {
    setFieldValues('uploadedVideo', '')
    setShowDeleteModal(false)
  }

  const changeDesignOrder = (source, destination, setFieldValue, designs) => {
    const updatedDesign = { ...designs[source.index], edited: true }
    const updatedDesigns = [...designs]
    updatedDesigns[source.index] = updatedDesign
    updatedDesigns.splice(source.index, 1)
    updatedDesigns.splice(destination.index, 0, updatedDesign)

    setFieldValue(
      'designs',
      updatedDesigns.map((item, index) => ({
        ...item,
        order: index,
      })),
    )
  }

  const handleDeleteButtonClick = () => {
    setOpenDeleteModal(true)
  }

  const onCloseIconClicked = () => redirect(`/drills/${trainingModule?.id}/show`)

  const onSubmit = (formValues) => {
    // Check whether i need to close designer modal after create/update or not.
    const { [SHOULD_CLOSE_DESIGNER_MODAL_FLAG]: shouldCloseDesignerModal, ...rest } = formValues
    const closeDesignerModal =
      shouldCloseDesignerModal && courtEditorModalOpen ? setCourtEditorModalOpen : null

    if (!trainingModule?.id) {
      return createDrill(rest, closeDesignerModal)
    }

    return updateDrill(rest, closeDesignerModal)
  }

  return (
    <div ref={elRef}>
      <DeleteModal
        title={constructDrillTitle(translate('ra.text.deleteDrill'), trainingModule?.title)}
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        handleDelete={() => deleteDrill && deleteDrill(trainingModule?.id)}
        loading={deleteDrill?.isLoading}
      />

      <ConditionalRender renderValue={isFetching}>
        <Grid
          container
          justifyContent='center'
          style={{ marginTop: '100px', marginBottom: '30px' }}
        >
          <LoaderDots style={drillListPageStyles.loaderIcon} />
        </Grid>
      </ConditionalRender>

      <ConditionalRender renderValue={!isFetching}>
        <Formik
          initialValues={{
            title: trainingModule?.id ? trainingModule?.title : '',
            description: trainingModule?.id ? trainingModule?.description : '',
            access: trainingModule?.id ? trainingModule?.access?.name === 'PUBLIC' : false,
            intensities: trainingModule?.id
              ? trainingModule?.intensities?.map((intensity) => intensity.name)
              : [],
            positions: trainingModule?.id
              ? trainingModule?.positions?.map((position) => position.name)
              : ['ALL'],
            tactics: trainingModule?.id ? setTacticsColor(trainingModule?.tactics) : [],
            designs: trainingModule?.id ? trainingModule?.designs : [],
            uploadedVideo: trainingModule?.uploadedVideo || '',
          }}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, setValues, values, submitForm, dirty }) => (
            <Form className={classes.form}>
              <ContainerPadding>
                <Grid
                  container
                  alignItems='center'
                  classes={{ root: `${classes.gridContainer} ${classes.fullWidth}` }}
                >
                  <Grid id={TOUR_IDS.createdrills.title} item xs={8}>
                    <MyTextField label={translate('ra.formFields.title')} name='title' />
                  </Grid>
                  <Grid item container xs={4} alignItems='center'>
                    <ConditionalRender renderValue={shouldUpdateGlobally}>
                      <div
                        id={TOUR_IDS.createdrills.publish}
                        className={classes.appBarPublishWrapper}
                      >
                        <span ref={spanRef}>{translate('ra.text.publish')}</span>
                        <MySwitchField
                          name='access'
                          color='primary'
                          disabled={trainingModule?.isHidden}
                          onClick={() => setOpen(true)}
                        />
                        <Popper
                          open={open}
                          anchorEl={spanRef.current}
                          placement='bottom'
                          transition
                          style={{ zIndex: 20, marginTop: 10 }}
                        >
                          {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                              <Paper style={{ padding: 10 }}>
                                <Typography>
                                  {translate('ra.text.publishTextA')}
                                  {pathname === 'plays' ? 'Play' : 'Drill'}
                                  {translate('ra.text.publishTextB')}
                                  <br />
                                  {translate('ra.text.publishTextC')}
                                </Typography>
                              </Paper>
                            </Fade>
                          )}
                        </Popper>
                      </div>
                    </ConditionalRender>
                    <div id={TOUR_IDS.createdrills.save} className={classes.appBarIconsWrapper}>
                      <ConditionalRender
                        renderValue={pathname === 'plays' || pathname === 'drills'}
                      >
                        <MyDeleteIcon
                          className={classes.deleteIcon}
                          disabled={!trainingModule?.id}
                          onClick={handleDeleteButtonClick}
                        />
                      </ConditionalRender>
                      <ButtonWithLoader
                        label={translate('ra.buttons.save')}
                        loading={isMutating}
                        // if at least on of the fields is being changed, the form can be submitted
                        disabled={!dirty}
                      />
                      <Tooltip title={translate('ra.action.cancel')}>
                        <IconButton
                          color='primary'
                          aria-label='back arrow icon button'
                          component='div'
                          onClick={onCloseIconClicked}
                        >
                          <MyCloseIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
              </ContainerPadding>
              <Divider light orientation='horizontal' />
              <ContainerPadding className={classes.body}>
                <Grid container classes={{ root: classes.fullWidth }}>
                  {/* Left Column */}
                  <Grid item xs={8}>
                    {/* Description for drills */}
                    <ConditionalRender renderValue={type === 'drills'}>
                      <div
                        id={TOUR_IDS.createdrills.description}
                        className={classes.fieldContainer}
                      >
                        <span className={classes.span}>{translate('ra.text.description')}</span>
                        <MyTextField label='' name='description' multiline minRows={5} fullWidth />
                      </div>
                    </ConditionalRender>

                    {/* Designs */}
                    <div className={classes.fieldContainer}>
                      <div
                        id={TOUR_IDS.createdrills.addGraphics}
                        className={classes.graphicsWrapper}
                      >
                        <span>Graphics</span>
                        <AddButton
                          label='Add'
                          onClick={() => setCourtEditorModalOpen(true)}
                          style={{ padding: 3 }}
                          filled
                        />
                      </div>

                      <Designs
                        designs={values.designs}
                        deleteDesign={(designOrder) =>
                          deleteDesign(designOrder, setFieldValue, values.designs)
                        }
                        changeDesignOrder={changeDesignOrder}
                        editDesign={(designOrder) => editDesign(designOrder, values.designs)}
                        type={type}
                        show={false}
                        drillId={trainingModule?.id}
                        formValues={values}
                        patchDrill={updateDrill}
                        setFieldValue={setFieldValue}
                      />
                      <CourtEditorDialog
                        open={courtEditorModalOpen}
                        onClose={() => {
                          setDesignToEdit('')
                          submitForm()
                        }}
                        onExit={() => {
                          setDesignToEdit('')
                          setCourtEditorModalOpen(false)
                        }}
                        setDesign={(newDesign) => {
                          if (newDesign.id) {
                            setValues({
                              ...values,
                              designs: values.designs.map((design) =>
                                design.id === newDesign.id ? newDesign : design,
                              ),
                              [SHOULD_CLOSE_DESIGNER_MODAL_FLAG]: true,
                            })
                          } else {
                            setValues({
                              ...values,
                              designs: [
                                ...values.designs.map((obj) => {
                                  return obj.order >= newDesign.order
                                    ? { ...obj, order: obj.order + 1 }
                                    : { ...obj }
                                }),
                                newDesign,
                              ],
                              [SHOULD_CLOSE_DESIGNER_MODAL_FLAG]: true,
                            })
                          }
                        }}
                        designToEdit={designToEdit}
                        setDesignToEdit={setDesignToEdit}
                        // setFieldValue={setFieldValue}
                        isLoading={{
                          addLoading: addDrill.isLoading,
                          updateLoading: updateDrill.isLoading,
                        }}
                        designs={values.designs}
                        title={trainingModule?.title}
                        editDesign={(designOrder) => editDesign(designOrder, values.designs)}
                        changeDesignOrder={(source, destination) =>
                          changeDesignOrder(source, destination, setFieldValue, values.designs)
                        }
                        deleteDesign={(designOrder) =>
                          deleteDesign(designOrder, setFieldValue, values.designs)
                        }
                      />
                    </div>
                  </Grid>

                  {/* Divider */}
                  <Grid
                    item
                    xs={1}
                    container
                    justifyContent='center'
                    classes={{ root: classes.gridDividerRoot }}
                  >
                    <Divider light orientation='vertical' />
                  </Grid>

                  {/* Right Column */}
                  <Grid item xs={3}>
                    {/* Description for Plays */}
                    <ConditionalRender renderValue={type === 'plays'}>
                      <div id={TOUR_IDS.createplays.description} className={classes.fieldContainer}>
                        <span className={classes.span}>{translate('ra.text.description')}</span>
                        <MyTextField label='' name='description' multiline minRows={5} fullWidth />
                      </div>
                    </ConditionalRender>

                    {/* Intensities & Positions */}
                    <ConditionalRender renderValue={type === 'drills'}>
                      <div id={TOUR_IDS.createdrills.intensity}>
                        <div className={classes.fieldContainer}>
                          <span className={classes.noMarginSpan}>
                            {translate('ra.formFields.intensity')}
                          </span>
                          <MyCheckboxGroupInput name='intensities' options={intensitiesChoices} />
                        </div>
                        <div className={classes.fieldContainer}>
                          <span className={classes.noMarginSpan}>
                            {translate('ra.formFields.positions')}
                          </span>
                          <MyCheckboxOneToMany name='positions' options={positionsChoices} />
                        </div>
                      </div>
                    </ConditionalRender>
                    {/* Tactics */}
                    <div id={TOUR_IDS.createdrills.tactics} className={classes.fieldContainer}>
                      <span className={classes.span}>{translate('ra.formFields.tactics')}</span>
                      <ChipsContainer
                        chips={values.tactics}
                        handleAddIcon={() => setTacticsModalOpen(true)}
                        onDelete={(tag) => {
                          setFieldValue(
                            'tactics',
                            values.tactics.filter((t) => t.id !== tag.id),
                          )
                        }}
                      />
                    </div>

                    <TacticsModal
                      open={tacticsModalOpen}
                      onClose={() => setTacticsModalOpen(false)}
                      initialTags={values.tactics}
                      setTags={(tagsClicked) => setFieldValue('tactics', tagsClicked)}
                    />
                    <MyUploadVideoField
                      label='Upload Video'
                      maxVideoSize={VIDEO_SIZE.DRILL_VIDEO}
                      acceptedTypes='.mp4, AVI'
                      trainingModuleId={
                        trainingModule?.originalTrainingModuleId || trainingModule?.id
                      }
                      setShowDeleteModal={setShowDeleteModal}
                    />
                  </Grid>
                </Grid>
                <DeleteModal
                  open={showDeleteModal}
                  onClose={() => setShowDeleteModal(false)}
                  handleDelete={() => onRemoveVideo(setFieldValue)}
                  title={translate('ra.text.removeVideo')}
                />
              </ContainerPadding>
              {/* <FormAutoSave */}
              {/*  create={addDrill} */}
              {/*  update={updateDrill} */}
              {/*  drillId={trainingModule?.id} */}
              {/*  type={type} */}
              {/*  dirty={dirty} */}
              {/*  closeButtonPressed={closeButtonPressed} */}
              {/*  isUpdating={isMutating} */}
              {/*  pathname={pathname} */}
              {/*  isModal={isModal} */}
              {/*  closeModal={closeModal} */}
              {/* /> */}
            </Form>
          )}
        </Formik>
      </ConditionalRender>
    </div>
  )
}

DrillCreateForm.defaultProps = {
  shouldUpdateGlobally: true,
}

DrillCreateForm.propTypes = {
  trainingModule: trainingModuleType,
  createDrill: PropTypes.func,
  updateDrill: PropTypes.func,
  deleteDrill: PropTypes.func,
  isFetching: PropTypes.bool,
  isMutating: PropTypes.bool,
  type: PropTypes.string,
  shouldUpdateGlobally: PropTypes.bool,
}

export default DrillCreateForm
