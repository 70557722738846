import React from 'react'

const MyCancelCircleIcon = (props) => {
  return (
    <svg
      {...props}
      width='21'
      height='21'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ cursor: 'pointer' }}
    >
      <circle cx='11.5' cy='11.5' r='11.5' />
      <path
        d='M16.4582 7.54044L15.4594 6.54169L11.4998 10.5013L7.54025 6.54169L6.5415 7.54044L10.5011 11.5L6.5415 15.4596L7.54025 16.4584L11.4998 12.4988L15.4594 16.4584L16.4582 15.4596L12.4986 11.5L16.4582 7.54044Z'
        fill='white'
      />
    </svg>
  )
}

export default MyCancelCircleIcon
