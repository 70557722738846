import React, { useEffect, useState } from 'react'
import { useInfiniteQuery, useQuery } from 'react-query'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import QueryKeys from '../../utils/constants/queryKeys'
import getDrills from '../../Apis/drill/getDrills'
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll'
import SearchField from '../ui/SearchField/SearchField'
import DialogWithCloseIcon from '../DialogWithCloseIcon/DialogWithCloseIcon'
import MyToggleButton from '../MyToggleButton/MyToggleButton'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import ChipsContainer from '../ChipsContainer/ChipsContainer'
import sessionStorageIds from '../../utils/constants/sessionStorageIds'
import ContainerPadding from '../containerPadding/containerPadding'
import TRAINING_MODULES_TYPES from '../../utils/constants/trainingModulesTypes'
import DrillsMasonry from '../DrillsMasonry/DrillsMasonry'
import TacticsSelectPopover from '../TacticsSelectPopover/TacticsSelectPopover'
import LoaderDots from '../loaders/LoaderDots/LoaderDots'
import drillListPageStyles from '../../pages/DrillList/DrillListPage/drillListPage.styles'
import playsListModalStyles from './playsListModal.styles'

const PlaysListModal = (props) => {
  const { open, onClose, onPlayClick } = props
  const [toggleValue, setToggleValue] = useState('private')
  const [searchField, setSearchField] = useState('')
  const [tactics, setTactics] = useState([])
  const [drillClicked, setDrillClicked] = useState(null)
  const translate = useTranslate()
  const useStyles = makeStyles(playsListModalStyles)
  const classes = useStyles()

  useEffect(() => {
    if (open) setDrillClicked(null)
  }, [open])

  const usersTrainingModules = useQuery(
    [QueryKeys.GET_DRILLS, toggleValue],
    () =>
      getDrills({
        access: 'PRIVATE',
        pageNumber: 1,
        perPage: 1000,
        type: TRAINING_MODULES_TYPES.plays,
      }),
    { enabled: Boolean(toggleValue === 'public') },
  )

  const { data, hasNextPage, fetchNextPage, isLoading, isFetchedAfterMount, isFetchingNextPage } =
    useInfiniteQuery(
      [QueryKeys.GET_DRILLS, toggleValue, tactics, searchField],
      ({ pageParam = 1 }) =>
        getDrills({
          access: toggleValue.toUpperCase(),
          pageNumber: pageParam,
          tactics: tactics.map((tactic) => tactic.name),
          q: searchField,
          type: TRAINING_MODULES_TYPES.plays,
        }),
      {
        getNextPageParam: ({ metadata }) => {
          const { currentPage, totalPages } = metadata
          return currentPage === totalPages || totalPages === 0 ? undefined : currentPage + 1
        },
      },
    )

  return (
    <DialogWithCloseIcon
      open={open}
      onClose={onClose}
      minDialogWidth='80%'
      dialogHeight='calc(100% - 64px)'
      minDialogHeight='10%'
      fullWidth
    >
      <ContainerPadding>
        <div className={`${classes.divFlex} ${classes.spaceVertical}`}>
          <span className={classes.title}>PLAYS</span>
          <MyToggleButton
            left={{
              value: 'private',
              label: translate('ra.buttons.personal'),
            }}
            right={{
              value: 'public',
              label: translate('ra.buttons.public'),
            }}
            setToggleValue={setToggleValue}
            toggleValue={toggleValue}
          />
        </div>

        <Grid container alignItems='center' spacing={2}>
          <Grid item xs={10}>
            <SearchField
              variant='filled'
              fullWidth
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
              reset={() => setSearchField('')}
            />
          </Grid>
          <Grid item xs={2}>
            <TacticsSelectPopover
              initialTags={tactics}
              setTags={(tagsClicked) => setTactics(tagsClicked)}
            />
          </Grid>
        </Grid>

        <div className={`${classes.divFlex} ${classes.spaceVertical}`}>
          <ChipsContainer
            chips={tactics.map((tactic) => ({
              ...tactic,
              name: tactic.name,
              color: tactic.color,
            }))}
            onDelete={(tag) => setTactics(tactics.filter((tactic) => tactic.name !== tag.name))}
          />
        </div>

        <DrillsMasonry
          drills={isFetchedAfterMount ? data?.pages : []}
          toggleAccess={toggleValue}
          isModal
          handleDrillClick={(element) => onPlayClick(element)}
          drillClicked={drillClicked}
          downloadedTrainingModules={usersTrainingModules?.data?.trainingModulesDetailsDto.filter(
            (trainingModule) => !!trainingModule.originalTrainingModuleId,
          )}
        />

        <ConditionalRender renderValue={!isFetchedAfterMount || isFetchingNextPage}>
          <Grid
            container
            justifyContent='center'
            style={{ marginTop: '30px', marginBottom: '30px' }}
          >
            <LoaderDots style={drillListPageStyles.loaderIcon} />
          </Grid>
        </ConditionalRender>

        <InfiniteScroll
          isLoading={isLoading || isFetchingNextPage}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          scrollId={sessionStorageIds.DRILLS_DIALOG_LIST_SCROLLBAR_POSITION}
        />
      </ContainerPadding>
    </DialogWithCloseIcon>
  )
}

PlaysListModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onPlayClick: PropTypes.func,
}

export default PlaysListModal
