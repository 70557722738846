import COLORS from '../colors'

const SELECT_INPUT_STYLES = {
  // arrowDown
  arrowDown: {
    color: COLORS.lightGrey,
    fontSize: '17px',
    marginRight: '12px',
  },

  // helperText
  helperTextRoot: {
    display: 'none',
  },

  // InputLabelProps
  error: {
    '&&&': {
      color: COLORS.errorRed,
    },
  },
  formControl: {
    '&&': {
      transform: 'translate(12px, 14px)',
      fontSize: '0.9rem',
      color: 'rgba(0, 0, 0, 0.40)',
    },
  },
  shrink: {
    '&&&': {
      transform: 'translate(12px, -6px)',
      fontSize: '12px',
      color: COLORS.black,
    },
  },

  // InputProps
  root: {
    borderRadius: '6px',
  },
  input: {
    '&&': {
      height: '0.7rem',
      minHeight: '0.7rem',
      fontSize: '0.95rem',
      padding: '14px 12px 17px',
    },
    '&:focus': {
      backgroundColor: 'unset',
    },
  },
  selectMenu: {
    minHeight: '0.6rem',
    '&:focus': {
      backgroundColor: COLORS.white,
    },
  },
}

export default SELECT_INPUT_STYLES
