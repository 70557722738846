import COLORS from '../../utils/constants/colors'

const drillBoxLiteStyles = {
  divFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  greySmall: {
    color: COLORS.lightGrey,
    fontSize: '12px',
    marginRight: '5px',
  },
  spaceVertical: {
    marginTop: '15px',
    marginBottom: '5px',
  },
  title: {
    fontSize: '19px',
  },
  description: {
    fontSize: '14px',
  },
  cloudIcon: {
    marginRight: '5px',
  },
  cloudDownloadIcon: {
    marginRight: '5px',
    fill: COLORS.orange,
  },
  cloudIconNotClicked: {
    marginRight: '5px',
    fill: COLORS.orange,
  },
  cloudIconClicked: {
    marginRight: '5px',
    fill: COLORS.lightGrey,
    animation: '$cloudIconAnimation 400ms linear',
    '&&': {
      cursor: 'default',
    },
  },
  '@keyframes cloudIconAnimation': {
    '0%': {
      transform: 'translateX(0px)',
    },
    '35%': {
      transform: 'translateX(1px)',
    },
    '70%': {
      transform: 'translateX(-1px)',
    },
    '100%': {
      transform: 'translateX(0px)',
    },
  },
  creator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}

export default drillBoxLiteStyles
