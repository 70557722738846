import axiosInstance from '../axiosInterceptor/axiosInstance'

const broadcastSwitch = async (data) => {
  const { broadcastId, orgId, switchToMediaId } = data
  const url = `/livestreams/${broadcastId}/switch?clubId=${orgId}&switchToMediaId=${switchToMediaId}`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default broadcastSwitch
