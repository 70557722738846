import * as React from 'react'
import { Switch, makeStyles } from '@material-ui/core'
import { Field } from 'formik'

const MyBooleanField = (props) => {
  const useStyles = makeStyles({
    checkboxRoot: {
      padding: 0,
    },
  })
  const classes = useStyles()

  return <Field {...props} type='checkbox' as={(props) => <Switch {...props} />} />
}

export default MyBooleanField
