import React from 'react'
import { TableCell, Typography } from '@material-ui/core'

const TypographyField = ({
  columnAlignment,
  label,
  rowIsSelected,
  hasNoDivider,
  colorChoice,
  tableCellStyles,
}) => {
  return (
    <TableCell
      align={columnAlignment}
      style={{
        ...tableCellStyles,
        borderBottom: hasNoDivider ? 'none' : '1px solid rgba(224, 224, 224, 1)',
      }}
    >
      <Typography style={{ color: !rowIsSelected ? colorChoice : '' }}>{label}</Typography>
    </TableCell>
  )
}

export default TypographyField
