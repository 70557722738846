import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Collapse, IconButton, Typography } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import PropTypes, { objectOf } from 'prop-types'
import expandableTextStyles from './expandableText.styles'

const ExpandableText = ({ expandableText, allowMultipleExpanded }) => {
  const [expandedIndexes, setExpandedIndexes] = useState([])
  const useStyles = makeStyles(expandableTextStyles)
  const classes = useStyles()

  const EXPAND_ONLY_ONE = 'expandOnlyOne'
  const EXPAND = 'expand'
  const CLOSE = 'close'

  const TOGGLE_CASES = {
    [EXPAND_ONLY_ONE]: (index) => setExpandedIndexes([index]),
    [EXPAND]: (index) => setExpandedIndexes((prevState) => [...prevState, index]),
    [CLOSE]: (index) => setExpandedIndexes((prevState) => prevState.filter((i) => i !== index)),
  }
  const handleToggle = (index) => {
    expandedIndexes.includes(index)
      ? TOGGLE_CASES[CLOSE](index)
      : allowMultipleExpanded
        ? TOGGLE_CASES[EXPAND](index)
        : TOGGLE_CASES[EXPAND_ONLY_ONE](index)
  }

  return (
    <div>
      {expandableText.map((item, index) => (
        <div key={item.header} className={classes.root}>
          <div className={classes.header} onClick={() => handleToggle(index)}>
            <IconButton
              className={classes.icon}
              onClick={() => handleToggle(index)}
              aria-expanded={expandedIndexes.includes(index)}
              aria-label='show more'
            >
              {expandedIndexes.includes(index) ? <RemoveIcon /> : <AddIcon />}
            </IconButton>
            <Typography variant='body2' className={classes.headerText}>
              {item.header}
            </Typography>
          </div>
          <Collapse in={expandedIndexes.includes(index)} timeout='auto' unmountOnExit>
            <Typography variant='body2' className={classes.message}>
              {item.text}
            </Typography>
          </Collapse>
        </div>
      ))}
    </div>
  )
}

ExpandableText.propTypes = {
  expandableText: PropTypes.arrayOf(objectOf(PropTypes.string)),
  allowMultipleExpanded: PropTypes.bool,
}

export default ExpandableText
