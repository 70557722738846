export const RECENT_BROADCASTS_LIMIT = 6

const BROADCAST_STATES = {
  scheduled: 'scheduled',
  running: 'running',
  finished: 'finished',
  paused: 'paused',
  cancelled: 'cancelled',
  errored: 'errored',
  deleting: 'deleting',
  creating: 'creating',
}

export default BROADCAST_STATES
