import React from 'react'
import { makeStyles, TextField } from '@material-ui/core'
import TEXT_FIELD_STYLES from '../../../utils/constants/CSS/textFieldStyles'

const MySimpleTextField = (props) => {
  const useStyles = makeStyles(TEXT_FIELD_STYLES)
  const classes = useStyles()

  return (
    <TextField
      {...props}
      type='input'
      variant='filled'
      fullWidth
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.root,
          input: classes.input,
          disabled: classes.disabledProps,
        },
      }}
      InputLabelProps={{
        classes: {
          shrink: classes.shrink,
          formControl: classes.formControl,
          focused: classes.focused,
          disabled: classes.disabledLabel,
        },
      }}
    />
  )
}

export default MySimpleTextField
