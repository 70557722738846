export const SPACING = {
  XS: 5,
  S: 10,
  M: 15,
  L: 20,
  XL: 30,
  XXL: 60,
  XXXL: 80,
}

export const TYPOGRAPHY = {
  XXXS: 6,
  XXS: 8,
  XS: 10,
  S: 12,
  M: 14,
  L: 16,
  XL: 18,
  XXL: 20,
  XXXL: 22,
}
