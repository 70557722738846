import { bool, shape, string, arrayOf, oneOfType, oneOf } from 'prop-types'
import tacticType from './tacticType'

const tacticClickedType = shape({
  children: oneOfType([
    oneOf([null]), // Allows null as a valid value
    arrayOf(shape({ ...tacticType, clicked: bool })),
  ]),
  clicked: bool,
  color: string,
  id: string,
  name: string,
  parentId: string,
})

export default tacticClickedType
