export const parseUpdateRecord = (record) => {
  delete record.picture;

  const newTeams = record.teams.map(team => {
    delete team.name;
    return ({ ...team });
  });

  return ({
    ...record,
    teams: newTeams,
  })
};
