import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import { Edit, SimpleForm, ReferenceArrayInput, required, ArrayInput } from 'react-admin'
import stringWithComma from '../../../../utils/helpers/stringWithComma'
import CLOTHING_CHOICES from '../../../../utils/constants/clothingChoices'
import playerEditStyles from './playerEdit.styles'
import FormGrid from '../../../components/layout/FormGrid/FormGrid'
import MyTextInput from '../../../components/inputs/MyTextInput'
import MyNumberInput from '../../../components/inputs/MyNumberInput'
import MyAutocompleteArrayInput from '../../../components/inputs/MyAutocompleteArrayInput'
import MySelectInput from '../../../components/inputs/MySelectInput'
import CustomIterator from '../../../components/CustomIterator/CustomIterator'
import MyEditLogoInput from '../../../components/inputs/MyEditLogoInput'
import PlayerEditToolbar from '../PlayerEditToolbar/PlayerEditToolbar'
import MyDateField from '../../../components/inputs/MyDateField'
import useQueryParams from '../../../../hooks/useQueryParams'
import isUserOrgRolesValid from '../../../../utils/helpers/isUserOrgRolesValid'
import USER_ORG_MEMBERS_ROLES from '../../../../utils/constants/userOrgMembersRoles'
import USER_ORG_ROLES from '../../../../utils/constants/userOrgRoles'
import IsUserRolesAuthorized from '../../../../components/IsUserRolesAuthorized/IsUserRolesAuthorized'

const PlayerEdit = (props) => {
  const queryParams = useQueryParams()
  const useStyles = makeStyles(playerEditStyles)
  const classes = useStyles()

  const editTransform = (data) => ({ ...data, logo: data.logoUrl })

  const userHasNotOnlyTeamMemberRole = isUserOrgRolesValid([
    USER_ORG_ROLES.ADMIN,
    USER_ORG_ROLES.OWNER,
    USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
  ])

  return (
    <Edit
      {...props}
      classes={{ root: classes.root, main: classes.main }}
      mutationMode='optimistic'
      actions={false}
      transform={editTransform}
    >
      <SimpleForm toolbar={<></>} className={classes.simpleForm}>
        <FormGrid container alignItems='center' justifyContent='space-between'>
          <MyEditLogoInput
            lg={7}
            xs={12}
            source='logoUrl'
            disabled={!userHasNotOnlyTeamMemberRole}
          />
          <IsUserRolesAuthorized
            acceptedRoles={[
              USER_ORG_ROLES.OWNER,
              USER_ORG_ROLES.ADMIN,
              USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
            ]}
          >
            <PlayerEditToolbar {...props} lg={5} xs={5} />
          </IsUserRolesAuthorized>
        </FormGrid>

        <FormGrid container spacing={2}>
          <MyTextInput
            source='personalData.firstName'
            label='First Name'
            validate={[required()]}
            sm={12}
            disabled={!userHasNotOnlyTeamMemberRole}
          />
          <MyTextInput
            source='personalData.lastName'
            label='Last Name'
            validate={[required()]}
            sm={12}
            disabled={!userHasNotOnlyTeamMemberRole}
          />
        </FormGrid>

        <FormGrid container spacing={2}>
          <MyDateField
            source='personalData.dateOfBirth'
            label='Date Of Birth'
            gridItemStyle={classes.dateField}
            disabled={!userHasNotOnlyTeamMemberRole}
          />
          <MyNumberInput
            disabled={!userHasNotOnlyTeamMemberRole}
            source='personalData.height'
            label='Height'
            parse={(height) => stringWithComma(height)}
            step={0.01}
          />
        </FormGrid>

        <FormGrid container spacing={2}>
          <ReferenceArrayInput
            reference='positions'
            source='personalData.positions'
            disabled={!userHasNotOnlyTeamMemberRole}
          >
            <MyAutocompleteArrayInput
              source='personalData.positions'
              resettable
              label='Positions'
              // TODO In positions provider use getMany method.
              // .map will create a new reference which causes re-render. Should return the same reference in format.
              format={(v) => {
                if (v && v.length > 0) {
                  for (let i = 0; i < v.length; i++) {
                    if (typeof v[i] === 'object') {
                      v[i] = v[i].id
                    }
                  }
                  return v
                }
              }}
            />
          </ReferenceArrayInput>
        </FormGrid>

        <ArrayInput
          source='teams'
          label=''
          classes={{ root: classes.arrayInputJersey }}
          disabled={!userHasNotOnlyTeamMemberRole}
        >
          <CustomIterator org={queryParams?.org} fieldArrayName='teams' />
        </ArrayInput>

        <FormGrid container spacing={2}>
          <span className={classes.text}>CONTACT DATA</span>
        </FormGrid>

        <FormGrid container spacing={2}>
          <MyTextInput
            source='personalData.email'
            label='E-mail'
            type='email'
            sm={12}
            disabled={!userHasNotOnlyTeamMemberRole}
          />
          <MyNumberInput
            source='personalData.phoneNumber'
            label='Tel-number'
            type='tel'
            sm={12}
            disabled={!userHasNotOnlyTeamMemberRole}
          />
        </FormGrid>

        <FormGrid container spacing={2}>
          <span className={classes.text}>CLOTHING & SHOE SIZE</span>
        </FormGrid>

        <FormGrid container spacing={2}>
          <MyTextInput
            source='clothingData.shoeSize'
            label='shoe size (EU)'
            sm={12}
            md={12}
            disabled={!userHasNotOnlyTeamMemberRole}
          />
          <MySelectInput
            source='clothingData.jerseySize'
            label='Jersey size'
            choices={CLOTHING_CHOICES}
            sm={12}
            md={12}
            disabled={!userHasNotOnlyTeamMemberRole}
          />
          <MySelectInput
            source='clothingData.shortsSize'
            label='Shorts size'
            choices={CLOTHING_CHOICES}
            disabled={!userHasNotOnlyTeamMemberRole}
          />
        </FormGrid>
      </SimpleForm>
    </Edit>
  )
}

export default PlayerEdit
