const findReplacedObjectBetweenTwoArrays = (arr1, arr2) => {
  const obj2 = arr2.find((obj) => !arr1.some((o) => obj.id === o.id))

  if (obj2) {
    return obj2
  } else if (arr2.length !== arr1.length) {
    return arr2.find((obj) => !arr1.some((o) => obj.id === o.id))
  } else {
    return null
  }
}

export default findReplacedObjectBetweenTwoArrays
