import COLORS from '../../../utils/constants/colors'

const signUpFormStyles = (theme) => ({
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 15,
  },
  termWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  link: {
    color: COLORS.purple,
  },
  errorMessage: {
    fontSize: 12,
    color: theme.palette.error.main,
    marginTop: 3,
    marginLeft: 14,
    marginRight: 14,
    textAlign: 'start',
  },
})

export default signUpFormStyles;
