import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const CardWrapperMoreIcon = ({ children }) => {
  const useStyles = makeStyles({
    wrapper: {
      paddingLeft: '7%',
      paddingRight: '7%',
      height: '100%',
    },
  })
  const classes = useStyles()

  return <div className={classes.wrapper}>{children}</div>
}

CardWrapperMoreIcon.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default CardWrapperMoreIcon
