import COLORS from '../../utils/constants/colors'
import { CARD_STYLES } from '../../utils/constants/CSS/cardStyles'

const invitationsListStyles = {
  card: CARD_STYLES.card,
  cardTitle: CARD_STYLES.cardTitle,
  cardSubtitle: {
    ...CARD_STYLES.cardSubtitle,
  },
  cancelButton: {
    '&&': {
      color: COLORS.red,
    },
  },
  deleteButton: {
    '&&': {
      backgroundColor: COLORS.red,
      color: COLORS.white,
      '&:hover': {
        backgroundColor: COLORS.red,
      },
    },
  },
}

export default invitationsListStyles
