import COLORS from '../../utils/constants/colors'

const practiceBoxStyles = (hasManagerAccess) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    backgroundColor: COLORS.white,
    borderRadius: 5,
    padding: '22px 22px',
    margin: 10,
    cursor: hasManagerAccess ? 'pointer' : '',
  },
  practiceInfoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  practiceAccordionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    marginLeft: 78,
  },
  practiceIconsWrapper: {
    display: 'flex',
    gap: 20,
  },
  practiceButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  notes: {
    fontSize: 14,
  },
  notebook: {
    marginLeft: 'auto',
    zIndex: 1000,
  },
  orangeIcon: {
    '&&': {
      zIndex: 1000,
      fill: COLORS.orange,
    },
  },
})

export default practiceBoxStyles
