import * as React from 'react'
import { Divider, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useState } from 'react'
import designsStyles from './designs.styles'
import Design from '../design/Design'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import mutationType from '../../types/mutationType'
import designType from '../../types/designType'
import DragIcon from '../../assets/icons/dragIcon'

const Designs = (props) => {
  const {
    designs,
    deleteDesign,
    editDesign,
    changeDesignOrder,
    noIcons,
    type,
    show,
    drillId,
    formValues,
    patchDrill,
    setFieldValue,
  } = props
  const useStyles = makeStyles(designsStyles(type))
  const classes = useStyles()

  const handleDragEnd = (result) => {
    if (!result.destination) return

    const { source, destination } = result

    changeDesignOrder(source, destination, setFieldValue, designs)
  }

  const sortedDesigns = designs.sort((a, b) => a.order - b.order)

  return editDesign ? (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='ROOT' direction='vertical'>
        {(droppableProvided) => (
          <div
            className={classes.wrapper}
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
          >
            {sortedDesigns &&
              sortedDesigns.length > 0 &&
              sortedDesigns.map((design, index) => (
                <Draggable
                  draggableId={design.id || design.order}
                  key={design.id || design.order}
                  index={index}
                >
                  {(draggableProvided) => (
                    <div
                      key={design.id || design.order}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                    >
                      <div
                        className={classes.draggableDesign}
                        {...draggableProvided.dragHandleProps}
                      >
                        <DragIcon />
                        <Design
                          key={design.id || design.order}
                          index={index}
                          design={design}
                          designs={designs}
                          designNumber={`${index + 1}/${designs.length}`}
                          deleteDesign={() => deleteDesign(design.order)}
                          editDesign={() => editDesign(design.order)}
                          noIcons={noIcons}
                          type={type}
                          show={show}
                          drillId={drillId}
                          formValues={formValues}
                          patchDrill={patchDrill}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <ConditionalRender renderValue={type !== 'drills'}>
                        <Divider className={classes.divider} light />
                      </ConditionalRender>
                    </div>
                  )}
                </Draggable>
              ))}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <div className={classes.wrapper}>
      {sortedDesigns &&
        sortedDesigns.length > 0 &&
        sortedDesigns.map((design, index) => (
          <div key={design.id || design.order}>
            <Design
              key={design.id || design.order}
              index={index}
              design={design}
              designs={designs}
              designNumber={`${index + 1}/${designs.length}`}
              deleteDesign={() => deleteDesign(design.order)}
              editDesign={() => editDesign(design.order)}
              noIcons={noIcons}
              type={type}
              show={show}
              drillId={drillId}
              formValues={formValues}
              patchDrill={patchDrill}
              setFieldValue={setFieldValue}
            />
            <ConditionalRender renderValue={type !== 'drills'}>
              <Divider className={classes.divider} light />
            </ConditionalRender>
          </div>
        ))}
    </div>
  )
}

Designs.propTypes = {
  designs: PropTypes.arrayOf(designType),
  deleteDesign: PropTypes.func,
  editDesign: PropTypes.func,
  noIcons: PropTypes.bool,
  type: PropTypes.string,
  show: PropTypes.bool,
  drillId: PropTypes.string,
  formValues: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    access: PropTypes.bool,
    intensities: PropTypes.arrayOf(PropTypes.string),
    positions: PropTypes.arrayOf(PropTypes.string),
  }),
  patchDrill: mutationType,
  setFieldValue: PropTypes.func,
  changeDesignOrder: PropTypes.func,
}

export default Designs
