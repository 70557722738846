import COLORS from '../../../../utils/constants/colors'

const myUploadFilesFieldStyles = {
  mediaUploadContainer: { display: 'flex', gap: '12px', flexDirection: 'column' },
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  addButton: {
    flex: 1,
  },
  infoIcon: {
    color: COLORS.grey,
  },
  visuallyHiddenInput: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  },
  uploadedFile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cancelButton: { fill: COLORS.grey, cursor: 'pointer' },
  completedText: { display: 'flex', justifyContent: 'flex-end' },
}

export default myUploadFilesFieldStyles
