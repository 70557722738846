import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import EditIcon from '@material-ui/icons/Edit'
import courtLogosPreviewEditBoxStyles from './courtLogosPreviewEditBox.styles'
import ConditionalRender from '../ConditionalRender/ConditionalRender'

const CourtLogosPreviewEditBox = (props) => {
  const { className, imageSrc, handleEdit, isDisabled, iconSize } = props
  const [hovered, setHovered] = useState(false)
  const useStyles = makeStyles(courtLogosPreviewEditBoxStyles(hovered, isDisabled, iconSize))
  const classes = useStyles()

  const handleMouseOver = () => {
    setHovered(true)
  }

  const handleMouseOut = () => {
    setHovered(false)
  }

  return (
    <div
      className={`${classes.wrapper} ${className}`}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      onClick={handleEdit}
    >
      {imageSrc ? (
        <>
          <div className={classes.imgContainer}>
            <img className={classes.img} src={imageSrc} alt='court-avatar' />
          </div>

          <ConditionalRender renderValue={hovered && !isDisabled}>
            <EditIcon className={classes.editIcon} />
          </ConditionalRender>
        </>
      ) : (
        <AddPhotoAlternateIcon className={classes.addPhotoIcon} onClick={handleEdit} />
      )}
    </div>
  )
}

CourtLogosPreviewEditBox.propTypes = {
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  handleEdit: PropTypes.func,
  isDisabled: PropTypes.bool,
  iconSize: PropTypes.string,
}

export default CourtLogosPreviewEditBox
