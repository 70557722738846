const selectEventTypeStyles = (theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,

    [theme.breakpoints.down(1000)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 10,
    },
  },
})

export default selectEventTypeStyles
