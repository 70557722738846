const playersListItemStyles = {
  card: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1px',
    cursor: 'pointer',
    fontSize: 'small',
    width: '100%',
    height: '80px',
    position: 'relative',
    transition: 'box-shadow 0.1s ease',
    '&:hover': {
      boxShadow: `0px 0px 8px 2px #f9e3e2`,
    },
    justifyContent: 'space-around',
  },
  avatar: {
    width: '28px',
    height: '28px',
    marginLeft: '8px',
  },
  selectedCard: {
    boxShadow: `0px 0px 5px 2px #E0726E`,
  },
  clickedCard: {
    backgroundColor: '#f5f5f5',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    marginLeft: '1px',
    flexGrow: 0,
    textAlign: 'center',
  },
  playerName: {
    fontSize: 'small',
    marginBottom: '1px',
    wordWrap: 'break-word',
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '1px',
    justifyContent: 'center',
  },
  chip: {
    marginRight: '1px',
    marginBottom: '1px',
    fontSize: 'x-small',
  },
  booleanField: {
    position: 'absolute',
    bottom: '30px',
    right: '4px',
  },
}

export default playersListItemStyles
