import React, { useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useTranslate } from 'react-admin'
import { v4 } from 'uuid'
import MyTable from '../../../components/DynamicTable/MyTable/MyTable'
import TypographyField from '../../../components/DynamicTable/Fields/TypographyField/TypographyField'
import UserField from '../../../components/DynamicTable/Fields/UserField/UserField'
import DialogWithCloseIcon from '../../../components/DialogWithCloseIcon/DialogWithCloseIcon'
import UsersDrawer from '../UsersDrawer/UsersDrawer'
import useQueryParams from '../../../hooks/useQueryParams'
import useHistoryPush from '../../../hooks/useHistoryPush'
import QueryKeys from '../../../utils/constants/queryKeys'
import getOrgUsers from '../../../Apis/organisation/getOrgUsers'
import LoaderDots from '../../../components/loaders/LoaderDots/LoaderDots'
import MultiStringWithCommaField from '../../../components/DynamicTable/Fields/MultiStringWithCommaField/MultiStringWithCommaField'
import getOrgPendingInvitations from '../../../Apis/invitations/getOrgPendingInvitations'
import getTeams from '../../../Apis/team/getTeams'
import ChipTableField from '../../../components/DynamicTable/Fields/ChipTableField/ChipTableField'
import COLORS from '../../../utils/constants/colors'
import CardWrapperMoreIcon from '../../../components/layout/CardWrapperMoreIcon/CardWrapperMoreIcon'
import AddButton from '../../../components/ui/AddButton/AddButton'
import orgUsersPageStyles from './orgUsersPage.styles'
import getSubscriptionDetails from '../../../Apis/subscriptions/getSubscriptionDetails'
import InviteUsersWizardContents from '../InviteUsersWizardContents'
import inviteUsers from '../../../Apis/invitations/inviteUsers'
import getUserData from '../../../Apis/user/getUserData'
import OrgSeatManagement from '../../../components/OrgSeatManagement/OrgSeatManagement'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'

const OrgUsersPage = () => {
  const queryParams = useQueryParams()
  const { user, org: orgId } = queryParams
  const [orgUsers, setOrgUsers] = useState([])
  const [isInviteUsersModalOpen, setIsInviteUsersModalOpen] = useState(false)
  const [isOrgSeatsModalOpen, setIsOrgSeatsModalOpen] = useState(false)
  const [isUserDrawerOpen, setIsUserDrawerOpen] = useState(Boolean(user))
  const [invitations, setInvitations] = useState()
  const { redirect } = useHistoryPush()
  const queryClient = useQueryClient()
  const translate = useTranslate()
  const useStyles = makeStyles(orgUsersPageStyles)
  const classes = useStyles()
  const [emails, setEmails] = useState([])

  const me = useQuery([QueryKeys.GET_USER_BY_EMAIL], () => getUserData())

  const usersOrder = { Pending: 1, Active: 2, Rejected: 3 }
  const steps = ['Invite Users', 'Set Roles']

  const inviteUsersMutation = useMutation(
    QueryKeys.INVITE_USERS_TO_ORG,
    (data) => inviteUsers({ data }),
    {
      onSuccess: () => {
        setEmails([])
        queryClient.invalidateQueries(QueryKeys.GET_ORG_PENDING_INVITATIONS).then(() => {})
        queryClient.invalidateQueries(QueryKeys.GET_SUBSCRIPTION_DETAILS).then(() => {})
        setIsInviteUsersModalOpen(false)
      },
    },
  )

  const { data: teams, isLoading: teamsIsLoading } = useQuery([QueryKeys.GET_TEAMS], () =>
    getTeams(orgId),
  )

  const subscriptionDetails = useQuery(
    [QueryKeys.GET_SUBSCRIPTION_DETAILS],
    () => getSubscriptionDetails({ orgId }),
    { enabled: Boolean(orgId) },
  )

  function setTeamProp(isAdmin, isOwner, allTeams, roles) {
    return isAdmin || isOwner
      ? ['All']
      : allTeams
          .filter(
            (team) =>
              Object.keys(roles).includes(team.id) || Object.keys(roles).includes(team.name),
          )
          .map((selTeam) => selTeam.name)
  }

  const parseOrgUserInfo = (orgUser) => {
    const { firstName, lastName, roles } = orgUser
    const isAdmin = Object.values(orgUser.roles).includes('Admin')
    const isOwner = Object.values(orgUser.roles).includes('Owner')

    return {
      ...orgUser,
      isInvitation: false,
      name: firstName ? firstName.concat(' ', lastName) : '',
      status: 'Active',
      isAdmin,
      teams: setTeamProp(isAdmin, isOwner, teams, roles),
      rolesList: Object.values(roles).map((role) =>
        role.replace(/([A-Z])/g, (match) => ` ${match}`).trim(),
      ),
      roles: Object.entries(orgUser.roles).map(([teamName, role]) => ({
        team: teams.find((item) => item.name === teamName),
        role,
      })),
    }
  }

  const parseOrgInvitationInfo = (invUser) => {
    const { firstName, lastName, receiverEmail, isAdmin, isOwner, roles } = invUser

    return {
      ...invUser,
      isInvitation: true,
      name: firstName ? firstName.concat(' ', lastName) : '',
      email: receiverEmail,
      teams: setTeamProp(isAdmin, isOwner, teams, roles),
      rolesList: isAdmin
        ? ['Admin']
        : isOwner
          ? ['Owner']
          : Object.values(roles).map((role) =>
              role.replace(/([A-Z])/g, (match) => ' ' + match).trim(),
            ),
      roles: Object.entries(invUser.roles).map(([team, role]) => ({
        team: teams.find((teamItem) => teamItem.id === team) || '',
        role,
      })),
    }
  }

  const orgUsersMutation = useMutation([QueryKeys.GET_ORG_USERS], () => getOrgUsers(orgId), {
    enabled: !teamsIsLoading,
    onSuccess: (response, { invitations }) => {
      setOrgUsers([
        ...invitations.map((invUser) => parseOrgInvitationInfo(invUser)),
        ...response.map((orgUser) => parseOrgUserInfo(orgUser)),
      ])
    },
  })

  const orgOwnerInvitationsQuery = useQuery(
    [QueryKeys.GET_ORG_PENDING_INVITATIONS],
    () => getOrgPendingInvitations({ orgId }),
    {
      enabled: !teamsIsLoading,
      onSuccess: (response) => {
        orgUsersMutation.mutate({ invitations: response })
      },
    },
  )

  const userInfoReformat = (userInfo) => {
    const { id, name, email, logoUrl, scopes, roles, receiverEmail, isAdmin } = userInfo || {}
    const isInvitation = Boolean(receiverEmail)

    return {
      id,
      name,
      email,
      logoUrl,
      isInvitation,
      scopes,
      orgRole: isAdmin ? 'Admin' : 'Member',
      roles: isAdmin
        ? [{ key: v4() }]
        : roles.map((userRole) => {
            const { team, role } = userRole
            return {
              key: v4(),
              team,
              role: role.replace(/([A-Z])/g, (match) => ` ${match}`).trim(),
            }
          }),
    }
  }

  const onRowClick = (event, row) => {
    redirect('/orgUsers', [{ user: row.id }])
    setIsUserDrawerOpen(true)
  }
  return (
    <>
      <DialogWithCloseIcon
        open={isInviteUsersModalOpen}
        onClose={() => {
          setEmails([])
          setIsInviteUsersModalOpen(false)
        }}
        dialogWidth='60%'
        wizardSteps={steps}
      >
        <InviteUsersWizardContents
          me={me}
          emails={emails}
          setEmails={setEmails}
          invitations={invitations}
          setInvitations={setInvitations}
          orgUsers={orgUsers}
          orgId={orgId}
          subscriptionDetails={subscriptionDetails?.data}
          inviteUsersMutation={inviteUsersMutation}
          onClose={() => {
            setEmails([])
            setIsInviteUsersModalOpen(false)
          }}
        />
      </DialogWithCloseIcon>
      <DialogWithCloseIcon
        open={isOrgSeatsModalOpen}
        onClose={() => setIsOrgSeatsModalOpen(false)}
        dialogTitle={translate('ra.text.orgSeats')}
        dialogWidth='60%'
      >
        <OrgSeatManagement subDetails={subscriptionDetails.data} isModal />
      </DialogWithCloseIcon>
      <CardWrapperMoreIcon>
        <Card className={classes.card}>
          <CardHeader
            title={
              <Grid container justifyContent='space-between' alignItems='center'>
                <Typography className={classes.cardTitle} variant='h5'>
                  {translate('ra.text.OrgUsers')}
                </Typography>
              </Grid>
            }
            subheader={
              <Typography className={classes.cardSubtitle}>
                {translate('ra.text.orgUsersList')}
              </Typography>
            }
          />
          <CardContent>
            <Divider variant='middle' className={classes.divider} />
            <div className={classes.addButtonWrapper}>
              <ButtonWithLoader
                label='Buy Seats'
                style={{ backgroundColor: COLORS.subscriptionBlue }}
                onClick={() => setIsOrgSeatsModalOpen(true)}
              />
              <AddButton label='Invite' onClick={() => setIsInviteUsersModalOpen(true)} filled />
            </div>
            {orgUsersMutation?.isLoading || orgOwnerInvitationsQuery?.isLoading ? (
              <LoaderDots style={orgUsersPageStyles.loaderIcon} />
            ) : (
              <MyTable
                rows={orgUsers
                  .filter((user) => !user.rolesList.includes('Owner'))
                  .sort((a, b) => usersOrder[a.status] - usersOrder[b.status])}
                onRowClick={onRowClick}
                allowClickHoleLine
                hasHeader
                hasDivider
                size='small'
                boldHeader
              >
                <UserField title='Name' key='name' logoName='logoUrl' />
                <TypographyField title='Email' key='email' colorChoice={COLORS.black} />
                <MultiStringWithCommaField title='Role' key='rolesList' maxStringsShown={5} />
                <MultiStringWithCommaField title='Team' key='teams' maxStringsShown={2} />
                <ChipTableField title='Status' key='status' />
              </MyTable>
            )}
          </CardContent>
        </Card>
      </CardWrapperMoreIcon>
      {user && orgUsers.some((orgUser) => orgUser.id === user) && (
        <UsersDrawer
          open={isUserDrawerOpen}
          onClose={() => {
            redirect('/orgUsers', [], ['user'])
            setIsUserDrawerOpen(false)
          }}
          userDetails={userInfoReformat(orgUsers.find((orgUser) => orgUser.id === user))}
          teams={teams}
          subscriptionDetails={subscriptionDetails}
        />
      )}
    </>
  )
}

export default OrgUsersPage
