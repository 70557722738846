import * as React from 'react'
import { Drawer, makeStyles } from '@material-ui/core'
import { useQueryClient } from 'react-query'
import CloseDrawer from '../../components/CloseDrawer/CloseDrawer'
import SelectEventType from '../../components/SelectEventType/SelectEventType'
import { useTeamCalendarContext } from '../../context/useTeamCalendarContext'
import calendarPageStyles from './CalendarPage/calendarPage.styles'
import QueryKeys from '../../utils/constants/queryKeys'
import useHistoryPush from '../../hooks/useHistoryPush'

const CreateEventDrawer = () => {
  const {
    setClickedEventId,
    createEventDrawerOpen,
    setDateClick,
    selectedEventType,
    event,
    formDirty,
    createEvent,
    updateEvent,
    eventTypes,
    setSelectedEventType,
    clickedEventId,
    setFormDirty,
    currentTeam,
    teams,
    dateClick,
    eventsRaw,
    setEvents,
    setCreateEventDrawerOpen,
    courts,
  } = useTeamCalendarContext()
  const queryClient = useQueryClient()
  const { redirect, pathname } = useHistoryPush()
  const useStyles = makeStyles(calendarPageStyles)
  const classes = useStyles()

  const handleCloseDrawerClick = () => {
    queryClient.invalidateQueries(QueryKeys.GET_EVENTS)
    setCreateEventDrawerOpen(false)
    setClickedEventId('')
    setDateClick({})
    redirect(`/${pathname.split('/')[1]}`, [], ['breadcrumb', 'eventType'])
  }

  return (
    <Drawer
      open={createEventDrawerOpen}
      anchor='right'
      classes={{ paper: classes.drawerPaper }}
      disableEnforceFocus
    >
      <div className={classes.drawerContainer}>
        <CloseDrawer onClick={handleCloseDrawerClick} />
        {(clickedEventId && event) || !clickedEventId ? (
          <SelectEventType
            selectedEventType={selectedEventType}
            event={event}
            formDirty={formDirty}
            setFormDirty={setFormDirty}
            createEvent={createEvent}
            updateEvent={updateEvent}
            eventTypes={eventTypes}
            setSelectedEventType={setSelectedEventType}
            clickedEventId={clickedEventId}
            currentTeam={currentTeam}
            teams={teams}
            dateClick={dateClick}
            eventsRaw={eventsRaw}
            setEvents={setEvents}
            disabled={event}
            courts={courts}
          />
        ) : (
          <h3>Loading...</h3>
        )}
      </div>
    </Drawer>
  )
}
export default CreateEventDrawer
