import COLORS from '../../utils/constants/colors'

const practiceBoxIconsStyles = {
  wrapperFlex: {
    display: 'flex',
    alignItems: 'center',
    gap: '25px',
    color: COLORS.grey,
    fontSize: '14px',
  },
  clockFlex: {
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
  },
  divFlex: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
}

export default practiceBoxIconsStyles
