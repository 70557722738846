import { React, useEffect, useRef, useState } from 'react'
// import video.js as external module, otherwise doesn't work
import videojs from '!video.js'
import { useTranslate } from 'react-admin'
import 'video.js/dist/video-js.css'
import { useQuery } from 'react-query'
import { Divider, makeStyles } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import LivestreamPlayerToolbar from './LivestreamPlayerToolbar/LivestreamPlayerToolbar'
import liveStreamPlayerStyles from './LiveStreamPlayer.styles'
import liveStreamPlayerSettings from '../../utils/constants/LiveStreamPlayerSettings'
import getLivestreamURL from '../../Apis/livestream/getLivestreamUrl'
import QueryKeys from '../../utils/constants/queryKeys'
import LoaderDots from '../../components/loaders/LoaderDots/LoaderDots'
import useQueryParams from '../../hooks/useQueryParams'
import isStringUrl from '../../utils/helpers/isStringUrl'

const LivestreamPlayerPage = () => {
  const [isLightMode, setIsLightMode] = useState(true)
  const videoNode = useRef(null)
  const player = useRef(null)
  const initialized = useRef(false)
  const location = useLocation()
  const { eventId } = useQueryParams()
  const broadcastId = location?.pathname?.split('/')?.[2]
  const translate = useTranslate()
  const useStyles = makeStyles(liveStreamPlayerStyles(isLightMode))
  const classes = useStyles()

  const { data, isLoading, isFetchedAfterMount } = useQuery(
    [QueryKeys.GET_SPECIFIC_LIVESTREAM],
    () => getLivestreamURL(eventId, broadcastId),
    { retry: true, retryDelay: 15000 },
  )

  useEffect(() => {
    if (videoNode.current && !initialized.current && !isLoading && isFetchedAfterMount) {
      initialized.current = true // prevent duplicate initialization
      player.current = videojs(videoNode.current, {
        ...liveStreamPlayerSettings,
        sources: [
          {
            src: isStringUrl(data)
              ? data
              : `data:application/vnd.apple.mpegurl;base64,${btoa(data)}`,
            type: 'application/x-mpegURL',
          },
        ],
      })
    }
  }, [broadcastId, eventId, isLoading, isFetchedAfterMount, data])

  return (
    <div>
      <LivestreamPlayerToolbar
        broadcastId={broadcastId}
        isLightMode={isLightMode}
        setIsLightMode={setIsLightMode}
      />
      <Divider />
      <div className={classes.mainContent}>
        {isLoading ? (
          <div className={classes.loaderContainer}>
            <span className={classes.loaderText}>{translate('ra.text.streamNotStarted')}</span>
            <LoaderDots style={liveStreamPlayerStyles().loaderIcon} />
          </div>
        ) : (
          <video ref={videoNode} className='video-js vjs-default-skin vjs-fill'>
            <track kind='captions' />
          </video>
        )}
      </div>
    </div>
  )
}
export default LivestreamPlayerPage
