import COLORS from '../../utils/constants/colors'

const drillSliderStyles = {
  drillSliderWrapper: {
    position: 'relative',
    height: '100%',
    maxWidth: 280,
    minWidth: 280,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
  },
  leftArrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 10,
    borderRadius: '50%',
    padding: 4,
    backgroundColor: COLORS.white,
    boxShadow: '0px 0px 1px #0707078a',
  },
  rightArrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 10,
    borderRadius: '50%',
    padding: 4,
    backgroundColor: COLORS.white,
    boxShadow: '0px 0px 1px #0707078a',
  },
}

export default drillSliderStyles
