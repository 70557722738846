const selectOrgStyles = {
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectInput: {
    padding: '15px 12px 15px',
  },
  menuItemRoot: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
  },
  menuItemSelected: {
    '&&': {
      backgroundColor: 'transparent',
    },
  },
  addButton: {
    '&&': {
      padding: 6,
    },
  },
}

export default selectOrgStyles
