const selectedPlayerStyles = {
  card: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1px',
    fontSize: 'small',
    width: '102%',
    height: '75px',
    position: 'relative',
  },
  avatar: {
    width: '28px',
    height: '28px',
    marginLeft: '2px',
  },
  cardHeader: {
    padding: '0px',
  },
  selectedCard: {
    boxShadow: `0px 0px 5px 2px #E0726E`,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    padding: 0,
    margin: 0,
  },
  playerName: {
    fontSize: 'small',
    marginBottom: '1px',
    textAlign: 'center',
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginBottom: '1px',
    fontSize: 'x-small',
  },
  dragIcon: {
    padding: '0px',
  },
  cardActions: {
    justifyContent: 'center',
    padding: 0,
  },
  span: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export default selectedPlayerStyles
