const imageCropWithEditStyles = {
  dialogBodyWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  imageCrop: {
    alignSelf: 'center',
  },
  buttonsWrapper: {
    marginRight: 10,
  },
}

export default imageCropWithEditStyles
