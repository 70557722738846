import { number, shape, string, bool } from 'prop-types'

const designType = shape({
  description: string,
  id: string,
  isZoomedIn: bool,
  order: number,
  preview: string,
  source: string,
})

export default designType
