import { BASE_URL } from '../../utils/constants/url'
import axiosInstance from '../axiosInterceptor/axiosInstance'

const getOrgQuoteMulti = async (data) => {
  const { orgId, addonSeats } = data
  const url = `${BASE_URL}/billing/subscriptions/get-quote-multi?orgId=${orgId}`
  const response = await axiosInstance.post(url, addonSeats)
  return response?.data
}

export default getOrgQuoteMulti
