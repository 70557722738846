import COLORS from '../../../utils/constants/colors'

const drillShowToolbarStyles = {
  editIcon: {
    '&&': {
      fill: COLORS.orange,
    },
  },
  deleteIcon: {
    '&&': {
      fill: COLORS.orange,
    },
  },
  cloudIconNotClicked: {
    marginRight: '5px',
    fill: COLORS.orange,
  },
  cloudIconClicked: {
    marginRight: '5px',
    fill: COLORS.lightGrey,
    animation: '$cloudIconAnimation 400ms linear',
    '&&': {
      cursor: 'default',
    },
  },
  buttonLabel: {
    color: COLORS.orange,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
  },
  disabledButtonLabel: {
    color: COLORS.darkGrey,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
  },
}

export default drillShowToolbarStyles
