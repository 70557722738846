import COLORS from '../../../../utils/constants/colors'

const mySelectFieldStyles = {
  formControlRoot: {
    minWidth: '170px',
  },

  // InputLabelProps
  formControl: {
    transform: 'translate(12px, 14px)',
    fontSize: '0.9rem',
    color: 'rgba(0, 0, 0, 0.40)',
  },
  shrink: {
    '&&': {
      transform: 'translate(12px, -6px)',
      fontSize: '12px',
    },
    color: `${COLORS.black}`,
  },
  focused: {
    '&&': {
      color: `${COLORS.black}`,
    },
  },
  error: {
    '&&&': {
      color: `${COLORS.errorRed}`,
    },
  },

  // Select
  select: {
    fontSize: '0.95rem',
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  selectInput: {
    padding: '15px 12px 15px',
  },
  emptyOptionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
}

export default mySelectFieldStyles
