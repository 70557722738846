import * as React from 'react'
import PropTypes from 'prop-types'

const DescriptionField = ({ text, label, titleStyle, recordStyle }) => {
  return (
    <>
      <div className={titleStyle}>{label}</div>
      <div className={recordStyle}>{text}</div>
    </>
  )
}

DescriptionField.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string,
  titleStyle: PropTypes.string,
  recordStyle: PropTypes.string,
}

export default DescriptionField
