import lodashGet from 'lodash/get'

const myMenuStyles = (theme, isSmallScreenMenuOpen) => {
  const SIDEBAR_WIDTH = 250
  const MENU_WIDTH = 240
  const CLOSED_MENU_WIDTH = 60

  return {
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0.5em',
      marginBottom: '1em',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    open: {
      width: lodashGet(theme, 'menu.width', MENU_WIDTH),
    },
    closed: {
      width: lodashGet(
        theme,
        'menu.closedWidth',
        isSmallScreenMenuOpen ? SIDEBAR_WIDTH : CLOSED_MENU_WIDTH,
      ),
    },
  }
}

export default myMenuStyles
