import COLORS from '../../utils/constants/colors'

const teamsDisplayStyles = {
  tableContainer: {
    marginTop: ' 20px',
    marginBottom: '20px',
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: ' 20px',
  },
  buttons: {
    display: 'flex',
  },
  table: {
    border: '1px solid black',
  },
  tableRow: {
    backgroundColor: COLORS.orange,
  },
  headerTableCell: {
    border: '1px solid black',
    textAlign: 'center',
  },
  tableCell: {
    border: 'none',
    textAlign: 'center',
    padding: '8px',
    width: '2%',
  },
}

export default teamsDisplayStyles
