import React from 'react'
import { useNotify, useRefresh, useTranslate, useUpdate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { Button, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { parseUpdateRecord } from '../../../utils/helpers/parseUpdatedRecord'
import updateButtonStyles from './updateButton.styles'

const UpdateButton = (props) => {
  const { resource, id, closeDrawer } = props
  const formState = useFormState()
  const { values, dirty } = formState
  const notify = useNotify()
  const refresh = useRefresh()
  const translate = useTranslate()
  const useStyles = makeStyles(updateButtonStyles)
  const classes = useStyles()

  const [update] = useUpdate(resource, id, parseUpdateRecord(values), '', {
    onSuccess: () => {
      notify(`${translate('ra.notification.updated')}`, 'success', undefined, false, 5000)
      closeDrawer()
      refresh()
    },
    onFailure: () => {
      notify(`${translate('ra.notification.updatedError')}`, 'error', undefined, false, 5000)
    },
  })

  const handleUpdate = () => {
    update()
  }

  return (
    <Button
      variant='text'
      onClick={handleUpdate}
      disabled={!dirty}
      classes={{
        root: classes.root,
      }}
    >
      {translate('ra.buttons.save')}
    </Button>
  )
}

UpdateButton.propTypes = {
  resource: PropTypes.string,
  id: PropTypes.string,
  closeDrawer: PropTypes.func,
}

export default UpdateButton
