import React from 'react'
import { makeStyles, Menu, MenuItem } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import drillListAppbarFiltersMenuStyles from './DrillListAppbarFiltersMenu.styles'
import { DRILLS_FILTERS_TYPES } from '../../../utils/constants/drillsFiltersTypes'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'

const DrillListAppbarFiltersMenu = (props) => {
  const {
    open,
    onClose,
    anchorEl,
    onMenuItemClick,
    onTacticsItemClick,
    showSelectFilter,
    showDrillsFilters,
    tactics,
  } = props
  const translate = useTranslate()
  const { intensity, position } = DRILLS_FILTERS_TYPES
  const useStyles = makeStyles(drillListAppbarFiltersMenuStyles)
  const classes = useStyles()

  return (
    <Menu open={open} onClose={onClose} anchorEl={anchorEl}>
      <ConditionalRender renderValue={showDrillsFilters}>
        <MenuItem
          className={classes.menuItemRoot}
          onClick={() => onMenuItemClick(intensity)}
          selected={showSelectFilter.includes(intensity)}
        >
          {translate('ra.formFields.intensity')}
        </MenuItem>
        <MenuItem
          className={classes.menuItemRoot}
          onClick={() => onMenuItemClick(position)}
          selected={showSelectFilter.includes(position)}
        >
          {translate('ra.formFields.positions')}
        </MenuItem>
      </ConditionalRender>
      <MenuItem
        className={classes.menuItemRoot}
        onClick={onTacticsItemClick}
        selected={tactics.length}
      >
        {translate('ra.formFields.tactics')}
      </MenuItem>
    </Menu>
  )
}

export default DrillListAppbarFiltersMenu
