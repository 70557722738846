import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import SecurityIcon from '@material-ui/icons/Security'
import GavelIcon from '@material-ui/icons/Gavel'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import COLORS from './colors'
import TERMS_AND_PRIVACY_URLS from './termsAndPrivacyUrls'

const PROFILE_MENU_OPTIONS = [
  { href: 'profile', text: 'Profile Settings', icon: <SettingsIcon /> },
  { href: 'invitationsList', text: 'Invitations', icon: <PersonAddIcon /> },
  {
    href: TERMS_AND_PRIVACY_URLS.TERMS_OF_USE,
    text: 'Terms Of Use',
    icon: <GavelIcon style={{ fill: COLORS.grey }} />,
  },
  {
    href: TERMS_AND_PRIVACY_URLS.PRIVACY_POLICY,
    text: 'Privacy Notice',
    icon: <SecurityIcon style={{ fill: COLORS.grey }} />,
  },
]

export default PROFILE_MENU_OPTIONS
