import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'react-error-boundary'
import { logoStyles, noLayoutContainerStyles } from './noLayoutContainer.styles'
import LogoIcon from '../../../assets/icons/LogoIcon'
import MobileNotificationModal from '../../MobileNotificationModal/MobileNotificationModal'
import MyError from '../../../react-admin/components/MyError/MyError'

const NoLayoutContainer = ({ children }) => {
  const useStyles = makeStyles(noLayoutContainerStyles)
  const classes = useStyles()

  return (
    <ErrorBoundary fallback={<MyError />}>
      <div className={classes.wrapper}>
        <LogoIcon big style={logoStyles} />
        <MobileNotificationModal />
        <div className={classes.formWrapper}>{children}</div>
      </div>
    </ErrorBoundary>
  )
}

NoLayoutContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default NoLayoutContainer
