import React, { useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { useTranslate } from 'react-admin'
import { makeStyles, Divider } from '@material-ui/core'
import { useMutation } from 'react-query'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import inviteUsersModalStyles from './inviteUsersModal.styles'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import getUserByEmail from '../../../Apis/user/getUserByEmail'
import COLORS from '../../../utils/constants/colors'
import MyAutocomplete from '../../../components/form/fields/MyAutocomplete'
import MyChip from '../../../components/MyChip/MyChip'
import useFormValidation from '../../../hooks/useFormValidation'

const InviteUsersModal = (props) => {
  const { emails, setEmails, me, orgUsers, stepForward } = props
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [currentInput, setCurrentInput] = useState('')

  const { email: emailRegex } = useFormValidation()
  const translate = useTranslate()

  const useStyles = makeStyles(inviteUsersModalStyles)
  const classes = useStyles()

  const getEmail = useMutation((data) => getUserByEmail(data))

  const nonAcceptableEmailAdded = (erMessage) => {
    setErrorMessage(erMessage)
    setIsSnackbarOpen(true)
  }

  const onChipDelete = (index) => {
    const newArray = [...emails]
    newArray.splice(index || newArray.length - 1, 1)
    setEmails(newArray)
  }

  const EMAIL_ADDED_TWICE = 'emailAddedTwice'
  const EMAIL_ADDED_SELF = 'emailAddedSelf'
  const EMAIL_ADDED_ALREADY_USER = 'emailAddedAlreadyUser'
  const EMAIL_DELETED = 'emailDeleted'
  const EMAIL_NOT_EMAIL = 'emailNotEmail'
  const EMAIL_ADDED = 'emailAdded'
  const BOTH_EMPTY = 'bothEmpty'
  const VALUE_EMPTY_EMAILS = 'emptyEmails'
  const VALUE_AND_EMAILS = 'valueAndEmails'

  const SUBMIT_RULES = [
    {
      condition: (currentInput, emails) => currentInput === '' && !emails.length,
      action: () => SUBMIT_CASES[BOTH_EMPTY](currentInput, emails),
    },
    {
      condition: (currentInput, emails) => currentInput === '' && emails.length,
      action: () => SUBMIT_CASES[VALUE_EMPTY_EMAILS](currentInput, emails),
    },
    {
      condition: (currentInput) => currentInput,
      action: () => SUBMIT_CASES[VALUE_AND_EMAILS](currentInput, emails),
    },
  ]
  const SUBMIT_CASES = {
    [BOTH_EMPTY]: () => {
      nonAcceptableEmailAdded('ra.errorMessages.noUsersToInviteError')
      setCurrentInput('')
      return
    },
    [VALUE_EMPTY_EMAILS]: () => {
      stepForward()
    },
    [VALUE_AND_EMAILS]: () => {
      const matchingRule = HANDLE_KEY_DOWN_RULES.find((rule) =>
        rule.condition(currentInput, emails, setEmails),
      )
      if (matchingRule) {
        matchingRule.action(currentInput, setEmails, true)
      }
    },
  }
  const TOGGLE_CASES = {
    [EMAIL_ADDED_SELF]: () => {
      nonAcceptableEmailAdded('ra.errorMessages.selfInviteError')
    },
    [EMAIL_ADDED_ALREADY_USER]: () => {
      nonAcceptableEmailAdded('ra.errorMessages.alreadyOrganisationUserError')
    },
    [EMAIL_ADDED_TWICE]: () => {
      nonAcceptableEmailAdded('ra.errorMessages.doubleInviteError')
    },
    [EMAIL_NOT_EMAIL]: () => {
      nonAcceptableEmailAdded('ra.errorMessages.wrongEmailFormat')
    },
    [EMAIL_ADDED]: (newMail, setNewEmails, move) => {
      getEmail.mutate(
        { email: newMail },
        {
          onSuccess: (response) => {
            setNewEmails((prevState) => [
              ...prevState,
              {
                name: response.data.firstName.concat(' ', response.data.lastName),
                email: newMail.trim(),
                existedUser: true,
              },
            ])
            if (move) stepForward()
          },
          onError: () => {
            setNewEmails((prevState) => {
              return [...prevState, { email: newMail, existedUser: false }]
            })
            if (move) stepForward()
          },
        },
      )
      setCurrentInput('')
    },
    [EMAIL_DELETED]: () => onChipDelete(),
  }
  const HANDLE_KEY_DOWN_RULES = [
    {
      condition: (newMail) => {
        return newMail === me.data.email
      },
      action: () => {
        TOGGLE_CASES[EMAIL_ADDED_SELF]()
        setCurrentInput('')
      },
    },
    {
      condition: (newMail) => {
        return orgUsers.some((user) => user.email === newMail)
      },
      action: () => TOGGLE_CASES[EMAIL_ADDED_ALREADY_USER](),
    },
    {
      condition: (newMail, enteredEmails) => {
        if (enteredEmails.length) {
          return enteredEmails.find((user) => user.email === newMail)
        }
      },
      action: () => TOGGLE_CASES[EMAIL_ADDED_TWICE](),
    },
    {
      condition: (newMail) => {
        return !emailRegex.isValidSync(newMail)
      },
      action: () => {
        TOGGLE_CASES[EMAIL_NOT_EMAIL]()
      },
    },
    {
      condition: () => true,
      action: (newMail, setNewEmails, move) => {
        TOGGLE_CASES[EMAIL_ADDED](newMail, setNewEmails, move)
      },
    },
  ]
  const HANDLE_KEY_DOWN_BUTTONS = {
    ['Enter']: (newMail, enteredEmails, setNewEmails) => {
      if (!newMail) {
        return
      }
      const matchingRule = HANDLE_KEY_DOWN_RULES.find((rule) =>
        rule.condition(newMail, enteredEmails, setNewEmails),
      )
      if (matchingRule) {
        return matchingRule.action(newMail, setNewEmails)
      }
    },
    ['Tab']: (newMail, enteredEmails, setNewEmails) => {
      if (!newMail) {
        return
      }
      const matchingRule = HANDLE_KEY_DOWN_RULES.find((rule) =>
        rule.condition(newMail, enteredEmails, setNewEmails),
      )
      if (matchingRule) {
        return matchingRule.action(newMail, setNewEmails)
      }
    },
    [' ']: (newMail, enteredEmails, setNewEmails) => {
      if (!newMail) {
        return
      }
      const matchingRule = HANDLE_KEY_DOWN_RULES.find((rule) =>
        rule.condition(newMail, enteredEmails, setNewEmails),
      )
      if (matchingRule) {
        return matchingRule.action(newMail, setNewEmails)
      }
    },
    ['Backspace']: (enteredEmails) => {
      if (currentInput) return
      TOGGLE_CASES[EMAIL_DELETED](enteredEmails)
    },
  }

  const handleKeyDown = (e, enteredEmails, setEmails) => {
    const newMail = e.target.value.trim()
    if (HANDLE_KEY_DOWN_BUTTONS[e.key]) {
      HANDLE_KEY_DOWN_BUTTONS[e.key](newMail, enteredEmails, setEmails)
    }
  }

  const onSubmit = (currentInput, emails) => {
    const findSubmitRule = SUBMIT_RULES.find((rule) => rule.condition(currentInput, emails))
    if (findSubmitRule) {
      findSubmitRule.action(currentInput, emails)
    }
  }

  // TODO: Formik does not need here cause wwe don't use its functionality. Can be more understandable without formik
  return (
    <>
      <div className={classes.dialogContents}>
        <Formik initialValues={{ email: [''] }} onSubmit={() => onSubmit(currentInput, emails)}>
          {() => (
            <Form>
              <Field
                name='email'
                inputValue={currentInput}
                label={translate('ra.formFields.email')}
                component={MyAutocomplete}
                multiple
                disableClearable
                id='tags-filled'
                options={[]}
                freeSolo
                onInputChange={(e) => {
                  if (e) {
                    setCurrentInput(e.target.value.trim())
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === ' ' || e.key === 'Tab') {
                    e.preventDefault()
                    e.target.blur()
                  }
                  handleKeyDown(e, emails, setEmails)
                }}
                renderTags={() => {
                  return emails.map((email, index) => (
                    <MyChip
                      key={index}
                      style={{
                        marginLeft: '10px',
                        marginBottom: '5px',
                        color: COLORS.white,
                        backgroundColor: email.existedUser ? '#E0726E' : '#F0A3A0',
                      }}
                      color={COLORS.white}
                      label={email.existedUser ? email.name : email.email}
                      variant='outlined'
                      onDelete={() => onChipDelete(index)}
                    />
                  ))
                }}
              />
              <div className={classes.footer}>
                <Divider width='100%' />
                <div className={classes.buttonContainer}>
                  <ButtonWithLoader
                    className={classes.inviteButton}
                    label={translate('ra.buttons.next')}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => {
          setIsSnackbarOpen(false)
        }}
      >
        <Alert variant='filled' severity='error'>
          {translate(errorMessage)}
        </Alert>
      </Snackbar>
    </>
  )
}
export default InviteUsersModal
