import React from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { Box, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const AccordionArrowIcon = ({ color, down }) => {
  const useStyles = makeStyles({
    box: {
      width: '20px',
      height: '20px',
      borderRadius: '100%',
      backgroundColor: color,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      '&&': {
        fontSize: '20',
        fill: 'white',
      },
    },
  })
  const classes = useStyles()

  return (
    <Box className={classes.box}>
      {down ? (
        <KeyboardArrowDownIcon className={classes.icon} />
      ) : (
        <KeyboardArrowRightIcon className={classes.icon} />
      )}
    </Box>
  )
}

AccordionArrowIcon.propTypes = {
  color: PropTypes.string,
  down: PropTypes.bool,
}

export default AccordionArrowIcon
