import { CARD_STYLES } from '../../../utils/constants/CSS/cardStyles'

const teamSettingsPageStyles = {
  card: CARD_STYLES.card,
  cardTitle: CARD_STYLES.cardTitle,
  cardSubtitle: CARD_STYLES.cardSubtitle,
  divider: CARD_STYLES.divider,
  loader: CARD_STYLES.loader,
  addTeamWrapper: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  chipsContainer: {
    marginBottom: '30px',
  },
  chip: {
    marginRight: '10px',
    marginBottom: '10px',
  },
}

export default teamSettingsPageStyles
