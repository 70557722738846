import { differenceInMinutes } from 'date-fns'

const isTimeDifferenceAboveCertainDuration = (d1, d2, duration) => {
  const newD1 = new Date(d1)
  const newD2 = new Date(d2)

  return differenceInMinutes(newD2, newD1) >= duration
}

export default isTimeDifferenceAboveCertainDuration
