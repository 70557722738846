import React, { useState } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { useMutation } from 'react-query'
import profileSettingsCloseAccountStyles from './profileSettingsCloseAccount.styles'
import DeleteConfirmationModal from '../../../components/ui/DeleteConfirmationModal/DeleteConfirmationModal'
import disableUserAccount from '../../../Apis/user/disableUserAccount'
import TextButton from '../../../components/ui/TextButton/TextButton'
import useHistoryPush from '../../../hooks/useHistoryPush'
import ContainerPadding from '../../../components/containerPadding/containerPadding'

const ProfileSettingsCloseAccount = () => {
  const [disableAccountModal, setDisableAccountModal] = useState(false)
  const translate = useTranslate()
  const { redirect } = useHistoryPush()
  const useStyles = makeStyles(profileSettingsCloseAccountStyles)
  const classes = useStyles()
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const disableAccount = useMutation((data) => disableUserAccount(data), {
    onError: (error) => {
      if (error.response && error.response.data) {
        setErrorMessage(
          error.response.data.errorMessage === 'Wrong password'
            ? 'ra.errorMessages.wrong'
            : 'ra.notification.deleteError',
        )
        setIsSnackbarOpen(true)
      } else {
        setErrorMessage('ra.notification.deleteError')
        setIsSnackbarOpen(true)
      }
    },
    onSuccess: () => {
      redirect('/login')
    },
  })

  const onSubmit = (data) => {
    const { name } = data
    disableAccount.mutate({
      password: name,
    })
  }

  return (
    <ContainerPadding padding='3% 1% 3% 1%'>
      <div className={classes.disableWrapper}>
        <Typography className={classes.cardTitle} variant='h6'>
          {translate('ra.text.closeAccount')}
        </Typography>
        <TextButton
          onClick={() => setDisableAccountModal(true)}
          label={translate('ra.buttons.close')}
          className={classes.disableButtonRoot}
        />
      </div>
      <Typography className={classes.subtitle} variant='subtitle1'>
        {translate('ra.text.disableAccountDataLost')}
      </Typography>
      <DeleteConfirmationModal
        isModalOpen={disableAccountModal}
        onClose={() => setDisableAccountModal(false)}
        title={translate('ra.text.disableAccount')}
        subtitle={translate('ra.text.insertPassword')}
        comparisonValue=''
        buttonLabel={translate('ra.buttons.disable')}
        textFieldLabel={translate('ra.formFields.password')}
        onSubmit={onSubmit}
        isPassword
        isLoading={disableAccount.isLoading}
        importantMessage={translate('ra.text.orgOwner')}
      />
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => {
          setIsSnackbarOpen(false)
        }}
      >
        <Alert variant='filled' severity='error'>
          {translate(errorMessage)}
        </Alert>
      </Snackbar>
    </ContainerPadding>
  )
}
export default ProfileSettingsCloseAccount
