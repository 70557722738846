import { defaultTheme } from 'react-admin'
import merge from 'lodash/merge'
import red from '@material-ui/core/colors/red'
import COLORS from './colors'

const metargusTheme = merge({}, defaultTheme, {
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: COLORS.orange,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: COLORS.purple,
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Roboto'].join(','),
    fontWeight: ['100', '300', '500'],
  },
  overrides: {
    MuiTableRow: {
      head: {
        backgroundColor: 'lightgray',
        '& > th ': {
          color: 'black',
          fontWeight: 500,
          paddingTop: '25px',
          paddingBottom: '12px',
        },
      },
    },
    MuiDialogContent: {
      root: {
        '&&&': {
          paddingBottom: 12,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 8,
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'unset!important',
        '&:hover': {
          boxShadow: 'unset!important',
        },
        textTransform: 'initial',
      },
      contained: {
        boxShadow: 'unset!important',
        '&:hover': {
          boxShadow: 'unset!important',
        },
      },
      textSizeSmall: {
        fontSize: '14px',
        fontWeight: 400,
        color: COLORS.lightGrey,
      },
    },
    RaFilterButton: {
      root: {
        paddingRight: '15px',
      },
    },
    RaLayout: {
      appFrame: {
        '&&': {
          marginTop: 0,
        },
      },
      content: {
        '&': {
          position: 'relative',
          padding: 0,
          overflow: 'auto',
          height: 'calc(100dvh - 65px)',
        },
      },
    },
    RaCreate: {
      root: {
        minHeight: '100%',
      },
      main: {
        minHeight: '100%',
        marginTop: 0,
      },
      noActions: {
        '&': {
          marginTop: 0,
        },
      },
    },
    RaEdit: {
      root: {
        minHeight: '100%',
      },
      main: {
        minHeight: '100%',
        marginTop: 0,
      },
      noActions: {
        '&': {
          marginTop: 0,
        },
      },
    },
    RaShow: {
      noActions: {
        '&': {
          marginTop: 0,
        },
      },
    },
    RaMenu: {
      open: {
        width: '255px',
      },
    },
    RaFilterForm: {
      form: {
        minHeight: 0,
        marginTop: '0px',
      },
    },
    RaListToolbar: {
      toolbar: {
        minHeight: '0px!important',
        paddingTop: '6px',
      },
    },
    RaTopToolbar: {
      root: {
        alignItems: 'center',
      },
    },
    // MuiToolbar: {
    //   gutters: {
    //     paddingLeft: '0px!important',
    //     paddingRight: '0px!important',
    //   }
    // },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      },
    },
    MuiInputBase: {
      inputMarginDense: {
        paddingTop: '0px 0 0px',
      },
    },
  },
})

export default metargusTheme
