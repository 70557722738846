import { makeStyles } from "@material-ui/core";
import { VisibilityOff } from "@material-ui/icons";

const MyVisibilityOffIcon = (props) => {
  const useStyles = makeStyles({
    icon: {
      width: "15px",
      height: "15px",
      cursor: "pointer",
    }
  });
  const classes = useStyles();

  return (
    <VisibilityOff {...props} classes={{ root: classes.icon }}/>
  );
};

export default MyVisibilityOffIcon;