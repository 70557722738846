import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import drillBoxFooterStyles from './drillBoxFooter.styles'
import deleteDrill from '../../Apis/drill/deleteDrill'
import QueryKeys from '../../utils/constants/queryKeys'
import { accessDrill } from '../../Apis/drill/accessDrill'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import constructDrillTitle from '../../utils/helpers/constructDrillTitle'
import DeleteModal from '../DeleteModal/DeleteModal'
import updatePlaybook from '../../Apis/playbook/updatePlaybook/updatePlaybook'
import trainingModuleType from '../../types/trainingModuleType'
import TextButton from '../ui/TextButton/TextButton'
import USER_ORG_ROLES from '../../utils/constants/userOrgRoles'
import isUserOrgRolesValid from '../../utils/helpers/isUserOrgRolesValid'
import USER_ORG_MEMBERS_ROLES from '../../utils/constants/userOrgMembersRoles'

const DrillBoxFooter = (props) => {
  const {
    drill,
    toggleAccess,
    isCurrentUserDrillCreator,
    usersDownloaded,
    playbookId,
    isDeleteModalHidden,
  } = props
  const { id, title, originalCreatorFirstName } = drill
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [addToPersonalButtonClicked, setAddToPersonalButtonClicked] = useState(false)
  const [isTrainingModuleAlreadyDownloaded, setIsTrainingModuleAlreadyDownloaded] = useState(false)
  const translate = useTranslate()
  const queryClient = useQueryClient()
  const { OWNER, ADMIN } = USER_ORG_ROLES
  const { TEAM_MANAGER } = USER_ORG_MEMBERS_ROLES
  const useStyles = makeStyles(drillBoxFooterStyles)
  const classes = useStyles()

  useEffect(() => {
    setIsTrainingModuleAlreadyDownloaded(
      usersDownloaded && usersDownloaded?.revisionReferenceId === usersDownloaded?.revisionId,
    )
  }, [usersDownloaded])

  const deleteADrill = useMutation((drillId) => deleteDrill(drillId), {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.GET_DRILLS).then((res) => res),
  })

  const deletePlaybookTrainingModule = useMutation((drillId) => updatePlaybook(drillId), {
    onSuccess: () => {
      setOpenDeleteModal(false)
      queryClient.invalidateQueries(QueryKeys.GET_PLAYBOOKS).then((res) => res)
    },
  })

  const accessADrill = useMutation((drillId) => accessDrill(drillId), {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.GET_DRILLS),
  })

  const handleDelete = (e) => {
    e.stopPropagation()
    if (!playbookId) {
      return deleteADrill.mutate(id)
    }

    return deletePlaybookTrainingModule.mutate({
      playBookId: playbookId,
      values: { trainingModulesForDeletion: [id] },
    })
  }

  const handleRemoveButton = (e) => {
    if (isDeleteModalHidden) {
      return handleDelete(e)
    }

    e.stopPropagation()
    return setOpenDeleteModal(true)
  }

  const onAddToPersonalButtonClicked = (e) => {
    e.stopPropagation()
    if (!addToPersonalButtonClicked) {
      setAddToPersonalButtonClicked(true)
      accessADrill.mutate(id)
    }
  }

  return (
    <div className={classes.rootWrapper}>
      {(playbookId && !isUserOrgRolesValid([OWNER, ADMIN, TEAM_MANAGER])) || (
        <ConditionalRender
          renderValue={
            ((originalCreatorFirstName || !isCurrentUserDrillCreator) &&
              toggleAccess === 'private') ||
            playbookId
          }
        >
          <TextButton
            className={classes.textButton}
            label={translate('ra.buttons.remove')}
            onClick={handleRemoveButton}
          />
        </ConditionalRender>
      )}

      <DeleteModal
        title={constructDrillTitle(translate('ra.text.removeDrill'), title)}
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        handleDelete={(e) => handleDelete(e)}
        loading={deleteADrill.isLoading || deletePlaybookTrainingModule.isLoading}
      />

      <ConditionalRender renderValue={!isCurrentUserDrillCreator && toggleAccess === 'public'}>
        <TextButton
          className={classnames(classes.addPersonalButton, classes.textButton, {
            [classes.addPersonalButtonClicked]: addToPersonalButtonClicked,
          })}
          label={translate('ra.buttons.addToPersonal')}
          onClick={(e) => onAddToPersonalButtonClicked(e)}
          disabled={isTrainingModuleAlreadyDownloaded}
        />
      </ConditionalRender>
    </div>
  )
}

DrillBoxFooter.propTypes = {
  drill: trainingModuleType,
  toggleAccess: PropTypes.string,
  isCurrentUserDrillCreator: PropTypes.bool,
  usersDownloaded: PropTypes.arrayOf(trainingModuleType),
  playbookId: PropTypes.string,
  isDeleteModalHidden: PropTypes.bool,
}

DrillBoxFooter.defaultProps = {
  isDeleteModalHidden: false,
}

export default DrillBoxFooter
