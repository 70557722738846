import axiosInstance from '../axiosInterceptor/axiosInstance'

const getBillingSummary = async (data) => {
  const { orgId } = data
  const url = `/billing/summary?orgId=${orgId}`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getBillingSummary
