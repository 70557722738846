import IMAGE_CROP_DEFAULT_DIMENSIONS from '../../../../utils/constants/imageCropDefaultDimensions'
import COLORS from '../../../../utils/constants/colors'

const imageCropStyles = (width, height, zoom, minZoom, maxZoom) => {
  const newWidth = width ?? IMAGE_CROP_DEFAULT_DIMENSIONS.WIDTH
  const newHeight = height ?? IMAGE_CROP_DEFAULT_DIMENSIONS.HEIGHT

  const zoomPercentage = ((zoom - minZoom) / (maxZoom - minZoom)) * 100
  const zoomBarValue = (newWidth * zoomPercentage) / 100
  const zoomBarHeight = 3

  return {
    zoomBarContainer: {
      width: newWidth,
      height: zoomBarHeight,
      overflow: 'hidden',
      background: COLORS.white,
    },
    zoomBarValue: {
      width: zoomBarValue,
      height: zoomBarHeight,
      float: 'left',
      background: COLORS.purple,
    },
    wrapper: {
      display: 'flex',
      height: newHeight,
    },
    cropWrapper: {
      position: 'relative',
      width: newWidth,
      height: '100%',
    },
    buttonsWrapper: {
      height: '100%',
      backgroundColor: '#F5F5F5',
      padding: '0 7px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 15,
    },
    closeIcon: {
      '&&': {
        fill: COLORS.purple,
        paddingTop: 7,
      },
    },
    greyIcon: {
      '&&': {
        fill: COLORS.grey,
      },
    },
  }
}

export default imageCropStyles
