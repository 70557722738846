import React from 'react'
import { Box, makeStyles, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import EditIcon from '@material-ui/icons/Edit'
import NewAccordionChild from './NewAccordionChild'
import DeleteIcon from '@material-ui/icons/Delete'
import accordionLabelBoxStyles from './accordionLabelBoxStyles'
import { useTranslate } from 'react-admin'

const AccordionLabelBox = (props) => {
  const {
    icon,
    label,
    arrowOnClick,
    onClick,
    style,
    clicked,
    children,
    onDeleteChild,
    onUpdateName,
    isEditable,
    viewOnly,
  } = props
  const useStyles = makeStyles(accordionLabelBoxStyles(clicked))
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Box className={`${classes.box} ${classes.boxHovered}`} onClick={onClick} style={style}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className={classes.icon} onClick={arrowOnClick}>
          {icon}
        </span>
        {!isEditable ? (
          <span className={classes.label}>{label}</span>
        ) : (
          <NewAccordionChild
            label={label}
            onUpdateName={onUpdateName}
            onDeleteChild={onDeleteChild}
            viewOnly={viewOnly}
          >
            <Tooltip title={translate('ra.buttons.edit')}>
              <EditIcon className={classes.editIcon} />
            </Tooltip>
            <Tooltip title={translate('ra.action.delete')}>
              <DeleteIcon
                classes={{ root: classes.deleteIcon }}
                onClick={onDeleteChild}
                fontSize='medium'
              />
            </Tooltip>
            <div className={classes.addIcon}>{children}</div>
          </NewAccordionChild>
        )}
      </div>
      {!isEditable && !viewOnly && <div className={classes.addIcon}>{children}</div>}
      {clicked && <CheckCircleIcon className={classes.doneIcon} />}
    </Box>
  )
}

AccordionLabelBox.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  arrowOnClick: PropTypes.func,
  onClick: PropTypes.func,
  clicked: PropTypes.bool,
  children: PropTypes.node,
  onDeleteChild: PropTypes.func,
  onUpdateName: PropTypes.func,
  isEditable: PropTypes.bool,
  viewOnly: PropTypes.bool,
}

AccordionLabelBox.defaultProps = {
  viewOnly: false,
}

export default AccordionLabelBox
