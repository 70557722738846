import COLORS from '../../../utils/constants/colors'

const loaderDots = {
  loaderIcon: {
    fill: COLORS.orange,
    width: 40,
    height: 40,
  },
}
export default loaderDots
