import getPracticePlan from '../../../../Apis/practice/getPracticePlan'

const practicePlanShowBreadcrumb = async (data) => {
  const { match } = data || {}
  const { practicePlanId } = match.params

  const practicePlanDescription = await getPracticePlan({
    practicePlanId,
  }).then((practicePlan) => practicePlan.description)

  if (practicePlanDescription) {
    return ['PRACTICE', practicePlanDescription.toUpperCase(), 'SHOW']
  }

  return ['PRACTICE', 'PRACTICE PLAN TOPIC']
}

export default practicePlanShowBreadcrumb
