const userNameFieldStyles = {
  userNameFieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginTop: 7,
    marginBottom: 7,
  },
  userNameFieldAvatar: {
    width: '40px',
    height: '40px',
  },
}

export default userNameFieldStyles
