import * as React from 'react'
import { TextInput } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import TEXT_INPUT_STYLES from '../../../utils/constants/CSS/textInputStyles'

const MyTextInput = (props) => {
  const useStyles = makeStyles(TEXT_INPUT_STYLES)
  const classes = useStyles()

  return (
    <TextInput
      {...props}
      fullWidth
      margin='none'
      InputProps={{
        classes: {
          root: classes.root,
          input: classes.input,
        },
        disableUnderline: true,
      }}
      InputLabelProps={{
        classes: {
          error: classes.error,
          shrink: classes.shrink,
          formControl: classes.formControl,
        },
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperTextRoot,
        },
      }}
    />
  )
}

export default MyTextInput
