import COLORS from '../../utils/constants/colors'

const closeDrawerStyles = {
  circle: {
    width: '48px',
    height: '48px',
    position: 'fixed',
    backgroundColor: COLORS.orange,
    borderRadius: '50%',
    transform: 'translateX(-135%)',
    marginTop: '15px',
    cursor: 'pointer',
    boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.2)',
  },
  closeIcon: {
    fontSize: '20px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fill: COLORS.white,
    cursor: 'pointer',
  },
}

export default closeDrawerStyles
