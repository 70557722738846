import COLORS from '../../../utils/constants/colors'

const profileSettingsInfoEmailFormStyles = {
  divFlex: {
    display: 'flex',
    alignItems: 'center',
    width: '49%',
    gap: '10px',
  },
  editPassword: {
    '&&': {
      fill: COLORS.orange,
      marginRight: '5px',
    },
  },
  editPhoneNumber: {
    '&&': {
      fill: COLORS.orange,
    },
  },
}

export default profileSettingsInfoEmailFormStyles
