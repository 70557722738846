import axiosInstance from '../axiosInterceptor/axiosInstance'

const createEvent = (data) => {
  const { formData, eventType } = data
  const newData = {
    ...formData,
    eventTypeId: eventType.id,
  }
  const url = `/events/${data.eventType.code}`

  return axiosInstance.post(url, newData)
}

export default createEvent
