import COLORS from '../colors'

const AUTOCOMPLETE_STYLES = {
  // InputLabelProps
  formControl: {
    '&&': {
      transform: 'translate(12px, 13px) scale(0.90)',
      color: 'rgba(0, 0, 0, 0.40)',
    },
  },
  shrink: {
    '&&': {
      transform: 'translate(12px, -6px) scale(0.75)',
      color: `${COLORS.black}`,
    },
  },
  focused: {
    color: `${COLORS.black}!important`,
  },

  // InputProps
  root: {
    '&&': {
      borderRadius: '6px',
      paddingTop: '10px',
      paddingBottom: '9px',
    },
  },
  input: {
    '&&&': {
      height: '0.7rem',
      minHeight: '0.7rem',
      fontSize: '0.95rem',
      padding: '6px 4px',
    },
    '&:focus': {
      backgroundColor: 'unset',
    },
  },

  // Chips
  chipRoot: {
    backgroundColor: COLORS.orange,
    color: COLORS.white,
    height: 25,
    margin: 1,
    marginRight: 3,
  },
  chipLabel: {
    paddingRight: 9,
  },
  chipDeleteIcon: {
    height: 19,
    fill: COLORS.bisque,
  },
}

export default AUTOCOMPLETE_STYLES
