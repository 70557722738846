import { shape, string, arrayOf } from 'prop-types'
import practicePlanComponentType from './practicePlanComponentType'

const practicePlanType = shape({
  description: string,
  eventId: string,
  id: string,
  practicePlanComponents: arrayOf(practicePlanComponentType),
})

export default practicePlanType
