import { useTranslate } from 'react-admin'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Button, DialogActions, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import DialogWithCloseIcon from '../../../components/DialogWithCloseIcon/DialogWithCloseIcon'
import passOwnershipModalStyles from './passOwnershipModal.styles'
import MyTable from '../../../components/DynamicTable/MyTable/MyTable'
import TypographyField from '../../../components/DynamicTable/Fields/TypographyField/TypographyField'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import ContainerPadding from '../../../components/containerPadding/containerPadding'
import QueryKeys from '../../../utils/constants/queryKeys'
import getOrgUsers from '../../../Apis/organisation/getOrgUsers'
import getUserData from '../../../Apis/user/getUserData'
import LoaderDots from '../../../components/loaders/LoaderDots/LoaderDots'
import isObjectEmptyHelper from '../../../utils/helpers/isObjectEmptyHelper'
import inviteUsers from '../../../Apis/invitations/inviteUsers'
import localStorageIds from '../../../utils/constants/localStorageIds'
import MySimpleTextField from '../../../components/form/fields/MySimpleTextField'
import useFormValidation from '../../../hooks/useFormValidation'
import localStorageHelper from '../../../utils/helpers/localStorageHelper'

const PassOwnershipModal = (props) => {
  const { open, onClose, orgId } = props
  const [isRequestSuccessOpen, setIsRequestSuccessOpen] = useState(false)
  const [orgMembers, setOrgMembers] = useState([])
  const [selectedMember, setSelectedMember] = useState({})
  const [emailInput, setEmailInput] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { email: emailRegex } = useFormValidation()
  const queryClient = useQueryClient()
  const translate = useTranslate()
  const useStyles = makeStyles(passOwnershipModalStyles)
  const classes = useStyles()

  useEffect(() => {
    if (emailInput !== '') setSelectedMember({})
  }, [emailInput])

  const me = useQuery([QueryKeys.GET_USER_BY_EMAIL], () => getUserData())

  const onModalClose = (onCloseArg, setEmailInputArg, setSelectedMemberArg) => {
    onCloseArg()
    setEmailInputArg('')
    setSelectedMemberArg({})
  }

  const orgUsersQuery = useQuery([QueryKeys.GET_ORG_USERS], () => getOrgUsers(orgId), {
    onSuccess: (response) => {
      setOrgMembers(
        response
          .filter((resUser) => !Object.values(resUser.roles).includes('Owner'))
          .map((orgUser) => {
            const isAdmin = Object.values(orgUser.roles).includes('Admin')

            return {
              ...orgUser,
              orgRole: isAdmin ? 'Admin' : 'Member',
              name: orgUser?.firstName ? orgUser?.firstName.concat(' ', orgUser.lastName) : '',
            }
          }),
      )
    },
  })

  const passOwnershipMutation = useMutation(
    [QueryKeys.PASS_ORG_OWNERSHIP],
    (data) => inviteUsers({ data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.GET_ORG_PENDING_INVITATIONS).then(() => {})
        onModalClose(onClose, setEmailInput, setSelectedMember)
        setIsRequestSuccessOpen(true)
      },
      onError: (err) => setErrorMessage(err?.response?.data),
    },
  )

  const handleSendRequest = (senderId, idOrg, tableUserEmail, newEmailInput) => {
    const emailToSend = tableUserEmail || newEmailInput

    const { localStorageValue: selectedOrg } = localStorageHelper(localStorageIds.SELECTED_ORG)

    const ownerInvitation = {
      senderId,
      orgId: idOrg,
      invitations: [{ receiverEmail: emailToSend, isOwner: true, scopes: selectedOrg.scopes }],
    }

    passOwnershipMutation.mutate(ownerInvitation)
  }

  return (
    <>
      <DialogWithCloseIcon
        open={open}
        onClose={() => onModalClose(onClose, setEmailInput, setSelectedMember)}
        dialogTitle={translate('ra.text.passOwnership')}
        dialogWidth='50%'
        dialogHeight='auto'
        overflow='hidden'
      >
        <div className={classes.contentContainer}>
          <Typography className={classes.tableTitle} variant='subtitle2' align='center'>
            {translate('ra.text.orgMembers')}
          </Typography>
          {orgUsersQuery.isLoading ? (
            <LoaderDots style={classes.loaderIcon} />
          ) : (
            <MyTable
              rows={orgMembers}
              selectedItem={selectedMember}
              setUpperSelectState={setSelectedMember}
              tableStyles={{ overflow: 'auto' }}
              hasCheckbox
              checkBoxStyle='circle'
            >
              <TypographyField title='Name' key='name' colorChoice='initial' />
              <TypographyField title='email' key='email' colorChoice='initial' />
              <TypographyField title='Role' key='orgRole' colorChoice='initial' />
            </MyTable>
          )}
        </div>

        <div className={classes.footer}>
          <Grid allowFullScreen xs={12} className={classes.emailContainer}>
            <span>{translate('ra.text.inviteByEmail')}</span>
            <MySimpleTextField
              id='Email'
              label='Email'
              style={{ width: '45%' }}
              variant='outlined'
              onChange={(e) => setEmailInput(e.target.value)}
              disabled={!isObjectEmptyHelper(selectedMember)}
            />
          </Grid>

          <Divider />
          <DialogActions>
            <Button
              variant='text'
              classes={{ root: classes.cancelButton }}
              onClick={() => {
                onModalClose(onClose, setEmailInput, setSelectedMember)
              }}
            >
              {translate('ra.buttons.cancel')}
            </Button>
            <ButtonWithLoader
              label={translate('ra.buttons.request')}
              className={classes.deleteButton}
              onClick={() => handleSendRequest(me.data.id, orgId, selectedMember.email, emailInput)}
              loading={passOwnershipMutation.isLoading}
              disabled={
                isObjectEmptyHelper(selectedMember) &&
                (!emailRegex.isValidSync(emailInput) || emailInput === '')
              }
            />
          </DialogActions>
        </div>
      </DialogWithCloseIcon>
      <DialogWithCloseIcon
        open={isRequestSuccessOpen}
        onClose={() => setIsRequestSuccessOpen(false)}
        maxDialogWidth='40%'
        dialogTitle='Pass Ownership of the org'
      >
        <ContainerPadding className={classes.successModal}>
          <CheckCircleIcon className={classes.successIcon} />
          <Typography>{translate('ra.text.passOwnershipSuccess')}</Typography>
        </ContainerPadding>
      </DialogWithCloseIcon>
      <Snackbar open={passOwnershipMutation.isError} autoHideDuration={6000}>
        <Alert variant='filled' severity='error' elevation={6}>
          <span>{errorMessage}</span>
        </Alert>
      </Snackbar>
    </>
  )
}

export default PassOwnershipModal
