import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import ImageCropWithEdit from '../../../components/ui/ImageCropComponents/ImageCropWithEdit/ImageCropWithEdit'
import CROPPER_SHAPES from '../../../utils/constants/cropperShapes'

const MyEditLogoInput = (props) => {
  const { source, disabled } = props
  const {
    input: { onChange, value },
  } = useField(source)

  return (
    <ImageCropWithEdit
      defaultImageSrc={value}
      setCroppedImage={(croppedImage) => onChange(croppedImage)}
      isDisabled={disabled}
      cropShape={CROPPER_SHAPES.RECT}
      dimensions={{ width: 400, height: 400 }}
      variant='rounded'
    />
  )
}

MyEditLogoInput.propTypes = {
  source: PropTypes.string,
  disabled: PropTypes.bool,
}

export default MyEditLogoInput
