import * as React from 'react'
import { Dialog, DialogContent, List, ListItem, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useTeamCalendarContext } from '../../../context/useTeamCalendarContext'
import SelectEventTypeRenderValue from '../../../components/SelectEventTypeField/SelectEventTypeRenderValue'
import createEventModalStyles from './createEventModal.styles'
import dateHelper from '../../../utils/helpers/dateHelpers/dateHelper'
import setFakeEvent from '../../../utils/helpers/eventHelpers/setFakeEvents'
import useHistoryPush from '../../../hooks/useHistoryPush'

const CreateEventModal = ({ open, handleClose }) => {
  const { setSelectedEventType, eventTypes, setCreateEventDrawerOpen, eventsRaw, setEvents } =
    useTeamCalendarContext()
  const { today, todayPlusOneHour } = dateHelper()
  const { redirect, pathname } = useHistoryPush()
  const useStyles = makeStyles(createEventModalStyles)
  const classes = useStyles()

  const handleEventTypeModalClick = (eventTypeId) => {
    if (!eventTypes || eventTypes?.length === 0) return

    handleClose()
    setCreateEventDrawerOpen(true)
    const selectedEventType = eventTypes.find((type) => type.id === eventTypeId)
    setSelectedEventType(selectedEventType)
    setFakeEvent(setEvents, eventsRaw, selectedEventType, today, todayPlusOneHour)
    redirect(pathname, [{ breadcrumb: 'create' }])
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      classes={{
        paper: classes.dialog,
      }}
      disableScrollLock
    >
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <List classes={{ root: classes.listRoot }}>
          {eventTypes.map((type) => (
            <ListItem
              key={type.id}
              onClick={() => handleEventTypeModalClick(type.id)}
              classes={{
                root: classes.listItemRoot,
              }}
            >
              <SelectEventTypeRenderValue eventType={type} small />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  )
}

CreateEventModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
}

export default CreateEventModal
