import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import Brightness5Icon from '@material-ui/icons/Brightness5'
import Brightness3Icon from '@material-ui/icons/Brightness3'
import { useTranslate } from 'react-admin'
import USER_ORG_MEMBERS_ROLES from '../../../utils/constants/userOrgMembersRoles'
import { USER_SCOPES } from '../../../utils/constants/userScopes'
import USER_ORG_ROLES from '../../../utils/constants/userOrgRoles'
import IsUserRolesAuthorized from '../../../components/IsUserRolesAuthorized/IsUserRolesAuthorized'
import IsUserScopesAuthorized from '../../../components/IsUserScopesAuthorized/IsUserScopesAuthorized'
import LivestreamBannerStopConfirmationModal from '../../../components/LivestreamBannerStopConfirmationModal/LivestreamBannerStopConfirmationModal'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import COLORS from '../../../utils/constants/colors'
import useHistoryPush from '../../../hooks/useHistoryPush'
import stopLivestream from '../../../Apis/livestream/stopLivestream'
import pauseLivestream from '../../../Apis/livestream/pauseLivestream'
import resumeLivestream from '../../../Apis/livestream/resumeLivestream'
import livestreamPlayerToolbarStyles from './livestreamPlayerToolbar.styles'
import QueryKeys from '../../../utils/constants/queryKeys'
import getLivestream from '../../../Apis/livestream/getLivestream'
import BROADCAST_STATES from '../../../utils/constants/broadcastStates'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'

const LivestreamPlayerToolbar = ({ broadcastId, isLightMode, setIsLightMode }) => {
  const [isStopModalOpen, setIsStopModalOpen] = useState(false)
  const { goBack } = useHistoryPush()
  const queryClient = useQueryClient()
  const translate = useTranslate()
  const useStyles = makeStyles(livestreamPlayerToolbarStyles(isLightMode))
  const classes = useStyles()

  const { data: broadcast, isLoading: broadcastIsLoading } = useQuery(
    [QueryKeys.GET_LIVESTREAM],
    () => getLivestream({ broadcastId }),
  )
  const showButtons =
    broadcast?.state === BROADCAST_STATES.running || broadcast?.state === BROADCAST_STATES.paused
  const isLive = broadcast?.state === BROADCAST_STATES.running

  const pauseTheLivestream = useMutation((data) => pauseLivestream(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_LIVESTREAM)
    },
  })

  const resumeTheLivestream = useMutation((data) => resumeLivestream(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_LIVESTREAM)
    },
  })

  const stopTheLivestream = useMutation((data) => stopLivestream(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_LIVESTREAM)
      setIsStopModalOpen(false)
      goBack()
    },
  })

  const handlePauseButton = () => pauseTheLivestream.mutate(broadcastId)
  const handleResumeButton = () => resumeTheLivestream.mutate(broadcastId)

  const handleCloseStopModal = () => setIsStopModalOpen(false)
  const handleStopButton = () => setIsStopModalOpen(true)
  const handleModalStopButton = () => {
    stopTheLivestream.mutate(broadcastId)
  }

  const handleLightButtonClick = () => setIsLightMode((prevState) => !prevState)

  return (
    <Box boxShadow={3}>
      <div className={classes.toolbar}>
        <div className={classes.leftButtons}>
          <ButtonWithLoader
            label={translate('ra.buttons.exit')}
            onClick={goBack}
            style={{
              marginLeft: 20,
              border: `1px solid ${COLORS.orange}`,
              backgroundColor: COLORS.white,
              color: COLORS.orange,
            }}
          />
          {isLightMode ? (
            <Brightness5Icon onClick={handleLightButtonClick} />
          ) : (
            <Brightness3Icon
              onClick={handleLightButtonClick}
              style={{ fill: COLORS.white, color: COLORS.white }}
            />
          )}
        </div>
        <IsUserScopesAuthorized acceptedScopes={[USER_SCOPES.LIVESTREAM]}>
          <IsUserRolesAuthorized
            acceptedRoles={[
              USER_ORG_ROLES.OWNER,
              USER_ORG_ROLES.ADMIN,
              USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
            ]}
          >
            <ConditionalRender renderValue={showButtons}>
              <div className={classes.rightButtons}>
                {isLive ? (
                  <ButtonWithLoader
                    label={translate('ra.buttons.pause')}
                    onClick={handlePauseButton}
                    disabled={broadcastIsLoading || stopTheLivestream.isLoading}
                    loading={pauseTheLivestream.isLoading}
                  />
                ) : (
                  <ButtonWithLoader
                    label={translate('ra.buttons.play')}
                    onClick={handleResumeButton}
                    disabled={broadcastIsLoading || stopTheLivestream.isLoading}
                    loading={resumeTheLivestream.isLoading}
                  />
                )}
                <ButtonWithLoader
                  label={translate('ra.buttons.stop')}
                  onClick={handleStopButton}
                  disabled={broadcastIsLoading}
                  loading={stopTheLivestream.isLoading}
                />
              </div>

              <LivestreamBannerStopConfirmationModal
                isStopModalOpen={isStopModalOpen}
                handleCloseStopModal={handleCloseStopModal}
                handleModalStopButton={handleModalStopButton}
              />
            </ConditionalRender>
          </IsUserRolesAuthorized>
        </IsUserScopesAuthorized>
      </div>
    </Box>
  )
}
LivestreamPlayerToolbar.propTypes = {
  broadcastId: PropTypes.string,
  isLightMode: PropTypes.bool,
  setIsLightMode: PropTypes.func,
}

export default LivestreamPlayerToolbar
