import React, { useState } from 'react'
import { Container, makeStyles, TextField } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import designStyles from './design.styles'
import EditCircleIcon from '../../assets/icons/editCircleIcon'
import BinCircleIcon from '../../assets/icons/binCircleIcon'
import designType from '../../types/designType'
import DrillSliderDownloadImage from '../DrillSliderDownloadImage/DrillSliderDownloadImage'
import ConditionalRender from '../ConditionalRender/ConditionalRender'

const Design = (props) => {
  const {
    design,
    designs,
    index,
    designNumber,
    deleteDesign,
    editDesign,
    noIcons,
    type,
    show,
    setFieldValue,
  } = props
  const [description, setDescription] = useState(design?.description)
  const translate = useTranslate()
  const useStyles = makeStyles(designStyles(type))
  const classes = useStyles()
  const isZoomedIn = design?.source ? JSON.parse(design.source)?.isZoomedIn : null

  const onTextFieldChange = (e) => {
    const updatedDesign = { ...designs[index], description: e.target.value, edited: true }
    const updatedDesigns = [...designs]
    setDescription(e.target.value)
    updatedDesigns[index] = updatedDesign

    setFieldValue('designs', updatedDesigns)
  }

  return (
    <div className={classes.wrapper}>
      <span className={classes.designNumber}>{designNumber}</span>
      <div style={{ position: 'relative' }}>
        <img
          src={design.preview}
          alt='Preview'
          className={classnames(classes.image, {
            [classes.halfCourtMargin]: isZoomedIn !== null ? isZoomedIn : design.isZoomedIn,
          })}
        />
        <Container className={classes.iconContainer}>
          <DrillSliderDownloadImage design={design} show />
          <ConditionalRender renderValue={!noIcons}>
            <BinCircleIcon onClick={deleteDesign} />
            <EditCircleIcon onClick={editDesign} />
          </ConditionalRender>
        </Container>
      </div>
      {!show ? (
        <TextField
          label=''
          value={description}
          name='description'
          type='input'
          variant='filled'
          placeholder={translate('ra.text.addANote')}
          fullWidth
          multiline
          minRows={3}
          maxRows={5}
          onChange={onTextFieldChange}
          classes={{
            root: classnames(classes.formControlRoot, {
              [classes.wholeCourtMargin]: isZoomedIn !== null ? !isZoomedIn : !design.isZoomedIn,
            }),
          }}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: classes.inputBaseRoot,
              input: classes.input,
            },
          }}
        />
      ) : (
        <span className={classes.showDescription}>{description}</span>
      )}
    </div>
  )
}

Design.propTypes = {
  design: designType,
  designs: PropTypes.arrayOf(designType),
  index: PropTypes.number,
  designNumber: PropTypes.string,
  deleteDesign: PropTypes.func,
  editDesign: PropTypes.func,
  noIcons: PropTypes.bool,
  type: PropTypes.string,
  show: PropTypes.bool,
  setFieldValue: PropTypes.func,
}

export default Design
