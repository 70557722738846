import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useField, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { KeyboardTimePicker } from '@material-ui/pickers'
import DATE_TIME_FIELD_STYLES from '../../../utils/constants/CSS/dateTimefieldStyles'
import ClockIcon from '../../../assets/icons/clockIcon'

const MyTimeField = (props) => {
  const { name, required, onChange } = props
  const { values, setValues } = useFormikContext()
  const [field, meta, helpers] = useField(name)
  const { value } = field
  const { error } = meta
  const { setValue } = helpers
  const useStyles = makeStyles(DATE_TIME_FIELD_STYLES)
  const classes = useStyles()

  const isError = error || (!value && required)

  return (
    <KeyboardTimePicker
      {...props}
      variant='inline'
      inputVariant='filled'
      value={value}
      error={isError}
      helperText={error}
      ampm={false}
      onChange={(v) => (onChange ? onChange(values, v, setValues) : setValue(v))}
      keyboardIcon={<ClockIcon fontSize='small' />}
      fullWidth
      KeyboardButtonProps={{
        classes: {
          root: classes.icon,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          input: classes.input,
        },
        disableUnderline: true,
      }}
      InputLabelProps={{
        classes: {
          formControl: classes.formControl,
          shrink: classes.shrink,
          focused: classes.focused,
        },
      }}
    />
  )
}

MyTimeField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
}

export default MyTimeField
