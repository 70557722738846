import { shape, string, arrayOf } from 'prop-types'

const eventVariantType = shape({
  code: string,
  color: string,
  entityName: string,
  id: string,
  name: string,
  scopes: arrayOf(string),
})

export default eventVariantType
