import COLORS from '../../../utils/constants/colors'

const userAvatarStyles = (open) => ({
  logoImage: {
    width: 38,
    height: 38,
    paddingLeft: '12px',
    paddingRight: '12px',
    borderRadius: '50%',
    cursor: !open ? 'pointer' : 'default',
  },
  accountIcon: {
    paddingLeft: '11px',
    paddingRight: '12px',
    marginTop: '3px',
    fontSize: '40px',
    cursor: !open ? 'pointer' : 'default',
    fill: COLORS.menuChoiceGrey,
  },
})

export default userAvatarStyles
