import { arrayOf, shape, string } from 'prop-types'

const userType = shape({
  orgIds: arrayOf(string),
  dateOfBirth: string,
  email: string,
  firstName: string,
  id: string,
  lastLogin: string,
  lastName: string,
  logoUrl: string,
  roleIds: arrayOf(string),
})

export default userType
