import * as React from 'react'
import PropTypes from 'prop-types'
import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SelectEventTypeRenderValue from './SelectEventTypeRenderValue'
import eventVariantType from '../../types/events/eventVariantType'
import selectEventTypeFieldStyles from './selectEventTypeFieldStyles'

const SelectEventTypeField = (props) => {
  const { disabled, eventTypes, selectedEventType, setSelectedEventType } = props
  const useStyles = makeStyles(selectEventTypeFieldStyles)
  const classes = useStyles()

  return (
    <FormControl sx={{ minWidth: 140 }}>
      <Select
        onChange={(value) => setSelectedEventType(value.target.value)}
        variant='standard'
        disableUnderline
        disabled={!!disabled}
        value={selectedEventType}
        classes={{
          select: classes.select,
          disabled: disabled && classes.disabledBlack,
          icon: classes.icon,
        }}
        renderValue={(value) => <SelectEventTypeRenderValue eventType={value} />}
        IconComponent={disabled ? () => null : ExpandMoreIcon}
      >
        {eventTypes &&
          eventTypes.map((type) => (
            <MenuItem key={type.id} value={type}>
              <SelectEventTypeRenderValue eventType={type} small />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

SelectEventTypeField.propTypes = {
  eventTypes: PropTypes.arrayOf(eventVariantType),
  selectedEventType: eventVariantType,
  setSelectedEventType: PropTypes.func,
  disabled: PropTypes.bool,
}

export default SelectEventTypeField
