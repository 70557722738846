const statsHeaders = {
  gamesPlayed: 'GP',
  gamesStarted: 'GS',
  minutesPlayed: 'MIN',
  points: 'PTS',
  twoPointer: '2P',
  threePointer: '3P',
  freeThrows: 'FT',
  offensiveRebounds: 'OR',
  defensiveRebounds: 'DR',
  totalRebounds: 'TR',
  assists: 'AST',
  steals: 'STL',
  turnOvers: 'TO',
  blocksAgainst: 'BLKA',
  foulsCommited: 'FC',
  foulsDrawn: 'FD',
  pir: 'PIR',
}

export default statsHeaders
