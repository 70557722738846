const addClubTeamFormStyles = (theme) => {
  const SMALL_DEVICE_BREAKPOINT = 1250

  return {
    orgAvatarText: {
      color: 'grey',
      marginTop: '15px',
      marginRight: 'auto',
      marginBottom: '15px',
    },
    orgFieldWrapper: {
      [theme.breakpoints.down(SMALL_DEVICE_BREAKPOINT)]: {
        flexGrow: 0,
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
    imageCropWrapper: {
      alignItems: 'flex-end',
      [theme.breakpoints.down(SMALL_DEVICE_BREAKPOINT)]: {
        alignItems: 'flex-start',
        marginLeft: 8,
        flexGrow: 0,
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
    divider: {
      margin: '30px 0 22px 0',
      width: '97%',
    },
    addButton: {
      marginTop: 15,
      marginBottom: 15,
      marginRight: 'calc(2% + 8px)',
    },
  }
}

export default addClubTeamFormStyles
