import { useEffect, React, useState } from 'react'
import { Typography, Divider, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-admin'
import getPlayer from '../../Apis/player/getPlayer'
import ContainerPadding from '../containerPadding/containerPadding'
import PracticeTeamsShow from './PracticeTeamsShow'
import SelectedPlayer from '../PracticePlayersSelectedPlayer/SelectedPlayer'
import ConditionalRender from '../ConditionalRender/ConditionalRender'

const PracticePlayersComponentsTable = ({ practicePlayers }) => {
  const translate = useTranslate()
  const [players, setPlayers] = useState()
  const [teamsOfThree, setTeamsOfThree] = useState([])
  const [teamsOfFour, setTeamsOfFour] = useState([])
  const [teamsOfFive, setTeamsOfFive] = useState([])

  const getPlayerData = async (playerId) => {
    return getPlayer({ playerId })
  }
  // TODO endpoint to load all players
  useEffect(() => {
    const fetchData = async () => {
      const dataPromises = practicePlayers.players.map((playerId) => getPlayerData(playerId))
      const allPlayerData = await Promise.all(dataPromises)
      setPlayers(allPlayerData)
    }

    const categorizeTeams = () => {
      const newTeamsOfThree = []
      const newTeamsOfFour = []
      const newTeamsOfFive = []

      practicePlayers.practiceTeams.forEach((team) => {
        if (team.numberOfPlayers === 3) {
          newTeamsOfThree.push(team)
        } else if (team.numberOfPlayers === 4) {
          newTeamsOfFour.push(team)
        } else if (team.numberOfPlayers === 5) {
          newTeamsOfFive.push(team)
        }
      })

      setTeamsOfThree(newTeamsOfThree)
      setTeamsOfFour(newTeamsOfFour)
      setTeamsOfFive(newTeamsOfFive)
    }
    fetchData()

    categorizeTeams()
  }, [practicePlayers])

  return (
    <ContainerPadding>
      <Typography style={{ marginBottom: '10px', fontSize: 'medium' }}>
        {translate('ra.text.playersInPractice')}
      </Typography>
      <Grid container spacing={1}>
        {players?.map((player) => (
          <Grid item xs={4} md={3} lg={2} key={player.id}>
            <SelectedPlayer player={player} />
          </Grid>
        ))}
      </Grid>

      <ConditionalRender renderValue={teamsOfThree?.length > 0}>
        <Divider
          variant='middle'
          style={{
            margin: '15px 0 15px 0 ',
          }}
        />
        <Typography style={{ marginTop: '10px', fontSize: 'medium' }}>
          {translate('ra.text.teamsOfThree')}
        </Typography>
      </ConditionalRender>
      <Grid container spacing={1}>
        {teamsOfThree.length > 0 &&
          teamsOfThree.map((team, teamIndex) => (
            <PracticeTeamsShow team={team} teamIndex={teamIndex} />
          ))}
      </Grid>

      <ConditionalRender renderValue={teamsOfFour?.length > 0}>
        <Divider
          variant='middle'
          style={{
            margin: '15px 0 15px 0 ',
          }}
        />
        <Typography style={{ marginTop: '10px', fontSize: 'medium' }}>
          {translate('ra.text.teamsOfFour')}
        </Typography>
      </ConditionalRender>
      <Grid container spacing={1}>
        {teamsOfFour.length > 0 &&
          teamsOfFour.map((team, teamIndex) => (
            <PracticeTeamsShow team={team} teamIndex={teamIndex} />
          ))}
      </Grid>

      <ConditionalRender renderValue={teamsOfFive?.length > 0}>
        <Divider
          variant='middle'
          style={{
            margin: '15px 0 15px 0 ',
          }}
        />
        <Typography style={{ marginTop: '10px', fontSize: 'medium' }}>
          {translate('ra.text.teamsOfFive')}
        </Typography>
      </ConditionalRender>
      <Grid container spacing={1}>
        {teamsOfFive.length > 0 &&
          teamsOfFive.map((team, teamIndex) => (
            <PracticeTeamsShow team={team} teamIndex={teamIndex} />
          ))}
      </Grid>
    </ContainerPadding>
  )
}

PracticePlayersComponentsTable.propTypes = {
  practicePlayers: PropTypes.shape({
    players: PropTypes.arrayOf(PropTypes.string.isRequired),
    practiceTeams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        numberOfPlayers: PropTypes.number.isRequired,
        teamPlayers: PropTypes.string,
      }),
    ),
  }).isRequired,
}

export default PracticePlayersComponentsTable
