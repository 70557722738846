import COLORS from '../../utils/constants/colors'

const tacticsAccordionStyles = {
  wrapper: {
    padding: '6%',
    paddingTop: '3%',
  },
  accordionWrapper: {
    marginTop: '20px',
  },
  loaderIcon: {
    fill: COLORS.orange,
    width: 40,
    height: 40,
  },
}

export default tacticsAccordionStyles
