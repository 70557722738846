import * as React from 'react'
import { Chip, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { addOpacityToColor } from '../../utils/helpers/addOpacityToColor'

const MyChip = (props) => {
  const { label, style, onDelete, color, small } = props
  const useStyles = makeStyles({
    chipRoot: {
      backgroundColor: color ? addOpacityToColor(color, 0.2) : '',
      color: color ?? '',
      height: small ? '19px' : '32px',
      '&&:focus': {
        backgroundColor: color ? addOpacityToColor(color, 0.2) : '',
      },
    },
    chipDeleteIcon: {
      color: color ?? '',
      width: small ? '15px' : '19px',
      height: small ? '15px' : '19px',
      margin: '0 5px 0 -8px',
      '&&:hover': {
        color: color ?? '',
      },
    },
  })
  const classes = useStyles()

  return (
    <Chip
      label={label}
      onDelete={onDelete}
      style={style}
      classes={{
        root: classes.chipRoot,
        deleteIcon: classes.chipDeleteIcon,
      }}
    />
  )
}

MyChip.propTypes = {
  label: PropTypes.string,
  onDelete: PropTypes.func,
  color: PropTypes.string,
  small: PropTypes.bool,
}

export default MyChip
