import React from 'react'
import { useTranslate } from 'react-admin'
import { DialogActions } from '@material-ui/core'
import PropTypes from 'prop-types'
import TextButton from '../../../components/ui/TextButton/TextButton'
import COLORS from '../../../utils/constants/colors'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import DialogWithCloseIcon from '../../../components/DialogWithCloseIcon/DialogWithCloseIcon'

const DeactivateSubscriptionConfirmationModal = (props) => {
  const { isOpen, onClose, handleDeactivateAddon } = props
  const translate = useTranslate()

  return (
    <div>
      <DialogWithCloseIcon
        open={isOpen}
        onClose={onClose}
        dialogWidth='30%'
        dialogTitle={translate('ra.text.deactivateAddonConfirmation')}
      >
        <DialogActions>
          <TextButton label={translate('ra.buttons.no')} onClick={onClose} />
          <ButtonWithLoader
            label={translate('ra.buttons.deactivate')}
            style={{ backgroundColor: COLORS.red }}
            onClick={handleDeactivateAddon}
            type='button'
          />
        </DialogActions>
      </DialogWithCloseIcon>
    </div>
  )
}

DeactivateSubscriptionConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  handleDeactivateAddon: PropTypes.func,
}

export default DeactivateSubscriptionConfirmationModal
