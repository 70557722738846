import { useCallback, useEffect, useState } from 'react'

const useResize = (myRef) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  // Parameter myRef can be ref or html element.
  const handleResize = useCallback(() => {
    if (myRef instanceof HTMLElement) {
      const { innerWidth, innerHeight } = window;
      setWidth(innerWidth)
      setHeight(innerHeight)
    }

    if (myRef?.current) {
      const { offsetWidth, offsetHeight } = myRef.current
      setWidth(offsetWidth)
      setHeight(offsetHeight)
    }
  }, [myRef?.current])

  useEffect(() => {
    window.addEventListener('load', handleResize)
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('load', handleResize)
      window.removeEventListener('resize', handleResize)
    }
  }, [myRef?.current, handleResize])

  return { width, height }
}

export default useResize
