import COLORS from '../../../utils/constants/colors'

const updateButtonStyles = {
  root: {
    '&&': {
      backgroundColor: COLORS.orange,
      color: COLORS.white,
      '&:disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)!important',
        color: 'rgba(0, 0, 0, 0.26)!important',
      },
      '&:hover': {
        backgroundColor: COLORS.orange,
      },
    },
  },
}

export default updateButtonStyles
