import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { Box, Card, Collapse, Divider, makeStyles, Typography, Button } from '@material-ui/core'
import { useRedirect, useTranslate } from 'react-admin'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import PropTypes from 'prop-types'
import decodeJwt from 'jwt-decode'
import TextButton from '../../../ui/TextButton/TextButton'
import MyTextField from '../../fields/MyTextField'
import subscriptionFormStyles from './subscriptionForm.styles'
import ButtonWithLoader from '../../../ui/ButtonWithLoader/ButtonWithLoader'
import ContainerPadding from '../../../containerPadding/containerPadding'
import COLORS from '../../../../utils/constants/colors'
import MyBooleanField from '../../fields/MyBooleanField'
import QueryKeys from '../../../../utils/constants/queryKeys'
import getSubscriptionDetails from '../../../../Apis/subscriptions/getSubscriptionDetails'
import useUrlSearchParams from '../../../../hooks/useUrlSearchParams'
import LoaderDots from '../../../loaders/LoaderDots/LoaderDots'
import orgIsInSubscriptionState from '../../../../utils/helpers/orgIsInSubscriptionState'
import SUBSCRIPTION_STATES from '../../../../utils/constants/subscriptionStates'
import cancelSubscription from '../../../../Apis/subscriptions/cancelSubscription'
import activateCoupon from '../../../../Apis/subscriptions/activateCoupon'
import patchSubscriptionDetails from '../../../../Apis/subscriptions/patchSubscriptionDetails'
import LoaderCircle from '../../../loaders/LoaderCircle/LoaderCircle'
import changeCard from '../../../../Apis/subscriptions/changeCard'
import ConditionalRender from '../../../ConditionalRender/ConditionalRender'
import localStorageIds from '../../../../utils/constants/localStorageIds'
import { setLocalStorageValue } from '../../../../utils/helpers/localStorageHelperMethods'
import CancelSubscriptionDialogConfirmation from './CancelSubscriptionDialogConfirmation/CancelSubscriptionDialogConfirmation'
import isObjectEmptyHelper from '../../../../utils/helpers/isObjectEmptyHelper'
import hasNullRequiredValues from '../../../../utils/constants/hasNullRequiredValues'

const SubscriptionForm = ({ setPaymentSuccess, showCard, setTab, handleActivateSubscription }) => {
  const { org: orgId } = useUrlSearchParams(['org']) || {}
  const [isOnEditMode, setIsOnEditMode] = useState()
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [couponMessage, setCouponMessage] = useState({ message: '', isError: false })
  const externalRedirect = useRedirect()
  const translate = useTranslate()
  const queryClient = useQueryClient()
  const useStyles = makeStyles(subscriptionFormStyles)
  const classes = useStyles()

  const handleCloseCancelConfirmationModal = () => setIsCancelModalOpen(false)
  const handleOpenCancelConfirmationModal = () => setIsCancelModalOpen(true)

  const { data: subscriptionDetails, isLoading } = useQuery(
    [QueryKeys.GET_SUBSCRIPTION_DETAILS],
    () => getSubscriptionDetails({ orgId }),
    {
      enabled: Boolean(orgId),
    },
  )

  useEffect(() => {
    if (hasNullRequiredValues(subscriptionDetails)) {
      setIsOnEditMode(true)
      return
    }
    setIsOnEditMode(false)
  }, [subscriptionDetails])

  const {
    firstName,
    lastName,
    street,
    streetNumber,
    city,
    zipCode,
    country,
    companyName,
    vatNumber,
    activity,
    needsInvoice,
    cardFourLastDigits,
    coupon,
  } = subscriptionDetails || {}

  const cancelSubscriptionMutation = useMutation(() => cancelSubscription({ orgId }), {
    onSuccess: (res) => {
      const { jwtToken } = res?.data || {}
      const { Subscriptions } = decodeJwt(jwtToken)

      setLocalStorageValue([
        { id: localStorageIds.TOKEN, value: jwtToken },
        {
          id: localStorageIds.SUBSCRIPTIONS,
          value: Subscriptions,
        },
      ])
      queryClient.invalidateQueries(QueryKeys.GET_ACCOUNT_ME)
      queryClient.invalidateQueries(QueryKeys.GET_SUBSCRIPTION_DETAILS)
      setTab(0)
      handleCloseCancelConfirmationModal()
    },
  })

  const activateCouponMutation = useMutation(
    (data) => activateCoupon({ couponCode: data.couponCode, orgId }),
    {
      onSuccess: (res) => {
        const { id } = res?.data || {}
        if (id) {
          queryClient.invalidateQueries(QueryKeys.GET_ACCOUNT_ME).then(() => {
            setPaymentSuccess(undefined)
            setCouponMessage({
              message: `Coupon activated successfully! Your trial has been extended to ${res.data.durationInMonths} month${res.data.durationInMonths > 1 && 's'}.`,
              isError: false,
            })
          })
          queryClient.invalidateQueries(QueryKeys.GET_SUBSCRIPTION_DETAILS)
          return
        }
      },
      onError: (res) => {
        const message = res?.response?.data?.details || 'Coupon activation failed.'
        setPaymentSuccess(undefined)
        setCouponMessage({ message: message, isError: true })
      },
    },
  )

  const saveSubscriptionDetailsMutation = useMutation(
    (data) => patchSubscriptionDetails({ formData: data, orgId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.GET_SUBSCRIPTION_DETAILS)
      },
    },
  )

  const changeCardMutation = useMutation(() => changeCard({ orgId }), {
    onSuccess: (res) => {
      const { vivaPaymentOrderCode } = res?.data || {}
      externalRedirect(`${process.env.REACT_APP_VIVA_URL}${vivaPaymentOrderCode}`)
    },
  })

  const setInvoiceFieldsOnSubmit = (formValues) => {
    return formValues?.needsInvoice
      ? formValues
      : { ...formValues, companyName: '', vatNumber: '', activity: '' }
  }

  const onSaveDetailsClick = (validateForm, setTouched, values, dirty) => {
    if (!dirty) return setIsOnEditMode(false)
    validateForm().then((res) => {
      if (!isObjectEmptyHelper(res))
        return setTouched({
          firstName: true,
          lastName: true,
          street: true,
          streetNumber: true,
          city: true,
          zipCode: true,
          country: true,
          companyName: true,
          vatNumber: true,
          activity: true,
        })
      return saveSubscriptionDetailsMutation.mutate(setInvoiceFieldsOnSubmit(values))
    })
  }

  const onSubmitCoupon = (values) => activateCouponMutation.mutate(values)

  const validate = (values) => {
    const {
      firstName,
      lastName,
      street,
      streetNumber,
      city,
      zipCode,
      country,
      needsInvoice,
      companyName,
      vatNumber,
      activity,
    } = values
    const errors = {}

    if (!firstName || !lastName || !street || !streetNumber || !city || !zipCode || !country) {
      errors.invoice = 'Required'
    }

    if (needsInvoice && (!companyName || !vatNumber || !activity)) {
      errors.invoice = 'Required'
    }

    return errors
  }

  if (isLoading) {
    return (
      <ContainerPadding paddingValue='45%'>
        <LoaderDots
          style={{
            fill: COLORS.orange,
            width: 70,
            height: 70,
          }}
        />
      </ContainerPadding>
    )
  }

  return (
    <div className={classes.container}>
      <Formik
        initialValues={{
          couponCode: coupon?.code || '',
        }}
        onSubmit={onSubmitCoupon}
      >
        {({ values }) => (
          <Form style={{ marginBottom: '40px' }}>
            <ConditionalRender renderValue={Boolean(!subscriptionDetails.coupon)}>
              {showCard && (
                <div className={classes.halfLineFieldContainer} style={{ marginBottom: '20px' }}>
                  <MyTextField
                    name='couponCode'
                    label='Coupon'
                    style={{ flex: 1 }}
                    inputPropStyles={{ height: '40px' }}
                  />

                  <Box container boxShadow={3}>
                    <ButtonWithLoader
                      style={{
                        backgroundColor: values.couponCode.length <= 3 ? 'primary' : COLORS.blue,
                        flex: 1,
                        height: '40px',
                      }}
                      disabled={values.couponCode.length <= 3}
                      label={translate('ra.text.activateCoupon')}
                    />
                  </Box>
                </div>
              )}
            </ConditionalRender>

            {couponMessage.message && (
              <Typography
                style={{
                  color: couponMessage.isError ? COLORS.red : COLORS.green,
                  marginTop: '10px',
                  textAlign: 'center',
                }}
              >
                {couponMessage.message}
              </Typography>
            )}
            <ConditionalRender
              renderValue={Boolean(
                subscriptionDetails.coupon && !couponMessage.message && showCard,
              )}
            >
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Typography variant='subtitle1' style={{ fontWeight: 'bold', extAlign: 'center' }}>
                  {'You have an activated coupon!'}
                </Typography>
              </div>
            </ConditionalRender>
          </Form>
        )}
      </Formik>
      <Formik
        enableReinitialize
        initialValues={{
          firstName: firstName || '',
          lastName: lastName || '',
          street: street || '',
          streetNumber: streetNumber || '',
          city: city || '',
          zipCode: zipCode || '',
          country: country || '',
          needsInvoice: !!needsInvoice || false,
          companyName: companyName || '',
          vatNumber: vatNumber || '',
          activity: activity || '',
          card: cardFourLastDigits ? `ΧΧΧΧ ΧΧΧΧ ΧΧΧΧ ${cardFourLastDigits}` : '',
          couponCode: coupon?.code || '',
        }}
        validate={validate}
      >
        {({ setTouched, validateForm, values, dirty }) => (
          <Form>
            <div className={classes.formHeader}>
              <Typography>{translate('ra.text.billingDetails')}</Typography>
              <LoaderCircle
                loading={saveSubscriptionDetailsMutation.isLoading}
                color={COLORS.blue}
              />
              <ConditionalRender renderValue={!saveSubscriptionDetailsMutation.isLoading}>
                {!isOnEditMode ? (
                  <TextButton
                    label='EDIT'
                    className={classes.textButtons}
                    onClick={() => setIsOnEditMode(true)}
                  />
                ) : (
                  isOnEditMode && (
                    <TextButton
                      label='SAVE'
                      className={classes.textButtons}
                      onClick={() => onSaveDetailsClick(validateForm, setTouched, values, dirty)}
                    />
                  )
                )}
              </ConditionalRender>
            </div>
            <div>
              <div className={classes.halfLineFieldContainer}>
                <MyTextField
                  name='firstName'
                  label='First Name'
                  disabled={!isOnEditMode}
                  inputPropStyles={{ height: '40px' }}
                  required
                />
                <MyTextField
                  name='lastName'
                  label='Last name'
                  disabled={!isOnEditMode}
                  inputPropStyles={{ height: '40px' }}
                  required
                />
              </div>
              <div className={classes.halfLineFieldContainer}>
                <MyTextField
                  name='street'
                  label='Street'
                  style={{ flex: 2 }}
                  disabled={!isOnEditMode}
                  inputPropStyles={{ height: '40px' }}
                  required
                />
                <MyTextField
                  name='streetNumber'
                  label='Number'
                  style={{ flex: 1 }}
                  disabled={!isOnEditMode}
                  inputPropStyles={{ height: '40px' }}
                  required
                />
              </div>
              <div className={classes.halfLineFieldContainer}>
                <MyTextField
                  name='city'
                  label='City'
                  style={{ flex: 2 }}
                  disabled={!isOnEditMode}
                  required
                />
                <MyTextField
                  name='zipCode'
                  label='ZipCode'
                  style={{ flex: 1 }}
                  disabled={!isOnEditMode}
                  inputPropStyles={{ height: '40px' }}
                  required
                />
              </div>
              <MyTextField
                name='country'
                label='Country'
                className={classes.oneLineFields}
                disabled={!isOnEditMode}
                inputPropStyles={{ height: '40px' }}
                required
              />

              <div className={classes.invoiceCheck}>
                <Typography>{translate('ra.text.needInvoice')}</Typography>
                <MyBooleanField
                  name='needsInvoice'
                  style={{
                    color: isOnEditMode ? COLORS.blue : COLORS.lightGrey,
                    transform: 'scale(1.2)',
                    marginLeft: '10px',
                  }}
                  disabled={!isOnEditMode}
                />
              </div>
              <Collapse in={values.needsInvoice} timeout='auto' unmountOnExit>
                <MyTextField
                  name='companyName'
                  label='Company Name'
                  className={classes.oneLineFields}
                  disabled={!isOnEditMode}
                  required={values.needsInvoice}
                  inputPropStyles={{ height: '40px' }}
                />
                <MyTextField
                  name='vatNumber'
                  label='VAT Number'
                  className={classes.oneLineFields}
                  disabled={!isOnEditMode}
                  required={values.needsInvoice}
                  inputPropStyles={{ height: '40px' }}
                />
                <MyTextField
                  name='activity'
                  label='Activity/Industry'
                  className={classes.oneLineFields}
                  disabled={!isOnEditMode}
                  required={values.needsInvoice}
                  inputPropStyles={{ height: '40px' }}
                />
              </Collapse>
              {!orgIsInSubscriptionState([SUBSCRIPTION_STATES.TRIAL]) &&
                !!cardFourLastDigits &&
                showCard && (
                  <div className={classes.cardContainer}>
                    <Card variant='outlined' elevation={0} className={classes.card}>
                      <ContainerPadding paddingValue='1%'>
                        <Typography
                          className={classes.typographyBold}
                          style={{ marginLeft: '10px' }}
                        >
                          Card
                        </Typography>
                        <MyTextField
                          name='card'
                          className={classes.cardField}
                          variant='standard'
                          disabled
                          inputPropStyles={{ height: '40px' }}
                        />
                      </ContainerPadding>
                    </Card>

                    <TextButton
                      className={classes.textButtons}
                      label={translate('ra.buttons.changeCard')}
                      onClick={changeCardMutation.mutate}
                    />
                  </div>
                )}
            </div>
            {(orgIsInSubscriptionState([SUBSCRIPTION_STATES.INACTIVE]) ||
              orgIsInSubscriptionState([SUBSCRIPTION_STATES.TRIAL])) &&
              showCard && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      handleActivateSubscription()
                    }}
                    style={{
                      marginBottom: '0px',
                      width: '22%',
                      backgroundColor: !subscriptionDetails.firstName ? 'primary' : COLORS.blue,
                      color: 'white',
                    }}
                    disabled={!subscriptionDetails.firstName}
                  >
                    {translate('ra.buttons.payNow')}
                  </Button>
                </div>
              )}
            {showCard &&
              orgIsInSubscriptionState([SUBSCRIPTION_STATES.ACTIVE, SUBSCRIPTION_STATES.GRACE]) && (
                <>
                  <Divider FullWidth style={{ marginTop: '10px' }} />
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <TextButton
                      label={translate('ra.buttons.cancelSubscription')}
                      style={{ color: 'primary', marginTop: '10px' }}
                      onClick={handleOpenCancelConfirmationModal}
                    />
                  </div>

                  <CancelSubscriptionDialogConfirmation
                    isOpen={isCancelModalOpen}
                    onClose={handleCloseCancelConfirmationModal}
                    cancelSubscription={cancelSubscriptionMutation}
                  />
                </>
              )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

SubscriptionForm.propTypes = {
  setPaymentSuccess: PropTypes.func,
  setActivateCouponFailure: PropTypes.func,
  showCard: PropTypes.bool,
  setTab: PropTypes.bool,
  handleActivateSubscription: PropTypes.func,
}

SubscriptionForm.defaultProps = {
  handleActivateSubscription: () => {},
}

export default SubscriptionForm
