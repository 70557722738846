import axiosInstance from '../axiosInterceptor/axiosInstance'

const updateOrg = async (data) => {
  const { name, city, countryId, orgId, logo } = data
  const url = `/clubs/${orgId}`

  return axiosInstance.patch(url, {
    name,
    countryId,
    city,
    logo,
  })
}

export default updateOrg
