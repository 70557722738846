import { stringify } from 'query-string'
import { BASE_URL } from '../../utils/constants/url'
import httpClient from '../../utils/helpers/httpClient'

const playerProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort

    let queryParams = {}
    Object.entries(params.filter).forEach((filter) => {
      const [key, value] = filter
      queryParams = { ...queryParams, [key]: value, clubId: queryParams.orgId } // Remove in general clubId, it will get it from queryParams
    })
    queryParams = {
      ...queryParams,
      page,
      perPage,
      OrderBy: order === 'ASC' ? field : `-${field}`,
      // range: JSON.stringify([(page - 1) * perPage, page * perPage - 1])
    }

    const url = `${BASE_URL}/${resource}?${stringify(queryParams)}`

    return httpClient(url).then(({ headers, json }) => ({
      data: json.players,
      total: json.metadata.totalCount,
    }))
  },

  create: (resource, params) =>
    httpClient(`${BASE_URL}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: {
        ...json,
        contacts: [{ type: 'EMAIL' }, { type: 'PHONE_NUMBER' }],
        positions: json.personalData?.positions?.map((position) => position.id),
      },
    })),

  getOne: (resource, params) =>
    httpClient(`${BASE_URL}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  delete: (resource, params) =>
    httpClient(`${BASE_URL}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: {} })),

  update: (resource, params) =>
    httpClient(`${BASE_URL}/${resource}/${params.id}`, {
      method: 'PATCH',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),
}

export default playerProvider
