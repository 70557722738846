import React from 'react'
import { makeStyles } from '@material-ui/core'
import { saveAs } from 'file-saver'
import PropTypes from 'prop-types'
import MyDownloadIcon from '../../assets/icons/MyDownloadIcon'
import drillSliderDownloadImageStyles from './drillSliderDownloadImage.styles'
import designType from '../../types/designType'

const DrillSliderDownloadImage = ({ style, design, show }) => {
  const useStyles = makeStyles(drillSliderDownloadImageStyles)
  const classes = useStyles()

  const downloadImage = () => {
    if (show) {
      saveAs(design.preview, `${design.id}.png`)
    }
  }

  return <MyDownloadIcon style={style} className={classes.downloadIcon} onClick={downloadImage} />
}

DrillSliderDownloadImage.propTypes = {
  style: PropTypes.objectOf(PropTypes.string),
  design: designType,
  show: PropTypes.bool,
}

export default DrillSliderDownloadImage
