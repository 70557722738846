import * as React from 'react'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import NoLayoutContainer from '../../../components/layout/NoLayoutContainer/NoLayoutContainer'
import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm'
import forgotPasswordPageStyles from './forgotPasswordPage.styles'

const ForgotPasswordPage = () => {
  const translate = useTranslate()
  const useStyles = makeStyles(forgotPasswordPageStyles)
  const classes = useStyles()

  return (
    <NoLayoutContainer>
      <div className={classes.forgotPasswordLabel}>{translate('ra.text.forgotPassword')}</div>
      <ForgotPasswordForm />
    </NoLayoutContainer>
  )
}

export default ForgotPasswordPage
