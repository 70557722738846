import COLORS from '../../../utils/constants/colors'

const courtsListStyles = {
  emptyCourt: {
    paddingTop: 15,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: 15,
  },
  buttonsWrapperHidden: {
    visibility: 'hidden',
  },
  orangeButton: {
    '&&': {
      fill: COLORS.orange,
    },
  },
}

export default courtsListStyles
