const dateFormatter = (date) => {
  if (date) {
    return new Date(date).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    })
  }

  return null
}

export default dateFormatter
