const getBase64ImageFromUrlHelper = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.setAttribute('crossOrigin', 'anonymous')
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      const dataURL = canvas.toDataURL('image/png')
      resolve(dataURL)
    }
    img.onerror = (error) => {
      reject(error)
    }

    // Add dummy get parameter in url to force browser to send new request. (CORS error)
    img.src = `${url}?not-from-cache-please`
  })
}

export default getBase64ImageFromUrlHelper
