import { shape, string, number } from 'prop-types'
import trainingModuleType from './trainingModuleType'

const practicePlanComponentType = shape({
  duration: number,
  id: string,
  notes: string,
  order: number,
  trainingModule: trainingModuleType,
})

export default practicePlanComponentType
