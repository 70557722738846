import { Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import orgTypeChooseModalStyles from './orgTypeChooseModal.styles'
import ORGANISATION_TYPES from '../../../utils/constants/OrganisationTypes'
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball'
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents'

const OrgTypeChooseModal = ({ setOrgTypeSelected, stepForward }) => {
  const useStyles = makeStyles(orgTypeChooseModalStyles)
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={3}
      justifyContent='center'
      style={{ paddingBottom: '16px', boxSizing: 'border-box' }}
    >
      <Grid item xs={12} sm={6}>
        <Card
          className={classes.card}
          onClick={() => {
            setOrgTypeSelected(ORGANISATION_TYPES.ORG_CLUB)
            stepForward()
          }}
        >
          <CardContent className={classes.cardContent}>
            <SportsBasketballIcon style={{ fontSize: 120 }} />
            <Typography variant='h4'>Club</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card
          className={classes.card}
          onClick={() => {
            setOrgTypeSelected(ORGANISATION_TYPES.ORG_LEAGUE)
            stepForward()
          }}
        >
          <CardContent className={classes.cardContent}>
            <EmojiEventsIcon style={{ fontSize: 130 }} />
            <Typography variant='h4'>League</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

OrgTypeChooseModal.propTypes = {
  setOrgTypeSelected: PropTypes.func.isRequired,
  stepForward: PropTypes.func.isRequired,
}

export default OrgTypeChooseModal
