import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import AccordionLabelBox from './AccordionLabelBox'
import AccordionArrowIcon from './AccordionArrowIcon'

const AccordionParent = (props) => {
  const {
    label,
    items,
    color,
    initiallyOpen,
    gridPosition,
    style,
    onClick,
    clicked,
    children,
    onDeleteChild,
    onUpdateName,
    isEditable,
    viewOnly,
  } = props
  const [open, setOpen] = useState(initiallyOpen)

  return (
    <Grid container justifyContent={gridPosition}>
      <AccordionLabelBox
        label={label}
        icon={
          open ? <AccordionArrowIcon color={color} down /> : <AccordionArrowIcon color={color} />
        }
        arrowOnClick={(e) => {
          e.stopPropagation()
          setOpen(!open)
        }}
        onClick={onClick}
        color={color}
        style={style}
        clicked={clicked}
        onDeleteChild={onDeleteChild}
        onUpdateName={onUpdateName}
        isEditable={isEditable}
        viewOnly={viewOnly}
      >
        {children}
      </AccordionLabelBox>
      {open && items}
    </Grid>
  )
}

AccordionParent.propTypes = {
  label: PropTypes.string,
  items: PropTypes.node,
  color: PropTypes.string,
  initiallyOpen: PropTypes.bool,
  gridPosition: PropTypes.string,
  onClick: PropTypes.func,
  clicked: PropTypes.bool,
  children: PropTypes.node,
  onDeleteChild: PropTypes.func,
  onUpdateName: PropTypes.func,
  isEditable: PropTypes.bool,
  viewOnly: PropTypes.bool,
}

AccordionParent.defaultProps = {
  viewOnly: false,
}

export default AccordionParent
