import * as React from 'react'
import { Chip, makeStyles } from '@material-ui/core'
import { v4 } from 'uuid'
import chipFieldStyles from './chipField.styles'

const ChipField = (props) => {
  const { label, dataArray, titleStyle } = props
  const useStyles = makeStyles(chipFieldStyles)
  const classes = useStyles()

  const dataArrayNames = dataArray?.map((object) => object.name)

  return (
    <>
      <div className={titleStyle}>{label}</div>
      <div style={{ display: 'flex', gap: 5 }}>
        {dataArrayNames?.map((chip) => (
          <Chip
            key={v4()}
            label={chip}
            classes={{ root: classes.chipRoot, label: classes.chipLabel }}
          />
        ))}
      </div>
    </>
  )
}

export default ChipField
