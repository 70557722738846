import React, { useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Accordion, AccordionSummary, makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import practicePlayersComponentsAccordionStyles from './practicePlayersComponentsAccordion.styles'
import useHistoryPush from '../../hooks/useHistoryPush'
import TextButton from '../ui/TextButton/TextButton'
import removePracticePlayers from '../../Apis/practice/removePracticePlayers'
import QueryKeys from '../../utils/constants/queryKeys'
import MySportsHandballIcon from '../../assets/icons/MySportsHandballIcon'
import FadeOut from '../ui/FadeOut/FadeOut'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import IsUserRolesAuthorized from '../IsUserRolesAuthorized/IsUserRolesAuthorized'
import USER_ORG_ROLES from '../../utils/constants/userOrgRoles'
import USER_ORG_MEMBERS_ROLES from '../../utils/constants/userOrgMembersRoles'

// TODO : Try to make it simpler. Do it for the PracticePlanComponentsAccordion too

const PracticePlayersComponentsAccordion = ({ practicePlayers, isModal, selectedEventId }) => {
  const practicePlayersData = practicePlayers || {}
  const { id: practicePlayersId } = practicePlayersData
  const [isFadedOut, setIsFadedOut] = useState(false)
  const [practicePlayersDeletedId, setPracticePlayersDeletedId] = useState(null)
  const accordionRef = useRef(null)
  const translate = useTranslate()
  const { redirect } = useHistoryPush()
  const queryClient = useQueryClient()
  const useStyles = makeStyles(practicePlayersComponentsAccordionStyles())
  const classes = useStyles()

  const removeAPracticePlayers = useMutation(() => removePracticePlayers(practicePlayers.id), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICES)
    },
  })

  const handleAccordionClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    redirect(`/practice-players/${practicePlayers?.id}/show`, [
      { eventId: selectedEventId },
      { goBack: 'practice' },
    ])
  }

  const removePracticePlayersDebounce = debounce(() => {
    removeAPracticePlayers.mutate(practicePlayers.id)
    setPracticePlayersDeletedId(practicePlayersId)
  }, 1200)

  const handleDeleteButton = (e) => {
    e.stopPropagation()
    setIsFadedOut(true)

    removePracticePlayersDebounce()
  }

  return (
    <ConditionalRender renderValue={!practicePlayersDeletedId}>
      <FadeOut isFadedOut={isFadedOut && !removeAPracticePlayers.isLoading}>
        <div style={{ position: 'relative' }} ref={accordionRef}>
          <Accordion
            classes={{ root: classes.accordionRoot, expanded: classes.accordionRootExpanded }}
            onChange={handleAccordionClick}
            expanded={false}
          >
            <AccordionSummary
              classes={{
                root: classes.accordionSummaryRoot,
                content: classes.accordionSummaryContent,
                expandIcon: classes.accordionSummaryExpandIcon,
                expanded: classes.accordionSummaryExpanded,
              }}
            >
              <div className={classes.accordionSummaryBody}>
                <div className={classes.practicePlanLabel}>
                  {practicePlayers ? 'Players' : null}
                </div>

                <div className={classes.divFlex}>
                  {isModal ? (
                    <>
                      <MySportsHandballIcon className={classes.handballIcon} />
                      <span className={classes.componentsLength}>
                        {practicePlayers?.players?.length}
                      </span>
                    </>
                  ) : (
                    <IsUserRolesAuthorized
                      acceptedRoles={[
                        USER_ORG_ROLES.OWNER,
                        USER_ORG_ROLES.ADMIN,
                        USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
                      ]}
                    >
                      <TextButton
                        onClick={handleDeleteButton}
                        label={translate('ra.buttons.removeSmall')}
                        className={classes.button}
                      />
                    </IsUserRolesAuthorized>
                  )}
                </div>
              </div>
            </AccordionSummary>
          </Accordion>
        </div>
      </FadeOut>
    </ConditionalRender>
  )
}

PracticePlayersComponentsAccordion.propTypes = {
  practicePlayers: PropTypes.shape({
    id: PropTypes.string.isRequired,
    creatorId: PropTypes.string.isRequired,
    eventId: PropTypes.string.isRequired,
    players: PropTypes.arrayOf(PropTypes.string),
    practiceTeams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        numberOfPlayers: PropTypes.number.isRequired,
        teamPlayerNames: PropTypes.arrayOf(PropTypes.string).isRequired,
        order: PropTypes.number.isRequired,
      }),
    ),
    deletedDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
      PropTypes.oneOf([null]),
    ]),
  }),
  isModal: PropTypes.bool.isRequired,
  selectedEventId: PropTypes.string,
}

export default PracticePlayersComponentsAccordion
