import COLORS from '../../utils/constants/colors'

const sendConfirmationEmailPageStyles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  button: {
    margin: '10px 0',
  },
  link: {
    color: COLORS.purple,
    alignSelf: 'flex-start',
  },
}

export default sendConfirmationEmailPageStyles
