import React from 'react'
import PropTypes from 'prop-types'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import CheckIcon from '@material-ui/icons/Check'
import billingAddonStyles from './billingAddon.styles'
import {
  makeStyles,
  Card,
  CardContent,
  Grid,
  Typography,
  List,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import addonInfo from '../../../utils/constants/addonInfo'
import subscriptionIconMapper from '../../../utils/constants/subscriptionMapper'

const BillingAddonActivation = ({ billingAddon, stepForward }) => {
  const useStyles = makeStyles(billingAddonStyles)
  const classes = useStyles()

  const mapPricingModel = (pricingModel, unitPrice) => {
    const pricingModels = {
      PerSeat: 'Seat',
      Manual: 'Offline Payment',
      Fixed: 'Month',
    }

    if (unitPrice) {
      return `€${unitPrice}/${pricingModels[pricingModel]}`
    }

    return `${pricingModels[pricingModel]}`
  }

  return (
    <>
      <Card variant='outlined' elevation={0} className={classes.addonCard}>
        <CardContent className={classes.cardContent}>
          <Grid sm={4} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {subscriptionIconMapper[billingAddon.id]}
              </div>
              <Typography variant='h6' style={{ fontWeight: 'bold', marginLeft: 5 }}>
                {billingAddon.id === 'Coach' ? 'Coaching' : billingAddon.id}
              </Typography>
            </div>
          </Grid>
          <Grid sm={6} style={{ display: 'flex', flexDirection: 'column' }}>
            {addonInfo[billingAddon.id].map((feature) => (
              <List key={feature} className={classes.dotText}>
                <ListItemIcon style={{ minWidth: 20 }}>
                  <CheckIcon className={classes.check} />
                </ListItemIcon>
                <ListItemText primary={feature} />
              </List>
            ))}
          </Grid>
          <Grid sm={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant='body1'>
              {mapPricingModel(billingAddon.pricingModel, billingAddon.unitPrice)}
            </Typography>
          </Grid>
        </CardContent>
      </Card>
      <div className={classes.buttonContainer}>
        <ButtonWithLoader label='Next' justifyContent='flex-end' onClick={stepForward} />
      </div>
    </>
  )
}

BillingAddonActivation.propTypes = {
  billingAddon: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pricing: PropTypes.string.isRequired,
    seats: PropTypes.number,
    enabled: PropTypes.bool.isRequired,
    userActivatable: PropTypes.bool.isRequired,
    pricingModel: PropTypes.string,
    unitPrice: PropTypes.number,
  }).isRequired,
  stepForward: PropTypes.func.isRequired,
}

BillingAddonActivation.defaultProps = {
  stepForward: () => {},
}

export default BillingAddonActivation
