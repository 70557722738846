const orgAvatarEmptyPreviewStyles = {
  emptyOrg: {
    padding: 12,
    backgroundColor: '#eeeeee',
    borderRadius: '50%',
    width: 90,
    height: 90,
  },
  preview: {
    width: 105,
    height: 105,
    marginTop: 5,
  },
}

export default orgAvatarEmptyPreviewStyles
