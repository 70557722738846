import { Checkbox, ClickAwayListener, makeStyles, Paper, Popper, Grow } from '@material-ui/core'
import colourPickerPopperStyles from './colourPickerPopper.styles'
import { useFormikContext } from 'formik'
import { useState } from 'react'
import ButtonWithLoader from '../ui/ButtonWithLoader/ButtonWithLoader'
import COLORS from '../../utils/constants/colors'

const ColourPickerPopper = (props) => {
  const { name, palette } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const { values, setFieldValue } = useFormikContext()
  const useStyles = makeStyles(colourPickerPopperStyles)
  const classes = useStyles()

  const isValueChecked = (values, id) => {
    return values[name]?.id === id
  }

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClickAway = () => {
    setAnchorEl(null)
  }

  const UncheckedIcon = ({ colour }) => (
    <span className={classes.icon} style={{ backgroundColor: colour.value }} />
  )

  return (
    <>
      <ButtonWithLoader
        label='Select a colour'
        type='button'
        style={{ backgroundColor: values[name]?.value || COLORS.lightGrey }}
        onClick={handleClick}
      />
      <Popper
        className={classes.popper}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement='bottom-start'
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Grow in={!!anchorEl} timeout={500}>
            <Paper elevation={4} className={classes.paper}>
              {palette.length &&
                palette.map((colour) => (
                  <Checkbox
                    key={colour.id}
                    checked={isValueChecked(values, colour.id)}
                    className={classes.coloredBox}
                    style={{
                      color: colour.value,
                    }}
                    onClick={() => setFieldValue(name, colour)}
                    variant='solid'
                    size='medium'
                    icon={<UncheckedIcon colour={colour} />}
                  />
                ))}
            </Paper>
          </Grow>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default ColourPickerPopper
