import * as React from 'react'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import { useState } from 'react'
import { useTranslate } from 'react-admin'
import AddButton from '../../components/ui/AddButton/AddButton'
import SimpleTextField from '../../components/simpleTextField/SimpleTextField'
import AddPracticePlanTypePopover from '../../components/AddPracticePlanTypePopover/AddPracticePlanTypePopover'
import PracticePlanComponentsList from '../../components/PracticePlanComponentsList/PracticePlanComponentsList'
import DrillsListModal from '../../components/DrillsListModal/DrillsListModal'
import { usePracticePlanContext } from '../../context/usePracticePlanContext'
import ContainerPadding from '../../components/containerPadding/containerPadding'
import DrillCreateFormModal from '../DrillCreate/DrillCreateFormModal/DrillCreateFormModal'
import DrillShowPageModal from '../DrillShow/DrillShowPageModal/DrillShowPageModal'
import practicePlanCreateStyles from './PracticePlanCreate.styles'
import MyDeleteIcon from '../../assets/icons/MyDeleteIcon'
import ButtonWithLoader from '../../components/ui/ButtonWithLoader/ButtonWithLoader'

const PracticePlanCreate = () => {
  const { practicePlan, setPracticePlan, deletePracticePlan, triggerPracticePlanRequest } =
    usePracticePlanContext()
  const { description } = practicePlan
  const [addPopoverAnchorEl, setAddPopoverAnchorEl] = useState(false)
  const [drillsListModalOpen, setDrillsListModalOpen] = useState(false)
  const [drillCreateFormModalOpen, setDrillCreateFormModalOpen] = useState(false)
  const [drillShowPageModalOpen, setDrillShowPageModalOpen] = useState(false)
  const [clickedComponent, setClickedComponent] = useState()
  const translate = useTranslate()
  const useStyles = makeStyles(practicePlanCreateStyles)
  const classes = useStyles()

  const handleDescriptionChange = (e) => {
    const { value } = e.target
    setPracticePlan({ ...practicePlan, description: value })
  }

  const handleSaveButtonClick = () => {
    triggerPracticePlanRequest(true)
  }

  const handleDeletePracticePlan = () => {
    deletePracticePlan.mutate(practicePlan?.id)
  }

  return (
    <div className={classes.wrapper}>
      <Grid container alignItems='center' className={classes.header}>
        <Grid item xs={12} sm={9}>
          <SimpleTextField
            fullWidth
            value={description}
            onChange={handleDescriptionChange}
            placeholder={translate('ra.text.practicePlanTopic')}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Grid container className={classes.headerButtons}>
            <MyDeleteIcon disabled={!practicePlan?.id} onClick={handleDeletePracticePlan} />
            <ButtonWithLoader
              label={translate('ra.buttons.save')}
              onClick={handleSaveButtonClick}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider light />
      <ContainerPadding vertical paddingValue='1.5%'>
        <div className={classes.addButton}>
          <AddButton
            onClick={(e) => setAddPopoverAnchorEl(e.currentTarget)}
            label={translate('ra.buttons.addSmall')}
            filled
          />
          <AddPracticePlanTypePopover
            open={Boolean(addPopoverAnchorEl)}
            onClose={() => setAddPopoverAnchorEl(false)}
            anchorEl={addPopoverAnchorEl}
            openDrillsListModal={() => {
              setAddPopoverAnchorEl(false)
              setDrillsListModalOpen(true)
            }}
            openDrillCreateModal={() => {
              setAddPopoverAnchorEl(false)
              setDrillCreateFormModalOpen(true)
            }}
            description={description}
          />
          {drillsListModalOpen && (
            <DrillsListModal
              open={drillsListModalOpen}
              onClose={() => setDrillsListModalOpen(false)}
            />
          )}

          <DrillShowPageModal
            open={drillShowPageModalOpen}
            onClose={() => {
              setDrillShowPageModalOpen(false)
              setClickedComponent(null)
            }}
            openDrillCreateModal={() => {
              setDrillShowPageModalOpen(false)
              setDrillCreateFormModalOpen(true)
            }}
            practicePlan={practicePlan}
            clickedComponent={clickedComponent}
          />
          {drillCreateFormModalOpen && (
            <DrillCreateFormModal
              open={drillCreateFormModalOpen}
              onClose={(selectedDrillComponent) => {
                if (selectedDrillComponent) {
                  setDrillShowPageModalOpen(true)
                } else {
                  setClickedComponent(undefined)
                }
                setDrillCreateFormModalOpen(false)
              }}
              clickedComponent={clickedComponent}
              setClickedComponent={setClickedComponent}
            />
          )}
        </div>
        <PracticePlanComponentsList
          openDrillShowPageModal={(component) => {
            setClickedComponent(component)
            setDrillShowPageModalOpen(true)
          }}
        />
      </ContainerPadding>
    </div>
  )
}

export default PracticePlanCreate
