import COLORS from '../../utils/constants/colors'

const practicesListStyles = {
  headerButtons: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px',
  },
  addButton: {
    marginLeft: 'auto',
  },
  practicesContainer: {
    marginTop: 15,
  },
  loaderIcon: {
    fill: COLORS.orange,
    marginTop: -50,
    width: 100,
    height: 100,
  },
}

export default practicesListStyles
