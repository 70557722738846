import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { FAKE_EVENT_UUID } from '../../utils/constants/eventTypeForms'
import eventVariantType from '../../types/events/eventVariantType'
import eventRawType from '../../types/events/eventRawType'

const AutoChangeFakeEvent = (props) => {
  const { clickedEventId, selectedEventType, eventsRaw, setEvents } = props
  const { values } = useFormikContext()
  const { title, startDateTime, endDateTime, fromDate, startTime, endTime, teams } = values

  useEffect(() => {
    const eventId = clickedEventId || FAKE_EVENT_UUID
    const fakeEvent = eventsRaw && eventsRaw.filter((value) => value.uuid === eventId)

    if (
      values &&
      fakeEvent &&
      fakeEvent[0] &&
      ((title !== fakeEvent[0].title && title !== '') ||
        startDateTime !== fakeEvent[0].startDateTime ||
        endDateTime !== fakeEvent[0].endDateTime ||
        fromDate !== fakeEvent[0].startDateTime ||
        startTime !== fakeEvent[0].startDateTime ||
        endTime !== fakeEvent[0].startDateTime ||
        values.type.name !== selectedEventType.name)
    ) {
      const newEventsRaw = eventsRaw.filter(function (value) {
        return value.uuid !== eventId
      })

      const createTitle = () => {
        if (clickedEventId) {
          return eventsRaw.filter((event) => event.uuid === clickedEventId)[0].title
        } else {
          if (
            title &&
            (selectedEventType.name === 'OTHER' || selectedEventType.name === 'LIVE_STREAM')
          ) {
            return title
          } else {
            return `NEW ${selectedEventType.name}`
          }
        }
      }

      setEvents([
        ...newEventsRaw,
        {
          uuid: eventId,
          type: {
            displayName: selectedEventType,
            color: selectedEventType.color,
          },
          title: createTitle(),
          timeZone: 'Europe/Athens',
          startDateTime: startTime ?? startDateTime,
          endDateTime: endTime ?? endDateTime,
          teams: [
            {
              name: teams?.[0] ? teams[0].name : 'TM',
              uuid: teams?.[0] ? teams[0].id : FAKE_EVENT_UUID,
              threeLetterIdentifier: teams?.[0] ? teams[0].threeLetterIdentifier : '',
            },
          ],
        },
      ])
    }
  }, [values, selectedEventType]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

AutoChangeFakeEvent.propTypes = {
  clickedEventId: PropTypes.string,
  selectedEventType: eventVariantType,
  eventsRaw: PropTypes.arrayOf(eventRawType),
  setEvents: PropTypes.func,
}

export default AutoChangeFakeEvent
