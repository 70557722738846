import * as React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Toolbar, useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import CreateAndAddButton from './CreateAndAddButton/CreateAndAddButton'
import playerCreateToolbarStyles from './playerCreateToolbar.styles'
import MySaveButton from './MySaveButton/MySaveButton'
import useQueryParams from '../../../../hooks/useQueryParams'

const PlayerCreateToolbar = (props) => {
  const { validating, mutationMode, saving, pristine, invalid, ...rest } = props
  const translate = useTranslate()
  const queryParams = useQueryParams()
  const useStyles = makeStyles(playerCreateToolbarStyles)
  const classes = useStyles()

  return (
    <Toolbar classes={{ desktopToolbar: classes.toolbar }}>
      <Grid container spacing={2} justifyContent='flex-end'>
        <Grid item>
          <CreateAndAddButton
            {...rest}
            label={translate('ra.buttons.createAndNew')}
            redirect={false}
            submitOnEnter={false}
            variant='text'
            disabled={pristine}
            icon={<></>}
            saving={saving && invalid}
          />
        </Grid>
        <Grid item>
          <MySaveButton
            {...rest}
            label={translate('ra.buttons.create')}
            redirect={(basePath) => `${basePath}?org=${queryParams?.org}`}
            submitOnEnter
            disabled={pristine}
            icon={<></>}
            saving={saving && !invalid}
          />
        </Grid>
      </Grid>
    </Toolbar>
  )
}

PlayerCreateToolbar.propTypes = {
  validating: PropTypes.bool,
  saving: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
}

export default PlayerCreateToolbar
