import getPlayer from '../../../../Apis/player/getPlayer'

const playerStatsBreadcrumb = async (data) => {
  const { match, teamName } = data || {}
  const { playerId } = match.params

  const playerName = await getPlayer({ playerId }).then((player) => {
    return `${player?.personalData?.firstName} ${player?.personalData?.lastName}`
  })

  return ['PLAYER STATS', playerName ? playerName.toUpperCase() : '']
}

export default playerStatsBreadcrumb
