const addLeagueFormStyles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  imageCropContainer: {
    justifyContent: 'center',
    alignContent: 'center',
  },
}

export default addLeagueFormStyles
