import * as React from 'react'
import { v4 } from 'uuid'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import MyChip from '../MyChip/MyChip'
import chipsContainerStyles from './chipsContainer.styles'
import AddIcon from '../../assets/icons/addIcon'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import sortByStringValueAscending from '../../utils/helpers/sortByStringValueAscending'

const ChipsContainer = (props) => {
  const { chips, handleAddIcon, onDelete, small, overflow } = props
  const useStyles = makeStyles(chipsContainerStyles)
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      {chips &&
        sortByStringValueAscending(chips, 'name').map((tag, index) => {
          if (overflow && index > 2) return null

          return (
            <MyChip
              key={v4()}
              label={tag.name}
              onDelete={onDelete ? () => onDelete(tag) : null}
              color={tag.color}
              small={small}
            />
          )
        })}
      <ConditionalRender renderValue={overflow && chips?.length > 3}>
        <div className={classes.overflow}>+{Math.max((chips?.length || 0) - 3, 0)}</div>
      </ConditionalRender>
      <ConditionalRender renderValue={handleAddIcon}>
        <AddIcon onClick={handleAddIcon} />
      </ConditionalRender>
    </div>
  )
}

ChipsContainer.propTypes = {
  chips: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
  handleAddIcon: PropTypes.func,
  onDelete: PropTypes.func,
  small: PropTypes.bool,
  overflow: PropTypes.bool,
}

export default ChipsContainer
