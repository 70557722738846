import COLORS from '../../../utils/constants/colors'

const drillShowBodyStyles = {
  cardContentRoot: {
    '&&': {
      padding: 0,
    },
  },
  fullWidth: {
    width: '100%',
  },
  span: {
    marginTop: '25px',
    marginBottom: '10px',
  },
  greySpan: {
    color: COLORS.darkGrey,
    textAlign: 'justify',
    whiteSpace: 'pre-wrap',
  },
  description: {
    marginTop: '25px',
    fontSize: 17.5,
  },
  tagsLabel: {
    display: 'block',
    color: COLORS.darkGrey,
    marginTop: '25px',
  },
  gridDividerRoot: {
    maxWidth: '4.75%',
    flexBasis: '4.75%',
    minHeight: '84.7vh',
  },

  tagsGrid: {
    maxWidth: '100%',
    flexGrow: 1,
  },

  videoPlayer: {
    width: '100%',
    height: 'auto',
    paddingTop: 40,
    paddingBottom: 40,
  },
}

export default drillShowBodyStyles
