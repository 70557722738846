import * as React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import BoxEventTypeColor from '../BoxEventTypeColor/BoxEventTypeColor'
import eventVariantType from '../../types/events/eventVariantType'

const SelectEventTypeRenderValue = (props) => {
  const { eventType, small } = props

  return (
    <Grid container spacing={1} alignItems='center' mr={1}>
      <Grid item sx={2}>
        <BoxEventTypeColor color={eventType.color} />
      </Grid>
      <Grid item sx={4}>
        <div style={{ fontSize: small ? 16 : 22, cursor: 'pointer', marginLeft: small ? 0 : 6 }}>
          {eventType.name}
        </div>
      </Grid>
    </Grid>
  )
}

SelectEventTypeRenderValue.propTypes = {
  eventType: eventVariantType,
  small: PropTypes.bool,
}

export default SelectEventTypeRenderValue
