import { BASE_URL } from '../../utils/constants/url'
import axiosInstance from '../axiosInterceptor/axiosInstance'

const addEmptyOrgSeats = async (data) => {
  const { orgId, addedSeats } = data
  const url = `${BASE_URL}/billing/seats?orgId=${orgId}`
  const response = await axiosInstance.post(url, addedSeats)
  return response?.data
}

export default addEmptyOrgSeats
