import React, { useEffect, useState } from 'react'
import { useInfiniteQuery, useQuery } from 'react-query'
import { Grid } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import DrillListAppbar from '../DrillListAppbar/DrillListAppbar'
import QueryKeys from '../../../utils/constants/queryKeys'
import getDrills from '../../../Apis/drill/getDrills'
import useQueryParams from '../../../hooks/useQueryParams'
import addUrlQueryParams from '../../../utils/helpers/addUrlQueryParams'
import setDrillsFiltersFromUrl from '../../../utils/helpers/setDrillsFiltersFromUrl'
import InfiniteScroll from '../../../components/InfiniteScroll/InfiniteScroll'
import sessionStorageIds from '../../../utils/constants/sessionStorageIds'
import ContainerPadding from '../../../components/containerPadding/containerPadding'
import TRAINING_MODULES_TYPES from '../../../utils/constants/trainingModulesTypes'
import { DRILLS_FILTERS_TYPES } from '../../../utils/constants/drillsFiltersTypes'
import DrillsMasonry from '../../../components/DrillsMasonry/DrillsMasonry'
import historyRouterType from '../../../types/historyRouterType'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import drillListPageStyles from './drillListPage.styles'

const DrillListPage = ({ history }) => {
  const queryParams = useQueryParams()
  const access = queryParams?.access
  const [toggleValue, setToggleValue] = useState(access ?? 'private')
  const [filters, setFilters] = useState(setDrillsFiltersFromUrl(queryParams))
  const [tactics, setTactics] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const translate = useTranslate()
  const { intensity, position } = DRILLS_FILTERS_TYPES
  const trainingModuleType = history && history?.location?.pathname?.split('/')[1]
  const showDrillsFilters = trainingModuleType === 'drills'

  useEffect(() => {
    if (history) {
      addUrlQueryParams(history, 'access', toggleValue)
    }
  }, [toggleValue]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (history) {
      addUrlQueryParams(history, 'intensity', filters[intensity] ? filters[intensity].join() : '')

      addUrlQueryParams(history, 'position', filters[position] ? filters[position].join() : '')
    }
  }, [filters]) // eslint-disable-line react-hooks/exhaustive-deps

  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage, isFetchedAfterMount } =
    useInfiniteQuery(
      [
        QueryKeys.GET_DRILLS,
        toggleValue,
        filters[intensity],
        filters[position],
        tactics,
        searchValue,
        trainingModuleType,
      ],
      ({ pageParam = 1 }) =>
        getDrills({
          access: toggleValue.toUpperCase(),
          pageNumber: pageParam,
          intensities: showDrillsFilters ? filters[intensity] : [],
          positions: showDrillsFilters ? filters[position] : [],
          tactics: tactics.map((tactic) => tactic.name),
          q: searchValue,
          type: showDrillsFilters ? TRAINING_MODULES_TYPES.drill : TRAINING_MODULES_TYPES.plays,
        }),
      {
        getNextPageParam: ({ metadata }) => {
          const { currentPage, totalPages } = metadata
          return currentPage === totalPages || totalPages === 0 ? undefined : currentPage + 1
        },
      },
    )

  const usersTrainingModules = useQuery(
    [QueryKeys.GET_DRILLS, toggleValue],
    () =>
      getDrills({
        access: 'PRIVATE',
        pageNumber: 1,
        perPage: 1000,
        type:
          trainingModuleType === 'drills'
            ? TRAINING_MODULES_TYPES.drill
            : TRAINING_MODULES_TYPES.plays,
      }),
    { enabled: Boolean(toggleValue === 'public') },
  )

  return (
    <ContainerPadding>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <DrillListAppbar
            resource={trainingModuleType}
            toggleValue={toggleValue}
            setToggleValue={setToggleValue}
            filters={filters}
            setFilters={setFilters}
            setTactics={setTactics}
            tactics={tactics}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            isLoading={!isFetchedAfterMount || isFetchingNextPage}
          />
        </Grid>

        {data && data?.pages?.map((page) => page.trainingModulesDetailsDto).flat().length ? (
          <Grid item>
            <DrillsMasonry
              drills={data?.pages ?? []}
              toggleAccess={toggleValue}
              trModuleType={trainingModuleType}
              downloadedTrainingModules={usersTrainingModules?.data?.trainingModulesDetailsDto.filter(
                (trainingModule) => !!trainingModule.originalTrainingModuleId,
              )}
            />
          </Grid>
        ) : (
          <ConditionalRender renderValue={isFetchedAfterMount && !isFetchingNextPage}>
            <span style={drillListPageStyles.noDrillsMessage}>
              {translate('ra.text.noTrainingModulesFound')}
            </span>
          </ConditionalRender>
        )}

        <InfiniteScroll
          isLoading={isLoading || isFetchingNextPage}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          scrollId={sessionStorageIds.DRILLS_SCROLLBAR_POSITION}
        />
      </Grid>
    </ContainerPadding>
  )
}

DrillListPage.propTypes = {
  history: historyRouterType,
}

export default DrillListPage
