import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useRedirect, useTranslate } from 'react-admin'
import { Grid, makeStyles } from '@material-ui/core'
import dashboardStyles from './dashboard.styles'
import welcome from '../../../assets/images/welcome.svg'
import QueryKeys from '../../../utils/constants/queryKeys'
import addOrg from '../../../Apis/organisation/addOrg'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import LoaderDots from '../../../components/loaders/LoaderDots/LoaderDots'
import localStorageHelper from '../../../utils/helpers/localStorageHelper'
import getOrgs from '../../../Apis/organisation/getOrgs'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import AddOrgModal from '../../../components/AddOrgModal/AddOrgModal'
import addTeam from '../../../Apis/team/addTeam'
import localStorageIds from '../../../utils/constants/localStorageIds'

const Dashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const translate = useTranslate()
  const useStyles = makeStyles(dashboardStyles)
  const classes = useStyles()

  const allOrgs = useQuery([QueryKeys.GET_ORGS], () => getOrgs())

  return (
    <div className={classes.wrapper}>
      <ConditionalRender renderValue={allOrgs.isFetching}>
        <Grid container justifyContent='center' alignItems='center'>
          <LoaderDots className={dashboardStyles.loader} />
        </Grid>
      </ConditionalRender>

      <ConditionalRender renderValue={!allOrgs.isFetching && allOrgs.data?.length == 0}>
        <>
          <span className={classes.title}>Welcome to Mismatch!</span>
          <span className={classes.subTitle}>{translate('ra.text.feelGame')}</span>
          <img alt='img' src={welcome} className={classes.image} />
          <span className={classes.subTitle}>{translate('ra.text.createOrg')}</span>
          <ButtonWithLoader
            type='button'
            onClick={() => setIsModalOpen(true)}
            label={translate('ra.buttons.create')}
            className={classes.createButton}
          />
          <AddOrgModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </>
      </ConditionalRender>
    </div>
  )
}

export default Dashboard
