const MASONRY_BREAKPOINTS = {
  LIST: {
    default: 5,
    2200: 4,
    1750: 3,
    1250: 2,
    950: 1,
  },
  MODAL: {
    default: 4,
    2000: 3,
    1350: 2,
    950: 1,
  },
  PLAYBOOK: {
    default: 4,
    1800: 3,
    1400: 2,
    950: 1,
  },
}

export default MASONRY_BREAKPOINTS
