import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'

const FormGrid = (props) => {
  const { children } = props
  const useStyles = makeStyles({
    root: {
      width: '100%',
      margin: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
    'spacing-xs-2': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    'grid-xs-1': {
      maxWidth: '7%',
    },
  })
  const classes = useStyles()

  const mapChildren = () =>
    React.Children.map(children, (child) => {
      if (!child) return
      const { props } = child
      const { xs, sm, md, lg, xl, gridItemStyle } = props

      return (
        <Grid
          className={gridItemStyle}
          item
          xs={xs ? xs : true}
          sm={sm ? sm : true}
          md={md ? md : true}
          lg={lg ? lg : true}
          xl={xl ? xl : true}
          style={{ padding: '8px' }}
        >
          {child}
        </Grid>
      )
    })

  return (
    <Grid {...props} classes={classes}>
      {mapChildren()}
    </Grid>
  )
}

export default FormGrid
