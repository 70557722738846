import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import ContainerPadding from '../containerPadding/containerPadding'
import DialogWithCloseIcon from '../DialogWithCloseIcon/DialogWithCloseIcon'
import GetWindowSize from '../GetWindowSize/GetWindowSize'

const MobileNotificationModal = () => {
  const { windowHeight, windowWidth } = GetWindowSize()
  const translate = useTranslate()

  const isDeviceSmall = windowWidth < 550 || windowHeight < 550
  const isPortrait = window.matchMedia('(orientation: portrait)').matches

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <DialogWithCloseIcon open={isDeviceSmall || isPortrait} dialogWidth='330px' hideCloseIcon>
        <ContainerPadding paddingValue='5%'>
          {isDeviceSmall ? (
            <Typography>
              {translate('ra.text.notMobileOptimised')}
              <br /> {translate('ra.text.tryDesktop')}
            </Typography>
          ) : (
            <Typography>{translate('ra.text.notVerticalOptimised')}</Typography>
          )}
        </ContainerPadding>
      </DialogWithCloseIcon>
    </div>
  )
}

export default MobileNotificationModal
