import COLORS from '../../utils/constants/colors'

const drillBoxLiteAnimationStyles = (loading) => ({
  wrapper: {
    border: `1px solid ${COLORS.borderGrey}`,
    borderRadius: '6px',
    padding: '10px 10px',
    margin: '10px 0',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',

    '&::before': {
      content: loading ? '""' : '',
      boxSizing: 'border-box',
      position: 'absolute',
      border: '2px solid transparent',
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      borderTopColor: `${COLORS.orange}`,
      borderRightColor: `${COLORS.orange}`,
      animation: '$border 2s infinite',
    },
    '&::after': {
      content: loading ? '""' : '',
      boxSizing: 'border-box',
      position: 'absolute',
      border: '2px solid transparent',
      width: 0,
      height: 0,
      bottom: 0,
      right: 0,
      animation: '$border 2s 1s infinite, $borderColor 2s 1s infinite',
    },
  },

  '@keyframes border': {
    '0%': {
      width: 0,
      height: 0,
    },
    '25%': {
      width: '100%',
      height: 0,
    },
    '50%': {
      width: '100%',
      height: '100%',
    },
    '100%': {
      width: '100%',
      height: '100%',
    },
  },

  '@keyframes borderColor': {
    '0%': {
      borderBottomColor: `${COLORS.orange}`,
      borderLeftColor: `${COLORS.orange}`,
    },
    '50%': {
      borderBottomColor: `${COLORS.orange}`,
      borderLeftColor: `${COLORS.orange}`,
    },
    '51%': {
      borderBottomColor: '#transparent',
      borderLeftColor: '#transparent',
    },
    '100%': {
      borderBottomColor: '#transparent',
      borderLeftColor: '#transparent',
    },
  },
})

export default drillBoxLiteAnimationStyles
