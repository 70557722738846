import axiosInstance from '../axiosInterceptor/axiosInstance'
import { EVENT_TYPE_FORMS } from '../../utils/constants/eventTypeForms'

const getPractices = async (data) => {
  const { teamId, startDateTime, endDateTime, isPracticePlanIncluded } = data
  const queryString = `?EventTypeCode=${EVENT_TYPE_FORMS.PRACTICE}&TeamId=${teamId}&StartDateTime=${startDateTime}&EndDateTime=${endDateTime}&IsPracticePlanIncluded=${isPracticePlanIncluded}`
  const url = `/events${queryString}`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getPractices
