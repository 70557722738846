import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { makeStyles, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import MyEditIcon from '../../../assets/icons/MyEditIcon'
import MyDeleteIcon from '../../../assets/icons/MyDeleteIcon'
import courtsListStyles from './courtsList.styles'
import QueryKeys from '../../../utils/constants/queryKeys'
import { deleteCourt } from '../../../Apis/courts/deleteCourt'
import courtType from '../../../types/courtType'
import IsUserRolesAuthorized from '../../../components/IsUserRolesAuthorized/IsUserRolesAuthorized'
import USER_ORG_ROLES from '../../../utils/constants/userOrgRoles'
import USER_ORG_MEMBERS_ROLES from '../../../utils/constants/userOrgMembersRoles'

const CourtsList = ({ courts, isCourtsLoading, editedCourtId, setEditedCourtId }) => {
  const [showButtons, setShowButtons] = useState(-1)
  const queryClient = useQueryClient()
  const translate = useTranslate()
  const useStyles = makeStyles(courtsListStyles)
  const classes = useStyles()

  const deleteACourt = useMutation((data) => deleteCourt(data), {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.GET_COURTS),
  })

  const handleDelete = (courtId) => deleteACourt.mutate({ courtId })
  const handleEdit = (courtId) => setEditedCourtId(courtId)

  const filterCourts = () => {
    if (editedCourtId) {
      return courts?.filter((court) => court.id !== editedCourtId)
    }
    return courts
  }

  return !filterCourts()?.length && !isCourtsLoading && !editedCourtId ? (
    <Typography className={classes.emptyCourt} variant='body2'>
      {translate('ra.text.emptyCourtsList')}
    </Typography>
  ) : (
    <Table>
      <TableBody>
        {filterCourts()?.map((court) => (
          <TableRow
            key={court.id}
            hover
            onMouseEnter={() => setShowButtons(court.id)}
            onMouseLeave={() => setShowButtons(-1)}
          >
            <TableCell>{court.name}</TableCell>
            <TableCell>{court.description}</TableCell>
            <TableCell>
              <div
                className={classnames(classes.buttonsWrapper, {
                  [classes.buttonsWrapperHidden]: showButtons !== court.id,
                })}
              >
                <IsUserRolesAuthorized
                  acceptedRoles={[
                    USER_ORG_ROLES.OWNER,
                    USER_ORG_ROLES.ADMIN,
                    USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
                  ]}
                >
                  <MyEditIcon
                    className={classes.orangeButton}
                    onClick={() => handleEdit(court.id)}
                  />
                  <MyDeleteIcon
                    className={classes.orangeButton}
                    onClick={() => handleDelete(court.id)}
                  />
                </IsUserRolesAuthorized>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

CourtsList.propTypes = {
  courts: PropTypes.arrayOf(courtType),
  isCourtsLoading: PropTypes.bool,
  editedCourtId: PropTypes.string,
  setEditedCourtId: PropTypes.func,
}

export default CourtsList
