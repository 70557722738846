import parseDateTime from '../dateHelpers/parseDateTime'
import parseEndDateTimeNextDay from '../dateHelpers/parseEndDateTimeNextDay'
import isDateValid from '../dateHelpers/isDateValid'

const calculateTimeFields = (fromDate, startTime, endTime) => {
  const newStartTime = parseDateTime(fromDate, startTime)
  const newEndTime = parseDateTime(fromDate, endTime)
  const newEndTimeNextDay = parseEndDateTimeNextDay(newStartTime, newEndTime)

  return {
    startTime: newStartTime,
    endTime: newEndTimeNextDay,
  }
}

const onEventFieldChange = {
  onFromDateFieldChange: (formValues, fromDateValue, setValues) => {
    if (!isDateValid(fromDateValue)) {
      return
    }

    const { startTime, endTime } = calculateTimeFields(
      fromDateValue,
      formValues.startTime,
      formValues.endTime,
    )

    setValues({
      ...formValues,
      fromDate: fromDateValue,
      startTime,
      endTime,
    })
  },

  onStartTimeFieldChange: (formValues, startTimeValue, setValues) => {
    if (!isDateValid(startTimeValue)) {
      return
    }

    const { startTime, endTime } = calculateTimeFields(
      formValues.fromDate,
      startTimeValue,
      formValues.endTime,
    )

    setValues({
      ...formValues,
      startTime,
      endTime,
    })
  },

  onEndTimeFieldChange: (formValues, endTimeValue, setValues) => {
    if (!isDateValid(endTimeValue)) {
      return
    }

    const { fromDate, startTime } = formValues
    const newEndTime = parseDateTime(fromDate, endTimeValue)

    setValues({
      ...formValues,
      endTime: parseEndDateTimeNextDay(startTime, newEndTime),
    })
  },
}

export default onEventFieldChange
