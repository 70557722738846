import React from 'react'
import SaveIcon from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/core'
import COLORS from '../../utils/constants/colors'

const MySaveIcon = (props) => {
  const useStyles = makeStyles({
    leftIcon: {
      width: 35,
      height: 33,
      cursor: 'pointer',
      fill: COLORS.darkerOrange,
    },
  })
  const classes = useStyles()

  return <SaveIcon {...props} classes={{ root: classes.leftIcon }} />
}

export default MySaveIcon
