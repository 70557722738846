import * as React from 'react'
import NoLayoutContainer from '../../components/layout/NoLayoutContainer/NoLayoutContainer'
import LoginForm from '../../components/form/forms/LoginForm/LoginForm'

const LoginPage = () => (
  <NoLayoutContainer>
    <LoginForm />
  </NoLayoutContainer>
)

export default LoginPage
