const tacticsPopover = {
  wrapper: {
    padding: '0%',
    paddingTop: '0%',
  },
  accordionWrapper: {
    marginTop: '0',
  },
  popover: {
    width: '300px',
    maxHeight: '500px',
    padding: '5px 0',
  },
}
export default tacticsPopover
