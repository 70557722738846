import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import playerEditToolbarStyles from './playerEditToolbar.styles'
import DeleteButtonWithConfirmation from '../../../components/DeleteButtonWithConfirmation/DeleteButtonWithConfirmation'
import UpdateButton from '../../../components/UpdateButton/UpdateButton'

const PlayerEditToolbar = (props) => {
  const useStyles = makeStyles(playerEditToolbarStyles)
  const classes = useStyles()

  return (
    <div className={classes.buttonsWrapper}>
      <DeleteButtonWithConfirmation
        {...props}
        title='Are you sure you want to delete this player?'
        subtitle='This action will delete user from the organisation and from all teams that he is participating. Do you wish to proceed?'
      />
      <UpdateButton {...props} />
    </div>
  )
}

export default PlayerEditToolbar
