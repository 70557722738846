import COLORS from '../../../utils/constants/colors'

const streamFieldsStyles = {
  tvIcon: {
    '&&': {
      width: 25,
      height: 25,
    },
  },
  cancelIcon: {
    fill: COLORS.orange,
    cursor: 'pointer',
  },
  addButtonText: {
    '&&': {
      fontSize: '1rem',
      fontWeight: 400,
      color: COLORS.white,
    },
  },
}

export default streamFieldsStyles
