import React from 'react'
import PropTypes from 'prop-types'
import Close from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core'
import closeDrawerStyles from './closeDrawer.styles'

const CloseDrawer = ({ onClick }) => {
  const useStyles = makeStyles(closeDrawerStyles)
  const classes = useStyles()

  return (
    <div className={classes.circle} onClick={onClick}>
      <Close className={classes.closeIcon} />
    </div>
  )
}

CloseDrawer.propTypes = {
  onClick: PropTypes.func,
}

export default CloseDrawer
