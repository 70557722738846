const parseEvents = (events, teamExists) => {
  if (!events || (events?.data && Object.keys(events.data).length === 0) || events.errors)
    return null

  const createTitle = (ev) => {
    if (!teamExists && ev.teams[0].threeLetterIdentifier && ev.type?.code !== 'game') {
      return `(${ev.teams[0].threeLetterIdentifier}) ${ev?.title}`
    }

    return ev?.title
  }

  return events.map((event) => ({
    id: event.id,
    start: event.startDateTime,
    end: event.endDateTime,
    title: createTitle(event),
    color: event.type.color,
  }))
}

export default parseEvents
