import COLORS from '../../../utils/constants/colors'

const usersDrawerStyles = {
  drawerPaper: {
    width: '35%',
    minWidth: 540,
    display: 'flex',
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '25px',
  },
  infoHeader: { display: 'flex', justifyContent: 'space-between', marginTop: '20px' },
  formContainer: { marginTop: '20px' },
  typoBold: { fontWeight: 'bold' },
  typoGrey: { color: COLORS.grey },
  loaderIcon: {
    fill: COLORS.orange,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 40,
    width: 50,
    height: 50,
  },
}

export default usersDrawerStyles
