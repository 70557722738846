import React from 'react'
import PropTypes from 'prop-types'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import CheckIcon from '@material-ui/icons/Check'
import billingAddonConfirmationStyles from './billinAddonConfirmation.styles'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  List,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Tooltip,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import addonInfo from '../../../utils/constants/addonInfo'
import SubscriptionForm from '../../../components/form/forms/SubscriptionForm/SubscriptionForm'
import { useTranslate } from 'react-admin'
import TextButton from '../../../components/ui/TextButton/TextButton'
import LoaderDots from '../../../components/loaders/LoaderDots/LoaderDots'
import useUrlSearchParams from '../../../hooks/useUrlSearchParams'
import { useQuery } from 'react-query'
import ContainerPadding from '../../../components/containerPadding/containerPadding'
import COLORS from '../../../utils/constants/colors'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import getOrgQuote from '../../../Apis/subscriptions/getOrgQuote'
import subscriptionIconMapper from '../../../utils/constants/subscriptionMapper'

const BillingAddonConfirmation = ({
  billingAddon,
  usersToInvite,
  subscriptionDetails,
  setPaymentSuccess,
  stepBackwards,
  finish,
}) => {
  const useStyles = makeStyles(billingAddonConfirmationStyles)
  const classes = useStyles()
  const { org: orgId } = useUrlSearchParams(['org']) || {}
  const translate = useTranslate()

  const mapPricingModel = (pricingModel, unitPrice) => {
    const pricingModels = {
      PerSeat: 'Seat',
      Manual: 'Offline Payment',
      Fixed: 'Month',
    }

    if (unitPrice) {
      return `€${unitPrice}/${pricingModels[pricingModel]}`
    }

    return `${pricingModels[pricingModel]}`
  }

  const { data: pricing, isLoading } = useQuery(
    'Quote',
    () =>
      getOrgQuote({
        orgId,
        addonType: billingAddon.id,
        seats: Object.keys(usersToInvite).length,
      }),
    {
      enabled: Boolean(orgId),
    },
  )

  return (
    <>
      {isLoading ? (
        <ContainerPadding paddingValue='45%'>
          <LoaderDots
            style={{
              fill: COLORS.orange,
              width: 70,
              height: 70,
            }}
          />
        </ContainerPadding>
      ) : (
        <>
          <SubscriptionForm setPaymentSuccess={setPaymentSuccess} />
          <Card variant='outlined' elevation={0} className={classes.addonCard}>
            <CardContent className={classes.cardContent}>
              <Grid sm={2} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {subscriptionIconMapper[billingAddon.id]}
                  </div>
                  <Typography variant='h6' style={{ fontWeight: 'bold', marginLeft: 5 }}>
                    {billingAddon.id === 'Coach' ? 'Coaching' : billingAddon.id}
                  </Typography>
                </div>
              </Grid>
              <Grid sm={4} style={{ display: 'flex', flexDirection: 'column' }}>
                {addonInfo[billingAddon.id].map((feature) => (
                  <List key={feature} className={classes.dotText}>
                    <ListItemIcon style={{ minWidth: 20 }}>
                      <CheckIcon className={classes.check} />
                    </ListItemIcon>
                    <ListItemText primary={feature} />
                  </List>
                ))}
              </Grid>
              <Grid
                sm={2}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <Typography variant='body1'>
                  {mapPricingModel(billingAddon.pricingModel, billingAddon.unitPrice)}
                </Typography>
              </Grid>
              <Grid
                sm={2}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <Typography variant='body1'>{`Seats: ${Object.keys(usersToInvite).length}`}</Typography>
              </Grid>
              <Grid
                sm={2}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                  {billingAddon.pricing ? 'Total' : ''}
                </Typography>
                <div className={classes.container}>
                  {subscriptionDetails.coupon || subscriptionDetails.status === 'Trial' ? (
                    <>
                      <Typography
                        variant='body1'
                        style={{
                          textDecoration: 'line-through',
                          marginRight: 5,
                        }}
                      >
                        {`€${pricing?.amountBeforeDiscount?.toFixed(2)}`}
                      </Typography>
                      <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                        {subscriptionDetails.coupon
                          ? `€${pricing?.amount?.toFixed(2)}`
                          : (0).toFixed(2)}
                      </Typography>
                    </>
                  ) : (
                    <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                      {`€${pricing?.amount?.toFixed(2)}`}
                    </Typography>
                  )}
                  <ConditionalRender
                    renderValue={
                      !!subscriptionDetails?.coupon || subscriptionDetails.status === 'Trial'
                    }
                  >
                    <Tooltip
                      title={
                        subscriptionDetails?.coupon
                          ? 'You have an activated coupon!'
                          : 'You are in Trial'
                      }
                    >
                      <InfoIcon className={classes.infoIcon} />
                    </Tooltip>
                  </ConditionalRender>
                </div>
              </Grid>
            </CardContent>
          </Card>
          <div className={classes.buttonContainer}>
            <TextButton
              onClick={stepBackwards}
              label={translate('ra.buttons.prev')}
              className={classes.previousButton}
            />
            <ButtonWithLoader
              label={translate('ra.buttons.activate')}
              justifyContent='flex-end'
              onClick={finish}
              disabled={!subscriptionDetails?.city || !subscriptionDetails?.firstName}
            />
          </div>
        </>
      )}
    </>
  )
}

BillingAddonConfirmation.propTypes = {
  billingAddon: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pricing: PropTypes.string.isRequired,
    seats: PropTypes.number,
    enabled: PropTypes.bool.isRequired,
    userActivatable: PropTypes.bool.isRequired,
    pricingModel: PropTypes.string,
    unitPrice: PropTypes.number,
  }).isRequired,
  usersToInvite: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string.isRequired,
      logoUrl: PropTypes.string,
      roles: PropTypes.object.isRequired,
      scopes: PropTypes.arrayOf(PropTypes.string).isRequired,
      orgRole: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isAdmin: PropTypes.bool,
      isOwner: PropTypes.bool,
      status: PropTypes.string,
      receiverEmail: PropTypes.string,
    }),
  ).isRequired,
  subscriptionDetails: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    totalSeats: PropTypes.number,
    allocatedSeats: PropTypes.number,
    availableSeats: PropTypes.number,
    nextBillingDate: PropTypes.string,
    vivaPaymentOrder: PropTypes.any,
    vivaPaymentOrderInitialTransactionId: PropTypes.any,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    streetNumber: PropTypes.string,
    zipCode: PropTypes.string,
    country: PropTypes.string,
    companyName: PropTypes.string,
    vatNumber: PropTypes.string,
    activity: PropTypes.string,
    needsInvoice: PropTypes.bool,
    addons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        pricing: PropTypes.string,
        seats: PropTypes.shape({
          all: PropTypes.number,
          allocated: PropTypes.number,
          free: PropTypes.number,
        }),
        enabled: PropTypes.bool,
        userActivatable: PropTypes.bool,
      }),
    ),
    cardFourLastDigits: PropTypes.string,
    coupon: PropTypes.any,
  }),
  setPaymentSuccess: PropTypes.func,
  stepBackwards: PropTypes.func,
  finish: PropTypes.func.isRequired,
}

export default BillingAddonConfirmation
