import COLORS from '../../utils/constants/colors'

const expandableTextStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: { display: 'flex', alignItems: 'center', width: '100%', cursor: 'pointer' },
  headerText: { color: COLORS.lightGrey },
  icon: {
    color: COLORS.orange,
  },
  message: {
    marginLeft: '40px',
  },
}

export default expandableTextStyles
