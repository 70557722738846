import React from 'react'
import { makeStyles } from '@material-ui/core'
import CropRotateIcon from '@material-ui/icons/CropRotate'

const MyCropRotateIcon = (props) => {
  const useStyles = makeStyles({
    leftIcon: {
      cursor: 'pointer',
    },
  })
  const classes = useStyles()

  return <CropRotateIcon {...props} classes={{ root: classes.leftIcon }} />
}

export default MyCropRotateIcon
