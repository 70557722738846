import COLORS from '../../utils/constants/colors'

const practicePlanShowStyles = {
  appbar: {
    display: 'flex',
    alignItems: 'center',
  },
  appBarPracticePlanName: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  appBarButtons: {
    marginLeft: 'auto',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  orangeIcon: {
    '&&': {
      fill: COLORS.orange,
    },
  },
  pdfIcon: {
    padding: 0,
  },
}

export default practicePlanShowStyles
