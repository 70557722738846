// When component is text calculate the vertical margin for minutes chip based on notes text length.
export const textComponentCalculateMinutesMargin = (notesText, widthBeforeLineBreak) => {
  const TEXT_LINE_HEIGHT = 10
  const TEXT_MARGIN = 10
  const SMALL_HEIGHT_CONTENT = 20

  const notesHeight =
    Math.ceil(notesText?.length <= 0 ? 1 : notesText.length / widthBeforeLineBreak) *
      TEXT_LINE_HEIGHT +
    2 * TEXT_MARGIN

  const verticalMargin = notesHeight / 2

  // If the content is too small.
  if (verticalMargin < SMALL_HEIGHT_CONTENT) {
    return [0, 10, 0, 10]
  }

  return [0, verticalMargin, 0, 0]
}

// When component is drill calculate the vertical margin for minutes chip based on notes, title, description text length.
export const drillComponentCalculateMinutesMargin = (
  notesText,
  title,
  description,
  designs,
  widthBeforeLineBreak,
) => {
  const TEXT_LINE_HEIGHT = 10
  const TITLE_TEXT_LINE_HEIGHT = 15
  const TEXT_MARGIN = 5
  const DESIGN_HEIGHT = 70
  const HALF_DESIGN_HEIGHT = 30
  const SMALL_HEIGHT_CONTENT = 20
  let verticalMargin

  const splitIntoBatches = (arr, size) => {
    const result = []

    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size))
    }

    return result
  }

  const calcDesignsHeight = (designsArray) => {
    const BATCH_SIZE = 4
    const MARGIN_TOP = 10
    const batchedDesigns = splitIntoBatches(designsArray, BATCH_SIZE)
    let designsHeight = 0

    batchedDesigns.forEach((batchedDesign) => {
      let isFullCourtFlag = false

      batchedDesign.forEach((design) => {
        const { isZoomedIn } = JSON.parse(design.source)
        if (!isZoomedIn) isFullCourtFlag = true
      })

      designsHeight += isFullCourtFlag ? DESIGN_HEIGHT : HALF_DESIGN_HEIGHT
    })

    // Also add height for every design marginTop and subtract the first row of drills.
    designsHeight += (batchedDesigns?.length ?? 0) * MARGIN_TOP - 10

    return designsHeight
  }

  // Calculate notes Height
  const notesHeight =
    !notesText || notesText?.length <= 0
      ? 0
      : Math.ceil(notesText.length / widthBeforeLineBreak) * TEXT_LINE_HEIGHT + 2 * TEXT_MARGIN

  // Calculate title Height
  const titleHeight =
    !title || title?.length <= 0
      ? 0
      : Math.ceil(title.length / widthBeforeLineBreak) * TITLE_TEXT_LINE_HEIGHT + 2 * TEXT_MARGIN

  // Calculate description Height
  const descriptionHeight =
    !description || description?.length <= 0
      ? 0
      : Math.ceil(description.length / widthBeforeLineBreak) * TEXT_LINE_HEIGHT + 2 * TEXT_MARGIN

  // Calculate designs Height
  const designsHeight = !designs || designs?.length <= 0 ? 0 : calcDesignsHeight(designs)

  if (notesHeight > titleHeight + descriptionHeight) {
    verticalMargin = notesHeight / 2 + designsHeight
  } else {
    verticalMargin = (titleHeight + descriptionHeight) / 2 + designsHeight
  }

  // If the content is too small.
  if (verticalMargin < SMALL_HEIGHT_CONTENT) {
    return [0, 10, 0, 10]
  }

  return [0, verticalMargin, 0, 0]
}

// Create minutes circle chip
export const minutesTextWithCircleBackground = (text) => {
  if (!text?.length)
    return {
      text: '',
    }

  const RADIUS = 22
  const textLength = text.length
  let xPos = 8

  if (textLength > 1) {
    xPos = 5
  }

  return {
    stack: [
      {
        stack: [
          {
            text,
            style: 'minutesTableNumber',
            relativePosition: { x: xPos, y: 3 },
            textAlign: 'center',
            width: RADIUS,
          },
          {
            text: 'min',
            style: 'minutesTableText',
            relativePosition: { x: 6, y: 13 },
            width: RADIUS,
          },
        ],
      },
      {
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: RADIUS,
            h: RADIUS,
            r: RADIUS,
            color: '#cbcbcb',
            relativePosition: { x: 0, y: 0 },
            fillOpacity: 0.4,
          },
        ],
      },
    ],
  }
}

// // Create simple text square chip
export const textWithSquareBackground = (text, availableWidth, onlyText = false) => {
  if (!text?.length)
    return {
      text: '',
    }

  const widthBeforeLineBreak = onlyText ? 220 : 110
  const TEXT_LINE_HEIGHT = 14
  const TEXT_MARGIN = 1
  const CANVAS_HEIGHT =
    Math.ceil(text.length / widthBeforeLineBreak) * TEXT_LINE_HEIGHT + 2 * TEXT_MARGIN

  return {
    stack: [
      {
        text,
        relativePosition: { x: 0, y: TEXT_MARGIN },
        margin: [4, 0, 4, 0],
      },
      {
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: availableWidth,
            h: CANVAS_HEIGHT,
            r: 8,
            color: '#cbcbcb',
            relativePositionPosition: { x: 0, y: 0 },
            fillOpacity: 0.4,
          },
        ],
      },
    ],
  }
}
