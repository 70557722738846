import { CARD_STYLES } from '../../../utils/constants/CSS/cardStyles'

const courtsPageStyles = {
  card: CARD_STYLES.card,
  cardHeaderRoot: {
    paddingBottom: 0,
  },
  cardTitle: {
    marginBottom: 12,
    marginLeft: 10,
  },
  cardSubtitle: {
    ...CARD_STYLES.cardSubtitle,
  },
  divider: CARD_STYLES.divider,
  addButtonWrapper: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: 15,
  },
  bodyWrapper: {
    padding: '1% 2.5%',
  },
}

export default courtsPageStyles
