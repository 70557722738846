import COLORS from '../../utils/constants/colors'

const livestreamBannerStyles = {
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    maxWidth: '20%',
    minWidth: '250px',
    height: 'auto',
    minHeight: '6%',
    borderRadius: '10px',
    flexDirection: 'column',
    backgroundColor: COLORS.lightBlack,
    boxShadow: '0 4px 4px rgb(0 0 0 / 33%)',
    zIndex: 10000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'auto',
  },
  upperColumn: {
    padding: '12px',
    width: '99%',
  },
  liveWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    cursor: 'all-scroll',
  },
  antennaIcon: {
    width: 22,
    height: 22,
    padding: '0 3px',
  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
    cursor: 'auto',
  },
  liveContainer: {
    paddingTop: 10,
  },
  live: {
    color: COLORS.white,
    fontWeight: 450,
  },
  livestreamState: {
    color: COLORS.darkerOrange,
    fontSize: 12,
    fontWeight: 500,
    cursor: 'pointer',
  },
  viewDetails: {
    marginLeft: 5,
    color: COLORS.darkerOrange,
    fontSize: 12,
    fontWeight: 500,
    cursor: 'pointer',
  },
  dragIcon: {
    '&&': {
      fill: COLORS.white,
    },
  },
  myTextButton: {
    color: COLORS.darkerOrange,
    fontSize: 10,
    height: 15,
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export default livestreamBannerStyles
