import axiosInstance from '../axiosInterceptor/axiosInstance'

const addDrill = (data) => {
  const url = 'training-modules'
  const { values } = data

  return axiosInstance.post(url, values)
}

export default addDrill
