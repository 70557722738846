import CancelIcon from '@material-ui/icons/Cancel'
import { makeStyles } from '@material-ui/core'
import COLORS from '../../utils/constants/colors'

const MyCancelIcon = (props) => {
  const useStyles = makeStyles({
    root: {
      width: '22px',
      height: '22px',
      cursor: 'pointer',
      fill: COLORS.orange,
    },
  })
  const classes = useStyles()

  return <CancelIcon {...props} classes={{ root: classes.root }} />
}

export default MyCancelIcon
