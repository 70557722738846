import * as React from 'react'
import SignUpForm from './SignUpForm/SignUpForm'
import NoLayoutContainer from '../../components/layout/NoLayoutContainer/NoLayoutContainer'

const SignUpPage = () => (
  <NoLayoutContainer>
    <SignUpForm />
  </NoLayoutContainer>
)

export default SignUpPage
