import {
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import React from 'react'
import { useTranslate } from 'react-admin'
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender'
import COLORS from '../../utils/constants/colors'
import QueryKeys from '../../utils/constants/queryKeys'
import ContainerPadding from '../../components/containerPadding/containerPadding'
import LoaderDots from '../../components/loaders/LoaderDots/LoaderDots'
import ConnectionErrorPage from '../ErrorInConnection/ConnectionErrorPage'
import mainSubscriptionTabStyles from './MainSubscriptionTab/mainSubscriptionTab.styles'
import dateFormatter from '../../utils/helpers/dateHelpers/dateFormatter'
import getBillingSummary from '../../Apis/subscriptions/getBillingSummary'

const useBillingSummaryStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      padding: '2px 10px',
    },
    '& .MuiTableRow-root': {
      height: '24px',
    },
  },
})

const BillingSummary = ({ nextBillingDate, orgId }) => {
  const translate = useTranslate()
  const useStyles = makeStyles(mainSubscriptionTabStyles)
  const billingSummaryClasses = useBillingSummaryStyles()
  const classes = useStyles()

  const {
    data: billingSummary,
    isSummaryLoading,
    errorSummary,
  } = useQuery([QueryKeys.GET_BILLING_SUMMARY], () => getBillingSummary({ orgId }), {
    enabled: Boolean(orgId),
  })

  const billingDate = nextBillingDate ? dateFormatter(nextBillingDate) : null

  if (errorSummary || !orgId) {
    return <ConnectionErrorPage />
  }

  return (
    <>
      {isSummaryLoading ? (
        <ContainerPadding paddingValue='45%'>
          <LoaderDots
            style={{
              fill: COLORS.orange,
              width: 70,
              height: 70,
            }}
          />
        </ContainerPadding>
      ) : (
        <Table className={`${classes.table} ${billingSummaryClasses.table}`}>
          <TableHead>
            <ConditionalRender
              renderValue={billingSummary && billingSummary?.lineItems.length >= 0}
            >
              <TableRow style={{ backgroundColor: COLORS.opacBlue }}>
                <TableCell></TableCell>
                <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                  {translate('ra.text.addonDes')}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {translate('ra.text.totalPrice')}
                </TableCell>
              </TableRow>
            </ConditionalRender>
          </TableHead>
          <TableBody>
            {billingSummary?.lineItems.map((item) => (
              <TableRow key={item.name}>
                <TableCell style={{ border: 'none' }}>
                  {item.name === 'Coach' ? 'Coaching' : item.name}
                </TableCell>
                <TableCell colSpan={2} style={{ textAlign: 'center', border: 'none' }}>
                  {item.description}
                </TableCell>
                <TableCell style={{ textAlign: 'center', border: 'none' }}>
                  {item.totalAmount ? `€${item.totalAmount?.toFixed(2)}` : '-'}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3} style={{ borderTop: '1px solid #d3d3d3' }}>
                {billingDate && `${translate('ra.text.nextBillingDate')}: ${billingDate}`}
              </TableCell>
              <TableCell
                colSpan={1}
                style={{ textAlign: 'center', borderTop: '1px solid #d3d3d3' }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  €{billingSummary?.totalAmount.toFixed(2)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  )
}

BillingSummary.propTypes = {
  nextBillingDate: PropTypes.string,
  orgId: PropTypes.string,
}

export default BillingSummary
