import { Field, FieldArray, useFormikContext } from 'formik'
import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import { useTranslate } from 'react-admin'
import { v4 } from 'uuid'
import MyAutocomplete from '../MyAutocomplete'
import COLORS from '../../../../utils/constants/colors'
import AddButton from '../../../ui/AddButton/AddButton'
import myAdjustableFieldsStyles from './myAdjustableFields.styles'

const MyAdjustableFields = ({
  ParentListIndex,
  ParentList,
  parentField,
  fieldsNumber,
  labels,
  keys,
  options,
  isFirstRequired,
}) => {
  // TODO: Needs a quite good refactor!
  // must be: {ParentList: [..., parentField: [{Field1,Field2,...}, {...},{...}]]}
  const { values } = useFormikContext()
  const translate = useTranslate()
  const noOptionsLeft = !options.some((option) => option.length === 0)

  const useStyles = makeStyles(myAdjustableFieldsStyles)
  const classes = useStyles()

  const createNewRow = () => {
    const addedRow = { key: v4() }
    keys.forEach((key) => (addedRow[key] = ''))

    return addedRow
  }

  if (ParentList !== undefined) {
    return (
      <FieldArray
        name={`${ParentList}[${ParentListIndex}].${parentField}`}
        render={(arrayHelpers) => {
          return (
            <>
              <div
                className={classes.fields}
                style={{ marginBottom: !noOptionsLeft ? '20px' : '' }}
              >
                {values[ParentList].length > 0 &&
                  values[ParentList][ParentListIndex][parentField].map((role, index) => {
                    const fields = []
                    for (let i = 0; i < fieldsNumber; i++) {
                      fields.push(
                        <Field
                          className={i === 0 ? classes.firstRowItem : classes.secondRowItem}
                          key={values[ParentList][ParentListIndex][parentField][index]?.key + i}
                          style={isFirstRequired && index === 0 ? { width: '49%' } : {}}
                          name={`${ParentList}[${ParentListIndex}].${parentField}[${index}].${keys[i]}`}
                          label={labels[i]}
                          variant='filled'
                          options={options[i]}
                          autoSelect={false}
                          component={MyAutocomplete}
                          getOptionLabel={(option) => option?.name || option}
                          getOptionSelected={(option) => option}
                          value={values[ParentList][ParentListIndex][parentField][index][keys[i]]}
                          required={
                            (isFirstRequired && index === 0) || // sets required if it is the first row or in the rest rows, if there has been at least one value inserted
                            Object.values(
                              values[ParentList][ParentListIndex][parentField][index],
                            ).filter((value) => value !== null).length > 1
                          }
                        />,
                      )
                    }

                    return (
                      <div
                        key={values[ParentList][ParentListIndex][parentField][index].key}
                        className={classes.row}
                      >
                        {fields}
                        {isFirstRequired && index > 0 && (
                          <CancelIcon
                            onClick={() => arrayHelpers.remove(index)}
                            style={{
                              fill: COLORS.orange,
                              cursor: 'pointer',
                            }}
                          />
                        )}
                      </div>
                    )
                  })}
              </div>
              {noOptionsLeft && (
                <AddButton
                  className={classes.addMoreButton}
                  label={translate('ra.buttons.addRole')}
                  onClick={() => arrayHelpers.push(createNewRow())}
                  filled
                />
              )}
            </>
          )
        }}
      />
    )
  } else {
    return (
      <FieldArray
        name={parentField}
        render={(arrayHelpers) => {
          return (
            <>
              <div className={classes.fields}>
                {values[parentField].map((role, index) => {
                  const fields = []
                  // eslint-disable-next-line no-plusplus
                  for (let i = 0; i < fieldsNumber; i++) {
                    fields.push(
                      <Field
                        className={i === 0 ? classes.firstRowItem : classes.secondRowItem}
                        style={isFirstRequired && index === 0 ? { width: '50%' } : {}}
                        key={values[parentField][index]?.key + i}
                        name={`${parentField}[${index}].${keys[i]}`}
                        label={labels[i]}
                        variant='filled'
                        options={options[i]}
                        autoSelect={false}
                        component={MyAutocomplete}
                        getOptionLabel={(option) => option?.name || option}
                        getOptionSelected={(option) => option}
                        value={values[parentField][index][keys[i]]}
                        required={
                          (isFirstRequired && index === 0) || // sets required if it is the first row or in the rest rows, if there has been at least one value inserted
                          Object.values(values[parentField][index]).filter(
                            (value) => value !== null,
                          ).length > 1
                        }
                      />,
                    )
                  }

                  return (
                    <div key={values[parentField][index].key} className={classes.row}>
                      {fields}
                      {isFirstRequired && index > 0 && (
                        <CancelIcon
                          onClick={() => arrayHelpers.remove(index)}
                          style={{
                            fill: COLORS.orange,
                            cursor: 'pointer',
                          }}
                        />
                      )}
                    </div>
                  )
                })}
              </div>
              {noOptionsLeft && (
                <AddButton
                  className={classes.addMoreButton}
                  label={translate('ra.buttons.addRole')}
                  onClick={() => arrayHelpers.push(createNewRow())}
                  filled
                />
              )}
            </>
          )
        }}
      />
    )
  }
}

export default MyAdjustableFields
