import React from 'react'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'
import connectionErrorPageStyles from './connectionErrorPageStyles'
import COLORS from '../../utils/constants/colors'

const ConnectionErrorPage = () => {
  const translate = useTranslate()
  const useStyles = makeStyles(connectionErrorPageStyles)
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <SentimentDissatisfiedIcon
        style={{
          width: '40%',
          height: '40%',
          fill: COLORS.orange,
          opacity: 0.5,
        }}
      />
      <div>{translate('ra.errorMessages.connectionError')}</div>
    </div>
  )
}

export default ConnectionErrorPage
