import getDrill from '../../../../Apis/drill/getDrill'

const drillBreadcrumbEdit = async (data) => {
  const { match } = data || {}
  const { drillId } = match.params

  if (drillId === 'create') return

  const drillTitle = await getDrill(drillId).then((drill) => drill.title)

  return ['DRILLS', drillTitle ? drillTitle.toUpperCase() : '']
}

export default drillBreadcrumbEdit
