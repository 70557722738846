import { useState } from 'react'

const useTimer = (timeLimit) => {
  const [timeElapsed, setTimeElapsed] = useState(false)
  const [timeoutId, setTimeoutId] = useState()

  const timeout = () => {
    return setTimeout(() => {
      setTimeElapsed(true)
    }, timeLimit)
  }

  const resetTimer = () => {
    clearTimeout(timeoutId)
    setTimeoutId(timeout())
    setTimeElapsed(false)
  }

  return {
    timeElapsed,
    resetTimer,
  }
}

export default useTimer
