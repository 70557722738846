import COLORS from '../../../../utils/constants/colors'

const playerEditStyles = (theme) => ({
  simpleForm: {
    paddingLeft: '4%',
    paddingRight: '4%',
  },
  chip: {
    color: COLORS.white,
    backgroundColor: `${COLORS.orange}!important`,
    '& .MuiSvgIcon-root': {
      fill: COLORS.bisque,
    },
  },
  root: {
    minHeight: '100%',
  },
  main: {
    minHeight: '100%',
    marginTop: 0,
  },
  text: {
    display: 'block',
    fontSize: '0.9rem',
  },
  arrayInputJersey: {
    marginTop: 0,
    marginBlock: 0,
    marginBottom: 20,
  },
  dateField: {
    [theme.breakpoints.down(1150)]: {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
})

export default playerEditStyles
