import designsRowPdfHelper from '../../../utils/helpers/pdfHelpers/designsRowPdfHelper'
import {
  minutesTextWithCircleBackground,
  textWithSquareBackground,
} from '../../../utils/helpers/pdfHelpers/practicePlanPdfHelper'
import Stadium from '../../../assets/images/FullCourt.png'
import HalfStadium from '../../../assets/images/HalfCourt.png'

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = src
    image.onload = () => resolve(image)
    image.onerror = reject
  })
}

const practicePlanPdfContent = async (data) => {
  const { practicePlan } = data
  const { practicePlanComponents } = practicePlan || {}

  const stadiumImage = await loadImage(Stadium)
  const halfStadiumImage = await loadImage(HalfStadium)

  const content = []
  const maxPageWords = 3200
  const maxOnlyTextTimeWidth = 15
  const maxOnlyTextNotesWidth = 540
  const maxTableWidth = 555
  const designWidth = 96.5
  const maxDesignWidth = 300

  practicePlanComponents?.forEach((practicePlanComponent) => {
    const { trainingModule, duration, notes } = practicePlanComponent || {}
    const { designs, description, title } = trainingModule || {}

    // Text Component
    if (!trainingModule) {
      content.push({
        pageBreak: content.length > 0 && notes.length >= maxPageWords ? 'before' : '',
        unbreakable: notes.length < maxPageWords,
        table: {
          widths: [maxOnlyTextTimeWidth, maxOnlyTextNotesWidth],
          dontBreakRows: true,
          headerRows: 1,
          keepWithHeaderRows: true,
          body: [
            [
              {
                border: [true, true, false, true],
                ...minutesTextWithCircleBackground(duration?.toString()),
                style: { alignment: 'left' },
                colSpan: 1,
              },
              {
                border: [false, true, true, true],
                ...textWithSquareBackground(notes, maxOnlyTextNotesWidth, true),
                style: 'notesText',
                colSpan: 1,
              },
            ],
          ],
        },
      })
    }

    if (trainingModule) {
      content.push({
        pageBreak:
          content.length > 0 && notes.length + description.length >= maxPageWords ? 'before' : '',
        unbreakable: notes.length + description.length < maxPageWords,
        table: {
          widths: [maxTableWidth / 2, maxTableWidth / 2],
          body: [
            [
              {
                border: [true, true, false, false],
                ...minutesTextWithCircleBackground(duration?.toString()),
                style: { alignment: 'left' },
              },
              {
                border: [false, true, true, false],
                text: title,
                style: 'titleText',
              },
            ],
            [
              {
                stack: [
                  {
                    text: description,
                    style: 'descriptionText',
                    colSpan: 1,
                    rowSpan: 1,
                  },
                  notes.length > 0
                    ? {
                        ...textWithSquareBackground(notes, maxTableWidth / 2),
                        style: 'notesText',
                        rowSpan: 1,
                      }
                    : {},
                ],
                border: [true, false, false, true],
              },
              {
                border: [false, false, true, true],
                stack: designsRowPdfHelper(
                  designs,
                  designWidth,
                  maxDesignWidth,
                  stadiumImage,
                  halfStadiumImage,
                ),
                colSpan: 1,
                rowSpan: 1,
              },
            ],
          ],
        },
      })
    }
  })
  return content
}

export default practicePlanPdfContent
