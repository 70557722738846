import TERMS_AND_PRIVACY_URLS from '../utils/constants/termsAndPrivacyUrls'
import VIDEO_SIZE from '../utils/constants/uploadFileSize'

const el = {
  ra: {
    notification: {
      genericError: 'Ουπς, κάτι πήγε στραβά',
      loginError: 'Κάτι πήγε στραβά, παρακαλώ ξαναπροσπαθήστε',
      deleteSuccess: 'Διαγράφηκε επιτυχώς',
      deleteError: 'Προέκυψε κάποιο σφάλμα κατά τη διαγραφή',
      created: 'Δημιουργήθηκε Επιτυχώς',
      updated: 'Ενημερώθηκε Επιτυχώς',
      updatedError: 'Προέκυψε κάποιο σφάλμα κατά την ενημέρωση',
      item_doesnt_exist: 'Το στοιχείο δεν υπάρχει πλέον',
      noNotifications: 'Δεν Υπάρχουν Ειδοποιήσεις.',
      invitation: 'Πρόσκληση',
      billing: 'Tιμολόγηση',
      serverError: 'Αδυναμία φόρτωσης ειδοποιήσεων.',
    },
    invitation: {
      pending: 'Έχετε μια εκκρεμή πρόσκληση για να εγγραφείτε στον σύλλογο',
    },
    billing: {
      billingPeriodEnding: '',
      billingPeriodEnded: '',
    },
    validationErrors: {
      inValidEmail: 'Παρακαλώ, εισάγετε ένα έγκυρο e-mail',
      password: 'Πρέπει να περιέχει 6 χαρακτήρες, έναν κεφαλαίο, έναν πεζό, και έναν αριθμό.',
      confirmPassword: 'Οι κωδικοί πρόσβασης πρέπει να ταιριάζουν',
      terms: 'Απαιτείται αποδοχή όρων & προϋποθέσεων',
      requiredField: 'Υποχρεωτικό πεδίο',
      fromDate: 'Η ημερομηνία έναρξης ενός livestream event δεν μπορεί να είναι στο παρελθόν.',
      startTime: 'Η ώρα έναρξης ενός livestream event δεν μπορεί να είναι στο παρελθόν.',
      endTime:
        'Η ώρα λήξης ενός livestream event δεν μπορεί να έχει μικρότερη διάρκεια απο 30 λεπτά.',
      courtId: 'Το πεδίο γήπεδο είναι υποχρεωτικό σε ένα livestream event.',
      threeLetter: 'Το αναγνωριστικό ονόματος πρέπει να είναι 3 γράμματα.',
    },
    message: {
      invalid_form: 'ΜΗ ΕΓΚΥΡΗ ΦΟΡΜΑ',
      not_found: 'Η σελίδα που αναζητήσατε δεν υπάρχει',
    },
    auth: {
      logout: 'Έξοδος',
      auth_check_error: 'Authorization Error',
    },
    page: {
      dashboard: 'Πίνακας Ελέγχου',
      login: 'Σύνδεση',
      signUp: 'Εγγραφή',
      terms: 'Όροι & Προϋποθέσεις',
      forgotPassword: 'Ξέχασα τον κωδικό μου',
      resetPassword: 'Επαναφορά κωδικού',
      profile: 'Προσωπικά Στοιχεία',
      orgSettings: 'Στοιχεία του συλλόγου',
      security: 'Ασφάλεια',
      preferences: 'Ρυθμίσεις',
      addOrg: 'Προσθήκη Συλλόγου',
      edit: 'ΕΠΕΞΕΡΓΑΣΙΑ',
      list: 'ΛΙΣΤΑ',
      create: 'ΔΗΜΙΟΥΡΓΙΑ',
      not_found: 'Η σελίδα δεν βρέθηκε',
      show: 'ΠΡΟΒΟΛΗ',
      yourPlan: 'ΠΛΑΝΟ',
      payments: 'ΠΛΗΡΩΜΕΣ',
      billingDetails: 'ΛΕΠΤΟΜΕΡΕΙΕΣ ΧΡΕΩΣΗΣ',
    },
    text: {
      team1: 'Ομάδα 1',
      team2: 'Ομάδα 2',
      teamsOfThree: 'Ομάδες των τριων',
      teamsOfFour: 'Ομάδες των τεσσάρων',
      teamsOfFive: 'Ομάδες των πέντε',
      playersInPractice: 'Παίκτες στην προπόνηση.',
      teamsInPractice: 'Ομάδες στην προπόνηση.',
      players: 'ΠΑΙΚΤΕΣ',
      selectedPlayers: 'Παίκτες',
      downloadRecording: 'Κατέβασμα βίντεο',
      notMobileOptimised: 'Η ιστοσελίδα δεν είναι ακόμα βελτιστοποιημένη για κινητές συσκευές.',
      notVerticalOptimised:
        'Η ιστοσελίδα δεν είναι ακόμα βελτιστοποιημένη για χρήση σε κάθετη συγσκευή, παρακαλώ γυρίστε την συσκευή σας οριζόντια.',
      tryDesktop: 'Δοκιμάστε την σε υπολογιστή ή tablet!',
      emailConfirm: 'Παρακαλώ πατήστε το κουμπί για να επιβεβαιώσετε το email',
      feelGame: 'Έλα σε επαφή με το παιχνίδι',
      createOrg: 'Δημιούργησε τον πρώτο σου σύλλογο',
      orgAvatar: 'Εικονίδιο συλλόγου',
      TeamAvatar: 'Εικονίδιο Ομάδας',
      deleteEventTitle: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το event?',
      deleteEventSubtitle: 'Αυτή η ενέργεια θα διαγράψει το event"". Θέλετε να προχωρήσετε;',
      noPractices: 'Δεν βρέθηκαν προπονήσεις για τις επιλεγμένες ημερομηνίες!',
      deletePractice: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτήν την Προπόνηση?',
      deletePracticePlan: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το Πλάνο Προπόνησης?',
      description: 'Περιγραφή',
      publish: 'Δημοσιεύμενο',
      tags: 'Ετικέτες',
      viewMore: 'ΔΕΙΤΕ ΠΕΡΙΣΣΟΤΕΡΑ',
      deleteDrill: 'Είστε σίγουροι ότι θέλετε να διαγράψετε την άσκηση',
      removeDrill: 'Είστε σίγουροι ότι θέλετε να αφαιρέσετε την άσκηση',
      backToOrg: 'Πίσω στο Organisation',
      text: 'Κείμενο',
      drill: 'Άσκηση',
      createNewDrill: 'Δημιουργία Καινούργιας Άσκησης',
      noTrainingModulesFound: 'Δεν βρέθηκαν ασκήσεις!',
      createNew: 'Δημιουργία',
      minutes: 'Λεπτά',
      activity: 'Δραστηριότητα',
      notes: 'Σημειώσεις',
      removePlan: 'Αφαίρεση Πλάνου',
      min: 'min',
      practicePlans: 'Πλάνο Προπόνησης',
      noPracticePlans: 'Δεν βρέθηκαν πλάνα προπόνησης',
      addPracticePlan: 'Πρόσθεστε Πλάνο Προπόνησης',
      practicePlanTopic: 'Θέμα Πλάνου Προπόνησης',
      tacticsGroups: 'ΟΜΑΔΕΣ ΤΑΚΤΙΚΩΝ',
      drillUpdateMode: 'Θέλετε να αλλάξετε την άσκηση μόνο στο συγκεκριμένο πλάνο προπόνησης',
      changeEmail: 'Τροποποίηση email',
      insertNewEmail: 'Εισάγετε το καινούργιο email και το κωδικό πρόσβασης',
      changePhoneNumber: 'Τροποποίηση αριθμού τηλεφώνου',
      insertNewPhoneNumber: 'Εισάγετε το καινούργιο αριθμό τηλεφώνου και το κωδικό πρόσβασης',
      disableAccount: 'Είστε σίγουροι ότι θέλετε να κλείσετε τον λογαριασμό σας;',
      insertPassword: 'Εισάγετε το κωδικό πρόσβασης για να κλείσετε τον λογαριασμό σας',
      closeAccount: 'Απενεργοποίση λογαριασμού',
      disableAccountDataLost: 'Εάν κλείσετε τον λογαριασμό σας, όλα τα δεδομένα θα διαγραφούν',
      deleteOrg: 'Είστε σίγουροι ότι θέλετε να γίνει διαγραφή του συλλόγου;',
      deleteOrgName: 'Παρακαλώ γράψτε το όνομα του συλλόγου που θέλετε να διαγράψετε',
      deleteTeam: 'Είστε σίγουροι ότι θέλετε να γίνει διαγραφή της ομάδας;',
      deleteTeamName: 'Παρακαλώ γράψτε το όνομα της ομάδας που θέλετε να διαγράψετε',
      pendingEmail: 'Email(s) σε εκκρεμότητα',
      forgotPassword:
        'Παρακαλώ γράψτε το email σας για να λάβετε ένα σύνδεσμο επαναφοράς κωδικού πρόσβασης',
      noCurrentTeams: 'Δεν υπάρχουν διαθέσιμες ομάδες',
      graphics: 'Συστήματα',
      courts: 'Γήπεδα',
      courtsList: 'Λίστα των γηπέδων σας',
      orgUsersList: 'Λίστα μελών του συλλόγου και εκκρεμών προσκλήσεων',
      invitationsList: 'Λίστα των εκκρεμών προσκλήσεων σου',
      emptyCourts: 'Δεν υπάρχουν διαθέσιμα γήπεδα',
      emailSent: 'Σας έχει σταλεί email επιβεβαίωσης!',
      deleteOrgInfo: 'Σβήνοντας το συλλόγου διαγράφονται όλες οι ομάδες και όλα τα μέλη.',
      passOwnershipInfo:
        'Μεταβιβάζοντας την ιδιοκτησία του συλλόγου, μεταβιβάζετε όλα τα δικαιώματα επεξεργασία χρηστών και πληρωμών σε άλλο μέλος του συλλόγου.',
      passOwnershipSuccess: 'Η αίτηση στάλθηκε! Περιμένετε για τον χρήστη να την αποδεκτεί.',
      managePlayers: 'Διαχειριστείτε τα μέλη της ομάδας σας',
      stopLivestream: 'Είστε σίγουρος ότι θέλετε να σταματήσετε το τρέχον livestream;',
      createOrgAndTeam: 'Τι είδους είναι ο οργανισμός σας;',
      addTeamText: 'Δημιουργήστε Ομάδα',
      recent: 'ΠΡΟΣΦΑΤΑ',
      request: 'ΑΙΤΗΣΗ',
      teamSettings: 'Ρυθμίσεις ομάδας',
      manageOrgTeams:
        'Διαχειριστείτε τις ομάδες του συλλόγου σας, τις πληροφορίες και τις κατηγορίες τους',
      noRecentLivestream: 'Δεν υπάρχουν πρόσφατα livestreams!',
      coachCorner: "COACH'S CORNER",
      inviteUsers: 'Προσάλεσε χρήστες',
      setRoles: 'Ορισμός ρόλων',
      deleteOrgUser: 'είστε σίγουρος ότι θέλετε να αφαιρέσετε αυτόν τον χρήστη από το σύλλογο;',
      doubleInviteError: 'Δεν μπορείτε να προσθέσετε το ίδιο email δυο φορές!',
      deleteInvitationConfirmation:
        'Είστε σίγουρος ότι θέλετε να αρνηθείτε την πρόσκληση από τον χρήστη ',
      passOwnership: 'Μεταβίβαση ιδιοκτησίας του συλλόγου',
      paymentSuccess: 'Επιτυχής Πληρωμή',
      changeCardSuccess: 'Επιτυχής αλλαγή κάρτας',
      paymentFail: 'Αποτυχία Πληρωμής',
      changeCardFail: 'Αποτυχία αλλαγής κάρτας',
      userPricePerMonth: 'ανά χρήστη/μήνα',
      addingNewUser: 'Προσθήκη νέου χρήστη;',
      addingNewUserText:
        'Οποιοσδήποτε νέος χρήστης θα προστεθεί με μια φορά χρέωση βασισμένη στο υπόλοιπο του κύκλου χρέωσης σας.',
      removingUser: 'Αφαίρεση χρήστη;',
      removingUserText:
        'Με την αφαίρεση ενός χρήστη, η θέση θα παραμείνει «Ανοιχτή» στο σύλλογο σας μέχρι το τέλος του κύκλου πληρωμής. Αν έχετε «Ανοιχτή» θέση κατά την πρόσθεση ενός νέου χρήστη, δεν θα χρεωθείτε.',
      getInvoice: 'Πως μπορώ να λάβω τιμολόγιο;',
      getInvoiceText:
        'Αν έχετε προσθέσει όλες τις απαραίτητες λεπτομέριες, θα λάβετε τιμολόγιο εντός 24 ωρών μέσω email.',
      contactYou: 'Πως μπορώ να επικοινωνήσω μαζί σας;',
      contactYouText:
        'Για οποιαδίποτε πληροφορία σχετικά με την τιμολόγηση και τις συνδρομές, επικοινωνήστε μαζί μας στο accounting@mismatch.gr',
      orgSubscription: 'Συνδρομή',
      activateOrgSubscription: 'Τα Addons σου.',
      faq: 'Συνηθεις Ερωτήσεις',
      subscriptionSummary: 'Σύνοψη',
      orgSeats: 'Θέσεις Οργανισμού',
      activate: 'ΕΝΕΡΓΟΠΟΙΗΣΗ',
      reActivateOrgSubscription: 'Ενεργοποίηση ξανά συνδρομής στο Σύλλογου ασς',
      trial: 'Δοκιμή',
      subscription: 'Συνδρομή',
      monthly: 'Μηνιαία',
      noTransactions: 'Δεν βρέθηκαν πληρωμές!',
      addANote: 'Προσθέστε σημείωση',
      copied: 'Αντιγράφηκε',
      orgMembers: 'ΧΡΗΣΤΕΣ ΣΥΛΛΟΓΟΥ',
      uploadVideoInfo:
        'Τα βίντεο θα πρέπει να είναι αρκετά μικρα για να προβάλλονται στα διαλείμματα και το μέγεθος τους να μην υπερβαίνει τα 20Mb.',
      uploadDrillVideoInfo: `Το βίντεο θα πρέπει να είναι μικρό και το μέγεθος τους να μην υπερβαίνει τα ${VIDEO_SIZE.DRILL_VIDEO}Mb. Υποστηριζόμενα αρχεία: MP4, AVI`,

      stillUploading:
        'Υπάρχουν ακόμα video που ανεβαίνουν, αν θες να δημιουρήσεις το παιχνίδι δεν θα αποθηκευτούν!',
      completed: 'Ολοκληρώθηκε',
      addTeamFirst: 'Πρέπει πρώτα να προσθέσεις ομάδα!',
      youHave: 'Έχετε ',
      pendingInvitation: ' εκκρεμείς προσκλήσεις',
      emptyCourtsList: 'Δεν έχουν δημιουργηθεί ακόμα γήπεδα!',
      noOptionsAvailable: 'Μη διαθέσιμες επιλογές',
      createNewCourt: 'Δημιουργήστε καινούργιο γήπεδο',
      inviteByEmail: 'Ή καλέστε με email:',
      ownershipRequested: 'Έχετε προσκαλέσει τον ',
      toBecomeOwner: ' να γίνει ιδιοκτήτης του συλλόγου.',
      invitations: 'Προσκλήσεις',
      OrgUsers: 'Χρήστες ανά σύλλογο',
      apps: 'Εφαρμογές',
      manageIntegrations: 'Διαχειριστείτε τις εφαρμογές σας',
      youtubeIntegrationText:
        'Μπορείτε να συνδέσετε το σύλλογο σας με το κανάλι σας στο YouTube για να διευκολύνετε την οργάνωση livestream. Αυτή η σύνδεση θα δημιουργεί αυτόματα τα broadcast στο YouTube, για να μην χρειάζεται να το κάνετε εσείς.',
      youtubeIntegrationInfo: `Η χρήση και η μεταφορά πληροφοριών που λαμβάνονται από τις υπηρεσίες API της Google σε οποιαδήποτε άλλη εφαρμογή θα συμμορφώνεται με την <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Πολιτική Χρήστη Δεδομένων των Υπηρεσιών API της Google</a>, συμπεριλαμβανομένων των απαιτήσεων Περιορισμένης Χρήσης.`,
      linkYoutubeChannel: 'Συνδέστε κανάλι YouTube',
      unlink: 'Αποσύνδεση',
      contentChildren: 'Περιεχόμενο κατάλληλο για παιδιά:',
      promotionalContent: 'Περιέχει περιεχόμενο προώθησης',
      liveChat: 'Ζωντανή συζήτηση',
      liveChatReplay: 'Επανάληψη ζωντανής συζήτησης',
      liveChatParticipants: 'Συμμετέχοντες ζωντανής συζήτησης',
      liveChatReactions: 'Αντιδράσεις ζωντανής συζήτησης',
      useYoutube: 'Χρησιμοποίησε το κανάλι YouTube του συλλόγου',
      differentYoutubeChannel: 'Χρησιμοποιείστε κλειδί αναμετάδωσεις URI',
      enterThumbnail: 'Ανεβάστε Thumbnail του video',
      thumbnailEdit: 'Η εικόνα του βίντεο δεν πρέπει να υπερβαίνει τα 2MB',
      noPlays: 'Δεν έχετε προσθέσει ακόμα ασκήσεις!',
      commenary: 'ΖΩΝΤΑΝΟΣ ΣΧΟΛΙΑΣΜΟΣ',
      ambient: 'ΗΧΟΣ ΠΕΡΙΒΆΛΛΟΝΤΟΣ ΧΩΡΟΥ',
      streamNotStarted: 'Το stream δεν έχει αρχίσει ακόμα. Παρακαλώ περιμένετε.',
      profileSettingsSub: 'Διαχειρίσου τις προσωπικές σου πληροφορίες',
      billingDetails: 'Λεπτομέριες Πληρωμής',
      needInvoice: 'Είστε εταιρεία; Χρειάζεστε τιμολόγιο;',
      removeVideo: 'Είστε σίγουρος ότι θέλετε να αφαιρέσετε αυτό το βίντεο από το πρόγραμμα;',
      bigFileAdded: 'Το αρχείο είναι μεγαλύτερο από 20Mb',
      orgOwner:
        'Είστε ο ιδιοκτήτης του συλλόγου. Το κλείσιμο του λογαριασμού σας θα οδηγήσει στη διαγραφή όλων των συλλόγων που κατέχετε.',
      cancelSubscriptionConfirmation: 'Είστε σίγουρος ότι θέλετε να ακυρώσετε την συνδρομη σας;',
      period: 'Περίοδος',
      quarter: 'Π',
      youAreAdding: 'Προσθέτεις',
      coachingSeat: 'θέσεις προπονητή',
      at: 'για',
      month: 'μήνα',
      availableSeats: 'διαθέσιμες θέσεις',
      unauthorizedPageHeader:
        'Φαίνεται πως σε κάθισαν στον πάγκο, ας σε επιστρέψουμε στο παιχνίδι!',
      unauthorizedPageMain:
        'Επικοινώνησε με τον ιδιοκτήτη του συλλόγου για να τσεκάρεις την κατάσταση της εγγραφής ή επικοινώσησε μαζί μας.',
      unauthorizedTooltip: 'Δεν έχεις πρόσβαση σην σελίδα. Ελεγξε την συνδρομή του συλλόγου!',
      supportBubbleHeader: 'Έχετε πρόβλημα;',
      supportBubbleText:
        'Ρωτήστε μας ο,τιδήποτε ή ενημερώστε μας για τυχόν προβλήματα που αντιμετοπίζετε!',
      jerseyNumber: 'Αριθμός Φανέλας: ',
      height: 'Ύψος: ',
      positions: 'Θέσεις: ',
      games: 'Παιχνίδια: ',
      allGames: 'Όλα τα Παιχνίδια',
      lastGame: 'Τελευταίο Παιχνίδι',
      last5Games: 'Τελευταία 5 Παιχνίδια',
      last10Games: 'Τελευταία 10 Παιχνίδια',
      seasonStats: 'Στατιστικά Σεζόν',
      gameStats: 'Στατιστικά Παιχνιδιού',
      stats: 'Στατιστικά',
      navGames: 'Παιχνίδια',
      won: 'Νίκες',
      lost: 'Ήττες',
      PIR: 'Δείκτης Απόδοσης, μέτρο της συνολικής στατιστικής απόδοσης ενός παίκτη',
      GP: 'Αγώνες Που Παίχθηκαν',
      GS: 'Αγώνες Που Ξεκίνησαν',
      MIN: 'Λεπτά Που Παίχθηκαν',
      PTS: 'Πόντοι',
      '2P': 'Δίποντο',
      '3P': 'Τρίποντο',
      FT: 'Ελεύθερες Βολές',
      OR: 'Επιθετικά Ριμπάουντ',
      DR: 'Αμυντικά Ριμπάουντ',
      TR: 'Συνολικά Ριμπάουντ',
      AST: 'Ασίστ',
      STL: 'Κλεψίματα',
      TO: 'Λάθη',
      BLK: 'Κοψίματα',
      BLKA: 'Κοψίματα Κατα',
      FC: 'Φάουλ',
      FD: 'Κερδισμένα Φάουλ',
      perQuarter: 'Ανά Περίοδο',
      endOfQuarter: 'Τέλος Περιόδου',
      perSeatCost: 'Τιμή/Θέση',
      allocatedSeats: 'Κατανεμημένες Θέσεις',
      totalCost: 'Συνολική Τιμή',
      nextBillingDate: 'Επόμενη Χρέωση',
      videoActivation: 'Ενεργοποίηση Βίντεο',
      videoActivationMessage:
        'Μπορείς να ρωτήσεις οτιδήποτε θέλεις να μάθεις σχετικά με τη ροή βίντεο.',
      updateSubscriptionConfirmation: 'Πρόκειται να αναβαθμίσετε τη συνδρομή σας.',
      noActiveAddons:
        'Πρόκειται να αφαιρέσετε το τελευταίο Addon από τη συνδρομή σας. Καθώς αυτό είναι το τελευταίο Addon, η συνδρομή σας θα ακυρωθεί μέχρι να το ενεργοποιήσετε ξανά.',
      priceSeat: 'Τιμή/Θέση: ',
      addOnActivation: 'Ενεργοποίηση Add-on',
      addonInfo:
        'Από προεπιλογή, θα λάβετε αυτό το πρόσθετο με την ενεργοποίηση. Αν θέλετε, μπορείτε να επιλέξετε περισσότερους χρήστες από τη λίστα παρακάτω.',
      billingPaymentMessage:
        'Η ύπαρξη ενεργής συνδρομής θα σας οδηγήσει να πληρώσετε μόνο για τον υπόλοιπο μήνα για να ενεργοποιήσετε αυτό το addon.',
      billingPaymentMessageInvite:
        'Η ύπαρξη ενεργής συνδρομής θα σας οδηγήσει να πληρώσετε μόνο για τον υπόλοιπο μήνα για να καλέσετε χρήστες.',

      reactivate: 'ΕΠΑΝΕΝΕΡΓΟΠΟΙΗΣΗ',
      activateCoupon: 'ΕΝΕΡΓΟΠΟΙΗΣΗ ΚΟΥΠΟΝΙΟΥ',
      publishTextA: 'Παρακαλώ βεβαιωθείται πως όλα τα πεδία είναι συμπληρωμένα και το  ',
      publishTextB: ' είναι στα αγγλικά.',
      publishTextC:
        'Το Mismatch έχει το δικαίομα να επεξεργαστεί ή να το αποδημοσιεύει αν θεωρηθεί απαραίτητο',
      deactivateAddonConfirmation: 'Είστε σίγουροι ότι θέλετε να απενεργοποιήσετε αυτό το add-on;',
      mainSubText: 'Επανεγγραφείτε για να αποκτήσετε πρόσβαση στα ενεργοποιημένα addon.',
      addonType: 'Addon',
      seatsAvailable: 'Διαθέσιμες Θέσεις',
      totalPrice: 'Συνολική Τιμή',
      socialConfMvp: 'Αναρτηση MVP αγώνα',
      socialConfAnnouncement: 'Αναρτηση ανακοίνωσης αγώνα',
      socialConfQuarter: 'Αναρτηση ανακοίνωσης δεκάλεπτου αγώνα',
      socialConfResult: 'Αναρτηση ανακοίνωσης αποτελέσματος αγώνα',
      socialConfBackground: 'Φόντο ανακοινώσεων',
      noAccounts: 'Δε Βρέθηκαν Λογαριασμοί',
      share: 'Κοινοποίηση',
      copyToClipBoard: 'Η διεύθυνση URL αντιγράφηκε στο πρόχειρο!',
      didNotPlay: 'Δεν Έπαιξε',
      score: 'Ζωντανό αποτέλεσμα',
      final: 'Τελικό Αποτέλεσμα',
      continueWithFB: 'Continue with Facebook',
      metaIntegrationText:
        'You can connect your organisation with you Facebook account to automate the posting on your Facebook and Instagram feed. For every game you create, you get posts for game announcement, game mvp, game result and quarter result!',
      socialDes:
        'Το Addon αυτό θα επηρεάσει όλα τα μέλη του συλλόγου. Επομένως, δεν χρειάζεται να το προσθέσετε στους χρήστες του συλλόγου.',
      emailNotifications:
        'Η εγγραφή σας ετοιμάζεται. Σύντομα θα λάβετε ένα email με τον σύνδεσμο λήψης.',
    },
    formFields: {
      firstName: 'Όνομα',
      lastName: 'Επώνυμο',
      email: 'Διεύθυνση Email',
      phoneNumber: 'Αριθμός Τηλεφώνου',
      password: 'Κωδικός',
      oldPassword: 'Παλιός Κωδικός',
      newPassword: 'Νέος Κωδικός',
      confirmPassword: 'Επιβεβαίωση Κωδικού',
      birthday: 'Ημερομηνία Γέννησης',
      acceptTerms: `Έχω διαβάσει & αποδέχομαι τους <a style="color: #E0726E" href="${TERMS_AND_PRIVACY_URLS.TERMS_OF_USE}" target="_blank">όρους χρήσης</a> και <a style="color: #E0726E" href="${TERMS_AND_PRIVACY_URLS.PRIVACY_POLICY}" target="_blank">προστασίας προσωπικών δεδομένων</a>`,
      name: 'Όνομα',
      country: 'Χώρα',
      city: 'Πόλη',
      logo: 'Logo',
      ageGroup: 'Επίλεξε Ηλικία',
      gender: 'Επίλεξε Φύλο',
      shortName: 'Σύντομο Όνομα',
      teamShortName: 'Σύντομο όνομα ομάδας',
      teamName: 'Όνομα Ομάδας',
      teamExternalId: 'Εξωτερικό Id ομάδας',
      eventTitle: 'Όνομα event',
      from: 'Από',
      date: 'Ημερομηνία',
      to: 'Εώς',
      notes: 'Σημειώσεις',
      topic: 'Θέμα',
      place: 'Τοποθεσία',
      startTime: 'Ώρα Έναρξης',
      endTime: 'Ώρα Λήξης',
      location: 'Τοποθεσία',
      opponent: 'Αντίπαλος',
      departure: 'Αναχώρηση Από',
      arrival: 'Αφιξη Σε',
      home: 'Εντός',
      away: 'Εκτός',
      neutral: 'Ουδέτερο',
      court: 'Γήπεδο',
      gym: 'Γυμναστήριο',
      other: 'Άλλο',
      team: 'Ομάδα',
      teams: 'Ομάδες',
      role: 'Ρόλος',
      intensity: 'Ενταση',
      positions: 'Θέσεις',
      tactics: 'Τακτικές',
      title: 'Title',
      courtName: 'Όνομα Γηπέδου',
      homeTeam: 'Γηπεδούχος Ομάδα',
      awayTeam: 'Φιλοξενούμενη ομάδα',
      streamKeyURI: 'Κλειδί αναμετάδοσης URI',
      streamKeyURI2: 'Επιπρόσθετω Κλειδί αναμετάδοσης URI',
      addAdditionalStreamKey: 'Προσθεσε επιπλέον κλειδί αναμετάδοσης URI',
      youtubeVideoTitle: 'Τίτλος Youtube video',
      youtubeVisibility: 'Ορατότητα',
      orgName: 'Όνομα Συλλόγου',
      OrgRole: 'Ρόλος Συλλόγου',
      userPermissions: 'Άδειες Χρηστή',
      streamVideo: 'Ζωντανή Μετάδοση',
      recordVideo: 'Μόνο Εγγραφή Βίντεο',
      livestreamType: 'Τύπος Livestream',
      panoramic: 'Πανοραμικό',
      gameLite: 'Game Lite',
      gameFull: 'Game Full',
    },
    buttons: {
      average: 'Μέσος όρος',
      accumulate: 'Συνολικά',
      login: 'ΣΥΝΔΕΣΗ',
      signUp: 'ΕΓΓΡΑΦΗ',
      submit: 'ΕΠΙΒΕΒΑΙΩΣΗ',
      reset: 'ΕΠΑΝΑΦΟΡΑ',
      update: 'ΕΝΗΜΕΡΩΣΗ',
      save: 'Αποθήκευση',
      change: 'ΑΛΛΑΓΗ',
      disableAccount: 'ΔΙΑΓΡΑΦΗ',
      disable: 'ΑΠΕΝΕΡΓΟΠΟΙΗΣΗ',
      add: 'ΠΡΟΣΘΗΚΗ',
      addSmall: 'Προσθήκη',
      upload: 'ΑΝΕΒΑΣΜΑ',
      uploadImage: 'ΑΝΕΒΑΣΜΑ ΕΙΚΟΝΑΣ',
      create: 'ΔΗΜΙΟΥΡΓΙΑ',
      createSmall: 'Δημιουργία',
      newEvent: 'Καινούργιο Event',
      createAndNew: 'ΔΗΜΙΟΥΡΓΙΑ ΚΑΙ ΠΡΟΣΘΗΚΗ',
      addPlayer: 'Προσθήκη Παίχτη',
      invite: 'ΠΡΟΣΚΛΗΣΗ',
      addOrg: 'Προσθήκη Συλλόγου',
      addTeam: 'Προσθήκη Ομάδας',
      addRole: 'Προσθήθη Ρόλου',
      editPlayer: 'ΕΠΕΞΕΡΓΑΣΙΑ ΠΑΙΧΤΗ',
      delete: 'ΔΙΑΓΡΑΦΗ',
      cancel: 'ΑΚΥΡΩΣΗ',
      selectTeam: 'Διαλέξτε Ομάδα',
      public: 'ΔΗΜΟΣΙΑ',
      personal: 'ΠΡΟΣΩΠΙΚΑ',
      edit: 'Επεξεργασία',
      close: 'ΚΛΕΙΣΙΜΟ',
      remove: 'ΑΦΑΙΡΕΣΗ',
      removeSmall: 'Αφαίρεση',
      resend: 'ΕΠΑΝΑΠΟΣΤΟΛΗ',
      redirectLogin: 'Μετάβαση στην οθόνη login',
      resendMail: 'Αποστολή e-mail ξανά',
      chooseFile: 'ΔΙΑΛΕΞΤΕ ΑΡΧΕΙΟ',
      uploadOrgLogo: 'ΑΝΕΒΑΣΤΕ LOGO ΓΙΑ ΤΟΝ ΣΥΛΛΟΓΟ',
      uploadLeagueLogo: 'ΑΝΕΒΑΣΤΕ LOGO ΓΙΑ ΤΗ ΛΙΓΚΑ',
      uploadTeamLogo: 'ΑΝΕΒΑΣΤΕ LOGO ΓΙΑ ΤΗΝ ΟΜΑΔΑ',
      drillUpdateModePP: 'ΝΑΙ, ΣΤΟ ΠΛΑΝΟ',
      drillUpdateModeGeneral: 'ΟΧΙ, ΠΑΝΤΟΥ',
      loadRecentSetups: 'Φόρτωση Πρόσφατων Ρυθμίσεων',
      select: 'ΕΠΙΛΕΞΤΕ',
      stop: 'ΣΤΑΜΑΤΗΣΤΕ',
      skip: 'ΠΑΡΑΒΛΕΨΗ',
      passOwnership: 'ΑΛΛΑΓΗ ΙΔΙΟΚΤΗΤΗ',
      cancelRequest: 'ΑΚΥΡΩΣΗ ΑΙΤΗΜΑΤΟΣ',
      request: 'YΠΟΒΟΛΗ',
      accept: 'ΑΠΟΔΟΧΗ',
      reject: 'ΑΠΟΡΡΙΨΗ',
      continue: 'ΣΥΝΕΧΕΙΑ',
      tryAgain: 'ΠΡΟΣΠΑΘΕΙΣΤΕ ΞΑΝΑ',
      retry: 'ΕΠΑΝΑΛΗΨΗ',
      changeCard: 'ΑΛΛΑΓΗ ΚΑΡΤΑΣ',
      addToPersonal: 'ΠΡΟΣΘΗΚΗ ΣΤΑ ΠΡΟΣΩΠΙΚΑ',
      addToPersonalLower: 'Προσθήκη στα προσωπικά',
      choosePracticePlan: 'ΔΙΑΛΕΞΤΕ ΠΛΑΝΟ ΠΡΟΠΟΝΗΣΗΣ',
      newPracticePlan: 'ΚΑΙΝΟΥΡΓΙΟ ΠΛΑΝΟ ΠΡΟΠΟΝΗΣΗΣ',
      newPractice: 'Καινούργια Προπόνηση',
      ambient: 'ΠΕΡΙΒΑΛΛΟΝΤΟΣ ΧΩΡΟΥ',
      commentary: 'ΣΠΙΚΑΖ',
      export: 'Εξαγωγή',
      watchStream: 'ΔΕΙΤΕ ΜΕΤΑΔΩΣΗ',
      watchVideo: 'ΔΕΙΤΕ ΒΙΝΤΕΟ',
      exit: 'ΕΞΟΔΟΣ',
      play: 'PLAY',
      pause: 'PAUSE',
      none: 'ΚΑΝΕΝΑ',
      removeVideo: 'ΑΦΑΙΡΕΣΗ ΤΟΥ ΒΙΝΤΕΟ',
      cancelSubscription: 'ΑΚΥΡΩΣΗ ΣΥΝΔΡΟΜΗΣ',
      no: 'ΟΧΙ',
      activate: 'ΕΝΕΡΓΟΠΟΙΗΣΗ',
      subscribe: 'ΕΓΓΡΑΦΗ',
      practicePlayers: 'ΠΑΙΚΤΕΣ',
      deactivate: 'ΑΠΕΝΕΡΓΟΠΟΙΗΣΗ',
      next: 'ΕΠΟΜΕΝΟ',
      prev: 'ΠΙΣΩ',
      addUsers: 'ΠΡΟΣΘΗΚΗ ΧΡΗΣΤΩΝ',
      finish: 'ΟΛΟΚΛΗΡΩΣΗ',
      payNow: 'ΠΛΗΡΩΜΗ',
    },
    gridLinks: {
      login: 'Πίσω στην οθόνη Σύνδεσης',
      signUp: 'Κάνε Εγγραφή',
      forgotPassword: 'Ξέχασες τον κωδικό;',
    },
    errorMessages: {
      unknown: '',
      unauthorized: '',
      forbidden: '',
      invalidData: 'Πρέπει να συμπληρώσετε όλα τα υποχρεωτικά πεδία.',
      serviceUnavailable: '',
      badRequest: '',
      s2sConflict: 'Συγνώμη, το email ή ο αριθμός τηλεφώνου χρησιμοποιούνται ήδη.',
      businessError: 'Business Error',
      accountNotFound: 'Συγνώμη, ο λογαριαμός δεν υπάρχει.',
      wrongPassword: 'Συγνώμη, ο λογαριαμός δεν υπάρχει ή ο κωδικός είναι λάθος.',
      emailAccountNotConfirmed: 'Συγνώμη, ο λογαριαμός δεν έχει ενεργοποιηθεί.',
      passwordResetTimeExpired: 'Συγνώμη, το χρονικό όριο επαναφοράς έληξε.',
      passwordNotFound: '',
      tokenNotValid: 'Invalid Token',
      tokenHasBeenUsed: 'Token has been used',
      identityNotFound: '',
      identityAlreadyConfirmed: '',
      identityIsPrimary: '',
      usernameExists: '',
      orgNameExists: 'Συγνώμη, το όνομα συλλόγου που επιλέξατε υπάρχει ήδη.',
      confirmEmailError: 'Το email δεν επιβεβαιώθηκε.',
      confirmEmailErrorRedirect: 'Μετάβαση στη σελίδα επαναποστολής email επιβεβαίωσης σε',
      emailResentError: 'Αποτυχία αποστολής νέου mail επιβεβαίωσης. Προσπαθήστε πάλι!',
      emailResetPasswordError:
        'Δεν ήταν δυνατή η εύρεση λογαριασμού με το δωθέν email. Παρακαλώ ελέγξτε το email ξανά. Αν πιστεύετε πως υπήρξε κάποιο λάθος, παρακαλώ επικοινωνήστε μαζί μας στο support@mismatch.gr.',
      logosLargePayloadError: 'Συγνώμη, οι εικόνες ξεπερνούν το όριο των 16mb.',
      changePasswordError: 'Αποτυχία κατά την ενημέρωση κωδικού.',
      updateTeamError: 'Αποτυχία κατά την ενημέρωση ομάδας. Παρακαλώ προσπαθήστε ξανά!',
      signUpError: 'Λυπάμαι, κάτι πήγε στραβά',
      selfInviteError: 'Δεν μπορείτε να προσκαλέσετε τον εαυτό σας!',
      noUsersToInviteError: 'Δεν προστέθηκαν χρήστες για να προσκληθούν!',
      alreadyOrganisationUserError:
        'Ο χρήστης είναι ήδη μέλος του συλλόγου ή έχει ήδη σταλθεί πρόσκληση!',
      wrongEmailFormat: 'Λάθος μορφή email!',
      invitationSendError: 'Σφάλμα κατά την αποστολή της πρόσκλησης!',
      invitationRejected: 'Απόρριψη πρόσκλησης!',
      maxVideosAdded: 'Δεν μπορείτε να προσθέσετε περισσότερα βίντεο!',
      sameVideosAdded: 'Δεν μπορείτε να προσθέσετε το ίδιο βίντεο!',
      wrong: 'Λάθος κωδικός!',
      errorMessages: 'Κάτι πήγε σταρβά.',
      connectionError: 'Παρουσιάστηκε σφάλμα κατά τη φόρτωση των δεδομένων.',
      supportMessageFail: 'Μήνυμα υποστήριξης δεν στάλθηκε επιτυχώς!',
      noStatsAvailable: 'Δεν υπάρχουν διαθέσιμα στατιστικά για αυτό το παιχνίδι.',
      statsGameNotStarted: 'Το παιχνίδι δεν έχει αρχίσει ακόμα.',
    },
    successMessages: {
      forgotPassword: 'Εστάλη email με οδηγίες.',
      resetPassword: 'Ο κωδικός άλλαξε, θα κατευθυνθείτε στην οθόνη Σύνδεσης.',
      emailConfirm: 'Το email επιβεβαιώθηκε, θα κατευθυνθείτε στην οθόνη Σύνδεσης.',
      signUp: 'Ο λογαριασμός σας έχει δημιουργηθεί, θα κατευθυνθείτε στην οθόνη Σύνδεσης.',
      profileData: 'Τα στοιχεία σου ενημερώθηκαν.',
      changePasswordSuccess: 'Ο κωδικός σας ενημερώθηκε επιτυχώς.',
      preferences: 'Οι προτιμήσεις σας ενημερώθηκαν.',
      disableAccount:
        'Ο λογαριασμός σας έχει απενεργοποιηθεί, θα κατευθυνθείτε στην οθόνη Σύνδεσης.',
      confirmEmailSuccess: 'Το email επιβεβαιώθηκε με επιτυχία.',
      emailResent: 'Σας στάλθηκε νέο email επιβεβαίωσης!',
      emailResetPasswordSuccess: 'Σας στάλθηκε νέο email ανάκτησης κωδικού!',
      updateTeamSuccess: 'Η ομάδας σας ενημερώθηκε με επιτυχία!',
      invitationAccepted: 'Αποδοχή πρόσκλησης!',
      supportMessageSuccess: 'Μήνυμα υποστήριξης στάλθηκε επιτυχώς!',
    },
    navigation: {
      skip_nav: 'Μετάβαση στο περιεχόμενο',
      page_rows_per_page: 'Σειρές ανά σελίδα',
      page_range_info: 'Εύρος ανά σελίδα',
      next: 'ΕΠΟΜΕΝΟ',
      no_results: 'ΚΑΝΕΝΑ ΑΠΟΤΕΛΕΣΜΑ',
    },
    action: {
      save: 'Αποθήκευση',
      delete: 'Διαγραφή',
      sort: 'ΤΑΞΙΝΟΜΗΣΗ',
      add_filter: 'Προσθήκη Φίλτρου',
      add_court: 'Προσθήκη Γηπέδου',
      search: 'Αναζήτηση',
      back: 'Επιστροφή',
      refresh: 'Ανανέωση',
      addPlayers: 'Προσθέσθε παίκτες στην προπόνηση',
      selectAll: 'Επιλογή όλων',
      deselectAll: 'Απεπιλογή όλων',
      cancel: 'Ακύρωση',
    },
    tour: {
      didYouKnow: 'Ήξερες οτι:',
      didYouKnowIntro: '',
      welcome: 'Καλώς ήρθατε στην εφαρμογή Mismatch',
      welcomeIntro:
        'Εξερευνήστε τις λειτουργίες προπονητή για αποτελεσματική προπόνηση. Έτοιμοι να ξεκινήσετε;',
      organisation: 'Σύλλογος',
      orgIntro:
        'Δημιουργήστε και επεξεργαστείτε συλλόγους, προσκαλέστε άλλους προπονητές, δείτε όλους τους συλλόγους στους οποίους είστε μέλος.',
      logo: 'Λογότυπο και Όνομα Ομάδας',
      logoIntro:
        'Το όνομα και το λογότυπο της ομάδας σας. Επισκεφτείτε αυτήν τη σελίδα εύκολα ή ελέγξτε το ημερολόγιο οποτεδήποτε κάνοντας κλικ στο λογότυπο της λέσχης σας.',
      roster: 'Διαχείριση Ομάδας',
      rosterIntro:
        'Χρησιμοποιήστε το ημερολόγιο για να παρακολουθείτε τα γεγονότα της ομάδας, όπως προπονήσεις και αγώνες, διαχειριστείτε τo <strong>Δυναμικό της Ομάδας</strong>, σχεδιάστε και προγραμματίστε αποτελεσματικές συνεδρίες εκπαίδευσης με <strong>σχέδια προπόνησης</strong> και οργανώστε τις κινήσεις σας σε <strong>βιβλία τακτικής</strong>.',
      coach: 'Γωνιά του Προπονητή',
      coachIntro:
        'Ο κεντρικός σας χώρος προπονητικής. Διαμορφώστε <strong>ασκήσεις</strong> για τη μέγιστη αξιοποίηση του δυναμικού της ομάδας σας και διαμορφώστε <strong>κινήσεις</strong> για την επίτευξη αποτελεσμάτων.',
      drill: 'Άσκησεις',
      drillIntro:
        'Σε αυτήν τη σελίδα, δείτε τις άσκησεις που έχετε δημιουργήσει εσείς ή άλλοι. Δημιουργήστε ασκήσεις για συγκεκριμένες δεξιότητες μπάσκετ.',
      public: 'Προσωπική - Δημόσια Άσκηση',
      publicIntro:
        'Χρησιμοποιήστε αυτά τα κουμπιά για να ξεχωρίσετε προσωπικές ασκήσεις (δικές σας) ή δημόσιες ασκήσεις που κοινοποιούνται από άλλους, προσβάσιμες σε όλους στη λέσχη.',
      createDrill: 'Δημιουργία Άσκησης',
      createDrillIntro: 'Εκπαιδεύστε την ομάδα με άσκηση. Δημιουργήστε μια άσκηση από εδώ.',
      drillTitle: 'Τίτλος Άσκησης',
      drillTitleIntro: 'Ο τίτλος της άσκησής σας.',
      drillDes: 'Περιγραφή Άσκησης',
      drillDesIntro:
        'Παρέχετε λεπτομερείς πληροφορίες για την άσκηση: άσκηση, δεξιότητες, προσδοκίες και οδηγίες προς τους παίκτες.',
      drillGraphics: 'Προσθήκη Γραφικών',
      drillGraphicsIntro:
        'Ενισχύστε την άσκηση με γραφικά γηπέδου χρησιμοποιώντας το κουμπί <strong>Προσθήκη</strong>. Συμπεριλάβετε γραμμές, βέλη, κωνικούς, μπάλες και άλλα για μια οπτική αναπαράσταση.',
      drillIntensity: 'Ένταση Άσκησης και Θέσεις',
      drillIntensityIntro:
        'Ορίστε το επίπεδο έντασης της άσκησης. <br/> Καθορίστε τις θέσεις των παικτών για αυτήν την άσκηση.',
      drillTactics: 'Τακτική Άσκησης',
      drilltacticsIntro:
        'Καθορίστε τη σχετική τακτική για αυτήν την άσκηση. Η προσθήκη επιθετικών ή αμυντικών τακτικών βοηθά τους παίκτες να κατανοήσουν τις δεξιότητες που εκπαιδεύονται.',
      publicDrill: 'Δημοσίευση Άσκησης',
      publicDrillIntro:
        'Χρησιμοποιήστε το κουμπί <strong>Δημοσίευση</strong> για να μοιραστείτε δημόσια τις ασκήσεις, παρέχοντας πρόσβαση σε κάθε προπονητή στη λέσχη σας.',
      drillSave: 'Αποθήκευση και Διαγραφή',
      drillSaveIntro:
        'Αφού δημιουργήσετε μια άσκηση, αποθηκεύστε τη χρησιμοποιώντας το κουμπί αποθήκευσης.',
      rosterMan: 'Διαχείριση Ρόστερ',
      rosterManIntro:
        'Διαχειριστείτε απροσπέλαστα το ρόστερ της ομάδας σας εδώ. Προσθέστε, επεξεργαστείτε ή αφαιρέστε παίκτες όπως χρειάζεται.',
      playerList: 'Λίστα Παικτών',
      playerListOntro:
        'Όλοι οι παίκτες της ομάδας αναφέρονται. Ταξινομήστε ανά όνομα, φανέλα, ύψος, ηλικία. Κάντε κλικ σε έναν παίκτη για να επεξεργαστείτε τις λεπτομέρειες.',
      addPlayer: 'Προσθήκη Παίκτη',
      addPlayerIntro:
        'Κάντε κλικ στο <strong>Προσθήκη Παίκτη</strong> για να ενσωματώσετε νέα μέλη στο ρόστερ σας.',
      plays: 'Κινήσεις',
      playsIntro:
        'Η ενότητα Κινήσεις παρουσιάζει προ-σχεδιασμένες στρατηγικές και συντονισμένες κινήσεις που έχουν σχεδιαστεί για την επίτευξη συγκεκριμένων στόχων στο γήπεδο.',
      publicPlay: 'Προσωπική - Δημόσια Κίνηση',
      publicPlayIntro:
        'Χρησιμοποιήστε αυτά τα κουμπιά για να διακρίνετε τα προσωπικά παιχνίδια (τα δικά σας) ή τα δημόσια παιχνίδια που κοινοποιούνται, προσβάσιμα από όλα τα μέλη του συλλόγου.',
      createPlay: 'Δημιουργία Παιχνιδιού',
      createPlayIntro:
        'Σχεδιάστε τα παιχνίδια σας, περιγράψτε τα και ετικετοποιήστε τα για ευκολότερο φιλτράρισμα.',
      playTitle: 'Τίτλος Παιχνιδιού',
      playTitleIntro: 'Ο τίτλος του παιχνιδιού σας.',
      playGraphics: 'Προσθήκη Γραφικών',
      playGraphicsIntro:
        'Ενισχύστε τον συνδυασμό με γραφικά πίστας χρησιμοποιώντας το κουμπί <strong>Προσθήκη</strong>. Συμπεριλάβετε γραμμές, βέλη, κώνους, μπάλες και άλλα για οπτική αναπαράσταση.',
      playDes: 'Περιγραφή Παιχνιδιού',
      playDesIntro: 'Παρέχετε λεπτομερείς πληροφορίες για το παιχνίδι.',
      playTactics: 'Τακτική Παιχνιδιού',
      playTacticsIntro:
        'Καθορίστε την τακτική για το παιχνίδι. Προσθέστε επιθετική/αμυντική περιγραφή για την κατανόηση των παικτών.',
      makePublicPlay: 'Δημοσίευση Παιχνιδιού',
      makePublicPlayIntro:
        'Καταστήστε τα παιχνίδια σας δημόσια, επιτρέποντας σε κάθε άλλον προπονητή στον σύλλογό σας να τα έχει πρόσβαση.',
      playSave: 'Αποθήκευση και Διαγραφή',
      playSaveIntro:
        'Μετά τη δημιουργία ενός παιχνιδιού, αποθηκεύστε το χρησιμοποιώντας το κουμπί <strong>Αποθήκευση</strong>.',
      practice: 'Προπονήσεις',
      practiceIntro:
        'Εξερευνήστε τις προγραμματισμένες προπονήσεις της ομάδας. Προγραμματίστε προπονήσεις για τα επερχόμενα παιχνίδια.',
      date: 'Εύρος Ημερομηνιών',
      dateIntro:
        'Μπορείτε να καθορίσετε το εύρος ημερομηνιών για να δείτε τα προγραμματισμένα γεγονότα.',
      newPractice: 'Νέα Προπόνηση',
      newPracticeIntro:
        'Κάντε κλικ σε αυτό το κουμπί για να προγραμματίσετε μια νέα προπόνηση για την ομάδα.',
      playBook: 'Βιβλίο Παιχνιφιών',
      playBookIntro:
        'Ένα Playbook περιλαμβάνει πολλά παιχνίδια, καθορίζοντας τις τακτικές που μια ομάδα χρησιμοποιεί σε όλα τα στάδια ενός παιχνιδιού.',
      addPlays: 'Προσθήκη Παιχνιδιών',
      addPlaysIntro: 'Κάντε κλικ σε αυτό το κουμπί για να προσθέσετε παιχνίδια στο βιβλίο.',
      export: 'Εξαγωγή Playbook',
      exportIntro: 'Εξαγωγή του βιβλίου παιχνιδιών σε μορφή PDF για τη διανομή στις προπονήσεις.',
    },
  },
  Password: {
    NotAllowedKeys: 'Invalid password',
  },
  GENERAL_SYSTEM_LANGUAGE: 'Γλώσσα Συστήματος',
  TEMPORAL_DATE_FORMAT: 'Μορφή Ημερομηνίας',
}

export default el
