import React, { useRef } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import loadingDots from '../../../assets/images/loading-dots.svg'
import buttonWithLoaderStyles from './buttonWithLoader.styles'

const ButtonWithLoader = (props) => {
  const { label, loading, type, disabled, ...rest } = props
  const buttonRef = useRef(null)
  const buttonRect = buttonRef?.current?.getBoundingClientRect()
  const useStyles = makeStyles(() => buttonWithLoaderStyles(buttonRect))
  const classes = useStyles()

  return (
    <Button
      ref={buttonRef}
      {...rest}
      variant='contained'
      type={type || 'submit'}
      classes={{ root: classes.buttonRoot, disabled: classes.buttonDisabled }}
      disabled={loading || disabled}
    >
      {!loading ? (
        <span>{label}</span>
      ) : (
        <img alt='img' src={loadingDots} className={classes.loadingDots} />
      )}
    </Button>
  )
}

ButtonWithLoader.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
}
ButtonWithLoader.defaultProps = {
  loading: false,
}

export default ButtonWithLoader
