import React, { useEffect, useState } from 'react'
import { makeStyles, Typography, Container, TableContainer, Paper } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { useQuery } from 'react-query'
import teamStatisticsPageStyles from './teamStatisticsPage.styles'
import COLORS from '../../utils/constants/colors'
import useQueryParams from '../../hooks/useQueryParams'
import LoaderDots from '../../components/loaders/LoaderDots/LoaderDots'
import ContainerPadding from '../../components/containerPadding/containerPadding'
import QueryKeys from '../../utils/constants/queryKeys'
import getGameStats from '../../Apis/stats/getGameStats'
import GameStatsInfo from './GameStatsInfo'
import PerQuarterTable from './PerQuarterTable'
import PlayersPerGameTable from './PlayerPerGameTable'
import MyToggleButton from '../../components/MyToggleButton/MyToggleButton'
import NoStatsAvailablePage from './NoStatsAvalaiblePage'

const GameStatisticsPage = () => {
  const useStyles = makeStyles(teamStatisticsPageStyles)
  const classes = useStyles()
  const [toggleValue, setToggleValue] = useState()
  const [players, setPlayers] = useState()
  const translate = useTranslate()
  const queryParams = useQueryParams()

  const {
    data: gameStats,
    isFetching: isGameStatsFetching,
    error: gameStatsError,
  } = useQuery(
    [QueryKeys.GET_GAME_STATS, queryParams?.eventId],
    () => getGameStats({ gameId: queryParams?.eventId }),
    {
      enabled: Boolean(queryParams?.eventId),
    },
  )

  useEffect(() => {
    if (toggleValue === 'team1Players') {
      setPlayers(gameStats?.teams[0]?.teamPlayerStats)
    } else {
      setPlayers(gameStats?.teams[1]?.teamPlayerStats)
    }
  }, [isGameStatsFetching, toggleValue])

  useEffect(() => {
    if (gameStats && gameStats.teams) {
      setToggleValue('team1Players')
    }
  }, [isGameStatsFetching])

  return (
    <Container>
      {isGameStatsFetching ? (
        <ContainerPadding paddingValue='45%'>
          <LoaderDots
            style={{
              fill: COLORS.orange,
              width: 70,
              height: 70,
            }}
          />
        </ContainerPadding>
      ) : (
        <ContainerPadding paddingValue='1%' className={classes.statsContainer}>
          {gameStats?.teams.length === 0 || gameStatsError ? (
            <NoStatsAvailablePage error={gameStatsError?.message} />
          ) : (
            <ContainerPadding paddingValue='1%'>
              <GameStatsInfo gameStats={gameStats} eventId={queryParams?.eventId} />
              <Typography variant='h6' gutterBottom style={{ marginTop: '50px' }}>
                {translate('ra.text.perQuarter')}
              </Typography>
              <PerQuarterTable
                teams={gameStats.teams}
                currentPeriod={gameStats?.gameTime?.period || null}
              />

              <Typography variant='h6' gutterBottom style={{ marginTop: '50px' }}>
                {translate('ra.text.endOfQuarter')}
              </Typography>
              <PerQuarterTable
                teams={gameStats.teams}
                currentPeriod={gameStats?.gameTime?.period || null}
                sum
              />

              <Typography
                variant='h6'
                gutterBottom
                style={{ marginTop: '50px', display: 'flex', justifyContent: 'space-between' }}
              >
                <span>{translate('ra.text.selectedPlayers')}</span>
                <MyToggleButton
                  left={{
                    value: 'team1Players',
                    label: gameStats?.teams[0].name,
                  }}
                  right={{
                    value: 'team2Players',
                    label: gameStats?.teams[1]?.name,
                  }}
                  setToggleValue={setToggleValue}
                  toggleValue={toggleValue}
                />
              </Typography>
              <TableContainer component={Paper}>
                <PlayersPerGameTable players={players} />
              </TableContainer>
            </ContainerPadding>
          )}
        </ContainerPadding>
      )}
    </Container>
  )
}

export default GameStatisticsPage
