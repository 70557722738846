import isUserOrgRolesValid from './isUserOrgRolesValid'
import isUserScopesValid from './isUserScopesValid'
import orgIsInSubscriptionState from './orgIsInSubscriptionState'
import SUBSCRIPTION_STATES from '../constants/subscriptionStates'
import { USER_SCOPES } from '../constants/userScopes'
import userHasOnlySpecificScopes from './userHasOnlySpecificScopes'

const sidebarOptionWithAuth = (sidebarOptions) => {
  // TODO: When not everything coaching related is pay locked, add new property to sidebar options
  const filteredByRoleAndScopesOptions = sidebarOptions.filter(
    (option) =>
      isUserOrgRolesValid(option.allowedUserOrgRoles) && isUserScopesValid(option.allowedScopes),
  )

  // if user has only coach should not be able to see any page that is pay locked
  if (
    orgIsInSubscriptionState([SUBSCRIPTION_STATES.INACTIVE]) &&
    userHasOnlySpecificScopes([USER_SCOPES.COACH, USER_SCOPES.STATS])
  )
    return filteredByRoleAndScopesOptions.map((option) => {
      if (
        ((option.allowedScopes.includes(USER_SCOPES.COACH) ||
          option.allowedScopes.includes(USER_SCOPES.STATS)) &&
          (option.name !== 'subscription' || option.name !== 'orgUsers')) ||
        option?.payLocked
      ) {
        return { ...option, disabled: true }
      } else {
        return option
      }
    })

  // if user has both coach and livestream should not be able to see practice plan and playbook
  if (orgIsInSubscriptionState([SUBSCRIPTION_STATES.INACTIVE]))
    return filteredByRoleAndScopesOptions.map((option) => {
      // the subscription page is a coach only page but should be visible even if subscription state is inactive
      if (
        (option.allowedScopes.includes(USER_SCOPES.COACH) ||
          option.allowedScopes.includes(USER_SCOPES.STATS)) &&
        option.name !== 'subscription'
      ) {
        return { ...option, disabled: true }
      } else {
        return option
      }
    })

  return filteredByRoleAndScopesOptions
}

export default sidebarOptionWithAuth
