import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import myToggleButtonStyles from './myToggleButton.styles'
import TOUR_IDS from '../../utils/constants/introIds'

const MyToggleButton = (props) => {
  const { left, right, toggleValue, setToggleValue } = props
  const useStyles = makeStyles(myToggleButtonStyles)
  const classes = useStyles()

  const handleAlignment = (e) => {
    if (e.target.value === left.value) {
      setToggleValue(left.value)
    } else {
      setToggleValue(right.value)
    }
  }

  return (
    <ToggleButtonGroup
      id={TOUR_IDS.drills.public}
      value={toggleValue}
      onClick={handleAlignment}
      size='small'
    >
      <ToggleButton
        classes={{
          selected: classes.selected,
          root: classes.root,
        }}
        value={left.value}
      >
        {left.label}
      </ToggleButton>
      <ToggleButton
        classes={{
          selected: classes.selected,
          root: classes.root,
        }}
        value={right.value}
      >
        {right.label}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

MyToggleButton.propTypes = {
  left: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  right: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  toggleValue: PropTypes.string,
  setToggleValue: PropTypes.func,
}

export default MyToggleButton
