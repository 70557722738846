import COLORS from '../colors'

const TEXT_INPUT_STYLES = {
  // HelperText
  helperTextRoot: {
    display: 'none',
  },

  // InputLabelProps
  error: {
    '&&&': {
      color: COLORS.errorRed,
    },
  },
  formControl: {
    '&&': {
      transform: 'translate(12px, 14px)',
      fontSize: '0.9rem',
      color: 'rgba(0, 0, 0, 0.40)',
    },
  },
  shrink: {
    '&&&': {
      transform: 'translate(12px, -6px)',
      fontSize: '12px',
      color: COLORS.black,
    },
  },
  disabledLabel: { opacity: 0.5 },

  // InputProps
  root: {
    borderRadius: '6px',
    padding: 0,
  },
  input: {
    height: '0.7rem',
    minHeight: '0.7rem',
    fontSize: '0.95rem',
    padding: '17px 12px 14px',
  },
  rootMultiline: {
    borderRadius: '6px',
  },
  inputMultiline: {
    fontSize: '0.95rem!important',
  },
  disabledProps: { opacity: 0.5 },
}

export default TEXT_INPUT_STYLES
