import axiosInstance from '../axiosInterceptor/axiosInstance'

const getLivestream = async (data) => {
  const { broadcastId } = data
  const url = `/livestreams/${broadcastId}`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getLivestream
