import axiosInstance from '../axiosInterceptor/axiosInstance'

const getPlayer = async (data) => {
  const { playerId } = data
  const url = `players/${playerId}`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getPlayer
