import COLORS from '../../../utils/constants/colors'

const selectRecentLivestreamDialogStyles = {
  listItemText: {
    fontSize: '0.9rem',
    fontWeight: 500,
  },
  selectButton: {
    margin: '8px 16px',
  },
  loader: {
    width: 30,
    height: 30,
    margin: 30,
    fill: COLORS.orange,
  },
  emptyText: {
    fontSize: '0.9rem',
    fontWeight: 500,
    padding: '0 16px',
  },
}

export default selectRecentLivestreamDialogStyles
