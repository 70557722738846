import * as React from 'react'
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { useTranslate } from 'react-admin'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import PracticePlanComponentsAccordion from '../PracticePlanComponentsAccordion/PracticePlanComponentsAccordion'
import DialogWithCloseIcon from '../DialogWithCloseIcon/DialogWithCloseIcon'
import SearchField from '../ui/SearchField/SearchField'
import practicePlanRecentModalStyles from './practicePlanRecentModal.styles'
import QueryKeys from '../../utils/constants/queryKeys'
import getPracticePlans from '../../Apis/practice/getPracticePlans'
import LoaderDots from '../loaders/LoaderDots/LoaderDots'
import COLORS from '../../utils/constants/colors'
import useQueryParams from '../../hooks/useQueryParams'
import ContainerPadding from '../containerPadding/containerPadding'
import sessionStorageIds from '../../utils/constants/sessionStorageIds'
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll'
import ConditionalRender from '../ConditionalRender/ConditionalRender'

const PracticePlanRecentModal = (props) => {
  const { open, onClose, eventId, setSelectedPracticePlan } = props
  const [searchField, setSearchField] = useState('')
  const history = useHistory()
  const queryParams = useQueryParams()
  const { org } = queryParams
  const translate = useTranslate()
  const useStyles = makeStyles(practicePlanRecentModalStyles)
  const classes = useStyles()

  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery(
    [QueryKeys.GET_PRACTICE_PLANS, searchField],
    ({ pageParam = 1 }) =>
      getPracticePlans({
        Page: pageParam,
        Q: searchField,
        orgId: org,
      }),
    {
      getNextPageParam: ({ metadata }) => {
        const { currentPage, totalPages } = metadata
        return currentPage === totalPages || totalPages === 0 ? undefined : currentPage + 1
      },
    },
  )
  const queryPracticePlans = data
    ? data.pages.map((page) => page.practicePlanDetailsDto).flat()
    : []

  useEffect(() => {
    const unlisten = history.listen(() => {
      // Close modal when user navigates back
      onClose()
    })
    return () => unlisten()
  }, [history])

  return (
    <DialogWithCloseIcon
      open={open}
      onClose={onClose}
      dialogTitle='Practice Plans'
      minDialogWidth='80%'
      minDialogHeight='50%'
    >
      <ContainerPadding>
        <SearchField
          variant='filled'
          value={searchField}
          onChange={(e) => {
            setSearchField(e.target.value)
          }}
          reset={() => setSearchField('')}
          fullWidth
        />
        <Typography component='div' className={classes.recentsTypo}>
          {translate('ra.text.recent')}
        </Typography>
        <Divider variant='middle' className={classes.divider} />
        {queryPracticePlans?.length > 0 ? (
          queryPracticePlans?.map((practicePlan) => (
            <div key={practicePlan.id} className={classes.accordionWrapper}>
              <PracticePlanComponentsAccordion
                practicePlan={practicePlan}
                isModal
                selectedEventId={eventId}
                onclose={onClose}
                setSelectedPracticePlan={setSelectedPracticePlan}
              />
              <Divider variant='middle' className={classes.divider} />
            </div>
          ))
        ) : (
          <p>{translate('ra.text.noPracticePlans')}</p>
        )}

        <ConditionalRender renderValue={isLoading || isFetchingNextPage}>
          <Grid
            container
            justifyContent='center'
            style={{ marginTop: '100px', marginBottom: '30px' }}
          >
            <LoaderDots
              style={{
                fill: COLORS.orange,
                marginTop: -50,
                width: 50,
                height: 50,
              }}
            />
          </Grid>
        </ConditionalRender>
        <InfiniteScroll
          isLoading={isLoading || isFetchingNextPage}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          scrollId={sessionStorageIds.PRACTICE_PLANS_SCROLLBAR_POSITION}
        />
      </ContainerPadding>
    </DialogWithCloseIcon>
  )
}

PracticePlanRecentModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  eventId: PropTypes.string,
  setSelectedPracticePlan: PropTypes.func,
}

export default PracticePlanRecentModal
