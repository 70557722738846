import React from 'react'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import streamFieldsStyles from './streamFields.styles'
import MyLiveTvIcon from '../../../assets/icons/MyLiveTvIcon'
import MyTextField from '../../form/fields/MyTextField'
import GAME_EVENT_FORM_BROADCAST_FIELDS from '../../../utils/constants/gameEventFormBroadcastFields'
import { isFieldDisabled } from '../../../utils/helpers/isFieldDisabled'

const StreamField = ({ state }) => {
  const translate = useTranslate()
  const useStyles = makeStyles(streamFieldsStyles)
  const classes = useStyles()

  return (
    <div className='div-flex'>
      <MyLiveTvIcon className={classes.tvIcon} />
      <MyTextField
        name={GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY}
        label={translate('ra.formFields.streamKeyURI')}
        required
        disabled={isFieldDisabled(state, GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY)}
        fullWidth
      />
    </div>
  )
}

StreamField.propTypes = {
  state: PropTypes.string,
}

export default StreamField
