import { shape, string, arrayOf } from 'prop-types'
import enumIdType from './enumIdType'
import countryType from './countryType'

const orgType = shape({
  city: string,
  country: countryType,
  id: string,
  logoUrl: string,
  name: string,
  teams: arrayOf(enumIdType),
  user: shape({
    email: string,
    firstName: string,
    lastName: string,
  }),
})

export default orgType
