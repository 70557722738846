const practicePlanPdfFooter = (currentPage, pageCount) => {
  const t = {
    layout: 'noBorders',
    fontSize: 8,
    style: { alignment: 'right' },
    margin: [0, 0, 50, 0],
    text: `Page  ${currentPage.toString()} of ${pageCount}`,
  }

  return t
}

export default practicePlanPdfFooter
