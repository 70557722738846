import COLORS from '../../utils/constants/colors'

const statsNavBarStyles = () => ({
  navBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid #ddd',
    marginBottom: '20px',
  },
  tab: {
    padding: '10px 20px',
    cursor: 'pointer',
    fontWeight: 'bold',
    color: COLORS.darkGrey,
  },
  activeTab: {
    color: 'black',
    borderBottom: '2px solid #E0726E',
  },
})

export default statsNavBarStyles
