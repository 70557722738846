import COLORS from '../../utils/constants/colors'

const teamsModalStyles = {
  droppableGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(190px, 2fr))',
  },
  draggableDiv: {
    padding: '4px',
    textAlign: 'center',
  },
  tableContainer: {
    marginTop: '20px',
  },
  table: {
    border: '1px solid black',
  },
  firstRow: {
    backgroundColor: COLORS.orange,
  },
  span: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: '20px',
    bottom: '20px',
  },
}

export default teamsModalStyles
