import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { useTranslate } from 'react-admin'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from '@material-ui/core'

const PracticeTeamsShow = ({ team, teamIndex }) => {
  const translate = useTranslate()
  const { teamPlayerNames: teamPlayersArray } = team || {}

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} key={uuidv4()}>
      <TableContainer style={{ marginTop: '20px', marginBottom: '20px' }} key={uuidv4()}>
        <Table style={{ border: '1px solid black' }}>
          <TableHead>
            <TableRow style={{ backgroundColor: '#E0726E' }}>
              <TableCell
                style={{
                  border: '1px solid black',
                  textAlign: 'center',
                  color: 'white',
                  padding: '8px',
                  height: '20px',
                  width: '50%',
                }}
              >
                <span>{`${translate('ra.text.team')} ${teamIndex + 1}`}</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamPlayersArray.map((player, playerIndex) => (
              <TableRow key={uuidv4()}>
                <TableCell
                  style={{
                    border: 'none',
                    textAlign: 'center',
                    padding: '8px',
                    width: '2%',
                    borderTop: playerIndex === team.numberOfPlayers ? '1px solid black' : '',
                  }}
                >
                  <span>{player}</span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

PracticeTeamsShow.propTypes = {
  team: PropTypes.shape({
    numberOfPlayers: PropTypes.number,
    teamPlayerNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  teamIndex: PropTypes.number,
}

export default PracticeTeamsShow
