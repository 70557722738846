import React from 'react'
import { TeamCalendarProvider } from '../../../context/useTeamCalendarContext'
import CalendarPage from '../CalendarPage/CalendarPage'
import ContainerPadding from '../../../components/containerPadding/containerPadding'

const CalendarPageProvider = () => {
  return (
    <TeamCalendarProvider>
      <ContainerPadding>
        <CalendarPage />
      </ContainerPadding>
    </TeamCalendarProvider>
  )
}

export default CalendarPageProvider
