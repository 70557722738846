/* eslint-disable react/jsx-props-no-spreading */
import { React } from 'react'
import { List, Datagrid, TextInput, useRefresh } from 'react-admin'
import { Drawer, InputAdornment, makeStyles } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Route } from 'react-router'
import useQueryParams from '../../../../hooks/useQueryParams'
import PlayerCreate from '../../../create/Player/PlayerCreate/PlayerCreate'
import playerListStyles from './playerList.styles'
import PlayerEdit from '../../../edit/Player/PlayerEdit/PlayerEdit'
import PlayerListEmpty from '../PlayerListEmpty/PlayerListEmpty'
import CloseDrawer from '../../../../components/CloseDrawer/CloseDrawer'
import SEARCH_INPUT_STYLES from '../../../../utils/constants/CSS/searchInputStyles'
import PlayerCreatePagination from '../../../create/Player/PlayerCreatePagination/PlayerCreatePagination'
import ContainerPadding from '../../../../components/containerPadding/containerPadding'
import useHistoryPush from '../../../../hooks/useHistoryPush'
import UserNameField from '../../../components/fields/UserNameField/UserNameField'
import PositionsField from '../../../components/fields/PositionsField/PositionsField'
import TeamField from '../../../components/fields/TeamField/TeamField'
import JerseyNumberField from '../../../components/fields/JerseyNumberField/JerseyNumberField'
import HeightField from '../../../components/fields/HeightField/HeightField'
import AgeField from '../../../components/fields/AgeField/AgeField'
import PlayerListActions from '../PlayerListActions/PlayerListActions'

const PlayerList = (props) => {
  const queryParams = useQueryParams()
  const refresh = useRefresh()
  const { redirect } = useHistoryPush()
  const useStyles = makeStyles(playerListStyles)
  const classes = useStyles()
  const fieldUseStyles = makeStyles(SEARCH_INPUT_STYLES)
  const fieldClasses = fieldUseStyles()

  const handleClose = () => {
    redirect('/players')
  }

  const rowClick = (id) => {
    redirect(`/players/${id}`)
  }

  const postFilters = [
    <TextInput
      style={{ marginBottom: 10, marginTop: 10 }}
      label=''
      placeholder='Search'
      source='q'
      alwaysOn
      variant='standard'
      resettable
      InputProps={{
        classes: {
          root: fieldClasses.root,
          input: fieldClasses.input,
        },
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon fontSize='small' style={{ marginLeft: 10 }} />
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
    />,
  ]

  return (
    <ContainerPadding>
      <List
        {...props}
        syncWithLocation={false}
        filter={{
          orgId: queryParams?.org,
          teamId: queryParams?.team,
        }}
        perPage={10}
        filters={postFilters}
        actions={<PlayerListActions />}
        bulkActionButtons={false}
        empty={<PlayerListEmpty refresh={refresh} />}
        pagination={<PlayerCreatePagination />}
        classes={{
          main: classes.listMain,
          content: classes.listContent,
        }}
        sort={{ field: 'FirstName', order: 'DESC' }}
      >
        <Datagrid rowClick={rowClick} classes={{ rowCell: classes.dataGridRowCell }}>
          <UserNameField source='FirstName' label='Name' recordScource='personalData' />
          {!queryParams?.team && <TeamField source='teams' label='Teams' sortable={false} />}
          {queryParams?.team && (
            <JerseyNumberField
              source='JerseyNumber'
              label='Jersey Number'
              recordSource='teams'
              teamId={queryParams?.team}
            />
          )}
          <HeightField source='Height' label='Height' recordSource='personalData' />
          <AgeField source='DateOfBirth' label='Age' recordSource='personalData' />
          <PositionsField
            source='personalData'
            label='Positions'
            recordSource='personalData'
            sortable={false}
          />
        </Datagrid>
      </List>
      <Route path='/players/create'>
        {({ match }) => (
          <Drawer open={!!match} anchor='right' classes={{ paper: classes.drawerPaper }}>
            <div className={classes.drawerContainer}>
              <CloseDrawer onClick={handleClose} />
              <PlayerCreate {...props} />
            </div>
          </Drawer>
        )}
      </Route>
      <Route path='/players/:id'>
        {({ match }) => {
          const isMatch = match ? match.params && match.params.id !== 'create' : false

          return (
            <Drawer
              open={isMatch}
              anchor='right'
              onClose={handleClose}
              classes={{ paper: classes.drawerPaper }}
            >
              <CloseDrawer onClick={handleClose} />
              {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
              {isMatch ? (
                <PlayerEdit id={match.params.id} closeDrawer={handleClose} {...props} />
              ) : null}
            </Drawer>
          )
        }}
      </Route>
    </ContainerPadding>
  )
}

export default PlayerList
