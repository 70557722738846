import { shape, string } from 'prop-types'

const eventVariantSmallType = shape({
  code: string,
  color: string,
  id: string,
  name: string,
})

export default eventVariantSmallType
