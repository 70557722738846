import MenuBookIcon from '@material-ui/icons/MenuBook'
import SportsHandballIcon from '@material-ui/icons/SportsHandball'
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball'
import React from 'react'
import COLORS from './colors'

const MENU_COACH_CORNER = [
  {
    name: 'plays',
    text: 'Plays',
    icon: <MenuBookIcon />,
    iconSelected: <MenuBookIcon style={{ fill: COLORS.orange }} />,
  },
  {
    name: 'drills',
    text: 'Drills',
    icon: <SportsHandballIcon />,
    iconSelected: <SportsHandballIcon style={{ fill: COLORS.orange }} />,
  },
  {
    name: 'tactics',
    text: 'Tactics',
    icon: <SportsBasketballIcon />,
    iconSelected: <SportsBasketballIcon style={{ fill: COLORS.orange }} />,
  },
]

export default MENU_COACH_CORNER
