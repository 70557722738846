import React, { useState } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import ImageCropEditPreview from '../ImageCropEditPreview/ImageCropEditPreview'
import imageCropWithEditStyles from './imageCropWithEdit.styles'
import ImageCrop from '../ImageCrop/ImageCrop'
import TextButton from '../../TextButton/TextButton'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader'
import DialogWithCloseIcon from '../../../DialogWithCloseIcon/DialogWithCloseIcon'

const ImageCropWithEdit = (props) => {
  const {
    defaultImageSrc,
    setCroppedImage,
    aspect,
    cropShape,
    minZoom,
    maxZoom,
    zoomStep,
    dialogTitle,
    dialogBottomText,
    isDisabled,
    emptyPreviewIcon,
    editPreviewWidth,
    editIconSize,
    EditPreviewComponent,
    editPreviewComponentClassName,
    dimensions,
    variant,
  } = props
  const [preview, setPreview] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const translate = useTranslate()
  const useStyles = makeStyles(imageCropWithEditStyles)
  const classes = useStyles()

  const handleEditIcon = () => {
    if (!isDisabled) {
      setIsModalOpen(true)
    }
  }
  const handleCloseModal = () => setIsModalOpen(false)
  const handleSaveClick = () => {
    handleCloseModal()
    setCroppedImage(preview)
  }

  return (
    <>
      {EditPreviewComponent ? (
        <EditPreviewComponent
          imageSrc={defaultImageSrc}
          handleEdit={handleEditIcon}
          className={editPreviewComponentClassName}
          isDisabled={isDisabled}
          iconSize={editIconSize}
        />
      ) : (
        <ImageCropEditPreview
          imageSrc={defaultImageSrc}
          emptyPreviewIcon={emptyPreviewIcon}
          width={editPreviewWidth}
          handleEdit={handleEditIcon}
          isDisabled={isDisabled}
          variant={variant}
        />
      )}

      <DialogWithCloseIcon
        open={isModalOpen}
        onClose={handleCloseModal}
        dialogTitle={dialogTitle}
        dialogWidth='550px'
        dialogHeight='425px'
      >
        <div className={classes.dialogBodyWrapper}>
          <div className={classes.imageCrop}>
            <ImageCrop
              defaultImageSrc={defaultImageSrc}
              setCroppedImage={setPreview}
              aspect={aspect}
              width={270}
              height={270}
              cropShape={cropShape}
              minZoom={minZoom}
              maxZoom={maxZoom}
              zoomStep={zoomStep}
              dimensions={dimensions || null}
            />
          </div>

          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography variant='subtitle2'>{dialogBottomText}</Typography>
            </Grid>
            <Grid item className={classes.buttonsWrapper}>
              <TextButton onClick={handleCloseModal} label={translate('ra.buttons.cancel')} />
              <ButtonWithLoader
                label={translate('ra.buttons.save')}
                onClick={handleSaveClick}
                type='button'
              />
            </Grid>
          </Grid>
        </div>
      </DialogWithCloseIcon>
    </>
  )
}

ImageCropWithEdit.propTypes = {
  defaultImageSrc: PropTypes.string,
  setCroppedImage: PropTypes.func,
  aspect: PropTypes.number,
  cropShape: PropTypes.string,
  minZoom: PropTypes.number,
  maxZoom: PropTypes.number,
  zoomStep: PropTypes.number,
  dialogTitle: PropTypes.string,
  dialogBottomText: PropTypes.string,
  isDisabled: PropTypes.bool,
  emptyPreviewIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  editPreviewWidth: PropTypes.number,
  editIconSize: PropTypes.string,
  EditPreviewComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  editPreviewComponentClassName: PropTypes.string,
}

ImageCropWithEdit.defaultProps = {
  dialogTitle: 'Crop Your Image',
  isDisabled: false,
}

export default ImageCropWithEdit
