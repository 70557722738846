import * as React from 'react'
import { useState } from 'react'
import { IconButton, makeStyles, Tooltip } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { useMutation, useQueryClient } from 'react-query'
import PropTypes from 'prop-types'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import useQueryParams from '../../hooks/useQueryParams'
import practiceBoxStyles from './practiceBox.styles'
import PracticeBoxDate from '../PracticeBoxDate/PracticeBoxDate'
import PracticeBoxIcons from '../PracticeBoxIcons/PracticeBoxIcons'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import NotebookIcon from '../../assets/icons/notebookIcon'
import CreatePracticePlanPopover from '../CreatePracticePlanPopover/CreatePracticePlanPopover'
import PracticePlanComponentsAccordion from '../PracticePlanComponentsAccordion/PracticePlanComponentsAccordion'
import useHistoryPush from '../../hooks/useHistoryPush'
import MyDeleteIcon from '../../assets/icons/MyDeleteIcon'
import QueryKeys from '../../utils/constants/queryKeys'
import deleteEvent from '../../Apis/event/deleteEvent'
import deletePracticePlan from '../../Apis/practice/deletePracticePlan'
import LoaderCircle from '../loaders/LoaderCircle/LoaderCircle'
import DeleteModal from '../DeleteModal/DeleteModal'
import practiceType from '../../types/practiceType'
import practicePlanType from '../../types/practicePlanType'
import PdfDownloadButton from '../PdfDownloadButton/PdfDownloadButton'
import PDF_VARIANTS from '../../utils/constants/pdfVariants'
import USER_ORG_ROLES from '../../utils/constants/userOrgRoles'
import USER_ORG_MEMBERS_ROLES from '../../utils/constants/userOrgMembersRoles'
import PracticePlayersComponentsAccordion from '../PracticePlayersComponentsAccordion/PracticePlayersComponentsAccordion'
import isUserOrgRolesValid from '../../utils/helpers/isUserOrgRolesValid'

const PracticeBox = (props) => {
  const { practice, setPracticePlansModal, setPracticeAccordionPressed } = props
  const { startDateTime, specificEventDetails, id, type } = practice
  const { notes, place, court, practicePlan, practicePlayers } = specificEventDetails
  const [createPracticePlanPopoverEl, setCreatePracticePlanPopoverEl] = useState(null)
  const [deletePracticeModalOpen, setDeletePracticeModalOpen] = useState(false)
  const date = new Date(startDateTime.replace('Z', ''))
  const hasManagerAccess = isUserOrgRolesValid([
    USER_ORG_ROLES.OWNER,
    USER_ORG_ROLES.ADMIN,
    USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
  ])
  const queryClient = useQueryClient()
  const { redirect } = useHistoryPush()
  const queryParams = useQueryParams()
  const translate = useTranslate()
  const useStyles = makeStyles(practiceBoxStyles(hasManagerAccess))
  const classes = useStyles()

  const deleteAPracticePlan = useMutation((practicePlanId) => deletePracticePlan(practicePlanId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICES).then((res) => res)
    },
  })

  const deleteAPractice = useMutation((data) => deleteEvent(data), {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.GET_PRACTICES).then((res) => res),
  })

  const handleClick = () => {
    if (createPracticePlanPopoverEl || !hasManagerAccess) {
      setCreatePracticePlanPopoverEl(null)
    } else {
      redirect(`/practice/${practice.id}`)
    }
  }

  const handleDeletePracticeClick = (e) => {
    e.stopPropagation()
    deleteAPractice.mutate({ eventTypeCode: type?.code, eventId: id })
    if (practicePlan) {
      deleteAPracticePlan.mutate(practicePlan?.id)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  return (
    <>
      <DeleteModal
        title={translate('ra.text.deletePractice')}
        open={deletePracticeModalOpen}
        onClose={() => setDeletePracticeModalOpen(false)}
        handleDelete={handleDeletePracticeClick}
        loading={deleteAPractice.isLoading}
        dialogWidth='40%'
      />

      <div
        role='button'
        className={classes.wrapper}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        tabIndex='0'
      >
        <div className={classes.practiceInfoWrapper}>
          <div className={classes.practiceIconsWrapper}>
            <PracticeBoxDate
              month={date.toLocaleString('default', { month: 'long' })}
              date={date.getDate()}
            />
            <PracticeBoxIcons date={startDateTime} place={place} location={court} />
          </div>
          <div className={classes.practiceButtonsWrapper}>
            <ConditionalRender renderValue={hasManagerAccess}>
              <ConditionalRender renderValue={place === 'Court' && !practicePlayers}>
                <Tooltip title={translate('ra.action.addPlayers')}>
                  <IconButton color='primary' component='div'>
                    <PlaylistAddIcon
                      className={classes.notebook}
                      onClick={(e) => {
                        e.stopPropagation()
                        redirect(
                          '/practice-players',
                          [
                            { eventId: practice.id },
                            { team: queryParams.team },
                            { goBack: '/practice' },
                          ],
                          ['breadcrumb'],
                        )
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </ConditionalRender>

              <ConditionalRender renderValue={place === 'Court' && !practicePlan}>
                <IconButton color='primary' aria-label='notebook icon' component='span'>
                  <NotebookIcon
                    className={classes.notebook}
                    onClick={(e) => {
                      e.stopPropagation()
                      setCreatePracticePlanPopoverEl(e?.currentTarget)
                    }}
                  />
                </IconButton>
                <CreatePracticePlanPopover
                  open={Boolean(createPracticePlanPopoverEl)}
                  onClose={() => {
                    setCreatePracticePlanPopoverEl(null)
                  }}
                  anchorEl={createPracticePlanPopoverEl}
                  handlePopoverCreateNewClick={() =>
                    redirect(
                      '/practice-plan',
                      [{ eventId: id }, { goBack: '/practice' }],
                      ['breadcrumb'],
                    )
                  }
                  setPracticePlansModal={setPracticePlansModal}
                  setPracticeAccordionPressed={setPracticeAccordionPressed}
                  practiceId={id}
                />
              </ConditionalRender>

              {deleteAPractice.isLoading ? (
                <LoaderCircle loading={deleteAPractice.isLoading} />
              ) : (
                <IconButton color='primary' aria-label='delete practice-plan' component='span'>
                  <MyDeleteIcon
                    className={classes.orangeIcon}
                    onClick={(e) => {
                      e.stopPropagation()
                      setDeletePracticeModalOpen(true)
                    }}
                  />
                </IconButton>
              )}
            </ConditionalRender>

            <ConditionalRender renderValue={practicePlan}>
              <PdfDownloadButton
                pdfVariant={PDF_VARIANTS.PRACTICE_PLAN_WITH_PLAYERS}
                title={`${practicePlan?.description}.pdf`}
                contentProps={{ practicePlan, practicePlayers }}
                className={classes.orangeIcon}
              />
            </ConditionalRender>
          </div>
        </div>

        <div className={classes.practiceAccordionWrapper}>
          <ConditionalRender renderValue={notes}>
            <span className={classes.notes}>{notes}</span>
          </ConditionalRender>

          <ConditionalRender renderValue={place === 'Court' && practicePlan}>
            <PracticePlanComponentsAccordion
              practicePlan={practicePlan}
              isModal={false}
              selectedEventId={id}
            />
          </ConditionalRender>

          <ConditionalRender renderValue={practicePlayers}>
            <PracticePlayersComponentsAccordion
              practicePlayers={practicePlayers}
              isModal={false}
              selectedEventId={id}
            />
          </ConditionalRender>
        </div>
      </div>
    </>
  )
}

PracticeBox.propTypes = {
  practice: practiceType,
  practicePlan: practicePlanType,
  setPracticePlansModal: PropTypes.func,
  setPracticeAccordionPressed: PropTypes.func,
}

export default PracticeBox
