import axiosInstance from '../axiosInterceptor/axiosInstance'
import { stringify } from 'query-string'

const getOrgPlayers = async ({ orgId }) => {
  const queryParams = { OrderBy: '-FirstName', clubId: orgId, page: 1, perPage: 50 }
  const url = `/players?${stringify(queryParams)}`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getOrgPlayers
