import axiosInstance from '../axiosInterceptor/axiosInstance'

const getEvent = async (data) => {
  const { eventTypeCode, eventId } = data
  const url = `/events/${eventTypeCode}/${eventId}`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getEvent
