import COLORS from '../../../utils/constants/colors'

const deleteConfigurationModalStyles = {
  dialogTitleRoot: {
    marginTop: '5px',
    paddingBottom: '5px',
  },
  dialogContentRoot: {
    paddingBottom: 15,
  },
  title: {
    fontWeight: 500,
    fontSize: '24px',
  },
  subtitle: {
    marginTop: '0px',
  },
  ownerMessage: {
    fontSize: 15,
    color: COLORS.errorRed,
  },
  formWrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '15px',
    gap: '10px',
  },
  cancelButton: {
    color: COLORS.red,
  },
  deleteButton: {
    '&&': {
      backgroundColor: COLORS.red,
    },
    '&&:hover': {
      backgroundColor: COLORS.red,
    },
  },
}

export default deleteConfigurationModalStyles
