const practiceBreadcrumb = async (data) => {
  const { teamName, breadcrumb } = data || {}

  return [
    teamName ? teamName.toUpperCase() : '',
    'PRACTICES',
    breadcrumb === 'create' ? 'CREATE' : null,
  ]
}

export default practiceBreadcrumb
