import React from 'react'
import '../../../../utils/constants/CSS/eventForms.css'
import { Field, Form, Formik } from 'formik'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import MyMultilineTextField from '../../fields/MyMultilineTextField'
import FormContainer from '../../../layout/FormContainer/FormContainer'
import MyDateField from '../../fields/MyDateField'
import MyTimeField from '../../fields/MyTimeField'
import MyTextField from '../../fields/MyTextField'
import GetFormDirtyValue from '../../../getFormDirtyValue/getFormDirtyValue'
import parseEventFormSubmit from '../../../../utils/helpers/eventHelpers/parseEventFormSubmit'
import MyGroupIcon from '../../../../assets/icons/myGroupIcon'
import MyAutocomplete from '../../fields/MyAutocomplete'
import AutoChangeFakeEvent from '../../AutoChangeFakeEvent'
import MyLocationOnIcon from '../../../../assets/icons/myLocationOnIcon'
import onEventFieldChange from '../../../../utils/helpers/eventHelpers/onEventFieldChange'
import mutationType from '../../../../types/mutationType'
import teamType from '../../../../types/teamType'
import eventVariantType from '../../../../types/events/eventVariantType'
import calendarDateClickType from '../../../../types/calendarDateClickType'
import eventType from '../../../../types/events/eventType'
import eventRawType from '../../../../types/events/eventRawType'
import {
  setEventEndTime,
  setEventStartTime,
} from '../../../../utils/helpers/eventHelpers/setEventTime'

const MeetingEventForm = (props) => {
  const {
    clickedEventId,
    createEvent,
    updateEvent,
    event,
    selectedEventType,
    setFormDirty,
    currentTeam,
    teams,
    dateClick,
    eventsRaw,
    setEvents,
  } = props
  const { location, notes, eventTeams } = event || {}
  const translate = useTranslate()
  const { onFromDateFieldChange, onStartTimeFieldChange, onEndTimeFieldChange } = onEventFieldChange

  return (
    <Formik
      initialValues={{
        fromDate: setEventStartTime(event, dateClick),
        startTime: setEventStartTime(event, dateClick),
        endTime: setEventEndTime(event, dateClick),
        teams: event?.teams ?? [currentTeam],
        location: event ? location : '',
        notes: event ? notes : '',
      }}
      onSubmit={(data) => {
        if (event) {
          updateEvent.mutate({
            formData: parseEventFormSubmit(data),
            eventId: clickedEventId,
            eventType: selectedEventType,
          })
        } else {
          createEvent.mutate({
            formData: parseEventFormSubmit(data),
            eventType: selectedEventType,
          })
        }
      }}
    >
      {() => (
        <Form id='my-form'>
          <FormContainer>
            <MyDateField
              name='fromDate'
              label={translate('ra.formFields.date')}
              required
              onChange={onFromDateFieldChange}
            />

            <div className='div-flex div-flex-responsive-1100'>
              <MyTimeField
                name='startTime'
                label={translate('ra.formFields.startTime')}
                required
                onChange={onStartTimeFieldChange}
              />
              <MyTimeField
                name='endTime'
                label={translate('ra.formFields.endTime')}
                required
                onChange={onEndTimeFieldChange}
              />
            </div>

            <div className='div-flex'>
              <MyGroupIcon />
              {currentTeam ? (
                event ? (
                  <span>{teams?.map((team) => team.name).join(', ')}</span>
                ) : (
                  <span>{currentTeam.name}</span>
                )
              ) : (
                <Field
                  name='teams'
                  label={translate('ra.formFields.teams')}
                  multiple
                  required
                  component={MyAutocomplete}
                  options={teams || []}
                  getOptionLabel={(option) => option.name}
                  defaultValue={event?.teams}
                  fullWidth
                />
              )}
            </div>

            <div className='div-flex'>
              <MyLocationOnIcon />
              <MyTextField label={translate('ra.formFields.location')} name='location' />
            </div>

            <MyMultilineTextField
              name='notes'
              label={translate('ra.formFields.notes')}
              multiline
              minRows={5}
              fullWidth
            />
            <GetFormDirtyValue setFormDirty={setFormDirty} />
          </FormContainer>
          <AutoChangeFakeEvent
            clickedEventId={clickedEventId}
            selectedEventType={selectedEventType}
            eventsRaw={eventsRaw}
            setEvents={setEvents}
          />
        </Form>
      )}
    </Formik>
  )
}

MeetingEventForm.propTypes = {
  clickedEventId: PropTypes.string,
  createEvent: mutationType,
  updateEvent: mutationType,
  event: eventType,
  selectedEventType: eventVariantType,
  setFormDirty: PropTypes.func,
  currentTeam: teamType,
  teams: PropTypes.arrayOf(teamType),
  dateClick: calendarDateClickType,
  eventsRaw: PropTypes.arrayOf(eventRawType),
  setEvents: PropTypes.func,
}

export default MeetingEventForm
