import COLORS from '../../utils/constants/colors'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { makeStyles } from '@material-ui/core'

const ExpandIcon = (props) => {
  const useStyles = makeStyles({
    icon: {
      fill: COLORS.lightGrey,
      width: '17px',
    },
  })
  const classes = useStyles()

  return <KeyboardArrowDown {...props} classes={{ root: classes.icon }} />
}

export default ExpandIcon
