const coachCornerStyles = {
  coachCornerTitle: {
    paddingLeft: 18,
    paddingBottom: 5,
    fontSize: '90%',
    color: '#555555',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 17,
  },
}

export default coachCornerStyles
