import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import getOrgs from '../../Apis/organisation/getOrgs'
import localStorageHelper from '../../utils/helpers/localStorageHelper'
import getTeams from '../../Apis/team/getTeams'
import useQueryParams from '../../hooks/useQueryParams'
import QueryKeys from '../../utils/constants/queryKeys'
import useHistoryPush from '../../hooks/useHistoryPush'
import localStorageIds from '../../utils/constants/localStorageIds'
import orgIsInSubscriptionState from '../../utils/helpers/orgIsInSubscriptionState'
import SUBSCRIPTION_STATES from '../../utils/constants/subscriptionStates'
import userHasOnlySpecificScopes from '../../utils/helpers/userHasOnlySpecificScopes'
import { USER_SCOPES } from '../../utils/constants/userScopes'
import isUserOrgRolesValid from '../../utils/helpers/isUserOrgRolesValid'
import USER_ORG_ROLES from '../../utils/constants/userOrgRoles'

const SetUrlAfterLogin = () => {
  const [org, setOrg] = useState('')
  const { redirect } = useHistoryPush()
  const queryParams = useQueryParams()
  const { pathname } = useHistoryPush()
  const orgLocalStorageHelper = localStorageHelper(localStorageIds.SELECTED_ORG)
  const teamLocalStorageHelper = localStorageHelper(localStorageIds.SELECTED_TEAM)

  // Clean organisation after logout
  useEffect(
    () => () => {
      setOrg('')
    },
    [],
  )

  const HAS_ORGS = 'hasOrgs'
  const HAS_NO_ORGS = 'noOrgs'

  const ON_GET_ORGS_SUCCESS = {
    [HAS_ORGS]: (data) => {
      const oldUrlOrgId = queryParams?.org || orgLocalStorageHelper?.localStorageValue?.id
      const existingOrg = data.find((c) => c.id === oldUrlOrgId)

      setOrg(existingOrg || data[0])
      orgLocalStorageHelper.setLocalStorageValue(existingOrg || data[0])
    },
    [HAS_NO_ORGS]: () => {
      // Remove ths organisation and the team from local storage

      orgLocalStorageHelper.removeLocalStorageValue()
      teamLocalStorageHelper.removeLocalStorageValue()
    },
  }

  useQuery([QueryKeys.GET_ORGS_AFTER_LOGIN], () => getOrgs(), {
    onSuccess: (orgs) => {
      if (orgs.length > 0) {
        ON_GET_ORGS_SUCCESS[HAS_ORGS](orgs)
      } else {
        ON_GET_ORGS_SUCCESS[HAS_NO_ORGS]()
      }
    },
  })

  const HAS_EXISTING_TEAM = 'hasExistingTeam'
  const HAS_ONLY_ONE_TEAM = 'hasOnlyOneTeam'
  const HAS_NO_TEAMS = 'noTeams'

  const ON_GET_TEAMS_SUCCESS = {
    [HAS_EXISTING_TEAM]: (existingTeam, newPathnameTeam) => {
      teamLocalStorageHelper.setLocalStorageValue(existingTeam)
      redirect(`${newPathnameTeam}`, [{ org: org?.id }, { team: existingTeam.id }])
    },
    [HAS_ONLY_ONE_TEAM]: (data, newPathnameTeam) => {
      redirect(`${newPathnameTeam}`, [{ org: org?.id }, { team: data[0].id }])
      teamLocalStorageHelper.removeLocalStorageValue(data[0])
    },
    [HAS_NO_TEAMS]: (newPathname) => {
      redirect(`${newPathname}`, [{ org: org?.id }])
      teamLocalStorageHelper.removeLocalStorageValue()
    },
  }

  useQuery([QueryKeys.GET_TEAMS_AFTER_LOGIN, org?.id], () => getTeams(org?.id), {
    enabled: Boolean(org?.id),
    onSuccess: (data) => {
      const newPathname = pathname === '/' ? '/calendar' : pathname
      const newPathnameTeam = pathname === '/' ? '/teamCalendar' : pathname

      // TODO: Add mapper
      if (
        orgIsInSubscriptionState([SUBSCRIPTION_STATES.INACTIVE]) &&
        userHasOnlySpecificScopes([USER_SCOPES.COACH]) &&
        isUserOrgRolesValid([USER_ORG_ROLES.OWNER])
      ) {
        redirect('subscription', [{ org: org?.id }])
        return teamLocalStorageHelper.removeLocalStorageValue()
      }

      if (!data?.length) {
        return ON_GET_TEAMS_SUCCESS[HAS_NO_TEAMS](newPathname)
      }

      const oldUrlTeamId = queryParams?.team || teamLocalStorageHelper?.localStorageValue?.id
      const existingTeam = data?.find((team) => team.id === oldUrlTeamId)

      if (existingTeam) {
        return ON_GET_TEAMS_SUCCESS[HAS_EXISTING_TEAM](existingTeam, newPathnameTeam)
      }

      if (data?.length === 1) {
        return ON_GET_TEAMS_SUCCESS[HAS_ONLY_ONE_TEAM](data, newPathnameTeam)
      }

      return redirect(`${newPathname}`, [{ org: org?.id }])
    },
  })

  return null
}

export default SetUrlAfterLogin
