import React, { useEffect, useState } from 'react'
import { makeStyles, MenuItem, Tooltip, Typography } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { useMyLayout } from '../../context/useMyLayout'
import coachCornerStyles from './coachCorner.styles'
import createMenuItemLinkStyle from '../../utils/helpers/createMenuItemLinkStyle'
import useHistoryPush from '../../hooks/useHistoryPush'
import MENU_COACH_CORNER from '../../utils/constants/menuCoachCorner'
import TOUR_IDS from '../../utils/constants/introIds'
import coachCornerOptionWithAuth from '../../utils/helpers/coachCornerOptionWithAuth'

const CoachCorner = () => {
  const [visible, setVisible] = useState(false)
  const openSidebar = useSelector((state) => state.admin.ui.sidebarOpen)
  const { menuChoice, error } = useMyLayout()
  const { redirect } = useHistoryPush()
  const translate = useTranslate()
  const useStyles = makeStyles(coachCornerStyles)
  const classes = useStyles()

  // In order to show "Coach's Corner" after an amount of time.
  useEffect(() => {
    if (openSidebar) {
      setTimeout(() => {
        setVisible(true)
      }, 100)
    }
    setVisible(false)
  }, [openSidebar])

  return (
    <div id={TOUR_IDS.calendar.coach}>
      {openSidebar && visible ? (
        <Typography variant='subtitle1' className={classes.coachCornerTitle}>
          {translate('ra.text.coachCorner')}
        </Typography>
      ) : null}
      {MENU_COACH_CORNER.map((item) => (
        <Tooltip
          key={item.name}
          title={
            !error
              ? translate('ra.text.unauthorizedTooltip')
              : translate('ra.errorMessages.connectionError')
          }
          disableHoverListener={coachCornerOptionWithAuth() && !error}
          placement='right'
          arrow
        >
          <div>
            <MenuItem
              key={item.name}
              onClick={() => redirect(`/${item.name}`)}
              style={createMenuItemLinkStyle(menuChoice, item.name)}
              disabled={!coachCornerOptionWithAuth() || error}
            >
              <div className={classes.textWrapper}>
                {menuChoice === item.name ? item.iconSelected : item.icon}
                {item.text}
              </div>
            </MenuItem>
          </div>
        </Tooltip>
      ))}
    </div>
  )
}

export default CoachCorner
