import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { Grid, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router'
import decodeJwt from 'jwt-decode'
import { useMutation, useQueryClient } from 'react-query'
import PropTypes from 'prop-types'
import ContainerPadding from '../../../containerPadding/containerPadding'
import MyTextField from '../../fields/MyTextField'
import ImageCrop from '../../../ui/ImageCropComponents/ImageCrop/ImageCrop'
import ButtonWithLoader from '../../../ui/ButtonWithLoader/ButtonWithLoader'
import OrgAvatarEmptyPreview from '../../../orgAvatarEmptyPreview/OrgAvatarEmptyPreview'
import addOrg from '../../../../Apis/organisation/addOrg'
import { setLocalStorageValue } from '../../../../utils/helpers/localStorageHelperMethods'
import localStorageIds from '../../../../utils/constants/localStorageIds'
import QueryKeys from '../../../../utils/constants/queryKeys'
import ORGANISATION_TYPES from '../../../../utils/constants/OrganisationTypes'
import addLeagueFormStyles from './addLeagueForm.styles'

const AddLeagueForm = (props) => {
  const { stepForward } = props
  const [croppedOrgImage, setCroppedOrgImage] = useState(null)
  const queryClient = useQueryClient()
  const history = useHistory()
  const translate = useTranslate()
  const useStyles = makeStyles(addLeagueFormStyles)
  const classes = useStyles()

  const addAOrg = useMutation((data) => addOrg(data), {
    onSuccess: ({ data }) => {
      const { Subscriptions } = decodeJwt(data?.accessToken)
      setLocalStorageValue([
        { id: localStorageIds.TOKEN, value: data?.accessToken },
        { id: localStorageIds.SELECTED_ORG, value: data?.org },
        {
          id: localStorageIds.SUBSCRIPTIONS,
          value: Subscriptions,
        },
      ])

      queryClient.invalidateQueries(QueryKeys.GET_ACCOUNT_ME)
      history.push({
        pathname: '/calendar',
        search: `?org=${data?.org.id}`,
      })
      stepForward()
    },
  })

  const handleSubmit = (formData) => {
    addAOrg.mutate({
      name: formData.orgName,
      logo: croppedOrgImage,
      type: ORGANISATION_TYPES.ORG_LEAGUE,
      color: formData.orgColour?.value?.substring(1),
    })
  }

  return (
    <Formik
      initialValues={{
        orgName: '',
        orgColour: null,
      }}
      onSubmit={(values) => handleSubmit(values)}
    >
      {() => (
        <Form className={classes.form}>
          <ContainerPadding
            paddingValue='2%'
            style={{ display: 'flex', gap: 30, marginTop: 'auto', marginBottom: 'auto' }}
          >
            <Grid container xs={6}>
              <Grid className={classes.avatarContainer}>
                <OrgAvatarEmptyPreview croppedImage={croppedOrgImage} />
              </Grid>
              <MyTextField
                name='orgName'
                label={translate('ra.formFields.name')}
                variant='filled'
                required
              />
            </Grid>
            <Grid container xs={6} className={classes.imageCropContainer}>
              <ImageCrop
                setCroppedImage={setCroppedOrgImage}
                width={240}
                height={240}
                addImageLabel={translate('ra.buttons.uploadLeagueLogo')}
              />
            </Grid>
          </ContainerPadding>
          <Grid container justifyContent='flex-end' alignItems='center' style={{ marginTop: 10 }}>
            <ButtonWithLoader
              label={translate('ra.buttons.next')}
              loading={addAOrg.isLoading}
              justifyContent='flex-end'
            />
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

AddLeagueForm.propTypes = {
  stepForward: PropTypes.func,
}

export default AddLeagueForm
