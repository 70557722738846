import {
  Checkbox,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TablePagination,
  TableHead,
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import { isArray } from 'pdfmake/src/helpers'
import myTableStyles from './myTable.styles'
import COLORS from '../../../utils/constants/colors'

const MyTable = ({
  children,
  rows,
  onRowClick,
  allowClickHoleLine,
  hasHeader,
  size,
  boldHeadDivider,
  hasNoDivider,
  hasCheckbox,
  allowMultipleChecked,
  selectedItem,
  setUpperSelectState,
  checkBoxStyle,
  tableCompStyles,
  tableStyles,
  tableCellStyles,
  boldHeader,
  fontsize,
  headerColumnAlignment,
  hasPagination,
  numRowsShow,
  startingSelection,
  disabledRowId,
}) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(numRowsShow || 10)
  const [selectedRows, setSelectedRows] = useState([])
  const useStyles = makeStyles(
    myTableStyles(hasNoDivider, tableCompStyles, tableStyles, boldHeader, fontsize),
  )
  const classes = useStyles()

  useEffect(() => {
    if (startingSelection) {
      const initialSelectedRows = rows.filter((row) =>
        startingSelection.some((selection) => selection.id === row.id),
      )
      if (initialSelectedRows.length > 0) {
        setSelectedRows(initialSelectedRows)
      }
    }
  }, [rows])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((row) => row)
      setSelectedRows(newSelected)
      setUpperSelectState(newSelected)
    } else {
      const disabledRow = rows.find((row) => row.id === disabledRowId)
      const newSelected = disabledRow ? [disabledRow] : []
      setSelectedRows(newSelected)
      setUpperSelectState(newSelected)
    }
  }

  const handleClick = (row) => {
    const selectedIndex = selectedRows.find((selectedRow) => selectedRow.id === row.id)
    let newSelected = []

    if (!selectedIndex) {
      newSelected = allowMultipleChecked ? newSelected.concat(selectedRows, row) : [row]
    } else {
      newSelected = selectedRows.filter((theRow) => theRow.id !== row.id)
    }

    setUpperSelectState(allowMultipleChecked ? newSelected : newSelected[0])
    setSelectedRows(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <TableContainer className={classes.tableContainer}>
      <Table size={size} stickyHeader className={classes.tableContainer}>
        <TableHead className={classes.tableHead}>
          {hasHeader && (
            <TableRow
              role='checkbox'
              style={{
                borderBottom: boldHeadDivider && '3px solid rgba(224, 224, 224, 1)',
                borderColor: boldHeadDivider && COLORS.black,
              }}
            >
              {hasCheckbox && (
                <TableCell padding='checkbox' className={classes.tableHeader}>
                  {allowMultipleChecked && (
                    <Checkbox
                      icon={checkBoxStyle === 'circle' ? <CircleUnchecked /> : undefined}
                      checkedIcon={
                        checkBoxStyle === 'circle' ? <RadioButtonCheckedIcon /> : undefined
                      }
                      color='primary'
                      checked={selectedRows.length === rows.length}
                      onClick={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  )}
                </TableCell>
              )}

              {isArray(children) ? (
                children.map((child) => (
                  <TableCell
                    align={headerColumnAlignment}
                    className={classes.tableHeader}
                    style={tableCellStyles}
                  >
                    <Typography className={classes.typographyBold}>{child.props.title}</Typography>
                  </TableCell>
                ))
              ) : (
                <TableCell
                  align={headerColumnAlignment}
                  className={classes.tableHeader}
                  style={tableCellStyles}
                >
                  <Typography className={classes.typographyBold}>{children.props.title}</Typography>
                </TableCell>
              )}
            </TableRow>
          )}
        </TableHead>

        <TableBody>
          {rows.map((row, index) => {
            const rowIsSelected = selectedItem
              ? selectedItem?.id === row.id
              : selectedRows.some((selectedRow) => selectedRow.id === row.id)
            const isDisabled = row.id === disabledRowId

            return (
              <TableRow
                key={row.id}
                role='checkbox'
                onClick={(event) =>
                  onRowClick && allowClickHoleLine && onRowClick(event, row, index)
                }
                style={isDisabled ? { backgroundColor: '#f5f5f5' } : {}}
              >
                {hasCheckbox && (
                  <TableCell
                    padding='checkbox'
                    className={classes.tableHeader}
                    style={tableCellStyles}
                  >
                    <Checkbox
                      icon={checkBoxStyle === 'circle' ? <CircleUnchecked /> : undefined}
                      checkedIcon={
                        checkBoxStyle === 'circle' ? <RadioButtonCheckedIcon /> : undefined
                      }
                      color='primary'
                      checked={rowIsSelected}
                      onClick={() => handleClick(row)}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                      disabled={isDisabled} // Disable checkbox if row is disabled
                    />
                  </TableCell>
                )}
                {isArray(children)
                  ? children.map((child) => {
                      // Maybe transfer tableCell from Fields here in 'return'
                      return React.cloneElement(
                        child,
                        {
                          label: row[child.key],
                          row,
                          rowIsSelected,
                          hasNoDivider,
                          tableCellStyles,
                          styles: classes.typographyLight,
                        },
                        null,
                      )
                    })
                  : React.cloneElement(
                      children,
                      {
                        label: row[children.key],
                        row,
                        rowIsSelected,
                        hasNoDivider,
                        tableCellStyles,
                        styles: classes.typographyLight,
                      },
                      null,
                    )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {hasPagination && (
        <TablePagination
          className={classes.pagination}
          component='div'
          count={rows.length} // Instead of "rows.length" will be total rows number
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  )
}

export default MyTable
