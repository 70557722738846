import React from 'react'
import { makeStyles } from '@material-ui/core'
import PauseIcon from '@material-ui/icons/Pause'
import COLORS from '../../utils/constants/colors'

const MyPauseIcon = (props) => {
  const useStyles = makeStyles({
    addIcon: {
      width: 24,
      height: 24,
      fill: COLORS.white,
      cursor: 'pointer',
    },
  })
  const classes = useStyles()

  return <PauseIcon {...props} classes={{ root: classes.addIcon }} />
}

export default MyPauseIcon
