const setIntensityForPractices = (props) => {
  const { practicePlan, intensities } = props

  return {
    ...practicePlan,
    practicePlanComponents: practicePlan?.practicePlanComponents?.map((comp) => {
      return {
        ...comp,
        ...(comp.trainingModule && {
          trainingModule: {
            ...comp.trainingModule,
            ...(comp.trainingModule.intensities.length > 0
              ? {
                  intensities: comp.trainingModule.intensities
                    .map((intensity) => {
                      return intensities.filter((int) => intensity.name === int.name)
                    })
                    .flat(),
                }
              : {
                  intensities: [],
                }),
          },
        }),
      }
    }),
  }
}

export default setIntensityForPractices
