import COLORS from '../../utils/constants/colors'

const livestreamBannerStopConfirmationModalStyles = {
  buttonsWrapper: {
    paddingRight: 10,
  },
  cancelButton: {
    '&&:hover': {
      backgroundColor: 'rgba(63, 81, 181, 0.04)',
    },

    display: 'flex',
    alignItems: 'center',
    color: COLORS.orange,
    backgroundColor: COLORS.white,
    border: COLORS.white,
    borderRadius: '4px',
    height: 35,
    minWidth: 68.9844,
    fontSize: '0.875rem',
    fontWeight: 600,
    padding: '6px 16px',
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  stopButton: {
    display: 'flex',
    alignItems: 'center',
    color: COLORS.white,
    backgroundColor: COLORS.red,
    border: COLORS.red,
    borderRadius: '4px',
    height: 35,
    minWidth: 68.9844,
    fontSize: '0.875rem',
    fontWeight: 600,
    padding: '6px 16px',
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
}

export default livestreamBannerStopConfirmationModalStyles
