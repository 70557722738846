import React from 'react'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import { v4 } from 'uuid'
import PropTypes from 'prop-types'
import imageCropAddPreviewStyles from './imageCropAddPreview.styles'
import MyCloudUploadIcon from '../../../../assets/icons/MyCloudUploadIcon'

const ImageCropAddPreview = (props) => {
  const { width, height, handleAdd, label } = props
  const translate = useTranslate()
  // Generate a random id for the case two imageCrop used simultaneously.
  const inputId = v4()
  const useStyles = makeStyles(imageCropAddPreviewStyles(width, height))
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <label className={classes.inputLabel} htmlFor={inputId}>
        <input
          className={classes.fileInput}
          id={inputId}
          type='file'
          accept='image/*'
          onChange={handleAdd}
        />
      </label>
      <div className={classes.chooseFile}>
        <MyCloudUploadIcon />
        <span>{label ?? translate('ra.buttons.chooseFile')}</span>
      </div>
    </div>
  )
}

ImageCropAddPreview.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  handleAdd: PropTypes.func,
  label: PropTypes.string,
}

export default ImageCropAddPreview
