import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-admin'
import { useFormikContext } from 'formik'
import { useQuery } from 'react-query'
import { Divider, Grid, List, ListItem, ListItemText, makeStyles } from '@material-ui/core'
import QueryKeys from '../../../utils/constants/queryKeys'
import DialogWithCloseIcon from '../../DialogWithCloseIcon/DialogWithCloseIcon'
import GAME_EVENT_FORM_BROADCAST_FIELDS from '../../../utils/constants/gameEventFormBroadcastFields'
import getRecentLivestreams from '../../../Apis/livestream/getRecentLivestreams'
import { RECENT_BROADCASTS_LIMIT } from '../../../utils/constants/broadcastStates'
import ConditionalRender from '../../ConditionalRender/ConditionalRender'
import ButtonWithLoader from '../../ui/ButtonWithLoader/ButtonWithLoader'
import selectRecentLivestreamDialogStyles from './selectRecentLivestreamDialog.styles'
import YOUTUBE_STREAM_URL from '../../../utils/constants/youtubeStreamUrl'
import localStorageHelper from '../../../utils/helpers/localStorageHelper'
import LoaderDots from '../../loaders/LoaderDots/LoaderDots'
import orgSettingPageStyles from '../../../pages/OrgSettings/OrgSettingsPage/orgSettingsPage.styles'
import localStorageIds from '../../../utils/constants/localStorageIds'

const SelectRecentLivestreamDialog = (props) => {
  const { open, onClose } = props
  const [selectedIndex, setSelectedIndex] = useState('')
  const [recentBroadcasts, setRecentBroadcasts] = useState([])
  const translate = useTranslate()
  const { values, setValues } = useFormikContext()
  const { localStorageValue } = localStorageHelper(localStorageIds.SELECTED_ORG)
  const hasSetYoutube = !!localStorageValue?.youTubeChannelName
  const useStyles = makeStyles(selectRecentLivestreamDialogStyles)
  const classes = useStyles()

  const { isLoading } = useQuery(
    [QueryKeys.GET_RECENT_BROADCASTS, localStorageValue?.id],
    () => getRecentLivestreams({ orgId: localStorageValue?.id, limit: RECENT_BROADCASTS_LIMIT }),
    {
      onSuccess: (res) => {
        // Don't show the ones with yt configuration if signed out
        // TODO: This check should be happening on the be, because now it may not show any recent broadcasts if all of the recent are yt
        setRecentBroadcasts(
          hasSetYoutube
            ? res
            : res.filter(
                (rec) => !Object.values(rec?.event_yt_config).filter((val) => val !== null)?.length,
              ),
        )
      },
    },
  )

  const handleListItemClick = (index) => setSelectedIndex(index)

  const handleClickSelect = (data) => {
    const hasYoutubeConfig = !!data[selectedIndex]?.event_yt_config?.broadcastTitle

    let streamData

    if (hasYoutubeConfig) {
      const { thumbnailUrl, broadcastTitle, visibility } =
        data[selectedIndex]?.event_yt_config || {}

      streamData = {
        [GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG]: {
          [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_THUMBNAIL]: thumbnailUrl ?? null,
          [GAME_EVENT_FORM_BROADCAST_FIELDS.BROADCAST_TITLE]: broadcastTitle ?? '',
          [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_VISIBILITY]: visibility ?? '',
        },
        [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY]: null,
      }
    } else {
      streamData = {
        [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY]: data[
          selectedIndex
        ].streamer_output_url?.replace(YOUTUBE_STREAM_URL, ''),
        [GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG]: null,
      }
    }

    setValues({
      ...values,
      ...streamData,
      ...(data[selectedIndex].streamer_output2_url
        ? { [GAME_EVENT_FORM_BROADCAST_FIELDS.IS_ADDITIONAL_YOUTUBE_STREAM_KEY_ADDED]: true }
        : values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_ADDITIONAL_YOUTUBE_STREAM_KEY_ADDED]),
      [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY_2]: data[
        selectedIndex
      ].streamer_output2_url?.replace(YOUTUBE_STREAM_URL, ''),
      [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_TOP_LEFT_LOGO]: data[selectedIndex].court_tl_logo_url,
      [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_TOP_RIGHT_LOGO]:
        data[selectedIndex].court_tr_logo_url,
      [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_BOTTOM_LEFT_LOGO]:
        data[selectedIndex].court_bl_logo_url,
      [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_BOTTOM_RIGHT_LOGO]:
        data[selectedIndex].court_br_logo_url,
      [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_CENTER_LOGO]: data[selectedIndex].court_cntr_logo_url,
    })

    onClose()
  }

  return (
    <DialogWithCloseIcon
      open={open}
      onClose={onClose}
      dialogTitle={translate('ra.buttons.loadRecentSetups')}
    >
      <List component='nav'>
        <ConditionalRender renderValue={isLoading}>
          <Grid container justifyContent='center'>
            <LoaderDots className={orgSettingPageStyles.loader} />
          </Grid>
        </ConditionalRender>

        {recentBroadcasts?.length > 0 ? (
          recentBroadcasts.map((livestream, index) => (
            <div key={livestream.uuid}>
              <ListItem
                button
                selected={selectedIndex === index}
                onClick={() => handleListItemClick(index)}
              >
                <ListItemText
                  primary={livestream?.name}
                  primaryTypographyProps={{ className: classes.listItemText }}
                />
              </ListItem>

              <ConditionalRender renderValue={index !== recentBroadcasts?.length - 1}>
                <Divider />
              </ConditionalRender>
            </div>
          ))
        ) : (
          <span className={classes.emptyText}>{translate('ra.text.noRecentLivestream')}</span>
        )}
      </List>

      <Grid container justifyContent='flex-end'>
        <ButtonWithLoader
          className={classes.selectButton}
          label={translate('ra.buttons.select')}
          type='button'
          onClick={() => handleClickSelect(recentBroadcasts)}
          disabled={typeof selectedIndex !== 'number'}
        />
      </Grid>
    </DialogWithCloseIcon>
  )
}

SelectRecentLivestreamDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default SelectRecentLivestreamDialog
