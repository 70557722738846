import React from 'react'
import { useField, useFormState } from 'react-final-form'
import PropTypes from 'prop-types'
import ImageCrop from '../../../components/ui/ImageCropComponents/ImageCrop/ImageCrop'
import CROPPER_SHAPES from '../../../utils/constants/cropperShapes'

const MyAddLogoInput = (props) => {
  const { source } = props
  const {
    input: { onChange },
  } = useField(source)
  const formState = useFormState()

  return (
    <ImageCrop
      setCroppedImage={(croppedImage) => onChange(croppedImage)}
      formValues={formState.values}
      width={170}
      height={170}
      cropShape={CROPPER_SHAPES.RECT}
      dimensions={{ width: 400, height: 400 }}
    />
  )
}

MyAddLogoInput.propTypes = {
  source: PropTypes.string,
}

export default MyAddLogoInput
