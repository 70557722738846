import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useTranslate } from 'react-admin'
import * as yup from 'yup'
import { Form, Formik } from 'formik'
import { Link, makeStyles } from '@material-ui/core'
import forgotPasswordFormStyles from './forgotPasswordForm.styles'
import MyTextField from '../../../components/form/fields/MyTextField'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import useFormValidation from '../../../hooks/useFormValidation'
import sendResetPasswordEmail from '../../../Apis/user/sendResetPasswordEmail'
import MySnackbar from '../../../components/MySnackbar/MySnackbar'

const ForgotPasswordForm = () => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const translate = useTranslate()
  const { email } = useFormValidation()
  const useStyles = makeStyles(forgotPasswordFormStyles)
  const classes = useStyles()

  const sendResetEmail = useMutation((data) => sendResetPasswordEmail(data), {
    onSettled: () => setIsSnackbarOpen(true),
  })

  const onCloseSnackbar = () => setIsSnackbarOpen(false)

  const onSubmit = (data) => {
    sendResetEmail.mutate({
      email: data.electronicMail,
    })
  }

  return (
    <>
      <Formik
        initialValues={{
          electronicMail: '',
        }}
        validationSchema={yup.object().shape({
          electronicMail: email,
        })}
        onSubmit={(data) => onSubmit(data)}
      >
        {() => (
          <Form>
            <div className={classes.formWrapper}>
              <MyTextField
                label={translate('ra.formFields.email')}
                name='electronicMail'
                required
              />
              <ButtonWithLoader
                fullWidth
                label={translate('ra.buttons.submit')}
                style={{ marginTop: 10 }}
                loading={sendResetEmail.isLoading}
              />
              <Link href='/login' variant='body2' className={classes.link}>
                {translate('ra.gridLinks.login')}
              </Link>
            </div>
          </Form>
        )}
      </Formik>
      <MySnackbar
        isOpen={isSnackbarOpen}
        onClose={onCloseSnackbar}
        isSuccess={sendResetEmail.isSuccess}
        successMessage={translate('ra.successMessages.emailResetPasswordSuccess')}
        errorMessage={translate('ra.errorMessages.emailResetPasswordError')}
      />
    </>
  )
}

export default ForgotPasswordForm
