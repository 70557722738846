import * as React from 'react'
import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core'
import { useListContext } from 'react-admin'
import LeftArrowIcon from '../../../../assets/icons/leftArrowIcon'
import RightArrowIcon from '../../../../assets/icons/rightArrowIcon'
import playerCreatePaginationStyles from './playerCreatePagination.styles'

const PlayerCreatePagination = () => {
  const { page, perPage, total, setPage, setPerPage } = useListContext()
  const nbPages = Math.ceil(total / perPage) || 1
  const useStyles = makeStyles(playerCreatePaginationStyles(page, nbPages))
  const classes = useStyles()

  const startCount = page * perPage - perPage
  const endCount = page * perPage < total ? page * perPage : total
  const countString = `${startCount} - ${endCount} of ${total}`

  return (
    <div className={classes.paginationWrapper}>
      <div className={classes.perPageWrapper}>
        Rows per page:
        <FormControl size='small'>
          <Select
            value={perPage}
            onChange={(e) => setPerPage(e.target.value)}
            disableUnderline
            classes={{
              select: classes.select,
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={40}>40</MenuItem>
          </Select>
        </FormControl>
      </div>
      <span className={classes.countString}>{countString}</span>
      <LeftArrowIcon className={classes.leftIcon} onClick={() => page > 1 && setPage(page - 1)} />
      <RightArrowIcon
        className={classes.rightIcon}
        onClick={() => page !== nbPages && setPage(page + 1)}
      />
    </div>
  )
}

export default PlayerCreatePagination
