import COLORS from '../../utils/constants/colors'

const selectEventTypeFieldStyles = {
  select: {
    '&:focus': {
      backgroundColor: `${COLORS.white}!important`,
    },
  },
  disabledBlack: {
    color: COLORS.black,
  },
  icon: {
    fill: COLORS.orange,
    right: -10,
  },
}

export default selectEventTypeFieldStyles
