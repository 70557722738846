import React from 'react'
import { makeStyles, TableCell, Typography, Tooltip } from '@material-ui/core'
import multiStringWithCommaFieldStyles from './multiStringWithCommaField.styles'

const MultiStringWithCommaField = ({
  label,
  columnAlignment,
  rowIsSelected,
  hasNoDivider,
  maxStringsShown,
}) => {
  const useStyles = makeStyles(multiStringWithCommaFieldStyles)
  const classes = useStyles()
  const labelArray = Array.isArray(label) ? label : []

  const showedString =
    labelArray.slice(0, maxStringsShown).length > 0 &&
    labelArray.slice(0, maxStringsShown).join(', ')

  const toolTipContent =
    labelArray.length > 0 &&
    labelArray
      .slice(maxStringsShown)
      .map((value, index) => (
        <Typography color={rowIsSelected ? 'primary' : undefined}>
          {index > 0 ? `\n${value}` : value}
        </Typography>
      ))

  return (
    <TableCell
      align={columnAlignment}
      style={{
        borderBottom: hasNoDivider ? 'none' : '1px solid rgba(224, 224, 224, 1)',
      }}
    >
      <div className={classes.cellContainer}>
        <Typography color={rowIsSelected ? 'primary' : undefined}>{showedString}</Typography>

        {labelArray.length > 0 && maxStringsShown < labelArray.length && (
          <>
            <Typography color={rowIsSelected ? 'primary' : undefined}>...</Typography>
            <Tooltip title={toolTipContent} arrow className={classes.teamTooltip}>
              <span className={classes.arrayLength}>{labelArray.length - maxStringsShown}</span>
            </Tooltip>
          </>
        )}
      </div>
    </TableCell>
  )
}

export default MultiStringWithCommaField
