import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import { FilterButton, TopToolbar, useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import playerListActionsStyles from './playerListActions.styles'
import AddButton from '../../../../components/ui/AddButton/AddButton'
import useQueryParams from '../../../../hooks/useQueryParams'
import USER_ORG_MEMBERS_ROLES from '../../../../utils/constants/userOrgMembersRoles'
import USER_ORG_ROLES from '../../../../utils/constants/userOrgRoles'
import IsUserRolesAuthorized from '../../../../components/IsUserRolesAuthorized/IsUserRolesAuthorized'

const PlayerListActions = () => {
  const queryParams = useQueryParams()
  const translate = useTranslate()
  const useStyles = makeStyles(playerListActionsStyles)
  const classes = useStyles()

  const addButtonUrl = `/players/create?org=${queryParams?.org}${
    queryParams?.team ? `&team=${queryParams.team}` : ''
  }`

  return (
    <TopToolbar classes={{ root: classes.topToolbarRoot }}>
      <FilterButton label={translate('ra.action.add_filter')} className={classes.filterButton} />
      <IsUserRolesAuthorized
        acceptedRoles={[
          USER_ORG_ROLES.OWNER,
          USER_ORG_ROLES.ADMIN,
          USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
        ]}
      >
        <AddButton
          label={translate('ra.buttons.addPlayer')}
          component={Link}
          to={addButtonUrl}
          filled
        />
      </IsUserRolesAuthorized>
    </TopToolbar>
  )
}

export default PlayerListActions
