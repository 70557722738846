import COLORS from '../../utils/constants/colors'

const moreIconMenuStyles = {
  iconButton: {
    marginLeft: 5,
    marginRight: 5,
  },
  orgMenuItems: {
    padding: '10px 16px',
  },
  orgMenuItemsBody: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    color: COLORS.darkGrey,
  },
  emptyDiv: {
    width: 20,
    marginLeft: 5,
    marginRight: 5,
  },
}

export default moreIconMenuStyles
