import React from 'react'
import { Chip, makeStyles, TableCell } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import COLORS from '../../../../utils/constants/colors'
import chipTableFieldStyles from './chipTableField.styles'
import TextButton from '../../../ui/TextButton/TextButton'
import MyEmailResend from '../../../../assets/icons/MyEmailResend'
import DownloadIcon from '../../../../assets/icons/downloadIcon'

const ChipTableField = ({ columnAlignment, label, actions, variant, tableCellStyles }) => {
  const CHIP_LABEL = {
    Active: 'Active',
    Pending: 'Pending',
    Rejected: 'Rejected',
    Completed: 'Completed',
    Failed: 'Failed',
    Paid: 'Completed',
    Cancelled: 'Cancelled',
    Error: 'Failed',
  }

  const CHIP_STATES = {
    Active: 'active',
    Pending: 'pending',
    Rejected: 'rejected',
    Completed: 'completed',
    Failed: 'failed',
  }

  const BACKGROUND_COLORS = {
    [CHIP_STATES.Active]: '#F5F6E9',
    [CHIP_STATES.Pending]: '#814DDE',
    [CHIP_STATES.Rejected]: '#FEF6F6',
    [CHIP_STATES.Completed]: '#1A7D36',
    [CHIP_STATES.Failed]: '#DE4D4D',
  }

  const WORD_COLORS = {
    [CHIP_STATES.Active]: '#73A64E',
    [CHIP_STATES.Pending]: COLORS.white,
    [CHIP_STATES.Rejected]: '#D55B6A',
    [CHIP_STATES.Completed]: COLORS.white,
    [CHIP_STATES.Failed]: COLORS.white,
  }

  const translate = useTranslate()
  const useStyles = makeStyles(
    chipTableFieldStyles(
      BACKGROUND_COLORS[CHIP_STATES[CHIP_LABEL[label]]],
      WORD_COLORS[CHIP_STATES[CHIP_LABEL[label]]],
    ),
  )
  const classes = useStyles()

  const extraComponent = {
    // To add later
    Failed: (
      <div className={classes.extraFields}>
        {/*<DownloadIcon*/}
        {/*  label={translate('ra.buttons.retry')}*/}
        {/*  size='medium'*/}
        {/*  style={{ fill: COLORS.blue, cursor: 'pointer' }}*/}
        {/*/>*/}
        <TextButton
          key='changeCard'
          label={translate('ra.buttons.changeCard')}
          style={{ color: COLORS.blue }}
          onClick={actions?.changeCard}
        />
      </div>
    ),
    Rejected: <MyEmailResend height={25} width={25} />,
  }

  return (
    <TableCell align={columnAlignment} style={tableCellStyles}>
      <div className={extraComponent[CHIP_LABEL[label]] && classes.cellContainer}>
        <Chip className={classes.chipField} label={CHIP_LABEL[label]} variant={variant} />
        {extraComponent && extraComponent[CHIP_LABEL[label]]}
      </div>
    </TableCell>
  )
}

export default ChipTableField
