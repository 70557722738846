import axiosInstance from '../../axiosInterceptor/axiosInstance'

const getPlaybooks = async (data) => {
  const { teamId } = data
  const queryString = `?teamId=${teamId}`
  const url = `/play-books${queryString}`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getPlaybooks
