import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import PropTypes from 'prop-types'
import TacticsAccordion from '../TacticsAccordion/TacticsAccordion'
import QueryKeys from '../../utils/constants/queryKeys'
import getTactics from '../../Apis/tactics/getTactics'
import DialogWithCloseIcon from '../DialogWithCloseIcon/DialogWithCloseIcon'
import initialTacticType from '../../types/tactics/initialTacticType'

const TacticsModal = (props) => {
  const { open, onClose, initialTags, setTags } = props
  const [tagsClicked, setTagsClicked] = useState(initialTags)
  const [tactics, setTactics] = useState()

  const { data } = useQuery([QueryKeys.GET_TACTICS], () => getTactics(), {
    onSuccess: () => {},
    staleTime: 0,
  })

  useEffect(() => {
    setTagsClicked(initialTags)
  }, [initialTags])

  useEffect(() => {
    if (tagsClicked) {
      const newTactics =
        data &&
        data.map((parentTactic) => ({
          ...parentTactic,
          children: parentTactic.children.map((tactic) => ({
            ...tactic,
            children: tactic.children.map((t) => {
              if (tagsClicked.length > 0 && tagsClicked.find((tag) => tag.id === t.id)) {
                return { ...t, clicked: true }
              }
              return { ...t, clicked: false }
            }),
          })),
        }))

      setTactics(newTactics)
    }
  }, [tagsClicked, data]) // eslint-disable-line react-hooks/exhaustive-deps

  const onTagClick = (tag) => {
    const tagIndex = tagsClicked.findIndex((t) => t.id === tag.id)
    if (tagIndex !== -1) {
      const newTagsClicked = tagsClicked.filter((t) => t.id !== tag.id)
      return setTagsClicked(newTagsClicked)
    }
    return setTagsClicked([...tagsClicked, tag])
  }

  return (
    <DialogWithCloseIcon
      open={open}
      backgroundColor='whitesmoke'
      onClose={() => {
        onClose()
        setTags(tagsClicked)
      }}
    >
      <TacticsAccordion
        onTagClick={onTagClick}
        tactics={tactics}
        tagsClicked={tagsClicked}
        viewOnly
      />
    </DialogWithCloseIcon>
  )
}

TacticsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  initialTags: PropTypes.arrayOf(initialTacticType),
  setTags: PropTypes.func,
}

export default TacticsModal
