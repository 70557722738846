const courtsAddNewStyles = {
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: 15,
  },
  checkButton: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    padding: '2px 0',
    lineHeight: 0,
  },
}

export default courtsAddNewStyles
