function hasNullRequiredValues(details) {
  const requiredFields = [
    'firstName',
    'lastName',
    'street',
    'city',
    'streetNumber',
    'zipCode',
    'country',
  ]

  return requiredFields.some((field) => details[field] === null || details[field] === undefined)
}

export default hasNullRequiredValues
