import React from 'react'
import { makeStyles } from '@material-ui/core'
import ZoomInIcon from '@material-ui/icons/ZoomIn';

const MyZoomInIcon = (props) => {
  const useStyles = makeStyles({
    icon: {
      cursor: 'pointer',
    },
  })
  const classes = useStyles()

  return <ZoomInIcon {...props} classes={{ root: classes.icon }} />
}

export default MyZoomInIcon
