import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import durationChipStyles from './durationChip.styles'

const DurationChip = ({ duration }) => {
  const translate = useTranslate()
  const useStyles = makeStyles(durationChipStyles)
  const classes = useStyles()

  return (
    <div>
      <div className={classes.divFlex}>
        <div className={classes.durationContainer}>
          <div className={classes.duration}>{duration}</div>
          <div className={classes.min}>{translate('ra.text.min')}</div>
        </div>
      </div>
    </div>
  )
}

DurationChip.propTypes = {
  duration: PropTypes.number,
}

export default DurationChip
