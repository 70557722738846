import React, { createContext, useContext, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import addTeam from '../Apis/team/addTeam'
import QueryKeys from '../utils/constants/queryKeys'
import getOrg from '../Apis/organisation/getOrg'
import useQueryParams from '../hooks/useQueryParams'
import getOrgs from '../Apis/organisation/getOrgs'
import getTeams from '../Apis/team/getTeams'
import getGenderSpec from '../Apis/team/getGenderSpec'
import getAgeRestriction from '../Apis/team/getAgeRestriction'
import getUserData from '../Apis/user/getUserData'
import localStorageIds from '../utils/constants/localStorageIds'
import localStorageHelper from '../utils/helpers/localStorageHelper'
import { setLocalStorageValue } from '../utils/helpers/localStorageHelperMethods'

const MyLayoutContext = createContext()

const MyLayoutProvider = ({ children }) => {
  const [showAddTeamModal, setShowAddTeamModal] = useState(false)
  const [error, setError] = useState(false)
  const history = useHistory()
  const params = useQueryParams()
  const orgId = params?.org
  const teamId = params?.team
  const menuChoice = history.location.pathname.split('/')[1]
  const redirect = useCallback((pathname) => history.push(pathname), [history])
  const queryClient = useQueryClient()
  const storedToken = localStorage.getItem(localStorageIds.TOKEN)

  const myOrgs = useQuery([QueryKeys.GET_ORGS], () => getOrgs())

  const singleOrg = useQuery([QueryKeys.GET_SINGLE_ORG, orgId], () => orgId && getOrg(orgId))

  const teams = useQuery([QueryKeys.GET_TEAMS, orgId], () => orgId && getTeams(orgId))

  const genderSpec = useQuery([QueryKeys.GET_GENDER_SPEC], () => getGenderSpec())

  const ageRestriction = useQuery([QueryKeys.GET_AGE_RESTRICTION], () => getAgeRestriction())

  const profileData = useQuery([QueryKeys.GET_ACCOUNT_ME], () => getUserData(), {
    refetchInterval: () => {
      if (error) {
        return 5000
      }
    },
    retry: false,
    onSuccess: (res) => {
      const { orgScopes, orgRoles, subscriptions } = res
      setError(false)
      setLocalStorageValue([
        { id: localStorageIds.SCOPES, value: orgScopes },
        {
          id: localStorageIds.USER_ORG_ROLES,
          value: orgRoles,
        },
        { id: localStorageIds.SUBSCRIPTIONS, value: subscriptions },
      ])
      if (!storedToken) {
        window.location.reload()
        setError(false)
      }
    },
    onError: () => {
      if (!storedToken) {
        localStorageHelper(localStorageIds.SELECTED_TEAM).removeLocalStorageValue()
        localStorageHelper(localStorageIds.SELECTED_ORG).removeLocalStorageValue()
        redirect('/login')
      } else {
        setError(true)
      }
    },
  })

  const addATeam = useMutation((data) => addTeam(data), {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(QueryKeys.GET_TEAMS).then((res) => res)
      queryClient.invalidateQueries(QueryKeys.GET_ORGS).then((res) => res)
      setShowAddTeamModal(false)
      setLocalStorageValue([{ id: localStorageIds.SELECTED_TEAM, value: data }])
      redirect(`/teamCalendar?org=${orgId}&team=${data.id}`)
    },
  })

  const value = useMemo(
    () => ({
      orgId,
      teamId,
      user: profileData?.data,
      myOrgs: myOrgs.data,
      singleOrg: singleOrg?.data,
      teams: teams?.data,
      genderSpec: genderSpec?.data,
      ageRestriction: ageRestriction?.data,
      addTeam: addATeam,
      redirect,
      menuChoice,
      showAddTeamModal,
      setShowAddTeamModal,
      error,
    }),
    [
      orgId,
      teamId,
      profileData,
      myOrgs,
      singleOrg,
      teams,
      genderSpec,
      ageRestriction,
      addATeam,
      redirect,
      menuChoice,
      showAddTeamModal,
      error,
    ],
  )

  return <MyLayoutContext.Provider value={value}>{children}</MyLayoutContext.Provider>
}

MyLayoutProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useMyLayout = () => {
  const context = useContext(MyLayoutContext)
  if (context === undefined) {
    throw new Error('useMyLayout must be used within a MyLayoutProvider')
  }
  return context
}

export { MyLayoutProvider, useMyLayout }
