import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import DrillCreateForm from '../DrillCreate/DrillCreateForm/DrillCreateForm'
import usePlaybookQueries from '../../hooks/usePlaybookQueries'
import DrillCreateFormEditDrillModal from '../DrillCreate/DrillCreateFormEditDrillModal/DrillCreateFormEditDrillModal'
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender'
import QueryKeys from '../../utils/constants/queryKeys'
import getUserData from '../../Apis/user/getUserData'

const PlaybookCreate = () => {
  const {
    trainingModule,
    playbook,
    createDrill,
    updateDrill,
    deleteDrill,
    isFetching,
    isMutating,
    isTrainingModuleUpdatedDirty,
    shouldUpdatePlayGlobally,
    setShouldUpdatePlayGlobally,
    type,
  } = usePlaybookQueries()
  const me = useQuery([QueryKeys.GET_ACCOUNT_ME], () => getUserData())
  const isCurrentUserDrillCreator = me?.data?.id === trainingModule?.creator.id
  const [isUpdateDrillModalOpen, setIsUpdateDrillModalOpen] = useState(null)
  // Show modal if play can be updated globally.
  useEffect(() => {
    // If user is not the creator of the TM, update only in PP or playbook automatically.
    if (!isCurrentUserDrillCreator || trainingModule?.originalCreatorFirstName) {
      setIsUpdateDrillModalOpen(false)
      return setShouldUpdatePlayGlobally(false)
    }

    if (typeof isTrainingModuleUpdatedDirty === 'boolean') {
      setIsUpdateDrillModalOpen(isTrainingModuleUpdatedDirty)
    }
  }, [isTrainingModuleUpdatedDirty, isCurrentUserDrillCreator])

  return (
    <ConditionalRender renderValue={!me.isLoading}>
      <DrillCreateFormEditDrillModal
        isOpen={isUpdateDrillModalOpen}
        onClose={() => setIsUpdateDrillModalOpen(false)}
        setShouldUpdateGlobally={setShouldUpdatePlayGlobally}
        pathname='playbook'
      />
      <DrillCreateForm
        trainingModule={trainingModule}
        playbook={playbook}
        createDrill={createDrill}
        updateDrill={updateDrill}
        deleteDrill={deleteDrill}
        isFetching={isFetching}
        isMutating={isMutating}
        type={type}
        shouldUpdateGlobally={shouldUpdatePlayGlobally}
      />
    </ConditionalRender>
  )
}

export default PlaybookCreate
