import COLORS from '../../utils/constants/colors'

const orgSeatManagementStyles = (isInTrial) => ({
  onHover: {
    backgroundColor: COLORS.lightGrey,
    '&:hover': {
      backgroundColor: COLORS.orange,
    },
  },
  typographyBold: { fontWeight: 'bold' },
  priceText: {
    textDecoration: isInTrial ? 'line-through' : null,
    fontWeight: 'bold',
    color: COLORS.subscriptionBlue,
  },
})

export default orgSeatManagementStyles
