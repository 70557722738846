import { Checkbox, Divider, makeStyles } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import * as React from 'react'
import { useEffect } from 'react'
import myCheckboxOneToManyStyles from './myCheckboxOneToMany.styles'

const MyCheckboxOneToMany = (props) => {
  const { name, options } = props
  const useStyles = makeStyles(myCheckboxOneToManyStyles)
  const classes = useStyles()

  const formikContext = useFormikContext()
  const { values, setFieldValue } = formikContext
  const { positions } = values
  const defaultValue = 'ALL'

  useEffect(() => {
    if (positions?.length === 0) {
      setFieldValue('positions', [defaultValue])
    }
  }, [positions])

  const onChange = (e) => {
    const { value, checked } = e.target

    if (!checked) {
      setFieldValue(
        'positions',
        positions.filter((position) => position !== value),
      )
    } else if (checked) {
      if (value === defaultValue) {
        setFieldValue('positions', [defaultValue])
      } else if (positions?.length === options?.length - 2) {
        setFieldValue('positions', [defaultValue])
      } else {
        const newValues = positions.filter((position) => position !== defaultValue)
        setFieldValue('positions', [...newValues, value])
      }
    }
  }

  return (
    <div className={classes.checkboxWrapper}>
      {options?.map((option) => (
        <div key={option.name}>
          <label className={classes.chipLabel}>
            <Field
              key={option.name}
              color='primary'
              name={name}
              type='checkbox'
              value={option.name}
              as={Checkbox}
              onChange={onChange}
              disabled={
                option.name === defaultValue &&
                JSON.stringify(positions) === JSON.stringify([defaultValue])
              }
            />
            {option.displayName}
          </label>

          {option.name === defaultValue && (
            <div className={classes.dividerWrapper}>
              <Divider orientation='vertical' className={classes.divider} />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default MyCheckboxOneToMany
