import { isBefore } from 'date-fns'

const isTimePast = (d1) => {
  if (!d1) return false
  const now = new Date()

  const newDate = new Date()
  const newD1 = new Date(d1)

  newDate.setHours(newD1.getHours())
  newDate.setMinutes(newD1.getMinutes())
  newDate.setSeconds(newD1.getSeconds())

  return isBefore(newDate, now)
}

export default isTimePast
