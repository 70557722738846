import React, { useEffect, useRef, useState } from 'react'
import { useLogin, useNotify, useTranslate, Notification, hideNotification } from 'react-admin'
import { Link, makeStyles } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import ButtonWithLoader from '../../../ui/ButtonWithLoader/ButtonWithLoader'
import loginFormStyles from './loginForm.styles'
import MyTextField from '../../fields/MyTextField'
import MyTextFieldPassword from '../../fields/MyTextFieldPassword'
import ConfirmEmail from '../../../ConfirmEmail/ConfirmEmail'

const LoginForm = () => {
  const reCaptchaRef = useRef()
  const [loading, setLoading] = useState(false)
  const translate = useTranslate()
  const login = useLogin()
  const notify = useNotify()
  const useStyles = makeStyles(loginFormStyles)
  const classes = useStyles()

  // Hide checkAuth error message
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(hideNotification())
  }, [])

  const onSubmit = async (data) => {
    const captchaToken = await reCaptchaRef.current.executeAsync()

    setLoading(true)
    const { email, password } = data
    login({ username: email, password, captchaToken }).catch((errorMessage) => {
      reCaptchaRef.current.reset()
      setLoading(false)
      notify(errorMessage, 'error', undefined, false, 3000)
    })
    reCaptchaRef.current.reset()
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={onSubmit}
    >
      {() => (
        <>
          <Form>
            <div className={classes.formWrapper}>
              <MyTextField
                label={translate('ra.formFields.email')}
                name='email'
                required
                autoComplete='email'
              />
              <MyTextFieldPassword
                label={translate('ra.formFields.password')}
                name='password'
                type='password'
                required
                autoComplete='current-password'
              />
              <ButtonWithLoader
                fullWidth
                label={translate('ra.buttons.login')}
                style={{ marginTop: 10 }}
                loading={loading}
              />
              <div className={classes.linksWrapper}>
                <Link href='/forgot-password' variant='body2' className={classes.link}>
                  {translate('ra.gridLinks.forgotPassword')}
                </Link>
                <Link href='/signUp' variant='body2' className={classes.link}>
                  {translate('ra.gridLinks.signUp')}
                </Link>
              </div>
            </div>
            <ReCAPTCHA
              ref={reCaptchaRef}
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              size='invisible'
            />
          </Form>
          <Notification />
          <ConfirmEmail />
        </>
      )}
    </Formik>
  )
}

export default LoginForm
