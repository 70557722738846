import { isBefore } from 'date-fns'

const isDateBefore = (d1) => {
  if (!d1) return false

  const startDate = new Date(d1)
  const today = new Date()

  // Set the same time to compare only date.
  startDate.setHours(today.getHours())
  startDate.setMinutes(today.getMinutes())
  startDate.setSeconds(today.getSeconds())
  startDate.setMilliseconds(today.getMilliseconds())

  return isBefore(startDate, today)
}

export default isDateBefore