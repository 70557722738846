import { Form, Formik } from 'formik'
import { Divider, makeStyles, Switch } from '@material-ui/core'
import * as React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslate } from 'react-admin'
import youtubeConfigurationFormStyles from './youtubeConfigurationForm.styles'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import patchYoutubeConfiguration from '../../../Apis/youtube/patchYoutubeConfiguration'
import localStorageHelper from '../../../utils/helpers/localStorageHelper'
import localStorageIds from '../../../utils/constants/localStorageIds'
import QueryKeys from '../../../utils/constants/queryKeys'

const YoutubeConfigurationForm = () => {
  const queryClient = useQueryClient()
  const orgLocalStorageValue = localStorageHelper(localStorageIds.SELECTED_ORG)
  const { id: broadcastConfigurationId, isForChildren } =
    orgLocalStorageValue?.localStorageValue?.youTubeBroadcastConfiguration || {}
  const translate = useTranslate()
  const useStyles = makeStyles(youtubeConfigurationFormStyles)
  const classes = useStyles()

  const patchConfiguration = useMutation((data) => patchYoutubeConfiguration(data), {
    onSuccess: (res, { resetFormValues }) => {
      // Update on localStorage the selected organisation
      queryClient.invalidateQueries(QueryKeys.GET_ORGS_AFTER_LOGIN).then(() => {
        queryClient.invalidateQueries(QueryKeys.GET_TEAMS_AFTER_LOGIN).then(() => {
          resetFormValues({ values: { isForChildren: res?.data?.isForChildren } })
        })
      })
    },
  })

  const onChangeSwitchState = (e, values, setValue) => {
    const valueName = e.target.name
    setValue(valueName, !values[valueName])
  }

  const onSubmitClick = (formValues, resetFormValues, updateConfiguration) => {
    updateConfiguration.mutate({
      broadcastConfigurationId,
      isForChildren: formValues?.isForChildren,
      resetFormValues,
    })
  }

  return (
    <Formik
      initialValues={{
        isForChildren: isForChildren ?? false,
      }}
      onSubmit={(values, { resetForm }) => {
        onSubmitClick(values, resetForm, patchConfiguration)
      }}
    >
      {({ values, setFieldValue, dirty }) => (
        <Form className={classes.formContainer}>
          <Divider />
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldSpacing}>
              {translate('ra.text.contentChildren')}
              <Switch
                className={classes.toggle}
                name='isForChildren'
                checked={values.isForChildren}
                onChange={(e) => onChangeSwitchState(e, values, setFieldValue)}
              />
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <ButtonWithLoader
              label={translate('ra.buttons.save')}
              disabled={!dirty}
              loading={patchConfiguration.isLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default YoutubeConfigurationForm
