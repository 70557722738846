const TEAM_COLOURS_PALETTE = [
  { id: 1, value: '#000000' },
  { id: 2, value: '#7d7d7d' },
  { id: 3, value: '#870014' },
  { id: 4, value: '#ec1c23' },
  { id: 5, value: '#ff7e26' },
  { id: 6, value: '#fef100' },
  { id: 7, value: '#22b14b' },
  { id: 8, value: '#00a1e7' },
  { id: 9, value: '#3f47cc' },
  { id: 10, value: '#a349a4' },
  { id: 11, value: '#ffffff' },
  { id: 12, value: '#c3c3c3' },
  { id: 13, value: '#b87957' },
  { id: 14, value: '#feaec9' },
  { id: 15, value: '#ffc80d' },
  { id: 16, value: '#eee3af' },
  { id: 17, value: '#b5e61d' },
  { id: 18, value: '#99d9ea' },
  { id: 19, value: '#7092be' },
  { id: 20, value: '#c8bfe7' },
]

export default TEAM_COLOURS_PALETTE
