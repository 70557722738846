import { useState } from 'react'
import './dateRangePicker.scss'
import { DateRangePicker } from 'materialui-daterange-picker'
import { Dialog, DialogContent, makeStyles } from '@material-ui/core'
import MyCloseIcon from '../../assets/icons/MyCloseIcon'
import CalendarIcon from '../../assets/icons/calendarIcon'
import LeftArrowIcon from '../../assets/icons/leftArrowIcon'
import RightArrowIcon from '../../assets/icons/rightArrowIcon'
import DateRangeLabel from '../dateRangeLabel/dateRangeLabel'
import useHistoryPush from '../../hooks/useHistoryPush'
import { parseDateUrl, parseDate } from '../../utils/helpers/dateHelpers/parseDate'
import TOUR_IDS from '../../utils/constants/introIds'
import myDateRangePickerStyles from './myDateRangePicker.styles'

const MyDateRangePicker = (props) => {
  const { dateRange, setDateRange } = props
  const [open, setOpen] = useState(false)
  const { redirect, pathname } = useHistoryPush()
  const useStyles = makeStyles(myDateRangePickerStyles)
  const classes = useStyles(props)

  const toggle = () => setOpen(!open)

  const setDatesToUrl = (startDate, endDate) => {
    redirect(pathname, [{ startDate: parseDateUrl(startDate) }, { endDate: parseDateUrl(endDate) }])
  }

  const handleDateRangePicker = (range) => {
    setDateRange(range)
    setDatesToUrl(range.startDate, range.endDate)
  }

  const handleArrowClicked = (arrow) => {
    const { startDate, endDate } = dateRange
    if (arrow === 'left') {
      startDate.setDate(startDate.getDate() - 8)
      endDate.setDate(endDate.getDate() - 8)
    } else {
      startDate.setDate(startDate.getDate() + 8)
      endDate.setDate(endDate.getDate() + 8)
    }

    setDateRange({
      startDate,
      endDate,
    })
    setDatesToUrl(startDate, endDate)
  }

  return (
    <>
      <div id={TOUR_IDS.practices.range} className={classes.divFlex}>
        <div className={classes.divFlex}>
          <LeftArrowIcon className={classes.arrowIcon} onClick={() => handleArrowClicked('left')} />
          <DateRangeLabel
            startDate={parseDate(dateRange?.startDate)}
            endDate={parseDate(dateRange?.endDate)}
          />
          <RightArrowIcon
            className={classes.arrowIcon}
            onClick={() => handleArrowClicked('right')}
          />
        </div>
        <CalendarIcon onClick={() => setOpen(true)} className={classes.calendarIcon} />
      </div>

      <Dialog open={open} onClose={toggle} classes={{ paper: classes.dialog }}>
        <MyCloseIcon onClick={toggle} className={classes.closeIcon} />
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <DateRangePicker
            open={true}
            toggle={() => {}}
            onChange={handleDateRangePicker}
            initialDateRange={dateRange}
            wrapperClassName={classes.dateRangePickerRoot}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default MyDateRangePicker
