import COLORS from '../colors'

const SEARCH_INPUT_STYLES = {
  // InputProps
  root: {
    borderRadius: '6px',
    backgroundColor: COLORS.searchGrey,
  },
  input: {
    height: '2rem',
    minHeight: '2rem',
    fontSize: '0.95rem',
  },
}

export default SEARCH_INPUT_STYLES
