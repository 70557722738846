// localStorageValues: [{id, value}]
export const setLocalStorageValue = (localStorageValues) => {
  localStorageValues.forEach((localStorageValue) => {
    localStorage.setItem(
      localStorageValue.id,
      typeof localStorageValue.value === 'string'
        ? localStorageValue.value
        : JSON.stringify(localStorageValue.value),
    )
  })

  window.dispatchEvent(new Event('storage'))
}

// localStorageIds: []string
export const removeLocalStorageValue = (localStorageIds) => {
  localStorageIds.forEach((localStorageId) => {
    localStorage.removeItem(localStorageId)
  })

  window.dispatchEvent(new Event('storage'))
}
