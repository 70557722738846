import React from 'react'
import Popover from '@material-ui/core/Popover'
import { makeStyles, MenuItem } from '@material-ui/core'
import { myDynamicPopoverStyles } from './myDynamicPopover.styles'
import AddCircleIcon from '@material-ui/icons/AddCircle'

const DynamicPopover = (props) => {
  const { open, anchorEl, onClose, options } = props
  const useStyles = makeStyles(myDynamicPopoverStyles)
  const classes = useStyles()

  const handleOptionClick = (option) => {
    option.onClick()
    onClose()
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{ paper: classes.myDynamicPopoverStyles }}
    >
      {options.map((option, index) => (
        <MenuItem key={index} onClick={() => handleOptionClick(option)}>
          {option.type === 'add' && (
            <AddCircleIcon style={{ width: '22', height: '22', marginRight: 5, fill: '#E0726E' }} />
          )}
          {option.label}
        </MenuItem>
      ))}
    </Popover>
  )
}

export default DynamicPopover
