import ChevronRightIcon from '@material-ui/icons/ChevronRight';import { makeStyles } from "@material-ui/core";
import COLORS from "../../utils/constants/colors";

const RightArrowIcon = (props) => {
  const useStyles = makeStyles({
    rightIcon: {
      width: "22px",
      height: "22px",
      cursor: "pointer",
      fill: COLORS.black,
    }
  });
  const classes = useStyles();

  return (
    <ChevronRightIcon {...props} classes={{ root: classes.rightIcon }}/>
  );
};

export default RightArrowIcon;
