import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import QueryKeys from '../utils/constants/queryKeys'
import URL_QUERY_PARAMS from '../utils/constants/urlQueryParams'
import useCreatePlaybook from '../Apis/playbook/createPlaybook/useCreatePlaybook'
import useUpdatePlaybook from '../Apis/playbook/updatePlaybook/useUpdatePlaybooks'
import useGetPlaybooks from '../Apis/playbook/getPlaybooks/useGetPlaybooks'
import useHistoryPush from './useHistoryPush'
import useQueryParams from './useQueryParams'
import addDrill from '../Apis/drill/addDrill'
import patchDrill from '../Apis/drill/patchDrill'
import getLatestRevision from '../Apis/revisions/getLatestRevision'
import getDrill from '../Apis/drill/getDrill'
import { TRAINING_MODULES_TYPES_URL } from '../utils/constants/trainingModulesTypes'
import getRevision from '../Apis/revisions/getRevision'
import parsePatchTrainingModule from '../utils/helpers/parsePatchTrainingModule'
import parsePostTrainingModule from '../utils/helpers/parsePostTrainingModule'
import findReplacedObjectBetweenTwoArrays from '../utils/helpers/findReplacedObjectBetweenTwoArrays'

const usePlaybookQueries = () => {
  const urlPlayId = window.location.href.match(/\/playbook\/([^?]+)/)?.[1]
  const [playId, setPlayId] = useState(urlPlayId && urlPlayId !== 'create' ? urlPlayId : null)
  // Based on user option to update or not play globally. Default value is false.
  const [shouldUpdatePlayGlobally, setShouldUpdatePlayGlobally] = useState(true)
  const [trainingModule, setTrainingModule] = useState(null)
  const queryClient = useQueryClient()
  const { redirect, pathname } = useHistoryPush()
  const queryParams = useQueryParams()

  const NO_TACTICS = 'No Tactics'

  const onGetPlaybookSuccess = (data) => {
    const plays = data[0]?.trainingModules

    // Set in url tempCreatedPlayId in order to choose TacticsGroup
    if (plays && queryParams?.[URL_QUERY_PARAMS.tempCreatedPlayId]) {
      const tempCreatedPlay = plays.find(
        (p) => p.originalTrainingModuleId === queryParams?.[URL_QUERY_PARAMS.tempCreatedPlayId],
      )

      const tacticGroup =
        tempCreatedPlay?.tactics && tempCreatedPlay?.tactics?.length > 0
          ? tempCreatedPlay?.tactics.map((t) => t.name).join(', ')
          : NO_TACTICS

      return redirect(
        pathname,
        [{ [URL_QUERY_PARAMS.tacticGroup]: tacticGroup }],
        [URL_QUERY_PARAMS.tempCreatedPlayId],
      )
    }
  }
  const playbook = useGetPlaybooks(
    [queryParams?.team],
    { teamId: queryParams?.team },
    { onSuccess: onGetPlaybookSuccess },
  )

  const onCreatePlaybookSuccess = (
    { data },
    { trainingModulesIds, isNewDrillCreated, closeDesignerModal },
  ) => {
    queryClient.invalidateQueries(QueryKeys.GET_PLAYBOOKS)

    const changedObject = findReplacedObjectBetweenTwoArrays(
      playbook?.data?.[0]?.trainingModules || [],
      data.trainingModules,
    )
    if (closeDesignerModal) {
      closeDesignerModal(false)
      return setPlayId(changedObject?.id)
    }
    // When user creates a new play
    if (isNewDrillCreated) {
      return redirect(`playbook/${data?.trainingModules?.[0].id}/show`, [
        { [URL_QUERY_PARAMS.tempCreatedPlayId]: trainingModulesIds?.[0] },
      ])
    }

    // When user select already created play
    return redirect(pathname, [{ [URL_QUERY_PARAMS.tempCreatedPlayId]: trainingModulesIds?.[0] }])
  }
  const createPlaybook = useCreatePlaybook({ onSuccess: onCreatePlaybookSuccess })

  const onUpdatePlaybookSuccess = ({ data }, { redirectTo, closeDesignerModal }) => {
    queryClient.invalidateQueries(QueryKeys.GET_PLAYBOOKS)

    // This way we found the last added/updated play.
    // Because of revisions the id changed.
    const changedObject = findReplacedObjectBetweenTwoArrays(
      playbook?.data?.[0]?.trainingModules,
      data.trainingModules,
    )
    if (closeDesignerModal) {
      closeDesignerModal(false)
      return setPlayId(changedObject?.id)
    }

    if (redirectTo) {
      return redirect(redirectTo, [{ [URL_QUERY_PARAMS.tempCreatedPlayId]: changedObject?.id }])
    }

    return redirect(`playbook/${changedObject?.id}/show`, [
      { [URL_QUERY_PARAMS.tempCreatedPlayId]: changedObject?.id },
    ])
  }
  const updatePlaybook = useUpdatePlaybook({ onSuccess: onUpdatePlaybookSuccess })

  const addADrill = useMutation((data) => addDrill(data), {
    onSuccess: ({ data }, { closeDesignerModal }) => {
      if (!playbook?.data?.[0]?.id) {
        return createPlaybook.mutate({
          teamId: queryParams?.team,
          trainingModulesIds: [data?.id],
          isNewDrillCreated: true,
          closeDesignerModal,
        })
      }

      return updatePlaybook.mutate({
        playBookId: playbook?.data?.[0]?.id,
        values: { trainingModulesForAssignment: [data?.id] },
        isNewDrillCreated: true,
        closeDesignerModal,
      })
    },
  })

  const updateADrill = useMutation((data) => patchDrill(data), {
    onSuccess: ({ data }, { trainingModuleId, closeDesignerModal }) => {
      queryClient.invalidateQueries(QueryKeys.GET_DRILLS)

      updatePlaybook.mutate({
        playBookId: playbook?.data[0]?.id,
        values: {
          trainingModulesForAssignment: [trainingModuleId],
          TrainingModulesForDeletion: [playId],
        },
        closeDesignerModal,
      })
    },
  })

  const trainingModuleFetch = useMutation([QueryKeys.GET_DRILL], (data) => getDrill(data), {
    onSuccess: (data) => {
      setTrainingModule(data)
    },
  })

  // Based on current training-module we ask for latest revision of original current training-module
  const trainingModuleLatestRevision = useMutation(
    [QueryKeys.GET_LATEST_REVISION],
    (data) => getLatestRevision(data),
    {
      enabled: playId !== 'create' && playId !== null,
      onSuccess: (response, data) => {
        // Check if current training-module revision is the latest training-module revision of the original
        if (response?.latestRevisionId === data?.trainingModuleRevision.id) {
          return trainingModuleFetch.mutate(data?.trainingModuleRevision.originalTrainingModuleId)
        }

        setTrainingModule(data?.trainingModuleRevision)
        return setShouldUpdatePlayGlobally(false)
      },
    },
  )

  // Because we are in playbook we ask for revision training-module.
  const trainingModuleRevision = useQuery(
    [QueryKeys.GET_REVISION, playId],
    () => getRevision(playId),
    {
      enabled: Boolean(playId && playId !== 'create'),
      onSuccess: (data) => {
        // setRevisionDrillId(data?.id)

        trainingModuleLatestRevision.mutate({
          trainingModuleId: data?.originalTrainingModuleId,
          trainingModuleRevision: data,
        })
      },
    },
  )

  const createDrillProcess = (data, closeDesignerModal) =>
    addADrill.mutate({
      values: parsePostTrainingModule(data, TRAINING_MODULES_TYPES_URL.PLAYS),
      closeDesignerModal,
    })

  const updateDrillProcess = (data, closeDesignerModal) => {
    const parsedTrainingModule = parsePatchTrainingModule(data, TRAINING_MODULES_TYPES_URL.PLAYS)

    if (shouldUpdatePlayGlobally) {
      return updateADrill.mutate({
        values: parsedTrainingModule,
        // Here we want the initial play id.
        trainingModuleId: trainingModule?.id,
        closeDesignerModal,
      })
    }

    // Initial edit. After this the isHidden is always true.
    if (!shouldUpdatePlayGlobally && !trainingModule.isHidden) {
      return updatePlaybook.mutate({
        playBookId: playbook?.data?.[0]?.id,
        values: {
          trainingModules: [parsePatchTrainingModule(data, TRAINING_MODULES_TYPES_URL.PLAYS, true)],
          trainingModulesForDeletion: [playId],
        },
        closeDesignerModal,
      })
    }

    if (!shouldUpdatePlayGlobally && trainingModule.isHidden) {
      return updateADrill.mutate({
        values: parsedTrainingModule,
        // Here we want the initial play id.
        trainingModuleId: trainingModule?.id,
        closeDesignerModal,
      })
    }

    return null
  }

  // Here we check if the initial training-module is updated either in coach corner or previously in playbook.
  // If yes we don't give user the option to update training-module globally.
  const isTrainingModuleUpdatedDirty =
    trainingModuleLatestRevision?.data &&
    trainingModuleRevision?.data &&
    trainingModule &&
    trainingModuleLatestRevision?.data?.latestRevisionId === trainingModuleRevision?.data?.id &&
    !trainingModule.isHidden &&
    urlPlayId !== 'create'

  const isFetching =
    playbook?.isFetching ||
    trainingModuleRevision?.isFetching ||
    trainingModuleFetch?.isLoading ||
    trainingModuleLatestRevision?.isLoading

  const isMutating = createPlaybook.isLoading || updatePlaybook.isLoading || addADrill.isLoading

  return {
    trainingModule,
    playbook,
    createDrill: createDrillProcess,
    updateDrill: updateDrillProcess,
    isFetching,
    isMutating,
    createPlaybook,
    updatePlaybook,
    isTrainingModuleUpdatedDirty,
    shouldUpdatePlayGlobally,
    setShouldUpdatePlayGlobally,
    type: TRAINING_MODULES_TYPES_URL.PLAYS,
  }
}

export default usePlaybookQueries
