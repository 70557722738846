import COLORS from '../../utils/constants/colors'

const breadcrumbStyles = {
  breadcrumb: {
    fontWeight: 500,
    fontSize: 14,
    color: COLORS.mediumDarkGrey,
  },
}
export default breadcrumbStyles
