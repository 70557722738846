import React from 'react'
import { makeStyles } from '@material-ui/core'
import statsNavBarStyles from './statsNavBar.styles'
import { useTranslate } from 'react-admin'

const StatsNavBar = ({ currentTab, setCurrentTab }) => {
  const translate = useTranslate(statsNavBarStyles)
  const useStyles = makeStyles(statsNavBarStyles)
  const classes = useStyles()

  return (
    <div className={classes.navBar}>
      <div
        className={`${classes.tab} ${currentTab === 'stats' ? classes.activeTab : ''}`}
        onClick={() => setCurrentTab('stats')}
      >
        {translate('ra.text.stats')}
      </div>
      <div
        className={`${classes.tab} ${currentTab === 'games' ? classes.activeTab : ''}`}
        onClick={() => setCurrentTab('games')}
      >
        {translate('ra.text.navGames')}
      </div>
    </div>
  )
}

export default StatsNavBar
