import * as React from 'react'
import { Form, Formik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslate } from 'react-admin'
import { Grid } from '@material-ui/core'

import MyTextField from '../../../components/form/fields/MyTextField'
import MyDateField from '../../../components/form/fields/MyDateField'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import ContainerPadding from '../../../components/containerPadding/containerPadding'
import QueryKeys from '../../../utils/constants/queryKeys'
import ImageCropWithEdit from '../../../components/ui/ImageCropComponents/ImageCropWithEdit/ImageCropWithEdit'
import updateUserData from '../../../Apis/user/updateUserData'
import userType from '../../../types/userType'

const ProfileSettingsInfoForm = ({ userData }) => {
  const queryClient = useQueryClient()

  const { firstName, lastName, dateOfBirth, logoUrl } = userData
  const translate = useTranslate()

  const updateTheUserData = useMutation(
    QueryKeys.UPDATE_USER_PERSONAL_SETTINGS,
    (data) => updateUserData(data),
    {
      onSuccess: (res, { resetForm }) => {
        const { firstName, lastName, dateOfBirth, logoUrl } = res.data
        resetForm({ values: { firstName, lastName, dateOfBirth, logo: logoUrl } })
        queryClient.invalidateQueries(QueryKeys.GET_ACCOUNT_ME)
      },
    },
  )

  const onSubmit = (data, { resetForm }) => {
    updateTheUserData.mutate({ ...data, resetForm })
  }

  return (
    <ContainerPadding padding='4% 1% 4% 1%'>
      <Formik
        initialValues={{
          firstName,
          lastName,
          dateOfBirth,
          logo: logoUrl,
        }}
        onSubmit={onSubmit}
      >
        {({ isValid, dirty, values, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid
                container
                item
                xs={12}
                justifyContent='center'
                style={{ marginTop: 10, marginBottom: 20 }}
              >
                <ImageCropWithEdit
                  defaultImageSrc={values.logo}
                  setCroppedImage={(croppedImage) => setFieldValue('logo', croppedImage)}
                  editPreviewWidth={110}
                />
              </Grid>
              <Grid item xs={6}>
                <MyTextField label={translate('ra.formFields.firstName')} name='firstName' />
              </Grid>
              <Grid item xs={6}>
                <MyTextField label={translate('ra.formFields.lastName')} name='lastName' />
              </Grid>
              <Grid item xs={12}>
                <MyDateField name='dateOfBirth' label={translate('ra.formFields.birthday')} />
              </Grid>
              <Grid item container cs={12} justifyContent='flex-end'>
                <ButtonWithLoader
                  label={translate('ra.buttons.update')}
                  disabled={!(isValid && dirty)}
                  loading={updateTheUserData.isLoading}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </ContainerPadding>
  )
}

ProfileSettingsInfoForm.propTypes = {
  userData: userType,
}

export default ProfileSettingsInfoForm
