const parsePostTrainingModule = (data, type) => {
  return {
    ...data,
    ...(data.access ? { access: 'PUBLIC' } : { access: 'PRIVATE' }),
    ...(!data.positions && []),
    ...(!data.intensities && []),
    ...(data.tactics && data.tactics.length > 0
      ? { tactics: data.tactics.map((tactic) => tactic.id) }
      : { tactics: [] }),
    ...(data.designs.filter((des) => des.edited === true).length > 0
      ? {
          designs: data.designs.filter((des) => des.edited === true),
        }
      : { designs: [] }),
    ...(type && type === 'drills' ? { type: 'DRILL' } : { type: 'PLAY' }),
  }
}

export default parsePostTrainingModule
