import { dateRangeLabelStyles } from "./dateRangeLabel.styles";
import { makeStyles } from "@material-ui/core";

const DateRangeLabel = (props) => {
  const { startDate, endDate } = props;
  const useStyles = makeStyles(dateRangeLabelStyles);
  const classes = useStyles(props);

  return (
    <div className={classes.dateLabelWrapper}>
      <span className={classes.dateLabel}>{startDate}</span>
      <span className={classes.dateLabel}>-</span>
      <span className={classes.dateLabel}>{endDate}</span>
    </div>
  );
};

export default DateRangeLabel;