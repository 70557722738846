const fadeOutStyles = (duration) => {
  return {
    fadeOut: {
      animation: `$fadeOutAnimation ${duration}s linear forwards`,
    },

    '@keyframes fadeOutAnimation': {
      '0%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
        display: 'none',
      },
    },
  }
}

export default fadeOutStyles
