import COLORS from '../../../utils/constants/colors'

const setRolesModalStyles = {
  dialog: { overflow: 'hidden' },
  dialogActions: { marginTop: 'auto' },
  userName: { marginTop: '15px' },
  cancelButton: {
    '&&': {
      color: COLORS.orange,
    },
  },
}

export default setRolesModalStyles
