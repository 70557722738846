import orgIsInSubscriptionState from './orgIsInSubscriptionState'
import SUBSCRIPTION_STATES from '../constants/subscriptionStates'

const coachCornerOptionWithAuth = () => {
  if (orgIsInSubscriptionState([SUBSCRIPTION_STATES.INACTIVE])) return false

  return true
}

export default coachCornerOptionWithAuth
