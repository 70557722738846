import { React, createContext, useContext, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import useQueryParams from '../hooks/useQueryParams'
import QueryKeys from '../utils/constants/queryKeys'
import useHistoryPush from '../hooks/useHistoryPush'
import addPracticePlan from '../Apis/practice/addPracticePlan'
import updatePracticePlan from '../Apis/practice/updatePracticePlan'
import getPracticePlan from '../Apis/practice/getPracticePlan'
import setIntensityForPractices from '../utils/helpers/setIntensityForPractices'
import getIntensities from '../Apis/drill/getIntesities/getIntensities'
import getEvent from '../Apis/event/getEvent'
import deletePracticePlan from '../Apis/practice/deletePracticePlan'

const PracticePlanContext = createContext()

const PracticePlanProvider = ({ children }) => {
  const practicePlanUrlId = window.location.href.match(/\/practice-plan\/([a-f0-9-]+)/)?.[1]
  const queryParams = useQueryParams()
  const { org, team, eventId } = queryParams
  // We store here the state of the whole PP and update this when component is updated.
  // Also keep the updated components to patch only the changed ones for less bytes.
  // And the deleted components, to also send on the next request.
  const [practicePlan, setPracticePlan] = useState({})
  const [practiceId, setPracticeId] = useState(eventId && eventId)
  const [deletedComponents, setDeletedComponents] = useState([])
  const [updatedComponents, setUpdatedComponents] = useState([])
  const queryClient = useQueryClient()
  const { redirect } = useHistoryPush()

  const intensities = useQuery([QueryKeys.GET_INTENSITIES], () => getIntensities(), {
    staleTime: Infinity,
  })

  const practice = useQuery(
    [QueryKeys.GET_EVENT],
    () =>
      getEvent({
        eventTypeCode: 'pract',
        eventId: practiceId,
      }),
    {
      enabled: Boolean(practiceId),
    },
  )

  const practicePlanQuery = useQuery(
    [QueryKeys.GET_PRACTICE_PLAN, practicePlanUrlId],
    () =>
      getPracticePlan({
        practicePlanId: practicePlanUrlId,
      }),
    {
      enabled: !!practicePlanUrlId && !intensities?.isLoading,
      onSuccess: (res) => {
        setPracticeId(res?.eventId)
        setPracticePlan(
          setIntensityForPractices({
            practicePlan: res,
            intensities: intensities?.data,
          }),
        )
      },
    },
  )

  // TODO: can be written better, close is boolean and closeFunction a function, but serve the same purpose
  const formUpdatePracticePlanBody = (close, addedComponent, closeFunction, components) => {
    // Update only the updated components
    const componentsToUpdate = practicePlan?.practicePlanComponents?.filter(
      (comp) =>
        !comp?.id || (updatedComponents?.includes(comp?.id) && comp?.id !== addedComponent?.id),
    )

    const newComponents =
      components ?? (addedComponent ? [...componentsToUpdate, addedComponent] : componentsToUpdate)
    return {
      id: practicePlan?.id,
      values: {
        description:
          practicePlan?.description ??
          `Practice Plan ${new Date(practice.startDateTime).toLocaleDateString('en-GB')}`,
        practicePlanComponentsForDelete: deletedComponents,
        practicePlanComponentForUpdateDtos: [...newComponents],
      },
      ...(close ? { close } : { closeDesignerModal: closeFunction }),
    }
  }

  const addAPracticePlan = useMutation((data) => addPracticePlan(data), {
    enabled: !intensities.isFetching,
    onSuccess: ({ data }, { close }) => {
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICE_PLANS)
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICES)

      if (close) {
        return redirect(`/practice-plan/${data?.id}/show`)
      }

      window.history.replaceState({}, '', `/practice-plan/${data?.id}?org=${org}&team=${team}`)
    },
  })

  const updateAPracticePlan = useMutation((data) => updatePracticePlan(data), {
    onSuccess: (response, { close }) => {
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICE_PLANS)
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICE_PLAN)

      if (close) {
        return redirect(`/practice-plan/${practicePlan?.id}/show`)
      }
      setUpdatedComponents([])
    },
  })

  const deleteAPracticePlan = useMutation(() => deletePracticePlan(practicePlan?.id), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICE_PLANS)
      redirect(`/practice`)
    },
  })

  // Use for Practice plan SAVE button and dragEnd.
  const triggerPracticePlanRequest = (close, components) => {
    const { description, practicePlanComponents } = practicePlan ?? {}

    if (!practicePlanUrlId) {
      addAPracticePlan.mutate({
        values: {
          eventId: practiceId,
          description:
            description && description?.length > 0
              ? description
              : `Practice Plan ${new Date(practice?.data?.startDateTime).toLocaleDateString(
                  'en-GB',
                )}`,
          components: practicePlanComponents ?? [],
        },
        close: true,
      })
    } else {
      updateAPracticePlan.mutate(formUpdatePracticePlanBody(close, null, null, components))
    }
  }

  const value = {
    practicePlan: practicePlan ?? {},
    setPracticePlan,
    team,
    practice: practice?.data,
    addPracticePlan: addAPracticePlan,
    updatePracticePlan: updateAPracticePlan,
    deletePracticePlan: deleteAPracticePlan,
    triggerPracticePlanRequest,
    formUpdatePracticePlanBody,
    setUpdatedComponents,
    updatedComponents,
    deletedComponents,
    setDeletedComponents,
  }

  return <PracticePlanContext.Provider value={value}>{children}</PracticePlanContext.Provider>
}

const usePracticePlanContext = () => {
  const context = useContext(PracticePlanContext)
  if (context === undefined) {
    throw new Error('usePracticePlanContext must be used within a PracticePlanProvider')
  }
  return context
}

export { PracticePlanProvider, usePracticePlanContext }
