const searchFieldStyles = {
  searchFieldRoot: {
    borderRadius: '6px',
  },
  searchFieldInput: {
    height: '0.7rem',
    minHeight: '0.7rem',
    fontSize: '0.95rem',
    padding: '17px 12px 14px',
  },
}

export default searchFieldStyles
