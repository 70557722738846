import * as yup from 'yup'
import { useTranslate } from 'react-admin'

const useFormValidation = () => {
  const translate = useTranslate()

  return {
    stringRequired: yup.string().required(translate('ra.validationErrors.requiredField')),
    email: yup.string().email(translate('ra.validationErrors.inValidEmail')),
    password: yup.string().matches(
      // With special characters
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{6,})/,

      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      translate('ra.validationErrors.password'),
    ),
    newPassword: yup.string().matches(
      // With special characters
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{6,})/,

      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      translate('ra.validationErrors.password'),
    ),
    confirmPassword: yup
      .string()
      .test('passwords-match', translate('ra.validationErrors.confirmPassword'), function (value) {
        return this.parent.password === value
      }),
    boolean: (label) => yup.boolean().oneOf([true], label),
    stringThreeLetters: yup.string().length(3, 'Must be 3 letters'),
  }
}

export default useFormValidation
