import { Button, makeStyles, Tooltip } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import PropTypes from 'prop-types'
import CancelIcon from '@material-ui/icons/Cancel'
import { LinearProgress, useTranslate } from 'react-admin'
import React, { useEffect, useState } from 'react'
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import InfoIcon from '@material-ui/icons/Info'
import { useMutation } from 'react-query'
import { useFormikContext } from 'formik'
import myUploadVideoFieldStyles from './myUploadVideoField.styles'
import getUploadVideo from '../../../../Apis/event/getUploadVideo'
import addVideoToAmazonBucket from '../../../../Apis/event/addVideoToAmazonBucket'
import TRAINING_MODULES_VIDEO from '../../../../utils/constants/trainingModuleMedia'
import isObjectEmptyHelper from '../../../../utils/helpers/isObjectEmptyHelper'
import VideoPlayer from '../../../VideoPlayer/VideoPlayer'
import TextButton from '../../../ui/TextButton/TextButton'

const MyUploadVideoField = ({
  label,
  maxVideoSize,
  acceptedTypes,
  trainingModuleId,
  setShowDeleteModal,
}) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [progress, setProgress] = useState({})
  const [cancelTokens, setCancelTokens] = useState({})
  const { values, setFieldValue } = useFormikContext()
  const { [TRAINING_MODULES_VIDEO.UPLOADED_VIDEO]: uploadedFile } = values
  const [uploadFile, setUploadFile] = useState()
  const translate = useTranslate()
  const useStyles = makeStyles(myUploadVideoFieldStyles)
  const classes = useStyles()

  const maxVideoSizeInBytes = maxVideoSize * 1024 * 1024

  const isUploadCompleted = (progressEvent) => {
    if (!progressEvent) return false
    return progressEvent.loaded === progressEvent.total
  }

  // Cancel the uplaod if user presses the back button while the progress is still on without save
  useEffect(() => {
    const handleBackButton = () => {
      if (!isObjectEmptyHelper(progress) && !isUploadCompleted(progress)) {
        setProgress({})
        setFieldValue([TRAINING_MODULES_VIDEO.UPLOADED_VIDEO], '')
        setCancelTokens(cancelTokens.cancel())
      }
    }

    window.addEventListener('popstate', handleBackButton)

    return () => {
      window.removeEventListener('popstate', handleBackButton)
    }
  }, [cancelTokens, progress, setFieldValue])

  const openSnackbar = (erMessage) => {
    setErrorMessage(erMessage)
    setIsSnackbarOpen(true)
  }

  const uploadVideoRequest = useMutation({
    mutationFn: (data) => addVideoToAmazonBucket({ ...data, setProgress, setCancelTokens }),
    mutationKey: 'fileUpload',
  })

  const getPresignedUrlRequest = useMutation((data) => getUploadVideo(data), {
    onSuccess: (res, { file, saveFile }) => {
      const fileName = file.name
      setUploadFile(file.name)
      saveFile([TRAINING_MODULES_VIDEO.UPLOADED_VIDEO], fileName)
      uploadVideoRequest.mutate({
        url: res.result,
        file,
      })
    },
  })

  const onRemoveVideoClick = (tokensCancel, setTokensCancel, setUploadedFiles) => {
    if (tokensCancel[uploadFile]) {
      tokensCancel[uploadFile].cancel()
    }
    setTokensCancel({})
    setUploadedFiles(TRAINING_MODULES_VIDEO.UPLOADED_VIDEO, '')
    setProgress({})
    setUploadFile('')
  }

  const handleFileChange = (e, saveFile) => {
    const file = e.target.files[0]

    if (file && file.size && file.size <= maxVideoSizeInBytes) {
      getPresignedUrlRequest.mutate({
        trainingModuleId,
        requestBody: { trainingModuleId, file: file.name },
        file,
        saveFile,
      })
    } else {
      // Handle the case where the file is too large
      openSnackbar('ra.text.bigFileAdded')
      e.target.value = null // Clear the file input value
    }
  }

  const calculateProgress = (progressEvent) => {
    return (progressEvent.loaded / progressEvent.total) * 100
  }

  const onUploadClick = () => {
    setProgress({})
  }

  return (
    <div className={classes.mediaUploadContainer}>
      {!isObjectEmptyHelper(progress[uploadedFile]) &&
        isUploadCompleted(progress[uploadedFile]) &&
        uploadFile && (
          <>
            <div className={classes.videoPlayer}>
              <VideoPlayer video={uploadFile || uploadedFile} trainingModuleId={trainingModuleId} />
            </div>
            <TextButton
              onClick={() => setShowDeleteModal(true)}
              label={translate('ra.buttons.removeVideo')}
              className={classes.deleteButton}
            />
          </>
        )}
      {isObjectEmptyHelper(progress) && !uploadFile && uploadedFile && (
        <>
          <div className={classes.videoPlayer}>
            <VideoPlayer video={uploadFile || uploadedFile} trainingModuleId={trainingModuleId} />
          </div>
          <TextButton
            onClick={() => setShowDeleteModal(true)}
            label={translate('ra.buttons.removeVideo')}
            className={classes.deleteButton}
          />
        </>
      )}
      {uploadedFile?.length < 1 && !uploadFile?.length > 0 && (
        <div>
          <div className={classes.container}>
            <Button
              color='primary'
              className={classes.addButton}
              component='label'
              variant='contained'
              startIcon={<CloudUploadIcon />}
              onClick={() => onUploadClick()}
            >
              {label}
              <input
                type='file'
                className={classes.visuallyHiddenInput}
                onChange={(e) => handleFileChange(e, setFieldValue)}
                accept={acceptedTypes}
              />
            </Button>
            <Tooltip
              title={
                <h3 style={{ fontStyle: 'oblique' }}>
                  {translate('ra.text.uploadDrillVideoInfo')}
                </h3>
              }
              arrow
            >
              <InfoIcon className={classes.infoIcon} />
            </Tooltip>
          </div>
        </div>
      )}
      {uploadFile?.length > 0 && !isUploadCompleted(progress[uploadFile]) && (
        <div key={uploadedFile}>
          <div className={classes.uploadedFile}>
            <span>{uploadFile}</span>
            <div className={classes.cancel}>
              <CancelIcon
                onClick={() => onRemoveVideoClick(cancelTokens, setCancelTokens, setFieldValue)}
                className={classes.cancelButton}
              />
            </div>
          </div>
          {!isObjectEmptyHelper(progress[uploadFile]) &&
            !isUploadCompleted(progress[uploadFile]) && (
              <LinearProgress
                variant='determinate'
                value={calculateProgress(progress[uploadedFile])}
                style={{ width: '100%' }}
              />
            )}
        </div>
      )}
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert variant='filled' severity='error'>
          {translate(errorMessage)}
        </Alert>
      </Snackbar>
    </div>
  )
}

MyUploadVideoField.propTypes = {
  label: PropTypes.string,
  maxVideoSize: PropTypes.number,
  acceptedTypes: PropTypes.string,
  trainingModuleId: PropTypes.string,
  setShowDeleteModal: PropTypes.func,
}

export default MyUploadVideoField
