const groupTrainingModulesByTactics = (trainingModules) => {
  return trainingModules?.reduce((acc, obj) => {
    const key = JSON.stringify(obj.tactics.map((tactic) => tactic.name).sort())
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
}

export default groupTrainingModulesByTactics
