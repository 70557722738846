const practicePlanComponentsTableStyles = (edit) => ({
  tableRoot: {
    tableLayout: 'auto',
    height: 'fit-content',
  },
  tableBodyRoot: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  tableCellRoot: {
    padding: 8,
    maxWidth: '32rem',
    position: 'relative',
    borderRight: !edit ? '1px solid rgba(224, 224, 224, 1)' : null,

    '&::before': edit
      ? {
          content: "''",
          position: 'absolute',
          left: -1,
          top: '50%',
          transform: 'translateY(-50%)',
          height: 'calc(100% - 30px)',
          borderRight: '1px solid rgba(224, 224, 224, 1)',
        }
      : null,
  },
  textComponentNotes: {
    textAlign: 'start',
  },
  breakWord: {
    wordBreak: 'break-word',
  },
  durationField: {
    width: '9.5%',
    '@media (max-width: 1760px)': {
      width: '10.5%',
    },
    '@media (max-width: 1640px)': {
      width: '9%',
    },
    '@media (max-width: 1510px)': {
      width: '9%',
    },
    '@media (max-width: 1360px)': {
      width: '9%',
    },
    '@media (max-width: 1210px)': {
      width: '12%',
    },
  },
  description: {
    marginTop: '10px',
    fontSize: '14px',
    lineHeight: '20px',
  },
})

export default practicePlanComponentsTableStyles
