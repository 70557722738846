import renderDesignOnCanvas from './renderDesignsOnCanvas'

const designsRowPdfHelper = (designs, designWidth, pageLength, stadiumImage, halfStadiumImage) => {
  if (!designs?.length) return []

  const CANVAS_WIDTH = 528
  const CANVAS_HEIGHT = 800

  const columnsArray = []
  let designsSumLength = 0
  let columnsRow = []

  designs.forEach((design) => {
    designsSumLength += designWidth
    if (designsSumLength >= pageLength) {
      columnsArray.push(columnsRow)
      columnsRow = []
      designsSumLength = 0
    }

    const jsonData = JSON.parse(design.source)

    const canvas = renderDesignOnCanvas(
      jsonData,
      stadiumImage,
      halfStadiumImage,
      CANVAS_WIDTH,
      CANVAS_HEIGHT,
    )

    columnsRow.push({
      image: canvas.toDataURL(),
      width: designWidth,
    })
  })

  columnsArray.push(columnsRow)

  return columnsArray.map((columnArray) => ({
    columns: columnArray,
  }))
}

export default designsRowPdfHelper
