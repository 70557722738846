import React from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core'
import { useField, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import DATE_TIME_FIELD_STYLES from '../../../utils/constants/CSS/dateTimefieldStyles'
import CalendarIcon from '../../../assets/icons/calendarIcon'

const MyDateField = (props) => {
  const { name, required, onChange } = props
  const { values, setValues } = useFormikContext()
  const [field, meta, helpers] = useField(name)
  const { value } = field
  const { error } = meta
  const { setValue } = helpers
  const useStyles = makeStyles(DATE_TIME_FIELD_STYLES)
  const classes = useStyles()

  const isError = error || (!value && required)

  return (
    <div style={{ position: 'relative' }}>
      <KeyboardDatePicker
        {...props}
        variant='inline'
        inputVariant='filled'
        format='dd/MM/yyyy'
        fullWidth
        value={value}
        error={isError}
        helperText={error}
        onChange={(v) => (onChange ? onChange(values, v, setValues) : setValue(v))}
        keyboardIcon={<CalendarIcon />}
        KeyboardButtonProps={{
          classes: {
            root: classes.icon,
          },
        }}
        InputProps={{
          classes: {
            root: classes.root,
            input: classes.input,
          },
          disableUnderline: true,
        }}
        InputLabelProps={{
          classes: {
            formControl: classes.formControl,
            shrink: classes.shrink,
            focused: classes.focused,
          },
        }}
      />
    </div>
  )
}

MyDateField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
}

export default MyDateField
