import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import userAvatarStyles from './userAvatar.styles'

const UserAvatar = (props) => {
  const { userAvatar, setProfileMenuModal } = props
  const open = useSelector((state) => state.admin.ui.sidebarOpen)
  const useStyles = makeStyles(userAvatarStyles(open))
  const classes = useStyles()

  const handleClick = (e) => {
    if (open) return null

    return setProfileMenuModal(e.currentTarget)
  }

  return (
    <div onClick={handleClick}>
      {userAvatar ? (
        <img {...props} className={classes.logoImage} src={userAvatar} alt='avatar' />
      ) : (
        <AccountCircleIcon {...props} className={classes.accountIcon} />
      )}
    </div>
  )
}

UserAvatar.propTypes = {
  userAvatar: PropTypes.string,
  setProfileMenuModal: PropTypes.func,
}

export default UserAvatar
