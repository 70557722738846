const TOUR_IDS = {
  calendar: {
    orgName: 'orgName',
    orgLogo: 'orgLogo',
    players: 'players',
    coach: 'coachCorner',
    createEvent: 'createEvent',
  },
  drills: {
    public: 'public',
    add: 'addButtonDrill',
  },
  createdrills: {
    title: 'drillTitle',
    description: 'bigDes',
    addGraphics: 'graphicsAdd',
    publish: 'publishDrill',
    intensity: 'intensityDrills',
    position: 'positionsDrills',
    tactics: 'tacticsDrills',
    save: 'saveDelete',
  },
  rosterManagement: {
    playerList: 'playerList',
    search: 'searchPlayer',
    create: 'addPlayer',
  },
  createEvent: {
    form: 'my-form',
    date: 'startDate',
    time: 'startEndTime',
    team: 'teamField',
    opponent: 'opponentInfo',
    court: 'courtName',
  },
  createPractice: {
    form: 'my-form',
    date: 'practiceDate',
    time: 'practiceTime',
    team: 'practiceTeamField',
    location: 'practiceLocation',
    courtName: 'practiceCourtName',
    plan: 'practicePlan',
  },
  createplays: {
    description: 'playsDes',
  },
  practices: {
    create: 'newPractice',
    range: 'datesRange',
  },
  playbooks: {
    add: 'addPlayInBook',
    export: 'exportPlaybook',
  },
}

export default TOUR_IDS
