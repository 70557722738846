import COLORS from '../../../utils/constants/colors'

const billingAddonUsersSelctionStyles = () => ({
  buttonContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '12px',
  },
  addonCard: {
    backgroundColor: COLORS.opacBlue,
    borderColor: COLORS.blue,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  loaderIcon: {
    fill: COLORS.orange,
    marginRight: '48%',
    marginLeft: '48%',
    marginTop: 20,
    width: 70,
    height: 70,
  },
  selectedRow: {
    backgroundColor: COLORS.subscriptionBlue,
  },
  warningMessage: {
    paddingLeft: '20px',
    borderRadius: '4px',
    fontSize: '16px',
  },
})

export default billingAddonUsersSelctionStyles
