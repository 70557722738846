import { CARD_STYLES } from '../../../utils/constants/CSS/cardStyles'

const profileSettingsPageStyles = {
  card: CARD_STYLES.card,
  cardTitle: CARD_STYLES.cardTitle,
  cardSubtitle: CARD_STYLES.cardSubtitle,
  divider: CARD_STYLES.divider,
  cardContentRoot: {
    paddingTop: 0,
  },
}

export default profileSettingsPageStyles
