import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { makeStyles } from '@material-ui/core'
import COLORS from '../../utils/constants/colors'

const LeftArrowCircleIcon = (props) => {
  const useStyles = makeStyles({
    leftIcon: {
      width: '18px',
      height: '18px',
      cursor: 'pointer',
      fill: COLORS.black,
    },
  })
  const classes = useStyles()

  return <KeyboardArrowLeftIcon {...props} classes={{ root: classes.leftIcon }} />
}

export default LeftArrowCircleIcon
