import { useState, useEffect } from 'react'

const getWindowSize = () => {
  const hasWindow = typeof window !== 'undefined'

  const getWindowDimensions = () => {
    const windowWidth = hasWindow ? window.innerWidth : null
    const windowHeight = hasWindow ? window.innerHeight : null
    return {
      windowWidth,
      windowHeight,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [hasWindow])

  return windowDimensions
}

export default getWindowSize
