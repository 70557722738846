const playerCreateStyles = (theme) => ({
  root: {
    paddingLeft: '4%',
    paddingRight: '4%',
  },
  arrayInputRoot: {
    marginTop: 0,
    marginBlock: 0,
  },
  dateField: {
    [theme.breakpoints.down(1150)]: {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
})

export default playerCreateStyles
