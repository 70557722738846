import COLORS from '../../utils/constants/colors'

const dialogWithCloseIconStyles = (
  dialogWidth,
  dialogHeight,
  maxDialogWidth,
  minDialogWidth,
  maxDialogHeight,
  minDialogHeight,
  backgroundColor,
  fullScreen,
  overflow,
) => ({
  dialog: {
    maxWidth: maxDialogWidth ?? 'none',
    minWidth: minDialogWidth ?? null,
    maxHeight: maxDialogHeight ?? null,
    width: dialogWidth ?? '80%',
    height: dialogHeight ?? null,
    minHeight: minDialogHeight ?? null,
    backgroundColor: backgroundColor ?? COLORS.white,
    borderRadius: fullScreen ? '0px!important' : null,
  },
  dialogContentRoot: {
    '&&': {
      overflow: overflow || undefined,
      padding: '10px',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '20px',
    right: 0,
    paddingRight: '20px',
  },
  title: {
    fontWeight: 500,
  },
})

export default dialogWithCloseIconStyles
