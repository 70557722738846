import { isEmptyArray } from 'formik'
import GAME_EVENT_FORM_BROADCAST_FIELDS from './gameEventFormBroadcastFields'
import LIVESTREAM_TYPE_OPTIONS from './livestreamTypeOptions'
import isObjectEmptyHelper from '../helpers/isObjectEmptyHelper'

// Business rules for gameEventForm livestream fields (It's used for data parse and not form validation)
const LIVESTREAM_FIELDS_VALIDATIONS = {
  [GAME_EVENT_FORM_BROADCAST_FIELDS.IS_RECORD]: (values) =>
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST] ||
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_RECORD],

  [GAME_EVENT_FORM_BROADCAST_FIELDS.TYPE]: (values) =>
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST] ||
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_RECORD],

  [GAME_EVENT_FORM_BROADCAST_FIELDS.LOAD_RECENT_SETUPS]: (values) =>
    (values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST] ||
      values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_RECORD]) &&
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.TYPE] === LIVESTREAM_TYPE_OPTIONS.LIVESTREAM_FULL,

  [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY]: (values) =>
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST] &&
    isObjectEmptyHelper(values[GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG]),

  [GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG]: (values) =>
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST] &&
    !isObjectEmptyHelper(values[GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG]),

  [GAME_EVENT_FORM_BROADCAST_FIELDS.IS_LIVESTREAM_TYPE_FULL]: (values) =>
    (values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST] ||
      values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_RECORD]) &&
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.TYPE] === LIVESTREAM_TYPE_OPTIONS.LIVESTREAM_FULL,

  [GAME_EVENT_FORM_BROADCAST_FIELDS.UPLOADED_MEDIAS]: (values) =>
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST] &&
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.TYPE] === LIVESTREAM_TYPE_OPTIONS.LIVESTREAM_FULL &&
    values.teams &&
    !isEmptyArray(values.teams),

  [GAME_EVENT_FORM_BROADCAST_FIELDS.IS_COMMENTARY_ON]: (values) =>
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST],

  [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY_2]: (values) =>
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_ADDITIONAL_YOUTUBE_STREAM_KEY_ADDED],
}

export default LIVESTREAM_FIELDS_VALIDATIONS
