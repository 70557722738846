const myCheckboxOneToManyStyles = {
  checkboxWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chipLabel: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
  },
}

export default myCheckboxOneToManyStyles
