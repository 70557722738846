import { Button, makeStyles, Tooltip, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CancelIcon from '@material-ui/icons/Cancel'
import { LinearProgress, useTranslate } from 'react-admin'
import React, { useEffect, useState } from 'react'
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import InfoIcon from '@material-ui/icons/Info'
import { useIsMutating, useMutation } from 'react-query'
import { isEmptyArray, useFormikContext } from 'formik'
import myUploadFilesFieldStyles from './myUploadFilesField.styles'
import getUploadPresignedUrl from '../../../../Apis/event/getUploadPresignedUrl'
import useQueryParams from '../../../../hooks/useQueryParams'
import addVideoToAmazonBucket from '../../../../Apis/event/addVideoToAmazonBucket'
import GAME_EVENT_FORM_BROADCAST_FIELDS from '../../../../utils/constants/gameEventFormBroadcastFields'
import COLORS from '../../../../utils/constants/colors'
import { useTeamCalendarContext } from '../../../../context/useTeamCalendarContext'

const MyUploadFilesField = ({ label, maxVideoNum, acceptedTypes, setMediaIsUploading }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [progress, setProgress] = useState({})
  const [cancelTokens, setCancelTokens] = useState({})
  const queryParams = useQueryParams()
  const { org: orgId } = queryParams
  const { values, setFieldValue } = useFormikContext()
  const { [GAME_EVENT_FORM_BROADCAST_FIELDS.UPLOADED_MEDIAS]: uploadedFiles } = values
  const translate = useTranslate()
  const useStyles = makeStyles(myUploadFilesFieldStyles)
  const classes = useStyles()
  const numOfVideos = useIsMutating({ mutationKey: 'fileUpload' })
  const { createEvent, updateEvent } = useTeamCalendarContext()

  useEffect(() => {
    if ((createEvent.isLoading || updateEvent.isLoading) && numOfVideos > 0) {
      Object.values(cancelTokens).forEach((token) => token.cancel())
      setFieldValue(GAME_EVENT_FORM_BROADCAST_FIELDS.UPLOADED_MEDIAS, {})
      setProgress({})
    }
  }, [cancelTokens, createEvent.isLoading, numOfVideos, setFieldValue, updateEvent.isLoading])

  // TODO: Try creating a promise using mutateAsync and add Promise.all in useEffect .then(change staste)
  useEffect(() => {
    setMediaIsUploading(numOfVideos > 0)
  }, [numOfVideos, setMediaIsUploading])

  const uploadVideoRequest = useMutation({
    mutationFn: (data) => addVideoToAmazonBucket({ ...data, setProgress, setCancelTokens }),
    mutationKey: 'fileUpload',
  })

  const getPresignedUrlRequest = useMutation((data) => getUploadPresignedUrl(data), {
    onSuccess: (res, { file, saveFile }) => {
      const fileName = file.name
      saveFile([GAME_EVENT_FORM_BROADCAST_FIELDS.UPLOADED_MEDIAS], {
        ...uploadedFiles,
        [fileName]: res[fileName].id,
      })

      uploadVideoRequest.mutate({
        url: res[file?.name].url,
        file,
      })
    },
  })

  const openSnackbar = (erMessage) => {
    setErrorMessage(erMessage)
    setIsSnackbarOpen(true)
  }

  const onUploadClick = () => {
    if (Object.keys(uploadedFiles).length === maxVideoNum)
      openSnackbar('ra.errorMessages.maxVideosAdded')
  }

  // Check if media already uploaded and get s3 url
  const handleFileChange = (e, savedFiles, saveFile) => {
    const file = e.target.files[0]

    // Clear the file input value
    e.target.value = null

    if (Object.keys(savedFiles).includes(file.name)) {
      openSnackbar('ra.errorMessages.sameVideosAdded')
      return
    }

    getPresignedUrlRequest.mutate({
      teamId: Array.isArray(values.teams) ? values.teams[0].id : values.teams.id,
      requestBody: { orgId, files: [file.name] },
      file,
      saveFile,
    })
  }

  // Remove the media from the formik state and cancel the axios request
  const onRemoveVideoClick = (fileName, files, tokensCancel, setTokensCancel, setUploadedFiles) => {
    const newUploadedMedia = { ...files }
    delete newUploadedMedia[fileName]

    const newCancelTokens = { ...tokensCancel }
    delete newCancelTokens[fileName]

    const newProgress = { ...progress }
    delete newProgress[fileName]

    if (tokensCancel[fileName]) {
      tokensCancel[fileName].cancel() // Cancel the request associated with the token
    }

    setTokensCancel(newCancelTokens)
    setUploadedFiles(GAME_EVENT_FORM_BROADCAST_FIELDS.UPLOADED_MEDIAS, newUploadedMedia)

    setProgress(newProgress)
  }

  const calculateProgress = (progressEvent) => {
    return (progressEvent.loaded / progressEvent.total) * 100
  }

  const isUploadCompleted = (progressEvent) => {
    if (!progressEvent) return false
    return progressEvent.loaded === progressEvent.total
  }

  return (
    <div className={classes.mediaUploadContainer}>
      <div>
        <div className={classes.container}>
          <Button
            color='primary'
            className={classes.addButton}
            component='label'
            variant='contained'
            startIcon={<CloudUploadIcon />}
            onClick={() => onUploadClick()}
            disabled={
              Object.keys(uploadedFiles).length >= 3 || !values.teams || isEmptyArray(values.teams)
            }
          >
            {label}
            {Object.keys(uploadedFiles).length < maxVideoNum && (
              <input
                type='file'
                className={classes.visuallyHiddenInput}
                onChange={(e) => handleFileChange(e, uploadedFiles, setFieldValue)}
                accept={acceptedTypes}
              />
            )}
          </Button>
          <Tooltip
            title={<h3 style={{ fontStyle: 'oblique' }}>{translate('ra.text.uploadVideoInfo')}</h3>}
            arrow
          >
            <InfoIcon className={classes.infoIcon} />
          </Tooltip>
        </div>
        {(!values.teams || isEmptyArray(values.teams)) && (
          <Typography style={{ color: COLORS.red, fontSize: 12 }}>
            {translate('ra.text.addTeamFirst')}
          </Typography>
        )}
      </div>
      {Object.keys(uploadedFiles).length > 0 &&
        values.teams &&
        !isEmptyArray(values.teams) &&
        Object.entries(uploadedFiles)
          .map(([name, value]) => ({
            name,
            value,
          }))
          .map((video) => (
            <div key={video.id}>
              <div key={video.id} className={classes.uploadedFile}>
                <span>{video.name}</span>
                <CancelIcon
                  onClick={() =>
                    onRemoveVideoClick(
                      video.name,
                      uploadedFiles,
                      cancelTokens,
                      setCancelTokens,
                      setFieldValue,
                    )
                  }
                  className={classes.cancelButton}
                />
              </div>
              {progress[video.name] && !isUploadCompleted(progress[video.name]) && (
                <LinearProgress
                  variant='determinate'
                  value={calculateProgress(progress[video.name])}
                  style={{ width: '100%' }}
                />
              )}
            </div>
          ))}
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert variant='filled' severity='error'>
          {translate(errorMessage)}
        </Alert>
      </Snackbar>
    </div>
  )
}

MyUploadFilesField.propTypes = {
  label: PropTypes.string,
  maxVideoNum: PropTypes.number,
  acceptedTypes: PropTypes.string,
  setMediaIsUploading: PropTypes.func,
}

export default MyUploadFilesField
