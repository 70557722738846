const switchTripleOptionStyles = {
  container: {
    height: 35,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 25,
  },
  holder: { width: 68 },
}

export default switchTripleOptionStyles
