import * as React from 'react'
import { List, Datagrid, TextField } from 'react-admin'
import useQueryParams from '../../../../hooks/useQueryParams'

const TeamList = (props) => {
  const queryParams = useQueryParams()

  return (
    <List
      {...props}
      filter={{
        org: queryParams.org,
        teams: queryParams.team,
      }}
    >
      <Datagrid>
        <TextField source='name' />
      </Datagrid>
    </List>
  )
}

export default TeamList
