import useQueryParams from './useQueryParams'
import SIDEBAR_OPTION from '../utils/constants/sidebarOptions'

const useMenuItemLinks = (menuChoice) => {
  const queryParams = useQueryParams()
  const sidebarOptionEntries = Object.entries(SIDEBAR_OPTION)
  let selectedSidebarOption = ''

  sidebarOptionEntries.forEach((option) =>
    option[1].forEach((o) => {
      if (o.name === menuChoice) {
        if (o.name === 'players') {
          selectedSidebarOption = queryParams?.team ? 'TEAM_ITEMS' : 'MENU_ITEMS'
        } else {
          selectedSidebarOption = option[0]
        }
      }
      return null
    }),
  )

  if (!selectedSidebarOption) {
    selectedSidebarOption = queryParams?.team ? 'TEAM_ITEMS' : 'MENU_ITEMS'
  }

  return {
    selectedSidebarOption,
    isProfileItems: selectedSidebarOption === 'PROFILE_ITEMS',
    isTeamSettings: selectedSidebarOption === 'orgMenuChoices',
  }
}

export default useMenuItemLinks
