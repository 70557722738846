import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import QueryKeys from '../utils/constants/queryKeys'
import getDrills from '../Apis/drill/getDrills'
import TRAINING_MODULES_TYPES from '../utils/constants/trainingModulesTypes'

const DrillShowContext = createContext()

const DrillShowProvider = ({ children }) => {
  const history = useHistory()
  const trainingModuleType = history?.location?.pathname?.split('/')[1]

  const usersTrainingModules = useQuery(
    [QueryKeys.GET_DRILLS],
    () =>
      getDrills({
        access: 'PRIVATE',
        pageNumber: 1,
        perPage: 1000,
        type:
          trainingModuleType === 'drills' || trainingModuleType === 'practice-plan'
            ? TRAINING_MODULES_TYPES.drill
            : TRAINING_MODULES_TYPES.plays,
      }),
    {
      staleTime: Infinity,
    },
  )

  const value = {
    usersTrainingModules,
  }

  return <DrillShowContext.Provider value={value}>{children}</DrillShowContext.Provider>
}

const useDrillShowContext = () => {
  const context = useContext(DrillShowContext)
  if (context === undefined) {
    throw new Error('useDrillShowContext must be used within a DrillShowProvider')
  }
  return context
}

export { DrillShowProvider, useDrillShowContext }
