import COLORS from '../../../../utils/constants/colors'

const playerListActionsStyles = {
  topToolbarRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  filterButton: {
    '& svg': {
      backgroundColor: COLORS.orange,
      borderRadius: '100%',
      width: 16,
      height: 16,
      padding: 2,
      fill: COLORS.white,
      marginRight: 4,
    },
  },
  addIcon1: {
    '&&': {
      width: 22.5,
      height: 22.5,
    },
  },
}

export default playerListActionsStyles
