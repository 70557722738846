import React from 'react'
import { makeStyles } from '@material-ui/core'
import SportsHandballIcon from '@material-ui/icons/SportsHandball'
import COLORS from '../../utils/constants/colors'

const MySportsHandballIcon = (props) => {
  const useStyles = makeStyles({
    addIcon: {
      width: 22,
      height: 22,
      fill: COLORS.grey,
    },
  })
  const classes = useStyles()

  return <SportsHandballIcon {...props} classes={{ root: classes.addIcon }} />
}

export default MySportsHandballIcon
