import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import goBackButtonStyles from './goBackButton.styles'
import PreviousArrowIcon from '../../assets/icons/previousArrowIcon'
import ConditionalRender from '../ConditionalRender/ConditionalRender'

const GoBackButton = (props) => {
  const { label, goTo } = props
  const useStyles = makeStyles(goBackButtonStyles)
  const classes = useStyles()

  return (
    <Grid container alignItems='center' classes={{ root: classes.container }} onClick={goTo}>
      <PreviousArrowIcon className={classes.icon} />

      <ConditionalRender renderValue={label}>
        <span className={classes.label}> {label} </span>
      </ConditionalRender>
    </Grid>
  )
}

GoBackButton.propTypes = {
  label: PropTypes.string,
  goTo: PropTypes.func,
}

export default GoBackButton
