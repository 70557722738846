import React from 'react'
import './myLayout.css'
import { Layout } from 'react-admin'
import MySidebar from '../MySidebar/MySidebar'
import MyAppBar from '../MyAppBar/MyAppBar'
import { SignalRConnectionProvider } from '../../../../context/useSignalRConnectionContext'
import SetUrlAfterLogin from '../../../../components/SetUrlAfterLogin/SetUrlAfterLogin'
import LivestreamBannerContainer from '../../../../components/LivestreamBannerContainer/LivestreamBannerContainer'
import MobileNotificationModal from '../../../../components/MobileNotificationModal/MobileNotificationModal'
import IsUserScopesAuthorized from '../../../../components/IsUserScopesAuthorized/IsUserScopesAuthorized'
import { USER_SCOPES } from '../../../../utils/constants/userScopes'
import IsUserRolesAuthorized from '../../../../components/IsUserRolesAuthorized/IsUserRolesAuthorized'
import USER_ORG_ROLES from '../../../../utils/constants/userOrgRoles'
import USER_ORG_MEMBERS_ROLES from '../../../../utils/constants/userOrgMembersRoles'
import CloseSidebarWhenSmallScreen from '../../../../components/CloseSidebarWhenSmallScreen/CloseSidebarWhenSmallScreen'
import MyError from '../../MyError/MyError'
import DrawerMenuSmallScreen from '../../../../components/DrawerMenuSmallScreen/DrawerMenuSmallScreen'
import SupportBubble from '../../../../components/SupportBubble/SupportBubble'

const MyLayout = (props) => (
  <SignalRConnectionProvider>
    <Layout {...props} appBar={MyAppBar} sidebar={MySidebar} error={MyError} />
    <SetUrlAfterLogin />
    <CloseSidebarWhenSmallScreen />
    <DrawerMenuSmallScreen />
    <MobileNotificationModal />
    <IsUserScopesAuthorized acceptedScopes={[USER_SCOPES.LIVESTREAM]}>
      <IsUserRolesAuthorized
        acceptedRoles={[
          USER_ORG_ROLES.ADMIN,
          USER_ORG_ROLES.OWNER,
          USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
          USER_ORG_MEMBERS_ROLES.TEAM_MEMBER,
        ]}
      >
        <LivestreamBannerContainer />
      </IsUserRolesAuthorized>
    </IsUserScopesAuthorized>
    <SupportBubble />
  </SignalRConnectionProvider>
)

export default MyLayout
