import { shape, string } from 'prop-types'

const eventCategoryType = shape({
  id: string,
  code: string,
  name: string,
  color: string,
})

export default eventCategoryType
