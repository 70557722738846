const myAppbarStyles = {
  toolbar: {
    paddingRight: 0,
  },
  leftAppbarWrapper: { display: 'flex', flex: 1, alignItems: 'center' },
  rightAppbarWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  marginPlaceholder: {
    marginRight: 22,
  },
}

export default myAppbarStyles
