import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/core'
import COLORS from '../../utils/constants/colors'

const EditCircleIcon = (props) => {
  const useStyles = makeStyles({
    addIcon: {
      width: '18px',
      height: '18px',
      cursor: 'pointer',
      borderRadius: '50%',
      padding: '4px',
      backgroundColor: COLORS.orange,
      fill: COLORS.white,
    },
  })
  const classes = useStyles()

  return <EditIcon {...props} classes={{ root: classes.addIcon }} />
}

export default EditCircleIcon
