import React from 'react'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Group from '@material-ui/icons/Group'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import MyCancelIcon from '../../assets/icons/myCancelIcon'
import practicePlayersInfoRowStyles from './practicePlayerInfoRowStyles'

const PracticePlayerInfoRow = (props) => {
  const { className, practicePlayers, onContainerClick, onCancelIconClick = null } = props
  const translate = useTranslate()
  const useStyles = makeStyles(practicePlayersInfoRowStyles(onContainerClick))
  const classes = useStyles()

  if (!practicePlayers || practicePlayers.length === 0) return null

  return (
    <ConditionalRender renderValue={practicePlayers}>
      <div
        className={`${classes.divFlex} ${className}`}
        onClick={onContainerClick ?? null}
        role='button'
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && onContainerClick) {
            onContainerClick()
          }
        }}
      >
        <ConditionalRender renderValue={practicePlayers && onCancelIconClick}>
          <MyCancelIcon className={classes.icon} onClick={onCancelIconClick} />
        </ConditionalRender>
        <span className={classes.topic}>{translate('ra.text.selectedPlayers')}</span>
        <Group className={classes.group} />
        <span className={classes.componentsLength}>{practicePlayers?.players?.length}</span>
      </div>
    </ConditionalRender>
  )
}

PracticePlayerInfoRow.propTypes = {
  className: PropTypes.string,
  practicePlayers: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    creatorId: PropTypes.string.isRequired,
    creatorUser: PropTypes.string,
    eventId: PropTypes.string.isRequired,
    event: PropTypes.string,
    players: PropTypes.arrayOf(PropTypes.string.isRequired),
    practiceTeams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        numberOfPlayers: PropTypes.number.isRequired,
        team1Players: PropTypes.string.isRequired,
        team2Players: PropTypes.string.isRequired,
      }),
    ).isRequired,
    deletedDate: PropTypes.string,
  }),

  onCancelIconClick: PropTypes.func,
  onContainerClick: PropTypes.func,
}

export default PracticePlayerInfoRow
