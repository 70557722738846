const parsePostTrainingModule = (data, type, keepOriginalDesingsForm) => {
  const newTrainingModule = {
    ...data,
    ...(data.access ? { access: 'PUBLIC' } : { access: 'PRIVATE' }),
    ...(!data.positions && []),
    ...(!data.intensities && []),
    ...(data.tactics && data.tactics.length > 0
      ? { tactics: data.tactics.map((tactic) => tactic.id) }
      : { tactics: [] }),
    ...(!keepOriginalDesingsForm &&
      (data.designs.filter((des) => des.edited === true).length > 0
        ? {
            design: {
              existingDesignsIds:
                data.designs
                  ?.filter((design) => design.id)
                  .map((designWithId) => designWithId.id) || [],
              designsDetails: [
                ...data.designs
                  .filter((des) => des.edited === true)
                  .map((item) => {
                    return {
                      id: item.id,
                      source: item.source,
                      preview: item.preview,
                      description: item.description,
                      order: item.order,
                    }
                  }),
              ],
              designsOrder: data.designs
                ?.filter((design) => design.id)
                .reduce((acc, item) => {
                  acc[item.id] =
                    item.order >= data.designs.filter((des) => des.edited === true).order &&
                    !data.designs.filter((des) => des.edited === true).id
                      ? item.order + 1
                      : item.order
                  return acc
                }, {}),
            },
          }
        : {
            design: {
              existingDesignsIds:
                data.designs
                  ?.filter((design) => design.id)
                  .map((designWithId) => designWithId.id) || [],
            },
          })),
    ...(type && type === 'drills' ? { type: 'DRILL' } : { type: 'PLAY' }),
  }

  if (!keepOriginalDesingsForm) {
    delete newTrainingModule.designs
  }
  return newTrainingModule
}

export default parsePostTrainingModule
