import COLORS from '../../utils/constants/colors'
import { addOpacityToColor } from '../../utils/helpers/addOpacityToColor'

const accordionLabelBoxStyles = (clicked) => ({
  box: {
    height: 55,
    backgroundColor: clicked ? addOpacityToColor('#FFFFFF', 0.6) : COLORS.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '5px',
    cursor: 'pointer',
    position: 'relative',
  },
  icon: {
    marginLeft: '10px',
    marginRight: '5px',
    opacity: clicked ? '0.6' : 'unset',
  },
  label: {
    padding: '2px 2px 3px',
    opacity: clicked ? '0.6' : 'unset',
  },
  doneIcon: {
    fill: COLORS.orange,
  },
  addIcon: {
    position: 'absolute',
    right: '10px',
    display: 'none',
    cursor: 'pointer',
  },
  deleteIcon: {
    position: 'absolute',
    right: '30px',
    top: '10px',
    display: 'none',
    cursor: 'pointer',
    width: '20px',
    zIndex: 10,
    '&&': {
      fill: COLORS.orange,
    },
  },
  editIcon: {
    position: 'absolute',
    right: '50px',
    top: '10px',
    display: 'none',
    cursor: 'pointer',
    color: COLORS.orange,
    width: '20px',
    zIndex: 10,
  },
  boxHovered: {
    '&:hover $addIcon': {
      display: 'block',
    },
    '&:hover $editIcon': {
      display: 'block',
    },
    '&:hover $deleteIcon': {
      display: 'block',
    },
  },
})

export default accordionLabelBoxStyles
