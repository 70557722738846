import React from 'react'
import PropTypes from 'prop-types'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import AccordionLabelBox from './AccordionLabelBox'

const AccordionChild = (props) => {
  const {
    label,
    color,
    style,
    onClick,
    clicked,
    onDeleteChild,
    children,
    onUpdateName,
    isEditable,
    viewOnly,
  } = props

  return (
    <AccordionLabelBox
      label={label}
      icon={<FiberManualRecordIcon style={{ fontSize: '10px', fill: color }} />}
      style={style}
      onClick={onClick}
      clicked={clicked}
      onDeleteChild={onDeleteChild}
      onUpdateName={onUpdateName}
      isEditable={isEditable}
      viewOnly={viewOnly}
    >
      {children}
    </AccordionLabelBox>
  )
}

AccordionChild.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  // style: PropTypes.string,
  onClick: PropTypes.func,
  clicked: PropTypes.bool,
  onDeleteChild: PropTypes.func,
  children: PropTypes.node,
  onUpdateName: PropTypes.func,
  isEditable: PropTypes.bool,
  viewOnly: PropTypes.bool,
}

AccordionChild.defaultProps = {
  viewOnly: false,
}

export default AccordionChild
