import { shape, string, bool, arrayOf } from 'prop-types'
import enumIdType from './enumIdType'
import designType from './designType'
import enumType from './enumType'
import trainingModuleTacticType from './tactics/trainingModuleTacticType'

const trainingModuleType = shape({
  access: enumIdType,
  creator: shape({
    id: string,
    firstName: string,
    lastName: string,
  }),
  description: string,
  designs: arrayOf(designType),
  id: string,
  intensities: arrayOf(enumType),
  isHidden: bool,
  originalCreatorFirstName: string,
  originalTrainingModuleId: string,
  positions: arrayOf(enumType),
  revisionId: string,
  revisionReferenceId: string,
  tactics: arrayOf(trainingModuleTacticType),
  title: string,
  type: enumType,
})

export default trainingModuleType
