const addTeamModalStyles = {
  orgName: {
    display: 'block',
    marginTop: '18px',
    marginBottom: '18px',
  },
  orgLogo: {
    width: 110,
    height: 110,
    borderRadius: '50%',
  },
  emptyOrg: {
    padding: 12,
    backgroundColor: '#eeeeee',
    borderRadius: '50%',
    width: 90,
    height: 90,
  },
}

export default addTeamModalStyles
