import COLORS from '../../utils/constants/colors'

const practicePlanComponentsAccordionStyles = (expanded) => ({
  accordionRoot: {
    boxShadow: 'none',
    minHeight: 30,

    '&::before': {
      backgroundColor: 'transparent',
      height: 0,
    },
  },
  accordionRootExpanded: {
    '&&': {
      margin: 0,
    },
  },
  accordionSummaryRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 35,
    '&:hover:active': {
      '&::after': {
        backgroundColor: 'transparent',
      },
    },
  },
  accordionSummaryContent: {
    margin: 0,
  },
  accordionSummaryExpandIcon: {
    position: 'absolute',
    right: 2,
    top: -4,
    transform: expanded ? 'rotate(180deg)' : 0,
  },
  accordionSummaryExpanded: {
    '&&': {
      margin: 0,
      minHeight: 30,
    },
  },
  accordionSummaryBody: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  arrowIcon: {
    padding: 8,
    zIndex: 1000,
  },
  accordionDetails: {
    padding: '15px 0px',
  },
  practicePlanLabel: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '21px',
  },
  button: {
    padding: 0,
    fontWeight: 400,
    zIndex: 1000,
  },
  divFlex: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  handballIcon: {
    marginLeft: 'auto',
  },
  componentsLength: {
    padding: '4px 8px 4px 8px',
    backgroundColor: '#7d6bbd',
    borderRadius: '9px',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '12px',
    color: COLORS.white,
  },
})

export default practicePlanComponentsAccordionStyles
