import { FAKE_EVENT_UUID } from '../../constants/eventTypeForms'

const setFakeEvent = (setEvents, eventsRaw, selectedEventType, startDateTime, endDateTime) => {
  if (!eventsRaw) return null
  if (!selectedEventType) return eventsRaw

  setEvents([
    ...eventsRaw,
    {
      uuid: FAKE_EVENT_UUID,
      type: {
        displayName: selectedEventType,
        color: selectedEventType.color,
      },
      title: `NEW ${selectedEventType.name}`,
      timeZone: 'Europe/Athens',
      startDateTime,
      endDateTime,
      teams: [
        {
          name: 'TM',
          uuid: FAKE_EVENT_UUID,
          threeLetterIdentifier: '',
        },
      ],
    },
  ])
}

export default setFakeEvent
