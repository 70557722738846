const parseDateTime = (date, time) => {
  const newDate = new Date(date)
  const newTime = new Date(time)

  const hours = newTime.getHours()
  const minutes = newTime.getMinutes()

  newDate.setHours(hours, minutes)

  return newDate
}

export default parseDateTime
