import PropTypes from 'prop-types'

const ConditionalRender = ({ children, renderValue }) => {
  if (!renderValue || !children) {
    return null
  }

  return children
}

ConditionalRender.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  renderValue: PropTypes.bool,
}

export default ConditionalRender
