const chipFiledStyles = (backColor, wordColor) => ({
  cellContainer: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
  chipField: {
    backgroundColor: backColor,
    color: wordColor,
    marginRight: '10px',
  },
})

export default chipFiledStyles
