import axiosInstance from '../axiosInterceptor/axiosInstance'

const patchDrill = (data) => {
  const { values, trainingModuleId } = data
  const url = `/training-modules/${trainingModuleId}`

  return axiosInstance.patch(url, values)
}

export default patchDrill
