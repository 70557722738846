import localStorageHelper from '../../../utils/helpers/localStorageHelper'
import getBase64ImageFromUrlHelper from '../../../utils/helpers/getBase64ImageFromUrlHelper'
import playbookPdfContentMismatchLogo from './playbookPdfContentMismatchLogo'
import playPdfContent from './playPdfContent'
import localStorageIds from '../../../utils/constants/localStorageIds'

const playbookPdfContent = async (data) => {
  const { trainingModules } = data?.trainingModules[0] || {}
  const content = []
  const orgLocalStorageHelper = localStorageHelper(localStorageIds.SELECTED_ORG)
  const teamLocalStorageHelper = localStorageHelper(localStorageIds.SELECTED_TEAM)
  const { name, logoUrl } = orgLocalStorageHelper?.localStorageValue || {}

  // Set logo
  content.push({
    svg: playbookPdfContentMismatchLogo,
    width: 200,
    style: 'mismatchLogo',
  })

  // Set Organisation Logo
  if (logoUrl) {
    const orgLogoBase64 = await getBase64ImageFromUrlHelper(logoUrl)

    content.push({
      image: orgLogoBase64,
      width: 200,
      style: 'orgLogo',
    })
  }

  // Set Org Name
  content.push({
    text: name,
    style: 'orgName',
  })

  // Set Team Name
  content.push({
    text: teamLocalStorageHelper?.localStorageValue?.name,
    style: 'teamName',
  })

  // Set Playbook Text
  content.push({
    text: 'PLAYBOOK',
    style: 'playbookText',
    pageBreak: 'after',
  })

  // Add Plays with an empty text for page break.
  if (trainingModules?.length > 0) {
    const playContentPromises = trainingModules.reduce(
      async (previousPromise, trainingModule, index) => {
        // Wait for the previous promise to resolve
        await previousPromise

        // Execute playPdfContent for the current training module
        const playContent = await playPdfContent({ trainingModule })

        // If this is not the first module, add a page break before it
        if (index > 0) {
          content.push({ text: '', pageBreak: 'after' })
        }

        // Push the playContent to content array if it exists
        if (playContent) {
          content.push(playContent)
        }

        // Return the playContent promise for the next iteration
        return playContent
      },
      // Make this promise resolve
      Promise.resolve(),
    )

    // Wait for all promises to finish before continuing
    await playContentPromises
  }

  return content
}

export default playbookPdfContent
