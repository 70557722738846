import COLORS from '../../../../utils/constants/colors'

const playerCreateToolbarStyles = {
  toolbar: {
    backgroundColor: COLORS.white,
    marginTop: 0,
  },
}

export default playerCreateToolbarStyles
