import React from 'react'
import { Form, Formik } from 'formik'
import { makeStyles, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { useMutation, useQueryClient } from 'react-query'
import PropTypes from 'prop-types'
import MyTextField from '../../../components/form/fields/MyTextField'
import MyCheckCircleIcon from '../../../assets/icons/MyCheckCircleIcon'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import courtsAddNewStyles from './courtsAddNew.styles'
import addCourt from '../../../Apis/courts/addCourt'
import QueryKeys from '../../../utils/constants/queryKeys'
import { updateCourt } from '../../../Apis/courts/updateCourt'
import useQueryParams from '../../../hooks/useQueryParams'
import courtType from '../../../types/courtType'
import MyCloseIcon from '../../../assets/icons/MyCloseIcon'

const CourtsAddNew = (props) => {
  const { addCourtShow, setAddCourtShow, editedCourt, setEditedCourtId } = props
  const translate = useTranslate()
  const queryClient = useQueryClient()
  const queryParams = useQueryParams()
  const useStyles = makeStyles(courtsAddNewStyles)
  const classes = useStyles()

  const createCourt = useMutation((data) => addCourt(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_COURTS)
      setAddCourtShow(false)
    },
  })

  const updateACourt = useMutation((data) => updateCourt(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_COURTS)
      setAddCourtShow(false)
      setEditedCourtId('')
    },
  })

  const handleSubmit = (formValues) => {
    if (!editedCourt) {
      return createCourt.mutate({
        orgId: queryParams?.org,
        ...formValues,
      })
    }

    return updateACourt.mutate({
      ...formValues,
      courtId: editedCourt.id,
    })
  }

  const handleCancelButton = () => {
    setAddCourtShow(false)
    setEditedCourtId('')
  }

  return (
    <ConditionalRender renderValue={addCourtShow}>
      <Formik
        initialValues={{
          name: editedCourt?.name ?? '',
          description: editedCourt?.description ?? '',
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell width='30%'>
                  <MyTextField
                    label={translate('ra.formFields.name')}
                    name='name'
                    variant='filled'
                  />
                </TableCell>
                <TableCell width='60%'>
                  <MyTextField
                    label={translate('ra.text.description')}
                    name='description'
                    variant='filled'
                  />
                </TableCell>
                <TableCell width='10%'>
                  <div className={classes.buttonsWrapper}>
                    <button type='submit' className={classes.checkButton}>
                      <MyCheckCircleIcon />
                    </button>
                    <MyCloseIcon onClick={handleCancelButton} />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Form>
      </Formik>
    </ConditionalRender>
  )
}

CourtsAddNew.propTypes = {
  addCourtShow: PropTypes.bool,
  setAddCourtShow: PropTypes.func,
  editedCourt: courtType,
  setEditedCourtId: PropTypes.func,
}

export default CourtsAddNew
