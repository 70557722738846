import COLORS from '../../utils/constants/colors'

const myDateRangePickerStyles = {
  dateRangePickerRoot: {
    '& div': {
      boxShadow: 'unset',
    },
  },
  dialog: {
    maxWidth: 'unset',
    width: '750px',
  },
  dialogContentRoot: {
    '&&': {
      padding: '25px 0 5px 25px',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    zIndex: 100,
  },
  divFlex: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  arrowIcon: {
    '&&': {
      fill: COLORS.orange,
    },
  },
  calendarIcon: {
    cursor: 'pointer',
    fill: COLORS.orange,
  },
}

export default myDateRangePickerStyles
