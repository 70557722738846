import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, Tooltip } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import TextButton from '../ui/TextButton/TextButton'
import MyCloudDownloadIcon from '../../assets/icons/myCloudDownloadIcon'
import trainingModuleDownloadButtonStyles from './trainingModuleDownloadButton.styles'

const TrainingModuleDownloadButton = (props) => {
  const { label, disabled, onClick } = props
  const translate = useTranslate()
  const useStyles = makeStyles(trainingModuleDownloadButtonStyles)
  const classes = useStyles()

  return !label ? (
    <Tooltip title={translate('ra.buttons.addToPersonalLower')} onClick={onClick}>
      <IconButton color='primary' component='span' disabled={disabled}>
        <MyCloudDownloadIcon className={classes.orangeIcon} />
      </IconButton>
    </Tooltip>
  ) : (
    <TextButton
      onClick={onClick}
      label={label}
      disabled={disabled}
      startIcon={<MyCloudDownloadIcon className={classes.orangeIcon} />}
    />
  )
}

export default TrainingModuleDownloadButton
