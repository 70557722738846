import COLORS from '../../../../utils/constants/colors'

const setRolesMemberFieldsStyles = {
  formContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    marginBottom: '20px',
  },
  formItem: {
    flex: 1,
    maxWidth: '48%',
  },
  typoBold: { fontWeight: 'bold' },
  typoGrey: { color: COLORS.grey },
}

export default setRolesMemberFieldsStyles
