import { shape, string, arrayOf } from 'prop-types'
import eventCategoryType from './events/eventCategoryType'
import broadcastType from './broadcastType'
import enumIdType from './enumIdType'

const broadcastEventType = shape({
  id: string,
  startDateTime: string,
  endDateTime: string,
  title: string,
  type: eventCategoryType,
  broadcast: broadcastType,
  teams: arrayOf(enumIdType),
})

export default broadcastEventType
