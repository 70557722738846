import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  Grid,
  Divider,
} from '@material-ui/core'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import teamsDisplayStyles from './teamsDisplayStyles'
import MyDeleteIcon from '../../assets/icons/MyDeleteIcon'
import MyEditIcon from '../../assets/icons/MyEditIcon'
import ContainerPadding from '../containerPadding/containerPadding'

const TeamsDisplay = ({ teams, onRemove, playersInEachTeam, edit, onEditClick }) => {
  const translate = useTranslate()
  const useStyles = makeStyles(teamsDisplayStyles)
  const classes = useStyles()

  if (!teams || teams.length === 0) {
    return null
  }

  return (
    <ContainerPadding padding='0 0% 0 0%'>
      <div className={classes.infoRow}>
        <span>{`Teams of ${playersInEachTeam}`}</span>

        {edit && (
          <div className={classes.buttons}>
            <MyEditIcon onClick={onEditClick} />
            <MyDeleteIcon onClick={() => onRemove()} />
          </div>
        )}
      </div>
      <Grid container spacing={1}>
        {teams.map((team, teamIndex) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={uuidv4()}>
            <TableContainer className={classes.tableContainer} key={uuidv4()}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <TableCell
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        color: 'white',
                        padding: '8px',
                        height: '20px',
                        width: '50%',
                      }}
                    >
                      <span>{`${translate('ra.text.team')} ${teamIndex + 1}`}</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {team.map(
                    (player, playerIndex) =>
                      player?.personalData?.firstName && (
                        <TableRow key={player?.id || player}>
                          <TableCell
                            className={classes.tableCell}
                            style={{
                              borderTop: playerIndex === playersInEachTeam ? '1px solid black' : '',
                            }}
                          >
                            <span>{`${player?.personalData?.firstName} ${player?.personalData?.lastName}`}</span>
                          </TableCell>
                        </TableRow>
                      ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ))}
      </Grid>
      <Divider variant='middle' className={classes.divider} />
    </ContainerPadding>
  )
}

TeamsDisplay.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  onRemove: PropTypes.func.isRequired,
  playersInEachTeam: PropTypes.number.isRequired,
  edit: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
}

export default TeamsDisplay
