const playerListStyles = {
  drawerPaper: {
    width: '31%',
    minWidth: 540,
  },
  drawerContainer: { overflow: 'auto' },
  listMain: {
    minHeight: 'calc(100dvh - 130px)',
    height: 'fit-content',
  },
  listContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  dataGridRowCell: {
    padding: '7px 24px 7px 16px',
  },
}

export default playerListStyles
