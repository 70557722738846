import COLORS from '../../utils/constants/colors'

const drawerMenuSmallScreenStyles = {
  drawerPaper: {
    width: 250,
    overflowX: 'hidden',
  },
  menuIconWrapper: {
    marginTop: 7,
    marginLeft: 5,
  },
  menuIcon: {
    fill: COLORS.orange,
  },
}

export default drawerMenuSmallScreenStyles
