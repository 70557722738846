import * as React from 'react'
import { useTranslate } from 'react-admin'
import { useMutation } from 'react-query'
import { makeStyles } from '@material-ui/core'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import useFormValidation from '../../../hooks/useFormValidation'
import resetPasswordFormStyles from './resetPasswordForm.styles'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import MyTextFieldPassword from '../../../components/form/fields/MyTextFieldPassword'
import useHistoryPush from '../../../hooks/useHistoryPush'
import resetPassword from '../../../Apis/user/resetPassword'
import getQueryParameterByName from '../../../utils/helpers/getQueryParameterByName'

const ResetPasswordForm = () => {
  const translate = useTranslate()
  const { password } = useFormValidation()
  const { redirect } = useHistoryPush()
  const email = getQueryParameterByName('email')
  const resetPasswordToken = getQueryParameterByName('resetPasswordToken')
  const useStyles = makeStyles(resetPasswordFormStyles)
  const classes = useStyles()

  const resetThePassword = useMutation((data) => resetPassword(data), {
    onSuccess: () => {
      redirect('/login', [{ email }])
    },
  })

  const onSubmit = (data) => {
    resetThePassword.mutate({
      ...data,
      email,
      resetPasswordToken,
    })
  }

  return (
    <Formik
      initialValues={{
        password: '',
      }}
      validationSchema={yup.object().shape({
        password,
      })}
      onSubmit={(data) => onSubmit(data)}
    >
      {() => (
        <Form>
          <div className={classes.formWrapper}>
            <MyTextFieldPassword
              label={translate('ra.formFields.password')}
              name='password'
              required
            />
            <ButtonWithLoader
              fullWidth
              label={translate('ra.buttons.submit')}
              style={{ marginTop: 10 }}
              loading={resetThePassword.isLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ResetPasswordForm
