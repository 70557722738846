import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useTranslate } from 'react-admin'
import { Card, CardContent, CardHeader, Divider, makeStyles, Typography } from '@material-ui/core'
import profileSettingsPageStyles from './profileSettingsPage.styles'
import ProfileSettingsInfo from '../ProfileSettingsInfo/ProfileSettingsInfo'
import ProfileSettingsSecurityForm from '../ProfileSettingsSecurityForm'
import ProfileSettingsCloseAccount from '../ProfileSettingsCloseAccount/ProfileSettingsCloseAccount'
import useQueryParams from '../../../hooks/useQueryParams'
import QueryKeys from '../../../utils/constants/queryKeys'
import getUserData from '../../../Apis/user/getUserData'
import VersionNumber from '../../../components/ui/VersionNumber/VersionNumber'
import CardWrapperMoreIcon from '../../../components/layout/CardWrapperMoreIcon/CardWrapperMoreIcon'

const ProfileSettingsPage = () => {
  const translate = useTranslate()
  const { profileOption } = useQueryParams()
  const useStyles = makeStyles(profileSettingsPageStyles)
  const classes = useStyles()

  const SCROLL_MARGIN_TOP = 10

  useEffect(() => {
    if (profileOption) {
      const element = document.getElementById(profileOption)
      const elementScroll = element?.offsetTop
      const mainBody = document.getElementById('main-content')

      mainBody.scrollTo({
        top: elementScroll - SCROLL_MARGIN_TOP,
        behavior: 'smooth',
      })
    }
  }, [profileOption])

  const me = useQuery([QueryKeys.GET_ACCOUNT_ME], () => getUserData())

  return (
    <CardWrapperMoreIcon>
      {!me.isLoading ? (
        <Card classes={{ root: classes.card }}>
          <CardHeader
            title={
              <Typography variant='h5' id='profile' className={classes.cardTitle}>
                {translate('ra.page.profile')}
              </Typography>
            }
            subheader={
              <Typography className={classes.cardSubtitle}>
                {translate('ra.text.profileSettingsSub')}
              </Typography>
            }
          />
          <CardContent classes={{ root: classes.cardContentRoot }}>
            <Divider variant='middle' className={classes.divider} />
            <ProfileSettingsInfo userData={me?.data} />
            {/* <Typography className={classes.cardTitle} variant='h5' id="preferences">*/}
            {/*  {translate("ra.page.preferences")}*/}
            {/*</Typography>*/}
            {/*<Divider variant="middle" className={classes.divider}/>*/}
            {/*<ProfilePreferencesForm*/}
            {/*  userGeneralPreferences={userGeneralPreferences}*/}
            {/*  userTemporalPreferences={userTemporalPreferences}*/}
            {/*  allGeneralPreferences={allGeneralPreferences}*/}
            {/*  allTemporalPreferences={allTemporalPreferences}*/}
            {/*/> */}
            <Typography className={classes.cardTitle} variant='h5' id='security'>
              {translate('ra.page.security')}
            </Typography>
            <Divider variant='middle' className={classes.divider} />
            <ProfileSettingsSecurityForm />
            <Divider variant='middle' className={classes.divider} />
            <ProfileSettingsCloseAccount />
            <VersionNumber />
          </CardContent>
        </Card>
      ) : null}
    </CardWrapperMoreIcon>
  )
}

export default ProfileSettingsPage
