import React from 'react'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import MySportsHandballIcon from '../../assets/icons/MySportsHandballIcon'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import MyCancelIcon from '../../assets/icons/myCancelIcon'
import practicePlanInfoRowStyles from './practicePlanInfoRow.styles'
import practicePlanShortType from '../../types/practicePlanShortType'

const PracticePlanInfoRow = (props) => {
  const { className, practicePlan, onContainerClick, onCancelIconClick } = props
  const translate = useTranslate()
  const useStyles = makeStyles(practicePlanInfoRowStyles(onContainerClick))
  const classes = useStyles()

  return (
    <ConditionalRender renderValue={practicePlan}>
      <div className={`${classes.divFlex} ${className}`} onClick={onContainerClick ?? null}>
        <ConditionalRender renderValue={onCancelIconClick}>
          <MyCancelIcon className={classes.icon} onClick={onCancelIconClick} />
        </ConditionalRender>
        <span className={classes.topic}>
          {practicePlan?.description
            ? practicePlan.description
            : translate('ra.text.practicePlanTopic')}
        </span>
        <MySportsHandballIcon className={classes.handballIcon} />
        <span className={classes.componentsLength}>
          {Array.isArray(practicePlan?.practicePlanComponents)
            ? practicePlan?.practicePlanComponents.length
            : practicePlan?.practicePlanComponents || '0'}
        </span>
      </div>
    </ConditionalRender>
  )
}

PracticePlanInfoRow.propTypes = {
  className: PropTypes.string,
  practicePlan: practicePlanShortType,
  onContainerClick: PropTypes.func,
  onCancelIconClick: PropTypes.func,
}

export default PracticePlanInfoRow
