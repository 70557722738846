import COLORS from '../../../utils/constants/colors'

const orgAddonSelectStyles = {
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  loader: {
    fill: COLORS.orange,
    width: 70,
    height: 70,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
}

export default orgAddonSelectStyles
