import COLORS from '../../utils/constants/colors'

const myToggleButtonStyles = {
  root: {
    '&&': {
      color: COLORS.orange,
      padding: '5px 10px 5px 10px',
      border: `1px solid ${COLORS.orange}`,
      backgroundColor: COLORS.white,
    },
  },
  selected: {
    '&&': {
      backgroundColor: COLORS.lightOrange,
      '&:hover': {
        backgroundColor: COLORS.lightOrange,
      },
    },
  },
}

export default myToggleButtonStyles
