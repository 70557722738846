import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import QueryKeys from '../utils/constants/queryKeys'
import addDrill from '../Apis/drill/addDrill'
import getDrill from '../Apis/drill/getDrill'
import patchDrill from '../Apis/drill/patchDrill'
import useHistoryPush from './useHistoryPush'
import deleteDrill from '../Apis/drill/deleteDrill'
import parsePostTrainingModule from '../utils/helpers/parsePostTrainingModule'
import parsePatchTrainingModule from '../utils/helpers/parsePatchTrainingModule'

const useCreateDrillFormQueries = () => {
  const history = useHistory()
  const pathname = history?.location?.pathname?.split('/')[1]
  const trainingModuleId = history?.location?.pathname?.split('/')[2]
  const queryClient = useQueryClient()
  const { redirect } = useHistoryPush()

  const trainingModule = useQuery(
    [QueryKeys.GET_DRILL, trainingModuleId],
    () => getDrill(trainingModuleId),
    {
      enabled: Boolean(trainingModuleId && trainingModuleId !== 'create'),
    },
  )

  const addADrill = useMutation((data) => addDrill(data), {
    onSuccess: ({ data }, { closeDesignerModal }) => {
      // In this case we close designer modal and add training-module id to url
      if (closeDesignerModal) {
        closeDesignerModal(false)
        queryClient.invalidateQueries(QueryKeys.GET_DRILL)
        return redirect(`${pathname}/${data?.id}`)
      }

      return redirect(`${pathname}/${data?.id}/show`)
    },
  })

  const patchADrill = useMutation((data) => patchDrill(data), {
    onSuccess: ({ data }, { closeDesignerModal }) => {
      queryClient.invalidateQueries(QueryKeys.GET_DRILL)

      // In this case we only close designer modal without redirect.
      if (closeDesignerModal) return closeDesignerModal(false)

      return redirect(`${pathname}/${data?.id}/show`)
    },
  })

  const deleteADrill = useMutation((drillId) => deleteDrill(drillId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_DRILLS)
      redirect(`/${pathname}`)
    },
  })

  const isFetching = trainingModule?.isFetching

  const isMutating = addADrill.isLoading || patchADrill.isLoading || deleteADrill.isLoading

  const createDrillProcess = (data, closeDesignerModal) =>
    addADrill.mutate({ values: parsePostTrainingModule(data, pathname), closeDesignerModal })
  const updateDrillProcess = (data, closeDesignerModal) =>
    patchADrill.mutate({
      values: parsePatchTrainingModule(data, pathname, false),
      trainingModuleId,
      closeDesignerModal,
    })
  const deleteDrillProcess = () => deleteADrill.mutate(trainingModuleId)

  return {
    trainingModule: trainingModule?.data,
    createDrill: createDrillProcess,
    updateDrill: updateDrillProcess,
    deleteDrill: deleteDrillProcess,
    isFetching,
    isMutating,
    type: pathname,
  }
}

export default useCreateDrillFormQueries
