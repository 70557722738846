import COLORS from '../colors'

const DATE_TIME_FIELD_STYLES = {
  // InputLabelProps
  formControl: {
    '&&': {
      transform: 'translate(12px, 14px)',
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.40)',
    },
  },
  shrink: {
    '&&': {
      transform: 'translate(12px, -6px)',
      fontSize: '12px',
      color: COLORS.black,
    },
  },
  focused: {
    '&&': {
      color: `${COLORS.black}`,
    },
  },

  // InputProps
  root: {
    borderRadius: '6px',
  },
  input: {
    height: '0.7rem',
    minHeight: '0.7rem',
    fontSize: '0.95rem',
    padding: '17px 12px 14px',
  },

  // icon
  icon: {
    padding: '10px',
  },
}

export default DATE_TIME_FIELD_STYLES
