import LIVESTREAM_TYPE_OPTIONS from './livestreamTypeOptions'

export const GAME = 'game'
export const PRACTICE = 'practice'
export const DEFAULT_TYPE_GAME = LIVESTREAM_TYPE_OPTIONS.LIVESTREAM_FULL
export const DEFAULT_TYPE_PRACTICE = LIVESTREAM_TYPE_OPTIONS.LIVESTREAM_LITE

export const LIVESTREAM_TYPE_OPTIONS_MAPPER = {
  [GAME]: (type) => type ?? DEFAULT_TYPE_GAME,
  [PRACTICE]: (type) => type ?? DEFAULT_TYPE_PRACTICE,
}