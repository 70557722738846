import React from 'react'
import { TableCell } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import ButtonWithLoader from '../../../ui/ButtonWithLoader/ButtonWithLoader'
import COLORS from '../../../../utils/constants/colors'
import TextButton from '../../../ui/TextButton/TextButton'

const AcceptRejectField = ({
  columnAlignment,
  onAcceptClick,
  onRejectClick,
  row,
  hasNoDivider,
}) => {
  const translate = useTranslate()

  return (
    <TableCell
      align={columnAlignment}
      style={{
        borderBottom: hasNoDivider ? 'none' : '1px solid rgba(224, 224, 224, 1)',
      }}
    >
      <ButtonWithLoader
        label={translate('ra.buttons.accept')}
        style={{ marginRight: '5px' }}
        onClick={(e) => onAcceptClick(e, row)}
      />
      <TextButton
        label={translate('ra.buttons.reject')}
        style={{ marginLeft: '5px' }}
        onClick={(e) => onRejectClick(e, row)}
      />
    </TableCell>
  )
}

export default AcceptRejectField
