import COLORS from '../../utils/constants/colors'

const practicePlanCreateStyles = () => ({
  wrapper: {
    height: '1000%',
    backgroundColor: COLORS.white,
  },
  header: {
    padding: '1.5%',
  },
  headerButtons: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 15,
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

export default practicePlanCreateStyles
