import { useRef, useEffect, React } from 'react'
import { makeStyles, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import DrillComponentInfo from '../DrillComponentInfo/DrillComponentInfo'
import DurationChip from '../DurationChip/DurationChip'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import ComponentField from '../ComponentField/ComponentField'
import PracticePlanComponentsTableHead from '../practicePlanComponentsTableHead/practicePlanComponentsTableHead'
import sortByStringValueDescending from '../../utils/helpers/sortByStringValueDescending'
import mutationType from '../../types/mutationType'
import practicePlanType from '../../types/practicePlanType'
import practicePlanComponentType from '../../types/practicePlanComponentType'
import practicePlanComponentsTableStyles from './practicePlanComponentsTable.styles'
import useResize from '../../hooks/useResize'
import TABLET_SCREEN_WIDTH from '../../utils/constants/tabletScreenWidth'

const PracticePlanComponentsTable = (props) => {
  const {
    edit,
    openDrillShowPageModal,
    practicePlan,
    setPracticePlan,
    triggerPracticePlanRequest,
    setUpdatedComponents,
    setDeletedComponents,
  } = props
  const { width: screenWidth } = useResize(document?.body)
  const isSmallScreen = screenWidth < TABLET_SCREEN_WIDTH
  const isShowPage = window.location.href.includes('show')
  const orderedComponents = sortByStringValueDescending(
    practicePlan?.practicePlanComponents,
    'order',
  )
  const useStyles = makeStyles(practicePlanComponentsTableStyles(edit))
  const classes = useStyles()
  const lastRowRef = useRef(null)
  const prevOrderedComponentsLength = useRef(orderedComponents.length)

  const handleDeleteComponent = (component) => {
    if (component?.id) setDeletedComponents((prevValue) => [...prevValue, component?.id])

    setPracticePlan({
      ...practicePlan,
      practicePlanComponents: [
        ...practicePlan?.practicePlanComponents?.filter(
          (comp) => comp?.id !== component?.id || comp?.tempId !== component?.tempId,
        ),
      ],
    })
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    height: '100%',
    background: isDragging ? '#9e9e9e1a' : 'transparent',
    display: isDragging && edit ? 'table' : '',
    ...draggableStyle,
  })

  const handleDragEnd = (result) => {
    if (!result.destination) return

    const { draggableId, source, destination } = result
    const component = orderedComponents.find(
      (rowComponent) => rowComponent.id === draggableId || rowComponent.tempId === draggableId,
    )
    const newItems = Array.from(orderedComponents)
    newItems.splice(source.index, 1)
    newItems.splice(destination.index, 0, component)
    const reorderItems = newItems.map((item, index) => ({ ...item, order: index }))
    setPracticePlan({ ...practicePlan, practicePlanComponents: reorderItems })

    triggerPracticePlanRequest(false, reorderItems)
  }

  useEffect(() => {
    if (lastRowRef.current && orderedComponents.length > prevOrderedComponentsLength.current) {
      lastRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [orderedComponents.length])
  useEffect(() => {
    prevOrderedComponentsLength.current = orderedComponents.length
  }, [orderedComponents.length])

  return (
    <Table classes={{ root: classes.tableRoot }}>
      <ConditionalRender renderValue={edit}>
        <PracticePlanComponentsTableHead />
      </ConditionalRender>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='droppable' direction='vertical'>
          {(droppableProvided) => (
            <TableBody
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              classes={{ root: classes.tableBodyRoot }}
            >
              {orderedComponents.map((component, index) => (
                <Draggable
                  key={component.id ?? component.tempId}
                  draggableId={component.id ?? component.tempId}
                  index={index}
                  isDragDisabled={!edit}
                >
                  {/* TODO: use formik to set description and component states */}
                  {(draggableProvided, snapshot) => {
                    return (
                      <TableRow
                        key={component.id ?? component.tempId}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        ref={(node) => {
                          draggableProvided.innerRef(node)
                          if (index === orderedComponents.length - 1) {
                            lastRowRef.current = node
                          }
                        }}
                        style={getItemStyle(
                          snapshot.isDragging,
                          draggableProvided.draggableProps.style,
                        )}
                      >
                        <TableCell
                          classes={{ root: `${classes.tableCellRoot} ${classes.durationField}` }}
                        >
                          {edit ? (
                            <ComponentField
                              practicePlan={practicePlan}
                              setPracticePlan={setPracticePlan}
                              setUpdatedComponents={setUpdatedComponents}
                              component={component}
                              duration
                            />
                          ) : (
                            <DurationChip duration={component.duration} />
                          )}
                        </TableCell>

                        {component.trainingModule ? (
                          <TableCell
                            classes={{ root: classes.tableCellRoot }}
                            onClick={() => {
                              openDrillShowPageModal ? openDrillShowPageModal(component) : {}
                            }}
                          >
                            <DrillComponentInfo drill={component.trainingModule} edit={edit} />
                          </TableCell>
                        ) : (
                          <TableCell
                            align='center'
                            classes={{ root: classes.tableCellRoot }}
                            style={{ height: '50px' }}
                            colSpan={2}
                          >
                            {edit ? (
                              <ComponentField
                                deleteComponent={() => handleDeleteComponent(component)}
                                practicePlan={practicePlan}
                                setPracticePlan={setPracticePlan}
                                setUpdatedComponents={setUpdatedComponents}
                                component={component}
                              />
                            ) : (
                              <div className={classes.textComponentNotes}>
                                {component.notes ? component.notes : '-'}
                              </div>
                            )}
                          </TableCell>
                        )}

                        <ConditionalRender
                          renderValue={component.trainingModule && !(isSmallScreen && isShowPage)}
                        >
                          <TableCell
                            classes={{ root: classes.tableCellRoot }}
                            style={{
                              verticalAlign: 'middle',
                              height: '200px',
                              width: !isSmallScreen ? '25%' : '4%',
                            }}
                          >
                            {edit ? (
                              <ComponentField
                                deleteComponent={() => handleDeleteComponent(component)}
                                practicePlan={practicePlan}
                                setPracticePlan={setPracticePlan}
                                setUpdatedComponents={setUpdatedComponents}
                                component={component}
                                isFieldHidden={isSmallScreen}
                              />
                            ) : (
                              <ConditionalRender renderValue={!isSmallScreen}>
                                {component.notes ? (
                                  <div className={classes.breakWord}>{component.notes}</div>
                                ) : (
                                  '-'
                                )}
                              </ConditionalRender>
                            )}
                          </TableCell>
                        </ConditionalRender>
                      </TableRow>
                    )
                  }}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
    </Table>
  )
}

PracticePlanComponentsTable.propTypes = {
  practicePlan: practicePlanType,
  components: PropTypes.arrayOf(practicePlanComponentType),
  edit: PropTypes.bool,
  updatePracticePlan: mutationType,
  openDrillShowPageModal: PropTypes.func,
}

export default PracticePlanComponentsTable
