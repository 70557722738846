const drillsListModalStyles = {
  divFlex: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  spaceVertical: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  title: {
    fontSize: '24px',
    marginRight: '10px',
  },
}

export default drillsListModalStyles
