import COLORS from '../../utils/constants/colors'

const drillBoxFooterStyles = {
  rootWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  textButton: {
    fontSize: 13,
  },
  rightIconsWrapper: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  greySmall: {
    color: COLORS.lightGrey,
    fontSize: '12px',
    marginRight: '5px',
  },
  divFlex: {
    display: 'flex',
    gap: '5px',
    flexWrap: 'wrap',
  },
  editIcon: {
    '&&': {
      fill: COLORS.orange,
    },
  },
  addPersonalButton: {
    cursor: 'pointer',
  },
  addPersonalButtonClicked: {
    animation: '$cloudIconAnimation 400ms linear',
    '&&': {
      cursor: 'default',
    },
  },
  '@keyframes cloudIconAnimation': {
    '0%': {
      transform: 'translateX(0px)',
    },
    '35%': {
      transform: 'translateX(1px)',
    },
    '70%': {
      transform: 'translateX(-1px)',
    },
    '100%': {
      transform: 'translateX(0px)',
    },
  },
}

export default drillBoxFooterStyles
