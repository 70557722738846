import React from 'react'
import { useRedirect, useTranslate } from 'react-admin'
import { useMutation, useQuery } from 'react-query'
import { format } from 'date-fns'
import MyTable from '../../../components/DynamicTable/MyTable/MyTable'
import TypographyField from '../../../components/DynamicTable/Fields/TypographyField/TypographyField'
import ChipTableField from '../../../components/DynamicTable/Fields/ChipTableField/ChipTableField'
import QueryKeys from '../../../utils/constants/queryKeys'
import getPayments from '../../../Apis/subscriptions/getPayments'
import useUrlSearchParams from '../../../hooks/useUrlSearchParams'
import LoaderDots from '../../../components/loaders/LoaderDots/LoaderDots'
import COLORS from '../../../utils/constants/colors'
import ContainerPadding from '../../../components/containerPadding/containerPadding'
import changeCard from '../../../Apis/subscriptions/changeCard'

const PaymentsTab = () => {
  const { org: orgId } = useUrlSearchParams(['org']) || {}
  const externalRedirect = useRedirect()
  const translate = useTranslate()

  const { data: paymentTransactions, isLoading } = useQuery([QueryKeys.GET_PAYMENTS], () =>
    getPayments({ orgId }),
  )

  const changeCardMutation = useMutation(() => changeCard({ orgId }), {
    onSuccess: (res) => {
      const { vivaPaymentOrderCode } = res?.data || {}
      externalRedirect(`${process.env.REACT_APP_VIVA_URL}${vivaPaymentOrderCode}`)
    },
  })

  const getsPaymentsToShow = (payments) => {
    return (
      payments
        .filter((pay) => !!pay.totalAmount && !!pay.periodStartDateTime && !!pay.periodEndDateTime)
        .map((pay) => {
          return {
            ...pay,
            id: pay?.id || '-',
            totalAmount: `${pay.totalAmount}€`,
            period: `${format(new Date(pay.periodStartDateTime), 'dd/MM/yy')}-${format(
              new Date(pay.periodEndDateTime),
              'dd/MM/yy',
            )}`,
          }
        }) || []
    )
  }

  if (isLoading) {
    return (
      <ContainerPadding paddingValue='45%'>
        <LoaderDots
          style={{
            fill: COLORS.orange,
            width: 70,
            height: 70,
          }}
        />
      </ContainerPadding>
    )
  }

  if (paymentTransactions && Object.keys(paymentTransactions).length) {
    return (
      <MyTable
        rows={getsPaymentsToShow(paymentTransactions)}
        hasHeader
        boldHeader
        allowMultipleChecked
        tableCellStyles={{ width: '2rem' }}
      >
        <TypographyField title='ID' key='id' colorChoice='black' columnAlignment='left' />
        <TypographyField title='Period' key='period' colorChoice='black' columnAlignment='left' />
        <TypographyField
          title='Total'
          key='totalAmount'
          colorChoice='black'
          columnAlignment='left'
        />
        <ChipTableField
          title='Status'
          key='status'
          columnAlignment='left'
          actions={{ changeCard: () => changeCardMutation.mutate() }}
        />
      </MyTable>
    )
  } else {
    return (
      <div style={{ marginLeft: '35%' }}>
        <p>{translate('ra.text.noTransactions')}</p>
      </div>
    )
  }
}

PaymentsTab.propTypes = {}

export default PaymentsTab
