import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-admin'
import { makeStyles, MenuItem, Tooltip } from '@material-ui/core'
import SIDEBAR_OPTION from '../../utils/constants/sidebarOptions'
import { useMyLayout } from '../../context/useMyLayout'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import GoBackButton from '../GoBackButton/GoBackButton'
import useMenuItemLinks from '../../hooks/useMenuItemLinks'
import useHistoryPush from '../../hooks/useHistoryPush'
import menuItemLinksStyles from './menuItemLinks.styles'
import createMenuItemLinkStyle from '../../utils/helpers/createMenuItemLinkStyle'
import useQueryParams from '../../hooks/useQueryParams'
import sidebarOptionWithAuth from '../../utils/helpers/sidebarOptionWithAuth'
import localStorageHelper from '../../utils/helpers/localStorageHelper'
import localStorageIds from '../../utils/constants/localStorageIds'
import orgIsInSubscriptionState from '../../utils/helpers/orgIsInSubscriptionState'
import SUBSCRIPTION_STATES from '../../utils/constants/subscriptionStates'
import userHasOnlySpecificScopes from '../../utils/helpers/userHasOnlySpecificScopes'
import { USER_SCOPES } from '../../utils/constants/userScopes'

const MenuItemLinks = ({ myOrgs, showCreateOrgTeamModal }) => {
  const [visible, setVisible] = useState(false)
  const { menuChoice, teams, error } = useMyLayout()
  const openSidebar = useSelector((state) => state.admin.ui.sidebarOpen)
  const translate = useTranslate()
  const { redirect } = useHistoryPush()
  const { team, org, profileOption } = useQueryParams()
  const { selectedSidebarOption, isProfileItems, isTeamSettings } = useMenuItemLinks(menuChoice)
  const sidebarOptions =
    menuChoice === 'TEAM_ITEMS' || menuChoice === 'MENU_ITEMS' || !menuChoice
      ? myOrgs && myOrgs.length > 0
        ? sidebarOptionWithAuth(SIDEBAR_OPTION[selectedSidebarOption])
        : sidebarOptionWithAuth(SIDEBAR_OPTION.TEAM_ITEMS)
      : sidebarOptionWithAuth(SIDEBAR_OPTION[selectedSidebarOption])
  const { setLocalStorageValue } = localStorageHelper(localStorageIds.SELECTED_TEAM)
  const useStyles = makeStyles(menuItemLinksStyles)
  const classes = useStyles()
  const cannotReturnToCalendar =
    !orgIsInSubscriptionState([SUBSCRIPTION_STATES.INACTIVE]) ||
    !userHasOnlySpecificScopes([USER_SCOPES.COACH])

  // In order to show "Back to organisation" after an amount of time.
  useEffect(() => {
    if (openSidebar) {
      setTimeout(() => {
        setVisible(true)
      }, 100)
    }
    setVisible(false)
  }, [openSidebar])

  // TODO: Add mapper
  const handleGoBackButtonClick = () => {
    if (team) {
      redirect('/teamCalendar')
    } else if (teams?.length === 1) {
      setLocalStorageValue(teams[0])
      redirect('/teamCalendar', [{ team: teams[0].id }])
    } else if (org) {
      redirect('/calendar')
    } else {
      redirect('/')
    }
  }

  return (
    <>
      <ConditionalRender
        renderValue={
          openSidebar && visible && (isProfileItems || isTeamSettings) && cannotReturnToCalendar
        }
      >
        <GoBackButton label={translate('ra.text.backToOrg')} goTo={handleGoBackButtonClick} />
      </ConditionalRender>

      {selectedSidebarOption &&
        (!isProfileItems
          ? sidebarOptions.map((item) => (
              <Tooltip
                key={item.name}
                title={
                  !error
                    ? translate('ra.text.unauthorizedTooltip')
                    : translate('ra.errorMessages.connectionError')
                }
                disableHoverListener={!item.disabled && !error}
                placement='right'
                arrow
              >
                <div>
                  <MenuItem
                    key={item.name}
                    onClick={() =>
                      myOrgs && myOrgs.length > 0
                        ? redirect(`/${item.name}`)
                        : showCreateOrgTeamModal(item.name)
                    }
                    style={createMenuItemLinkStyle(menuChoice, item.name)}
                    disabled={error || item?.disabled}
                  >
                    <div className={classes.textWrapper}>
                      {menuChoice === item.name ? item.iconSelected : item.icon}
                      {item.text}
                    </div>
                  </MenuItem>
                </div>
              </Tooltip>
            ))
          : sidebarOptions.map((item) => (
              <Tooltip
                title={
                  !error
                    ? translate('ra.text.unauthorizedTooltip')
                    : translate('ra.errorMessages.connectionError')
                }
                disableHoverListener={!item.disabled || !error}
                placement='right'
                arrow
              >
                <div>
                  <MenuItem
                    key={item.name}
                    onClick={() => redirect('/profile', [{ profileOption: item.name }])}
                    style={createMenuItemLinkStyle(profileOption, item.name)}
                    disabled={!error || item?.disabled}
                  >
                    <div className={classes.textWrapper}>
                      {profileOption === item.name ? item.iconSelected : item.icon}
                      {item.text}
                    </div>
                  </MenuItem>
                </div>
              </Tooltip>
            )))}
    </>
  )
}

export default MenuItemLinks
