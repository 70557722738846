import React, { useState } from 'react'
import { makeStyles, MenuItem, Tooltip } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MoreIcon from '@material-ui/icons/MoreVert'
import { useTranslate } from 'react-admin'
import moreIconMenuStyles from './moreIconMenu.styles'
import SIDEBAR_OPTION from '../../utils/constants/sidebarOptions'
import useHistoryPush from '../../hooks/useHistoryPush'
import sidebarOptionWithAuth from '../../utils/helpers/sidebarOptionWithAuth'

const MoreIconMenu = () => {
  const [orgMenu, setOrgMenu] = useState(null)
  const { redirect } = useHistoryPush()
  const sidebarOptions = sidebarOptionWithAuth(SIDEBAR_OPTION.orgMenuChoices)
  const translate = useTranslate()
  const useStyles = makeStyles(moreIconMenuStyles)
  const classes = useStyles()

  const handleOpenOrgMenu = (e) => setOrgMenu(e.currentTarget)
  const handleCloseOrgMenu = (item) => {
    setOrgMenu(null)
    redirect(item.name)
  }

  if (sidebarOptions?.length) {
    return (
      <>
        <IconButton
          aria-label='more'
          aria-controls='org-menu'
          aria-haspopup='true'
          onClick={handleOpenOrgMenu}
          className={classes.iconButton}
        >
          <MoreIcon />
        </IconButton>
        <Menu
          id='org-menu'
          open={Boolean(orgMenu)}
          anchorEl={orgMenu}
          onClose={() => setOrgMenu(null)}
          disableAutoFocusItem
        >
          {sidebarOptions.map((item) => (
            <Tooltip
              key={item.name}
              title={translate('ra.text.unauthorizedTooltip')}
              disableHoverListener={!item.disabled}
              placement='left'
              arrow
            >
              <div>
                <MenuItem
                  key={item.name}
                  classes={{ root: classes.orgMenuItems }}
                  onClick={() => handleCloseOrgMenu(item)}
                  disabled={item?.disabled}
                >
                  <div className={classes.orgMenuItemsBody}>
                    {item.icon}
                    {item.text}
                  </div>
                </MenuItem>
              </div>
            </Tooltip>
          ))}
        </Menu>
      </>
    )
  }

  return <div className={classes.emptyDiv} />
}

export default MoreIconMenu
