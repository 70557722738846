import React, { useRef, useState } from 'react'
import { Card, CardContent, Grid, makeStyles } from '@material-ui/core'
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import { addMinutes } from 'date-fns'
import calendarPageStyles from './calendarPage.styles'
import { useTeamCalendarContext } from '../../../context/useTeamCalendarContext'
import AddButton from '../../../components/ui/AddButton/AddButton'
import CreateEventDrawer from '../CreateEventDrawer'
import CalendarEventClickModal from '../CalendarEventClickModal/CalendarEventClickModal'
import CreateEventModal from '../CreateEventModal/CreateEventModal'
import setFakeEvent from '../../../utils/helpers/eventHelpers/setFakeEvents'
import LoaderCircle from '../../../components/loaders/LoaderCircle/LoaderCircle'
import useHistoryPush from '../../../hooks/useHistoryPush'
import IsUserRolesAuthorized from '../../../components/IsUserRolesAuthorized/IsUserRolesAuthorized'
import USER_ORG_ROLES from '../../../utils/constants/userOrgRoles'
import USER_ORG_MEMBERS_ROLES from '../../../utils/constants/userOrgMembersRoles'
import isUserOrgRolesValid from '../../../utils/helpers/isUserOrgRolesValid'
import useTour from '../../../hooks/useTour'
import calendarEventModalDimensions from '../../../utils/constants/calendarEventModalDimensions'
import useGetLocalStorage from '../../../hooks/useGetLocalStorage'

const CalendarPage = () => {
  const {
    setClickedEventId,
    setCoordinatesModal,
    translate,
    currentTeam,
    teams,
    events,
    setDates,
    setCreateEventDrawerOpen,
    setDateClick,
    setEvents,
    eventsRaw,
    selectedEventType,
    eventLoading,
    eventTypes,
  } = useTeamCalendarContext()
  const [createEventModalOpen, setCreateEventModalOpen] = useState(false)
  const [eventClickModalOpen, setEventClickModalOpen] = useState(false)
  const introTourName = 'intro-tour'
  const localStorageValue = useGetLocalStorage()
  const { [introTourName]: calendarTourValue } = localStorageValue || {}
  const { redirect, pathname } = useHistoryPush()
  const useStyles = makeStyles(calendarPageStyles)
  const classes = useStyles()
  const introRef = useRef()
  const tipRef = useRef()

  const tourToShowName = calendarTourValue ? 'hint-tour' : 'intro-tour'
  const tourToShowSteps = calendarTourValue ? 'hint' : 'calendar'
  const refName = calendarTourValue ? introRef : tipRef
  // Change this value to trigger the tip tour to show again (Always string)
  // Change the message from "createSteps" file
  const tipReshowKey = '3'

  useTour({
    elRef: refName,
    tourName: tourToShowName,
    tourSteps: tourToShowSteps,
    tourDesc: calendarTourValue ? tipReshowKey : null,
  })

  const { calendarEventModalWidth, calendarEventModalHeight } = calendarEventModalDimensions

  const onEventClick = (eventInfo) => {
    // TODO: Probably can transfer the position logic in the modal component to calculate the dimensions and position dynamically and not set them in constant and state.
    // (document.getElementById('EventModal')?.offsetWidth)
    const bodyRect = document.body.getBoundingClientRect()
    const elemRect = eventInfo.el.getBoundingClientRect()
    const middleOfElementLeft = (elemRect.left + elemRect.right) / 2
    let offsetLeft
    let offsetTop

    const SHOW_LEFT = 'showLeft'
    const SHOW_RIGHT = 'showRight'
    const SHOW_MIDDLE = 'showMiddle'

    const SHOW_CASES = {
      [SHOW_LEFT]: () => {
        offsetLeft = elemRect.left - bodyRect.left - calendarEventModalWidth
      },
      [SHOW_RIGHT]: () => {
        offsetLeft = elemRect.left - bodyRect.left
      },
      [SHOW_MIDDLE]: () => {
        offsetLeft = null
      },
    }

    if (middleOfElementLeft > calendarEventModalWidth) {
      SHOW_CASES[SHOW_LEFT]()
    } else if (bodyRect.width - middleOfElementLeft > calendarEventModalWidth) {
      SHOW_CASES[SHOW_RIGHT]()
    } else {
      SHOW_CASES[SHOW_MIDDLE]()
    }

    if (bodyRect.height - elemRect.top < calendarEventModalHeight) {
      offsetTop = elemRect.top - bodyRect.top - calendarEventModalHeight
    } else {
      offsetTop = elemRect.top - bodyRect.top
    }
    setEventClickModalOpen(true)
    setCoordinatesModal({ x: offsetLeft, y: offsetTop })
    // eslint-disable-next-line dot-notation
    setClickedEventId(eventInfo.event['_def'].publicId)
  }

  const onDateClick = (info) => {
    if (!eventTypes || eventTypes?.length === 0) return

    if (
      !isUserOrgRolesValid(
        [USER_ORG_ROLES.OWNER, USER_ORG_ROLES.ADMIN, USER_ORG_MEMBERS_ROLES.TEAM_MANAGER],
        currentTeam ? [currentTeam?.id] : teams?.map((team) => team.id),
      )
    )
      return

    const now = new Date()
    let start = new Date(info.date)
    start.setHours(now.getHours())
    start.setMinutes(now.getMinutes())
    start = addMinutes(start, 30)
    const end = addMinutes(start, 30)

    setDateClick({ start, end })
    setCreateEventDrawerOpen(true)
    setFakeEvent(setEvents, eventsRaw, selectedEventType, start, end)
    redirect(pathname, [{ breadcrumb: 'create' }])
  }

  return (
    <div ref={introRef}>
      <Grid container justifyContent='flex-end' style={{ marginTop: 10 }}>
        <IsUserRolesAuthorized
          acceptedRoles={[
            USER_ORG_ROLES.OWNER,
            USER_ORG_ROLES.ADMIN,
            USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
          ]}
          searchIds={currentTeam ? [currentTeam?.id] : teams?.map((team) => team.id)}
        >
          <AddButton
            onClick={() => setCreateEventModalOpen(true)}
            label={translate('ra.buttons.newEvent')}
            style={{ marginRight: 10 }}
            filled
          />
        </IsUserRolesAuthorized>
        <CreateEventModal
          open={createEventModalOpen}
          handleClose={() => setCreateEventModalOpen(false)}
        />
        <CreateEventDrawer />
      </Grid>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <FullCalendar
            initialView='dayGridMonth'
            plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
            datesSet={(dateInfo) => setDates(dateInfo)}
            events={events}
            eventClick={onEventClick}
            dateClick={onDateClick}
            dayMaxEvents
            weekNumberCalculation='ISO'
            customButtons={{
              customLoader: {
                text: <LoaderCircle loading={eventLoading} />,
              },
            }}
            headerToolbar={{
              start: 'prev,next,today,customLoader',
              center: 'title',
              end: 'dayGridMonth,dayGridWeek,dayGridDay,listMonth',
            }}
            buttonText={{
              today: 'TODAY',
              month: 'MONTH',
              week: 'WEEK',
              day: 'DAY',
              list: 'LIST',
            }}
            slotLabelFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            }}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            }}
          />
          <CalendarEventClickModal
            open={eventClickModalOpen}
            handleClose={() => setEventClickModalOpen(false)}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default CalendarPage
