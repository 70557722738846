import COLORS from '../../utils/constants/colors'

const drillBoxCreatorNameStyles = {
  divFlex: {
    display: 'flex',
    gap: 5,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  creatorName: {
    color: COLORS.lightGrey,
    fontSize: '12px',
    marginRight: '5px',
  },
}

export default drillBoxCreatorNameStyles
