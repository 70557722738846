import React from 'react'
import { makeStyles, Select } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import ExpandIcon from '../../assets/icons/expandIcon'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import tacticsSelectFieldStyles from './tacticsSelectField.styles'
import tacticClickedType from '../../types/tactics/tacticClickedType'
import MyCancelCircleIcon from '../../assets/icons/MyCancelCircleIcon'

const TacticsSelectField = (props) => {
  const { tactics, onOpen, onCloseField } = props
  const translate = useTranslate()
  const useStyles = makeStyles(tacticsSelectFieldStyles)
  const classes = useStyles()

  const renderValue = (selected) => (
    <div className={classes.renderValueContainer}>
      {translate('ra.formFields.tactics')}
      <span className={classes.renderValueLabel}>{selected.length}</span>
    </div>
  )

  return (
    <ConditionalRender renderValue={tactics.length}>
      <div className={classes.wrapper}>
        <Select
          classes={{
            select: classes.select,
            filled: classes.selectInput,
            icon: classes.selectIcon,
          }}
          IconComponent={ExpandIcon}
          disableUnderline
          value={tactics}
          onOpen={onOpen}
          renderValue={renderValue}
          MenuProps={{
            open: false,
          }}
        />
        <MyCancelCircleIcon className={classes.cancelIcon} onClick={onCloseField} />
      </div>
    </ConditionalRender>
  )
}

TacticsSelectField.propTypes = {
  tactics: PropTypes.arrayOf(tacticClickedType),
  onOpen: PropTypes.func,
  onCloseField: PropTypes.func,
}

export default TacticsSelectField
