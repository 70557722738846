const teamSettingsFormStyles = {
  formWrapper: {
    paddingLeft: '35px',
    paddingRight: '35px',
  },
  deleteButton: {
    marginTop: '25px',
    marginRight: '20px',
  },
}

export default teamSettingsFormStyles
