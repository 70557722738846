const arrayWithComma = (values) => {
  if (!values) return null

  return values.map((value, index) => {
    if (index !== values.length - 1) {
      return `${value}, `
    }
    return `${value}`
  })
}

export default arrayWithComma
