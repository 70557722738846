import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Cropper from 'react-easy-crop'
import ConditionalRender from '../../../ConditionalRender/ConditionalRender'
import ImageCropAddPreview from '../ImageCropAddPreview/ImageCropAddPreview'
import MyCloseCircleIcon from '../../../../assets/icons/MyCloseCircleIcon'
import { getCroppedImg } from '../../../../utils/helpers/cropImageHelpers'
import imageCropStyles from './imageCrop.styles'
import MyCropRotateIcon from '../../../../assets/icons/MyCropRotateIcon'
import MyZoomInIcon from '../../../../assets/icons/MyZoomInIcon'
import MyZoomOutIcon from '../../../../assets/icons/MyZoomOutIcon'
import CROPPER_SHAPES from '../../../../utils/constants/cropperShapes'

const ImageCrop = (props) => {
  const {
    defaultImageSrc,
    setCroppedImage,
    formValues,
    aspect,
    width,
    height,
    cropShape,
    minZoom,
    maxZoom,
    zoomStep,
    addImageLabel,
    dimensions,
  } = props
  const [imageSrc, setImageSrc] = useState(defaultImageSrc)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [cropSize, setCropSize] = useState(null)
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const useStyles = makeStyles(imageCropStyles(width, height, zoom, minZoom, maxZoom))
  const classes = useStyles()

  useEffect(() => {
    if (formValues?.logo === null) setImageSrc(undefined)
  }, [formValues?.logo])

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      const croppedImg = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
        {
          horizontal: false,
          vertical: false,
        },
        cropShape === CROPPER_SHAPES.ROUND,
      )

      if (!!dimensions) {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        canvas.width = dimensions.width
        canvas.height = dimensions.height

        const img = new Image()
        img.src = croppedImg

        const resizedCroppedImg = await new Promise((resolve) => {
          img.onload = () => {
            ctx.drawImage(img, 0, 0, dimensions.width, dimensions.height)
            resolve(canvas.toDataURL())
          }

          img.onerror = () => {
            resolve(null)
          }
        })

        if (resizedCroppedImg) {
          return setCroppedImage(resizedCroppedImg)
        }
      }

      return setCroppedImage(croppedImg)
    },
    [imageSrc, setCroppedImage, rotation, cropShape],
  )

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      e.preventDefault()
      const reader = new FileReader()
      const file = e.target.files[0]
      const image = new Image()

      reader.onloadend = () => {
        image.src = reader.result
        setImageSrc(reader.result)
      }

      // This is only for very narrow images which considered edge case for the library.
      // Works only when width > height in aspect ratio.
      image.onload = () => {
        // Narrow Height/Width Image
        if (
          cropShape === CROPPER_SHAPES.RECT &&
          ((image?.height ?? 0) * 1.5 < image?.width || (image?.width ?? 0) * 1.5 < image?.height)
        ) {
          return setCropSize({
            width,
            height: width / aspect,
          })
        }

        return setCropSize(null)
      }

      reader.readAsDataURL(file)
    }
  }

  const handleCloseCropper = () => {
    setImageSrc('')
    setCroppedImage('')
  }

  const handleRotate = () => {
    if (rotation < 360) {
      setRotation(rotation + 90)
    } else {
      setRotation(90)
    }
  }

  const handleZoomIn = () => {
    if (zoom <= maxZoom - zoomStep) {
      setZoom(zoom + zoomStep)
    }
    if (zoom > maxZoom - zoomStep && zoom < maxZoom) {
      setZoom(maxZoom)
    }
  }
  const handleZoomOut = () => {
    if (zoom >= minZoom + zoomStep) {
      setZoom(zoom - zoomStep)
    }
    if (zoom < minZoom + zoomStep && zoom > minZoom) {
      setZoom(minZoom)
    }
  }

  return (
    <div>
      <ConditionalRender renderValue={!imageSrc}>
        <ImageCropAddPreview
          handleAdd={onFileChange}
          width={width}
          height={height}
          label={addImageLabel}
        />
      </ConditionalRender>

      <ConditionalRender renderValue={imageSrc}>
        <>
          <div className={classes.zoomBarContainer}>
            <div className={classes.zoomBarValue} />
          </div>

          <div className={classes.wrapper}>
            <div className={classes.cropWrapper}>
              <Cropper
                image={imageSrc}
                crop={crop}
                cropSize={cropSize || null}
                rotation={rotation}
                zoom={zoom}
                aspect={aspect}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                cropShape={cropShape}
                minZoom={minZoom > 0.1 ? minZoom : ImageCrop.defaultProps.minZoom}
                maxZoom={maxZoom}
                restrictPosition={minZoom >= 1}
              />
            </div>

            <div className={classes.buttonsWrapper}>
              <MyCloseCircleIcon className={classes.closeIcon} onClick={handleCloseCropper} />
              <MyCropRotateIcon className={classes.greyIcon} onClick={handleRotate} />
              <MyZoomInIcon className={classes.greyIcon} onClick={handleZoomIn} />
              <MyZoomOutIcon className={classes.greyIcon} onClick={handleZoomOut} />
            </div>
          </div>
        </>
      </ConditionalRender>
    </div>
  )
}

ImageCrop.propTypes = {
  defaultImageSrc: PropTypes.string,
  setCroppedImage: PropTypes.func.isRequired,
  aspect: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  cropShape: PropTypes.string,
  minZoom: PropTypes.number,
  maxZoom: PropTypes.number,
  zoomStep: PropTypes.number,
  addImageLabel: PropTypes.string,
}

ImageCrop.defaultProps = {
  aspect: 1,
  cropShape: CROPPER_SHAPES.ROUND,
  minZoom: 0.5,
  maxZoom: 3,
  zoomStep: 0.1,
}

export default ImageCrop
