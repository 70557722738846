import React from 'react'

const DefaultOrgIcon = (props) => (
  <svg {...props} viewBox='0 0 48 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M24 3.41501e-07C16.2483 0.000987762 8.58748 2.13917 1.04695 6.25711C0.730383 6.42958 0.466019 6.68473 0.281761 6.99564C0.0975028 7.30655 0.000185542 7.66167 7.97209e-05 8.02354C7.97209e-05 21.0873 -0.05684 30.3344 2.87116 38.3591C5.79918 46.3838 11.7439 52.8139 22.9258 59.9646C23.2468 60.17 23.6195 60.2791 24 60.2791C24.3805 60.2791 24.7532 60.17 25.0742 59.9646C36.2561 52.8139 42.2008 46.3838 45.1288 38.3591C48.0569 30.3344 47.9999 21.0873 47.9999 8.02354C47.9998 7.66167 47.9025 7.30655 47.7182 6.99564C47.534 6.68473 47.2696 6.42958 46.953 6.25711C39.4128 2.13932 31.7524 -0.000987079 24 3.41501e-07Z'
      fill='#D7D7D7'
    />
    <g clipPath='url(#clip0_1258_3351)'>
      <path
        d='M15.1864 34.5654C15.94 33.8259 18.4301 31.1345 18.4301 31.1345L12.701 29.4167C12.1683 29.251 11.6107 29.1797 11.0534 29.2061C10.0838 29.1888 9.13514 29.4897 8.35269 30.0626C7.92254 30.4034 7.57554 30.8375 7.33794 31.3322C7.10034 31.8269 6.97837 32.3691 6.98127 32.9178C6.98131 33.7077 7.21292 34.4802 7.64745 35.1398C8.08199 35.7994 8.70038 36.3172 9.4261 36.629C10.1518 36.9408 10.953 37.033 11.7306 36.8941C12.5082 36.7553 13.228 36.3916 13.801 35.8479L13.8384 35.8105C14.2316 35.4454 14.8682 34.8603 15.1864 34.5654Z'
        fill='url(#paint0_linear_1258_3351)'
      />
      <path
        d='M27.1128 18.8057C28.9921 18.8057 30.5156 17.2822 30.5156 15.4028C30.5156 13.5235 28.9921 12 27.1128 12C25.2334 12 23.7099 13.5235 23.7099 15.4028C23.7099 17.2822 25.2334 18.8057 27.1128 18.8057Z'
        fill='url(#paint1_linear_1258_3351)'
      />
      <path
        d='M18.711 21.6375C20.5903 21.6375 22.1138 20.114 22.1138 18.2346C22.1138 16.3553 20.5903 14.8318 18.711 14.8318C16.8317 14.8318 15.3082 16.3553 15.3082 18.2346C15.3082 20.114 16.8317 21.6375 18.711 21.6375Z'
        fill='url(#paint2_linear_1258_3351)'
      />
      <path
        d='M26.6494 35.3284L31.8917 27.2215C31.8917 27.2215 27.3889 27.9517 25.746 27.3993C24.1031 26.847 23.7661 26.9313 23.7661 26.9313L25.0814 28.5461C25.733 29.3625 26.3393 30.2141 26.8974 31.0971C27.2757 31.8276 27.4381 32.6507 27.3655 33.4702C27.2798 34.1382 27.0341 34.7756 26.6494 35.3284V35.3284Z'
        fill='url(#paint3_linear_1258_3351)'
      />
      <path
        d='M39.4416 29.7724C37.9859 26.7815 34.7609 22.3208 32.7904 21.0196C30.8198 19.7184 28.18 19.1754 25.3014 21.3987C22.4227 23.6221 20.1526 27.2402 20.1526 27.2402C20.1526 27.2402 21.4024 27.0857 22.5398 26.9594C23.4879 26.8409 24.4506 26.95 25.3481 27.2776C26.1569 27.5775 26.9087 28.0127 27.5715 28.5648C28.2101 29.1145 28.7961 29.7225 29.322 30.3809C30.3825 31.6573 31.3731 32.9901 32.2896 34.3735C32.6771 34.994 33.1392 35.5646 33.6657 36.0726L33.7733 36.1522C34.3708 36.6123 35.0852 36.8959 35.8356 36.9706C36.5861 37.0453 37.3424 36.9082 38.0188 36.5748C38.6953 36.2415 39.2648 35.7252 39.6627 35.0846C40.0606 34.444 40.271 33.7047 40.2701 32.9506C40.2325 31.843 39.9495 30.7575 39.4416 29.7724V29.7724Z'
        fill='url(#paint4_linear_1258_3351)'
      />
      <path
        d='M26.3124 30.1328C25.9473 29.6039 22.6474 25.2462 20.6956 23.945C18.7438 22.6438 16.0851 22.1008 13.2065 24.3242C10.3279 26.5475 8.05782 30.1656 8.05782 30.1656C8.78478 29.704 9.59357 29.3863 10.4403 29.2295C11.3884 29.1114 12.351 29.2205 13.2487 29.5478C14.0564 29.8498 14.8079 30.2848 15.472 30.8349C16.102 31.3528 16.7021 31.906 17.2694 32.4919L20.2416 35.4594C20.408 35.6633 20.5947 35.8499 20.7986 36.0164L20.9062 36.096C21.5037 36.5562 22.2181 36.8397 22.9685 36.9144C23.719 36.9891 24.4753 36.852 25.1517 36.5187C25.8282 36.1853 26.3977 35.6691 26.7956 35.0285C27.1935 34.3879 27.4039 33.6486 27.403 32.8944C27.4264 31.8366 26.9302 31.0456 26.3124 30.1328Z'
        fill='url(#paint5_linear_1258_3351)'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_1258_3351'
        x1='5.05752'
        y1='36.9619'
        x2='16.7498'
        y2='29.2716'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='0.01' stopColor='white' stopOpacity='0.98' />
        <stop offset='0.21' stopColor='white' stopOpacity='0.69' />
        <stop offset='0.39' stopColor='white' stopOpacity='0.44' />
        <stop offset='0.56' stopColor='white' stopOpacity='0.25' />
        <stop offset='0.71' stopColor='white' stopOpacity='0.11' />
        <stop offset='0.83' stopColor='white' stopOpacity='0.03' />
        <stop offset='0.91' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_1258_3351'
        x1='24.2154'
        y1='16.676'
        x2='30.4548'
        y2='13.9378'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0' />
        <stop offset='0.68' stopColor='white' stopOpacity='0.68' />
        <stop offset='0.99' stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_1258_3351'
        x1='15.9915'
        y1='19.5967'
        x2='22.0811'
        y2='16.5496'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0' />
        <stop offset='0.68' stopColor='white' stopOpacity='0.68' />
        <stop offset='0.99' stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_1258_3351'
        x1='24.2108'
        y1='32.1643'
        x2='30.436'
        y2='25.6722'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.09' stopColor='white' />
        <stop offset='0.19' stopColor='white' stopOpacity='0.74' />
        <stop offset='0.33' stopColor='white' stopOpacity='0.42' />
        <stop offset='0.44' stopColor='white' stopOpacity='0.2' />
        <stop offset='0.52' stopColor='white' stopOpacity='0.05' />
        <stop offset='0.56' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_1258_3351'
        x1='20.1667'
        y1='28.4384'
        x2='40.2888'
        y2='28.4384'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0' />
        <stop offset='0.91' stopColor='white' stopOpacity='0.92' />
        <stop offset='0.99' stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint5_linear_1258_3351'
        x1='8.07187'
        y1='29.8848'
        x2='27.4264'
        y2='29.8848'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0' />
        <stop offset='0.91' stopColor='white' stopOpacity='0.92' />
        <stop offset='0.99' stopColor='white' />
      </linearGradient>
      <clipPath id='clip0_1258_3351'>
        <rect width='33.2935' height='24.99' fill='white' transform='translate(7 12)' />
      </clipPath>
    </defs>
  </svg>
)

export default DefaultOrgIcon
