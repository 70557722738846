import BarChart from '@material-ui/icons/BarChart'
import CastIcon from '@material-ui/icons/Cast'
import SportsHandballIcon from '@material-ui/icons/SportsHandball'
import ShareIcon from '@material-ui/icons/Share'
import React from 'react'

const subscriptionIconMapper = {
  Coach: <SportsHandballIcon />,

  Stats: <BarChart />,

  Livestream: <CastIcon />,

  Social: <ShareIcon />,
}

export default subscriptionIconMapper
