import { useEffect, useState } from 'react'
import isObjectEmptyHelper from '../utils/helpers/isObjectEmptyHelper'
import usePrevious from './usePrevious'

const useGetLocalStorage = () => {
  const [localStorageValue, setLocalStorageValue] = useState(localStorage)
  const prevLocalStorageValue = usePrevious(JSON.stringify(localStorageValue))

  useEffect(() => {
    const onStorage = () => {
      if (prevLocalStorageValue && prevLocalStorageValue !== JSON.stringify(localStorage)) {
        setLocalStorageValue({ ...localStorage })
      }
    }

    window.addEventListener('storage', onStorage)

    return () => {
      window.removeEventListener('storage', onStorage)
    }
  }, [prevLocalStorageValue])

  const parseJSONValues = (obj) => {
    if (isObjectEmptyHelper(obj)) return null
    return Object.entries(obj).reduce((acc, [key, value]) => {
      try {
        acc[key] = JSON.parse(value)
      } catch {
        acc[key] = value
      }
      return acc
    }, {})
  }

  return parseJSONValues(localStorageValue)
}

export default useGetLocalStorage
