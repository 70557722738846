const simpleTextFieldStyles = {
  root: {
    borderRadius: 6,
    height: 'inherit',
  },
  fieldInput: {
    height: '0.7rem',
    minHeight: '0.7rem',
    fontSize: '0.95rem',
    padding: '17px 12px 14px',
  },
  multiline: {
    padding: 5,
  },
}

export default simpleTextFieldStyles
