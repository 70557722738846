import React, { useRef, useEffect } from 'react'
import { TopToolbar, useListContext, useTranslate } from 'react-admin'
import {
  Card,
  CardContent,
  makeStyles,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import SingleBasketballPlayer from '../../../../assets/icons/singleBasketballPlayer'
import AddButton from '../../../../components/ui/AddButton/AddButton'
import playerListEmptyStyles from './playerListEmpty.styles'
import useHistoryPush from '../../../../hooks/useHistoryPush'
import useTour from '../../../../hooks/useTour'
import TOUR_IDS from '../../../../utils/constants/introIds'
import USER_ORG_ROLES from '../../../../utils/constants/userOrgRoles'
import USER_ORG_MEMBERS_ROLES from '../../../../utils/constants/userOrgMembersRoles'
import IsUserRolesAuthorized from '../../../../components/IsUserRolesAuthorized/IsUserRolesAuthorized'

const PlayerListEmpty = ({ refresh }) => {
  const { data } = useListContext()
  const translate = useTranslate()
  const { redirect } = useHistoryPush()
  const useStyles = makeStyles(playerListEmptyStyles)
  const classes = useStyles()
  const elRef = useRef()

  useEffect(() => {
    if (data && Object.keys(data).length > 0 && Object.getPrototypeOf(data) === Object.prototype) {
      refresh()
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  useTour({
    elRef,
    tourName: 'roster-tour',
    tourSteps: 'rosterManagementEmpty',
  })

  return (
    <>
      <TopToolbar classes={{ root: classes.topToolbarRoot }}>
        <IsUserRolesAuthorized
          acceptedRoles={[
            USER_ORG_ROLES.OWNER,
            USER_ORG_ROLES.ADMIN,
            USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
          ]}
        >
          <AddButton
            id={TOUR_IDS.rosterManagement.create}
            label={translate('ra.buttons.addPlayer')}
            onClick={() => redirect('/players/create')}
            filled
          />
        </IsUserRolesAuthorized>
      </TopToolbar>

      <Card className={classes.card}>
        <CardContent>
          <Table ref={elRef} id={TOUR_IDS.rosterManagement.playerList}>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.headerTableCellName}>Name</TableCell>
                <TableCell>Teams</TableCell>
                <TableCell>Height</TableCell>
                <TableCell>Age</TableCell>
                <TableCell>Positions</TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <span className={classes.containerImage}>
            <SingleBasketballPlayer className={classes.basketballPlayerIcon} />
            <span className={classes.title}>{translate('ra.text.managePlayers')}</span>
          </span>
        </CardContent>
      </Card>
    </>
  )
}

export default PlayerListEmpty
