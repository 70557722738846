import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useMutation } from 'react-query'
import { Form, Formik } from 'formik'
import { Grid } from '@material-ui/core'
import * as yup from 'yup'
import ButtonWithLoader from '../../components/ui/ButtonWithLoader/ButtonWithLoader'
import MyTextFieldPassword from '../../components/form/fields/MyTextFieldPassword'
import ContainerPadding from '../../components/containerPadding/containerPadding'
import updateUserData from '../../Apis/user/updateUserData'
import QueryKeys from '../../utils/constants/queryKeys'
import MySnackbar from '../../components/MySnackbar/MySnackbar'
import useFormValidation from '../../hooks/useFormValidation'

const ProfileSettingsSecurityForm = () => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { newPassword } = useFormValidation()
  const translate = useTranslate()

  const updateUserPassword = useMutation(
    QueryKeys.UPDATE_USER_PERSONAL_SETTINGS,
    (data) => updateUserData(data),
    {
      onSuccess: (data, { resetForm }) => {
        resetForm()
        setIsSnackbarOpen(true)
      },
      onError: ({ response }) => {
        setErrorMessage(response?.data?.Message)
        setIsSnackbarOpen(true)
      },
    },
  )

  const onSubmit = (data, { resetForm }) => {
    updateUserPassword.mutate({ ...data, resetForm })
  }

  const onCloseSnackbar = () => setIsSnackbarOpen(false)

  return (
    <ContainerPadding padding='4% 1% 4% 1%'>
      <Formik
        initialValues={{
          password: '',
          newPassword: '',
        }}
        validationSchema={yup.object().shape({
          newPassword,
        })}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <MyTextFieldPassword
                  label={translate('ra.formFields.oldPassword')}
                  name='password'
                />
              </Grid>
              <Grid item xs={6}>
                <MyTextFieldPassword
                  label={translate('ra.formFields.newPassword')}
                  name='newPassword'
                />
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-end'>
              <ButtonWithLoader
                label={translate('ra.buttons.update')}
                style={{ marginTop: '25px' }}
                disabled={
                  !(
                    formik.isValid &&
                    formik.dirty &&
                    formik.values.password &&
                    formik.values.newPassword
                  )
                }
                loading={updateUserPassword.isLoading}
              />
            </Grid>
          </Form>
        )}
      </Formik>
      <MySnackbar
        isOpen={isSnackbarOpen}
        onClose={onCloseSnackbar}
        isSuccess={updateUserPassword.isSuccess}
        successMessage={translate('ra.successMessages.changePasswordSuccess')}
        errorMessage={`${translate('ra.errorMessages.changePasswordError')} ${errorMessage}`}
      />
    </ContainerPadding>
  )
}

export default ProfileSettingsSecurityForm
