import React from 'react'
import PropTypes from 'prop-types'
import { useRecordContext } from 'react-admin'
import { Chip, makeStyles } from '@material-ui/core'
import positionsFieldStyles from './positionsField.styles'

const PositionsField = ({ source }) => {
  const record = useRecordContext()
  const useStyles = makeStyles(positionsFieldStyles)
  const classes = useStyles()

  if (!record || !record[source]) return <span>-</span>
  const { positions } = record[source]
  if (!positions || (positions && positions.length === 0)) return <span>-</span>

  return record[source].positions.map((position) => (
    <Chip label={position.name} key={position.id} classes={{ root: classes.chip }} />
  ))
}

PositionsField.propTypes = {
  source: PropTypes.string,
}

export default PositionsField
