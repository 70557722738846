import { Box, Typography } from '@material-ui/core'
import React from 'react'

const TabPanel = (props) => {
  const { children, value, index, style, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
      {...other}
    >
      {value === index && (
        <Box p={3} style={style}>
          <Typography style={style}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export default TabPanel
