import { useEffect } from 'react'
import { setSidebarVisibility } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import useResize from '../../hooks/useResize'
import TABLET_SCREEN_WIDTH from '../../utils/constants/tabletScreenWidth'

const CloseSidebarWhenSmallScreen = () => {
  const isSidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen)
  const dispatch = useDispatch()
  const { width: screenWidth } = useResize(document?.body)
  const isSmallScreen = screenWidth !== 0 && screenWidth < TABLET_SCREEN_WIDTH

  // Keep the sidebar closed when small screen
  useEffect(() => {
    if (isSmallScreen && isSidebarOpen) {
      dispatch(setSidebarVisibility(false))
    }
  }, [dispatch, isSidebarOpen, isSmallScreen])

  return null
}

export default CloseSidebarWhenSmallScreen
