import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import COLORS from '../../utils/constants/colors'

const MyEmailResend = (props) => {
  const { width, height } = props

  const useStyles = makeStyles({
    emailResendIcon: {
      cursor: 'pointer',
      padding: 2,
    },
  })
  const classes = useStyles()

  return (
    <svg
      {...props}
      className={classes.emailResendIcon}
      width={width || '19'}
      height={height || '19'}
      viewBox='0 0 22 19'
      fill={COLORS.orange}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 0C0.89 0 0 0.89 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H12.5C12.1699 15.2078 12 14.3582 12 13.5C12 11.7761 12.6848 10.1228 13.9038 8.90381C15.1228 7.68482 16.7761 7 18.5 7C19.0053 7.00149 19.5087 7.0619 20 7.18V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0H2ZM2 2L10 7L18 2V4L10 9L2 4V2ZM18 8L15.75 10.25L18 12.5V11C18.663 11 19.2989 11.2634 19.7678 11.7322C20.2366 12.2011 20.5 12.837 20.5 13.5C20.5 13.9 20.41 14.28 20.24 14.62L21.33 15.71C21.75 15.08 22 14.32 22 13.5C22 11.29 20.21 9.5 18 9.5V8ZM14.67 11.29C14.25 11.92 14 12.68 14 13.5C14 15.71 15.79 17.5 18 17.5V19L20.25 16.75L18 14.5V16C17.337 16 16.7011 15.7366 16.2322 15.2678C15.7634 14.7989 15.5 14.163 15.5 13.5C15.5 13.1 15.59 12.72 15.76 12.38L14.67 11.29Z'
        fill='#E0726E'
      />
    </svg>
  )
}

MyEmailResend.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default MyEmailResend
