const calendarPageStyles = {
  card: {
    height: 'fit-content',
    marginTop: 10,
  },
  cardContent: {
    '&&': {
      paddingBottom: 33,
    },
  },
  drawerPaper: {
    width: '31%',
    minWidth: 540,
    overflow: 'hidden',
  },
  drawerContainer: { height: '100%', overflow: 'auto' },
}

export default calendarPageStyles
