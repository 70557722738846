import React, { useEffect, useRef, useState } from 'react'
import { useIsFetching, useMutation, useQueryClient } from 'react-query'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import practicePlanComponentsAccordionStyles from './practicePlanComponentsAccordion.styles'
import PracticePlanComponentsTable from '../PracticePlanComponentsTable/PracticePlanComponentsTable'
import useHistoryPush from '../../hooks/useHistoryPush'
import TextButton from '../ui/TextButton/TextButton'
import deletePracticePlan from '../../Apis/practice/deletePracticePlan'
import QueryKeys from '../../utils/constants/queryKeys'
import sessionStorageIds from '../../utils/constants/sessionStorageIds'
import MySportsHandballIcon from '../../assets/icons/MySportsHandballIcon'
import addPracticePlan from '../../Apis/practice/addPracticePlan'
import practicePlanType from '../../types/practicePlanType'
import FadeOut from '../ui/FadeOut/FadeOut'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import MyDownArrowIcon from '../../assets/icons/MyDownArrowIcon'
import MyUpArrowIcon from '../../assets/icons/MyUpArrowIcon'
import IsUserRolesAuthorized from '../IsUserRolesAuthorized/IsUserRolesAuthorized'
import USER_ORG_ROLES from '../../utils/constants/userOrgRoles'
import USER_ORG_MEMBERS_ROLES from '../../utils/constants/userOrgMembersRoles'
import usePrevious from '../../hooks/usePrevious'

const PracticePlanComponentsAccordion = (props) => {
  const { practicePlan, isModal, selectedEventId, onclose, setSelectedPracticePlan } = props
  const practicePlanData = practicePlan || {}
  const { id: practicePlanId, description, practicePlanComponents } = practicePlanData
  const [isFadedOut, setIsFadedOut] = useState(false)
  const [practicePlanDeletedId, setPracticePlanDeletedId] = useState(null)
  const isPracticePlansFetching = useIsFetching([QueryKeys.GET_PRACTICE_PLANS])
  const isPracticePlansFetchingPrevious = usePrevious(isPracticePlansFetching)
  const accordionRef = useRef(null)
  const translate = useTranslate()
  const { redirect } = useHistoryPush()
  const queryClient = useQueryClient()
  const open = JSON.parse(sessionStorage.getItem(sessionStorageIds.PRACTICE_PLAN_OPEN_ACCORDION))
  const isExpanded = open && Array.isArray(open) && open.find((id) => id === practicePlanId)
  const [expanded, setExpanded] = useState(!!isExpanded)
  const useStyles = makeStyles(practicePlanComponentsAccordionStyles(expanded))
  const classes = useStyles()

  // Reset practicePlanDeletedId after deleting
  useEffect(() => {
    if (isPracticePlansFetchingPrevious > 0 && isPracticePlansFetching === 0) {
      setPracticePlanDeletedId(null)
    }
  }, [isPracticePlansFetchingPrevious, isPracticePlansFetching])

  useEffect(() => {
    if (isExpanded && !expanded) {
      setExpanded(true)
    }
  }, [practicePlanData]) // eslint-disable-line react-hooks/exhaustive-deps

  const deleteAPracticePlan = useMutation(() => deletePracticePlan(practicePlanId), {
    onSuccess: () => {
      setExpanded(false)
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICES)
    },
  })

  const addPracticePlanToEvent = useMutation((data) => addPracticePlan(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_EVENT)
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICES)
    },
  })

  const setExpandedToSessionStorage = () => {
    const openArray = JSON.parse(
      sessionStorage.getItem(sessionStorageIds.PRACTICE_PLAN_OPEN_ACCORDION),
    )
    if (expanded) {
      window.sessionStorage.setItem(
        sessionStorageIds.PRACTICE_PLAN_OPEN_ACCORDION,
        JSON.stringify(openArray.filter((id) => id !== selectedEventId)),
      )
    } else {
      window.sessionStorage.setItem(
        sessionStorageIds.PRACTICE_PLAN_OPEN_ACCORDION,
        JSON.stringify([...(openArray || []), selectedEventId]),
      )
    }
  }

  const createNewPracticePlanItem = ({ practicePlan, selectedEventId, practicePlanComponents }) => {
    return {
      values: {
        id: practicePlan?.id,
        description: practicePlan?.description,
        eventId: selectedEventId,
        components: practicePlanComponents?.map((component) => {
          return {
            notes: component.notes,
            duration: component.duration,
            order: component.order,
            trainingModuleId: component?.trainingModule?.id,
          }
        }),
      },
    }
  }

  const handleAccordionClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const newPracticePlan = createNewPracticePlanItem({
      practicePlan,
      selectedEventId,
      practicePlanComponents,
    })

    if (isModal) {
      if (selectedEventId) {
        addPracticePlanToEvent.mutate(newPracticePlan)
      } else {
        setSelectedPracticePlan(newPracticePlan)
      }
      onclose()
    } else {
      redirect(`/practice-plan/${newPracticePlan?.values?.id}/show`, [{ goBack: 'practice' }])
    }
  }

  const handleExpandButtonClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setExpanded(!expanded)
    setExpandedToSessionStorage()
  }

  const deletePracticePlanDebounce = debounce(() => {
    deleteAPracticePlan.mutate(practicePlanId)
    setPracticePlanDeletedId(practicePlanId)
  }, 1200)

  const handleDeleteButton = (e) => {
    e.stopPropagation()
    setIsFadedOut(true)

    deletePracticePlanDebounce()
  }

  return (
    <ConditionalRender renderValue={!practicePlanDeletedId}>
      <FadeOut isFadedOut={isFadedOut && !deleteAPracticePlan.isLoading}>
        <div style={{ position: 'relative' }} ref={accordionRef}>
          <Accordion
            classes={{ root: classes.accordionRoot, expanded: classes.accordionRootExpanded }}
            onChange={handleAccordionClick}
            expanded={expanded}
          >
            <AccordionSummary
              classes={{
                root: classes.accordionSummaryRoot,
                content: classes.accordionSummaryContent,
                expandIcon: classes.accordionSummaryExpandIcon,
                expanded: classes.accordionSummaryExpanded,
              }}
            >
              <div className={classes.accordionSummaryBody}>
                <div className={classes.practicePlanLabel}>
                  {!practicePlan?.isLoading ? description : null}
                </div>

                <div className={classes.divFlex}>
                  {isModal ? (
                    <>
                      <MySportsHandballIcon className={classes.handballIcon} />
                      <span className={classes.componentsLength}>
                        {practicePlan && practicePlanComponents ? practicePlanComponents.length : 0}
                      </span>
                    </>
                  ) : (
                    <IsUserRolesAuthorized
                      acceptedRoles={[
                        USER_ORG_ROLES.OWNER,
                        USER_ORG_ROLES.ADMIN,
                        USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
                      ]}
                    >
                      <TextButton
                        onClick={handleDeleteButton}
                        label={translate('ra.buttons.removeSmall')}
                        className={classes.button}
                      />
                    </IsUserRolesAuthorized>
                  )}
                  <ConditionalRender renderValue={practicePlan && isModal}>
                    <IconButton
                      className={classes.arrowIcon}
                      onClick={handleExpandButtonClick}
                      color='primary'
                      aria-label='arrow icon'
                      component='span'
                    >
                      {expanded ? <MyUpArrowIcon /> : <MyDownArrowIcon />}
                    </IconButton>
                  </ConditionalRender>
                </div>
              </div>
            </AccordionSummary>
            <ConditionalRender renderValue={practicePlan && isModal}>
              <AccordionDetails classes={{ root: classes.accordionDetails }}>
                <PracticePlanComponentsTable practicePlan={practicePlan} />
              </AccordionDetails>
            </ConditionalRender>
          </Accordion>
        </div>
      </FadeOut>
    </ConditionalRender>
  )
}

PracticePlanComponentsAccordion.propTypes = {
  practicePlan: practicePlanType,
  isModal: PropTypes.bool,
  selectedEventId: PropTypes.string,
  onclose: PropTypes.func,
  setSelectedPracticePlan: PropTypes.func,
}

export default PracticePlanComponentsAccordion
