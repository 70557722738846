const eventFormCourtLogosStyles = (imageWidth, centerLogoValue) => {
  const editBoxCenterDimension = (imageWidth * 12.5) / 100

  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 25,
      marginTop: 25,
    },
    divider: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 15,
    },
    competitionFieldsWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 15,
      '& img': {
        objectPosition: 'center',
      },
    },
    competitionLogo: {
      position: 'relative',
      width: 55,
      backgroundColor: '#bdbdbd',
      borderRadius: 10,
      padding: 5,
      '& svg': {
        width: '50%',
        height: '50%',
      },
    },
    competitionLogoFilled: {
      position: 'relative',
      width: 60,
      height: 35,
    },
    courtWrapper: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    courtImg: {
      width: '100%',
      height: '100%',
    },
    editBoxTopLeft: {
      position: 'absolute',
      top: '24.5%',
      left: '29%',
      width: '18%',
      height: '18%',
    },
    editBoxTopRight: {
      position: 'absolute',
      top: '24%',
      right: '31%',
      width: '18%',
      height: '18%',
    },
    editBoxBottomLeft: {
      position: 'absolute',
      bottom: '16.5%',
      left: '29%',
      width: '18%',
      height: '18%',
    },
    editBoxBottomRight: {
      position: 'absolute',
      bottom: '16.5%',
      right: '31%',
      width: '18%',
      height: '18%',
    },
    editBoxCenter: {
      position: 'absolute',
      top: centerLogoValue ? '44%' : '44.5%',
      left: centerLogoValue ? '42.9%' : '40.5%',
      width: centerLogoValue ? editBoxCenterDimension : '18%',
      height: centerLogoValue ? editBoxCenterDimension : '18%',
      '& img': {
        borderRadius: '100%',
      },
      '& svg': {
        bottom: '-14%',
      },
    },
  }
}

export default eventFormCourtLogosStyles
