const statsInfo = [
  {
    label: 'PIR',
    description:
      "Performance Index Rating, a measure of a player's overall statistical performance",
  },
  { label: 'GP', description: 'Games Played' },
  { label: 'GS', description: 'Games Started' },
  { label: 'MIN', description: 'Minutes Played' },
  { label: 'PTS', description: 'Points' },
  { label: '2P', description: 'Two-point Field Goal' },
  { label: '3P', description: 'Three-point Field Goal' },
  { label: 'FT', description: 'Free Throw' },
  { label: 'OR', description: 'Offensive Rebounds' },
  { label: 'DR', description: 'Defensive Rebounds' },
  { label: 'TR', description: 'Total Rebounds' },
  { label: 'AST', description: 'Assists' },
  { label: 'STL', description: 'Steals' },
  { label: 'TO', description: 'Turnovers' },
  { label: 'BLK', description: 'Blocks' },
  { label: 'BLKA', description: 'Blocks Against' },
  { label: 'FC', description: 'Fouls Committed' },
  { label: 'FD', description: 'Fouls Drawn' },
]

export default statsInfo
