const courtEditorDialogStyles = {
  dialog: {
    maxWidth: 'unset',
    width: '100%',
    borderRadius: '0px!important',
    // overflowY: 'hidden',
  },
}

export default courtEditorDialogStyles
