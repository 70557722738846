import COLORS from '../colors'

const AUTOCOMPLETE_ARRAY_INPUT_STYLES = {
  // InputLabelProps
  error: {
    '&&&&&': {
      color: COLORS.red,
      '&::after': {
        content: '"*"',
        position: 'absolute',
        right: -8,
        top: 0,
      },
    },
  },
  focused: {
    '&&&&': {
      color: COLORS.black,
      transform: 'translate(12px, -6px)',
      fontSize: '12px',
    },
  },
  filled: {
    '&&&': {
      transform: 'translate(12px, -6px)',
      fontSize: '12px',
      color: 'rgba(0, 0, 0, 0.40)',
    },
  },

  // InputProps
  root: {
    borderRadius: '6px',
  },
  input: {
    height: '1rem',
    minHeight: '0.7rem',
    padding: '17px 12px 14px',
    fontSize: '0.95rem',
    flex: 1,
    width: '60%',
  },

  // helperText
  helperTextRoot: {
    display: 'none',
  },

  // ra
  chipContainerFilled: {
    margin: 0,
    padding: 0,
    marginTop: 5,
  },
  chip: {
    height: 25,
    marginTop: 0,
    backgroundColor: COLORS.orange,
    color: 'white',
    '& > .MuiChip-deleteIcon': {
      height: 18,
      fill: COLORS.bisque,
    },
  },
  autocompleteInputRoot: {
    marginTop: 0,
    marginBottom: 0,
  },

  // arrowDown
  container: {
    position: 'relative',
  },
  arrowDownWrapper: {
    position: 'absolute',
    right: '14px',
    top: '27%',
  },
  arrowDown: {
    color: COLORS.lightGrey,
    fontSize: '17px',
  },
}

export default AUTOCOMPLETE_ARRAY_INPUT_STYLES
