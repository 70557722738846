import React, { useState } from 'react'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import PropTypes from 'prop-types'
import DialogWithCloseIcon from '../DialogWithCloseIcon/DialogWithCloseIcon'
import ORGANISATION_TYPES from '../../utils/constants/OrganisationTypes'
import OrgWizardContents from '../OrgWizardContents/OrgWizardContents'
import { useTranslate } from 'react-admin'
import addOrgModalStyles from './addOrgModal.styles'

const AddOrgModal = (props) => {
  const { isOpen, onClose, orgSuccessRedirect } = props
  const [orgTypeSelected, setOrgTypeSelected] = useState(ORGANISATION_TYPES.ORG_CLUB)
  const theme = useTheme()
  const translate = useTranslate()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1100))
  const useStyles = makeStyles(addOrgModalStyles)
  const classes = useStyles()

  const steps = orgTypeSelected
    ? [
        'Choose Organisation Type',
        orgTypeSelected === ORGANISATION_TYPES.ORG_CLUB ? 'Add Club Details' : 'Add League Details',
        'Available Addons',
        // 'Invite Users',
      ]
    : null

  return (
    <DialogWithCloseIcon
      open={isOpen}
      onClose={() => {
        onClose()
        setOrgTypeSelected(ORGANISATION_TYPES.ORG_CLUB)
      }}
      dialogTitle={!orgTypeSelected ? translate('ra.text.createOrgAndTeam') : ''}
      dialogWidth={isSmallScreen ? '60%' : '70%'}
      maxDialogWidth={isSmallScreen ? '600px' : '700px'}
      wizardSteps={steps}
      classes={classes}
    >
      <OrgWizardContents
        setOrgTypeSelected={setOrgTypeSelected}
        orgSuccessRedirect={orgSuccessRedirect}
        orgTypeSelected={orgTypeSelected}
        onClose={() => {
          onClose()
          setOrgTypeSelected(ORGANISATION_TYPES.ORG_CLUB)
        }}
      />
    </DialogWithCloseIcon>
  )
}

AddOrgModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  orgSuccessRedirect: PropTypes.string,
}

AddOrgModal.defaultProps = {
  isOpen: false,
}

export default AddOrgModal
