const myErrorStyles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5,
  },
  button: {
    marginTop: 15,
  },
}

export default myErrorStyles
