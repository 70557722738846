import COLORS from '../../../../utils/constants/colors'

const multiStringWithCommaFieldStyles = {
  cellContainer: { display: 'flex', alignItems: 'center' },
  arrayLength: {
    padding: '2px 8px 2px 8px',
    backgroundColor: COLORS.borderGrey,
    borderRadius: '9px',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '12px',
    marginRight: '35px',
    color: COLORS.black,
  },
  teamTooltip: { marginLeft: '10px' },
  multiStringTypo: { display: 'flex', alignItems: 'center' },
}

export default multiStringWithCommaFieldStyles
