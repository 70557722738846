import * as React from "react";
import { makeStyles, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useTranslate } from "react-admin";
import { practicePlanComponentsTableHeadStyles } from "./practicePlanComponentsTableHead.styles";

const PracticePlanComponentsTableHead = () => {
  const translate = useTranslate();
  const useStyles = makeStyles(practicePlanComponentsTableHeadStyles);
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow classes={{root: classes.tableRowRoot}}>
        <TableCell classes={{root: classes.tableCellRoot}}>{translate("ra.text.minutes")}</TableCell>
        <TableCell classes={{root: classes.tableCellRoot}}>{translate("ra.text.activity")}</TableCell>
        <TableCell classes={{root: classes.tableCellRoot}}>{translate("ra.text.notes")}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default PracticePlanComponentsTableHead;