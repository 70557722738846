import React from 'react'
import { makeStyles } from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import COLORS from '../../utils/constants/colors'

const MyPlayIcon = (props) => {
  const useStyles = makeStyles({
    addIcon: {
      width: 24,
      height: 24,
      fill: COLORS.white,
      cursor: 'pointer',
    },
  })
  const classes = useStyles()

  return <PlayArrowIcon {...props} classes={{ root: classes.addIcon }} />
}

export default MyPlayIcon
