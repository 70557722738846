import React from 'react'
import PropTypes from 'prop-types'
import { DialogActions, makeStyles } from '@material-ui/core'
import teamStatisticsPageStyles from './teamStatisticsPage.styles'
import ButtonWithLoader from '../../components/ui/ButtonWithLoader/ButtonWithLoader'
import DialogWithCloseIcon from '../../components/DialogWithCloseIcon/DialogWithCloseIcon'

const StatsInfoDialog = ({ open, onClose, statsInfo }) => {
  const useStyles = makeStyles(teamStatisticsPageStyles)
  const classes = useStyles()

  return (
    <DialogWithCloseIcon
      open={open}
      onClose={onClose}
      dialogTitle={'Stats Info'}
      maxDialogWidth='60%'
    >
      <div className={classes.dialogContentText}>
        <ul>
          {statsInfo.map((stat, index) => (
            <div className={classes.strong} key={index}>
              <strong>{stat.label}:</strong> {stat.description}
            </div>
          ))}
        </ul>
      </div>
      <DialogActions>
        <ButtonWithLoader label='Close' onClick={onClose} color='primary' />
      </DialogActions>
    </DialogWithCloseIcon>
  )
}

StatsInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  statsInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
}
export default StatsInfoDialog
