import React from 'react'
import PropTypes from 'prop-types'
import { useRecordContext } from 'react-admin'
import parseHeight from '../../../../utils/helpers/parseHeight'

const HeightField = ({ recordSource }) => {
  const record = useRecordContext()
  if (!record || !record[recordSource]?.height) return <span>-</span>

  return <span>{parseHeight(record[recordSource]?.height)}</span>
}

HeightField.propTypes = {
  recordScource: PropTypes.string,
}

export default HeightField
