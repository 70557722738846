import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import ClockIcon from '../../assets/icons/clockIcon'
import MySportsHandballIcon from '../../assets/icons/MySportsHandballIcon'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import MyLocationOnIcon from '../../assets/icons/myLocationOnIcon'
import practiceBoxIconsStyles from './practiceBoxIcons.styles'

const PracticeBoxIcons = (props) => {
  const { date, location, place } = props
  const useStyles = makeStyles(practiceBoxIconsStyles)
  const classes = useStyles()
  const time = new Date(date).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  })

  return (
    <div className={classes.wrapperFlex}>
      <div className={classes.clockFlex}>
        <ClockIcon />
        {time}
      </div>

      <ConditionalRender renderValue={place}>
        <div className={classes.divFlex}>
          <MySportsHandballIcon />
          {place}
        </div>
      </ConditionalRender>

      <ConditionalRender renderValue={location}>
        <div className={classes.divFlex}>
          <MyLocationOnIcon />
          {location?.name}
        </div>
      </ConditionalRender>
    </div>
  )
}

PracticeBoxIcons.propTypes = {
  date: PropTypes.string,
  location: PropTypes.string,
  place: PropTypes.string,
}

export default PracticeBoxIcons
