import { shape, string, arrayOf } from 'prop-types'
import teamSmallType from './teamSmallType'
import eventVariantSmallType from './events/eventVariantSmallType'
import enumIdType from './enumIdType'

const practiceType = shape({
  endDateTime: string,
  id: string,
  specificEventDetails: shape({
    court: enumIdType,
    notes: string,
    place: string,
  }),
  startDateTime: string,
  teams: arrayOf(teamSmallType),
  title: string,
  type: eventVariantSmallType,
})

export default practiceType
