import { useLocation } from 'react-router'

const useQueryParams = () => {
  let urlSearchParams
  try {
    urlSearchParams = new URLSearchParams(useLocation().search)
  } catch {
    //
  }
  if (urlSearchParams?.entries().next().value !== undefined) {
    return Object.fromEntries(urlSearchParams.entries())
  } else {
    // Sometimes urlSearchParams fail, that is why we do this
    const url = window.location.href
    const splitedUrl = url.split('?')
    if (splitedUrl.length > 1) {
      const splitByParam = splitedUrl[1].split('&')
      let params = {}
      splitByParam.map((param) => {
        const key = param.split('=')[0]
        const value = param.split('=')[1]
        params = { ...params, [key]: value }
      })
      return params
    } else {
      return {}
    }
  }
}

export default useQueryParams
