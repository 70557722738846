import axiosInstance from '../axiosInterceptor/axiosInstance'

const getPayments = async (data) => {
  const { orgId } = data
  const url = `/billing/orders?clubId=${orgId}`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getPayments
