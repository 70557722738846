import localStorageIds from '../constants/localStorageIds'
import localStorageHelper from './localStorageHelper'

const filterTeamsByRole = (data, acceptedRoles) => {
  const { localStorageValue: orgRoles } = localStorageHelper(localStorageIds.USER_ORG_ROLES)

  return data?.filter(
    (item) =>
      acceptedRoles.includes(orgRoles[item.id]) || acceptedRoles.includes(orgRoles[item.org.id]),
  )
}

export default filterTeamsByRole
