import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import MyPersonIcon from '../../assets/icons/MyPersonIcon'
import COLORS from '../../utils/constants/colors'
import drillBoxCreatorNameStyles from './drillBoxCreatorName.styles'

const DrillBoxCreatorName = (props) => {
  const { label } = props
  const useStyles = makeStyles(drillBoxCreatorNameStyles)
  const classes = useStyles()

  return (
    <div className={classes.divFlex}>
      <MyPersonIcon fillColor={COLORS.lightGrey} />
      <span className={classes.creatorName}>{label}</span>
    </div>
  )
}

DrillBoxCreatorName.propTypes = {
  label: PropTypes.string,
}

export default DrillBoxCreatorName
