import React, { useState } from 'react'
import { Button, makeStyles, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import { useDelete, useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import deleteButtonWithConfirmationStyles from './deleteButtonWithConfirmation.styles'
import useHistoryPush from '../../../hooks/useHistoryPush'

const DeleteButtonWithConfirmation = (props) => {
  const { title, subtitle, resource, basePath } = props
  const record = useRecordContext()
  const [openDialog, setOpenDialog] = useState(false)
  const notify = useNotify()
  const { redirect } = useHistoryPush()
  const translate = useTranslate()
  const refresh = useRefresh()
  const [deleteOne] = useDelete(resource, record.id, record, {
    onSuccess: () => {
      notify(`${translate('ra.notification.deleteSuccess')}`, 'success', undefined, false, 5000)
      setOpenDialog(false)
      redirect(basePath)
      refresh()
    },
    onFailure: () => {
      notify(`${translate('ra.notification.deleteError')}`, 'error', undefined, false, 5000)
    },
  })
  const useStyles = makeStyles(deleteButtonWithConfirmationStyles)
  const classes = useStyles()

  const handleClick = () => setOpenDialog(true)
  const handleCloseClick = () => setOpenDialog(false)
  const handleDelete = () => {
    deleteOne()
  }

  return (
    <>
      <Button variant='text' className={classes.deleteFormButton} onClick={handleClick}>
        {translate('ra.buttons.delete')}
      </Button>
      <Dialog fullWidth open={openDialog} onClose={handleCloseClick}>
        <DialogTitle>
          <Typography variant='h5' component='div'>
            {title}
          </Typography>
          <Typography variant='subtitle2' component='div' className={classes.subtitle}>
            {subtitle}
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button variant='text' className={classes.cancelButton} onClick={handleCloseClick}>
            {translate('ra.buttons.cancel')}
          </Button>
          <Button variant='contained' className={classes.deleteModalButton} onClick={handleDelete}>
            {translate('ra.buttons.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

DeleteButtonWithConfirmation.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  resource: PropTypes.string,
  basePath: PropTypes.string,
}

export default DeleteButtonWithConfirmation
