import * as React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import teamSettingsPageStyles from './teamSettingsPage.styles'
import useTeamsSettings from '../../../hooks/useTeamsSettings'
import AddTeamModal from '../../../components/ui/AddTeamModal/AddTeamModal'
import TeamSettingsForm from '../TeamSettingsForm/TeamSettingsForm'
import AddButton from '../../../components/ui/AddButton/AddButton'
import CardWrapperMoreIcon from '../../../components/layout/CardWrapperMoreIcon/CardWrapperMoreIcon'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import LoaderDots from '../../../components/loaders/LoaderDots/LoaderDots'
import MySnackbar from '../../../components/MySnackbar/MySnackbar'
import IsUserRolesAuthorized from '../../../components/IsUserRolesAuthorized/IsUserRolesAuthorized'
import USER_ORG_ROLES from '../../../utils/constants/userOrgRoles'

const TeamSettingsPage = () => {
  const [selectedTeamId, setSelectedTeamId] = useState(null)
  const [teamState, setTeamState] = useState(null)
  const translate = useTranslate()
  const {
    teams,
    gender,
    ageRestriction,
    updateTeam,
    deleteTeam,
    team,
    addTeam,
    showAddTeamModal,
    setShowAddTeamModal,
    showDeleteModal,
    setShowDeleteModal,
    isUpdateSnackbarOpen,
    setIsUpdateSnackbarOpen,
  } = useTeamsSettings(selectedTeamId)
  const useStyles = makeStyles(teamSettingsPageStyles)
  const classes = useStyles()

  useEffect(() => {
    setTeamState(team)
  }, [team])

  useEffect(() => {
    if (teams && teams.length > 0) {
      setSelectedTeamId(teams[0].id)
    }
  }, [teams])

  const onCloseSnackbar = () => setIsUpdateSnackbarOpen(false)

  const handleTeamChipClick = (teamItem) => {
    if (teamItem?.id !== selectedTeamId) {
      setSelectedTeamId(teamItem.id)
      setTeamState(null)
    }
  }

  return (
    <CardWrapperMoreIcon>
      <Card classes={{ root: classes.card }}>
        <CardHeader
          title={
            <Typography className={classes.cardTitle} variant='h5'>
              {translate('ra.text.teamSettings')}
            </Typography>
          }
          subheader={
            <Typography className={classes.cardSubtitle}>
              {translate('ra.text.manageOrgTeams')}
            </Typography>
          }
        />
        <CardContent>
          <Divider variant='middle' className={classes.divider} />

          <Grid container justifyContent='flex-end' className={classes.addTeamWrapper}>
            <IsUserRolesAuthorized acceptedRoles={[USER_ORG_ROLES.OWNER]}>
              <Grid item>
                <AddButton
                  label={translate('ra.buttons.addTeam')}
                  onClick={() => setShowAddTeamModal(true)}
                  filled
                />
              </Grid>
              <AddTeamModal
                showAddTeamModal={showAddTeamModal}
                handleCloseModal={() => setShowAddTeamModal(false)}
                addTeam={addTeam}
              />
            </IsUserRolesAuthorized>
          </Grid>
          <Container className={classes.chipsContainer}>
            <Grid container justifyContent='center'>
              {teams &&
                teams.length > 0 &&
                teams.map((teamItem) => (
                  <Chip
                    id={teamItem.id}
                    key={teamItem.id}
                    onClick={() => handleTeamChipClick(teamItem)}
                    label={teamItem.name}
                    variant={teamItem.id === selectedTeamId ? 'default' : 'outlined'}
                    color='primary'
                    className={classes.chip}
                  />
                ))}
            </Grid>
          </Container>
          <ConditionalRender renderValue={teams}>
            {teamState && team && team === teamState && gender && ageRestriction ? (
              <TeamSettingsForm
                teamState={teamState}
                ageRestriction={ageRestriction}
                gender={gender}
                team={team}
                showDeleteModal={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
                selectedTeamId={selectedTeamId}
                deleteTeam={deleteTeam}
                updateTeam={updateTeam}
              />
            ) : (
              <Grid container justifyContent='center'>
                <LoaderDots className={teamSettingsPageStyles.loader} />
              </Grid>
            )}
          </ConditionalRender>
          <ConditionalRender renderValue={!teams}>
            <div style={{ textAlign: 'center' }}>{translate('ra.text.noCurrentTeams')}</div>
          </ConditionalRender>
        </CardContent>
        <MySnackbar
          isOpen={isUpdateSnackbarOpen}
          onClose={onCloseSnackbar}
          isSuccess={updateTeam.isSuccess}
          successMessage={translate('ra.successMessages.updateTeamSuccess')}
          errorMessage={`${translate('ra.errorMessages.updateTeamError')}`}
        />
      </Card>
    </CardWrapperMoreIcon>
  )
}

export default TeamSettingsPage
