const myAdjustableFieldsStyles = {
  fields: {
    marginTop: '20px',
    display: 'grid',
    gridGap: '20px 20px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  firstRowItem: {
    width: '48%',
    maxWidth: '48%',
  },
  secondRowItem: {
    width: '42%',
  },
  addMoreButton: { marginTop: '20px', marginBottom: '20px' },
}

export default myAdjustableFieldsStyles
