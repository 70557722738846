const DefaultPlayerIcon = ({ width, height, squareVariant }) => (
  <svg
    style={{ lineHeight: 0.5 }}
    width={width || '40'}
    height={height || '40'}
    viewBox='0 0 50 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    {squareVariant ? (
      <rect x='0' y='0' width='50' height='50' fill='#E5E5E5' rx='9' ry='9' />
    ) : (
      <circle cx='24.5' cy='24.5' r='24.5' fill='#E5E5E5' />
    )}
    <g clipPath='url(#clip0_1110_12101)'>
      <path
        d='M21.8108 34.7983C22.806 33.8217 26.0942 30.2677 26.0942 30.2677L18.5288 27.9993C17.8253 27.7804 17.089 27.6863 16.3531 27.7211C15.0726 27.6983 13.8199 28.0956 12.7867 28.8522C12.2186 29.3022 11.7604 29.8755 11.4467 30.5287C11.1329 31.1819 10.9718 31.898 10.9757 32.6226C10.9757 33.6656 11.2816 34.6858 11.8554 35.5568C12.4292 36.4278 13.2458 37.1115 14.2041 37.5232C15.1625 37.935 16.2205 38.0568 17.2473 37.8734C18.2741 37.6901 19.2246 37.2098 19.9813 36.4919L20.0307 36.4424C20.5499 35.9603 21.3905 35.1877 21.8108 34.7983Z'
        fill='url(#paint0_linear_1110_12101)'
      />
      <path
        d='M26.4642 17.7265C28.946 17.7265 30.9578 15.7147 30.9578 13.233C30.9578 10.7513 28.946 8.73944 26.4642 8.73944C23.9825 8.73944 21.9707 10.7513 21.9707 13.233C21.9707 15.7147 23.9825 17.7265 26.4642 17.7265Z'
        fill='white'
      />
      <path
        d='M36.5021 28.9449C36.02 28.2465 31.6625 22.492 29.085 20.7737C26.5076 19.0554 22.9968 18.3384 19.1955 21.2744C15.3942 24.2103 12.3965 28.9882 12.3965 28.9882C13.3565 28.3787 14.4245 27.959 15.5426 27.752C16.7946 27.596 18.0658 27.7401 19.2512 28.1723C20.3178 28.5711 21.3101 29.1456 22.1871 29.8721C23.0191 30.5559 23.8115 31.2864 24.5606 32.0601L28.4855 35.9788C28.7053 36.2481 28.9517 36.4945 29.221 36.7144L29.3632 36.8194C30.1521 37.4271 31.0956 37.8015 32.0865 37.9002C33.0775 37.9988 34.0762 37.8178 34.9695 37.3776C35.8628 36.9374 36.6148 36.2556 37.1402 35.4097C37.6657 34.5638 37.9435 33.5875 37.9423 32.5917C37.9732 31.1948 37.318 30.1502 36.5021 28.9449Z'
        fill='white'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_1110_12101'
        x1='8.43529'
        y1='37.9629'
        x2='23.8752'
        y2='27.8076'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='0.01' stopColor='white' stopOpacity='0.98' />
        <stop offset='0.21' stopColor='white' stopOpacity='0.69' />
        <stop offset='0.39' stopColor='white' stopOpacity='0.44' />
        <stop offset='0.56' stopColor='white' stopOpacity='0.25' />
        <stop offset='0.71' stopColor='white' stopOpacity='0.11' />
        <stop offset='0.83' stopColor='white' stopOpacity='0.03' />
        <stop offset='0.91' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <clipPath id='clip0_1110_12101'>
        <rect width='27' height='30' fill='white' transform='translate(11 8)' />
      </clipPath>
    </defs>
  </svg>
)

export default DefaultPlayerIcon
