import axiosInstance from '../axiosInterceptor/axiosInstance'

const getOrgPendingInvitations = async (props) => {
  const { orgId } = props
  const url = `/Invitations/${orgId}/pending-invitations`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getOrgPendingInvitations
