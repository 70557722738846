const addUrlQueryParams = (history, paramName, paramValue) => {
  const { pathname, search } = history.location;
  const urlParams = new URLSearchParams(search);

  if (paramValue) {
    urlParams.set(paramName, paramValue);
  } else {
    urlParams.delete(paramName);
  }

  history.replace({
    pathname,
    search: urlParams.toString()
  });
};

export default addUrlQueryParams;