const stringWithComma = (value) => {
  if (!value) return
  if (value.length <= 1) return value

  const parts = value.split('')

  //Check if there is already a comma and if so remove it.
  const commaIndex = parts.indexOf('.')
  if (commaIndex > -1) parts.splice(commaIndex, 1)

  parts.splice(1, 0, '.')

  if (parts.length >= 5) {
    parts.splice(-1)
  }
  return Number(parts.join(''))
}

export default stringWithComma
