import React from 'react'
import { makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import COLORS from '../../utils/constants/colors'

const MyCloseIcon = (props) => {
  const useStyles = makeStyles({
    closeIcon: {
      fill: COLORS.orange,
      cursor: 'pointer',
    },
  })
  const classes = useStyles()

  return <CloseIcon {...props} classes={{ root: classes.closeIcon }} fontSize='medium' />
}

export default MyCloseIcon
