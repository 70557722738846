import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import drillBoxStyles from '../DrillBox/drillBox.styles'

const Description = ({ description }) => {
  const [maxWords, setMaxWords] = useState(35)

  const useStyles = makeStyles(drillBoxStyles)
  const classes = useStyles()
  const descriptionRef = useRef(null)

  const updateMaxWords = () => {
    const containerWidth = descriptionRef.current.clientWidth
    const containerHeight = descriptionRef.current.clientHeight
    const lineHeight = Math.floor(4 + containerWidth / containerHeight)
    const newMaxWords = Math.floor(containerWidth / lineHeight)
    setMaxWords(newMaxWords)
  }

  useEffect(() => {
    // Update maxWords when the window is resized
    updateMaxWords()
    window.addEventListener('resize', updateMaxWords)

    return () => {
      // Cleanup event listener on component unmount
      window.removeEventListener('resize', updateMaxWords)
    }
  }, [])

  // Split the description by spaces
  const words = description.split(' ').filter((word) => word.trim() !== '')
  const shouldShorten = words.length > maxWords

  const displayContent = shouldShorten ? (
    <>
      {words.slice(0, maxWords).join(' ')}

      {description.trim().length === 0 ? null : (
        <span className={`${classes.fadeAway}`}> {words[words.slice(0, maxWords).length]} ...</span>
      )}
    </>
  ) : (
    description
  )

  return (
    <div className={`${classes.description}`} ref={descriptionRef}>
      {displayContent}
    </div>
  )
}

Description.propTypes = {
  description: PropTypes.string.isRequired,
}

export default Description
