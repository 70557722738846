import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Drawer, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import createPracticeDrawerStyles from './createPracticeDrawer.styles'
import CloseDrawer from '../CloseDrawer/CloseDrawer'
import SelectEventType from '../SelectEventType/SelectEventType'
import createEvent from '../../Apis/event/createEvent'
import updateEvent from '../../Apis/event/updateEvent'
import QueryKeys from '../../utils/constants/queryKeys'
import useHistoryPush from '../../hooks/useHistoryPush'
import getCourts from '../../Apis/courts/getCourts'
import useQueryParams from '../../hooks/useQueryParams'
import teamType from '../../types/teamType'
import practiceType from '../../types/practiceType'

const PRACTICE_EVENT = {
  code: 'pract',
  entityName: 'Practice',
  id: '00000001-1fdd-49c5-99c2-000000000001',
  color: '#9351DC',
  displayName: 'Practice',
  name: 'Practice',
}

const CreatePracticeDrawer = (props) => {
  const { open, onClose, team, clickedPractice, clickedPracticeId } = props
  const [formDirty, setFormDirty] = useState(false)
  const queryClient = useQueryClient()
  const { redirect } = useHistoryPush()
  const queryParams = useQueryParams()
  const useStyles = makeStyles(createPracticeDrawerStyles)
  const classes = useStyles()
  const formattedPractice = clickedPractice
    ? { ...clickedPractice, ...clickedPractice?.specificEventDetails }
    : undefined
  delete formattedPractice?.specificEventDetails

  const createAnEvent = useMutation((data) => createEvent(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_EVENTS)
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICES)
      onClose()
      redirect('/practice', [], ['breadcrumb'])
    },
  })

  const updateAnEvent = useMutation((data) => updateEvent(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_EVENTS)
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICES)
      onClose()
      redirect('/practice', [], ['breadcrumb'])
    },
  })

  const courts = useQuery([QueryKeys.GET_COURTS, queryParams?.org], () =>
    getCourts(queryParams?.org),
  )

  const handleClose = () => {
    onClose()
    redirect('/practice', [], ['breadcrumb'])
  }

  return (
    <Drawer open={open} anchor='right' classes={{ paper: classes.drawerPaper }} disableEnforceFocus>
      <CloseDrawer onClick={handleClose} />
      {(clickedPracticeId && clickedPractice) || !clickedPracticeId ? (
        <SelectEventType
          setFormDirty={setFormDirty}
          formDirty={formDirty}
          selectedEventType={PRACTICE_EVENT}
          createEvent={createAnEvent}
          updateEvent={updateAnEvent}
          disabled
          currentTeam={team}
          teams={[team]}
          event={formattedPractice}
          clickedEventId={clickedPractice?.id}
          courts={courts?.data?.courts}
        />
      ) : (
        <h3>Loading...</h3>
      )}
    </Drawer>
  )
}

CreatePracticeDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  team: teamType,
  clickedPractice: practiceType,
  clickedPracticeId: PropTypes.string,
}

export default CreatePracticeDrawer
