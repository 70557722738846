import axiosInstance from '../axiosInterceptor/axiosInstance'

const getEvents = async (data) => {
  const { teams, org, startDateTime, endDateTime } = data
  if (!teams && !org) return null

  const teamQueryString = `teamId=${teams}`
  const orgQueryString = `clubId=${org}`

  const queryString = `?startDateTime=${startDateTime}&endDateTime=${endDateTime}&${
    org ? orgQueryString : teamQueryString
  }`

  const url = `/events${queryString}`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getEvents
