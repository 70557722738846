import { shape, string } from 'prop-types'

const countryType = shape({
  id: string,
  code: string,
  name: string,
  numericCode: string,
})

export default countryType
