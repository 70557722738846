import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton, InputAdornment } from '@material-ui/core'
import MyVisibilityOffIcon from '../../../assets/icons/myVisibilityOffIcon'
import MyVisibilityIcon from '../../../assets/icons/MyVisibilityIcon'
import MyTextField from './MyTextField'

const MyTextFieldPassword = (props) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = (e) => {
    e.preventDefault()
    setShowPassword(!showPassword)
  }

  return (
    <MyTextField
      {...props}
      type={!showPassword ? 'password' : ''}
      autoComplete='new-password'
      endAdornment={
        <InputAdornment position='end'>
          <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword}>
            {!showPassword ? <MyVisibilityOffIcon /> : <MyVisibilityIcon />}
          </IconButton>
        </InputAdornment>
      }
    />
  )
}

MyTextFieldPassword.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
}

export default MyTextFieldPassword
