import React from 'react'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import { useRouteMatch } from 'react-router'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import MyEditIcon from '../../assets/icons/MyEditIcon'
import MyDeleteIcon from '../../assets/icons/MyDeleteIcon'
import PreviousArrowIcon from '../../assets/icons/previousArrowIcon'
import ContainerPadding from '../../components/containerPadding/containerPadding'
import QueryKeys from '../../utils/constants/queryKeys'
import getPracticePlan from '../../Apis/practice/getPracticePlan'
import PdfDownloadButton from '../../components/PdfDownloadButton/PdfDownloadButton'
import PDF_VARIANTS from '../../utils/constants/pdfVariants'
import useHistoryPush from '../../hooks/useHistoryPush'
import PracticePlanComponentsTable from '../../components/PracticePlanComponentsTable/PracticePlanComponentsTable'
import practicePlanShowStyles from './practicePlanShow.styles'
import deletePracticePlan from '../../Apis/practice/deletePracticePlan'
import useQueryParams from '../../hooks/useQueryParams'
import LoaderDots from '../../components/loaders/LoaderDots/LoaderDots'
import COLORS from '../../utils/constants/colors'
import IsUserRolesAuthorized from '../../components/IsUserRolesAuthorized/IsUserRolesAuthorized'
import USER_ORG_ROLES from '../../utils/constants/userOrgRoles'
import USER_ORG_MEMBERS_ROLES from '../../utils/constants/userOrgMembersRoles'

const PracticePlanShow = () => {
  const { redirect, goBack } = useHistoryPush()
  const queryParams = useQueryParams()
  const queryClient = useQueryClient()
  const practicePlanId = useRouteMatch({ path: '/practice-plan/:id/show' })?.params?.id
  const useStyles = makeStyles(practicePlanShowStyles)
  const classes = useStyles()

  const { data, isFetching } = useQuery(
    [QueryKeys.GET_PRACTICE_PLAN],
    () =>
      getPracticePlan({
        practicePlanId,
      }),
    {
      enabled: Boolean(practicePlanId),
    },
  )

  const deleteAPracticePlan = useMutation(
    (practicePlanIdValue) => deletePracticePlan(practicePlanIdValue),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.GET_PRACTICES)
        redirect('practice')
      },
    },
  )

  const handleGoBackButton = () => {
    if (queryParams?.goBack) {
      return redirect('practice', [], ['goBack'])
    }

    return goBack()
  }
  const handleEditButton = () =>
    redirect(`/practice-plan/${practicePlanId}`, [{ goBack: 'practice' }])

  const handleRemoveButton = () => deleteAPracticePlan.mutate(practicePlanId)

  return (
    <div>
      <ContainerPadding paddingValue='1.5%' vertical className={classes.appbar}>
        <div className={classes.appBarPracticePlanName}>
          <PreviousArrowIcon onClick={handleGoBackButton} style={{ fill: COLORS.orange }} />
          <div>{data?.description}</div>
        </div>

        <div className={classes.appBarButtons}>
          <PdfDownloadButton
            pdfVariant={PDF_VARIANTS.PRACTICE_PLAN}
            title={`${data?.description}.pdf`}
            contentProps={{ practicePlan: data }}
            className={classes.orangeIcon}
          />

          <IsUserRolesAuthorized
            acceptedRoles={[
              USER_ORG_ROLES.OWNER,
              USER_ORG_ROLES.ADMIN,
              USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
            ]}
          >
            <MyEditIcon className={classes.orangeIcon} onClick={handleEditButton} />
            <MyDeleteIcon className={classes.orangeIcon} onClick={handleRemoveButton} />
          </IsUserRolesAuthorized>
        </div>
      </ContainerPadding>

      <Divider light />

      <ContainerPadding padding='3% 5%'>
        {isFetching ? (
          <Grid
            container
            justifyContent='center'
            style={{ marginTop: '30px', marginBottom: '30px' }}
          >
            <LoaderDots />
          </Grid>
        ) : (
          <PracticePlanComponentsTable practicePlan={data} />
        )}
      </ContainerPadding>
    </div>
  )
}

export default PracticePlanShow
