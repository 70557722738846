import COLORS from '../../utils/constants/colors'

const practicePlayersPageStyles = {
  handballIcon: {
    marginLeft: 'auto',
  },
  loaderIcon: {
    fill: COLORS.orange,
    marginTop: -50,
    width: 50,
    height: 50,
  },
  componentsLength: {
    padding: '4px 8px 4px 8px',
    backgroundColor: '#8E8E8E',
    borderRadius: '9px',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '12px',
    color: COLORS.white,
  },
  accordionWrapper: {
    marginTop: '10px',
  },
  divider: { margin: '10px 0 0 0' },
  recentsTypo: {
    marginTop: '15px',
    marginBottom: '15px',
    color: COLORS.lightGrey,
    fontSize: '0.9em',
  },
  saveButton: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 15,
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '20px',
  },
}

export default practicePlayersPageStyles
