import * as React from 'react'
import { Checkbox, makeStyles } from '@material-ui/core'
import { Field } from 'formik'
import checkboxGroupInputStyles from '../../../utils/constants/CSS/checkboxGroupInputStyles'

const MyCheckboxGroupInput = (props) => {
  const { name, label, options, ...otherProps } = props
  const useStyles = makeStyles(checkboxGroupInputStyles)
  const classes = useStyles()

  return (
    <div className={classes.checkboxWrapper}>
      {options?.map((option) => (
        <label key={option.name} className={classes.chipLabel}>
          <Field
            key={option.name}
            name={name}
            color='primary'
            type='checkbox'
            value={option.name}
            as={Checkbox}
          />
          {option.displayName}
        </label>
      ))}
    </div>
  )
}

export default MyCheckboxGroupInput
