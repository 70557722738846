export const parseDate = (date) => {
  if (!date) return null;
  const newDate = new Date(date);
  return newDate.toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
};

export const parseDateUrl = (date) => {
  const newDate = new Date(date);
  return newDate
    .toLocaleDateString("en-GB", { year: "numeric", month: "2-digit", day: "2-digit"})
    .split("/")
    .reverse()
    .join("-");
};