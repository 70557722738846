import axiosInstance from '../axiosInterceptor/axiosInstance'
import mapPracticePlayers from '../../utils/helpers/practicePlayersMapper/mapPracticePlayers'

const addPracticePlayers = async (data) => {
  const { eventId, players, teams } = data

  const mappedPracticePlayers = mapPracticePlayers(eventId, players, teams)
  const url = '/practice-players'
  return axiosInstance.post(url, mappedPracticePlayers)
}

export default addPracticePlayers
