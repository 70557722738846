import { CARD_STYLES } from '../../../utils/constants/CSS/cardStyles'
import COLORS from '../../../utils/constants/colors'

const orgSettingPageStyles = {
  card: CARD_STYLES.card,
  cardTitle: CARD_STYLES.cardTitle,
  cardSubtitle: CARD_STYLES.cardSubtitle,
  divider: CARD_STYLES.divider,
  loader: CARD_STYLES.loader,
  containerCloseOrg: {
    display: 'grid',
    marginTop: 15,
    marginBottom: 15,
  },
  info: { display: 'flex', gap: 20, marginTop: '10px', marginBottom: '10px', alignItems: 'center' },
  infoText: { marginTop: 10, color: COLORS.grey },
  deleteButton: {
    display: 'block',
    marginTop: 10,
  },
  loaderIcon: {
    fill: COLORS.orange,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 20,
    width: 70,
    height: 70,
  },
}

export default orgSettingPageStyles
