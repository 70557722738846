import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const confirmEmail = (data) => {
  const url = `${BASE_URL}/auth/confirm-email`

  return axios.post(url, data, {
    headers: generalHeader(),
  })
}

export default confirmEmail
