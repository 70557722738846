import COLORS from '../../../../../utils/constants/colors'

const createAndAddButtonStyles = {
  button: {
    height: '36px',
    backgroundColor: COLORS.white,
    color: COLORS.orange,
  },
  loadingDots: {
    width: '42px',
    height: '30px',
    paddingLeft: '45px',
    paddingRight: '45px',
    filter:
      'brightness(0) saturate(100%) invert(75%) sepia(36%) saturate(3725%) hue-rotate(312deg) brightness(93%) contrast(87%)',
  },
}

export default createAndAddButtonStyles
