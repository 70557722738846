import COLORS from '../../../utils/constants/colors'

const billingAddonStyles = () => ({
  addonCard: {
    backgroundColor: COLORS.opacBlue,
    borderColor: COLORS.blue,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '12px',
    paddingRight: '8px',
    paddingTop: '2px!important',
    paddingBottom: '2px!important',
  },
  dotText: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    marginLeft: '10%',
  },
  check: {
    width: 10,
    height: 10,
    color: COLORS.black,
    padding: 0,
  },
  priceColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '4px',
  },
})

export default billingAddonStyles
