import React, { useEffect, useState } from 'react'
import { Divider, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import orgSeatManagementStyles from './orgSeatManagement.styles'
import AddButton from '../ui/AddButton/AddButton'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import COLORS from '../../utils/constants/colors'
import ButtonWithLoader from '../ui/ButtonWithLoader/ButtonWithLoader'
import { useMutation, useQueryClient } from 'react-query'
import addEmptyOrgSeats from '../../Apis/subscriptions/addEmptyOrgSeats'
import useGetLocalStorage from '../../hooks/useGetLocalStorage'
import localStorageIds from '../../utils/constants/localStorageIds'
import getOrgQuoteMulti from '../../Apis/subscriptions/getOrgQuoteMulti'
import LoaderDots from '../loaders/LoaderDots/LoaderDots'
import SUBSCRIPTION_STATES from '../../utils/constants/subscriptionStates'
import QueryKeys from '../../utils/constants/queryKeys'

const OrgSeatManagement = (props) => {
  const { subDetails, isModal } = props
  const translate = useTranslate()
  const queryClient = useQueryClient()
  const localStorageValue = useGetLocalStorage()
  const { [localStorageIds.SELECTED_ORG]: selectedOrg } = localStorageValue || {}
  const { id: orgId } = selectedOrg || {}
  const nonOrgLevelAddons = subDetails.addons.filter((addon) => addon.isOrgLevel === false)
  const [addedSeats, setAddedSeats] = useState(
    nonOrgLevelAddons.reduce((acc, item) => {
      acc[item.id] = 0
      return acc
    }, {}),
  )
  const [pricing, setPricing] = useState([])
  const isActivationDisabled = !Object.values(addedSeats).some((seats) => seats > 0)
  const totalAmount = Object.values(pricing).reduce((sum, item) => sum + (item.amount || 0), 0)
  const isInTrial = subDetails.status === SUBSCRIPTION_STATES.TRIAL

  const useStyles = makeStyles(orgSeatManagementStyles(isInTrial))
  const classes = useStyles()

  const addOrgSeats = useMutation((data) => addEmptyOrgSeats(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_SUBSCRIPTION_DETAILS)
      queryClient.invalidateQueries(QueryKeys.GET_ACCOUNT_ME)

      setAddedSeats((prevState) => {
        const updatedState = Object.keys(prevState).reduce((acc, key) => {
          acc[key] = 0
          return acc
        }, {})
        return updatedState
      })
    },
  })

  const pricingMutation = useMutation((data) => getOrgQuoteMulti(data), {
    onSuccess: (res) => {
      setPricing(res)
    },
  })

  useEffect(() => {
    pricingMutation.mutate({ orgId, addonSeats: addedSeats })
  }, [addedSeats])

  const addSeat = (AddonType) => {
    setAddedSeats({ ...addedSeats, [AddonType]: addedSeats[AddonType] + 1 })
  }
  const removeSeat = (AddonType) => {
    if (addedSeats[AddonType] > 0)
      setAddedSeats({ ...addedSeats, [AddonType]: addedSeats[AddonType] - 1 })
  }

  if (!subDetails.addons.some((addon) => addon.isOrgLevel === false)) return null
  return (
    <>
      {!isModal && (
        <Typography variant='h5' gutterBottom className={classes.typographyBold}>
          {translate('ra.text.orgSeats')}
        </Typography>
      )}
      {nonOrgLevelAddons.map((addon) => {
        const isEnabled = addon.enabled
        const addonSeats = addon.seats

        // if (!addonSeats) return null
        return (
          <>
            <Grid
              style={{
                display: 'flex',
                paddingLeft: 10,
                marginTop: 20,
                // backgroundColor: COLORS.borderGrey,
                // border: 'solid 1px blue',
                // borderRadius: 10,
              }}
            >
              <Grid xs={2}>
                <Typography variant='h7' className={classes.typographyBold}>
                  {addon.id}
                </Typography>
              </Grid>
              <Grid xs={isEnabled ? 2 : 8} justifyContent='center' style={{ display: 'flex' }}>
                <Typography variant='h7'>
                  {isEnabled ? `${addonSeats.allocated}/${addonSeats.all}` : 'Enable addon!'}
                </Typography>
              </Grid>
              {isEnabled && (
                <>
                  <Grid xs={5} justifyContent='center' style={{ display: 'flex', gap: 10 }}>
                    {addon.pricingModel === 'PerSeat' && (
                      <>
                        <AddButton
                          style={{
                            width: 30,
                            minWidth: 7,
                            height: 25,
                            border: 'solid 1px',
                            borderColor: COLORS.subscriptionBlue,
                          }}
                          icon={<RemoveCircleIcon style={{ fill: COLORS.subscriptionBlue }} />}
                          onClick={() => removeSeat(addon.id)}
                        />
                        <Typography>{`${addedSeats[addon.id]} added seats`}</Typography>
                        <AddButton
                          filled
                          style={{
                            backgroundColor: COLORS.subscriptionBlue,
                            width: 30,
                            minWidth: 7,
                            height: 25,
                          }}
                          onClick={() => addSeat(addon.id)}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid
                    xs={4}
                    justifyContent='center'
                    alignItems='center'
                    style={{ display: 'flex' }}
                  >
                    <Typography>
                      {pricing[addon.id]?.description.replace('allocated', 'free')}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          </>
        )
      })}
      <div
        style={{
          paddingLeft: 10,
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: 20,
          marginTop: 10,
        }}
      >
        {pricingMutation.isLoading ? (
          <LoaderDots />
        ) : (
          <Tooltip
            title='Free of charge, because you are on Trial Period'
            disableHoverListener={!isInTrial}
            arrow
          >
            <Typography
              variant='h5'
              className={classes.priceText}
            >{`Total: ${totalAmount}€`}</Typography>
          </Tooltip>
        )}

        <ButtonWithLoader
          label='Purchase Seats'
          style={{
            backgroundColor: !isActivationDisabled ? COLORS.subscriptionBlue : COLORS.searchGrey,
          }}
          loading={addedSeats.isLoading}
          disabled={isActivationDisabled}
          onClick={() => addOrgSeats.mutate({ orgId, addedSeats })}
        />
      </div>
    </>
  )
}

export default OrgSeatManagement
