import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { ReferenceInput, required, useTranslate } from 'react-admin'
import CancelIcon from '@material-ui/icons/Cancel'
import COLORS from '../../../utils/constants/colors'
import AddButton from '../../../components/ui/AddButton/AddButton'
import MyNumberInput from '../inputs/MyNumberInput'
import MySelectInput from '../inputs/MySelectInput'
import FormGrid from '../layout/FormGrid/FormGrid'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'

const CustomIterator = (props) => {
  const { org, fieldArrayName, disabled } = props
  const translate = useTranslate()

  return (
    <FieldArray name={fieldArrayName}>
      {(fieldProps) => {
        return (
          <>
            {fieldProps.fields.map((teams, index) => {
              return (
                <div key={index}>
                  <FormGrid container spacing={0}>
                    <ReferenceInput
                      reference='team'
                      filter={{ org }}
                      source={fieldArrayName === 'teams' ? `${teams}.team` : `${teams}.team.id`}
                      label='Team'
                      md={12}
                      sm={12}
                    >
                      <MySelectInput
                        source={fieldArrayName === 'teams' ? `${teams}.team` : `${teams}.team.id`}
                        optionValue='id'
                        validate={[required()]}
                        label='Team'
                        disabled={disabled}
                      />
                    </ReferenceInput>

                    <div style={{ display: 'flex', alignItems: 'center' }} md={12} sm={12}>
                      <MyNumberInput
                        source={
                          fieldArrayName === 'teams' ? `${teams}.jerseyNumber` : `${teams}.value`
                        }
                        label='Jersey Number'
                        step={1}
                        min={0}
                        disabled={disabled}
                      />

                      {index !== 0 ? (
                        <ConditionalRender renderValue={!disabled}>
                          <CancelIcon
                            onClick={() => fieldProps.fields.remove(index)}
                            style={{
                              fill: COLORS.grey,
                              cursor: 'pointer',
                              marginLeft: '15px',
                            }}
                          />
                        </ConditionalRender>
                      ) : null}
                    </div>
                  </FormGrid>
                </div>
              )
            })}
            <FormGrid container spacing={2}>
              <ConditionalRender renderValue={!disabled}>
                <AddButton
                  label={translate('ra.buttons.addTeam')}
                  onClick={() => fieldProps.fields.push({ team: '', jerseyNumber: '' })}
                  filled
                />
              </ConditionalRender>
            </FormGrid>
          </>
        )
      }}
    </FieldArray>
  )
}

export default CustomIterator
