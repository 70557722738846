import COLORS from '../../utils/constants/colors'
import tabletScreenWidth from '../../utils/constants/tabletScreenWidth'

const componentFieldStyles = (isSidebarOpen) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    height: '100%',
    '@media (max-width: 750px)': {
      flexWrap: 'wrap',
    },
    [`@media (max-width: ${tabletScreenWidth})`]: {
      flexWrap: isSidebarOpen ? 'wrap' : null,
    },
  },
  deleteIcon: {
    '&&': {
      fill: COLORS.orange,
    },
  },
})

export default componentFieldStyles
