import COLORS from '../../../utils/constants/colors'

const drillCreateFormStyles = (theme, { deleteDisabled }) => {
  const SMALL_DEVICE_BREAKPOINT = 1170

  return {
    form: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    gridDividerRoot: {
      maxWidth: '4.75%',
      flexBasis: '4.75%',
    },
    gridContainer: {
      paddingTop: 15,
      paddingBottom: 15,
    },
    body: {
      flex: 1,
    },
    fullWidth: {
      width: '100%',
      height: '100%',
    },
    fieldContainer: {
      marginTop: 20,
    },
    span: {
      display: 'block',
      marginBottom: 15,
      fontSize: 17.5,
    },
    noMarginSpan: {
      display: 'block',
      fontSize: 17.5,
    },
    positionSpan: {
      display: 'block',
      marginTop: 15,
      marginBottom: 15,
    },
    graphicsWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 17.5,
    },
    appBarPublishWrapper: {
      display: 'flex',
      gap: 5,
      alignItems: 'center',
      marginLeft: '15%',
    },
    appBarIconsWrapper: {
      display: 'flex',
      gap: 10,
      alignItems: 'center',
      marginLeft: 'auto',
    },
    deleteIcon: {
      '&&': {
        fill: !deleteDisabled && COLORS.orange,
      },
    },
  }
}

export default drillCreateFormStyles
