import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { useTranslate } from 'react-admin'
import MyPdfIcon from '../../assets/icons/MyPdfIcon'
import PDF_MAPPER from '../../utils/constants/pdfMapper'
import TextButton from '../ui/TextButton/TextButton'
import pdfDownloadButtonStyles from './pdfDownloadButton.styles'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const PdfDownloadButton = (props) => {
  const { pdfVariant, title, contentProps, className, label } = props
  const translate = useTranslate()
  const useStyles = makeStyles(pdfDownloadButtonStyles)
  const classes = useStyles()
  const handlePdfClick = async (e) => {
    e.stopPropagation()

    pdfMake
      .createPdf({
        header: PDF_MAPPER[pdfVariant].header(contentProps),
        content: await PDF_MAPPER[pdfVariant].content(contentProps),
        footer: PDF_MAPPER[pdfVariant].footer,
        styles: PDF_MAPPER[pdfVariant].styles,
        pageMargins: PDF_MAPPER[pdfVariant].pageMargins,
      })
      .download(title)
  }

  return !label ? (
    <Tooltip title={translate('ra.buttons.export')} onClick={handlePdfClick}>
      <IconButton color='primary' aria-label='download pdf' component='span'>
        <MyPdfIcon className={`${classes.pdfIcon} ${className}`} />
      </IconButton>
    </Tooltip>
  ) : (
    <TextButton
      onClick={handlePdfClick}
      label={label}
      startIcon={<MyPdfIcon className={`${classes.pdfIcon} ${className}`} />}
    />
  )
}

PdfDownloadButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  pdfVariant: PropTypes.string,
  title: PropTypes.string,
  contentProps: PropTypes.object,
}

export default PdfDownloadButton
