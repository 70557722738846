import * as React from "react";
import { makeStyles } from "@material-ui/core";
import COLORS from "../../utils/constants/colors";
import FlightLandIcon from "@material-ui/icons/FlightLand";

const MyFlightLandIcon = props => {
  const useStyles = makeStyles({
    addIcon: {
      width: 22,
      height: 22,
      fill: COLORS.grey
    }
  });
  const classes = useStyles();

  return (
    <FlightLandIcon {...props} classes={{ root: classes.addIcon }} />
  );
};

export default MyFlightLandIcon;
