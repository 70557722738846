import axiosInstance from '../axiosInterceptor/axiosInstance'

const getTeamStats = async ({ teamId, games }) => {
  const url = `/stats/team/${teamId}`

  return axiosInstance.get(url, {
    params: {
      lastNGames: games,
    },
  })
}

export default getTeamStats
