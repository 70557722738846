import axiosInstance from '../axiosInterceptor/axiosInstance'

export const updateCourt = async (data) => {
  const { courtId, ...rest } = data
  const url = `/courts/${courtId}`

  const response = await axiosInstance.patch(url, JSON.stringify({ ...rest }))

  return response.data
}
