import axiosInstance from '../axiosInterceptor/axiosInstance'

const getUploadPresignedUrl = async (data) => {
  const { teamId, requestBody } = data
  const url = `/events/get-upload-presigned-url?teamId=${teamId}`
  const response = await axiosInstance.post(url, requestBody)
  return response?.data
}

export default getUploadPresignedUrl
