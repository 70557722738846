const designsStyles = (type) => ({
  wrapper: {
    display: type === 'drills' ? 'flex' : null,
    flexWrap: 'wrap',
    gap: 20,
    marginBottom: 20,
  },
  divider: {
    marginTop: 15,
    width: '100%',
  },
  draggableDesign: {
    display: 'flex',
    alignItems: 'center',
  },
})

export default designsStyles
