import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { matchPath, useLocation } from 'react-router'
import { v4 } from 'uuid'
import BREADCRUMB_ROUTES from '../../utils/constants/breadcrumbRoutes'
import useQueryParams from '../../hooks/useQueryParams'
import breadcrumbStyles from './breadcrumb.styles'
import localStorageHelper from '../../utils/helpers/localStorageHelper'
import localStorageIds from '../../utils/constants/localStorageIds'

const Breadcrumb = () => {
  const { pathname } = useLocation()
  const useStyles = makeStyles(breadcrumbStyles)
  const classes = useStyles()
  const [breadCrumbLinks, setBreadCrumbLinks] = useState([])
  const { breadcrumb, eventType, team } = useQueryParams()
  const orgLocalStorageValue = localStorageHelper(localStorageIds.SELECTED_ORG)
  const teamLocalStorageValue = localStorageHelper(localStorageIds.SELECTED_TEAM)

  const calcTeamName = () => {
    if (!teamLocalStorageValue.localStorageValue) {
      return orgLocalStorageValue.localStorageValue?.teams?.find((t) => t.id === team)?.name
    }

    return teamLocalStorageValue.localStorageValue?.name
  }

  const calcBread = async (teamName) => {
    const promises = BREADCRUMB_ROUTES.map(async (route) => {
      const match = matchPath(pathname, {
        path: route.path,
        exact: true,
      })

      return match ? route.breadcrumb({ match, breadcrumb, teamName, eventType }) : null
    })

    const breadcrumbs = await Promise.all(promises)
    setBreadCrumbLinks(breadcrumbs.find((e) => e))
  }

  useEffect(() => {
    const teamName = calcTeamName()
    calcBread(teamName)
  }, [pathname, breadcrumb, team])

  return (
    <Breadcrumbs aria-label='breadcrumb'>
      {breadCrumbLinks &&
        breadCrumbLinks.map(
          (path) =>
            path && (
              <span key={v4()} className={classes.breadcrumb}>
                {path}
              </span>
            ),
        )}
    </Breadcrumbs>
  )
}

export default Breadcrumb
