import axiosInstance from '../axiosInterceptor/axiosInstance'

const getTactics = async () => {
  // const { q } = data
  // const url = `${BASE_URL}/tactics?Q=${q}`
  const url = '/tactics'

  const response = await axiosInstance.get(url)
  return response.data
}

export default getTactics
