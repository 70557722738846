const mapPracticeTeams = (teams) => {
  const practiceTeams = []

  Object.entries(teams?.TeamsOfThree || {}).forEach((element, index) => {
    const playerNames = element[1].map(
      (player) => `${player.personalData.firstName} ${player.personalData.lastName}`,
    )
    const practiceTeam = {
      numberOfPlayers: 3,
      teamPlayerNames: playerNames,
      order: index,
    }

    practiceTeams.push(practiceTeam)
  })

  Object.entries(teams?.TeamsOfFour || {}).forEach((element, index) => {
    const playerNames = element[1].map(
      (player) => `${player.personalData.firstName} ${player.personalData.lastName}`,
    )
    const practiceTeam = {
      numberOfPlayers: 4,
      teamPlayerNames: playerNames,
      order: index,
    }

    practiceTeams.push(practiceTeam)
  })

  Object.entries(teams?.TeamsOfFive || {}).forEach((element, index) => {
    const playerNames = element[1].map(
      (player) => `${player.personalData.firstName} ${player.personalData.lastName}`,
    )
    const practiceTeam = {
      numberOfPlayers: 5,
      teamPlayerNames: playerNames,
      order: index,
    }

    practiceTeams.push(practiceTeam)
  })

  return practiceTeams
}

const mapPracticePlayers = (eventId, players, teams) => {
  return {
    eventId,
    creatorId: '00000000-0000-0000-0000-000000000000', // GUID empty value
    players: players.map((player) => player.id),
    practiceTeams: mapPracticeTeams(teams),
  }
}

export default mapPracticePlayers
