import COLORS from '../../../utils/constants/colors'

const drillListAppbarSelectFieldStyles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
  },
  chip: {
    height: 28,
  },
  deleteIcon: {
    '&&': {
      fill: COLORS.white,
      width: 18,
      height: 18,
    },
  },
  cancelIcon: {
    fill: COLORS.darkGrey,
  },

  // FormControl
  formControlRoot: {
    minWidth: 120,
    maxWidth: 250,
  },

  // Select
  select: {
    fontSize: '0.95rem',
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  selectInput: {
    padding: '15px 12px 15px',
  },
}

export default drillListAppbarSelectFieldStyles
