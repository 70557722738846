import axiosInstance from '../axiosInterceptor/axiosInstance'

const getUploadVideo = async (data) => {
  const { trainingModuleId, file, requestBody } = data
  const url = `/training-modules/upload-video?trainingModuleId=${trainingModuleId}&filename=${file.name}`
  const response = await axiosInstance.post(url, requestBody)
  return response?.data
}

export default getUploadVideo
