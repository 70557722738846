import * as React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

const BoxEventTypeColor = ({ color }) => (
  <div style={{ display: 'flex', alignItem: 'center' }}>
    <Box
      sx={{
        width: '18px',
        height: '18px',
        backgroundColor: `${color}`,
        borderRadius: '4px',
        marginLeft: '2px',
      }}
    />
  </div>
)

BoxEventTypeColor.propTypes = {
  color: PropTypes.string,
}

export default BoxEventTypeColor
