import React from 'react'
import PropTypes from 'prop-types'
import { Add as AddIcon } from '@material-ui/icons'
import AccordionParent from './AccordionParent'
import AccordionChild from './AccordionChild'
import tacticClickedType from '../../types/tactics/tacticClickedType'
import tacticType from '../../types/tactics/tacticType'
import COLORS from '../../utils/constants/colors'
import { Tooltip } from '@material-ui/core'
import { useTranslate } from 'react-admin'


const Accordion = ({
  data,
  tagsClicked,
  color,
  onTagClick,
  onAddChild,
  onDeleteChild,
  onUpdateName,
  viewOnly,
}) => {
  const translate = useTranslate()
  const renderNode = (node, parentColor, depth) => {
    const marginLeft = `${(depth + 1) * 5}%` // Adjust indentation based on depth
    const width = `${65 - depth * 5}%` // Adjust width based on depth
    if (node.children && node.children.length > 0) {
      return (
        <AccordionParent
          key={node.id}
          label={node.name}
          color={parentColor}
          initiallyOpen
          gridPosition='center'
          style={{ marginLeft, height: '45px', marginTop: '10px', width }}
          onClick={() => onTagClick({ ...node, color: parentColor })}
          clicked={tagsClicked.some((tag) => tag.id === node.id)}
          isEditable={!!node?.creatorId}
          onDeleteChild={() => onDeleteChild(node.id)}
          onUpdateName={(newName) => onUpdateName(node.id, newName)}
          items={node.children.map((child) => renderNode(child, parentColor, depth + 1))}
          viewOnly={viewOnly}
        >
          <Tooltip title={translate('ra.action.add')}>
            <AddIcon
            style={{
              color: COLORS.orange,
              borderRadius: '50%',
              cursor: 'pointer',
              zIndex: 10,
            }}
            onClick={(e) => {
              e.stopPropagation()
              onAddChild(node)
            }}
          />
          </Tooltip>
        </AccordionParent>
      )
    }
    return (
      <AccordionChild
        key={node.id}
        label={node.name}
        color={parentColor}
        style={{ marginLeft, height: '45px', marginTop: '10px', width }}
        onClick={() => onTagClick({ ...node, color: parentColor })}
        clicked={tagsClicked.some((tag) => tag.id === node.id)}
        isEditable={!!node?.creatorId}
        onDeleteChild={() => onDeleteChild(node.id)}
        onUpdateName={(newName) => onUpdateName(node.id, newName)}
        viewOnly={viewOnly}
      >
        <Tooltip title={translate('ra.action.add')}>
          <AddIcon
            style={{
              color: COLORS.orange,
              borderRadius: '50%',
              cursor: 'pointer',
              zIndex: 10,
            }}
            onClick={(e) => {
              e.stopPropagation()
              onAddChild(node)
            }}
          />
        </Tooltip>

      </AccordionChild>
    )
  }

  return (
    <AccordionParent
      label={data.name}
      color={color}
      initiallyOpen
      gridPosition='center'
      style={{ fontWeight: 600, color, width: '70%' }}
      onClick={() => onTagClick({ ...data, color })}
      clicked={tagsClicked.some((tag) => tag.id === data.id)}
      isEditable={!!data?.creatorId}
      onDeleteChild={() => onDeleteChild(data.id)}
      onUpdateName={(newName) => onUpdateName(data.id, newName)}
      items={data.children.map((child) => renderNode(child, color, 0))} // Start with depth 0
      viewOnly={viewOnly}
    >
      <AddIcon
        style={{
          color: COLORS.orange,
          borderRadius: '50%',
          cursor: 'pointer',
          zIndex: 10,
        }}
        onClick={(e) => {
          e.stopPropagation()
          onAddChild(data)
        }}
      />
    </AccordionParent>
  )
}

Accordion.propTypes = {
  data: tacticType,
  tagsClicked: PropTypes.arrayOf(tacticClickedType),
  color: PropTypes.string,
  onTagClick: PropTypes.func,
  onAddChild: PropTypes.func.isRequired,
  onDeleteChild: PropTypes.func.isRequired,
  onUpdateName: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
}

Accordion.defaultProps = {
  viewOnly: false,
}

export default Accordion
