import COLORS from '../../utils/constants/colors'

const drillBoxStyles = {
  drillBoxWrapper: {
    backgroundColor: COLORS.white,
    borderRadius: 6,
    padding: '10px 0',
    margin: '10px 0',
    boxShadow: '0px 0px 13px -2px rgba(0, 0, 0, 0.07)',
    cursor: 'pointer',
  },
  accessWrapper: {
    justifyContent: 'space-between',
  },
  accessIndicator: {
    fontSize: 12,
    padding: '2px 5px',
    backgroundColor: COLORS.backgroundGrey,
    color: COLORS.lightGrey,
  },
  title: {
    fontSize: 24,
  },
  spaceVertical: {
    marginTop: 10,
    marginBottom: 10,
    whiteSpace: 'pre-wrap',
  },
  description: {
    width: '100%',
    fontSize: 14,
    textAlign: 'justify',
    hyphens: 'auto',
  },
  fadeAway: {
    opacity: 0.7,
  },
  greySmall: {
    color: COLORS.lightGrey,
    fontSize: 12,
  },
  divFlex: {
    display: 'flex',
    gap: 5,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}

export default drillBoxStyles
