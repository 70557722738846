import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import * as yup from 'yup'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import ButtonWithLoader from '../../../ui/ButtonWithLoader/ButtonWithLoader'
import useFormValidation from '../../../../hooks/useFormValidation'
import mutationType from '../../../../types/mutationType'
import AddTeamFormBody from './AddTeamFormBody/AddTeamFormBody'
import genderType from '../../../../types/genderType'
import ageRestrictionType from '../../../../types/ageRestrictionType'

const AddTeamForm = (props) => {
  const { gender, ageRestriction, orgId, addTeam } = props
  const [croppedTeamLogo, setCroppedTeamLogo] = useState('')
  const { stringThreeLetters } = useFormValidation()
  const translate = useTranslate()

  const handleSubmit = (values) => {
    addTeam.mutate({
      orgId,
      name: values.name,
      threeLetterIdentifier: values.threeLetterIdentifier,
      gender: values.gender,
      ageRestriction: values.ageRestriction,
      logo: croppedTeamLogo,
      externalId: values?.externalId,
      color: values?.teamColor.value,
    })
  }

  return (
    <Formik
      initialValues={{
        name: '',
        threeLetterIdentifier: '',
        ageRestriction: '',
        gender: '',
        externalId: '',
        teamColor: '',
      }}
      validationSchema={yup.object().shape({
        threeLetterIdentifier: stringThreeLetters,
      })}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ setFieldValue }) => (
        <Form>
          <AddTeamFormBody
            setFieldValue={setFieldValue}
            gender={gender}
            ageRestriction={ageRestriction}
            setCroppedTeamImage={setCroppedTeamLogo}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '5px 10px' }}>
            <ButtonWithLoader
              label={translate('ra.buttons.add')}
              loading={addTeam.isLoading}
              justifyContent='flex-end'
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

AddTeamForm.propTypes = {
  gender: PropTypes.arrayOf(genderType),
  ageRestriction: PropTypes.arrayOf(ageRestrictionType),
  orgId: PropTypes.string,
  addTeam: mutationType,
}

AddTeamForm.defaultProps = {
  gender: [],
  ageRestriction: [],
}

export default AddTeamForm
