import { React, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, makeStyles, Grid } from '@material-ui/core'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslate } from 'react-admin'
import { v4 as uuidv4 } from 'uuid'
import DialogWithCloseIcon from '../DialogWithCloseIcon/DialogWithCloseIcon'
import SelectedPlayer from '../PracticePlayersSelectedPlayer/SelectedPlayer'
import teamsModalStyles from './teamsModalStyles'
import TeamTable from './TeamTable'
import AddButton from '../ui/AddButton/AddButton'
import ButtonWithLoader from '../ui/ButtonWithLoader/ButtonWithLoader'

const TeamsModal = ({ isOpen, onClose, selectedPlayers, playersInEachTeam, editTeam }) => {
  const translate = useTranslate()
  const useStyles = makeStyles(teamsModalStyles)
  const classes = useStyles()
  const [teamPlayers, setTeamPlayers] = useState([{ team: [] }, { team: [] }])
  const [updateSelectedPlayers, setUpdateSelectedPlayers] = useState(selectedPlayers)
  const [numTables, setNumTables] = useState(2)
  const addTable = () => {
    setTeamPlayers((prevTeamPlayers) => [...prevTeamPlayers, { team: [] }])
    setNumTables(numTables + 1)
  }

  useEffect(() => {
    if (isOpen) {
      setUpdateSelectedPlayers(selectedPlayers)
      if (editTeam != null) {
        const editTeamPlayerIds = editTeam
          .flatMap((team) => team.map((player) => player?.id))
          .filter((id) => id !== undefined)

        const filteredSelectedPlayers = selectedPlayers.filter(
          (player) => !editTeamPlayerIds.includes(player.id),
        )

        setUpdateSelectedPlayers(filteredSelectedPlayers)

        setTeamPlayers(
          editTeam.map((team) => ({
            team: team.filter((player) => player !== undefined),
          })),
        )

        setNumTables(editTeam.length)
      }
    }
  }, [isOpen, selectedPlayers, editTeam])

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const sourceIndex = result.source.index
    const destinationDroppableId = result.destination.droppableId

    // Player dropped into a team
    const destinationTeamIndex = parseInt(destinationDroppableId.replace('team', ''), 10) - 1
    const draggedPlayer = updateSelectedPlayers[sourceIndex]

    const updatedSelectedPlayers = updateSelectedPlayers.filter(
      (player, index) => index !== sourceIndex,
    )

    const updatedTeamPlayers = [...teamPlayers]
    updatedTeamPlayers[destinationTeamIndex]?.team.push(draggedPlayer)

    setUpdateSelectedPlayers(updatedSelectedPlayers)
    setTeamPlayers(updatedTeamPlayers)
  }

  const removePlayerFromTeam = (playerToRemove) => {
    const updatedTeamPlayers = teamPlayers.map((team) => {
      return {
        ...team,
        team: team.team.filter((player) => player.id !== playerToRemove.id),
      }
    })

    setTeamPlayers(updatedTeamPlayers)

    const updatedSelectedPlayers = [...updateSelectedPlayers, playerToRemove]
    setUpdateSelectedPlayers(updatedSelectedPlayers)
  }

  const onCloseModal = () => {
    const filteredTeams = teamPlayers.filter((team) => team.team.length > 0)
    const teams = {
      numberOfPlayersInEachTeam: playersInEachTeam,
      teams: filteredTeams,
    }
    setTeamPlayers([{ team: [] }, { team: [] }])
    setNumTables(2)
    onClose(teams)
  }

  const removeTeam = (indexToRemove) => {
    // Move players from the removed team back to selected players
    const removedPlayers = teamPlayers[indexToRemove].team
    const updatedSelectedPlayers = [...updateSelectedPlayers, ...removedPlayers]
    setUpdateSelectedPlayers(updatedSelectedPlayers)

    // Remove the team from teamPlayers
    const updatedTeamPlayers = [...teamPlayers]
    updatedTeamPlayers.splice(indexToRemove, 1)
    setTeamPlayers(updatedTeamPlayers)

    // Update the number of tables
    setNumTables(numTables - 1)
  }

  return (
    <DialogWithCloseIcon
      open={isOpen}
      onClose={onCloseModal}
      dialogTitle={`Teams of ${playersInEachTeam}`}
      minDialogWidth='80%'
      minDialogHeight='70%'
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <Typography style={{ fontSize: 'small' }}>{translate('ra.text.players')}</Typography>
        <Droppable droppableId='selected-players' direction='horizontal'>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classes.droppableGrid}
            >
              {updateSelectedPlayers.map((player, index) => (
                <Draggable key={player.id} draggableId={player.id} index={index}>
                  {(newProvided) => (
                    <div
                      ref={newProvided.innerRef}
                      className={classes.draggableDiv}
                      {...newProvided.draggableProps}
                      {...newProvided.dragHandleProps}
                      style={{
                        ...newProvided.draggableProps.style,
                      }}
                    >
                      <SelectedPlayer player={player} drag />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Grid container spacing={1}>
          {[...Array(numTables)].map((_, index) => (
            <Grid item xs={5} sm={4} md={3} lg={4} key={uuidv4()}>
              <TeamTable
                key={uuidv4()}
                teamNumber={index + 1}
                teamPlayers={teamPlayers[index]?.team || []}
                removePlayerFromTeam={removePlayerFromTeam}
                updateSelectedPlayers={updateSelectedPlayers}
                removeTeam={() => removeTeam(index)}
              />
            </Grid>
          ))}
        </Grid>
      </DragDropContext>
      <AddButton
        onClick={addTable}
        label={translate('ra.buttons.addTeam')}
        style={{ marginTop: 10 }}
        filled
      />
      <div className={classes.buttonDiv}>
        <ButtonWithLoader label='OK' onClick={onCloseModal} />
      </div>
    </DialogWithCloseIcon>
  )
}

TeamsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedPlayers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      playerName: PropTypes.string.isRequired,
      positions: PropTypes.arrayOf(PropTypes.string).isRequired,
      logoUrl: PropTypes.string,
    }),
  ).isRequired,
  playersInEachTeam: PropTypes.number.isRequired,
  editTeam: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
}

TeamsModal.defaultProps = {
  editTeam: null,
}

export default TeamsModal
