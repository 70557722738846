import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import { Avatar, makeStyles } from '@material-ui/core'
import imageCropEditPreviewStyles from './imageCropEditPreview.styles'
import ConditionalRender from '../../../ConditionalRender/ConditionalRender'
import CROPPER_SHAPES from '../../../../utils/constants/cropperShapes'

const ImageCropEditPreview = (props) => {
  const { imageSrc, emptyPreviewIcon, width, handleEdit, cropShape, isDisabled, variant } = props
  const useStyles = makeStyles(imageCropEditPreviewStyles(width, variant))
  const classes = useStyles()

  // TODO Add 'rounded' avatar functionality
  return (
    <div className={classes.container}>
      <ConditionalRender renderValue={imageSrc}>
        <Avatar variant={variant} className={classes.img} src={imageSrc} alt='Player-Avatar' />
      </ConditionalRender>

      <ConditionalRender renderValue={!imageSrc && emptyPreviewIcon}>
        {typeof emptyPreviewIcon === 'string' ? (
          <img className={classes.emptyImg} src={emptyPreviewIcon} alt='empty-org' />
        ) : (
          emptyPreviewIcon && emptyPreviewIcon(classes.img)
        )}
      </ConditionalRender>

      <ConditionalRender renderValue={!imageSrc && !emptyPreviewIcon}>
        <Avatar variant={variant} className={classes.img} alt='Empty-logo' />
      </ConditionalRender>

      <ConditionalRender renderValue={!isDisabled}>
        <EditIcon className={classes.editIcon} onClick={handleEdit} />
      </ConditionalRender>
    </div>
  )
}

ImageCropEditPreview.propTypes = {
  imageSrc: PropTypes.string,
  emptyPreviewIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  width: PropTypes.number,
  handleEdit: PropTypes.func,
  cropShape: PropTypes.string,
  isDisabled: PropTypes.bool,
}

ImageCropEditPreview.defaultProps = {
  cropShape: CROPPER_SHAPES.ROUND,
}

export default ImageCropEditPreview
