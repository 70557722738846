import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import { makeStyles } from "@material-ui/core";
import COLORS from "../../utils/constants/colors";

const ClockIcon = props => {
  const useStyles = makeStyles({
    icon: {
      width: 19,
      height: 19,
      fill: COLORS.grey
    }
  });
  const classes = useStyles();

  return (
    <QueryBuilderIcon {...props} classes={{ root: classes.icon}}/>
  )
}

export default ClockIcon;
