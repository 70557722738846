import COLORS from '../../../utils/constants/colors'

const buttonWithLoaderStyles = (buttonRect) => ({
  buttonRoot: {
    minWidth: buttonRect ? buttonRect.width : '',
    backgroundColor: COLORS.orange,
    color: COLORS.white,
    height: 35,
    '&:hover': {
      backgroundColor: COLORS.orange,
    },
  },
  buttonDisabled: {
    '&&&': {
      color: 'rgba(0, 0, 0, 0.26)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
  loadingDots: {
    width: '42px',
    height: '30px',
  },
})

export default buttonWithLoaderStyles
