import axiosInstance from '../axiosInterceptor/axiosInstance'

const getPracticePlans = async (data) => {
  const { Page, Q, orgId } = data

  const queryString = `?ClubId=${orgId}&Q=${Q}&Page=${Page}`
  const url = `/practice-plans${queryString}`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getPracticePlans
