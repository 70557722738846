const sortArrayOfObjectsByName = (array, fieldToOrder, orderType) => {
  // This function assumes that that array contains objects
  // orderType default is ASC, unless DESC entered

  if (!array?.length) return []

  const orderedArray = array.sort((a, b) => {
    // Convert names to lowercase to ensure case-insensitive sorting
    const nameA = a[fieldToOrder].toLowerCase()
    const nameB = b[fieldToOrder].toLowerCase()

    if (nameA < nameB) {
      return -1 // a should come before b
    }
    if (nameA > nameB) {
      return 1 // b should come before a
    }
    return 0 // names are equal
  })

  if (orderType === 'DESC') {
    // Reverse the sorted array
    return orderedArray?.reverse()
  }

  return orderedArray
}

export default sortArrayOfObjectsByName
