import COLORS from '../../utils/constants/colors'

const tacticsSelectFieldStyles = {
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectInput: {
    padding: '15px 12px 15px',
  },
  selectIcon: {
    marginRight: 7,
  },
  renderValueContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '0 10px',
  },
  renderValueLabel: {
    color: COLORS.white,
    backgroundColor: COLORS.lightBlue,
    padding: '1px 7px',
    fontSize: '12px',
    fontWeight: 500,
    borderRadius: '10px',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  cancelIcon: {
    fill: COLORS.darkGrey,
  },
}

export default tacticsSelectFieldStyles
