const subscriptionStatusTabStyles = {
  main: {
    paddingTop: '10%',
    paddingLeft: '25%',
    paddingRight: '25%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  checkIcon: {
    width: '50px',
    height: '50px',
    color: '#096BDE',
  },
  errorIcon: {
    width: '50px',
    height: '50px',
    color: '#C3512D',
  },
  typographyBold: { fontWeight: 'bold', marginLeft: '15px' },
  mainStatusText: { marginTop: '10px' },
}

export default subscriptionStatusTabStyles
