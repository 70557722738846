const NotebookIcon = (props) => {
  return (
    <svg
      {...props}
      width='16'
      height='18'
      viewBox='0 0 18 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 5V3H2V2C2 1.46957 2.21071 0.960859 2.58579 0.585786C2.96086 0.210714 3.46957 0 4 0H10V7L12.5 5.5L15 7V0H16C17.05 0 18 0.95 18 2V18C18 19.05 17.05 20 16 20H4C2.95 20 2 19.05 2 18V17H0V15H2V11H0V9H2V5H0ZM4 9H2V11H4V9ZM4 5V3H2V5H4ZM4 17V15H2V17H4Z'
        fill='#E0726E'
      />
    </svg>
  )
}

export default NotebookIcon
