import React, { useState, useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useMutation, useQueryClient } from 'react-query'
import tacticsAccordionStyles from './tacticsAccordion.styles'
import Accordion from '../accordion/Accordion'
import TACTICS_COLORS from '../../utils/constants/tacticsColors'
import sortByStringValueAscending from '../../utils/helpers/sortByStringValueAscending'
import LoaderDots from '../loaders/LoaderDots/LoaderDots'
import tacticType from '../../types/tactics/tacticType'
import tacticClickedType from '../../types/tactics/tacticClickedType'
import addTactic from '../../Apis/tactics/addTactic'
import QueryKeys from '../../utils/constants/queryKeys'
import updateTactic from '../../Apis/tactics/updateTactic'
import deleteTactic from '../../Apis/tactics/deleteTactic'

const TacticsAccordion = ({ onTagClick, tactics, tagsClicked, viewOnly }) => {
  const useStyles = makeStyles(tacticsAccordionStyles)
  const queryClient = useQueryClient()
  const classes = useStyles()
  const [tacticsState, setTacticsState] = useState([])

  const addNewTactic = useMutation((data) => addTactic(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_TACTICS)
    },
  })

  const updateATactic = useMutation((data) => updateTactic(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_TACTICS)
    },
  })

  const deleteATactic = useMutation((childId) => deleteTactic(childId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_TACTICS)
    },
  })

  useEffect(() => {
    const sortedTactics = tactics.map((parentTactic) => ({
      ...parentTactic,
      children: sortByStringValueAscending(parentTactic.children, 'name').map((tactic) => ({
        ...tactic,
        children: sortByStringValueAscending(tactic.children, 'name'),
      })),
    }))
    setTacticsState(sortedTactics)
  }, [tactics])

  const handleAddChild = (parentTactic) => {
    let type

    if (parentTactic.type === 0) {
      if (parentTactic.name === 'Offense') {
        type = 1
      } else {
        type = 2
      }
    } else {
      type = parentTactic.type
    }
    const payload = {
      name: null,
      parentId: parentTactic.id,
      type,
    }
    addNewTactic.mutate(payload)
  }

  const handleUpdateChildName = (childId, newName) => {
    const payload = {
      id: childId,
      name: newName,
      parentId: null,
      type: 0,
    }
    updateATactic.mutate(payload)
  }

  const handleDeleteChild = (childId) => {
    deleteATactic.mutate({ childId })
  }

  return (
    <Grid container classes={{ root: classes.wrapper }} alignContent='center'>
      {tacticsState.length > 0 ? (
        tacticsState.map((tactic) => (
          <Grid item xs={6} key={tactic.id} classes={{ root: classes.accordionWrapper }}>
            <Accordion
              data={tactic}
              tagsClicked={tagsClicked || []}
              color={TACTICS_COLORS[tactic.name]}
              onTagClick={onTagClick}
              onAddChild={handleAddChild}
              onDeleteChild={handleDeleteChild}
              onUpdateName={handleUpdateChildName}
              viewOnly={viewOnly}
            />
          </Grid>
        ))
      ) : (
        <Grid container justifyContent='center'>
          <LoaderDots style={tacticsAccordionStyles.loaderIcon} />
        </Grid>
      )}
    </Grid>
  )
}

TacticsAccordion.propTypes = {
  onTagClick: PropTypes.func,
  tactics: PropTypes.arrayOf(tacticType),
  tagsClicked: PropTypes.arrayOf(tacticClickedType),
  viewOnly: PropTypes.bool,
}

TacticsAccordion.defaultProps = {
  viewOnly: false,
}

export default TacticsAccordion
