import axiosInstance from '../axiosInterceptor/axiosInstance'

const activateCoupon = (data) => {
  const { couponCode, orgId } = data
  const url = '/billing/coupons'
  const payload = { couponCode, orgId }
  return axiosInstance.post(url, payload)
}

export default activateCoupon
