import COLORS from '../../utils/constants/colors'

const practiceBoxDateStyles = {
  wrapper: {
    width: '35px',
    height: '43px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'self-start',
    backgroundColor: COLORS.backgroundGrey,
    borderRadius: '10px',
    color: 'rgba(0, 0, 0, 0.60)',
  },
  month: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '17px',
    letterSpacing: '0.15px',
  },
  date: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '0.15px',
  },
}

export default practiceBoxDateStyles
