import COLORS from '../../../../utils/constants/colors'

const loginFormStyles = {
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 15,
  },
  linksWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  link: {
    color: COLORS.purple,
  },
}

export default loginFormStyles
