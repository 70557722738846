import React, { useState, Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { TextField, IconButton } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'

const NewAccordionChild = ({ label, children, onUpdateName, onDeleteChild, viewOnly }) => {
  const [name, setName] = useState(label)
  const [edit, setEdit] = useState(!label)

  const validate = (values) => {
    const errors = {}
    if (!values.nameInput) {
      errors.nameInput = 'Name is required'
    }
    return errors
  }

  const handleOkClick = (values) => {
    setName(values.nameInput)
    onUpdateName(values.nameInput)
    setEdit(false)
  }

  const handleEditClick = () => {
    setEdit(true)
  }

  const handleCancelClick = () => {
    setEdit(false)
    if (!label) {
      onDeleteChild()
    }
  }

  return (
    <div>
      {edit ? (
        <Formik
          initialValues={{ nameInput: name || '' }}
          validate={validate}
          onSubmit={(values) => handleOkClick(values)}
        >
          {({ values, handleChange, errors }) => (
            <Form>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Field
                  type='text'
                  id='nameInput'
                  name='nameInput'
                  as={TextField}
                  value={values.nameInput}
                  onChange={handleChange}
                  style={{ marginRight: '4px', marginLeft: '4px' }}
                  placeholder="Tactic's name"
                  error={Boolean(errors.nameInput)}
                />
                <IconButton
                  type='submit'
                  color='primary'
                  style={{
                    position: 'absolute',
                    right: '35px',
                    cursor: 'pointer',
                  }}
                >
                  <CheckIcon />
                </IconButton>
                <IconButton
                  type='button'
                  color='secondary'
                  onClick={handleCancelClick}
                  style={{
                    right: '5px',
                    position: 'absolute',
                    cursor: 'pointer',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div>
          <span>{name}</span>
          {!viewOnly &&
            Children.map(children, (child) => {
              if (child?.props.title === 'Edit') {
                return cloneElement(child, { onClick: handleEditClick })
              }
              if (child?.props.title === 'Delete') {
                return cloneElement(child)
              }
              return cloneElement(child, { style: { top: '10px', right: '10px' } })
            })}
        </div>
      )}
    </div>
  )
}

NewAccordionChild.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  onUpdateName: PropTypes.func,
  onDeleteChild: PropTypes.func,
  viewOnly: PropTypes.bool,
}

NewAccordionChild.defaultProps = {
  label: null,
  viewOnly: false,
}

export default NewAccordionChild
