import React from 'react'
import { makeStyles } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import COLORS from '../../utils/constants/colors'

const MyCloseCircleIcon = (props) => {
  const useStyles = makeStyles({
    leftIcon: {
      cursor: 'pointer',
      fill: COLORS.lightBlue,
    },
  })
  const classes = useStyles()

  return <HighlightOffIcon {...props} classes={{ root: classes.leftIcon }} />
}

export default MyCloseCircleIcon
