import { SPACING, TYPOGRAPHY } from './generalPdfStyles'

const practicePlanPdfStyles = {
  headerLogo: {
    alignment: 'right',
    marginTop: SPACING.M,
    marginRight: SPACING.M,
  },
  headerLogoTitle: {
    marginTop: SPACING.L,
    marginLeft: SPACING.M,
    fontSize: TYPOGRAPHY.XXL,
  },
  drillsWrapper: {
    marginTop: SPACING.L,
  },
  minutesText: {
    margin: [SPACING.M, SPACING.L, 0, 0],
  },
  minutesTableNumber: {
    fontSize: TYPOGRAPHY.XS,
    bold: true,
  },
  minutesTableText: {
    fontSize: TYPOGRAPHY.XXXS,
  },
  notesText: {
    fontSize: TYPOGRAPHY.XXS,
    alignment: 'center',
  },
  titleText: {
    alignment: 'right',
    bold: true,
    fontSize: TYPOGRAPHY.S,
  },
  descriptionText: {
    margin: [0, 0, 0, 5],
    fontSize: TYPOGRAPHY.XXS,
    alignment: 'justify',
  },
  chipRow: {
    marginTop: SPACING.S,
    marginBottom: SPACING.S,
    alignment: 'center',
  },
  drillComponentDescription: {
    margin: [SPACING.M, SPACING.XS, SPACING.M, SPACING.S],
    fontSize: TYPOGRAPHY.XS,
    alignment: 'center',
  },
}

export default practicePlanPdfStyles
