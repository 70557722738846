import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import eventFormCourtLogosStyles from './eventFormCourtLogos.styles'
import court from '../../assets/images/court.png'
import ImageCropWithEdit from '../ui/ImageCropComponents/ImageCropWithEdit/ImageCropWithEdit'
import useResize from '../../hooks/useResize'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import GAME_EVENT_FORM_BROADCAST_FIELDS from '../../utils/constants/gameEventFormBroadcastFields'
import CourtLogosPreviewEditBox from '../CourtLogosPreviewEditBox/CourtLogosPreviewEditBox'
import CROPPER_SHAPES from '../../utils/constants/cropperShapes'
import { isTeamLogoDisabled } from '../../utils/helpers/isFieldDisabled'
import LIVESTREAM_TYPE_OPTIONS from '../../utils/constants/livestreamTypeOptions'
import MyUploadFilesField from '../form/fields/MyUploadFilesField/MyUploadFilesField'

const EventFormCourtLogos = ({ state, setMediaIsUploading, selectedEventType }) => {
  const { values, setFieldValue } = useFormikContext()
  const imageRef = useRef(null)
  const { width } = useResize(imageRef)
  const useStyles = makeStyles(
    eventFormCourtLogosStyles(width, values[GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_CENTER_LOGO]),
  )
  const classes = useStyles()

  const setCroppedImage = (name, value) => setFieldValue(name, value)

  const isLivestreamTypeFull =
    (values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST] ||
      values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_RECORD]) &&
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.TYPE] === LIVESTREAM_TYPE_OPTIONS.LIVESTREAM_FULL

  return (
    <ConditionalRender renderValue={isLivestreamTypeFull}>
      <div className={classes.wrapper}>
        {selectedEventType?.code === 'game' && (
          <MyUploadFilesField
            label='Upload MP4 Video'
            maxVideoNum={3}
            acceptedTypes='.mp4'
            setMediaIsUploading={setMediaIsUploading}
          />
        )}

        <div className={classes.courtWrapper}>
          <img className={classes.courtImg} src={court} alt='img' ref={imageRef} />

          <ImageCropWithEdit
            defaultImageSrc={values[GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_TOP_LEFT_LOGO]}
            setCroppedImage={(croppedImage) =>
              setCroppedImage(GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_TOP_LEFT_LOGO, croppedImage)
            }
            aspect={4 / 3}
            minZoom={0.2}
            cropShape={CROPPER_SHAPES.RECT}
            dialogBottomText='Proposed Width: 300-1500 pixels'
            isDisabled={isTeamLogoDisabled(state)}
            EditPreviewComponent={CourtLogosPreviewEditBox}
            editPreviewComponentClassName={classes.editBoxTopLeft}
          />

          <ImageCropWithEdit
            defaultImageSrc={values[GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_TOP_RIGHT_LOGO]}
            setCroppedImage={(croppedImage) =>
              setCroppedImage(GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_TOP_RIGHT_LOGO, croppedImage)
            }
            aspect={4 / 3}
            minZoom={0.2}
            cropShape={CROPPER_SHAPES.RECT}
            dialogBottomText='Proposed Width: 300-1500 pixels'
            isDisabled={isTeamLogoDisabled(state)}
            EditPreviewComponent={CourtLogosPreviewEditBox}
            editPreviewComponentClassName={classes.editBoxTopRight}
          />

          <ImageCropWithEdit
            defaultImageSrc={values[GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_BOTTOM_LEFT_LOGO]}
            setCroppedImage={(croppedImage) =>
              setCroppedImage(GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_BOTTOM_LEFT_LOGO, croppedImage)
            }
            aspect={4 / 3}
            minZoom={0.2}
            cropShape={CROPPER_SHAPES.RECT}
            dialogBottomText='Proposed Width: 300-1500 pixels'
            isDisabled={isTeamLogoDisabled(state)}
            EditPreviewComponent={CourtLogosPreviewEditBox}
            editPreviewComponentClassName={classes.editBoxBottomLeft}
          />

          <ImageCropWithEdit
            defaultImageSrc={values[GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_BOTTOM_RIGHT_LOGO]}
            setCroppedImage={(croppedImage) =>
              setCroppedImage(
                GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_BOTTOM_RIGHT_LOGO,
                croppedImage,
              )
            }
            aspect={4 / 3}
            minZoom={0.2}
            cropShape={CROPPER_SHAPES.RECT}
            dialogBottomText='Proposed Width: 300-1500 pixels'
            isDisabled={isTeamLogoDisabled(state)}
            EditPreviewComponent={CourtLogosPreviewEditBox}
            editPreviewComponentClassName={classes.editBoxBottomRight}
          />

          <ImageCropWithEdit
            defaultImageSrc={values[GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_CENTER_LOGO]}
            setCroppedImage={(croppedImage) =>
              setCroppedImage(GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_CENTER_LOGO, croppedImage)
            }
            dialogBottomText='Proposed Width: 300-1500 pixels'
            isDisabled={isTeamLogoDisabled(state)}
            EditPreviewComponent={CourtLogosPreviewEditBox}
            editPreviewComponentClassName={classes.editBoxCenter}
          />
        </div>
      </div>
    </ConditionalRender>
  )
}

EventFormCourtLogos.propTypes = {
  state: PropTypes.string,
  selectedEventType: PropTypes.oneOfType([PropTypes.object]),
  setMediaIsUploading: PropTypes.func,
}

export default EventFormCourtLogos
