import { useMutation, useQuery, useQueryClient } from 'react-query'
import QueryKeys from '../utils/constants/queryKeys'
import getOrg from '../Apis/organisation/getOrg'
import getCountries from '../Apis/organisation/getCountries'
import updateOrg from '../Apis/organisation/updateOrg'
import useQueryParams from './useQueryParams'
import getOrgs from '../Apis/organisation/getOrgs'
import localStorageHelper from '../utils/helpers/localStorageHelper'
import useHistoryPush from './useHistoryPush'
import redirectToHomeHelper from '../utils/helpers/redirectToHomeHelper'
import localStorageIds from '../utils/constants/localStorageIds'
import deleteOrg from '../Apis/organisation/deleteOrg'

const useOrgSettings = () => {
  const queryParams = useQueryParams()
  const { org: orgId } = queryParams
  const { redirect } = useHistoryPush()
  const queryClient = useQueryClient()
  const orgLocalStorageHelper = localStorageHelper(localStorageIds.SELECTED_ORG)
  const teamLocalStorageHelper = localStorageHelper(localStorageIds.SELECTED_TEAM)

  const allOrgs = useQuery([QueryKeys.GET_ORGS], () => getOrgs())

  const singleOrg = useQuery([QueryKeys.GET_SINGLE_ORG, orgId], () => orgId && getOrg(orgId))

  const countries = useQuery([QueryKeys.GET_COUNTRIES], () => getCountries())

  const deleteTheOrg = useMutation((data) => deleteOrg(data), {
    onSuccess: (data, { orgId }) => {
      const orgsWithoutDeletedOrg = allOrgs.data.filter((c) => c.id !== orgId)

      if (orgsWithoutDeletedOrg?.length > 0) {
        const orgToRedirect = orgsWithoutDeletedOrg[0]
        const { teams } = orgToRedirect || {}

        orgLocalStorageHelper.setLocalStorageValue(orgToRedirect)
        queryClient.invalidateQueries(QueryKeys.GET_TEAMS)
        queryClient.invalidateQueries(QueryKeys.GET_ORGS)
        queryClient.invalidateQueries(QueryKeys.GET_ACCOUNT_ME)

        return redirectToHomeHelper(teams, redirect, orgToRedirect)
      }

      orgLocalStorageHelper.removeLocalStorageValue()
      teamLocalStorageHelper.removeLocalStorageValue()
      queryClient.invalidateQueries(QueryKeys.GET_ORGS)
      return redirect('/', [], ['org', 'team'])
    },
  })

  const updateTheOrg = useMutation((data) => updateOrg(data), {
    onSuccess: (res) => {
      orgLocalStorageHelper.setLocalStorageValue(res.data)
      queryClient.invalidateQueries(QueryKeys.GET_SINGLE_ORG)
      queryClient.invalidateQueries(QueryKeys.GET_ORGS)
    },
  })

  return {
    singleOrg: singleOrg?.data,
    countries: countries?.data,
    deleteOrg: deleteTheOrg,
    updateOrg: updateTheOrg,
  }
}

export default useOrgSettings
