import drillPdfHeader from '../../components/PdfDownloadButton/header/drillPdfHeader'
import drillPdfStyles from '../../components/PdfDownloadButton/styles/drillPdfStyles'
import drillPdfContent from '../../components/PdfDownloadButton/content/drillPdfContent'
import playbookPdfHeader from '../../components/PdfDownloadButton/header/playbookPdfHeader'
import playbookPdfContent from '../../components/PdfDownloadButton/content/playbookPdfContent'
import playbookPdfStyles from '../../components/PdfDownloadButton/styles/playbookPdfStyles'
import playPdfContent from '../../components/PdfDownloadButton/content/playPdfContent'
import PDF_VARIANTS from './pdfVariants'
import playbookPdfFooter from '../../components/PdfDownloadButton/footer/playbookPdfFooter'
import practicePlanPdfHeader from '../../components/PdfDownloadButton/header/practicePlanPdfHeader'
import practicePlanPdfContent from '../../components/PdfDownloadButton/content/practicePlanPdfContent'
import playPdfStyles from '../../components/PdfDownloadButton/styles/playPdfStyles'
import practicePlanPdfStyles from '../../components/PdfDownloadButton/styles/practicePlanPdfStyles'
import practicePlanPdfFooter from '../../components/PdfDownloadButton/footer/practicePlanPdfFooter'
import practicePlanWithPlayersPdfContent from '../../components/PdfDownloadButton/content/practicePlanWithPlayersPdfContent'

const PDF_MAPPER = {
  [PDF_VARIANTS.DRILL]: {
    header: drillPdfHeader,
    content: drillPdfContent,
    footer: '',
    styles: drillPdfStyles,
    pageMargins: [40, 50, 40, 40],
  },
  [PDF_VARIANTS.PLAY]: {
    header: drillPdfHeader,
    content: playPdfContent,
    footer: '',
    styles: playPdfStyles,
    pageMargins: [40, 50, 40, 40],
  },
  [PDF_VARIANTS.PLAYBOOK]: {
    header: playbookPdfHeader,
    footer: playbookPdfFooter,
    content: playbookPdfContent,
    styles: playbookPdfStyles,
    pageMargins: [40, 40, 40, 40],
  },
  [PDF_VARIANTS.PRACTICE_PLAN]: {
    header: practicePlanPdfHeader,
    content: practicePlanPdfContent,
    footer: practicePlanPdfFooter,
    styles: practicePlanPdfStyles,
    pageMargins: [10, 50, 10, 40],
  },
  [PDF_VARIANTS.PRACTICE_PLAN_WITH_PLAYERS]: {
    header: practicePlanPdfHeader,
    content: practicePlanWithPlayersPdfContent,
    footer: practicePlanPdfFooter,
    styles: practicePlanPdfStyles,
    pageMargins: [10, 50, 10, 40],
  },
}

export default PDF_MAPPER
