import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Chip, makeStyles, Avatar, Card, CardContent, Typography } from '@material-ui/core'
import playersListItemStyles from './playersListItemsStyles'

const PlayerListItem = ({ player, onSelect, checked }) => {
  const useStyles = makeStyles(playersListItemStyles)

  const classes = useStyles()
  const [clicked, setClicked] = useState(false)

  const positionNames = player.personalData.positions?.map((position) => position.name) || []

  const handleClick = (selectedPlayer) => {
    onSelect(selectedPlayer)
    setClicked(true)
    setTimeout(() => {
      setClicked(false)
    }, 300)
  }

  return (
    <Card
      className={`${classes.card} ${checked ? classes.selectedCard : ''} ${
        clicked ? classes.clickedCard : ''
      }`}
      onClick={() => handleClick(player)}
    >
      {player.logoUrl && (
        <Avatar className={classes.avatar} alt='Player Avatar' src={player.logoUrl || ''} />
      )}

      <CardContent className={classes.content} style={player.logoUrl ? { paddingLeft: 0 } : {}}>
        <Typography variant='body1' className={classes.playerName}>
          {`${player.personalData.firstName} ${player.personalData.lastName}`}
        </Typography>
        <div className={classes.chipContainer}>
          {positionNames.map((positionName, index) => (
            <Chip
              key={positionName + { index }}
              label={positionName}
              size='small'
              className={classes.chip}
            />
          ))}
        </div>
      </CardContent>
    </Card>
  )
}

PlayerListItem.propTypes = {
  player: PropTypes.shape({
    id: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
    selected: PropTypes.bool,
    personalData: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      positions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  checked: PropTypes.bool,
}

export default PlayerListItem
