import axios from 'axios'
import decodeJwt from 'jwt-decode'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'
import localStorageIds from '../../utils/constants/localStorageIds'
import localStorageHelper from '../../utils/helpers/localStorageHelper'
import {
  removeLocalStorageValue,
  setLocalStorageValue,
} from '../../utils/helpers/localStorageHelperMethods'

const authProvider = {
  // called when the user attempts to log in
  login: async (values) => {
    const url = `${BASE_URL}/auth/login`

    return axios
      .post(url, values, {
        headers: generalHeader(),
        withCredentials: true,
      })
      .then(({ data }) => {
        const { orgScopes, orgRoles, Subscriptions, isSuperAdmin } = decodeJwt(data?.accessToken)
        // Set localStorage values
        setLocalStorageValue([
          { id: localStorageIds.TOKEN, value: data.accessToken },
          { id: localStorageIds.SCOPES, value: orgScopes },
          { id: localStorageIds.USER_ORG_ROLES, value: orgRoles },
          { id: localStorageIds.SUBSCRIPTIONS, value: Subscriptions },
          { id: localStorageIds.INVITATIONS_SNACK_OPEN, value: false },
          {
            id: localStorageIds.IS_SUPER_ADMIN,
            value: JSON.parse(isSuperAdmin?.toLowerCase()),
          },
        ])

        removeLocalStorageValue([localStorageIds.USER_INITIAL_UNCONFIRMED_EMAIL])

        // PostHog analytics. Link the anonymous ID and userId together.
        if (process.env.REACT_APP_RUNTIME === 'PROD') {
          posthog.identify(data?.userId)
        }

        // Dispatching for the components that listening storage event.
        window.dispatchEvent(new Event('storage'))

        return Promise.resolve()
      })
      .catch((error) => {
        const { data, status } = error?.response || {}

        if (status === 401 && data === 'Unconfirmed email') {
          setLocalStorageValue([
            { id: localStorageIds.USER_INITIAL_UNCONFIRMED_EMAIL, value: values.username },
          ])
          return Promise.resolve()
        }

        return Promise.reject(data)
      })
  },

  // Method to refresh the access token
  refreshAccessToken: async () => {
    const url = `${BASE_URL}/auth/refresh-token`

    return axios
      .get(url, {
        withCredentials: true,
        headers: generalHeader(),
      })
      .then(({ data }) => {
        // Update the token in localStorage
        setLocalStorageValue([{ id: localStorageIds.TOKEN, value: data?.accessToken }])
        return data?.accessToken
      })
      .catch((error) => {
        removeLocalStorageValue([localStorageIds.TOKEN, localStorageIds.SCOPES])
        return Promise.reject(error)
      })
  },

  // called when the user clicks on the logout button
  logout: () => {
    removeLocalStorageValue([localStorageIds.TOKEN, localStorageIds.SCOPES, 'permissions'])

    return Promise.resolve()
  },

  // called when the API returns an error
  checkError: async ({ status }) => {
    if (status === 401) {
      try {
        await authProvider.refreshAccessToken()
        return Promise.resolve()
      } catch {
        localStorage.removeItem(localStorageIds.TOKEN)
        return Promise.reject() // Token refresh failed, reject promise
      }
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const { localStorageValue: userInitialUnconfirmedEmail } = localStorageHelper(
      localStorageIds.USER_INITIAL_UNCONFIRMED_EMAIL,
    )

    if (userInitialUnconfirmedEmail) {
      return Promise.reject({
        redirectTo: `send-confirmation-email?initialEmail=${userInitialUnconfirmedEmail}`,
      })
    }

    return localStorage.getItem(localStorageIds.TOKEN) ? Promise.resolve() : Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / scopes
  getPermissions: () => {
    const { localStorageValue: scopes } = localStorageHelper(localStorageIds.SCOPES)

    const userScopes = localStorage.getItem(localStorageIds.SCOPES) !== 'undefined' ? scopes : null
    return userScopes?.length ? Promise.resolve(userScopes) : Promise.reject()
  },
}

export default authProvider
