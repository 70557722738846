const COLORS = {
  white: '#FFFFFF',
  backgroundGrey: '#F5F5F5',
  borderGrey: '#D7D7D7',
  borderGreyDark: '#d2d2d2',
  grey: '#757575',
  lightGrey: '#808080',
  hoverGrey: '#cdcbcb2b',
  menuChoiceGrey: '#555555',
  searchGrey: '#e4e4e4',
  lightBlack: '#181818',
  mediumDarkGrey: 'rgba(0, 0, 0, 0.49)',
  lightDarkGrey: 'rgba(0, 0, 0, 0.38)',
  darkGrey: 'rgba(0, 0, 0, 0.55)',
  black: '#000000',

  lightOrange: '#e0726e29',
  salmon: '#f9e3e2',
  orange: '#E0726E',
  darkerOrange: '#EF6C00',
  darkOrange: '#ffc107',
  blue: '#096BDE',
  subscriptionBlue: '#2196F3',
  lightBlue: '#2fb8d4',
  opacBlue: '#F4FAFF',
  lightGreen: '#83B061',
  green: '#66bb6a',

  lightRed: '#bf360c',
  red: '#C62828',
  errorRed: '#f44336',

  purple: '#9077E7',
  darkYellow: '#FFBC7D',
  bisque: '#fad3b2',
}
export default COLORS
