import React from 'react'
import PropTypes from 'prop-types'
import PracticeBox from '../PracticeBox/PracticeBox'
import practiceType from '../../types/practiceType'

const Practices = (props) => {
  const { practices, setPracticePlansModal, setPracticeAccordionPressed } = props

  return practices && practices.length > 0
    ? practices
        .sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime))
        .map((practice) => (
          <PracticeBox
            key={practice.id}
            practice={practice}
            setPracticePlansModal={setPracticePlansModal}
            setPracticeAccordionPressed={setPracticeAccordionPressed}
          />
        ))
    : null
}

Practices.propTypes = {
  practices: PropTypes.arrayOf(practiceType),
  setPracticePlansModal: PropTypes.func,
  setPracticeAccordionPressed: PropTypes.func,
}

export default Practices
