import { shape, string, bool } from 'prop-types'
import courtCameraType from './courtCameraType'

const courtType = shape({
  address: string,
  camera: courtCameraType,
  orgId: string,
  courtConfigurationId: string,
  description: string,
  id: string,
  livestreamEnabled: bool,
  name: string,
  networkHardwareInstalled: string,
  publicIp: string,
})

export default courtType
