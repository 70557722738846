import { useState, useEffect } from 'react'

/* 
draggableRef: the ref that indicates the object that is draggable
type: the elements with a specific tagName that equals to the given type that are use for the dragging
startingX: The starting x position
startingY: The starting y position
*/

const useDrag = (draggableRef, type = null, startingX, startingY) => {
  const [{ dx, dy }, setOffset] = useState({ dx: startingX ?? 0, dy: startingY ?? 0 })

  const findChildrenByType = (parentElement) => {
    // Use querySelectorAll to find all children elements of the specified type
    const children = parentElement?.querySelectorAll(type) || []

    return Array.from(children)
  }

  useEffect(() => {
    const elRect = draggableRef?.current?.getBoundingClientRect()
    const bodyRect = document.body.getBoundingClientRect()

    const handleMouseDown = (e) => {
      e.preventDefault()

      const rect = draggableRef?.current.getBoundingClientRect()
      const offsetX = e.pageX - rect.left
      const offsetY = e.pageY - rect.top

      const handleMouseMove = (e) => {
        e.preventDefault()

        const getX = () => {
          const x = e.pageX - offsetX
          if (x >= 0 && x + elRect.width <= bodyRect.width) {
            return x
          } else if (x < 0) {
            return 0
          } else {
            return bodyRect.width - elRect.width
          }
        }

        const getY = () => {
          const y = e.pageY - offsetY
          if (y >= 0 && y + elRect.height <= bodyRect.height) {
            return y
          } else if (y < 0) {
            return 0
          } else {
            return bodyRect.height - elRect.height
          }
        }

        const newDx = getX()
        const newDy = getY()
        setOffset({ dx: newDx, dy: newDy })
      }

      document.addEventListener('mousemove', handleMouseMove)

      document.addEventListener(
        'mouseup',
        () => {
          document.removeEventListener('mousemove', handleMouseMove)
        },
        { once: true },
      )
    }
    if (type) {
      const allStrongElements = findChildrenByType(draggableRef?.current)

      allStrongElements.forEach((strongElement) => {
        strongElement.addEventListener('mousedown', handleMouseDown)
      })

      return () => {
        allStrongElements.forEach((strongElement) => {
          strongElement.removeEventListener('mousedown', handleMouseDown)
        })
      }
    }

    draggableRef?.current.addEventListener('mousedown', handleMouseDown)
    return () => {
      draggableRef?.current.removeEventListener('mousedown', handleMouseDown)
    }
  }, [dx, dy, draggableRef])

  useEffect(() => {
    if (draggableRef?.current) {
      draggableRef.current.style.transform = `translate3d(${dx}px, ${dy}px, 0)`
    }
  }, [dx, dy, draggableRef])

  return { dx, dy }
}

export default useDrag
