import COLORS from '../../utils/constants/colors'

const drillSliderDownloadImageStyles = {
  downloadIcon: {
    fill: COLORS.white,
    backgroundColor: COLORS.grey,
    padding: 4,
    borderRadius: 100,
    zIndex: 1000,
  },
}

export default drillSliderDownloadImageStyles
