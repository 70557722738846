import * as React from 'react'
import { NumberInput } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import NUMBER_INPUT_STYLES from '../../../utils/constants/CSS/numberInputStyles'

const MyNumberInput = (props) => {
  const useStyles = makeStyles(NUMBER_INPUT_STYLES)
  const classes = useStyles()

  return (
    <NumberInput
      {...props}
      fullWidth
      autoComplete='off'
      margin='none'
      InputProps={{
        classes: {
          root: classes.root,
          input: classes.input,
        },
        disableUnderline: true,
      }}
      InputLabelProps={{
        classes: {
          error: classes.error,
          shrink: classes.shrink,
          formControl: classes.formControl,
        },
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperTextRoot,
        },
      }}
    />
  )
}

export default MyNumberInput
