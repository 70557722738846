import { parseTeams } from '../parseTeams'

const parseEventFormSubmit = (data) => {
  const {
    startDateTime,
    endDateTime,
    fromDate,
    startTime,
    endTime,
    teams,
    isAdditionalYoutubeStreamKeyAdded,
    ...rest
  } = data

  // When editing event, the date is not of type Date, but string. So we turn it to date to make sure.
  const noSecondsStart = new Date(startTime || startDateTime).setSeconds(0, 0)
  const noSecondsEnd = new Date(new Date(endTime || endDateTime).setSeconds(0, 0))

  return {
    teamIds: parseTeams(teams),
    eventTeams:
      data.eventTeams ||
      teams.map((team) => ({
        teamId: team.id || team.teamId || null,
        threeLetterIdentifier: team.threeLetterIdentifier || null,
        name: team.name || team,
        logoUrl: team.teamLogo || null,
      })),
    startDateTime: new Date(noSecondsStart),
    endDateTime: new Date(noSecondsEnd),
    ...rest,
  }
}

export default parseEventFormSubmit
