import React, { useState } from 'react'
import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { useQuery, useQueryClient } from 'react-query'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import selectOrgStyles from './selectOrg.styles'
import ExpandIcon from '../../assets/icons/expandIcon'
import SelectRenderValue from '../SelectRenderValue/SelectRenderValue'
import QueryKeys from '../../utils/constants/queryKeys'
import getOrgs from '../../Apis/organisation/getOrgs'
import useQueryParams from '../../hooks/useQueryParams'
import useHistoryPush from '../../hooks/useHistoryPush'
import redirectToHomeHelper from '../../utils/helpers/redirectToHomeHelper'
import AddOrgModal from '../AddOrgModal/AddOrgModal'
import AddButton from '../ui/AddButton/AddButton'
import localStorageIds from '../../utils/constants/localStorageIds'
import sortArrayOfObjectsByName from '../../utils/helpers/sortArrayOfObjectsByName'
import useGetLocalStorage from '../../hooks/useGetLocalStorage'
import { setLocalStorageValue } from '../../utils/helpers/localStorageHelperMethods'

const SelectOrg = () => {
  const [org, setOrg] = useState('')
  const [orgs, setOrgs] = useState('')
  const [loadOrgs, setLoadOrgs] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const queryParams = useQueryParams()
  const queryClient = useQueryClient()
  const translate = useTranslate()
  const { redirect } = useHistoryPush()
  const localStorageValue = useGetLocalStorage()
  const { [localStorageIds.TOKEN]: token, [localStorageIds.SELECTED_ORG]: selectedOrg } =
    localStorageValue || {}
  const useStyles = makeStyles(selectOrgStyles)
  const classes = useStyles()

  // TODO: Select org/team avoid double request if possible
  const allOrgs = useQuery([QueryKeys.GET_ORGS, token], () => getOrgs(), {
    enabled: loadOrgs,
    onSuccess: (orgs) => {
      const oldUrlOrgId = queryParams?.org || selectedOrg?.id
      const newOrg = orgs?.find((c) => c.id === oldUrlOrgId)
      setOrgs(orgs)
      setLoadOrgs(false)
      setOrg(newOrg ?? (orgs?.length ? orgs[0] : undefined))
    },
  })

  const handleChange = (e) => {
    if (e.target.value === 'addOrg') {
      setShowModal(true)
    }

    if (orgs && orgs.length) {
      orgs.map((c) => {
        if (c.id === e.target.value.id) {
          const { teams } = c || {}

          setOrg(c)
          setLocalStorageValue([{ id: localStorageIds.SELECTED_ORG, value: c }])
          queryClient.invalidateQueries(QueryKeys.GET_TEAMS)

          return redirectToHomeHelper(teams, redirect, c)
        }

        return null
      })
    }
  }

  const handleOpen = () => {
    allOrgs.refetch()
  }

  const orderedOrgs = orgs
    ? sortArrayOfObjectsByName(
        orgs.filter((cb) => cb?.id !== org?.id),
        'name',
        'DESC',
      )
    : []
  orderedOrgs?.unshift(org)

  return (
    <>
      {orgs && orgs.length ? (
        <FormControl variant='filled'>
          <Select
            classes={{ select: classes.select, filled: classes.selectInput }}
            disableUnderline
            IconComponent={ExpandIcon}
            name='selectOrg'
            value={org}
            onChange={handleChange}
            onOpen={handleOpen}
            renderValue={(item) => (
              <SelectRenderValue item={item} chosenOption={org} logoArgName='logoUrl' labelBlack />
            )}
            style={{
              height: '40px',
              backgroundColor: '#f5f5f5',
              width: '200px',
              borderRadius: '5px',
            }}
          >
            {orderedOrgs?.map((item) => (
              <MenuItem
                key={item.id}
                value={item}
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.menuItemSelected,
                }}
              >
                <SelectRenderValue item={item} logoArgName='logoUrl' chosenOption={org} />
              </MenuItem>
            ))}
            <MenuItem value='addOrg' classes={{ root: classes.menuItemRoot }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AddCircleIcon
                  style={{ width: '30', height: '30', marginRight: 10, fill: '#E0726E' }}
                />
                <span style={{ color: '#555555', fontSize: '90%' }}>
                  {translate('ra.buttons.addOrg')}
                </span>
              </div>
            </MenuItem>
          </Select>
        </FormControl>
      ) : (
        <AddButton
          onClick={() => setShowModal(true)}
          label={translate('ra.buttons.addOrg')}
          className={classes.addButton}
        />
      )}
      <AddOrgModal isOpen={showModal} onClose={() => setShowModal(false)} />
    </>
  )
}
export default SelectOrg
