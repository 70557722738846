const notificationListStyles = {
  notificationListItem: {
    borderRadius: '4px',
    border: '1px solid #e0e0e0',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s ease',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2px',
  },
  unread: {
    backgroundColor: '#f5f5f5',
  },
  read: {
    backgroundColor: 'transparent',
  },
  hover: {
    '&:hover': {
      backgroundColor: '#d0d0d0 !important',
    },
  },
  notificationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  notificationType: {
    fontWeight: 'bold',
    marginRight: 'auto',
    marginLeft: '4px',
    fontSize: 'medium',
  },
  notificationContent: {
    display: 'flex',
    alignItems: 'center',
  },
  dateTime: {
    fontSize: 'x-small',
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: 'small',
  },
  bullet: {
    width: '7px',
    height: '7px',
    backgroundColor: '#e0726e',
    borderRadius: '50%',
  },
  primaryText: {
    fontWeight: 'bold',
    fontSize: 'small',
  },
  secondaryText: {
    fontWeight: 'normal',
    fontSize: 'small',
  },
}

export default notificationListStyles
