import * as React from "react";
import { makeStyles } from "@material-ui/core";
import COLORS from "../../utils/constants/colors";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";

const MyFlightTakeoffIcon = props => {
  const useStyles = makeStyles({
    addIcon: {
      width: 22,
      height: 22,
      fill: COLORS.grey
    }
  });
  const classes = useStyles();

  return (
    <FlightTakeoffIcon {...props} classes={{ root: classes.addIcon }} />
  );
};

export default MyFlightTakeoffIcon;
