import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import QueryKeys from '../utils/constants/queryKeys'
import getOrg from '../Apis/organisation/getOrg'
import useQueryParams from './useQueryParams'
import getGenderSpec from '../Apis/team/getGenderSpec'
import getAgeRestriction from '../Apis/team/getAgeRestriction'
import getTeams from '../Apis/team/getTeams'
import addTeam from '../Apis/team/addTeam'
import updateTeam from '../Apis/team/updateTeam'
import deleteTeam from '../Apis/team/deleteTeam'
import getSingleTeam from '../Apis/team/getSingleTeam'
import localStorageHelper from '../utils/helpers/localStorageHelper'
import getCountries from '../Apis/organisation/getCountries'
import useHistoryPush from './useHistoryPush'
import localStorageIds from '../utils/constants/localStorageIds'

const useTeamsSettings = (selectedTeam) => {
  const [showAddTeamModal, setShowAddTeamModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isUpdateSnackbarOpen, setIsUpdateSnackbarOpen] = useState(false)
  const queryParams = useQueryParams()
  const { org } = queryParams
  const queryClient = useQueryClient()
  const { redirect } = useHistoryPush()
  const teamLocalStorageHelper = localStorageHelper(localStorageIds.SELECTED_TEAM)

  const countries = useQuery([QueryKeys.GET_COUNTRIES], () => getCountries())

  const singleOrg = useQuery([org || null], () => org && getOrg(org))

  const teams = useQuery([QueryKeys.GET_TEAMS, org || null], () => org && getTeams(org))

  const team = useQuery(
    [QueryKeys.GET_SINGLE_TEAM, selectedTeam],
    () => selectedTeam && getSingleTeam(selectedTeam),
  )

  const gender = useQuery([QueryKeys.GET_GENDER_SPEC], () => getGenderSpec())

  const ageRestriction = useQuery([QueryKeys.GET_AGE_RESTRICTION], () => getAgeRestriction())

  const addATeam = useMutation((data) => addTeam(data), {
    onSuccess: ({ data }) => {
      setShowAddTeamModal(false)
      teamLocalStorageHelper.setLocalStorageValue(data)
      return queryClient.invalidateQueries(QueryKeys.GET_TEAMS)
    },
  })

  const updateATeam = useMutation((data) => updateTeam(data), {
    onSuccess: (data) => {
      setIsUpdateSnackbarOpen(true)
      teamLocalStorageHelper.setLocalStorageValue(data)
      queryClient.invalidateQueries(QueryKeys.GET_SINGLE_TEAM)
      queryClient.invalidateQueries(QueryKeys.GET_TEAMS)
    },
  })

  const deleteATeam = useMutation((data) => deleteTeam(data), {
    onSuccess: () => {
      setShowDeleteModal(false)
      queryClient.invalidateQueries(QueryKeys.GET_TEAMS)
      queryClient.invalidateQueries(QueryKeys.GET_ORGS)

      // When deleting last org.
      if (teams?.data?.length === 1) {
        teamLocalStorageHelper.removeLocalStorageValue()
        redirect('/calendar', {}, ['team'])
      }
    },
  })

  return {
    countries: countries?.data,
    singleOrgLogo: singleOrg?.data?.logoUrl,
    singleOrgName: singleOrg?.data?.name,
    orgId: singleOrg?.data?.id,
    teams:
      teams?.data?.length > 0 &&
      teams?.data?.sort((a, b) =>
        a.name.toUpperCase() > b.name.toUpperCase()
          ? 1
          : b.name.toUpperCase() > a.name.toUpperCase()
            ? -1
            : 0,
      ),
    team: team?.data,
    gender: gender?.data,
    ageRestriction: ageRestriction?.data,
    addTeam: addATeam,
    updateTeam: updateATeam,
    deleteTeam: deleteATeam,
    showAddTeamModal,
    setShowAddTeamModal,
    showDeleteModal,
    setShowDeleteModal,
    isUpdateSnackbarOpen,
    setIsUpdateSnackbarOpen,
  }
}

export default useTeamsSettings
