import React from 'react'
import PropTypes from 'prop-types'
import './loaderCircle.css'

const LoaderCircle = ({ loading, color }) =>
  loading ? (
    <span className='loader' style={{ borderBottomColor: color }} />
  ) : (
    <span className='fill-space' />
  )

LoaderCircle.propTypes = {
  loading: PropTypes.bool,
  color: PropTypes.string,
}

export default LoaderCircle
