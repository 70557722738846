import COLORS from '../../utils/constants/colors'

const drillsSliderPositionsStyles = (isZoomedIn) => ({
  positionsWrapper: {
    position: 'absolute',
    left: !isZoomedIn ? '29%' : '12%',
    bottom: !isZoomedIn ? '8%' : '10%',
    display: 'flex',
    gap: '5px',
  },
  avatarRoot: {
    width: '30px',
    height: '30px',
    fontSize: '14px',
    fontWeight: 500,
    color: COLORS.black,
    backgroundColor: COLORS.white,
  },
})

export default drillsSliderPositionsStyles
