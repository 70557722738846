import { addDays, isAfter, isSameDay } from 'date-fns'

const parseEndDateTimeNextDay = (startDate, endDate) => {
  if (isSameDay(startDate, endDate) && isAfter(startDate, endDate)) {
    return addDays(endDate, 1)
  }

  return endDate
}

export default parseEndDateTimeNextDay
