import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import { useQuery } from 'react-query'
import DrillCreateForm from '../DrillCreateForm/DrillCreateForm'
import usePracticePlanQueries from '../../../hooks/usePracticePlanQueries'
import DrillCreateFormEditDrillModal from '../DrillCreateFormEditDrillModal/DrillCreateFormEditDrillModal'
import QueryKeys from '../../../utils/constants/queryKeys'
import getUserData from '../../../Apis/user/getUserData'

const DrillCreateFormModal = (props) => {
  const { open, onClose, clickedComponent, setClickedComponent } = props
  const {
    trainingModule,
    createDrill,
    updateDrill,
    deleteDrill,
    isFetching,
    isMutating,
    shouldUpdateDrillGlobally,
    setShouldUpdateDrillGlobally,
    isTrainingModuleUpdatedDirty,
    type,
  } = usePracticePlanQueries({
    clickedComponent,
    closeDrillCreateModal: onClose,
    setClickedComponent,
  })
  const me = useQuery([QueryKeys.GET_ACCOUNT_ME], () => getUserData())
  const isCurrentUserDrillCreator = me?.data?.id === trainingModule?.creator.id
  const [isUpdateDrillModalOpen, setIsUpdateDrillModalOpen] = useState(null)

  // Show modal if play can be updated globally.
  useEffect(() => {
    // If user is not the creator of the TM, update only in PP or playbook automatically.
    if (!isCurrentUserDrillCreator || trainingModule?.originalCreatorFirstName) {
      setIsUpdateDrillModalOpen(false)
      return setShouldUpdateDrillGlobally(false)
    }

    if (typeof isTrainingModuleUpdatedDirty === 'boolean') {
      setIsUpdateDrillModalOpen(isTrainingModuleUpdatedDirty)
    }
  }, [
    isTrainingModuleUpdatedDirty,
    isCurrentUserDrillCreator,
    trainingModule?.originalCreatorFirstName,
    setShouldUpdateDrillGlobally,
  ])

  return (
    <>
      <DrillCreateFormEditDrillModal
        isOpen={isUpdateDrillModalOpen}
        onClose={() => setIsUpdateDrillModalOpen(false)}
        setShouldUpdateGlobally={setShouldUpdateDrillGlobally}
        pathname='practice-plan'
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth='lg'
        PaperProps={{
          style: {
            height: '90%',
          },
        }}
      >
        <DrillCreateForm
          trainingModule={trainingModule}
          createDrill={createDrill}
          updateDrill={updateDrill}
          deleteDrill={deleteDrill}
          isFetching={isFetching}
          isMutating={isMutating}
          type={type}
          shouldUpdateGlobally={shouldUpdateDrillGlobally}
        />
      </Dialog>
    </>
  )
}

DrillCreateFormModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default DrillCreateFormModal
