import polyglotI18nProvider from 'ra-i18n-polyglot'
import el from '../../i18n/el'
import en from '../../i18n/en'

const i18nProvider = polyglotI18nProvider(
  (locale) => (locale === 'el' ? el : en),
  'en', // Default locale
)

export default i18nProvider
