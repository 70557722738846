import localStorageHelper from './localStorageHelper'
import localStorageIds from '../constants/localStorageIds'
import SUBSCRIPTION_STATES from '../constants/subscriptionStates'

const orgIsInSubscriptionState = (subscriptionsAllowed, orgId) => {
  const searchForInactive =
    subscriptionsAllowed.length === 1 && subscriptionsAllowed?.[0] === SUBSCRIPTION_STATES.INACTIVE
  const { localStorageValue: selectedOrg } = localStorageHelper(localStorageIds.SELECTED_ORG)
  const { localStorageValue: localstorageSubscriptions } = localStorageHelper(
    localStorageIds.SUBSCRIPTIONS,
  )
  const checkOrgId = orgId || selectedOrg?.id

  if (!localstorageSubscriptions[checkOrgId]) return searchForInactive

  return subscriptionsAllowed.includes(localstorageSubscriptions[checkOrgId])
}

export default orgIsInSubscriptionState
