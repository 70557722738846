import { React } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from '@material-ui/core'
import { Droppable } from 'react-beautiful-dnd'
import { useTranslate } from 'react-admin'
import IconButton from '@material-ui/core/IconButton'
import { Delete } from '@material-ui/icons'
import ContainerPadding from '../containerPadding/containerPadding'
import SelectedPlayer from '../PracticePlayersSelectedPlayer/SelectedPlayer'
import teamsModalStyles from './teamsModalStyles'

const TeamTable = ({ teamNumber, teamPlayers, removePlayerFromTeam, removeTeam }) => {
  const translate = useTranslate()
  const useStyles = makeStyles(teamsModalStyles)
  const classes = useStyles()

  return (
    <ContainerPadding>
      <TableContainer className={classes.tableContainer}>
        <div className={classes.deleteButton}>
          <IconButton onClick={removeTeam}>
            <Delete />
          </IconButton>
        </div>

        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.firstRow}>
              <Droppable droppableId={`team${teamNumber}`}>
                {(provided) => (
                  <TableCell
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      border: '1px solid black',
                      textAlign: 'center',
                      color: 'white',
                      padding: '8px',
                      height: '20px',
                      width: '50%',
                    }}
                  >
                    <span>
                      {translate('ra.text.team')}
                      {teamNumber}
                    </span>
                  </TableCell>
                )}
              </Droppable>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {teamPlayers.map((player) => (
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                    key={player?.id || player}
                  >
                    <SelectedPlayer
                      player={player}
                      inTeam
                      removePlayerFromTeam={() => removePlayerFromTeam(player)}
                    />
                  </div>
                ))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </ContainerPadding>
  )
}

TeamTable.propTypes = {
  teamNumber: PropTypes.number,
  teamPlayers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      personalData: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        dateOfBirth: PropTypes.string,
        height: PropTypes.number,
        email: PropTypes.string,
        phoneNumber: PropTypes.string,
        positions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }).isRequired,
      clothingData: PropTypes.shape({
        shoeSize: PropTypes.number,
        jerseySize: PropTypes.string,
        shortsSize: PropTypes.string,
      }).isRequired,
      logoUrl: PropTypes.string,
      teams: PropTypes.arrayOf(
        PropTypes.shape({
          team: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          jerseyNumber: PropTypes.number,
        }),
      ).isRequired,
    }),
  ).isRequired,
  removePlayerFromTeam: PropTypes.func,
  removeTeam: PropTypes.func,
}

export default TeamTable
