import { shape, arrayOf } from 'prop-types'
import trainingModuleType from './trainingModuleType'
import metadataType from './metadataType'

const trainingModuleListType = shape({
  metadata: metadataType,
  trainingModulesDetailsDto: arrayOf(trainingModuleType),
})

export default trainingModuleListType
