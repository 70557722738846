import React from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { makeStyles } from '@material-ui/core'
import COLORS from '../../utils/constants/colors'

const MyCloudUploadIcon = (props) => {
  const useStyles = makeStyles({
    leftIcon: {
      width: '22px',
      height: '22px',
      cursor: 'pointer',
      fill: COLORS.orange,
    },
  })
  const classes = useStyles()

  return <CloudUploadIcon {...props} classes={{ root: classes.leftIcon }} />
}

export default MyCloudUploadIcon
