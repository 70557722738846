import axiosInstance from '../axiosInterceptor/axiosInstance'

const getLivestreamTypes = async (data) => {
  const { eventTypeCode } = data
  const url = `/events/broadcast-types?EventTypeCode=${eventTypeCode}`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getLivestreamTypes
