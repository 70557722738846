import React from 'react'
import { makeStyles } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'

const MyDownloadIcon = (props) => {
  const useStyles = makeStyles({
    addIcon: {
      width: 18,
      height: 18,
      cursor: 'pointer',
    },
  })
  const classes = useStyles()

  return <GetAppIcon {...props} classes={{ root: classes.addIcon }} />
}

export default MyDownloadIcon
