import { shape, number } from 'prop-types'

const metadataType = shape({
  currentPage: number,
  perPage: number,
  totalCount: number,
  totalPages: number,
})

export default metadataType
