import PlayerList from '../../react-admin/list/Player/PlayerList/PlayerList'
import TeamList from '../../react-admin/list/Team/TeamList/TeamList'
import { USER_SCOPES } from './userScopes'

const { ALL, COACH, STATS } = USER_SCOPES

const RESOURCES = [
  {
    name: 'players',
    list: PlayerList,
    create: PlayerList,
    edit: PlayerList,
    allowedScopes: [COACH, STATS],
    allowedUserOrgRoles: ALL,
  },
  {
    name: 'positions',
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
  },
  {
    name: 'team',
    list: TeamList,
    allowedScopes: ALL,
    allowedUserOrgRoles: ALL,
  },
]

export default RESOURCES
