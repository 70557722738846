import React from 'react'
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Input,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import drillListAppbarSelectFieldStyles from './DrillListAppbarSelectField.styles'
import ExpandIcon from '../../../assets/icons/expandIcon'
import MyCancelIcon from '../../../assets/icons/myCancelIcon'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import MyCancelCircleIcon from '../../../assets/icons/MyCancelCircleIcon'
import uppercaseFirstLetter from '../../../utils/helpers/uppercaseFirstLetter'
import enumDisplayNameType from '../../../types/enumDisplayNameType'

const DrillListAppbarSelectField = (props) => {
  const { isVisible, options, values, label, onCloseField, onDeleteChip, ...rest } = props
  const useStyles = makeStyles(drillListAppbarSelectFieldStyles)
  const classes = useStyles()

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  const handleCancelClick = (e, option) => {
    e.stopPropagation()
    onDeleteChip(values, option)
  }

  return (
    <ConditionalRender renderValue={isVisible}>
      <div className={classes.wrapper}>
        <FormControl variant='filled' classes={{ root: classes.formControlRoot }}>
          <Select
            {...rest}
            style={{ borderRadius: '5px', height: '42px' }}
            classes={{ select: classes.select, filled: classes.selectInput }}
            value={values}
            disableUnderline
            multiple
            displayEmpty
            IconComponent={ExpandIcon}
            input={<Input id='select-multiple-chip' />}
            MenuProps={MenuProps}
            renderValue={(chipOptions) =>
              chipOptions.length > 0 ? (
                <div className={classes.chips}>
                  {values.map((chipOption) => (
                    <Chip
                      key={v4()}
                      className={classes.chip}
                      color='primary'
                      label={uppercaseFirstLetter(chipOption.replace('_', ' '))}
                      clickable
                      deleteIcon={
                        <MyCancelIcon
                          className={classes.deleteIcon}
                          onMouseDown={(e) => handleCancelClick(e, chipOption)}
                        />
                      }
                      onDelete={() => {}}
                    />
                  ))}
                </div>
              ) : (
                <div>{label}</div>
              )
            }
          >
            {options &&
              !options.errorCode &&
              options.map((option) => (
                <MenuItem key={v4()} value={option.name}>
                  <FormControlLabel
                    key={v4()}
                    control={
                      <Checkbox
                        size='small'
                        checked={values && values.find((value) => value === option.name)}
                        name={option.name}
                      />
                    }
                    label={uppercaseFirstLetter(option.name.replace('_', ' '))}
                    color='primary'
                  />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <MyCancelCircleIcon className={classes.cancelIcon} onClick={onCloseField} />
      </div>
    </ConditionalRender>
  )
}

DrillListAppbarSelectField.propTypes = {
  isVisible: PropTypes.bool,
  options: PropTypes.arrayOf(enumDisplayNameType),
  values: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  onCloseField: PropTypes.func,
  onDeleteChip: PropTypes.func,
}

export default DrillListAppbarSelectField
