import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'

const GetFormDirtyValue = ({ setFormDirty, externalTeamLogo }) => {
  const formInfo = useFormikContext()

  // In game event user can add team logos which are not part of the form's state.
  if (externalTeamLogo) {
    setFormDirty(true)
  } else {
    setFormDirty(formInfo.dirty)
  }
  return null
}

GetFormDirtyValue.propTypes = {
  setFormDirty: PropTypes.func,
  externalTeamLogo: PropTypes.string,
}

export default GetFormDirtyValue
