import { React, useEffect, useState } from 'react'
import {
  Typography,
  Divider,
  Grid,
  Box,
  makeStyles,
  Popover,
  MenuItem,
  Button,
  Tooltip,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useTranslate } from 'react-admin'
import { Formik, Form } from 'formik'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import COLORS from '../../utils/constants/colors'
import practicePlayersPageStyles from './practicePlayersPageStyles'
import QueryKeys from '../../utils/constants/queryKeys'
import LoaderDots from '../loaders/LoaderDots/LoaderDots'
import useQueryParams from '../../hooks/useQueryParams'
import ContainerPadding from '../containerPadding/containerPadding'
import getOrgPlayers from '../../Apis/player/getOrgPlayers'
import PlayerListItem from '../PracticePlayersListItem/PlayersListItem'
import ButtonWithLoader from '../ui/ButtonWithLoader/ButtonWithLoader'
import getEvent from '../../Apis/event/getEvent'
import AddButton from '../ui/AddButton/AddButton'
import TeamsModal from '../PracticePlayersTeamsModal/TeamsModal'
import TeamsDisplay from '../PracticePlayersTeamsDisplay/TeamsDisplay'
import useHistoryPush from '../../hooks/useHistoryPush'
import addPracticePlayers from '../../Apis/practice/addPracticePlayers'
import getPracticePlayers from '../../Apis/practice/getPracticePlayers'
import updatePracticePlayers from '../../Apis/practice/updatePracticePlayers'
import MyCloseIcon from '../../assets/icons/MyCloseIcon'

const PracticePlayersPage = () => {
  const useStyles = makeStyles(practicePlayersPageStyles)
  const { redirect, goBack } = useHistoryPush()
  const queryClient = useQueryClient()
  const queryParams = useQueryParams()
  const { org, eventId, practicePlayersId } = queryParams
  const translate = useTranslate()
  const classes = useStyles()
  const params = { orgId: org }
  const [selectAll, setSelectAll] = useState(false)
  const [addPopoverAnchorEl, setAddPopoverAnchorEl] = useState(null)
  const [isTeamsModalOpen, setIsTeamsModalOpen] = useState(false)
  const [playersInEachTeam, setPlayersInEachTeam] = useState(3)
  const [editTeam, setEditTeam] = useState(null)
  const fieldValues = {
    3: 'teamsOfThree',
    4: 'teamsOfFour',
    5: 'teamsOfFive',
  }
  const { data: players, isLoading } = useQuery([QueryKeys.GET_ORG_PLAYERS], () =>
    getOrgPlayers(params),
  )
  const practice = useQuery(
    [QueryKeys.GET_EVENT],
    () =>
      getEvent({
        eventTypeCode: 'pract',
        eventId,
      }),
    {
      enabled: Boolean(eventId),
    },
  )
  const practicePlayers = useQuery(
    [QueryKeys.GET_PRACTICE_PLAYERS, { practicePlayersId }],
    () => getPracticePlayers({ practicePlayersId }),
    {
      enabled: !!practicePlayersId,
    },
  )

  const addPlayersToPractice = useMutation((data) => addPracticePlayers(data), {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICE_PLANS)
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICES)

      redirect(`/practice-players/${data?.id}/show`, [{ org }, { team: practice.data.teams[0].id }])
    },
  })

  const updateAPracticePlayers = useMutation((data) => updatePracticePlayers(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICE_PLANS)
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICES)

      redirect(`/practice-players/${practicePlayersId}/show`, [
        { org },
        { team: practice.data.teams[0].id },
      ])
    },
  })

  const teamsPlayers = players?.players.filter((player) =>
    practice.data?.teams?.some((team) =>
      player?.teams?.some((playerTeam) => playerTeam.team === team.id),
    ),
  )

  const handleSelectAll = (setFieldValue) => {
    if (selectAll) {
      setFieldValue('selectedPlayers', [])
    } else {
      const allPlayers = teamsPlayers.map((player) => {
        return player
      })
      setFieldValue('selectedPlayers', allPlayers)
    }
    setSelectAll(!selectAll)
  }

  const handleSelect = (selectedPlayer, values, setFieldValue) => {
    const updatedSelectedPlayers = values.selectedPlayers.some((p) => p.id === selectedPlayer.id)
      ? values.selectedPlayers.filter((p) => p.id !== selectedPlayer.id)
      : [...values.selectedPlayers, selectedPlayer]

    setFieldValue('selectedPlayers', updatedSelectedPlayers)

    if (updatedSelectedPlayers.length === teamsPlayers.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }

  const handleAddButtonClick = (event) => {
    setAddPopoverAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAddPopoverAnchorEl(null)
  }

  const handleTeamsModalClose = (teams, setFieldValue) => {
    handlePopoverClose()
    setIsTeamsModalOpen(false)
    setEditTeam(null)
    if (!teams || teams.teams.length === 0) {
      return
    }

    const fieldValue = fieldValues[playersInEachTeam]

    if (fieldValue) {
      setFieldValue(
        fieldValue,
        teams.teams.map((team) => team.team.map((player) => ({ ...player }))),
      )
    }
  }

  const handleRemoveTeamOfThree = (setFieldValue) => {
    setFieldValue('teamsOfThree', [])
  }

  const handleRemoveTeamOfFour = (setFieldValue) => {
    setFieldValue('teamsOfFour', [])
  }

  const handleRemoveTeamOfFive = (setFieldValue) => {
    setFieldValue('teamsOfFive', [])
  }

  const handleTeamsModalOpen = (numberOfPlayers) => {
    setPlayersInEachTeam(numberOfPlayers)
    setIsTeamsModalOpen(true)
  }

  const handleOnEditClick = (teams, playersInTeam) => {
    setEditTeam(teams)
    handleTeamsModalOpen(playersInTeam)
  }

  const onCloseIconClicked = () => goBack()

  const openAddPopover = Boolean(addPopoverAnchorEl)
  const addPopoverId = openAddPopover ? 'add-popover' : undefined
  const isLoadingQueries = isLoading || practice.isLoading || practicePlayers.isLoading

  useEffect(() => {
    if (practicePlayersId && teamsPlayers && practicePlayers.data) {
      const selectedPlayers = teamsPlayers.filter((player) =>
        practicePlayers.data.players.includes(player.id),
      )
      setSelectAll(selectedPlayers.length === teamsPlayers.length)
    }
  }, [])

  return (
    <ContainerPadding>
      {isLoadingQueries ? (
        <ContainerPadding paddingValue='45%'>
          <LoaderDots
            style={{
              fill: COLORS.orange,
              width: 70,
              height: 70,
            }}
          />
        </ContainerPadding>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            selectedPlayers: practicePlayersId
              ? teamsPlayers.filter((player) => practicePlayers.data.players.includes(player.id))
              : [],
            teamsOfThree:
              practicePlayers?.data?.practiceTeams
                .filter((team) => team.numberOfPlayers === 3)
                .map((team) =>
                  team.teamPlayerNames.map((playerName) =>
                    teamsPlayers.find(
                      (player) =>
                        `${player.personalData.firstName} ${player.personalData.lastName}` ===
                        playerName,
                    ),
                  ),
                ) || [],
            teamsOfFour:
              practicePlayers?.data?.practiceTeams
                .filter((team) => team.numberOfPlayers === 4)
                .map((team) =>
                  team.teamPlayerNames.map((playerName) =>
                    teamsPlayers.find(
                      (player) =>
                        `${player.personalData.firstName} ${player.personalData.lastName}` ===
                        playerName,
                    ),
                  ),
                ) || [],
            teamsOfFive:
              practicePlayers?.data?.practiceTeams
                .filter((team) => team.numberOfPlayers === 5)
                .map((team) =>
                  team.teamPlayerNames.map((playerName) =>
                    teamsPlayers.find(
                      (player) =>
                        `${player.personalData.firstName} ${player.personalData.lastName}` ===
                        playerName,
                    ),
                  ),
                ) || [],
          }}
          onSubmit={(values, { setSubmitting }) => {
            const payload = {
              practicePlayersId,
              eventId,
              players: values.selectedPlayers,
              teams: {
                TeamsOfThree: values.teamsOfThree,
                TeamsOfFour: values.teamsOfFour,
                TeamsOfFive: values.teamsOfFive,
              },
            }

            if (practicePlayersId) {
              updateAPracticePlayers.mutate(payload, {
                onSuccess: () => setSubmitting(false),
              })
            } else {
              addPlayersToPractice.mutate(payload, {
                onSuccess: () => setSubmitting(false),
              })
            }
          }}
        >
          {({ values, setFieldValue, dirty }) => (
            <Form>
              <Box display='flex' justifyContent='flex-end' alignItems='center' paddingTop={1}>
                <ButtonWithLoader label={translate('ra.buttons.save')} disabled={!dirty} />
                <Tooltip title={translate('ra.action.cancel')}>
                  <IconButton
                    color='primary'
                    aria-label='back arrow icon button'
                    component='div'
                    onClick={onCloseIconClicked}
                  >
                    <MyCloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              <Typography component='div' className={classes.recentsTypo}>
                {translate('ra.text.players')}
                <Box display='flex' justifyContent='flex-end' alignItems='center'>
                  <Button
                    onClick={() => handleSelectAll(setFieldValue)}
                    variant='text'
                    color='primary'
                    style={{ textTransform: 'none', fontSize: 'small' }}
                  >
                    {selectAll
                      ? translate('ra.action.deselectAll')
                      : translate('ra.action.selectAll')}
                  </Button>
                </Box>
              </Typography>
              <Grid container spacing={1}>
                {teamsPlayers?.map((player) => (
                  <Grid item xs={5} sm={4} md={3} lg={2} key={player.id}>
                    <PlayerListItem
                      player={player}
                      onSelect={(selectedPlayer) =>
                        handleSelect(selectedPlayer, values, setFieldValue)
                      }
                      checked={values.selectedPlayers.some(
                        (teamPlayer) => teamPlayer.id === player.id,
                      )}
                    />
                  </Grid>
                ))}
              </Grid>
              <Divider variant='middle' className={classes.divider} />
              <div className={classes.addButton}>
                <AddButton
                  onClick={handleAddButtonClick}
                  label={translate('ra.buttons.addSmall')}
                  filled
                />
                <Popover
                  id={addPopoverId}
                  open={openAddPopover}
                  anchorEl={addPopoverAnchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <div className={classes.popoverContent}>
                    <MenuItem
                      disabled={
                        values.selectedPlayers.length === 0 || values.teamsOfThree.length > 0
                      }
                      onClick={() =>
                        handleOnEditClick(
                          values.teamsOfThree.length > 0 ? values.teamsOfThree : null,
                          3,
                        )
                      }
                    >
                      <AddCircleIcon
                        style={{ width: '22', height: '22', marginRight: 5, fill: '#E0726E' }}
                      />
                      {translate('ra.text.teamsOfThree')}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleOnEditClick(
                          values.teamsOfFour.length > 0 ? values.teamsOfFour : null,
                          4,
                        )
                      }
                      disabled={
                        values.selectedPlayers.length === 0 || values.teamsOfFour.length > 0
                      }
                    >
                      <AddCircleIcon
                        style={{ width: '22', height: '22', marginRight: 5, fill: '#E0726E' }}
                      />
                      {translate('ra.text.teamsOfFour')}
                    </MenuItem>
                    <MenuItem
                      disabled={
                        values.selectedPlayers.length === 0 || values.teamsOfFive.length > 0
                      }
                      onClick={() =>
                        handleOnEditClick(
                          values.teamsOfFive.length > 0 ? values.teamsOfFive : null,
                          5,
                        )
                      }
                    >
                      <AddCircleIcon
                        style={{ width: '22', height: '22', marginRight: 5, fill: '#E0726E' }}
                      />
                      {translate('ra.text.teamsOfFive')}
                    </MenuItem>
                  </div>
                </Popover>
              </div>
              <TeamsModal
                isOpen={isTeamsModalOpen}
                onClose={(teams) => {
                  handleTeamsModalClose(teams, setFieldValue)
                }}
                selectedPlayers={values.selectedPlayers}
                playersInEachTeam={playersInEachTeam}
                editTeam={editTeam}
              />
              <TeamsDisplay
                teams={values.teamsOfThree}
                onRemove={() => handleRemoveTeamOfThree(setFieldValue)}
                onEditClick={() => handleOnEditClick(values.teamsOfThree, 3)}
                playersInEachTeam={3}
                edit
              />
              <TeamsDisplay
                teams={values.teamsOfFour}
                onRemove={() => handleRemoveTeamOfFour(setFieldValue)}
                onEditClick={() => handleOnEditClick(values.teamsOfFour, 4)}
                playersInEachTeam={4}
                edit
              />
              <TeamsDisplay
                teams={values.teamsOfFive}
                onRemove={() => handleRemoveTeamOfFive(setFieldValue)}
                onEditClick={() => handleOnEditClick(values.teamsOfFive, 5)}
                playersInEachTeam={5}
                edit
              />
            </Form>
          )}
        </Formik>
      )}
    </ContainerPadding>
  )
}

export default PracticePlayersPage
