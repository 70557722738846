import axiosInstance from '../axiosInterceptor/axiosInstance'

const addCourt = async (data) => {
  const url = '/courts'

  const response = await axiosInstance.post(url, JSON.stringify(data))

  return response?.data
}

export default addCourt
