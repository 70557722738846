import axiosInstance from '../axiosInterceptor/axiosInstance'

const getSubscriptionDetails = async (data) => {
  const { orgId } = data
  const url = `/billing/details?clubId=${orgId}`
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getSubscriptionDetails
