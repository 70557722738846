import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './user.css'
import UserAvatar from './UserAvatar/UserAvatar'
import UserInfo from './UserInfo/UserInfo'
import { useMyLayout } from '../../context/useMyLayout'
import MoreIconProfile from '../MoreIconProfile/MoreIconProfile'
import Notifications from '../Notification/Notifications'

const User = ({ sideBarOpen }) => {
  const [profileMenuModal, setProfileMenuModal] = useState(null)
  const { user, error } = useMyLayout()

  return (
    <div className='user-sidebar'>
      <UserAvatar userAvatar={user?.logoUrl} setProfileMenuModal={setProfileMenuModal} />
      <UserInfo
        userName={`${user?.firstName ?? ''} ${user?.lastName ?? ''}`}
        userEmail={user?.email}
      />
      <Notifications sideBarOpen={sideBarOpen} />
      <MoreIconProfile
        profileMenuModal={profileMenuModal}
        setProfileMenuModal={setProfileMenuModal}
        disabled={error}
      />
    </div>
  )
}

User.propTypes = {
  sideBarOpen: PropTypes.bool.isRequired,
}

export default User
