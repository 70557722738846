import PropTypes from 'prop-types'
import isUserOrgRolesValid from '../../utils/helpers/isUserOrgRolesValid'

const IsUserRolesAuthorized = ({ acceptedRoles, searchIds, children }) => {
  if (!children) return null
  const isAccepted = isUserOrgRolesValid(acceptedRoles, searchIds)

  return isAccepted ? children : null
}

IsUserRolesAuthorized.propTypes = {
  acceptedRoles: PropTypes.arrayOf(PropTypes.string),
  searchIds: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default IsUserRolesAuthorized
