import React, { useEffect, useRef, useState } from 'react'
import { Divider, makeStyles } from '@material-ui/core'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import { isEmptyArray } from 'formik'
import YouTubeIcon from '@material-ui/icons/YouTube'
import livestreamBannerStyles from './livestreamBanner.styles'
import AntennaIcon from '../../assets/icons/AntennaIcon'
import MyPlayIcon from '../../assets/icons/myPlayIcon'
import MyPauseIcon from '../../assets/icons/MyPauseIcon'
import MyStopIcon from '../../assets/icons/MyStopIcon'
import MyMicIcon from '../../assets/icons/MyMicIcon'
import useDrag from '../../hooks/useDrag'
import DragIcon from '../../assets/icons/dragIcon'
import pauseLivestream from '../../Apis/livestream/pauseLivestream'
import resumeLivestream from '../../Apis/livestream/resumeLivestream'
import stopLivestream from '../../Apis/livestream/stopLivestream'
import setCommentary from '../../Apis/livestream/setCommentary'
import useResize from '../../hooks/useResize'
import calculateBannerPosition from '../../utils/helpers/calculateBannerPositionHelper'
import isNonzeroNumber from '../../utils/helpers/isNonzeroNumberHelper'
import broadcastEventType from '../../types/broadcastEventType'
import MySaveIcon from '../../assets/icons/MySaveIcon'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import LivestreamBannerStopConfirmationModal from '../LivestreamBannerStopConfirmationModal/LivestreamBannerStopConfirmationModal'
import BROADCAST_STATES from '../../utils/constants/broadcastStates'
import COLORS from '../../utils/constants/colors'
import MySnackbar from '../MySnackbar/MySnackbar'
import LivestreamBannerMedia from '../LivestreamBannerMedia/LivestreamBannerMedia'
import IsUserRolesAuthorized from '../IsUserRolesAuthorized/IsUserRolesAuthorized'
import IsUserScopesAuthorized from '../IsUserScopesAuthorized/IsUserScopesAuthorized'
import { USER_SCOPES } from '../../utils/constants/userScopes'
import USER_ORG_ROLES from '../../utils/constants/userOrgRoles'
import USER_ORG_MEMBERS_ROLES from '../../utils/constants/userOrgMembersRoles'
import isUserOrgRolesValid from '../../utils/helpers/isUserOrgRolesValid'
import QueryKeys from '../../utils/constants/queryKeys'
import AddButton from '../ui/AddButton/AddButton'
import useHistoryPush from '../../hooks/useHistoryPush'

const LivestreamBanner = ({ broadcastEvent, bannerIndex }) => {
  const { redirect } = useHistoryPush()
  const queryClient = useQueryClient()
  const translate = useTranslate()
  const { broadcast, title, supportsCommentary, media } = broadcastEvent || {}
  const [isLive, setIsLive] = useState(false)
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [isStopModalOpen, setIsStopModalOpen] = useState(false)
  const elRef = useRef(null)
  useDrag(elRef, 'STRONG')
  const { width, height } = useResize(document.body)
  const { x, y } = calculateBannerPosition(
    width,
    height,
    elRef?.current?.clientWidth,
    elRef?.current?.clientHeight,
    bannerIndex,
  )
  const liveBroadcastState =
    broadcast?.state === BROADCAST_STATES.running || broadcast?.state === BROADCAST_STATES.paused
  const showMedia = media && !isEmptyArray(media) && liveBroadcastState
  const allowWatchVideo =
    broadcast?.id &&
    (broadcast?.state === BROADCAST_STATES.finished ||
      broadcast?.state === BROADCAST_STATES.running)

  const useStyles = makeStyles(livestreamBannerStyles)
  const classes = useStyles()

  // Relocate banners when resize browser's window
  useEffect(() => {
    if (isNonzeroNumber(width) && isNonzeroNumber(height) && elRef?.current) {
      elRef.current.style.transform = `translate3d(${x}px, ${y}px, 0)`
    }
  }, [width, height, x, y])

  const pauseTheLivestream = useMutation((broadcastId) => pauseLivestream(broadcastId), {
    onSuccess: () => setIsLive(true),
  })

  const stopTheLivestream = useMutation((broadcastId) => stopLivestream(broadcastId), {
    onSuccess: () => {
      setIsLive(true)
      setIsStopModalOpen(false)
    },
  })

  const resumeTheLivestream = useMutation((broadcastId) => resumeLivestream(broadcastId), {
    onSuccess: () => setIsLive(false),
  })

  const setTheCommentary = useMutation(({ broadcastId, on }) => setCommentary(broadcastId, on), {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.GET_RUNNING_BROADCASTS_EVENTS),
    onError: () => setIsSnackbarOpen(true),
  })

  const handleLiveClick = () => {
    redirect(`/livestream/${broadcast.id}`, [{ eventId: broadcastEvent.id }])
  }

  const handlePauseButton = () => pauseTheLivestream.mutate(broadcast?.id)
  const handleResumeButton = () => resumeTheLivestream.mutate(broadcast?.id)
  const handleSetCommentary = () =>
    setTheCommentary.mutate({
      broadcastId: broadcast.id,
      on: !broadcast?.isCommentaryOn,
    })
  const handleCloseStopModal = () => setIsStopModalOpen(false)
  const handleStopButton = () => setIsStopModalOpen(true)
  const handleModalStopButton = () => stopTheLivestream.mutate(broadcast?.id)

  const isLivestreamCreating = broadcast?.state === BROADCAST_STATES.creating
  const isLivestreamDeleting = broadcast?.state === BROADCAST_STATES.deleting

  // TODO: Add mapper for broadcast?.state
  if (isLivestreamDeleting) return null

  return (
    <div ref={elRef} className={classes.wrapper}>
      <div className={classes.upperColumn}>
        <div className={classes.liveWrapper}>
          <strong>
            <DragIcon classes={{ root: classes.dragIcon }} />
          </strong>

          <div className={classes.iconsContainer}>
            {broadcast?.youtubeStreamKey && <AntennaIcon className={classes.antennaIcon} />}
            {broadcast?.streamerSaveUrl && <MySaveIcon />}
            {supportsCommentary && (
              <MyMicIcon
                cursor={
                  isUserOrgRolesValid([
                    USER_ORG_ROLES.OWNER,
                    USER_ORG_ROLES.ADMIN,
                    USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
                  ])
                    ? 'pointer'
                    : 'default'
                }
                onClick={
                  isUserOrgRolesValid([
                    USER_ORG_ROLES.OWNER,
                    USER_ORG_ROLES.ADMIN,
                    USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
                  ])
                    ? handleSetCommentary
                    : () => {}
                }
                style={{ fill: broadcast?.isCommentaryOn ? COLORS.darkerOrange : COLORS.white }}
              />
            )}
          </div>

          <div className={classes.liveContainer}>
            <strong className={classes.live}>Live {title}</strong>
            {allowWatchVideo && (
              <AddButton
                label={translate('ra.buttons.watchStream')}
                icon={<YouTubeIcon />}
                className={classes.myTextButton}
                classNameText={classes.myTextButton}
                onClick={handleLiveClick}
              />
            )}

            {isLivestreamCreating && (
              <div
                className={classes.livestreamState}
              >{`${broadcast?.state.toUpperCase()}...`}</div>
            )}
          </div>

          <IsUserScopesAuthorized acceptedScopes={[USER_SCOPES.LIVESTREAM]}>
            <IsUserRolesAuthorized
              acceptedRoles={[
                USER_ORG_ROLES.OWNER,
                USER_ORG_ROLES.ADMIN,
                USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
              ]}
            >
              <ConditionalRender renderValue={!isLivestreamCreating}>
                <>
                  {isLive ? (
                    <MyPlayIcon onClick={handleResumeButton} />
                  ) : (
                    <MyPauseIcon onClick={handlePauseButton} />
                  )}
                  <MyStopIcon onClick={handleStopButton} />
                </>
              </ConditionalRender>

              <LivestreamBannerStopConfirmationModal
                isStopModalOpen={isStopModalOpen}
                handleCloseStopModal={handleCloseStopModal}
                handleModalStopButton={handleModalStopButton}
              />
            </IsUserRolesAuthorized>
          </IsUserScopesAuthorized>
        </div>
        {showMedia && <Divider style={{ backgroundColor: COLORS.grey, marginTop: 20 }} />}
      </div>
      {showMedia && <LivestreamBannerMedia media={media} broadcast={broadcast} />}
      <MySnackbar
        isOpen={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
        isSuccess={setTheCommentary.isSuccess}
        errorMessage={translate('ra.notification.genericError')}
      />
    </div>
  )
}

LivestreamBanner.propTypes = {
  broadcastEvent: broadcastEventType,
  bannerIndex: PropTypes.number,
}

LivestreamBanner.defaultProps = {
  bannerIndex: 0,
}

export default LivestreamBanner
