import React, { useRef, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { useTranslate } from 'react-admin'
import { useQuery } from 'react-query'
import { Grid, makeStyles } from '@material-ui/core'
import { addDays } from 'date-fns'
import useQueryParams from '../../hooks/useQueryParams'
import QueryKeys from '../../utils/constants/queryKeys'
import getPractices from '../../Apis/event/getPractices'
import AddButton from '../../components/ui/AddButton/AddButton'
import Practices from '../../components/practices/Practices'
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender'
import CreatePracticeDrawer from '../../components/CreatePracticeDrawer/CreatePracticeDrawer'
import getSingleTeam from '../../Apis/team/getSingleTeam'
import practicesListStyles from './practicesList.styles'
import MyDateRangePicker from '../../components/MyDateRangePicker/MyDateRangePicker'
import dateHelper from '../../utils/helpers/dateHelpers/dateHelper'
import useHistoryPush from '../../hooks/useHistoryPush'
import { parseDateUrl } from '../../utils/helpers/dateHelpers/parseDate'
import ContainerPadding from '../../components/containerPadding/containerPadding'
import PracticePlanRecentModal from '../../components/PracticePlanRecentModal/PracticePlanRecentModal'
import LoaderDots from '../../components/loaders/LoaderDots/LoaderDots'
import drillListPageStyles from '../DrillList/DrillListPage/drillListPage.styles'
import USER_ORG_ROLES from '../../utils/constants/userOrgRoles'
import IsUserRolesAuthorized from '../../components/IsUserRolesAuthorized/IsUserRolesAuthorized'
import USER_ORG_MEMBERS_ROLES from '../../utils/constants/userOrgMembersRoles'
import useTour from '../../hooks/useTour'
import TOUR_IDS from '../../utils/constants/introIds'

const PracticesList = () => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [practicePlansModal, setPracticePlansModal] = useState(false)
  const [practiceAccordionPressed, setPracticeAccordionPressed] = useState()
  const { today, todayPlusOneWeek } = dateHelper()
  const [dateRange, setDateRange] = useState()
  const queryParams = useQueryParams()
  const practiceId = useRouteMatch('/practice/:id')?.params?.id
  const translate = useTranslate()
  const { redirect, pathname } = useHistoryPush()
  const { OWNER, ADMIN } = USER_ORG_ROLES
  const { TEAM_MANAGER } = USER_ORG_MEMBERS_ROLES
  const useStyles = makeStyles(practicesListStyles)
  const classes = useStyles()
  const elRef = useRef()

  useTour({
    elRef,
    tourName: 'practice-tour',
    tourSteps: 'practices',
  })

  const setDatesInUrl = (startDate, endDate) => {
    const id = practiceId ? `/${practiceId}` : ''
    const path = `/practice${id}`
    redirect(path, [{ startDate: parseDateUrl(startDate) }, { endDate: parseDateUrl(endDate) }])
  }

  // Set start/end date filters from url
  useEffect(() => {
    const { startDate, endDate } = queryParams

    if (startDate && endDate) {
      const start = new Date(startDate)
      const end = new Date(endDate)
      start.setHours(0, 0, 0, 0)
      end.setHours(0, 0, 0, 0)
      setDateRange({
        startDate: start,
        endDate: end,
      })
    } else if (!startDate && !endDate) {
      today.setHours(0, 0, 0, 0)
      todayPlusOneWeek.setHours(0, 0, 0, 0)
      setDateRange({
        startDate: today,
        endDate: todayPlusOneWeek,
      })
      setDatesInUrl(today, todayPlusOneWeek)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (practiceId) {
      setOpenDrawer(true)
    } else {
      setOpenDrawer(false)
    }
  }, [practiceId])

  const team = useQuery([QueryKeys.GET_SINGLE_TEAM], () => getSingleTeam(queryParams?.team))

  const { data: practices, isFetching } = useQuery(
    [QueryKeys.GET_PRACTICES, dateRange],
    () =>
      getPractices({
        teamId: queryParams?.team,
        startDateTime: dateRange?.startDate.toISOString(),
        endDateTime: addDays(dateRange?.endDate, 1).toISOString(),
        isPracticePlanIncluded: true,
      }),
    {
      enabled: Boolean(dateRange),
    },
  )

  const clickedPractice = practices && practices.find((practice) => practice.id === practiceId)

  return (
    <ContainerPadding paddingValue='1.5%'>
      <div ref={elRef} className={classes.headerButtons}>
        <MyDateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
        <IsUserRolesAuthorized acceptedRoles={[OWNER, ADMIN, TEAM_MANAGER]}>
          <AddButton
            id={TOUR_IDS.practices.create}
            className={classes.addButton}
            label={translate('ra.buttons.newPractice')}
            onClick={() => {
              redirect(pathname, [{ breadcrumb: 'create' }])
              setOpenDrawer(true)
            }}
            filled
          />
        </IsUserRolesAuthorized>
      </div>

      <div className={classes.practicesContainer}>
        <Practices
          practices={practices}
          setPracticePlansModal={setPracticePlansModal}
          setPracticeAccordionPressed={setPracticeAccordionPressed}
        />
      </div>

      <ConditionalRender renderValue={practices && practices.length === 0}>
        <span style={{ marginLeft: '35%' }}>{translate('ra.text.noPractices')}</span>
      </ConditionalRender>

      <ConditionalRender renderValue={isFetching}>
        <Grid
          container
          justifyContent='center'
          style={{ marginTop: '100px', marginBottom: '30px' }}
        >
          <LoaderDots style={drillListPageStyles.loaderIcon} />
        </Grid>
      </ConditionalRender>

      <CreatePracticeDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        team={team?.data}
        clickedPractice={clickedPractice}
        clickedPracticeId={practiceId}
      />

      <PracticePlanRecentModal
        open={practicePlansModal}
        onClose={() => {
          setPracticePlansModal(false)
        }}
        eventId={practiceAccordionPressed}
      />
    </ContainerPadding>
  )
}

export default PracticesList
