import COLORS from '../../utils/constants/colors'

const supportBubbleStyles = {
  circle: {
    position: 'absolute',
    right: 30,
    bottom: 20,
    backgroundColor: COLORS.orange,
    zIndex: 2,
    borderRadius: '50%',
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  chatIcon: {
    color: COLORS.white,
  },
  popper: {
    zIndex: 2,
    width: 550,
  },
}

export default supportBubbleStyles
