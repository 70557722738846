import { React } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { useMutation, useQueryClient } from 'react-query'
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import FormContainer from '../layout/FormContainer/FormContainer'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import QueryKeys from '../../utils/constants/queryKeys'
import deletePracticePlan from '../../Apis/practice/deletePracticePlan'
import removePracticePlayers from '../../Apis/practice/removePracticePlayers'
import parseEventFormSubmit from '../../utils/helpers/eventHelpers/parseEventFormSubmit'
import useHistoryPush from '../../hooks/useHistoryPush'
import mutationType from '../../types/mutationType'
import eventVariantType from '../../types/events/eventVariantType'
import practicePlanType from '../../types/practicePlanType'
import TextButton from '../ui/TextButton/TextButton'
import isObjectEmptyHelper from '../../utils/helpers/isObjectEmptyHelper'
import PracticePlanInfoRow from '../PracticePlanInfoRow/PracticePlanInfoRow'
import practiceEventFormFooterStyles from './practiceEventFormFooter.styles'
import parseGameEventFormSubmit from '../../utils/helpers/eventHelpers/parseGameEventFormSubmit'
import courtType from '../../types/courtType'
import ids from '../../utils/constants/introIds'
import PracticePlayerInfoRow from '../PracticePlayersInfoRow/PracticePlayerInfoRow'

const PracticeEventFormFooter = (props) => {
  const {
    practicePlan: practicePlanData,
    practicePlayers,
    practiceId,
    render,
    selectedEventType,
    createEvent,
    updateEvent,
    setPracticePlansModal,
    setSelectedPracticePlan,
    setSelectedPracticePlayers,
    setPracticePlanIdRedirect,
    courts,
  } = props
  const translate = useTranslate()
  const queryClient = useQueryClient()
  const { values, submitForm, validateForm } = useFormikContext()
  const { redirect } = useHistoryPush()
  const useStyles = makeStyles(practiceEventFormFooterStyles)
  const classes = useStyles()

  const handleNewPracticePlanClick = async () => {
    const formValidationError = await validateForm()
    if (!isObjectEmptyHelper(formValidationError)) return

    if (!practiceId) {
      createEvent.mutate(
        {
          formData: parseEventFormSubmit(
            parseGameEventFormSubmit(values, courts, selectedEventType),
          ),
          eventType: selectedEventType,
        },
        {
          onSuccess: (response) =>
            redirect(
              '/practice-plan',
              [
                { team: response?.data.teams[0].id },
                { eventId: response?.data.id },
                { goBack: '/practice' },
              ],
              ['breadcrumb'],
            ),
        },
      )
    } else {
      updateEvent.mutate(
        {
          formData: parseEventFormSubmit(
            parseGameEventFormSubmit(values, courts, selectedEventType),
          ),
          eventId: practiceId,
          eventType: selectedEventType,
        },
        {
          onSuccess: () => {
            redirect(
              '/practice-plan',
              [{ team: values.teams[0].id }, { eventId: practiceId }, { goBack: '/practice' }],
              ['breadcrumb'],
            )
          },
        },
      )
    }
  }

  const handleSelectPracticePlanClick = () => setPracticePlansModal(true)

  const deleteAPracticePlan = useMutation((practicePlanId) => deletePracticePlan(practicePlanId), {
    onSuccess: () => {
      setSelectedPracticePlan(null)
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICES)
    },
  })

  const removeAPracticePlayers = useMutation(
    (practicePlayersId) => removePracticePlayers(practicePlayersId),
    {
      onSuccess: () => {
        setSelectedPracticePlayers(null)
        queryClient.invalidateQueries(QueryKeys.GET_PRACTICES)
      },
    },
  )

  const handlePracticePlanInfoClick = () => {
    setPracticePlanIdRedirect(practicePlanData?.id)
    submitForm()
  }

  const handleCancelIcon = (e) => {
    e.stopPropagation()
    if (practicePlanData?.id) {
      deleteAPracticePlan.mutate(practicePlanData.id)
    } else {
      setSelectedPracticePlan(null)
    }
  }

  const handleCancePlayerslIcon = (e) => {
    e.stopPropagation()
    if (practicePlayers.id) {
      removeAPracticePlayers.mutate(practicePlayers.id)
    }
  }

  const handlePracticePlayersClick = async () => {
    const formValidationError = await validateForm()
    if (!isObjectEmptyHelper(formValidationError)) return
    if (!practiceId) {
      createEvent.mutate(
        {
          formData: parseEventFormSubmit(
            parseGameEventFormSubmit(values, courts, selectedEventType),
          ),
          eventType: selectedEventType,
        },
        {
          onSuccess: (response) =>
            redirect(
              '/practice-players',
              [
                { eventId: response?.data.id },
                { team: values.teams.id || values.teams[0].id },
                { goBack: '/practice' },
              ],
              ['breadcrumb'],
            ),
        },
      )
    } else {
      updateEvent.mutate(
        {
          formData: parseEventFormSubmit(
            parseGameEventFormSubmit(values, courts, selectedEventType),
          ),
          eventId: practiceId,
          eventType: selectedEventType,
        },
        {
          onSuccess: () => {
            redirect(
              '/practice-players',
              [
                { eventId: practiceId },
                { team: values.teams.id || values.teams[0].id },
                { goBack: '/practice' },
              ],
              ['breadcrumb'],
            )
          },
        },
      )
    }
  }

  const handlePracticePlayersInfoClick = () => {
    redirect(
      `/practice-players/${practicePlayers.id}/show`,
      [{ eventId: practiceId }, { team: values.teams.id || values.teams[0].id }],
      ['goBack'],
    )
  }

  return (
    <ConditionalRender renderValue={render}>
      <FormContainer>
        <ConditionalRender renderValue={!practicePlayers || practicePlayers?.length === 0}>
          <div className={classes.practicePlanButtonsWrapper}>
            <TextButton
              label={translate('ra.buttons.practicePlayers')}
              onClick={handlePracticePlayersClick}
            />
          </div>
        </ConditionalRender>

        <ConditionalRender renderValue={practicePlayers || practicePlayers?.length > 0}>
          <PracticePlayerInfoRow
            practicePlayers={practicePlayers}
            onContainerClick={handlePracticePlayersInfoClick}
            onCancelIconClick={handleCancePlayerslIcon}
          />
        </ConditionalRender>

        {practicePlanData ? (
          <PracticePlanInfoRow
            practicePlan={practicePlanData}
            onContainerClick={handlePracticePlanInfoClick}
            onCancelIconClick={handleCancelIcon}
          />
        ) : null}

        {!practicePlanData ? (
          <div id={ids.createPractice.plan} className={classes.practicePlanButtonsWrapper}>
            <TextButton
              label={translate('ra.buttons.choosePracticePlan')}
              onClick={handleSelectPracticePlanClick}
            />
            <TextButton
              label={translate('ra.buttons.newPracticePlan')}
              onClick={handleNewPracticePlanClick}
            />
          </div>
        ) : null}
      </FormContainer>
    </ConditionalRender>
  )
}

PracticeEventFormFooter.propTypes = {
  practicePlan: practicePlanType,
  practicePlayers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      playerName: PropTypes.string.isRequired,
      positions: PropTypes.arrayOf(PropTypes.string).isRequired,
      logoUrl: PropTypes.string.isRequired,
    }),
  ),
  practiceId: PropTypes.string,
  render: PropTypes.bool,
  selectedEventType: eventVariantType,
  createEvent: mutationType,
  updateEvent: mutationType,
  setPracticePlansModal: PropTypes.func,
  setSelectedPracticePlan: PropTypes.func,
  setSelectedPracticePlayers: PropTypes.func,
  setPracticePlanIdRedirect: PropTypes.func,
  courts: PropTypes.arrayOf(courtType),
}

export default PracticeEventFormFooter
