import * as React from 'react'
import { AutocompleteArrayInput } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import AUTOCOMPLETE_ARRAY_INPUT_STYLES from '../../../utils/constants/CSS/autocompleteArrayInputStyles'

const MyAutocompleteArrayInput = (props) => {
  const useStyles = makeStyles(AUTOCOMPLETE_ARRAY_INPUT_STYLES)
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.arrowDownWrapper}>
        <KeyboardArrowDownIcon classes={{ root: classes.arrowDown }} />
      </div>
      <div>
        <AutocompleteArrayInput
          {...props}
          fullWidth
          classes={{
            chip: classes.chip,
            chipContainerFilled: classes.chipContainerFilled,
          }}
          options={{
            classes: {
              root: classes.autocompleteInputRoot,
            },
            FormHelperTextProps: {
              classes: {
                root: classes.helperTextRoot,
              },
            },
            InputProps: {
              classes: {
                root: classes.root,
                input: classes.input,
              },
              disableUnderline: true,
            },
            InputLabelProps: {
              classes: {
                error: classes.error,
                focused: classes.focused,
                filled: classes.filled,
              },
            },
          }}
        />
      </div>
    </div>
  )
}

export default MyAutocompleteArrayInput
