import httpClient from '../../utils/helpers/httpClient'
import { BASE_URL } from '../../utils/constants/url'

const teamProvider = {
  getList: (resource, params) => {
    const { org } = params.filter
    const url = `${BASE_URL}/teams?clubId=${org}`

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json,
        total: json.length,
      }
    })
  },

  getMany: () => {
    return Promise.reject('Provider: team, Method: getMany')
  },
}

export default teamProvider
