import GAME_EVENT_FORM_BROADCAST_FIELDS from '../../constants/gameEventFormBroadcastFields'
import isCourtLivestreamEnabled from '../isCourtLivestreamEnabled'
import LIVESTREAM_FIELDS_VALIDATIONS from '../../constants/livestreamFieldsValidations'

const parseGameEventFormSubmit = (data, courts, selectedEventType, mediaIsUploading) => {
  const {
    [GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST]: isBroadcast,
    [GAME_EVENT_FORM_BROADCAST_FIELDS.IS_RECORD]: isRecordVideo,
  } = data || {}

  const eventTeams =
    selectedEventType.name === 'Game'
      ? [
          {
            teamId: data.homeTeam?.gender ? data.homeTeam.id : data.homeTeam.teamId || null,
            threeLetterIdentifier: data.homeTeam.threeLetterIdentifier || null,
            name: data.homeTeam.name,
            logoBase64: data.homeTeamLogoPreview || null,
            isHomeTeam: true,
          },
          {
            teamId: data.awayTeam?.gender?.id ? data.awayTeam.id : data.awayTeam.teamId || null,
            threeLetterIdentifier: data.awayTeam.threeLetterIdentifier || null,
            name: data.awayTeam.name,
            logoBase64: data.awayTeamLogoPreview || null,
            isHomeTeam: false,
          },
        ]
      : data.teams.map((team) => ({
          teamId: team.id || team.teamId || null,
          threeLetterIdentifier: team.threeLetterIdentifier || null,
          name: team.name || team,
          logoUrl: team.teamLogo || null,
        }))
  const isCourtLivestream = isCourtLivestreamEnabled(courts, data.courtId)
  const courtSupportsCommentary =
    courts.find((item) => item.id === data.courtId)?.supportsCommentary || false
  const isEventLivestream = isBroadcast || isRecordVideo

  const checkFieldValidation = (fieldName, validationRule, typeOfEmptyValueToReturn) => {
    const { [fieldName]: fieldValue } = data || {}
    if (!isCourtLivestream || !isEventLivestream || (validationRule && !validationRule(data)))
      return typeOfEmptyValueToReturn !== undefined ? typeOfEmptyValueToReturn : ''

    return fieldValue
  }

  const calculateGameAnnouncementTime = (values) => {
    if (!values[GAME_EVENT_FORM_BROADCAST_FIELDS.GAME_ANNOUNCEMENT_N_HOURS_BEFORE_HOURS]) {
      return `00:${values[GAME_EVENT_FORM_BROADCAST_FIELDS.GAME_ANNOUNCEMENT_N_HOURS_BEFORE_MINS].toString().padStart(2, '0')}`
    } else if (!values[GAME_EVENT_FORM_BROADCAST_FIELDS.GAME_ANNOUNCEMENT_N_HOURS_BEFORE_MINS]) {
      return `${values[GAME_EVENT_FORM_BROADCAST_FIELDS.GAME_ANNOUNCEMENT_N_HOURS_BEFORE_HOURS].toString().padStart(2, '0')}:00`
    } else
      return `${values[GAME_EVENT_FORM_BROADCAST_FIELDS.GAME_ANNOUNCEMENT_N_HOURS_BEFORE_HOURS].toString().padStart(2, '0')}:${values[GAME_EVENT_FORM_BROADCAST_FIELDS.GAME_ANNOUNCEMENT_N_HOURS_BEFORE_MINS].toString().padStart(2, '0')}`
  }

  return {
    ...data,
    eventTeams: eventTeams,
    [GAME_EVENT_FORM_BROADCAST_FIELDS.IS_RECORD]: isEventLivestream,
    [GAME_EVENT_FORM_BROADCAST_FIELDS.TYPE]: checkFieldValidation(
      GAME_EVENT_FORM_BROADCAST_FIELDS.TYPE,
      LIVESTREAM_FIELDS_VALIDATIONS[GAME_EVENT_FORM_BROADCAST_FIELDS.TYPE],
    ),
    [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY]: checkFieldValidation(
      GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY,
      LIVESTREAM_FIELDS_VALIDATIONS[GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY],
      null,
    ),
    [GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG]: checkFieldValidation(
      GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG,
      LIVESTREAM_FIELDS_VALIDATIONS[GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG],
      null,
    ),
    [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY_2]: checkFieldValidation(
      GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY_2,
      LIVESTREAM_FIELDS_VALIDATIONS[GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY_2],
      null,
    ),
    [GAME_EVENT_FORM_BROADCAST_FIELDS.IS_COMMENTARY_ON]: Boolean(
      checkFieldValidation(
        GAME_EVENT_FORM_BROADCAST_FIELDS.IS_COMMENTARY_ON,
        LIVESTREAM_FIELDS_VALIDATIONS[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_COMMENTARY_ON],
      ) && courtSupportsCommentary,
    ),
    [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_TOP_LEFT_LOGO]: checkFieldValidation(
      GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_TOP_LEFT_LOGO,
      LIVESTREAM_FIELDS_VALIDATIONS[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_LIVESTREAM_TYPE_FULL],
    ),
    [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_TOP_RIGHT_LOGO]: checkFieldValidation(
      GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_TOP_RIGHT_LOGO,
      LIVESTREAM_FIELDS_VALIDATIONS[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_LIVESTREAM_TYPE_FULL],
    ),
    [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_BOTTOM_LEFT_LOGO]: checkFieldValidation(
      GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_BOTTOM_LEFT_LOGO,
      LIVESTREAM_FIELDS_VALIDATIONS[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_LIVESTREAM_TYPE_FULL],
    ),
    [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_BOTTOM_RIGHT_LOGO]: checkFieldValidation(
      GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_BOTTOM_RIGHT_LOGO,
      LIVESTREAM_FIELDS_VALIDATIONS[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_LIVESTREAM_TYPE_FULL],
    ),
    [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_CENTER_LOGO]: checkFieldValidation(
      GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_CENTER_LOGO,
      LIVESTREAM_FIELDS_VALIDATIONS[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_LIVESTREAM_TYPE_FULL],
    ),
    [GAME_EVENT_FORM_BROADCAST_FIELDS.UPLOADED_MEDIAS]: !mediaIsUploading
      ? checkFieldValidation(
          GAME_EVENT_FORM_BROADCAST_FIELDS.UPLOADED_MEDIAS,
          LIVESTREAM_FIELDS_VALIDATIONS[GAME_EVENT_FORM_BROADCAST_FIELDS.UPLOADED_MEDIAS],
        ) || {}
      : {},
    [GAME_EVENT_FORM_BROADCAST_FIELDS.GAME_ANNOUNCEMENT_N_HOURS_BEFORE]: data[
      GAME_EVENT_FORM_BROADCAST_FIELDS.IS_GAME_ANNOUNCEMENT_ENABLED
    ]
      ? calculateGameAnnouncementTime(data)
      : null,
  }
}

export default parseGameEventFormSubmit
