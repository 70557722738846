import isNonzeroNumber from './isNonzeroNumberHelper'

const calculateBannerPosition = (containerWidth, containerHeight, itemWidth, itemHeight, index) => {
  if (
    !isNonzeroNumber(containerWidth) ||
    !isNonzeroNumber(containerHeight) ||
    !isNonzeroNumber(itemWidth) ||
    !isNonzeroNumber(itemHeight)
  )
    return {}

  const itemsPerRow = Math.floor(containerWidth / itemWidth)
  const rowIndex = Math.floor(index / itemsPerRow)
  const colIndex = index % itemsPerRow

  const columnMargin = colIndex * 20
  const rowMargin = rowIndex * 20

  const x = colIndex * itemWidth + columnMargin
  const y = rowIndex * itemHeight + rowMargin

  // The item overflows the container, so return null
  if (x >= containerWidth || y >= containerHeight) {
    return null
  }

  return { x, y }
}

export default calculateBannerPosition
