import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'
import unauthorizedPageStyles from './unauthorizedPage.styles'
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender'
import isUserOrgRolesValid from '../../utils/helpers/isUserOrgRolesValid'
import USER_ORG_ROLES from '../../utils/constants/userOrgRoles'
import ButtonWithLoader from '../../components/ui/ButtonWithLoader/ButtonWithLoader'
import useHistoryPush from '../../hooks/useHistoryPush'
import COLORS from '../../utils/constants/colors'

const UnauthorizedPage = () => {
  const translate = useTranslate()
  const { redirect } = useHistoryPush()
  const useStyles = makeStyles(unauthorizedPageStyles)
  const classes = useStyles()

  return (
    <div className={classes.flexCont}>
      <SentimentDissatisfiedIcon
        style={{
          width: '40%',
          height: '40%',
          fill: COLORS.orange,
          opacity: 0.5,
        }}
      />
      <p className={classes.flexText}>{translate('ra.text.unauthorizedPageHeader')}</p>
      <p className={classes.flexText}>{translate('ra.text.unauthorizedPageMain')}</p>

      <ConditionalRender renderValue={isUserOrgRolesValid([USER_ORG_ROLES.OWNER])}>
        <ButtonWithLoader
          label='See Subscription'
          onClick={() => {
            redirect('/subscription')
          }}
        />
      </ConditionalRender>
    </div>
  )
}

export default UnauthorizedPage
