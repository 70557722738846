const liveStreamSettings = {
  autoplay: true,
  liveui: true,
  controls: true,
  liveTracker: {
    trackingThreshold: 0,
  },
  controlBar: {
    volumePanel: {
      inline: true,
    },
  },
  userActions: {
    hotkeys: {
      enableFull: true,
    },
  },
}

export default liveStreamSettings
