import getPlayer from '../../../../Apis/player/getPlayer'

const playerIdBreadcrumb = async (data) => {
  const { match, teamName } = data || {}
  const { playerId } = match.params

  if (playerId === 'create') return

  const playerName = await getPlayer({ playerId }).then((player) => {
    return `${player?.personalData?.firstName} ${player?.personalData?.lastName}`
  })

  return [
    teamName ? teamName.toUpperCase() : '',
    'PLAYER MANAGEMENT',
    playerName ? playerName.toUpperCase() : '',
  ]
}

export default playerIdBreadcrumb
