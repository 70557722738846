import React from 'react'
import { makeStyles } from '@material-ui/core'
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

const MyZoomOutIcon = (props) => {
  const useStyles = makeStyles({
    icon: {
      cursor: 'pointer',
    },
  })
  const classes = useStyles()

  return <ZoomOutIcon {...props} classes={{ root: classes.icon }} />
}

export default MyZoomOutIcon
