import axiosInstance from '../axiosInterceptor/axiosInstance'

const getOrgQuote = async (data) => {
  const { orgId, addonType, seats } = data

  const url = orgId
    ? `/billing/subscriptions/get-quote/${addonType}?seats=${seats}&orgId=${orgId}`
    : `/billing/subscriptions/get-quote/${addonType}?seats=${seats}`
  // Make the request with orgId as a query parameter
  const response = await axiosInstance.get(url)
  return response?.data
}

export default getOrgQuote
