import React, { Fragment } from 'react'
import { makeStyles, Toolbar } from '@material-ui/core'
import { AppBar } from 'react-admin'
import { useQuery } from 'react-query'
import MoreIconMenu from '../../../../components/moreIconMenu/moreIconMenu'
import LogoIcon from '../../../../assets/icons/LogoIcon'
import useHistoryPush from '../../../../hooks/useHistoryPush'
import COLORS from '../../../../utils/constants/colors'
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb'
import SelectOrg from '../../../../components/SelectOrg/SelectOrg'
import localStorageHelper from '../../../../utils/helpers/localStorageHelper'
import SubscriptionNotice from '../../../../components/SubscriptionNotice/SubscriptionNotice'
import UseQueryParams from '../../../../hooks/useQueryParams'
import localStorageIds from '../../../../utils/constants/localStorageIds'
import redirectToHomeHelper from '../../../../utils/helpers/redirectToHomeHelper'
import QueryKeys from '../../../../utils/constants/queryKeys'
import getTeams from '../../../../Apis/team/getTeams'
import myAppbarStyles from './MyAppbar.styles'
import TOUR_IDS from '../../../../utils/constants/introIds'
import ConditionalRender from '../../../../components/ConditionalRender/ConditionalRender'
import userHasOnlySpecificScopes from '../../../../utils/helpers/userHasOnlySpecificScopes'
import { USER_SCOPES } from '../../../../utils/constants/userScopes'

const MyAppBar = (props) => {
  const queryParams = UseQueryParams()
  const { org } = queryParams
  const { redirect } = useHistoryPush()
  const { removeLocalStorageValue } = localStorageHelper(localStorageIds.SELECTED_TEAM)
  const { localStorageValue: selectedOrg } = localStorageHelper(localStorageIds.SELECTED_ORG)
  const useStyles = makeStyles(myAppbarStyles)
  const classes = useStyles()

  const { data } = useQuery([QueryKeys.GET_TEAMS, org], () => org && getTeams(org))

  const handleLogoClick = () => {
    removeLocalStorageValue()

    if (!org) {
      return redirect('/')
    }

    return redirectToHomeHelper(data, redirect, selectedOrg)
  }

  return (
    <AppBar
      {...props}
      classes={{ toolbar: classes.toolbar }}
      container={Fragment}
      position='sticky'
      elevation={0}
      userMenu={false}
      style={{
        color: COLORS.orange,
        marginTop: '0px',
        backgroundColor: '#FFFFFF',
        borderBottom: '1px solid #E8E8E8',
        paddingRight: 0,
      }}
    >
      <div className={classes.leftAppbarWrapper}>
        <Breadcrumb />
        <Toolbar variant='regular' />
      </div>

      <LogoIcon
        onClick={handleLogoClick}
        style={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />

      <div className={classes.rightAppbarWrapper}>
        <ConditionalRender
          renderValue={!!org && !userHasOnlySpecificScopes([USER_SCOPES.LIVESTREAM])}
        >
          <SubscriptionNotice />
        </ConditionalRender>
        <div id={TOUR_IDS.calendar.orgName} style={{ display: 'flex', flexDirection: 'row' }}>
          <SelectOrg />
          {!org ? <div className={classes.marginPlaceholder} /> : <MoreIconMenu />}
        </div>
      </div>
    </AppBar>
  )
}

export default MyAppBar
