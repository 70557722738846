import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Masonry from 'react-masonry-css'
import DrillBox from '../DrillBox/DrillBox'
import drillsMasonryStyles from './drillsMasonry.styles'
import MASONRY_BREAKPOINTS from '../../utils/constants/masonryBreakpoints'
import DrillBoxLite from '../DrillBoxLite/DrillBoxLite'
import trainingModuleListType from '../../types/trainingModuleListType'
import trainingModuleType from '../../types/trainingModuleType'
import useQueryParams from '../../hooks/useQueryParams'
import useHistoryPush from '../../hooks/useHistoryPush'

const DrillsMasonry = ({
  drills,
  toggleAccess,
  trModuleType,
  isModal,
  drillClicked,
  handleDrillClick,
  downloadedTrainingModules,
}) => {
  const useStyles = makeStyles(drillsMasonryStyles(isModal))
  const classes = useStyles()
  const breakpoint = isModal ? MASONRY_BREAKPOINTS.MODAL : MASONRY_BREAKPOINTS.LIST
  const { redirect } = useHistoryPush()
  const queryParams = useQueryParams()
  const orgQueryString = queryParams?.org
  const teamQueryString = queryParams?.tea

  const showRedirect = (trainingModuleId) => {
    redirect(`${trModuleType}/${trainingModuleId}/show`, [
      { org: orgQueryString, team: queryParams?.team ? teamQueryString : '' },
    ])
  }

  return (
    <Masonry
      breakpointCols={breakpoint}
      className={classes.myMasonryGrid}
      columnClassName={classes.myMasonryGridColumn}
    >
      {drills &&
        drills.length > 0 &&
        drills.map(
          (elements) =>
            elements.trainingModulesDetailsDto.length > 0 &&
            elements.trainingModulesDetailsDto.map((element) => {
              if (isModal) {
                return (
                  <div key={element.id}>
                    <DrillBoxLite
                      drill={element}
                      toggleAccess={toggleAccess}
                      handleDrillClick={() => handleDrillClick(element)}
                      loading={drillClicked === element.id}
                      usersDownloaded={downloadedTrainingModules?.find(
                        (trainingModule) => trainingModule.originalTrainingModuleId === element.id,
                      )}
                    />
                  </div>
                )
              }
              return (
                <div key={element.id} onClick={() => showRedirect(element.id)}>
                  <DrillBox
                    drill={element}
                    toggleAccess={toggleAccess}
                    trModuleType={trModuleType}
                    usersDownloaded={
                      downloadedTrainingModules?.filter(
                        (trainingModule) => trainingModule.originalTrainingModuleId === element.id,
                      )[0]
                    }
                  />
                </div>
              )
            }),
        )}
    </Masonry>
  )
}

DrillsMasonry.propTypes = {
  drills: PropTypes.arrayOf(trainingModuleListType),
  toggleAccess: PropTypes.string,
  trModuleType: PropTypes.string,
  isModal: PropTypes.bool,
  drillClicked: trainingModuleType,
  handleDrillClick: PropTypes.func,
  downloadedTrainingModules: PropTypes.arrayOf(trainingModuleType),
}

export default DrillsMasonry
