import React from 'react'
import { makeStyles, MenuItem, Popover } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { useTranslate } from 'react-admin'
import { v4 } from 'uuid'
import addPracticePlanTypePopoverStyles from './addPracticePlanTypePopover.styles'
import { usePracticePlanContext } from '../../context/usePracticePlanContext'

const AddPracticePlanTypePopover = (props) => {
  const { open, onClose, anchorEl, openDrillsListModal, openDrillCreateModal } = props
  const { practicePlan, setPracticePlan } = usePracticePlanContext()
  const translate = useTranslate()
  const useStyles = makeStyles(addPracticePlanTypePopoverStyles)
  const classes = useStyles()

  const handleTextButtonClick = () => {
    setPracticePlan({
      ...practicePlan,
      practicePlanComponents: [
        ...(practicePlan?.practicePlanComponents ?? []),
        {
          tempId: v4(),
          notes: '',
          duration: 0,
          order:
            practicePlan && practicePlan?.practicePlanComponents
              ? Math.max(...practicePlan?.practicePlanComponents.map((comp) => comp.order)) + 1
              : 0,
        },
      ],
    })
    onClose()
  }

  const handleCreateNewDrillClick = () => {
    openDrillCreateModal()
  }

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{ paper: classes.popover }}
    >
      <MenuItem onClick={handleTextButtonClick}>{translate('ra.text.text')}</MenuItem>
      <MenuItem onClick={openDrillsListModal}>{translate('ra.text.drill')}</MenuItem>
      <MenuItem onClick={handleCreateNewDrillClick}>
        <AddCircleIcon style={{ width: '22', height: '22', marginRight: 5, fill: '#E0726E' }} />
        {translate('ra.text.createNewDrill')}
      </MenuItem>
    </Popover>
  )
}

export default AddPracticePlanTypePopover
