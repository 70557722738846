const drillListAppbarStyles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 25,
    gap: 20,
  },
  buttonsWrapper: {
    display: 'flex',
    marginLeft: 'auto',
    gap: 10,
  },
}

export default drillListAppbarStyles
