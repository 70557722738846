import React, { useEffect, useState } from 'react'
import { Field } from 'formik'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import MyTextField from '../../../fields/MyTextField'
import ConditionalRender from '../../../../ConditionalRender/ConditionalRender'
import MyAutocomplete from '../../../fields/MyAutocomplete'
import { countryCodeToFlag } from '../../../../../utils/helpers/countryCodeToFlag'
import addOrgFormBodyStyles from './addOrgFormBody.styles'
import mutationType from '../../../../../types/mutationType'
import countryType from '../../../../../types/countryType'

const AddOrgFormBody = (props) => {
  const { countries, addOrg, nameTextFieldInfo } = props
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const translate = useTranslate()
  const useStyles = makeStyles((theme) => addOrgFormBodyStyles(theme))
  const classes = useStyles()

  useEffect(() => {
    const errorMessage = addOrg?.error?.message

    if (errorMessage && errorMessage === 'OrgName exists') {
      setShowErrorMessage(true)
    }
  }, [addOrg])

  return (
    <Grid container spacing={2} direction='column'>
      <Grid item>
        <MyTextField
          name={nameTextFieldInfo?.name ?? 'name'}
          label={nameTextFieldInfo?.label ?? translate('ra.formFields.name')}
          variant='filled'
          required
          helperText={showErrorMessage ? translate('ra.errorMessages.orgNameExists') : ''}
        />
        <ConditionalRender renderValue={showErrorMessage}>
          <div className={classes.errorMessage}>{translate('ra.errorMessages.orgNameExists')}</div>
        </ConditionalRender>
      </Grid>
      <Grid item>
        <Field
          name='country'
          label='Country'
          required
          options={countries}
          component={MyAutocomplete}
          getOptionLabel={(option) => option.name}
          renderOption={(option) => {
            return (
              <>
                <span style={{ marginRight: 10 }}>{countryCodeToFlag(option.code)}</span>
                {option.name} ({option.code})
              </>
            )
          }}
        />
      </Grid>
      <Grid item>
        <MyTextField
          label={translate('ra.formFields.city')}
          name='city'
          variant='filled'
          required
        />
      </Grid>
    </Grid>
  )
}

AddOrgFormBody.propTypes = {
  countries: PropTypes.arrayOf(countryType),
  addOrg: mutationType,
  nameTextFieldInfo: PropTypes.shape({
    name: Promise.name,
    label: PropTypes.string,
  }),
}

AddOrgFormBody.defaultProps = {
  countries: [],
}

export default AddOrgFormBody
