import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import { DialogActions, Divider, makeStyles, Typography } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import ContainerPadding from '../../../components/containerPadding/containerPadding'
import SetRolesMemberFields from './SetRolesMemberFields/SetRolesMemberFields'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import setRolesModalStyles from './setRolesModal.styles'
import TextButton from '../../../components/ui/TextButton/TextButton'
import isObjectEmptyHelper from '../../../utils/helpers/isObjectEmptyHelper'
import AvailableSeatsDisplay from '../AvailableSeatsDisplay/AvailableSeatsDisplay'

const SetRolesModal = (props) => {
  const { invitations, subscriptionDetails, onClose, inviteUsersMutation, orgId, userId } = props
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [areSeatsValid, setAreSeatsValid] = useState(false)
  // Take it from sub details
  const translate = useTranslate()
  const useStyles = makeStyles(setRolesModalStyles)
  const classes = useStyles()
  const selectableAddons = subscriptionDetails?.addons
    ?.filter((addon) => !addon.isOrgLevel)
    .map((addon) => addon.id)

  const handleCloseSnackbar = () => {
    setIsSnackbarOpen(false)
  }

  const onSubmit = (values) => {
    const invitationValues = values.invitations.map((item) => {
      const teamRoles = {}

      if (!item.isAdmin) {
        item.roles.forEach((teamRole) => {
          // Checks if new team added without selecting any option. Could become differently
          if (Object.values(teamRole).filter((value) => value !== null).length > 1)
            teamRoles[teamRole.Team.id] = teamRole.Role.replace(/\s/g, '')
        })
      }

      return {
        ...item,
        scopes: selectableAddons.length > 1 ? item.scopes : [selectableAddons[0]],
        roles: !isObjectEmptyHelper(teamRoles) ? teamRoles : undefined,
      }
    })

    const invitationPayload = {
      senderId: userId,
      orgId,
      invitations: invitationValues,
    }
    inviteUsersMutation.mutate(invitationPayload)
  }

  const validate = (values) => {
    const errorsCreate = {}
    if (values.invitations.some((inv) => inv.orgScopes?.length === 0)) {
      errorsCreate.teams = translate('ra.validationErrors.requiredField')
    }

    return errorsCreate
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          invitations: invitations.map((inv) => {
            return {
              receiverEmail: inv.receiverEmail,
              isOwner: false,
              isAdmin: false,
              scopes: [],
              roles: [{}],
            }
          }),
        }}
        validate={validate}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ values }) => (
          <Form style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <ContainerPadding paddingValue='2%' style={{ position: 'relative', overflow: 'auto' }}>
              {invitations.map((inv, index) => (
                <div key={inv.name}>
                  <Divider />
                  <Typography name={`invitations[${index}].name`} className={classes.userName}>
                    {inv.name}
                  </Typography>
                  <SetRolesMemberFields orgScopeOptions={selectableAddons} index={index} />
                </div>
              ))}
            </ContainerPadding>
            <div className={classes.dialogActions}>
              <Divider />
              <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <AvailableSeatsDisplay
                  orgAddonSeat={subscriptionDetails?.addons}
                  addedScopes={values.invitations}
                  setAreSeatsValid={setAreSeatsValid}
                />
                <ButtonWithLoader
                  label={translate('ra.buttons.invite')}
                  disabled={areSeatsValid}
                  loading={inviteUsersMutation.isLoading}
                />
              </DialogActions>
            </div>
          </Form>
        )}
      </Formik>
      <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert variant='filled' severity='error'>
          {translate('ra.errorMessages.invitationSendError')}
        </Alert>
      </Snackbar>
    </>
  )
}

SetRolesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  invitations: PropTypes.array.isRequired,
  setInvitations: PropTypes.func,
  userId: PropTypes.string,
  inviteUsersMutation: PropTypes.func,
  subscriptionDetails: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    totalSeats: PropTypes.number,
    allocatedSeats: PropTypes.number,
    availableSeats: PropTypes.number,
    nextBillingDate: PropTypes.string,
    vivaPaymentOrder: PropTypes.any,
    vivaPaymentOrderInitialTransactionId: PropTypes.any,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    streetNumber: PropTypes.string,
    zipCode: PropTypes.string,
    country: PropTypes.string,
    companyName: PropTypes.string,
    vatNumber: PropTypes.string,
    activity: PropTypes.string,
    needsInvoice: PropTypes.bool,
    addons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        pricing: PropTypes.string,
        seats: PropTypes.shape({
          all: PropTypes.number,
          allocated: PropTypes.number,
          free: PropTypes.number,
        }),
        enabled: PropTypes.bool,
        userActivatable: PropTypes.bool,
      }),
    ),
    cardFourLastDigits: PropTypes.string,
    coupon: PropTypes.any,
  }),
}
export default SetRolesModal
