import * as React from 'react'
import { useTranslate } from 'react-admin'

const SignUpFormAcceptTerms = () => {
  const translate = useTranslate()

  return <div dangerouslySetInnerHTML={{ __html: translate('ra.formFields.acceptTerms') }} />
}

export default SignUpFormAcceptTerms
