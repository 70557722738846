import introJs from 'intro.js'
import { debounce } from 'lodash'
import { useEffect } from 'react'
import { useTranslate } from 'react-admin'
import usePrevious from './usePrevious'
import isUserScopesValid from '../utils/helpers/isUserScopesValid'
import createSteps from '../utils/constants/createSteps'
import { setLocalStorageValue } from '../utils/helpers/localStorageHelperMethods'

// enabled: indicates whether the tour is starting or not
// tourName: the string that provides the tour name
// steps: a function that returns an array of steps for the tour
// scope: the scopes of the user that are allowed to see the tour

export default function useTour({ elRef, tourName, tourSteps, scope = 'Coach', tourDesc = null }) {
  const previousEl = usePrevious(elRef)
  const translate = useTranslate()
  const intro = introJs()

  const shouldTourStart = () => {
    const tourNameValue = window.localStorage.getItem(tourName)
    if (!tourNameValue) return true
    if (!JSON.parse(tourNameValue)) return true
    if (tourNameValue !== tourDesc && !!tourDesc) return true
    return false
  }
  const stepIsNotHidden = (step) => {
    if (!Object.prototype.hasOwnProperty.call(step, 'element')) {
      return true
    }
    return step.element && !!step.element.getClientRects().length
  }

  // We use the enable variable to stop intro from running again every time the component is rerendering.
  useEffect(() => {
    if (previousEl !== elRef && isUserScopesValid(scope) && shouldTourStart()) {
      const steps = createSteps(tourSteps, translate)
      if (!steps) {
        return
      }
      intro.setOptions({
        exitOnEsc: false,
        exitOnOverlayClick: false,
        overlayOpacity: 0.8,
        skipLabel: 'Skip',
        nextLabel: 'Next',
        prevLabel: 'Previous',
        doneLabel: 'Finish!',
        showBullets: false,
        showProgress: steps?.length > 1,
        showStepNumbers: false,
        disableInteraction: false,
        steps: steps.filter((step) => stepIsNotHidden(step)),
        showButtons: steps?.length > 1,
      })
      intro.start()
      const upDateTourSteps = debounce(() => {
        // We update the tour steps every time the window resizes to avoid showing intro on hidden objects
        if (steps) {
          intro.setOptions({ steps: steps.filter((step) => stepIsNotHidden(step)) })
          intro.refresh(true)
        }
      }, 200)

      window.addEventListener('resize', upDateTourSteps)
      intro.onexit(() => {
        window.removeEventListener('resize', upDateTourSteps)
        // We create the key when user exits the intro
        if (window.history.state && !window.history.state.introBackButton) {
          setLocalStorageValue([
            {
              id: tourName,
              value: tourDesc !== null ? tourDesc : true,
            },
          ])
        }
      })
      intro.oncomplete(() => {
        window.removeEventListener('resize', upDateTourSteps)
        // We create the key when user completes the intro
        setLocalStorageValue([
          {
            id: tourName,
            value: tourDesc !== null ? tourDesc : true,
          },
        ])
      })
    }
  }, [elRef, intro, previousEl, scope, tourName, tourSteps, translate])
  // Close Intro.js when the back button is pressed
  window.addEventListener('popstate', () => {
    window.history.replaceState({ introBackButton: true }, document.title)
    intro.exit()
  })
}
