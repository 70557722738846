import React from 'react'
import { useTranslate } from 'react-admin'
import { DialogActions } from '@material-ui/core'
import PropTypes from 'prop-types'
import TextButton from '../../../../ui/TextButton/TextButton'
import COLORS from '../../../../../utils/constants/colors'
import ButtonWithLoader from '../../../../ui/ButtonWithLoader/ButtonWithLoader'
import DialogWithCloseIcon from '../../../../DialogWithCloseIcon/DialogWithCloseIcon'
import MutationType from '../../../../../types/mutationType'

const CancelSubscriptionDialogConfirmation = (props) => {
  const { isOpen, onClose, cancelSubscription } = props
  const translate = useTranslate()

  return (
    <div>
      <DialogWithCloseIcon
        open={isOpen}
        onClose={onClose}
        dialogWidth='48%'
        dialogTitle={translate('ra.text.cancelSubscriptionConfirmation')}
      >
        <DialogActions>
          <TextButton label={translate('ra.buttons.no')} onClick={onClose} />
          <ButtonWithLoader
            label={translate('ra.buttons.cancelSubscription')}
            style={{ backgroundColor: COLORS.red }}
            onClick={() => cancelSubscription.mutate()}
            loading={cancelSubscription.isLoading}
            type='button'
          />
        </DialogActions>
      </DialogWithCloseIcon>
    </div>
  )
}

CancelSubscriptionDialogConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  cancelSubscription: MutationType,
}

export default CancelSubscriptionDialogConfirmation
