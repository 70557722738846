const MyFilterIcon = (props) => {
  return (
    <svg
      {...props}
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='9.5' cy='9.5' r='9.5' fill='white' />
      <path d='M8.16667 14.5H10.8333V13.1667H8.16667V14.5ZM3.5 6.5V7.83333H15.5V6.5H3.5ZM5.5 11.1667H13.5V9.83333H5.5V11.1667Z' />
    </svg>
  )
}

export default MyFilterIcon
