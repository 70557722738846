import * as React from 'react'
import { Avatar, makeStyles } from '@material-ui/core'
import { v4 } from 'uuid'
import PropTypes, { arrayOf, objectOf } from 'prop-types'
import drillsSliderPositionsStyles from './drillsSliderPositions..styles'

const DrillSliderPositions = ({ positions, isZoomedIn }) => {
  const useStyles = makeStyles(drillsSliderPositionsStyles(isZoomedIn))
  const classes = useStyles()

  const allPosition =
    positions && positions.length > 0 && positions.find((position) => position.name === 'ALL')
  if (allPosition) return null

  return (
    <div className={classes.positionsWrapper}>
      {positions &&
        positions.map((position) => (
          <Avatar key={v4()} classes={{ root: classes.avatarRoot }}>
            {position.name.charAt(0)}
          </Avatar>
        ))}
    </div>
  )
}

DrillSliderPositions.propTypes = {
  positions: arrayOf(objectOf(PropTypes.string)),
  isZoomedIn: PropTypes.bool || undefined,
}

export default DrillSliderPositions
