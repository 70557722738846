import { shape, string, number } from 'prop-types'

const courtCameraType = shape({
  id: string,
  installed: string,
  password: string,
  rtspPort: number,
  username: string,
})

export default courtCameraType
