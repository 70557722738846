import BROADCAST_STATES from '../constants/broadcastStates'
import GAME_EVENT_FORM_BROADCAST_FIELDS from '../constants/gameEventFormBroadcastFields'

// Based on broadcast status disable field.
export const isFieldDisabled = (broadcastState, name) => {
  if (broadcastState && broadcastState !== BROADCAST_STATES.scheduled) {
    if (broadcastState === BROADCAST_STATES.running || broadcastState === BROADCAST_STATES.paused) {
      if (
        name === 'endTime' ||
        name === GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY ||
        name === GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_HOME_NAME ||
        name === GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_AWAY_NAME ||
        name === GAME_EVENT_FORM_BROADCAST_FIELDS.TYPE ||
        name === GAME_EVENT_FORM_BROADCAST_FIELDS.IS_ADDITIONAL_YOUTUBE_STREAM_KEY_ADDED ||
        name === GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY_2
      ) {
        return false
      }
    }

    return true
  }

  return false
}

export const isTeamLogoDisabled = (broadcastState) => {
  return (
    broadcastState &&
    (broadcastState === BROADCAST_STATES.finished ||
      broadcastState === BROADCAST_STATES.cancelled ||
      broadcastState === BROADCAST_STATES.errored ||
      broadcastState === BROADCAST_STATES.creating ||
      broadcastState === BROADCAST_STATES.deleting)
  )
}

export const isTeamFieldsAndCompDisabled = (broadcastState) => {
  return (
    broadcastState &&
    (broadcastState === BROADCAST_STATES.finished ||
      broadcastState === BROADCAST_STATES.cancelled ||
      broadcastState === BROADCAST_STATES.errored ||
      broadcastState === BROADCAST_STATES.creating ||
      broadcastState === BROADCAST_STATES.deleting)
  )
}
