import COLORS from '../../../utils/constants/colors'

const subscriptionPageStyles = {
  root: {
    overflow: 'auto',
    flexGrow: 1,
    margin: '20px',
  },
  container: {
    margin: '4%',
  },
  cloudIcon: { width: '75px', height: '75px', color: '#096BDE' },
  content: { width: '48%', display: 'table' },
  indicator: {
    color: '#096BDE',
  },
  columns: { display: 'flex', justifyContent: 'space-between' },
  form: { width: '100%', height: '100%' },
  addonCard: {
    height: '90px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '7%',
  },
  addonText: { margin: '10px' },
  typographyBold: { fontWeight: 'bold' },
  typographyLight: { color: COLORS.lightGrey },
}

export default subscriptionPageStyles
