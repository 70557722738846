import React, { useEffect, useState } from 'react'
import { Drawer, makeStyles } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import { useSelector } from 'react-redux'
import usePrevious from '../../hooks/usePrevious'
import useResize from '../../hooks/useResize'
import TABLET_SCREEN_WIDTH from '../../utils/constants/tabletScreenWidth'
import MyProvidedMenu from '../../react-admin/components/layout/MyMenu/MyMenu'
import drawerMenuSmallScreenStyles from './drawerMenuSmallScreen.styles'
import useHistoryPush from '../../hooks/useHistoryPush'

const DrawerMenuSmallScreen = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const isSidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen)
  const isSidebarOpenPreviousValue = usePrevious(isSidebarOpen)
  const { pathname } = useHistoryPush()
  const pathnamePreviousValue = usePrevious(pathname)

  const { width: screenWidth } = useResize(document?.body)
  const isSmallScreen = screenWidth !== 0 && screenWidth < TABLET_SCREEN_WIDTH

  const useStyles = makeStyles(drawerMenuSmallScreenStyles)
  const classes = useStyles()

  // When redirect close drawer menu
  useEffect(() => {
    if (
      isSmallScreen &&
      isDrawerOpen &&
      pathname &&
      pathnamePreviousValue &&
      pathname !== pathnamePreviousValue
    ) {
      setIsDrawerOpen(false)
    }
  }, [isSmallScreen, isDrawerOpen, pathname, pathnamePreviousValue])

  // React-admin (3.18) doesn't provide us a way of knowing when appbar menu icon is clicked
  // So in order to open drawer we compare sidebar current value with previous one.
  useEffect(() => {
    if (isSmallScreen && isSidebarOpenPreviousValue === true && isSidebarOpen === false) {
      setIsDrawerOpen(true)
    }
  }, [isSmallScreen, isSidebarOpen, isSidebarOpenPreviousValue])

  const handleCloseDrawer = () => setIsDrawerOpen(false)

  return (
    <Drawer
      open={isDrawerOpen}
      onClose={handleCloseDrawer}
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={classes.menuIconWrapper}>
        <IconButton aria-label='menu icon' component='span' onClick={handleCloseDrawer}>
          <MenuIcon className={classes.menuIcon} />
        </IconButton>
      </div>
      <MyProvidedMenu isSmallScreenMenuOpen={isDrawerOpen} />
    </Drawer>
  )
}

export default DrawerMenuSmallScreen
