import COLORS from '../../utils/constants/colors'

const deleteModalStyles = {
  dialogActions: {
    padding: 0,
  },
  cancelButton: {
    '&&': {
      color: COLORS.red,
    },
  },
  deleteButton: {
    '&&': {
      backgroundColor: COLORS.red,
      color: COLORS.white,
      '&:hover': {
        backgroundColor: COLORS.red,
      },
    },
  },
  subtitle: {
    marginBottom: '10px',
  },
}

export default deleteModalStyles
