import COLORS from '../../../utils/constants/colors'

const forgotPasswordFormStyles = {
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 15,
  },
  link: {
    color: COLORS.purple,
  },
}

export default forgotPasswordFormStyles
