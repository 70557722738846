import TACTICS_COLORS from '../constants/tacticsColors'

const setTacticsColor = (tactics) => {
  if (!tactics || tactics?.length === 0) return []

  return tactics.map((tactic) => {
    const type = tactic?.type ? tactic?.type : tactic?.name

    return {
      ...tactic,
      color: TACTICS_COLORS[type],
    }
  })
}

export default setTacticsColor
