import React from 'react'
import { Button, DialogActions, makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import deleteModalStyles from './deleteModal.styles'
import ButtonWithLoader from '../ui/ButtonWithLoader/ButtonWithLoader'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import DialogWithCloseIcon from '../DialogWithCloseIcon/DialogWithCloseIcon'

const DeleteModal = (props) => {
  const { title, subtitle, open, onClose, handleDelete, loading, buttonLabel, dialogWidth } = props
  const translate = useTranslate()
  const useStyles = makeStyles(deleteModalStyles)
  const classes = useStyles()

  return (
    <DialogWithCloseIcon
      open={open}
      onClose={onClose}
      dialogTitle={title}
      hideCloseIcon
      dialogWidth={dialogWidth ?? '48%'}
    >
      <>
        <ConditionalRender renderValue={subtitle}>
          <div className={classes.subtitle}>{subtitle}</div>
        </ConditionalRender>
        <DialogActions className={classes.dialogActions}>
          <Button variant='text' classes={{ root: classes.cancelButton }} onClick={onClose}>
            {translate('ra.buttons.cancel')}
          </Button>
          <ButtonWithLoader
            label={buttonLabel ?? translate('ra.buttons.delete')}
            className={classes.deleteButton}
            onClick={(e) => handleDelete(e)}
            loading={loading}
          />
        </DialogActions>
      </>
    </DialogWithCloseIcon>
  )
}

DeleteModal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleDelete: PropTypes.func,
  loading: PropTypes.bool,
  buttonLabel: PropTypes.string,
  dialogWidth: PropTypes.string,
}

export default DeleteModal
