import React from 'react'
import { useFormikContext } from 'formik'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import CancelIcon from '@material-ui/icons/Cancel'
import MyLiveTvIcon from '../../../assets/icons/MyLiveTvIcon'
import MyTextField from '../../form/fields/MyTextField'
import GAME_EVENT_FORM_BROADCAST_FIELDS from '../../../utils/constants/gameEventFormBroadcastFields'
import { isFieldDisabled } from '../../../utils/helpers/isFieldDisabled'
import AddButton from '../../ui/AddButton/AddButton'
import ConditionalRender from '../../ConditionalRender/ConditionalRender'
import streamFieldsStyles from './streamFields.styles'

const ExtraStreamField = ({ state }) => {
  const { values, setFieldValue } = useFormikContext()
  const translate = useTranslate()
  const useStyles = makeStyles(streamFieldsStyles)
  const classes = useStyles()

  const isAdditionalYoutubeStreamKeyAdded =
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.IS_ADDITIONAL_YOUTUBE_STREAM_KEY_ADDED]

  const toggleExtraStreamKeyValue = () =>
    setFieldValue(
      GAME_EVENT_FORM_BROADCAST_FIELDS.IS_ADDITIONAL_YOUTUBE_STREAM_KEY_ADDED,
      isAdditionalYoutubeStreamKeyAdded !== true,
    )

  return (
    <>
      <ConditionalRender renderValue={!isAdditionalYoutubeStreamKeyAdded}>
        <div className='div-flex'>
          <AddButton
            label={translate('ra.formFields.addAdditionalStreamKey')}
            classNameText={classes.addButtonText}
            disabled={isFieldDisabled(
              state,
              GAME_EVENT_FORM_BROADCAST_FIELDS.IS_ADDITIONAL_YOUTUBE_STREAM_KEY_ADDED,
            )}
            onClick={toggleExtraStreamKeyValue}
            filled
          />
        </div>
      </ConditionalRender>

      <ConditionalRender renderValue={isAdditionalYoutubeStreamKeyAdded}>
        <div className='div-flex'>
          <MyLiveTvIcon className={classes.tvIcon} />
          <MyTextField
            name={GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY_2}
            label={translate('ra.formFields.streamKeyURI2')}
            disabled={isFieldDisabled(state, GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY_2)}
          />
          <CancelIcon className={classes.cancelIcon} onClick={toggleExtraStreamKeyValue} />
        </div>
      </ConditionalRender>
    </>
  )
}

ExtraStreamField.propTypes = {
  state: PropTypes.string,
}

export default ExtraStreamField
