import React from 'react'
import { Avatar, makeStyles, TableCell, Typography } from '@material-ui/core'
import DefaultPlayerIcon from '../../../../assets/icons/defaultPlayerIcon'
import userFieldStyles from './userField.styles'

const UserField = ({ label, row, columnAlignment, rowIsSelected, hasNoDivider, logoName }) => {
  const useStyles = makeStyles(userFieldStyles(columnAlignment))
  const classes = useStyles()

  return (
    <TableCell
      align={columnAlignment}
      style={{
        borderBottom: hasNoDivider ? 'none' : '1px solid rgba(224, 224, 224, 1)',
      }}
    >
      <div className={classes.cellContainer}>
        {row[logoName] ? (
          <Avatar alt='avatar' src={row[logoName]} className={classes.userNameFieldAvatar} />
        ) : (
          <DefaultPlayerIcon />
        )}
        <Typography className={classes.typo} color={rowIsSelected ? 'primary' : undefined}>
          {label}
        </Typography>
      </div>
    </TableCell>
  )
}

export default UserField
