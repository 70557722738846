import React from 'react'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import { DialogActions, makeStyles } from '@material-ui/core'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import TextButton from '../../../components/ui/TextButton/TextButton'
import DialogWithCloseIcon from '../../../components/DialogWithCloseIcon/DialogWithCloseIcon'
import drillCreateFormEditDrillModalStyles from './drillCreateFormEditDrillModal.styles'
import useHistoryPush from '../../../hooks/useHistoryPush'

const DrillCreateFormEditDrillModal = (props) => {
  const { isOpen, onClose, setShouldUpdateGlobally, pathname } = props
  const translate = useTranslate()
  const { goBack } = useHistoryPush()
  const useStyles = makeStyles(drillCreateFormEditDrillModalStyles)
  const classes = useStyles()

  const onEditSpecificDrillClick = () => {
    setShouldUpdateGlobally(false)
    onClose()
  }
  const onEditEverywhereDrillClick = () => {
    setShouldUpdateGlobally(true)
    onClose()
  }
  const onCancelDrillClick = () => {
    onClose()
    goBack()
  }

  return (
    <DialogWithCloseIcon
      open={isOpen}
      hideCloseIcon
      dialogWidth='48%'
      dialogTitle={
        pathname === 'practice-plan'
          ? translate('ra.text.drillUpdateModeQuestionPP')
          : translate('ra.text.drillUpdateModeQuestionPlaybook')
      }
    >
      <DialogActions>
        <TextButton
          className={classes.cancelButton}
          label={translate('ra.buttons.cancel')}
          onClick={onCancelDrillClick}
        />
        <ButtonWithLoader
          label={translate('ra.buttons.drillUpdateModeGeneral')}
          onClick={onEditEverywhereDrillClick}
          loading={false}
          type='button'
        />
        <ButtonWithLoader
          label={
            pathname === 'practice-plan'
              ? translate('ra.buttons.drillUpdateModePP')
              : translate('ra.buttons.drillUpdateModePlaybook')
          }
          onClick={onEditSpecificDrillClick}
          loading={false}
          type='button'
        />
      </DialogActions>
    </DialogWithCloseIcon>
  )
}

DrillCreateFormEditDrillModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  setShouldUpdateGlobally: PropTypes.func,
  pathname: PropTypes.string,
}

export default DrillCreateFormEditDrillModal
