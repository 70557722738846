import React from 'react'
import { Divider } from '@material-ui/core'
import ProfileSettingsInfoForm from '../ProfileSettingsInfoForm/ProfileSettingsInfoForm'
import ProfileSettingsInfoEmailForm from '../ProfileSettingsInfoEmailForm/ProfileSettingsInfoEmailForm'
import userType from '../../../types/userType'

const ProfileSettingsInfo = ({ userData }) => (
  <>
    <ProfileSettingsInfoForm userData={userData} />
    <Divider />
    <ProfileSettingsInfoEmailForm userData={userData} />
  </>
)

ProfileSettingsInfo.propTypes = {
  userData: userType,
}

export default ProfileSettingsInfo
