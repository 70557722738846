import { fetchUtils } from 'react-admin'
import localStorageIds from '../constants/localStorageIds'

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }

  const token = localStorage.getItem(localStorageIds.TOKEN)
  options.headers.set('Authorization', `Bearer ${token}`)
  options.headers.set('Club-Id', JSON.parse(localStorage.getItem(localStorageIds.SELECTED_ORG))?.id)
  return fetchUtils.fetchJson(url, options)
}

export default httpClient
