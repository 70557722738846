import COLORS from '../../../utils/constants/colors'

const profileSettingsCloseAccountStyles = {
  cardTitle: {
    marginBottom: 15,
    marginLeft: 10,
    fontWeight: 500,
  },
  subtitle: {
    marginLeft: 10,
    marginBottom: 15,
    color: COLORS.darkGrey,
    lineHeight: '10px',
  },
  disableWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  disableButtonRoot: {
    marginTop: 15,
  },
}

export default profileSettingsCloseAccountStyles
