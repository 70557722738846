const subscriptionNoticeStyles = (theme) => {
  const RESPONSIVE_SMALL_SCREEN = 940

  return {
    card: {
      [theme.breakpoints.down(RESPONSIVE_SMALL_SCREEN)]: {
        cursor: 'pointer',
      },
    },
    successCard: {
      height: '40px',
      marginRight: '10px',
      backgroundColor: '#F4FAFF',
      display: 'flex',
      alignItems: 'center',
    },
    failureCard: {
      height: '40px',
      marginRight: '10px',
      backgroundColor: '#FFF4E5',
      display: 'flex',
      alignItems: 'center',
    },
    successCloudIcon: {
      width: '25px',
      height: '25px',
      color: '#096BDE',
      marginRight: '10px',
      marginLeft: '10px',
    },
    failureCloudIcon: {
      width: '25px',
      height: '25px',
      color: '#ED6C02',
      marginRight: '10px',
      marginLeft: '10px',
    },
    tooltip: {
      [theme.breakpoints.up(RESPONSIVE_SMALL_SCREEN)]: {
        display: 'none',
      },
    },
    trialPeriodText: {
      [theme.breakpoints.down(RESPONSIVE_SMALL_SCREEN)]: {
        display: 'none',
      },
    },
    successTypographyBold: { fontWeight: 'bold', color: '#096BDE', marginRight: '10px' },
    failureTypographyBold: { fontWeight: 'bold', marginRight: '10px' },
    typographySimple: { color: '#096BDE', marginRight: '10px' },
  }
}

export default subscriptionNoticeStyles
