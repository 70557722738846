const drillsMasonryStyles = () => ({
  myMasonryGrid: {
    display: 'flex',
    marginLeft: '-30px',
    width: 'auto',
  },
  myMasonryGridColumn: {
    paddingLeft: '30px',
    backgroundClip: 'padding-box',
  },
})

export default drillsMasonryStyles
