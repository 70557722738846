import COLORS from '../constants/colors'

const createMenuItemLinkStyle = (menuChoice, itemName) => ({
  paddingTop: '1.3vh',
  paddingBottom: '1.3vh',
  paddingRight: '1.7vh',
  paddingLeft: '18px',
  color: menuChoice === itemName ? COLORS.orange : '#555555',
  borderRight: menuChoice === itemName ? `solid ${COLORS.orange}` : null,
})

export default createMenuItemLinkStyle
