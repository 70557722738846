const eventFormLivestreamFieldsStyles = (theme) => ({
  loadSetupsButton: {
    padding: '0 8px',
    [theme.breakpoints.down(1080)]: {
      paddingLeft: 0,
    },
  },
  toggleGroup: { flex: 1, marginLeft: 12, width: '40%' },
  divider: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})

export default eventFormLivestreamFieldsStyles
