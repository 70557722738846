import PropTypes, { shape, string, arrayOf } from 'prop-types'
import teamSmallType from '../teamSmallType'
import eventVariantSmallType from './eventVariantSmallType'

const eventRawType = shape({
  endDateTime: PropTypes.oneOfType([string, PropTypes.instanceOf(Date)]),
  id: string,
  // specificEventDetails: string,
  startDateTime: PropTypes.oneOfType([string, PropTypes.instanceOf(Date)]),
  teams: arrayOf(teamSmallType),
  title: string,
  type: eventVariantSmallType,
})

export default eventRawType
