import { SPACING, TYPOGRAPHY } from './generalPdfStyles'
import playPdfStyles from './playPdfStyles'

const playbookPdfStyles = {
  // Also import all play styles
  ...playPdfStyles,

  playbookHeaderLogo: {
    alignment: 'right',
  },
  footerUrl: {
    alignment: 'end',
    marginTop: SPACING.M,
    marginBottom: SPACING.M,
  },
  playbookFooterLogo: {
    alignment: 'left',
  },
  playbookFooterDate: {
    alignment: 'right',
    fontSize: TYPOGRAPHY.S,
  },
  mismatchLogo: {
    marginTop: SPACING.XXXL,
    alignment: 'center',
  },
  orgLogo: {
    marginTop: SPACING.XXXL,
    alignment: 'center',
  },
  orgName: {
    bold: true,
    alignment: 'center',
    fontSize: TYPOGRAPHY.XXXL,
    marginTop: SPACING.XXL,
  },
  teamName: {
    alignment: 'center',
    fontSize: TYPOGRAPHY.L,
    marginTop: SPACING.S,
  },
  playbookText: {
    bold: true,
    alignment: 'center',
    fontSize: TYPOGRAPHY.XXXL,
    marginTop: SPACING.XXXL,
  },
}

export default playbookPdfStyles
