import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import { useQuery } from 'react-query'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import drillBoxStyles from './drillBox.styles'
import MyCloudIcon from '../../assets/icons/MyCloudIcon'
import DrillSlider from '../DrillSlider/DrillSlider'
import DrillBoxFooter from '../DrillBoxFooter/DrillBoxFooter'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import ChipsContainer from '../ChipsContainer/ChipsContainer'
import ContainerPadding from '../containerPadding/containerPadding'
import arrayWithComma from '../../utils/helpers/arrayWithComma'
import setTacticsColor from '../../utils/helpers/setTacticsColor'
import { TRAINING_MODULES_TYPES_URL } from '../../utils/constants/trainingModulesTypes'
import QueryKeys from '../../utils/constants/queryKeys'
import getUserData from '../../Apis/user/getUserData'
import trainingModuleType from '../../types/trainingModuleType'
import TRAINING_MODULE_ACCESS_OPTIONS from '../../utils/constants/trainingModuleAccessOptions'
import DrillBoxCreatorName from '../DrillBoxCreatorName/DrillBoxCreatorName'
import Description from '../Description/Description'

const DrillBox = (props) => {
  const { trModuleType, accessedFrom, drill, toggleAccess, playbookId } = props
  const {
    access,
    title,
    description,
    intensities,
    tactics,
    positions,
    designs,
    creator,
    originalCreatorFirstName,
  } = drill
  const translate = useTranslate()
  const useStyles = makeStyles(drillBoxStyles)
  const classes = useStyles()

  const me = useQuery([QueryKeys.GET_ACCOUNT_ME], () => getUserData())
  const isCurrentUserDrillCreator = me?.data?.id === creator?.id

  return (
    <div className={classes.drillBoxWrapper}>
      <ContainerPadding paddingValue='5%'>
        <div className={`${classes.divFlex} ${classes.accessWrapper} ${classes.spaceVertical}`}>
          <ConditionalRender renderValue={Boolean(!originalCreatorFirstName)}>
            <span className={classes.accessIndicator}>
              {access?.name === TRAINING_MODULE_ACCESS_OPTIONS.public.toUpperCase()
                ? translate('ra.buttons.public').toLowerCase()
                : translate('ra.buttons.personal').toLowerCase()}
            </span>
          </ConditionalRender>

          <ConditionalRender renderValue={Boolean(!me?.isLoading && !isCurrentUserDrillCreator)}>
            <DrillBoxCreatorName label={creator?.firstName} />
          </ConditionalRender>
          <ConditionalRender
            renderValue={
              !me?.isLoading &&
              ((((originalCreatorFirstName && originalCreatorFirstName?.length) ||
                !isCurrentUserDrillCreator) &&
                toggleAccess === TRAINING_MODULE_ACCESS_OPTIONS.private) ||
                playbookId)
            }
          >
            <DrillBoxCreatorName label={originalCreatorFirstName} />
          </ConditionalRender>
        </div>

        <ConditionalRender renderValue={Boolean(title)}>
          <div className={`${classes.divFlex} ${classes.spaceVertical}`}>
            <ConditionalRender
              renderValue={
                toggleAccess === TRAINING_MODULE_ACCESS_OPTIONS.private &&
                isCurrentUserDrillCreator &&
                access?.name === TRAINING_MODULE_ACCESS_OPTIONS.public.toUpperCase()
              }
            >
              <MyCloudIcon className={classes.cloudIcon} style={{ marginRight: 5 }} />
            </ConditionalRender>

            <div className={classes.title}>{title}</div>
          </div>
        </ConditionalRender>

        <ConditionalRender renderValue={intensities?.length > 0}>
          <div className={`${classes.greySmall} ${classes.spaceVertical}`}>
            {arrayWithComma(intensities.map((intensity) => intensity.name))}
          </div>
        </ConditionalRender>

        <ConditionalRender renderValue={Boolean(designs)}>
          <DrillSlider designs={designs} positions={positions} />
        </ConditionalRender>

        <ConditionalRender renderValue={description}>
          <Description description={description} />
        </ConditionalRender>

        <ConditionalRender
          renderValue={tactics?.length > 0 && trModuleType === TRAINING_MODULES_TYPES_URL.DRILLS}
        >
          <div className={`${classes.divFlex} ${classes.spaceVertical}`}>
            <ChipsContainer chips={setTacticsColor(tactics)} />
          </div>
        </ConditionalRender>

        <ConditionalRender
          renderValue={
            tactics?.length > 0 &&
            trModuleType === TRAINING_MODULES_TYPES_URL.PLAYS &&
            accessedFrom !== 'playbook'
          }
        >
          <div className={`${classes.divFlex} ${classes.spaceVertical}`}>
            <ChipsContainer chips={setTacticsColor(tactics)} />
          </div>
        </ConditionalRender>
        <DrillBoxFooter {...props} isCurrentUserDrillCreator={isCurrentUserDrillCreator} />
      </ContainerPadding>
    </div>
  )
}

DrillBox.propTypes = {
  trModuleType: PropTypes.string,
  accessedFrom: PropTypes.string,
  drill: trainingModuleType,
  toggleAccess: PropTypes.string,
  playbookId: PropTypes.string,
  isDeleteModalHidden: PropTypes.bool,
}

export default DrillBox
