import { makeStyles } from '@material-ui/core'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import COLORS from '../../utils/constants/colors'

const DragIcon = (props) => {
  const useStyles = makeStyles({
    dragIcon: {
      fill: COLORS.darkGrey,
      paddingRight: 5,
    },
  })
  const classes = useStyles()

  return <DragIndicatorIcon {...props} className={classes.dragIcon} />
}

export default DragIcon
