const inviteUsersModalStyles = {
  dialogContents: { paddingLeft: '3%', paddingRight: '3%' },
  dialogActions: { display: 'flex', justifyContent: 'flex-end', marginTop: 10 },
  checkboxesContainer: { flex: 1, flexDirection: 'row' },
  footer: { marginTop: '3%' },
  buttonContainer: { marginTop: '3%', display: 'flex', justifyContent: 'flex-end', gap: 15 },
  emailField: { width: '80%' },
  inviteButton: { justifyContent: 'flex-end' },
}

export default inviteUsersModalStyles
