import React from 'react'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  notificationIcon: {
    width: 21,
    height: 21,
    color: '#555555',
    cursor: 'pointer',
  },
})

const NotificationIcon = () => {
  const classes = useStyles()

  return <NotificationsIcon className={classes.notificationIcon} />
}

export default NotificationIcon
