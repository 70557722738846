const setDrillsFiltersFromUrl = (queryParams) => {
  if (!queryParams) return null;

  const intensities = queryParams.intensity?.split(",");
  const positions = queryParams.position?.split(",");
  let newFilters = {};

  if (intensities && intensities.length > 0) {
    newFilters = {
      ...newFilters,
      intensity: intensities
    }
  }

  if (positions && positions.length > 0) {
    newFilters = {
      ...newFilters,
      position: positions
    }
  }

  if (Object.keys(newFilters).length > 0) {
    return newFilters;
  } else {
    return {}
  }
};

export default setDrillsFiltersFromUrl;