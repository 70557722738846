import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import practiceBoxDateStyles from './practiceBoxDate.styles'

const PracticeBoxDate = (props) => {
  const { month, date } = props
  const useStyles = makeStyles(practiceBoxDateStyles)
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <span className={classes.month}>{month.substring(0, 3).toUpperCase()}</span>
      <span className={classes.date}>{date}</span>
    </div>
  )
}

PracticeBoxDate.propTypes = {
  month: PropTypes.string,
  date: PropTypes.string,
}

export default PracticeBoxDate
