const addOrgFormBodyStyles = (theme) => ({
  errorMessage: {
    fontSize: 12,
    color: theme.palette.error.main,
    marginTop: 3,
    marginLeft: 14,
    marginRight: 14,
    textAlign: 'start',
  },
})

export default addOrgFormBodyStyles
