import React from 'react'
import { PracticePlanProvider } from '../../context/usePracticePlanContext'
import PracticePlanCreate from '../../pages/PracticePlanCreate/PracticePlanCreate'

const CreatePracticePlanContextWrapper = () => (
  <PracticePlanProvider>
    <PracticePlanCreate />
  </PracticePlanProvider>
)

export default CreatePracticePlanContextWrapper
