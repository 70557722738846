import axiosInstance from '../axiosInterceptor/axiosInstance'

const getUserByEmail = async (data) => {
  const url = '/users/get-user-by-email'
  return axiosInstance.get(url, {
    params: data,
  })
}

export default getUserByEmail
