import React from 'react'
import ReactDOMServer from 'react-dom/server'
import SportsHandballIcon from '@material-ui/icons/SportsHandball'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import Group from '@material-ui/icons/Group'
import { Link } from '@material-ui/core'

const createSteps = (tourSteps, translate) => {
  const iconHandBall = ReactDOMServer.renderToString(
    <SportsHandballIcon style={{ fontSize: '2rem', verticalAlign: 'middle' }} />,
  )

  const iconBook = ReactDOMServer.renderToString(
    <MenuBookIcon style={{ fontSize: '2rem', verticalAlign: 'middle' }} />,
  )

  const iconGroup = ReactDOMServer.renderToString(
    <Group style={{ fontSize: '2rem', verticalAlign: 'middle' }} />,
  )

  const tourLink = (url, text) => ReactDOMServer.renderToString(<Link href={url}>{text}</Link>)

  const steps = {
    hint: [
      {
        element: document.querySelector('#orgName'),
        title: translate('ra.tour.didYouKnow'),
        intro: `You can ${tourLink('/orgUsers', 'invite users')} to your organisation!`,
      },
    ],
    calendar: [
      {
        title: `<img src="logo.png"/> ${translate('ra.tour.welcome')}`,
        intro: translate('ra.tour.welcomeIntro'),
      },
      {
        element: document.querySelector('#orgName'),
        title: translate('ra.tour.organisation'),
        intro: translate('ra.tour.orgIntro'),
        position: 'left',
      },
      {
        element: document.querySelector('#orgLogo'),
        title: translate('ra.tour.logo'),
        intro: translate('ra.tour.logoIntro'),
        position: 'right',
      },
      {
        element: document.querySelector('#players'),
        title: translate('ra.tour.roster'),
        intro: translate('ra.tour.rosterIntro'),
        position: 'right',
      },
      {
        element: document.querySelector('#coachCorner'),
        title: translate('ra.tour.coach'),
        intro: translate('ra.tour.coachIntro'),
        position: 'right',
      },
    ],
    drills: [
      {
        title: `${iconHandBall} ${translate('ra.tour.drill')}`,
        intro: translate('ra.tour.drillIntro'),
      },
      {
        element: document.querySelector('#public'),
        title: translate('ra.tour.public'),
        intro: translate('ra.tour.publicIntro'),
      },
      {
        element: document.querySelector('#addButtonDrill'),
        title: translate('ra.tour.createDrill'),
        intro: translate('ra.tour.createDrillIntro'),
      },
    ],
    createdrills: [
      {
        element: document.querySelector('#drillTitle'),
        title: translate('ra.tour.drillTitle'),
        intro: translate('ra.tour.drillTitleIntro'),
      },
      {
        element: document.querySelector('#bigDes'),
        title: translate('ra.tour.drillDes'),
        intro: translate('ra.tour.drillDesIntro'),
      },
      {
        element: document.querySelector('#graphicsAdd'),
        title: translate('ra.tour.drillGraphics'),
        intro: translate('ra.tour.drillGraphicsIntro'),
      },
      {
        element: document.querySelector('#intensityDrills'),
        title: translate('ra.tour.drillIntensity'),
        intro: translate('ra.tour.drillIntensityIntro'),

        position: 'left',
      },
      {
        element: document.querySelector('#tacticsDrills'),
        title: translate('ra.tour.drillTactics'),
        intro: translate('ra.tour.drillTacticsIntro'),
        position: 'left',
      },
      {
        element: document.querySelector('#publishDrill'),
        title: translate('ra.tour.publicDrill'),
        intro: translate('ra.tour.publicDrillIntro'),
        position: 'left',
      },
      {
        element: document.querySelector('#saveDelete'),
        title: translate('ra.tour.drillSave'),
        intro: translate('ra.tour.drillSaveIntro'),
        position: 'left',
      },
    ],
    rosterManagementEmpty: [
      {
        title: `${iconGroup} ${translate('ra.tour.rosterMan')}`,
        intro: translate('ra.tour.rosterManIntro'),
      },
      {
        element: document.querySelector('#playerList'),
        title: translate('ra.tour.playerList'),
        intro: translate('ra.tour.playerListIntro'),
      },
      {
        element: document.querySelector('#addPlayer'),
        title: translate('ra.tour.addPlayer'),
        intro: translate('ra.tour.addPlayerIntro'),
      },
    ],
    plays: [
      {
        title: `${iconBook} ${translate('ra.tour.plays')}`,
        intro: translate('ra.tour.playsIntro'),
      },
      {
        element: document.querySelector('#public'),
        title: translate('ra.tour.publicPlay'),
        intro: translate('ra.tour.publicPlayIntro'),
      },
      {
        element: document.querySelector('#addButtonDrill'),
        title: translate('ra.tour.createPlay'),
        intro: translate('ra.tour.createPlayIntro'),
      },
    ],
    createplays: [
      {
        element: document.querySelector('#drillTitle'),
        title: translate('ra.tour.playTitle'),
        intro: translate('ra.tour.playTitleIntro'),
      },
      {
        element: document.querySelector('#graphicsAdd'),
        title: translate('ra.tour.playGraphics'),
        intro: translate('ra.tour.playGraphicsIntro'),
      },
      {
        element: document.querySelector('#playsDes'),
        title: translate('ra.tour.playDes'),
        intro: translate('ra.tour.playDesIntro'),
        position: 'left',
      },
      {
        element: document.querySelector('#tacticsDrills'),
        title: translate('ra.tour.playTactics'),
        intro: translate('ra.tour.playTacticsIntro'),
        position: 'left',
      },
      {
        element: document.querySelector('#publishDrill'),
        title: translate('ra.tour.makePublicPlay'),
        intro: translate('ra.tour.makePublicPlayIntro'),
        position: 'left',
      },
      {
        element: document.querySelector('#saveDelete'),
        title: translate('ra.tour.playSave'),
        intro: translate('ra.tour.playSaveIntro'),
        position: 'left',
      },
    ],
    practices: [
      {
        title: `${iconHandBall} ${translate('ra.tour.practice')}`,
        intro: translate('ra.tour.practiceIntro'),
      },
      {
        element: document.querySelector('#datesRange'),
        title: translate('ra.tour.date'),
        intro: translate('ra.tour.dateIntro'),
      },
      {
        element: document.querySelector('#newPractice'),
        title: translate('ra.tour.newPractice'),
        intro: translate('ra.tour.newPracticeIntro'),
      },
    ],
    playbooks: [
      {
        title: `${iconBook} ${translate('ra.tour.playBook')}`,
        intro: translate('ra.tour.playBookIntro'),
      },
      {
        element: document.querySelector('#addPlayInBook'),
        title: translate('ra.tour.addPlays'),
        intro: translate('ra.tour.addPlaysIntro'),
      },
      {
        element: document.querySelector('#exportPlaybook'),
        title: translate('ra.tour.export'),
        intro: translate('ra.tour.exportIntro'),
      },
    ],
  }

  return steps[tourSteps]
}

export default createSteps
