import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import { v4 } from 'uuid'
import PropTypes from 'prop-types'
import drillComponentInfoStyles from './drillComponentInfo.styles'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import ChipsContainer from '../ChipsContainer/ChipsContainer'
import DrillSlider from '../DrillSlider/DrillSlider'
import GreyLabelsWithComma from '../greyLabelsWithComma/greyLabelsWithComma'
import TACTICS_COLORS from '../../utils/constants/tacticsColors'
import trainingModuleType from '../../types/trainingModuleType'
import Description from '../Description/Description'

const DrillComponentInfo = (props) => {
  const { drill, edit } = props
  const { title, description, positions, intensities, tactics, designs } = drill
  const positionsFirstLetter = positions.map((position) => ({
    displayName: position.name.charAt(0),
  }))
  const useStyles = makeStyles(drillComponentInfoStyles)
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div>
        <div className={classes.infoWrapper}>
          <div className={classes.title}>{title}</div>

          <ConditionalRender renderValue={positions.length > 0 && intensities.length > 0}>
            <div className={classes.divFlex}>
              <ConditionalRender renderValue={!edit && positions.length > 0}>
                <GreyLabelsWithComma
                  values={positionsFirstLetter}
                  className={classes.positionsFirstLetter}
                />
                <span className={classes.semicolon} />
              </ConditionalRender>
              <GreyLabelsWithComma values={intensities} className={classes.intensities} />
            </div>
          </ConditionalRender>

          <ConditionalRender renderValue={edit && positions.length > 0}>
            <div className={classes.divFlex}>
              {positionsFirstLetter.map((position) => (
                <div key={v4()} className={classes.positions}>
                  {position.displayName}
                </div>
              ))}
            </div>
          </ConditionalRender>

          <ConditionalRender renderValue={description}>
            <Description description={description} />
          </ConditionalRender>

          <ConditionalRender renderValue={tactics?.length > 0}>
            <ChipsContainer
              overflow
              chips={tactics?.map((tactic) => ({
                ...tactic,
                color: tactic.type === 'Offense' ? TACTICS_COLORS.Offense : TACTICS_COLORS.Defense,
              }))}
            />
          </ConditionalRender>
        </div>
      </div>
      <div>
        <ConditionalRender renderValue={designs && designs.length > 0}>
          <DrillSlider designs={designs} />
        </ConditionalRender>
      </div>
    </div>
  )
}

DrillComponentInfo.propTypes = {
  drill: trainingModuleType,
  edit: PropTypes.bool,
}

export default DrillComponentInfo
