const chipTableFieldStyles = (backColor, wordColor) => ({
  cellContainer: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
  chipField: {
    backgroundColor: backColor,
    color: wordColor,
    marginRight: '10px',
  },
  extraFields: {
    display: 'flex',
    alignItems: 'center',
    flex: 2,
    justifyContent: 'space-around',
  },
})

export default chipTableFieldStyles
