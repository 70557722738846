import COLORS from '../../utils/constants/colors'

const moreIconProfileStyles = {
  profileMenuItems: {
    padding: '10px 16px',
  },
  profileMenuItemsBody: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    color: COLORS.darkGrey,
  },
}

export default moreIconProfileStyles
