import { shape, string, number, bool, func, any } from 'prop-types'

const mutationType = shape({
  context: any,
  data: any,
  error: any,
  failureCount: number,
  isError: bool,
  isIdle: bool,
  isLoading: bool,
  isPaused: bool,
  isSuccess: bool,
  mutate: func,
  mutateAsync: func,
  reset: func,
  status: string,
  variables: any,
})

export default mutationType
