import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import App from './App'
import reportWebVitals from './reportWebVitals'
import localStorageIds from './utils/constants/localStorageIds'
import decodeJwt from 'jwt-decode'

const history = createBrowserHistory()
Sentry.init({
  environment: process.env.REACT_APP_RUNTIME === 'PROD' ? 'prod' : 'dev',
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new CaptureConsoleIntegration({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      levels: ['error'],
    }),
  ],
  tracesSampleRate: 1.0,
})

const storedToken = localStorage.getItem(localStorageIds.TOKEN)
const token = storedToken ? decodeJwt(storedToken) : null
const userId = token && token.sub ? token.sub : null
const selectedOrg = localStorage.getItem(localStorageIds.SELECTED_ORG)

Sentry.configureScope((scope) => {
  if (userId) {
    scope.setUser({ id: userId })
  }

  try {
    const data = JSON.parse(selectedOrg)
    const orgId = data.id
    scope.setTag('orgId', orgId)
  } catch {
    scope.setTag('orgId', 'unknown')
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
