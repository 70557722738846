import { arrayOf, shape, string, oneOfType, oneOf } from 'prop-types'

const tacticSimpleType = {
  id: string,
  name: string,
  parentId: string,
}

// Recurring type
const tacticType = shape({
  children: arrayOf(
    shape({
      children: oneOfType([
        arrayOf(
          shape({
            children: oneOfType([
              arrayOf(shape({})), // Ensure to use valid check functions here
              oneOf([null]),
            ]),
            ...tacticSimpleType,
          }),
        ),
        oneOf([null]),
      ]),
      ...tacticSimpleType,
    }),
  ),
  ...tacticSimpleType,
})

export default tacticType
