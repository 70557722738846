import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-admin'
import subscriptionStatusTabStyles from './subscriptionStatusTab.styles'
import SubscriptionAddonCard from '../SubscriptionAddonCard/SubscriptionAddonCard'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'

const SubscriptionStatusTab = ({
  selectedAddon,
  selectedMultipleAddons,
  paymentSuccess: status,
  setPaymentSuccess,
  setTab,
  isCardVerification,
}) => {
  const translate = useTranslate()
  const useStyles = makeStyles(subscriptionStatusTabStyles)
  const classes = useStyles()

  const textToShow =
    isCardVerification && !status
      ? [translate('ra.text.changeCardSuccess'), translate('ra.text.changeCardFail')]
      : [translate('ra.text.paymentSuccess'), translate('ra.text.paymentFail')]

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        {status ? (
          <CheckCircleIcon className={classes.checkIcon} />
        ) : (
          <ErrorIcon className={classes.errorIcon} />
        )}
        <Typography variant='h5' className={classes.typographyBold}>
          {status ? textToShow[0] : textToShow[1]}
        </Typography>
      </div>

      {selectedAddon ? (
        <SubscriptionAddonCard addon={selectedAddon} nonActivatableAddons isAddonConfirmation />
      ) : (
        selectedMultipleAddons.map((addon) => (
          <SubscriptionAddonCard
            key={addon.id}
            addon={addon}
            nonActivatableAddons
            isAddonConfirmation
          />
        ))
      )}

      <ButtonWithLoader
        style={{
          width: '100%',
          height: '45px',
          minWidth: '250px',
          backgroundColor: '#096BDE',
          marginTop: '20px',
        }}
        label={status ? translate('ra.buttons.continue') : translate('ra.buttons.tryAgain')}
        onClick={() => {
          setPaymentSuccess(undefined)
          setTab(1)
        }}
        loading={false}
      />
    </div>
  )
}

SubscriptionStatusTab.propTypes = {
  setPaymentSuccess: PropTypes.func,
  setActivateCouponFailure: PropTypes.func,
  setTab: PropTypes.func,
  isCardVerification: PropTypes.func,
  selectedAddon: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pricing: PropTypes.string,
    seats: PropTypes.shape({
      all: PropTypes.number,
      allocated: PropTypes.number,
      free: PropTypes.number,
    }),
    enabled: PropTypes.bool.isRequired,
    userActivatable: PropTypes.bool.isRequired,
  }),
  selectedMultipleAddons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      pricing: PropTypes.string,
      seats: PropTypes.shape({
        all: PropTypes.number,
        allocated: PropTypes.number,
        free: PropTypes.number,
      }),
      enabled: PropTypes.bool.isRequired,
      userActivatable: PropTypes.bool.isRequired,
    }),
  ),
  paymentSuccess: PropTypes.bool,
  activateCouponFailure: PropTypes.bool,
}

export default SubscriptionStatusTab
