import getEvent from '../../../../Apis/event/getEvent'

const calendarIdBreadcrumb = async (data) => {
  const { match, eventType } = data || {}
  const { eventId } = match.params

  const eventTitle = await getEvent({ eventId, eventTypeCode: eventType }).then(
    (event) => event.title,
  )

  return ['CALENDAR', eventTitle.toUpperCase()]
}

export default calendarIdBreadcrumb
