import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import fadeOutStyles from './fadeOut.styles'

const FadeOut = (props) => {
  const { isFadedOut, duration, children } = props

  const DEFAULT_DURATION = '1.2'

  const useStyles = makeStyles(fadeOutStyles(duration ?? DEFAULT_DURATION))
  const classes = useStyles()

  return <div className={classNames({ [classes.fadeOut]: isFadedOut })}>{children}</div>
}

FadeOut.propTypes = {
  isFadedOut: PropTypes.bool,
  duration: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default FadeOut
