const youtubeStreamFieldsStyles = (hasThumbnail) => ({
  fieldSpacing: { marginBottom: 10, marginTop: 10 },
  thumbnailContainer: {
    display: 'flex',
    gap: 15,
    alignItems: 'center',
  },
  thumbnailLogo: {
    position: 'relative',
    width: hasThumbnail ? 140 : 55,
    backgroundColor: '#bdbdbd',
    borderRadius: 10,
    padding: 5,
    '& svg': {
      width: '50%',
      height: '50%',
    },
    marginBottom: 10,
    marginTop: 10,
  },
  thumbnailLogoFilled: {
    position: 'relative',
    width: hasThumbnail ? '77%' : 125,
    height: hasThumbnail ? '90%' : 40,
    marginBottom: 10,
    marginTop: 10,
    marginLeft: hasThumbnail && 'auto',
    marginRight: hasThumbnail && 'auto',
  },
  errorContainer: {
    marginBottom: 15,
  },
  error: {
    fontSize: '12px',
    color: 'red',
  },
})

export default youtubeStreamFieldsStyles
