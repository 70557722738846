import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Visibility } from '@material-ui/icons'

const MyVisibilityIcon = (props) => {
  const useStyles = makeStyles({
    icon: {
      width: '15px',
      height: '15px',
      cursor: 'pointer',
    },
  })
  const classes = useStyles()

  return <Visibility {...props} classes={{ root: classes.icon }} />
}

export default MyVisibilityIcon
