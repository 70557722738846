import React from 'react'
import { Resource } from 'react-admin'
import RESOURCES from '../constants/resources'
import withAuthHOC from '../../components/withAuthHOC/withAuthHOC'

const resourcesWithAuth = () =>
  RESOURCES.map((route) => {
    const { name, list, create, edit, show, allowedScopes, allowedUserOrgRoles, payLocked } = route

    return (
      <Resource
        key={name}
        name={name}
        list={withAuthHOC(allowedScopes, allowedUserOrgRoles, payLocked)(list)}
        create={withAuthHOC(allowedScopes, allowedUserOrgRoles, payLocked)(create)}
        edit={withAuthHOC(allowedScopes, allowedUserOrgRoles, payLocked)(edit)}
        show={withAuthHOC(allowedScopes, allowedUserOrgRoles, payLocked)(show)}
      />
    )
  })

export default resourcesWithAuth
