const getCurrentDateClassicFormat = () => {
  const currentDate = new Date()
  const day = currentDate.getDate()
  const month = currentDate.getMonth() + 1 // Months are zero-indexed, so we add 1 to get the correct month
  const year = currentDate.getFullYear()

  // 25/04/2023
  return `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${year}`
}

export default getCurrentDateClassicFormat
