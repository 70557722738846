import COLORS from '../../../../../utils/constants/colors'

const mySaveButtonStyles = {
  button: {
    height: '36px',
    backgroundColor: COLORS.orange,
    color: COLORS.white,
  },
  loadingDots: {
    width: '42px',
    height: '30px',
    paddingLeft: '5px',
    paddingRight: '4px',
  },
}

export default mySaveButtonStyles
