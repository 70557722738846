import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DrillShowPage from '../DrillShowPage/DrillShowPage'

const DrillShowPageModal = (props) => {
  const { open, onClose, clickedComponent, practicePlan, openDrillCreateModal } = props

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='lg'
      PaperProps={{
        style: {
          height: '90%',
        },
      }}
    >
      <DrillShowPage
        practicePlanId={practicePlan?.id}
        clickedComponentId={clickedComponent?.trainingModule.id}
        isModal
        openDrillCreateModal={openDrillCreateModal}
        onModalClose={onClose}
      />
    </Dialog>
  )
}

DrillShowPageModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  openDrillCreateModal: PropTypes.func,
}

export default DrillShowPageModal
