import { addDays, addHours, addMinutes } from 'date-fns'

const dateHelper = () => {
  const today = new Date()
  const todayPlusHalfHour = addMinutes(new Date(), 30)
  const todayPlusOneHour = addHours(new Date(), 1)
  const todayPlusOneWeek = addDays(new Date(), 7)

  return {
    today,
    todayPlusHalfHour,
    todayPlusOneHour,
    todayPlusOneWeek,
  }
}

export default dateHelper
