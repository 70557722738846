import { shape, string } from 'prop-types'

const initialTacticType = shape({
  color: string,
  id: string,
  name: string,
  parentId: string,
  type: string,
})

export default initialTacticType
