import COLORS from '../../../utils/constants/colors'

const myTableStyles = (hasNoDivider, tableCompStyles, tableStyles, boldHeader, fontsize) => ({
  tableContainer: tableStyles,
  table: tableCompStyles,
  pagination: { position: 'absolute', bottom: '50px', right: '40px' },
  typographyBold: { fontWeight: boldHeader && 'bold', fontSize: fontsize ?? 14 },
  typographyLight: {
    color: COLORS.lightGrey,
    fontSize: fontsize,
  },
  tableHead: {
    '& .MuiTableCell-head': {
      backgroundColor: 'white',
    },
  },
  tableHeader: {
    borderBottom: hasNoDivider ? 'none' : '1px solid rgba(224, 224, 224, 1)',
  },
  downLoadButton: { color: '#054DA7' },
})

export default myTableStyles
