import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTranslate } from 'react-admin'
import COLORS from '../../utils/constants/colors'

const MyDeleteIcon = (props) => {
  const { disabled, onClick, ...rest } = props
  const translate = useTranslate()
  const useStyles = makeStyles({
    addIcon: {
      fill: COLORS.grey,
      cursor: 'pointer',
    },
  })
  const classes = useStyles()

  return (
    <Tooltip title={translate('ra.action.delete')}>
      <DeleteIcon
        {...rest}
        classes={{ root: classes.addIcon }}
        onClick={disabled ? null : onClick}
        fontSize='medium'
      />
    </Tooltip>
  )
}

MyDeleteIcon.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

export default MyDeleteIcon
