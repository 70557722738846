import getPracticePlan from '../../../../Apis/practice/getPracticePlan'

const practicePlanIdBreadcrumb = async (data) => {
  const { match } = data || {}
  const { practicePlanId } = match.params

  const practicePlanDescription = await getPracticePlan({
    practicePlanId,
  }).then((practicePlan) => practicePlan.description)

  if (practicePlanDescription) {
    return ['PRACTICE', practicePlanDescription.toUpperCase()]
  }

  return ['PRACTICE', 'PRACTICE PLAN TOPIC']
}

export default practicePlanIdBreadcrumb
