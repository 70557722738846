import localStorageHelper from './localStorageHelper'
import localStorageIds from '../constants/localStorageIds'

const isUserSuperAdmin = () => {
  const { localStorageValue: isSuperAdmin } = localStorageHelper(localStorageIds.IS_SUPER_ADMIN)

  return isSuperAdmin ?? false
}

export default isUserSuperAdmin
