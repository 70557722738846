import COLORS from '../colors'

export const CARD_STYLES = {
  card: {
    minHeight: '100%',
  },
  cardTitle: {
    marginBottom: '8px',
    marginLeft: 10,
  },
  cardSubtitle: {
    marginLeft: '10px',
  },
  divider: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  loader: {
    width: 30,
    height: 30,
    margin: 30,
    fill: COLORS.orange,
  },
}
