import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import COLORS from '../../utils/constants/colors'
import LocationOnIcon from '@material-ui/icons/LocationOn'

const MyLocationOnIcon = (props) => {
  const useStyles = makeStyles({
    addIcon: {
      width: 22,
      height: 22,
      fill: COLORS.grey,
    },
  })
  const classes = useStyles()

  return <LocationOnIcon {...props} classes={{ root: classes.addIcon }} />
}

export default MyLocationOnIcon
