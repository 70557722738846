import { BASE_URL } from '../../utils/constants/url'
import httpClient from '../../utils/helpers/httpClient'

const positionsProvider = {
  getList: () => {
    const url = `${BASE_URL}/players/positions`

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json,
        total: json.length,
      }
    })
  },

  getMany: () => {
    return Promise.reject('Provider: positions, Method: getMany')
  },
}

export default positionsProvider
