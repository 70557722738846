const NotificationTypes = {
  0: 'invitation',
  1: 'billing',
}

function mapNotification(notificationType) {
  return NotificationTypes[notificationType] || 'Unknown notification type'
}

export default mapNotification
