import * as React from 'react'
import Slider from 'react-slick'
import { makeStyles } from '@material-ui/core'
import { useState } from 'react'
import PropTypes, { arrayOf, objectOf } from 'prop-types'
import LeftArrowCircleIcon from '../../assets/icons/LeftArrowCircleIcon'
import RightArrowCircleIcon from '../../assets/icons/rightArrowCircleIcon'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import DrillSliderPositions from '../DrillSliderPositions/DrillSliderPositions'
import designType from '../../types/designType'
import drillSliderStyles from './drillSlider.styles'

const DrillSlider = (props) => {
  const { designs, positions } = props
  const [sliderRef, setSliderRef] = useState(null)
  const [slideIndex, setSlideIndex] = useState(0)
  const useStyles = makeStyles(drillSliderStyles)
  const classes = useStyles()

  const sliderSettings = {
    fade: true,
    // infinite creates bug
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    beforeChange: (prev, next) => setSlideIndex(next),
  }

  const handleLeftClick = (e) => {
    e.stopPropagation()
    sliderRef?.slickPrev()
  }

  const handleRightClick = (e) => {
    e.stopPropagation()
    sliderRef?.slickNext()
  }

  return designs && designs.length > 0 ? (
    <div className={classes.drillSliderWrapper}>
      <Slider ref={setSliderRef} {...sliderSettings}>
        {designs
          .sort((a, b) => a.order - b.order)
          .map((design) => {
            const { id, preview, isZoomedIn } = design || {}

            return (
              <div key={design.id}>
                <ConditionalRender renderValue={slideIndex > 0}>
                  <LeftArrowCircleIcon
                    className={classes.leftArrow}
                    onClick={handleLeftClick}
                    style={{ left: !isZoomedIn ? '18%' : '4%' }}
                  />
                </ConditionalRender>
                <ConditionalRender renderValue={slideIndex + 1 !== designs.length}>
                  <RightArrowCircleIcon
                    className={classes.rightArrow}
                    onClick={handleRightClick}
                    style={{ right: !isZoomedIn ? '18%' : '4%' }}
                  />
                </ConditionalRender>
                <div key={id}>
                  <img
                    src={preview}
                    alt='Preview'
                    style={{
                      width: !isZoomedIn ? 175 : 270,
                      height: !isZoomedIn ? 266 : 204,
                      objectFit: 'fill',
                      margin: 'auto',
                      paddingBottom: isZoomedIn && '20px',
                    }}
                  />
                </div>
                <DrillSliderPositions positions={positions && positions} isZoomedIn={isZoomedIn} />
              </div>
            )
          })}
      </Slider>
    </div>
  ) : null
}

DrillSlider.propTypes = {
  designs: arrayOf(designType),
  positions: arrayOf(objectOf(PropTypes.string)),
  size: PropTypes.string,
}

export default DrillSlider
