import COLORS from '../../utils/constants/colors'

const playbookListStyles = {
  body: {
    display: 'flex',
    height: '100%',
  },
  grid: {
    maxWidth: '15%',
    backgroundColor: COLORS.white,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '0.9em',
    fontWeight: 'bold',
    color: COLORS.grey,
    paddingLeft: 10,
    paddingTop: 10,
  },
  list: {
    marginTop: 20,
  },
  listItemText: {
    fontSize: '0.9em',
  },
  divider: {
    justifyContent: 'flex-end',
  },
  playsList: {
    flex: 1,
    overflowX: 'auto',
    width: '100%',
  },
  appBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 55,
    marginRight: 15,
  },
  popover: {
    padding: '5px 0',
  },
  loaderIcon: {
    fill: COLORS.orange,
    marginTop: -50,
    width: 70,
    height: 70,
  },
  circleLoader: {
    marginLeft: 'auto',
    marginRight: 2,
  },
  emptyMessage: {
    marginTop: 50,
  }
}

export default playbookListStyles
