export const FAKE_EVENT_UUID = '00000000-0000-0000-0000-000000000000'

export const EVENT_TYPE_FORMS = {
  OTHER: 'other',
  PRACTICE: 'pract',
  GAME: 'game',
  TRIP: 'trip',
  MEETING: 'meeti',
}

export const EVENT_TYPE_DEFAULT_VALUE = {
  code: 'game',
  color: '#B8E24A',
  entityName: 'Game',
  id: '00000001-1fdd-49c5-99c2-000000000002',
  name: 'Game',
  scopes: ['Livestream'],
}

export const Switch = (props) => {
  const { test, children } = props

  if (!test) return null

  // Filter out only children with a matching prop
  return children?.find((child) => child.props.value === test)
}
