import React from 'react'
import PropTypes from 'prop-types'
import COLORS from '../../utils/constants/colors'

const MyPersonIcon = (props) => {
  const { fillColor } = props

  return (
    <svg
      {...props}
      width='12'
      height='12'
      fill={COLORS.grey}
      viewBox='0 0 12 12'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.26315 9.43984C1.52106 8.82895 1.89532 8.27405 2.3651 7.80605C2.83346 7.33671 3.38826 6.96251 3.99889 6.7041C4.00436 6.70137 4.00983 6.7 4.0153 6.69727C3.16354 6.08203 2.60983 5.07988 2.60983 3.94922C2.60983 2.07617 4.12741 0.558594 6.00045 0.558594C7.8735 0.558594 9.39108 2.07617 9.39108 3.94922C9.39108 5.07988 8.83737 6.08203 7.98561 6.69863C7.99108 6.70137 7.99655 6.70273 8.00202 6.70547C8.61452 6.96387 9.16413 7.33438 9.63581 7.80742C10.1051 8.27578 10.4794 8.83058 10.7378 9.44121C10.9916 10.039 11.1285 10.6799 11.1411 11.3293C11.1414 11.3439 11.1389 11.3584 11.1336 11.372C11.1282 11.3856 11.1202 11.398 11.11 11.4084C11.0998 11.4189 11.0877 11.4272 11.0742 11.4328C11.0608 11.4385 11.0463 11.4414 11.0317 11.4414H10.2114C10.1512 11.4414 10.1034 11.3936 10.102 11.3348C10.0747 10.2793 9.65084 9.29082 8.90163 8.5416C8.12643 7.76641 7.09694 7.33984 6.00045 7.33984C4.90397 7.33984 3.87448 7.76641 3.09928 8.5416C2.35006 9.29082 1.92624 10.2793 1.89889 11.3348C1.89752 11.3949 1.84967 11.4414 1.78952 11.4414H0.969204C0.954608 11.4414 0.940158 11.4385 0.926706 11.4328C0.913255 11.4272 0.901074 11.4189 0.890882 11.4084C0.880689 11.398 0.87269 11.3856 0.867357 11.372C0.862023 11.3584 0.859464 11.3439 0.859829 11.3293C0.873501 10.6758 1.00885 10.04 1.26315 9.43984ZM6.00045 6.30078C6.62799 6.30078 7.21862 6.05605 7.66295 5.61172C8.10729 5.16738 8.35202 4.57676 8.35202 3.94922C8.35202 3.32168 8.10729 2.73105 7.66295 2.28672C7.21862 1.84238 6.62799 1.59766 6.00045 1.59766C5.37291 1.59766 4.78229 1.84238 4.33795 2.28672C3.89362 2.73105 3.64889 3.32168 3.64889 3.94922C3.64889 4.57676 3.89362 5.16738 4.33795 5.61172C4.78229 6.05605 5.37291 6.30078 6.00045 6.30078Z'
        fill={fillColor ?? 'black'}
      />
    </svg>
  )
}

MyPersonIcon.propTypes = {
  fillColor: PropTypes.string,
}

export default MyPersonIcon
