import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import { useMutation, useQueryClient } from 'react-query'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import drillShowToolbarStyles from './drillShowToolbar.styles'
import MyEditIcon from '../../../assets/icons/MyEditIcon'
import MyDeleteIcon from '../../../assets/icons/MyDeleteIcon'
import constructDrillTitle from '../../../utils/helpers/constructDrillTitle'
import DeleteModal from '../../../components/DeleteModal/DeleteModal'
import useHistoryPush from '../../../hooks/useHistoryPush'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import { accessDrill } from '../../../Apis/drill/accessDrill'
import QueryKeys from '../../../utils/constants/queryKeys'
import USER_ORG_ROLES from '../../../utils/constants/userOrgRoles'
import isUserOrgRolesValid from '../../../utils/helpers/isUserOrgRolesValid'
import USER_ORG_MEMBERS_ROLES from '../../../utils/constants/userOrgMembersRoles'
import trainingModuleType from '../../../types/trainingModuleType'
import mutationType from '../../../types/mutationType'
import TrainingModuleDownloadButton from '../../../components/TrainingModuleDownloadButton/TrainingModuleDownloadButton'
import { TRAINING_MODULES_TYPES_URL } from '../../../utils/constants/trainingModulesTypes'
import isUserSuperAdmin from '../../../utils/helpers/isUserSuperAdmin'

const DrillShowToolbar = (props) => {
  const {
    drillId,
    trainingModule,
    pathname,
    isModal,
    openDrillCreateModal,
    deleteADrill,
    deleteTrainingModule,
    isLoading,
    isDownloaded,
    isCurrentUserDrillCreator,
  } = props
  const { originalTrainingModuleId, revisionId, id } = trainingModule
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [cloudIconClicked, setCloudIconClicked] = useState(true)
  const queryClient = useQueryClient()
  const { redirect } = useHistoryPush()
  const translate = useTranslate()
  const { OWNER, ADMIN } = USER_ORG_ROLES
  const { TEAM_MANAGER } = USER_ORG_MEMBERS_ROLES
  const useStyles = makeStyles(drillShowToolbarStyles)
  const classes = useStyles()

  const isCoachCornerPage =
    pathname === TRAINING_MODULES_TYPES_URL.DRILLS || pathname === TRAINING_MODULES_TYPES_URL.PLAYS
  const showEditAndDelete =
    isCoachCornerPage || isUserOrgRolesValid([OWNER, ADMIN, TEAM_MANAGER]) || isUserSuperAdmin()

  useEffect(() => {
    // Only way I found to check if the training module is downloaded on the personal list. We always want it to be disabled
    setCloudIconClicked(trainingModule?.originalCreatorFirstName ? true : isDownloaded)
  }, [isDownloaded, trainingModule?.originalCreatorFirstName])

  const handleEditButtonClick = () => {
    if (isModal) {
      return openDrillCreateModal()
    }

    return redirect(`/${pathname}/${drillId}`)
  }

  const handleDeleteButtonClick = () => {
    setOpenDeleteModal(true)
  }

  const accessADrill = useMutation((id) => accessDrill(id), {
    onSuccess: () => {
      setCloudIconClicked(true)
      queryClient.invalidateQueries(QueryKeys.GET_DRILLS)
    },
  })

  const handleDownloadTrainingModule = () => {
    accessADrill.mutate(originalTrainingModuleId || id)
  }

  return (
    <>
      {!isLoading && (revisionId || !isCurrentUserDrillCreator) && (
        <TrainingModuleDownloadButton
          disabled={cloudIconClicked}
          onClick={() => {
            if (!cloudIconClicked) {
              handleDownloadTrainingModule()
            }
          }}
        />
      )}
      {showEditAndDelete &&
        (isCurrentUserDrillCreator || !isCoachCornerPage || isUserSuperAdmin()) && (
          <>
            <ConditionalRender renderValue={!revisionId}>
              <IconButton
                color='primary'
                aria-label='edit icon button'
                component='span'
                onClick={handleEditButtonClick}
              >
                <MyEditIcon className={classes.editIcon} />
              </IconButton>
            </ConditionalRender>

            <IconButton
              color='primary'
              aria-label='delete icon button'
              component='span'
              onClick={handleDeleteButtonClick}
            >
              <MyDeleteIcon className={classes.deleteIcon} />
            </IconButton>
          </>
        )}
      <DeleteModal
        title={
          !originalTrainingModuleId
            ? constructDrillTitle(translate('ra.text.deleteDrill'), trainingModule?.title)
            : constructDrillTitle(translate('ra.text.removeDrill'), trainingModule?.title)
        }
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        handleDelete={() => deleteTrainingModule(drillId)}
        loading={deleteADrill.isLoading}
      />
    </>
  )
}

DrillShowToolbar.propTypes = {
  drillId: PropTypes.string,
  trainingModule: trainingModuleType,
  pathname: PropTypes.string,
  isModal: PropTypes.bool,
  openDrillCreateModal: PropTypes.func,
  deleteADrill: mutationType,
  deleteTrainingModule: mutationType,
  isLoading: PropTypes.bool,
  isDownloaded: PropTypes.bool,
  isCurrentUserDrillCreator: PropTypes.bool,
}

export default DrillShowToolbar
