import COLORS from '../../../utils/constants/colors'

const deleteButtonWithConfirmationStyles = {
  subtitle: {
    marginTop: '15px',
  },
  deleteFormButton: {
    color: COLORS.orange,
  },
  deleteModalButton: {
    backgroundColor: COLORS.red,
    color: COLORS.white,
    '&:hover': {
      backgroundColor: COLORS.red,
    },
  },
  cancelButton: {
    color: COLORS.lightRed,
  },
}

export default deleteButtonWithConfirmationStyles
