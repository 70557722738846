import COLORS from '../../../../utils/constants/colors'

const playerListEmptyStyles = {
  card: {
    height: '85.5vh',
  },
  basketballPlayerIcon: {
    width: 290,
    height: 310,
  },
  headerTableCellName: {
    paddingRight: 45,
  },
  tableRow: {
    backgroundColor: 'white',
  },
  topToolbarRoot: {
    paddingBottom: 5,
    paddingTop: 5,
  },
  containerImage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10%',
  },
  title: {
    marginTop: 30,
    color: COLORS.darkGrey,
    fontSize: 20,
  },
}

export default playerListEmptyStyles
