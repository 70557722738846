const teamCalendarBreadcrumb = (data) => {
  const { breadcrumb, teamName } = data || {}

  return [
    teamName ? teamName.toUpperCase() : '',
    'CALENDAR',
    breadcrumb === 'create' ? 'CREATE' : '',
  ]
}

export default teamCalendarBreadcrumb
