import COLORS from '../../../../utils/constants/colors'

const subscriptionFormStyles = {
  container: {
    width: '100%',
    margin: 'auto',
  },

  formHeader: {
    marginTop: '3px',
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  halfLineFieldContainer: {
    display: 'flex',
    gap: '20px',
    marginBottom: '8px',
  },
  oneLineFields: {
    width: '65%',
    marginBottom: '5px',
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  invoiceCheck: {
    marginTop: '5px',
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    backgroundColor: COLORS.opacBlue,
    borderColor: COLORS.blue,
    minWidth: '50%',
  },
  cardField: {
    marginRight: '10%',
  },
  typographyBold: { fontWeight: 'bold' },
  textButtons: { color: COLORS.blue },
}

export default subscriptionFormStyles
