import React from 'react'
import DrillCreateForm from '../DrillCreateForm/DrillCreateForm'
import useCreateDrillFormQueries from '../../../hooks/useCreateDrillFormQueries'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'

const DrillCreatePage = () => {
  const { trainingModule, createDrill, updateDrill, deleteDrill, isFetching, isMutating, type } =
    useCreateDrillFormQueries()

  return (
    <ConditionalRender renderValue={!isFetching}>
      <DrillCreateForm
        trainingModule={trainingModule}
        createDrill={createDrill}
        updateDrill={updateDrill}
        deleteDrill={deleteDrill}
        isFetching={isFetching}
        isMutating={isMutating}
        type={type}
      />
    </ConditionalRender>
  )
}

export default DrillCreatePage
