import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useState } from 'react'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import deleteDrill from '../Apis/drill/deleteDrill'
import QueryKeys from '../utils/constants/queryKeys'
import useHistoryPush from './useHistoryPush'
import updatePlaybook from '../Apis/playbook/updatePlaybook/updatePlaybook'
import getDrill from '../Apis/drill/getDrill'
import { TRAINING_MODULES_TYPES_URL } from '../utils/constants/trainingModulesTypes'
import useQueryParams from './useQueryParams'
import getRevision from '../Apis/revisions/getRevision'
import getPracticePlan from '../Apis/practice/getPracticePlan'
import getPlaybooks from '../Apis/playbook/getPlaybooks/getPlaybooks'

const useShowTrainingModuleQueries = (props) => {
  const { practicePlanId, onModalClose, updatePracticePlan, clickedComponentId } = props || {}
  const history = useHistory()
  const trainingModuleType = history?.location?.pathname?.split('/')[1]
  const urlId = useRouteMatch({ path: `/${trainingModuleType}/:id` })?.params?.id
  const [trainingModule, setTrainingModule] = useState()
  const [playbookId, setPlaybookId] = useState()
  const [practicePlan, setPracticePlan] = useState()
  const [practicePlanComponent, setPracticePlanComponent] = useState()
  const drillId = clickedComponentId || urlId
  const queryClient = useQueryClient()
  const queryParams = useQueryParams()
  const { org, team } = queryParams
  const { redirect } = useHistoryPush()

  const fetchedTrainingModule = useQuery([QueryKeys.GET_DRILL], () => getDrill(drillId), {
    enabled:
      (trainingModuleType === TRAINING_MODULES_TYPES_URL.PLAYS ||
        trainingModuleType === TRAINING_MODULES_TYPES_URL.DRILLS) &&
      drillId !== 'create',
    onSuccess: (data) => {
      setTrainingModule(data)
    },
  })

  const fetchedTrainingModuleRevision = useQuery(
    [QueryKeys.GET_REVISION],
    () => getRevision(drillId),
    {
      enabled: trainingModuleType === 'playbook' || trainingModuleType === 'practice-plan',
      onSuccess: (data) => {
        setTrainingModule(data)
      },
    },
  )

  const practicePlanQueryRes = useQuery(
    [QueryKeys.GET_PRACTICE_PLAN],
    () => getPracticePlan({ practicePlanId }),
    {
      enabled:
        trainingModuleType === 'practice-plan' &&
        Boolean(trainingModule) &&
        Boolean(practicePlanId),
      onSuccess: (res) => {
        setPracticePlan(res)
        setPracticePlanComponent(
          res.practicePlanComponents.filter((comp) => comp?.trainingModule?.id === drillId)[0],
        )
      },
    },
  )

  const playbookQueryRes = useQuery(
    [QueryKeys.GET_PLAYBOOKS],
    () => getPlaybooks({ teamId: team }),
    {
      enabled: trainingModuleType === 'playbook',
      onSuccess: (res) => {
        setPlaybookId(res[0].id)
      },
    },
  )

  const isFetching = fetchedTrainingModule.isFetching

  const deletePlaybookTrainingModule = useMutation((drillId) => updatePlaybook(drillId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_DRILLS).then((res) => res)
    },
  })

  const deleteADrill = useMutation((drillId) => deleteDrill(drillId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_DRILLS).then((res) => res)
      redirect(`/${trainingModuleType}`, [{ org }, { team }])
    },
  })

  const deleteTrainingModule = (drillId) => {
    if (trainingModuleType === 'practice-plan') {
      updatePracticePlan.mutate(
        {
          id: practicePlan?.id,
          values: {
            description: practicePlan?.description,
            practicePlanComponentsForDelete: [practicePlanComponent?.id],
          },
        },
        {
          onSuccess: () => {
            onModalClose()
            queryClient.invalidateQueries(QueryKeys.GET_PRACTICE_PLAN)
          },
        },
      )
    } else if (trainingModuleType === 'playbook' && playbookId) {
      deletePlaybookTrainingModule.mutate(
        {
          playBookId: playbookId,
          values: { trainingModulesForDeletion: [drillId] },
        },
        {
          onSuccess: () => {
            redirect('/playbook')
            queryClient.invalidateQueries(QueryKeys.GET_PLAYBOOKS)
          },
        },
      )
    } else {
      deleteADrill.mutate(drillId)
    }
  }

  return {
    trainingModule,
    isFetching,
    drillId,
    pathname: trainingModuleType,
    trainingModuleType:
      trainingModuleType === 'drills' || trainingModuleType === 'practice-plan'
        ? 'drills'
        : 'plays',
    deleteADrill,
    deleteTrainingModule,
    setPlaybookId,
  }
}

export default useShowTrainingModuleQueries
