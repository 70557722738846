import React from 'react'
import PropTypes from 'prop-types'
import isUserScopesValid from '../../utils/helpers/isUserScopesValid'

const IsUserScopesAuthorized = ({ acceptedScopes, children }) => {
  if (!children) return null
  const isAccepted = isUserScopesValid(acceptedScopes)

  return isAccepted ? <div>{children}</div> : null
}

IsUserScopesAuthorized.propTypes = {
  acceptedScopes: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default IsUserScopesAuthorized
