import axiosInstance from '../../axiosInterceptor/axiosInstance'

const createPlaybook = (data) => {
  const { teamId, trainingModulesIds } = data
  const url = `/play-books?teamId=${teamId}`
  const values = { trainingModulesIds }
  return axiosInstance.post(url, values)
}

export default createPlaybook
