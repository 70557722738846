import React from 'react'
import PropTypes from 'prop-types'
import './userInfo.css'

const UserInfo = ({ userName, userEmail }) => {
  return (
    <div className='user-info'>
      <p className='user-info-name'>{userName}</p>
      <p className='user-info-email'>{userEmail}</p>
    </div>
  )
}

UserInfo.propTypes = {
  userName: PropTypes.string,
  userEmail: PropTypes.string,
}

export default UserInfo
