import axiosInstance from '../axiosInterceptor/axiosInstance'

const addPracticePlan = async (data) => {
  const { values } = data
  const { eventId, description, components } = values
  const url = '/practice-plans'

  return axiosInstance.post(url, {
    eventId,
    description,
    practicePlanComponentForCreationDtos: components,
  })
}

export default addPracticePlan
