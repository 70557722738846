import DialogActions from '@material-ui/core/DialogActions'
import React from 'react'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import ButtonWithLoader from '../ui/ButtonWithLoader/ButtonWithLoader'
import COLORS from '../../utils/constants/colors'
import DialogWithCloseIcon from '../DialogWithCloseIcon/DialogWithCloseIcon'

const GameSubmitConfirmationModal = ({ isOpen, onCancelClick, onCreateClick }) => {
  const translate = useTranslate()

  return (
    <DialogWithCloseIcon
      open={isOpen}
      maxDialogWidth='50%'
      maxDialogHeight='20%'
      hideCloseIcon
      dialogTitle={translate('ra.text.stillUploading')}
    >
      <DialogActions
        style={{
          padding: 0,
        }}
      >
        <ButtonWithLoader
          label={translate('ra.buttons.cancel')}
          style={{ backgroundColor: COLORS.red }}
          onClick={onCancelClick}
        />
        <ButtonWithLoader label={translate('ra.buttons.create')} onClick={onCreateClick} />
      </DialogActions>
    </DialogWithCloseIcon>
  )
}

GameSubmitConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  onCancelClick: PropTypes.func,
  onCreateClick: PropTypes.func,
}

export default GameSubmitConfirmationModal
