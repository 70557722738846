import localStorageIds from '../constants/localStorageIds'
import { USER_SCOPES } from '../constants/userScopes'
import localStorageHelper from './localStorageHelper'

const isUserScopesValid = (allowedScopes) => {
  const { ALL } = USER_SCOPES
  if (allowedScopes === ALL) return true

  const { localStorageValue: userScopes } = localStorageHelper(localStorageIds.SCOPES)

  const { localStorageValue: selectedOrg } = localStorageHelper(localStorageIds.SELECTED_ORG)
  const { id: orgId } = selectedOrg || {}

  if (!userScopes || !selectedOrg) return false
  if (!userScopes[orgId]) return false

  return userScopes[selectedOrg?.id].some((scope) => allowedScopes.includes(scope))
}

export default isUserScopesValid
