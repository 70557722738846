import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  GET_MANY,
  GET_MANY_REFERENCE,
} from 'react-admin'
import playerProvider from './playerProvider'
import positionsProvider from './positionsProvider'
import teamProvider from './teamProvider'

const dataProviders = [
  { dataProvider: playerProvider, resources: ['players'] },
  { dataProvider: positionsProvider, resources: ['positions'] },
  { dataProvider: teamProvider, resources: ['team'] },
]

const dataProviderIndex = (type, resource, params) => {
  const dataProviderMapping = dataProviders.find((dp) => dp.resources.includes(resource))

  const mappingType = {
    [GET_LIST]: 'getList',
    [GET_ONE]: 'getOne',
    [GET_MANY]: 'getMany',
    [GET_MANY_REFERENCE]: 'getManyReference',
    [CREATE]: 'create',
    [UPDATE]: 'update',
    [UPDATE_MANY]: 'updateMany',
    [DELETE]: 'delete',
  }

  return dataProviderMapping.dataProvider[mappingType[type]](resource, params)
}

export default dataProviderIndex
