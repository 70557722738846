import React from 'react'
import { Checkbox, FormControl, makeStyles, MenuItem, Select } from '@material-ui/core'
import PropTypes from 'prop-types'
import ExpandIcon from '../../assets/icons/expandIcon'
import mySelectWithCheckmarksStyles from './mySelectWithCheckmarks.styles'
import enumDisplayNameType from '../../types/enumDisplayNameType'
import tacticClickedType from '../../types/tactics/tacticClickedType'

const MySelectWithCheckmarks = (props) => {
  const { open, onClick, value, onChange, options, placeholder, fullWidth } = props
  const useStyles = makeStyles(mySelectWithCheckmarksStyles)
  const classes = useStyles()

  const renderValue = (selected) => {
    if (selected.length === 0) {
      return <div className={classes.placeholder}>{placeholder}</div>
    }

    return (
      <div className={classes.renderValueContainer}>
        {placeholder}
        <span className={classes.renderValueLabel}>{selected.length}</span>
      </div>
    )
  }

  return (
    <div>
      <FormControl fullWidth={fullWidth}>
        <Select
          open={open}
          onClick={onClick}
          displayEmpty
          IconComponent={ExpandIcon}
          disableUnderline
          multiple
          value={value}
          onChange={onChange}
          renderValue={renderValue}
          classes={{
            select: classes.select,
            filled: classes.selectInput,
            icon: classes.selectIcon,
          }}
          style={{
            height: '40px',
            backgroundColor: '#f5f5f5',
            borderRadius: '5px',
          }}
        >
          {options &&
            options.map((option) => (
              <MenuItem key={option?.name} value={option} classes={{ root: classes.menuItem }}>
                <Checkbox checked={Boolean(value.find((v) => v.name === option.name))} />
                {option.displayName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  )
}

MySelectWithCheckmarks.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.arrayOf(enumDisplayNameType), tacticClickedType]),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(enumDisplayNameType),
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
}

export default MySelectWithCheckmarks
