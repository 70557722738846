import COLORS from '../../utils/constants/colors'

const chipsContainerStyles = {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '5px',
  },
  overflow: {
    width: '19px',
    height: '20px',
    backgroundColor: COLORS.backgroundGrey,
    borderRadius: '100%',
    padding: '3px 8px',
  },
}

export default chipsContainerStyles
