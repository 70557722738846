import React from 'react'
import { useQuery } from 'react-query'
import { makeStyles, Switch, Tooltip } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import drillShowAppbarStyles from './drillShowAppbar.styles'
import DrillShowToolbar from '../DrillShowToolbar/DrillShowToolbar'
import QueryKeys from '../../../utils/constants/queryKeys'
import getUserData from '../../../Apis/user/getUserData'
import TitleField from '../../../react-admin/components/fields/TitleField/TitleField'
import PdfDownloadButton from '../../../components/PdfDownloadButton/PdfDownloadButton'
import trainingModuleType from '../../../types/trainingModuleType'
import TRAINING_MODULES_TYPES from '../../../utils/constants/trainingModulesTypes'
import PDF_VARIANTS from '../../../utils/constants/pdfVariants'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import MyCloseIcon from '../../../assets/icons/MyCloseIcon'
import useHistoryPush from '../../../hooks/useHistoryPush'

const DrillShowAppbar = (props) => {
  const { trainingModule, isModal, onModalClose } = props
  const { type, title } = trainingModule
  const translate = useTranslate()
  const history = useHistory()
  const { redirect } = useHistoryPush()
  const pathname = history?.location?.pathname?.split('/')[1]
  const useStyles = makeStyles(drillShowAppbarStyles)
  const classes = useStyles()

  const me = useQuery([QueryKeys.GET_ACCOUNT_ME], () => getUserData())
  const isCurrentUserDrillCreator = me?.data?.id === trainingModule?.creator.id

  const onCloseIconClicked = () => {
    if (isModal) {
      return onModalClose()
    }
    redirect(`/${pathname}`)
  }

  return (
    <div className={classes.appbarWrapper}>
      <TitleField title={trainingModule?.title} />
      <ConditionalRender
        renderValue={
          !trainingModule?.originalCreatorFirstName && !trainingModule?.originalTrainingModuleId
        }
      >
        <div className={classes.switch}>
          <span className={classes.publishLabel}>{translate('ra.text.publish')}</span>
          <Switch
            disabled
            defaultChecked={trainingModule?.access.name === 'PUBLIC'}
            color='primary'
          />
        </div>
      </ConditionalRender>

      <PdfDownloadButton
        pdfVariant={
          type?.name === TRAINING_MODULES_TYPES.plays ? PDF_VARIANTS.PLAY : PDF_VARIANTS.DRILL
        }
        title={`${title !== 'Untitled' ? title : type.toLowerCase()}.pdf`}
        contentProps={{ trainingModule }}
      />
      <DrillShowToolbar {...props} isCurrentUserDrillCreator={isCurrentUserDrillCreator} />
      <Tooltip title={translate('ra.action.cancel')}>
        <IconButton
          color='primary'
          aria-label='back arrow icon button'
          component='span'
          onClick={onCloseIconClicked}
        >
          <MyCloseIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

DrillShowAppbar.propTypes = {
  trainingModule: trainingModuleType,
  isModal: PropTypes.bool,
  onModalClose: PropTypes.func,
}

export default DrillShowAppbar
