// axiosInstance.js
import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import { setLocalStorageValue } from '../../utils/helpers/localStorageHelperMethods'
import localStorageIds from '../../utils/constants/localStorageIds'
import generalHeader from '../../utils/constants/generalHeader'

let isRefreshing = false // Flag to check if a token refresh is in progress
let failedQueue = [] // Queue to hold requests that fail due to a 401
let tokenRefreshPromise // To hold the promise of the refresh token request

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: BASE_URL, // Set your API base URL
})

// Function to process the queue
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  // Clear the queue
  failedQueue = []
}

// Function to refresh the token
async function refreshToken() {
  try {
    const response = await axios.get(`${BASE_URL}/auth/refresh-token`, {
      withCredentials: true,
    })

    // Update the token in localStorage
    const newAccessToken = response?.data?.accessToken
    setLocalStorageValue([{ id: localStorageIds.TOKEN, value: newAccessToken }])

    // Return the new token
    return newAccessToken
  } catch (error) {
    console.error('Failed to refresh token')
    throw error // Re-throw to handle in interceptor
  }
}

// Add a request interceptor to validate the token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(localStorageIds.TOKEN)

    // If no token is available, reject the request
    if (!token) {
      return Promise.reject()
    }

    // Add the token to the headers
    config.headers = generalHeader(token)
    return config
  },
  (error) => Promise.reject(error),
)

// Add a response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error?.config

    // If error status is 401 and the request is not for the refresh token
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true // Mark the request as retried

      if (!isRefreshing) {
        isRefreshing = true // Set the refreshing flag to true

        // Create the refresh token promise if not already created
        tokenRefreshPromise = refreshToken()
          .then((newAccessToken) => {
            axiosInstance.defaults.headers = generalHeader(newAccessToken)
            processQueue(null, newAccessToken)
            return newAccessToken // Return new token for the next requests
          })
          .catch((err) => {
            processQueue(err, null)
            window.location.href = '/login' // Redirect to login page on error
            throw err // Propagate the error
          })
          .finally(() => {
            isRefreshing = false // Reset the refreshing flag
            tokenRefreshPromise = null // Clear the promise
          })
      }

      // Wait for the token refresh to complete
      const newAccessToken = await tokenRefreshPromise
      originalRequest.headers = generalHeader(newAccessToken) // Update the original request's headers

      // Retry the original request with the new token
      return axiosInstance(originalRequest)
    }

    return Promise.reject(error)
  },
)

export default axiosInstance
