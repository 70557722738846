import React, { useState } from 'react'
import { Card, makeStyles, Tooltip, Typography } from '@material-ui/core'
import classnames from 'classnames'
import PaymentIcon from '@material-ui/icons/Payment'
import subscriptionNoticeStyles from './subscriptionNotice.styles'
import getSelectedOrgSubscriptionStatus from '../../utils/helpers/getSelectedOrgSubscriptionStatus'
import orgIsInSubscriptionState from '../../utils/helpers/orgIsInSubscriptionState'
import SUBSCRIPTION_STATES from '../../utils/constants/subscriptionStates'
import useHistoryPush from '../../hooks/useHistoryPush'
import isUserOrgRolesValid from '../../utils/helpers/isUserOrgRolesValid'
import USER_ORG_ROLES from '../../utils/constants/userOrgRoles'
import useGetLocalStorage from '../../hooks/useGetLocalStorage'
import localStorageIds from '../../utils/constants/localStorageIds'

const SubscriptionNotice = () => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const { redirect } = useHistoryPush()
  const useStyles = makeStyles(subscriptionNoticeStyles)
  const classes = useStyles()
  const localStorageValue = useGetLocalStorage()
  const {
    [localStorageIds.SUBSCRIPTIONS]: subsPerOrg,
    [localStorageIds.SELECTED_ORG]: selectedOrg,
  } = localStorageValue || {}
  const selectedOrgSubscriptionStatus = getSelectedOrgSubscriptionStatus(subsPerOrg, selectedOrg)
  const successStylesShown = !orgIsInSubscriptionState([SUBSCRIPTION_STATES.INACTIVE])
  const userIsOwner = isUserOrgRolesValid([USER_ORG_ROLES.OWNER])

  const openTooltip = () => setIsTooltipVisible(true)
  const closeTooltip = () => setIsTooltipVisible(false)

  const handleNoticeClick = () => {
    if (userIsOwner) redirect('/subscription')
  }

  const SUBSCRIPTION_TEXT = {
    [SUBSCRIPTION_STATES.INACTIVE]: 'Subscribe',
    [SUBSCRIPTION_STATES.TRIAL]: selectedOrgSubscriptionStatus,
  }

  if (orgIsInSubscriptionState([SUBSCRIPTION_STATES.ACTIVE, SUBSCRIPTION_STATES.GRACE])) return null

  return (
    <Card
      className={classnames(classes.card, {
        [classes.successCard]: successStylesShown,
        [classes.failureCard]: !successStylesShown,
      })}
      elevation={0}
      onClick={handleNoticeClick}
      style={{ cursor: userIsOwner ? 'pointer' : 'default' }}
    >
      <Tooltip
        open={isTooltipVisible}
        onClose={closeTooltip}
        onOpen={openTooltip}
        title={`${selectedOrgSubscriptionStatus}`}
        classes={{ tooltip: classes.tooltip }}
      >
        <PaymentIcon
          className={successStylesShown ? classes.successCloudIcon : classes.failureCloudIcon}
          onClick={openTooltip}
        />
      </Tooltip>
      <div className={classes.trialPeriodText}>
        <Typography
          className={
            successStylesShown ? classes.successTypographyBold : classes.failureTypographyBold
          }
        >
          {SUBSCRIPTION_TEXT?.[selectedOrgSubscriptionStatus]}
        </Typography>
      </div>
    </Card>
  )
}

export default SubscriptionNotice
