import COLORS from '../../../utils/constants/colors'
import { CARD_STYLES } from '../../../utils/constants/CSS/cardStyles'

const dashboardStyles = {
  loader: CARD_STYLES.loader,
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 22,
    fontWeight: 700,
    color: COLORS.darkGrey,
    margin: '40px 0 20px 0',
  },
  subTitle: {
    display: 'block',
    fontSize: 16,
    color: COLORS.darkGrey,
    margin: '10px 0',
  },
  image: {
    margin: '30px 0',
  },
  createButton: {
    marginTop: 20,
  },
}

export default dashboardStyles
