import COLORS from '../../utils/constants/colors'

const designStyles = (type) => ({
  wrapper: {
    position: 'relative',
    display: type !== 'drills' ? 'flex' : null,
    width: type !== 'drills' ? '100%' : null,
    marginTop: 20,
  },
  designNumber: {
    display: 'block',
    position: 'absolute',
    top: 40,
    left: 41,
    zIndex: 500,
    fontWeight: 500,
    fontSize: '14px',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    display: 'block',
    width: 350,
  },
  deleteIcon: {
    '&&': {
      position: 'absolute',
      right: type === 'drills' ? -240 : 30,
      // left:
      bottom: type === 'drills' ? 50 : 40,
    },
  },
  iconContainer: {
    width: 145,
    position: 'absolute',
    bottom: 20,
    right: 15,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
  },
  halfCourtMargin: {
    paddingBottom: 30,
  },
  wholeCourtMargin: {
    marginTop: 25,
  },
  formControlRoot: {
    marginLeft: type === 'drills' ? '0px' : '10px',
    width: type === 'drills' ? '100%' : '70%',
  },
  inputBaseRoot: {
    padding: 10,
    height: 110,
  },
  input: {
    position: 'absolute',
    top: 10,
    width: '93%',
    height: '100%',
    minHeight: '0.7rem',
    fontSize: '0.95rem',
  },
  inputMultiline: {
    fontSize: '0.95rem!important',
  },
  showDescription: {
    width: '75%',
    wordBreak: 'break-word',
    margin: type === 'drills' ? '0 auto 0 15px' : '50px 0 0 0',
    marginLeft: type === 'drills' ? 0 : 30,
    marginTop: 20,
    display: 'flex',
    color: COLORS.lightGrey,
    fontSize: 12,
  },
})

export default designStyles
