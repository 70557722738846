import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ORGANISATION_TYPES from '../../utils/constants/OrganisationTypes'
import AddClubTeamForm from '../form/AddOrgForms/AddClubTeamForm/AddClubTeamForm'
import AddLeagueForm from '../form/AddOrgForms/AddLeagueForm/AddLeagueForm'
import OrgAddonSelect from '../AddOrgModal/OrgAddonSelect/OrgAddonSelect'
// import InviteOrgUsersOneComp from '../AddOrgModal/InviteOrgUsersOneComp/InviteOrgUsersOneComp'
import ContainerPadding from '../containerPadding/containerPadding'
import OrgTypeChooseModal from '../AddOrgModal/OrgTypeChooseModal/OrgTypeChooseModal'
import orgWizardContentsStyles from './orgWizardContents.styles'

const OrgWizardContents = (props) => {
  const {
    setOrgTypeSelected,
    orgSuccessRedirect,
    orgTypeSelected,
    activeStep,
    stepForward,
    onClose,
  } = props
  const useStyles = makeStyles(orgWizardContentsStyles)
  const classes = useStyles()

  const wizardStepContentMapper = {
    0: {
      [ORGANISATION_TYPES.ORG_CLUB]: (
        <OrgTypeChooseModal setOrgTypeSelected={setOrgTypeSelected} stepForward={stepForward} />
      ),
    },
    1: {
      [ORGANISATION_TYPES.ORG_CLUB]: (
        <AddClubTeamForm orgSuccessRedirect={orgSuccessRedirect} stepForward={stepForward} />
      ),
      [ORGANISATION_TYPES.ORG_LEAGUE]: <AddLeagueForm stepForward={stepForward} />,
    },
    2: {
      [ORGANISATION_TYPES.ORG_CLUB]: (
        <OrgAddonSelect onClose={onClose} orgTypeSelected={orgTypeSelected} />
      ),
      [ORGANISATION_TYPES.ORG_LEAGUE]: (
        <OrgAddonSelect onClose={onClose} orgTypeSelected={orgTypeSelected} />
      ),
    },
    // 3: {
    //   [ORGANISATION_TYPES.ORG_CLUB]: (
    //     <InviteOrgUsersOneComp stepForward={stepForward} onClose={onClose} />
    //   ),
    //   [ORGANISATION_TYPES.ORG_LEAGUE]: (
    //     <InviteOrgUsersOneComp stepForward={stepForward} onClose={onClose} adminOnlyOption />
    //   ),
    // },
  }

  return (
    <ContainerPadding paddingValue='3%' className={classes.contentsContainer}>
      {wizardStepContentMapper[activeStep][orgTypeSelected]}
    </ContainerPadding>
  )
}

OrgWizardContents.propTypes = {
  setOrgTypeSelected: PropTypes.func,
  orgSuccessRedirect: PropTypes.string,
  orgTypeSelected: PropTypes.string,
  activeStep: PropTypes.number,
  stepForward: PropTypes.func,
  onClose: PropTypes.func,
}

export default OrgWizardContents
