const orgWizardContentsStyles = {
  contentsContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}

export default orgWizardContentsStyles
