import axios from 'axios'

const addVideoToAmazonBucket = async (data) => {
  const { url, file, setProgress, setCancelTokens } = data

  //  axios cancel token and save it to parent state
  const source = axios.CancelToken.source()
  setCancelTokens((prevTokens) => ({
    ...prevTokens,
    [file.name]: source,
  }))

  try {
    const response = await axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: (progressEvent) => {
        // Save the percentage to parent state
        setProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: progressEvent,
        }))
      },
      cancelToken: source.token,
    })

    return response?.data
  } catch (error) {
    if (axios.isCancel(error)) {
      // Handle cancellation if needed
      return { data: null, message: 'Video upload canceled by the user.' } // Return a message for cancellation
    }
  }
  return null
}

export default addVideoToAmazonBucket
