import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import DragIcon from '../../assets/icons/dragIcon'
import SimpleTextField from '../simpleTextField/SimpleTextField'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import MyDeleteIcon from '../../assets/icons/MyDeleteIcon'
import practicePlanComponentType from '../../types/practicePlanComponentType'
import componentFieldStyles from './componentField.styles'

const ComponentField = (props) => {
  const {
    deleteComponent,
    component,
    duration,
    isFieldHidden,
    practicePlan,
    setPracticePlan,
    setUpdatedComponents,
  } = props
  const fieldValue = practicePlan?.practicePlanComponents?.find((comp) =>
    comp.id ? comp.id === component.id : comp.tempId === component.tempId,
  )?.[duration ? 'duration' : 'notes']
  const isSidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen)
  const useStyles = makeStyles(componentFieldStyles(isSidebarOpen))
  const classes = useStyles()

  const handleOnChange = (e) => {
    const { value } = e.target
    if (component?.id)
      setUpdatedComponents((prevValue) =>
        !prevValue?.includes(component?.id) ? [...prevValue, component?.id] : prevValue,
      )

    // If the field is duration and the value is letter, don't update the state
    if (duration && isNaN(value)) {
      return
    }

    // Identify changed component and change the duration or notes else return it unchanged and update the Practice plan state
    const updatedComponents = (practicePlan?.practicePlanComponents || []).map((comp) => {
      if (comp.id ? comp.id !== component.id : comp.tempId !== component.tempId) return comp

      const updatedComponent = { ...comp }
      if (!duration) {
        updatedComponent.notes = value
      } else {
        updatedComponent.duration = isNaN(value) ? comp.duration : value
      }

      return updatedComponent
    })

    setPracticePlan({
      ...practicePlan,
      practicePlanComponents: updatedComponents,
    })
  }

  return (
    <div className={classes.wrapper}>
      <ConditionalRender renderValue={duration}>
        <DragIcon />
      </ConditionalRender>

      <ConditionalRender renderValue={!isFieldHidden}>
        <SimpleTextField
          value={fieldValue}
          onChange={handleOnChange}
          style={{
            width: '100%',
            height: !duration && '100%',
          }}
          multiline={!!(component?.trainingModule && !duration)}
          minrows={component?.trainingModule ? 14 : 0}
        />
      </ConditionalRender>

      <ConditionalRender renderValue={!duration}>
        <MyDeleteIcon className={classes.deleteIcon} onClick={deleteComponent} />
      </ConditionalRender>
    </div>
  )
}

ComponentField.propTypes = {
  deleteComponent: PropTypes.func,
  component: practicePlanComponentType,
  duration: PropTypes.bool,
  isFieldHidden: PropTypes.bool,
}

export default ComponentField
