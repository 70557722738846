import { shape, string } from 'prop-types'
import enumIdType from './enumIdType'
import genderType from './genderType'
import ageRestrictionType from './ageRestrictionType'

const teamType = shape({
  ageRestriction: ageRestrictionType,
  org: enumIdType,
  gender: genderType,
  id: string,
  name: string,
  threeLetterIdentifier: string,
})

export default teamType
