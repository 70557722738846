import COLORS from '../../utils/constants/colors'

const pdfDownloadButtonStyles = {
  orangeIcon: {
    '&&': {
      zIndex: 1000,
      fill: COLORS.orange,
    },
  },
}

export default pdfDownloadButtonStyles
