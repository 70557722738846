const colourPickerPopperStyles = {
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px, inset 0 -1px 0 ',
    '$root.Mui-focusVisible &': {
      outline: '2px auto',
      outlineOffset: 2,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
    },
  },
  paper: {
    marginBottom: 10,
    marginRight: 20,
  },
  popper: {
    zIndex: 1400,
    maxWidth: '410px',
    display: 'flex',
  },
  coloredBox: {
    width: 20,
    height: 20,
    transform: 'scale(1.6)',
    margin: 5,
  },
}

export default colourPickerPopperStyles
