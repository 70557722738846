const popoverStyles = {
  paper: {
    height: '300px',
    width: '260px',
    overflow: 'auto',
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default popoverStyles
