import designsRowPdfHelper from '../../../utils/helpers/pdfHelpers/designsRowPdfHelper'
import {
  minutesTextWithCircleBackground,
  textWithSquareBackground,
} from '../../../utils/helpers/pdfHelpers/practicePlanPdfHelper'
import Stadium from '../../../assets/images/FullCourt.png'
import HalfStadium from '../../../assets/images/HalfCourt.png'
import getPracticePlan from '../../../Apis/practice/getPracticePlan'
import getPlayer from '../../../Apis/player/getPlayer'

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = src
    image.onload = () => resolve(image)
    image.onerror = reject
  })
}
const teamsRowPdfHelper = (teams) => {
  if (!teams?.length) return []

  const columnsArray = []
  let columnsRow = []

  teams.forEach((team, index) => {
    if ((index + 1) % 5 === 0) {
      columnsArray.push(columnsRow)
      columnsRow = []
      columnsArray.push([{ text: '\n', width: 87 }])
    }
    columnsRow.push({
      table: {
        body: [
          [
            {
              text: `Team ${index + 1}`,
              style: 'descriptionText',
            },
          ],
          [
            {
              stack: team.teamPlayerNames.map((playerName, playerIndex) => {
                const textStyle =
                  playerIndex === team.numberOfPlayers - 1 ? { decoration: 'underline' } : {}
                return { text: playerName, style: textStyle }
              }),
              style: 'descriptionText',
            },
          ],
        ],
        widths: [87.5],
      },
    })
  })

  columnsArray.push(columnsRow)
  return columnsArray.map((columnArray) => ({
    columns: columnArray,
  }))
}

const createTables = (teams) => {
  const tables = []
  const threePlayerTeams = teams.filter((team) => team.numberOfPlayers === 3)
  const fourPlayerTeams = teams.filter((team) => team.numberOfPlayers === 4)
  const fivePlayerTeams = teams.filter((team) => team.numberOfPlayers === 5)

  if (threePlayerTeams.length > 0) {
    const tableOfThree = {
      table: {
        body: [
          [{ text: 'Teams of Three', style: 'descriptionText' }],
          [
            {
              border: [true, false, true, true],
              stack: teamsRowPdfHelper(threePlayerTeams),
            },
          ],
        ],
      },
    }

    tables.push(tableOfThree)
  }

  if (fourPlayerTeams.length > 0) {
    tables.push([{ text: '\n', width: 87 }])
    const tableOfFour = {
      table: {
        body: [
          [{ text: 'Teams of Four', style: 'descriptionText' }],
          [
            {
              border: [true, false, true, true],
              stack: teamsRowPdfHelper(fourPlayerTeams),
            },
          ],
        ],
      },
    }

    tables.push(tableOfFour)
  }

  if (fivePlayerTeams.length > 0) {
    tables.push([{ text: '\n', width: 87 }])
    const tableOfFive = {
      table: {
        body: [
          [{ text: 'Teams of Five', style: 'descriptionText' }],
          [
            {
              border: [true, false, true, true],
              stack: teamsRowPdfHelper(fivePlayerTeams),
            },
          ],
        ],
      },
    }

    tables.push(tableOfFive)
  }

  return tables
}

const practicePlanWithPlayersPdfContent = async (data) => {
  const { practicePlan, practicePlayers } = data

  const content = []
  const maxPageWords = 3200
  const maxOnlyTextTimeWidth = 15
  const maxOnlyTextNotesWidth = 540
  const maxTableWidth = 555
  const designWidth = 96.5
  const maxDesignWidth = 300

  const getPlayerData = async (playerId) => {
    return getPlayer({ playerId })
  }

  if (practicePlan) {
    const loadedPracticePlan = await getPracticePlan({
      practicePlanId: practicePlan.id,
    })

    const { practicePlanComponents } = loadedPracticePlan || {}
    const stadiumImage = await loadImage(Stadium)
    const halfStadiumImage = await loadImage(HalfStadium)

    practicePlanComponents?.forEach((practicePlanComponent) => {
      const { trainingModule, duration, notes } = practicePlanComponent || {}
      const { designs, description, title } = trainingModule || {}

      // Text Component
      if (!trainingModule) {
        content.push({
          pageBreak: content.length > 0 && notes.length >= maxPageWords ? 'before' : '',
          unbreakable: notes.length < maxPageWords,
          table: {
            widths: [maxOnlyTextTimeWidth, maxOnlyTextNotesWidth],
            dontBreakRows: true,
            headerRows: 1,
            keepWithHeaderRows: true,
            body: [
              [
                {
                  border: [true, true, false, true],
                  ...minutesTextWithCircleBackground(duration?.toString()),
                  style: { alignment: 'left' },
                  colSpan: 1,
                },
                {
                  border: [false, true, true, true],
                  ...textWithSquareBackground(notes, maxOnlyTextNotesWidth, true),
                  style: 'notesText',
                  colSpan: 1,
                },
              ],
            ],
          },
        })
      }

      if (trainingModule) {
        content.push({
          pageBreak:
            content.length > 0 && notes.length + description.length >= maxPageWords ? 'before' : '',
          unbreakable: notes.length + description.length < maxPageWords,
          table: {
            widths: [maxTableWidth / 2, maxTableWidth / 2],
            body: [
              [
                {
                  border: [true, true, false, false],
                  ...minutesTextWithCircleBackground(duration?.toString()),
                  style: { alignment: 'left' },
                },
                {
                  border: [false, true, true, false],
                  text: title,
                  style: 'titleText',
                },
              ],
              [
                {
                  stack: [
                    {
                      text: description,
                      style: 'descriptionText',
                      colSpan: 1,
                      rowSpan: 1,
                    },
                    notes.length > 0
                      ? {
                          ...textWithSquareBackground(notes, maxTableWidth / 2),
                          style: 'notesText',
                          rowSpan: 1,
                        }
                      : {},
                  ],
                  border: [true, false, false, true],
                },
                {
                  border: [false, false, true, true],
                  stack: designsRowPdfHelper(
                    designs,
                    designWidth,
                    maxDesignWidth,
                    stadiumImage,
                    halfStadiumImage,
                  ),
                  colSpan: 1,
                  rowSpan: 1,
                },
              ],
            ],
          },
        })
      }
    })
  }

  if (practicePlayers) {
    const dataPromises = practicePlayers.players.map((playerId) => getPlayerData(playerId))
    const allPlayerData = await Promise.all(dataPromises)
    const playerNames = allPlayerData.map(
      (player) => `${player.personalData.firstName} ${player.personalData.lastName}`,
    )
    const teams = practicePlayers.practiceTeams
    content.push({
      table: {
        widths:
          teams?.length > 0 ? [(maxTableWidth * 28) / 100, (maxTableWidth * 72) / 100] : [100, 0],
        body: [
          [
            {
              text: 'Players in practice',
              style: 'descriptionText',
            },
            teams?.length > 0
              ? {
                  text: 'Teams in practice',
                  style: 'descriptionText',
                }
              : null,
          ],

          [
            {
              stack: [
                {
                  ul: playerNames,
                  style: 'descriptionText',
                },
              ],
              border: [true, false, false, true],
            },
            teams?.length > 0 ? [...createTables(teams)] : null,
          ],
        ],
      },
    })
  }
  return content
}

export default practicePlanWithPlayersPdfContent
