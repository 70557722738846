import COLORS from '../../utils/constants/colors'

const goBackButtonStyles = {
  container: {
    paddingTop: '1.5vh',
    paddingBottom: '1.5vh',
    paddingLeft: 25,
    cursor: 'pointer',
  },
  icon: {
    marginRight: 10,
    fill: COLORS.orange,
  },
  label: {
    fontSize: 13,
    color: COLORS.orange,
    fontWeight: 500,
  },
}

export default goBackButtonStyles
