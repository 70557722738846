const playbookMasonryStyles = () => ({
  myMasonryGrid: {
    display: 'flex',
    width: 'auto',
  },
  myMasonryGridColumn: {
    padding: 15,
    backgroundClip: 'padding-box',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  divider: {
    width: '30%',
    marginLeft: '10px',
    marginRight: '10px',
    flex: 1,
  },
  chip: {
    marginLeft: '10px',
    marginRight: '10px',
  },
})

export default playbookMasonryStyles
