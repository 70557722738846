import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useTranslate } from 'react-admin'
import { Card, CardContent, CardHeader, Divider, makeStyles, Typography } from '@material-ui/core'
import CardWrapperMoreIcon from '../../../components/layout/CardWrapperMoreIcon/CardWrapperMoreIcon'
import courtsPageStyles from './courtsPage.styles'
import AddButton from '../../../components/ui/AddButton/AddButton'
import CourtsList from '../CourtsList/CourtsList'
import CourtsAddNew from '../CourtsAddNew/CourtsAddNew'
import QueryKeys from '../../../utils/constants/queryKeys'
import getCourts from '../../../Apis/courts/getCourts'
import getCourt from '../../../Apis/courts/getCourt'
import useQueryParams from '../../../hooks/useQueryParams'
import localStorageHelper from '../../../utils/helpers/localStorageHelper'
import localStorageIds from '../../../utils/constants/localStorageIds'
import IsUserRolesAuthorized from '../../../components/IsUserRolesAuthorized/IsUserRolesAuthorized'
import USER_ORG_ROLES from '../../../utils/constants/userOrgRoles'
import USER_ORG_MEMBERS_ROLES from '../../../utils/constants/userOrgMembersRoles'

const CourtsPage = () => {
  const [addCourtShow, setAddCourtShow] = useState(false)
  const [editedCourtId, setEditedCourtId] = useState('')
  const translate = useTranslate()
  const queryParams = useQueryParams()
  const orgLocalStorageHelper = localStorageHelper(localStorageIds.SELECTED_ORG)
  const useStyles = makeStyles(courtsPageStyles)
  const classes = useStyles()

  const courts = useQuery(
    [QueryKeys.GET_COURTS, orgLocalStorageHelper?.localStorageValue?.id],
    () => getCourts(queryParams?.org),
  )

  const court = useQuery([QueryKeys.GET_COURT, editedCourtId], () => getCourt(editedCourtId), {
    enabled: Boolean(editedCourtId),
    onSuccess: () => {
      setAddCourtShow(true)
    },
  })

  return (
    <CardWrapperMoreIcon>
      <Card className={classes.card}>
        <CardHeader
          classes={{ root: classes.cardHeaderRoot }}
          title={
            <Typography className={classes.cardTitle} variant='h5'>
              {translate('ra.text.courts')}
            </Typography>
          }
          subheader={
            <Typography className={classes.cardSubtitle}>
              {translate('ra.text.courtsList')}
            </Typography>
          }
        />
        <CardContent>
          <IsUserRolesAuthorized
            acceptedRoles={[
              USER_ORG_ROLES.OWNER,
              USER_ORG_ROLES.ADMIN,
              USER_ORG_MEMBERS_ROLES.TEAM_MANAGER,
            ]}
          >
            <Divider variant='middle' className={classes.divider} />
            <div className={classes.addButtonWrapper}>
              <AddButton
                label={translate('ra.action.add_court')}
                onClick={() => setAddCourtShow(true)}
                filled
              />
            </div>
          </IsUserRolesAuthorized>

          <div className={classes.bodyWrapper}>
            <CourtsAddNew
              editedCourt={court?.data}
              addCourtShow={addCourtShow}
              setAddCourtShow={setAddCourtShow}
              setEditedCourtId={setEditedCourtId}
            />
            <CourtsList
              courts={courts?.data?.courts}
              isCourtsLoading={courts.isLoading}
              editedCourtId={editedCourtId}
              setEditedCourtId={setEditedCourtId}
            />
          </div>
        </CardContent>
      </Card>
    </CardWrapperMoreIcon>
  )
}

export default CourtsPage
