import React, { useRef, useEffect } from 'react'
// import video.js as external module, otherwise doesn't work
import videojs from '!video.js'
import { useQuery } from 'react-query'
import PropTypes from 'prop-types'
import 'video.js/dist/video-js.css'
import getVideoUrl from '../../Apis/drill/getVideoUrl'
import QueryKeys from '../../utils/constants/queryKeys'
import LoaderDots from '../loaders/LoaderDots/LoaderDots'

const VideoPlayer = ({ video, trainingModuleId }) => {
  const videoRef = useRef(null)
  const playerRef = useRef(null)

  const { data, isLoading, isFetchedAfterMount } = useQuery(
    [QueryKeys.GET_VIDEO_URL],
    () => getVideoUrl(trainingModuleId, video),
    { retry: true, retryDelay: 10000 },
  )

  useEffect(() => {
    function getMimeType() {
      const fileName = video.split('/').pop().split('?')[0]
      // Extract file extension
      const extension = fileName.split('.').pop().toLowerCase()
      if (extension === 'mp4' || extension === 'mpeg4') {
        return 'video/mp4'
      }
      if (extension === 'avi') {
        return 'video/x-msvideo'
      }
      return 'Unknown'
    }
    if (videoRef.current && isFetchedAfterMount && !isLoading) {
      const videoNode = videoRef.current
      playerRef.current = videojs(videoNode, {
        fluid: true,
        controls: true,
        sources: [
          {
            src: data,
            type: getMimeType(),
          },
        ],
      })
    }
  }, [data, isFetchedAfterMount, isLoading, video])

  return (
    <div data-vjs-player>
      {isLoading ? (
        <LoaderDots> </LoaderDots>
      ) : (
        <video ref={videoRef} className='video-js vjs-default-skin'>
          <track kind='captions' />
        </video>
      )}
    </div>
  )
}

VideoPlayer.propTypes = {
  video: PropTypes.string,
  trainingModuleId: PropTypes.string,
}

export default VideoPlayer
