import * as React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import History from '@material-ui/icons/History'
import ErrorIcon from '@material-ui/icons/Report'
import Button from '@material-ui/core/Button'
import ContainerPadding from '../../../components/containerPadding/containerPadding'
import myErrorStyles from './myError.styles'

const MyError = () => {
  const history = useHistory()
  const useStyles = makeStyles(myErrorStyles)
  const classes = useStyles()

  return (
    <ContainerPadding vertical>
      <div className={classes.wrapper}>
        <h1>
          <ErrorIcon /> Something Went Wrong{' '}
        </h1>
        <Typography variant='body1'>
          A client error occurred and your request couldn&apos;t be completed.
        </Typography>
        <div>
          <Button
            className={classes.button}
            variant='contained'
            startIcon={<History />}
            onClick={() => history.go(-1)}
          >
            Back
          </Button>
        </div>
      </div>
    </ContainerPadding>
  )
}

export default MyError
