import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import * as yup from 'yup'
import { Form, Formik } from 'formik'
import decodeJwt from 'jwt-decode'
import ContainerPadding from '../../../containerPadding/containerPadding'
import ImageCrop from '../../../ui/ImageCropComponents/ImageCrop/ImageCrop'
import OrgAvatarEmptyPreview from '../../../orgAvatarEmptyPreview/OrgAvatarEmptyPreview'
import AddOrgFormBody from '../../forms/AddOrgForm/AddOrgFormBody/AddOrgFormBody'
import addOrg from '../../../../Apis/organisation/addOrg'
import AddTeamFormBody from '../../forms/AddTeamForm/AddTeamFormBody/AddTeamFormBody'
import ButtonWithLoader from '../../../ui/ButtonWithLoader/ButtonWithLoader'
import addClubTeamFormStyles from './addClubTeamForm.styles'
import useFormValidation from '../../../../hooks/useFormValidation'
import addTeam from '../../../../Apis/team/addTeam'
import QueryKeys from '../../../../utils/constants/queryKeys'
import localStorageIds from '../../../../utils/constants/localStorageIds'
import { setLocalStorageValue } from '../../../../utils/helpers/localStorageHelperMethods'
import useTeamsSettings from '../../../../hooks/useTeamsSettings'
import ORGANISATION_TYPES from '../../../../utils/constants/OrganisationTypes'
import { USER_SCOPES } from '../../../../utils/constants/userScopes'

const AddClubTeamForm = (props) => {
  const { orgSuccessRedirect, stepForward } = props
  const { countries, gender, ageRestriction } = useTeamsSettings()
  const [croppedOrgImage, setCroppedOrgImage] = useState(null)
  const [croppedTeamImage, setCroppedTeamImage] = useState(null)
  const translate = useTranslate()
  const { stringThreeLetters } = useFormValidation()
  const queryClient = useQueryClient()
  const history = useHistory()
  const useStyles = makeStyles(addClubTeamFormStyles)
  const classes = useStyles()

  const envBasedScopes =
    process.env.REACT_APP_RUNTIME === 'PROD'
      ? [USER_SCOPES.COACH]
      : [USER_SCOPES.COACH, USER_SCOPES.STATS]

  const addATeam = useMutation((data) => addTeam(data), {
    onSuccess: ({ data }, variables) => {
      setLocalStorageValue([{ id: localStorageIds.SELECTED_TEAM, value: data }])

      queryClient.invalidateQueries(QueryKeys.GET_ORGS).then((res) => res)
      queryClient.invalidateQueries(QueryKeys.GET_TEAMS, null, data.id).then((res) => res)

      history.push({
        pathname: orgSuccessRedirect ? `/${orgSuccessRedirect}` : '/teamCalendar',
        search: `?org=${variables.orgId}&team=${data.id}`,
      })
      stepForward()
    },
  })

  const addAOrg = useMutation((data) => addOrg(data), {
    onSuccess: ({ data }, variables) => {
      const { Subscriptions } = decodeJwt(data?.accessToken)
      setLocalStorageValue([
        { id: localStorageIds.TOKEN, value: data?.accessToken },
        { id: localStorageIds.SELECTED_ORG, value: data?.org },
        {
          id: localStorageIds.SUBSCRIPTIONS,
          value: Subscriptions,
        },
      ])

      queryClient.invalidateQueries(QueryKeys.GET_ACCOUNT_ME).then(() => {
        addATeam.mutate({
          name: variables.teamName,
          threeLetterIdentifier: variables.threeLetterIdentifier,
          ageRestriction: variables.ageRestriction,
          gender: variables.gender,
          externalId: variables?.externalId,
          color: variables?.teamColor?.value,
          logo: croppedTeamImage,
          orgId: data?.org?.id,
        })
      })
    },
  })

  const handleSubmit = (formData) => {
    addAOrg.mutate({
      ...formData,
      name: formData.orgName,
      countryId: formData.country.id,
      logo: croppedOrgImage,
      type: ORGANISATION_TYPES.ORG_CLUB,
      scopes: envBasedScopes,
    })
  }

  return (
    <Formik
      initialValues={{
        orgName: '',
        country: '',
        city: '',
        teamName: '',
        threeLetterIdentifier: '',
        ageRestriction: '',
        gender: '',
        externalId: '',
        teamColor: '',
      }}
      validationSchema={yup.object().shape({
        threeLetterIdentifier: stringThreeLetters,
      })}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue }) => (
        <Form>
          <ContainerPadding paddingValue='2%'>
            <Grid container justifyContent='center'>
              <Grid
                className={classes.orgFieldWrapper}
                item
                container
                direction='column'
                spacing={2}
                lg={6}
              >
                <Grid item container justifyContent='center'>
                  <OrgAvatarEmptyPreview croppedImage={croppedOrgImage} />
                </Grid>
                <Grid item>
                  <AddOrgFormBody
                    countries={countries}
                    addOrg={addOrg}
                    nameTextFieldInfo={{
                      name: 'orgName',
                      label: translate('ra.formFields.orgName'),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                className={classes.imageCropWrapper}
                item
                container
                direction='column'
                justifyContent='center'
                lg={6}
              >
                <div>
                  <div className={classes.orgAvatarText}>{translate('ra.text.orgAvatar')}</div>
                  <ImageCrop
                    setCroppedImage={setCroppedOrgImage}
                    width={240}
                    height={240}
                    addImageLabel={translate('ra.buttons.uploadOrgLogo')}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container justifyContent='center'>
              <Divider className={classes.divider} />
            </Grid>

            <AddTeamFormBody
              setFieldValue={setFieldValue}
              gender={gender}
              ageRestriction={ageRestriction}
              nameTextFieldInfo={{
                name: 'teamName',
                label: translate('ra.formFields.teamName'),
              }}
              shortNameLabel={translate('ra.formFields.teamShortName')}
              setCroppedTeamImage={setCroppedTeamImage}
            />
          </ContainerPadding>

          <Grid container justifyContent='flex-end' alignItems='center'>
            <ButtonWithLoader
              className={classes.addButton}
              label={translate('ra.buttons.next')}
              loading={addAOrg.isLoading || addATeam.isLoading}
              justifyContent='flex-end'
            />
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

AddClubTeamForm.propTypes = {
  orgSuccessRedirect: PropTypes.string,
  stepForward: PropTypes.func,
}

export default AddClubTeamForm
