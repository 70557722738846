import COLORS from '../../../../utils/constants/colors'

const playerCreatePaginationStyles = (page, nbPages) => ({
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '15px',
    margin: 'auto 10px 12px 10px',
  },
  perPageWrapper: {
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  select: {
    fontSize: 12,
    padding: '7px 21px 7px 7px',
  },
  countString: {
    fontSize: '12px',
  },
  leftIcon: {
    '&&': {
      fill: page > 1 ? COLORS.black : COLORS.grey,
      cursor: page > 1 ? 'pointer' : 'not-allowed',
      padding: '6px 8px',
    },
  },
  rightIcon: {
    '&&': {
      fill: page !== nbPages ? COLORS.black : COLORS.darkGrey,
      cursor: page !== nbPages ? 'pointer' : 'not-allowed',
      padding: '6px 8px',
    },
  },
})

export default playerCreatePaginationStyles
