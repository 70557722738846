import COLORS from '../../../utils/constants/colors'

const drillShowAppbarStyles = {
  appbarWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
    gap: 2,
  },
  switch: {
    marginRight: '7%',
    display: 'flex',
    alignItems: 'center',
  },
  publishLabel: {
    marginRight: '8px',
  },
  pdfIcon: {
    fill: COLORS.orange,
  },
}

export default drillShowAppbarStyles
