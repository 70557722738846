import { fabric } from 'fabric'

const renderDesignOnCanvas = (
  jsonData,
  stadiumImage,
  halfStadiumImage,
  CANVAS_WIDTH,
  CANVAS_HEIGHT,
) => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  fabric.util.enlivenObjects(jsonData.objects, (objects) => {
    let smallestDistanceFromTop = objects[0]?.top || 0
    // Find the smallest distance from the top of the canvas
    objects.forEach((obj) => {
      if (obj.top < smallestDistanceFromTop) {
        smallestDistanceFromTop = obj.top
      }
    })
    smallestDistanceFromTop -= 20

    // Find the smallest distance from the left of the canvas
    let smallestDistanceFromLeft = objects[0]?.left || 0
    objects.forEach((obj) => {
      if (obj.left < smallestDistanceFromLeft) {
        smallestDistanceFromLeft = obj.left
      }
    })
    smallestDistanceFromLeft -= 20

    if (jsonData.isZoomedIn) {
      canvas.width = CANVAS_WIDTH
      canvas.height = CANVAS_HEIGHT / 2
      // Set the half stadium image as the background
      ctx.drawImage(halfStadiumImage, 0, 0, CANVAS_WIDTH, CANVAS_HEIGHT / 2)
    } else {
      canvas.width = CANVAS_WIDTH
      canvas.height = CANVAS_HEIGHT
      // Set the stadium image as the background
      ctx.drawImage(stadiumImage, 0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)
    }
    const scaleFactorForPlayers = 1.4
    const scaleFactorForObjects = 1

    const resizedObjects = []

    objects.forEach((obj) => {
      const newObject = obj
      // Check and modify scaling based on object type
      if (
        obj.objectType === 'CircleIcon' ||
        obj.objectType === 'HatcIcon' ||
        obj.objectType === 'CenterIcon'
      ) {
        newObject.scaleX = scaleFactorForPlayers
        newObject.scaleY = scaleFactorForPlayers
      } else if (
        obj.objectType === 'BBallIcon' ||
        obj.objectType === 'Vector' ||
        obj.objectType === 'Cone' ||
        obj.objectType === 'CrossBar'
      ) {
        newObject.scaleX = scaleFactorForObjects
        newObject.scaleY = scaleFactorForObjects
      }
      resizedObjects.push(newObject)
    })

    const group = new fabric.Group(resizedObjects, {
      left: smallestDistanceFromLeft,
      top: smallestDistanceFromTop,
    })

    group.scale(1)
    group.setCoords()
    group.render(ctx)
  })

  return canvas
}

export default renderDesignOnCanvas
