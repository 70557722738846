import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DashboardMenuItem } from 'react-admin'
import { MyLayoutProvider, useMyLayout } from '../../../../context/useMyLayout'
import OrgLogo from '../../../../components/OrgLogo/OrgLogo'
import SelectTeam from '../../../../components/selectTeam/SelectTeam'
import MenuItemLinks from '../../../../components/MenuItemLinks/MenuItemLinks'
import CoachCorner from '../../../../components/CoachCorner/CoachCorner'
import User from '../../../../components/user/User'
import IsUserScopesAuthorized from '../../../../components/IsUserScopesAuthorized/IsUserScopesAuthorized'
import { USER_SCOPES } from '../../../../utils/constants/userScopes'
import AddOrgModal from '../../../../components/AddOrgModal/AddOrgModal'
import useHistoryPush from '../../../../hooks/useHistoryPush'
import ConditionalRender from '../../../../components/ConditionalRender/ConditionalRender'
import SIDEBAR_OPTION from '../../../../utils/constants/sidebarOptions'
import myMenuStyles from './myMenu.styles'
import TOUR_IDS from '../../../../utils/constants/introIds'
import ImpersonationField from '../../../../components/ImpersonationField/ImpersonationField'

const MyMenu = (props) => {
  const {
    classes: classesOverride,
    className,
    dense,
    hasDashboard,
    onMenuClick,
    logout,
    isSmallScreenMenuOpen,
    ...rest
  } = props
  const [showModal, setShowModal] = useState(false)
  const [orgSuccessRedirect, setOrgSuccessRedirect] = useState('')
  const { myOrgs, singleOrg, error } = useMyLayout()
  const { redirect } = useHistoryPush()
  const open = useSelector((state) => state.admin.ui.sidebarOpen)
  const { pathname } = useLocation()
  const isSelectTeamVisible = ![...SIDEBAR_OPTION.orgMenuChoices, ...SIDEBAR_OPTION.PROFILE_ITEMS]
    .map((option) => `/${option.name}`)
    .includes(pathname)
  const useStyles = makeStyles((theme) => myMenuStyles(theme, isSmallScreenMenuOpen), {
    name: 'RaMenu',
  })
  const classes = useStyles(props)

  const showCreateOrgTeamModal = (page) => {
    redirect('/')
    setOrgSuccessRedirect(page || 'teamCalendar')
    setShowModal(true)
  }

  return (
    <>
      <div
        className={classnames(
          classes.main,
          {
            [classes.open]: open,
            [classes.closed]: !open,
          },
          className,
        )}
        {...rest}
      >
        <div id={TOUR_IDS.calendar.players}>
          <ConditionalRender renderValue={isSelectTeamVisible}>
            <div className='wrapper-logo-select'>
              <OrgLogo
                singleOrgLogo={singleOrg?.logoUrl}
                myOrgs={myOrgs}
                showCreateOrgTeamModal={showCreateOrgTeamModal}
              />
              <SelectTeam
                myOrgs={myOrgs}
                showCreateOrgTeamModal={showCreateOrgTeamModal}
                error={error}
              />
            </div>
          </ConditionalRender>
          {hasDashboard && <DashboardMenuItem />}
          <MenuItemLinks myOrgs={myOrgs} showCreateOrgTeamModal={showCreateOrgTeamModal} />
        </div>

        <div className='wrapper-coach-corner-user'>
          <ImpersonationField sideBarOpen={open} />
          <IsUserScopesAuthorized acceptedScopes={[USER_SCOPES.COACH]}>
            <CoachCorner error={error} />
          </IsUserScopesAuthorized>
          <User sideBarOpen={open} />
        </div>
      </div>
      <AddOrgModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        orgSuccessRedirect={orgSuccessRedirect}
      />
    </>
  )
}

MyMenu.propTypes = {
  className: PropTypes.string,
  hasDashboard: PropTypes.bool,
  onMenuClick: PropTypes.func,
  logout: PropTypes.func,
  isSmallScreenMenuOpen: PropTypes.bool,
}

const MyProvidedMenu = ({ open, ...props }) => {
  return (
    <MyLayoutProvider id={TOUR_IDS.calendar.orgLogo}>
      <MyMenu {...props} open={open} hasDashboard={false} />
    </MyLayoutProvider>
  )
}

export default MyProvidedMenu
