import { useField } from 'formik'
import { makeStyles, TextField } from '@material-ui/core'
import TEXT_FIELD_STYLES from '../../../utils/constants/CSS/textFieldStyles'

const MyMultilineTextField = (props) => {
  const { autoComplete, ...otherProps } = props
  const [field, meta] = useField(props)
  const useStyles = makeStyles(TEXT_FIELD_STYLES)
  const classes = useStyles()

  return (
    <TextField
      {...otherProps}
      {...field}
      variant='filled'
      type='input'
      helperText={meta.touched && meta.error}
      error={meta.touched && meta.error}
      autoComplete={autoComplete ? 'off' : ''}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.rootMultiline,
          input: classes.inputMultiline,
        },
      }}
      InputLabelProps={{
        classes: {
          shrink: classes.shrink,
          formControl: classes.formControl,
          focused: classes.focused,
        },
      }}
    />
  )
}

export default MyMultilineTextField
