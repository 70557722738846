import { useLocation } from 'react-router-dom'

// paramNames = []string
const useUrlSearchParams = (paramNames) => {
  const location = useLocation()

  if (!paramNames?.length || paramNames?.length <= 0) return null
  const queryParams = new URLSearchParams(location.search)

  return paramNames?.reduce((acc, paramName) => {
    acc[paramName] = queryParams?.get(paramName)
    return acc
  }, {})
}

export default useUrlSearchParams
