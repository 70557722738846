import React from 'react'
import { makeStyles, TextField } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import SearchIcon from '@material-ui/icons/Search'
import MyCloseIcon from '../../../assets/icons/MyCloseIcon'
import searchFieldStyles from './searchField.styles'

const SearchField = (props) => {
  const { reset, ...rest } = props
  const translate = useTranslate()
  const useStyles = makeStyles(searchFieldStyles)
  const classes = useStyles()

  return (
    <TextField
      {...rest}
      placeholder={translate('ra.action.search')}
      color='secondary'
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.searchFieldRoot,
          input: classes.searchFieldInput,
        },
        startAdornment: <SearchIcon fontSize='small' />,
        endAdornment:
          props.value && reset ? <MyCloseIcon fontSize='small' onClick={reset} /> : null,
      }}
    />
  )
}

export default SearchField
