import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const InfiniteScroll = (props) => {
  const { isLoading, hasNextPage, fetchNextPage, scrollId } = props

  const handleScroll = (e) => {
    window.sessionStorage.setItem(scrollId, e.target.scrollTop)
  }

  useEffect(() => {
    document.querySelector('#main-content').addEventListener('scroll', handleScroll, true)

    return () => {
      document.querySelector('#main-content')?.removeEventListener('scroll', handleScroll, true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const scrollPosition = parseInt(sessionStorage.getItem(scrollId))
    if (scrollPosition > 0) {
      document.querySelector('#main-content').scrollTo({
        top: scrollPosition,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const observer = useRef(null)

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage()
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoading, hasNextPage],
  ) // eslint-disable-line react-hooks/exhaustive-deps

  return <div ref={lastElementRef} style={{ marginTop: '50px', width: '40px', height: '40px' }} />
}

InfiniteScroll.propTypes = {
  isLoading: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  fetchNextPage: PropTypes.func,
  scrollId: PropTypes.string,
}

export default InfiniteScroll
